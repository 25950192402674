// TopNavigationLayout.tsx
import { useRouteContext } from '@btrway/web-routing';
import { Box, Group, MantineSpacing, Stack, Text, Title } from '@mantine/core';
import { StyleProp } from '@mantine/core/lib/core/Box/style-props/style-props.types';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useLayoutNavigation } from '../../hooks/useLayoutNavigation';
import { ButtonNavigation } from '../ButtonNavigation/ButtonNavigation';
import styles from './TopNavigationLayout.module.css';

interface TopNavigationLayoutProps {
  header?: React.ReactNode | string;
  subHeader?: React.ReactNode | string;
  contentPadding?: StyleProp<MantineSpacing>;
  headerBackgroundColor?: string;
  variant?: 'light' | 'dark';
}

export const TopNavigationLayout: React.FC<TopNavigationLayoutProps> = ({
  header,
  subHeader,
  contentPadding = 'md',
  headerBackgroundColor,
  variant,
}) => {
  const { childRoutes, currentRoute } = useRouteContext();
  const outletContext = useOutletContext();
  const { navItems, currentValue, handleNavigation } = useLayoutNavigation(
    childRoutes,
    currentRoute
  );

  const renderHeader = () => {
    if (React.isValidElement(header)) {
      return header;
    }

    if (typeof header === 'string') {
      return (
        <Title order={3} c={variant === 'dark' ? 'white' : undefined}>
          {header}
        </Title>
      );
    }

    return (
      <Title order={3} c={variant === 'dark' ? 'white' : undefined}>
        {currentRoute.label}
      </Title>
    );
  };

  const renderSubHeader = () => {
    if (!subHeader) return null;

    if (React.isValidElement(subHeader)) {
      return subHeader;
    }

    if (typeof subHeader === 'string') {
      return <Text size="lg">{subHeader}</Text>;
    }

    return null;
  };

  return (
    <Stack gap={0} justify="flex-start" p={0} className={styles.pageContainer}>
      <Stack
        gap={0}
        className={styles.headerGroup}
        style={
          headerBackgroundColor
            ? { backgroundColor: headerBackgroundColor }
            : undefined
        }
        align="flex-start"
        w="100%"
      >
        <Group
          justify="space-between"
          align="center"
          gap="xs"
          wrap="nowrap"
          w="100%"
        >
          <Group justify="flex-start" gap="xs" align="flex-start">
            {renderHeader()}
          </Group>
          <ButtonNavigation
            navItems={navItems}
            currentValue={currentValue}
            onChange={handleNavigation}
            variant={variant}
          />
        </Group>
        <Box w="100%">{renderSubHeader()}</Box>
      </Stack>
      <div className={styles.contentContainer}>
        <Box className={styles.contentWrapper} p={contentPadding}>
          <Outlet context={outletContext} />
        </Box>
      </div>
    </Stack>
  );
};
