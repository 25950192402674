import { AssetResponse, useGetAssetByUid } from '@btrway/api-courseware';

interface UseCourseResult {
  asset: AssetResponse | null;
  isLoading: boolean;
}

export const useAsset = (assetUid: number): UseCourseResult => {
  const { data: asset, isLoading } = useGetAssetByUid(assetUid);

  return {
    asset: asset || null,
    isLoading,
  };
};
