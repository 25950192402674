// hooks/useWorkflowDefinition.ts
import {
  getGetWorkflowDefinitionByKeyQueryKey,
  getGetWorkflowDefinitionsByOrganizationQueryKey,
  useGetWorkflowDefinitionByKey,
  useGetWorkflowDefinitionsByOrganization,
  useSaveWorkflowDefinition,
  useUpdateWorkflowDefinitionConfig,
  WorkflowDefinitionConfigUpdate,
  WorkflowDefinitionRequest,
} from '@btrway/api-workflow';
import { useWorkflowCommon } from '../providers/WorkflowCommonProvider';
import { CommonWorkflow } from '../types/commonWorkflow';
import { WorkflowResult, WorkflowsResult } from '../types/service';
import {
  convertCommonToWorkflowRequest,
  convertResponseToCommon,
  validateWorkflowBeforeConversion,
} from '../utils/commonWorkflowConversions';

export function useWorkflowDefinition(
  organizationId: number,
  workflowKey?: string
) {
  const { sourceType } = useWorkflowCommon();
  const isEnabled = sourceType === 'definition';
  const { mutateAsync: updateConfig } = useUpdateWorkflowDefinitionConfig();
  const { mutateAsync: saveWorkflowDefinition } = useSaveWorkflowDefinition();

  const {
    data,
    isLoading,
    error,
    refetch: refetchDefinition,
  } = useGetWorkflowDefinitionByKey(
    organizationId,
    workflowKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!workflowKey,
        queryKey: getGetWorkflowDefinitionByKeyQueryKey(
          organizationId,
          workflowKey || ''
        ),
      },
    }
  );

  const {
    data: definitionsData,
    isLoading: definitionsLoading,
    error: definitionsError,
    refetch: refetchDefinitions,
  } = useGetWorkflowDefinitionsByOrganization(organizationId, {
    query: {
      enabled: isEnabled,
      queryKey: getGetWorkflowDefinitionsByOrganizationQueryKey(organizationId),
    },
  });

  const result: WorkflowResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchDefinition,
  };

  const definitionsResult: WorkflowsResult = {
    workflows: (definitionsData || []).map(convertResponseToCommon),
    isLoading: definitionsLoading,
    error: definitionsError as Error | undefined,
    refetch: refetchDefinitions,
  };

  return {
    workflowResult: result,
    workflowsResult: definitionsResult,
    updateConfig: async (config: WorkflowDefinitionConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'definition') {
        throw new Error('Cannot save template workflow in definition context');
      }
      const validationErrors = validateWorkflowBeforeConversion(workflow);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid workflow: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToWorkflowRequest(
        workflow
      ) as WorkflowDefinitionRequest;
      return await saveWorkflowDefinition({ data: request });
    },
  };
}
