import {
  EventRequest,
  EventTargetRequest,
  RecurrenceModificationTypeEnum,
  useDeleteEvent,
  useDeleteRecurringEvent,
  useSaveEvent,
} from '@btrway/api-calendar';
import { useAPIClient } from '@btrway/api-client-provider';
import { WorkgroupResponse } from '@btrway/api-core';
import {
  RecurrenceModal,
  RecurrenceModificationDialog,
  formatRecurrenceRule,
} from '@btrway/calendar-recurrence';
import { ContentEditor, EditorContent } from '@btrway/content-editor';
import {
  Box,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconMapPin, IconRepeat } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { EventDateTime } from '../../types/eventDateTime';
import { CalendarEventDates } from '../CalendarEventDates/CalendarEventDates';
import { EventTargetList } from '../EventTargetList/EventTargetList';
import { EventTypeSelector } from '../EventTypeSelector/EventTypeSelector';
import CalendarEventFileUpload from '../CalendarEventFileUpload/CalendarEventFileUpload';

type FormValues = Partial<Omit<EventRequest, 'eventTargets'>>;

interface CalendarEventEditProps {
  workgroup?: WorkgroupResponse;
  initialEvent: Partial<EventRequest>;
  onCancel?: () => void;
  onSave?: (event: EventRequest) => void;
  onDelete?: () => void;
  userTimezone: string;
}

export const CalendarEventEdit = ({
  workgroup,
  initialEvent,
  onCancel,
  onSave,
  onDelete,
  userTimezone,
}: CalendarEventEditProps) => {
  const { queryClient } = useAPIClient();
  const [showRecurrenceModal, setShowRecurrenceModal] = useState(false);
  const [
    showRecurrenceModificationDialog,
    setShowRecurrenceModificationDialog,
  ] = useState(false);
  const [eventTargets, setEventTargets] = useState<EventTargetRequest[]>(
    initialEvent.eventTargets || []
  );
  const [hasInvalidDates, setHasInvalidDates] = useState(false);
  const [pendingEventRequest, setPendingEventRequest] =
    useState<EventRequest | null>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { mutateAsync: deleteEvent } = useDeleteEvent();
  const { mutateAsync: deleteRecurringEvent } = useDeleteRecurringEvent();
  const { mutateAsync: saveEvent } = useSaveEvent();

  const form = useForm<FormValues>({
    initialValues: initialEvent,
  });

  useEffect(() => {
    form.reset();
    form.setValues(initialEvent);
    setEventTargets(initialEvent.eventTargets || []);
  }, [initialEvent]);

  const isPartOfRecurrenceSeries =
    initialEvent?.parentEventId && !initialEvent?.isRecurrenceException;
  const isModifyingRecurrenceRule =
    form.values.recurrenceRule !== initialEvent?.recurrenceRule;

  const validateDates = () => {
    const startMs = new Date(form.values.startTime || new Date()).getTime();
    const endMs = new Date(form.values.endTime || new Date()).getTime();
    const isValid = endMs > startMs;
    setHasInvalidDates(!isValid);
    return isValid;
  };

  const handleDelete = async () => {
    if (!initialEvent.id) return;

    const isPartOfRecurrenceSeries =
      initialEvent.parentEventId && !initialEvent.isRecurrenceException;

    if (isPartOfRecurrenceSeries) {
      setShowDeleteConfirmation(true);
    } else {
      try {
        await deleteEvent({ eventId: initialEvent.id });
        onDelete?.();
        onCancel?.();
      } catch (error) {
        console.error('Failed to delete event:', error);
      }
    }
  };

  const handleRecurrenceDeleteConfirm = async (
    type: RecurrenceModificationTypeEnum
  ) => {
    if (!initialEvent.id) return;

    try {
      await deleteRecurringEvent({
        id: initialEvent.id,
        data: { modificationType: type },
      });
      onDelete?.();
      onCancel?.();
    } catch (error) {
      console.error('Failed to delete recurring event:', error);
    }
  };

  const handleEventDateTimeChange = (newValue: EventDateTime) => {
    form.setValues({
      ...form.values,
      startTime: newValue.startTime,
      endTime: newValue.endTime,
      timeZoneName: newValue.timeZoneName,
    });
    validateDates();
  };

  const handleAllDayChange = (checked: boolean) => {
    const startDate = new Date(form.values.startTime || new Date());
    const endDate = new Date(form.values.endTime || new Date());

    if (checked) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
    }

    form.setValues({
      ...form.values,
      allDayEvent: checked,
      startTime: startDate.toISOString(),
      endTime: endDate.toISOString(),
    });
  };

  const handleAddTarget = (target: EventTargetRequest) => {
    setEventTargets((prev) => [...prev, target]);
  };

  const handleRemoveTarget = (targetId: number) => {
    setEventTargets((prev) => prev.filter((t) => t.entityId !== targetId));
  };

  const handleRecurrenceChange = (recurrenceRule: string) => {
    form.setFieldValue('recurrenceRule', recurrenceRule);
    form.setFieldValue('isRecurrenceParent', !!recurrenceRule);
  };

  const handleSubmit = async (values: FormValues) => {
    if (!validateDates()) return;

    const eventRequest: EventRequest = {
      startTime: values.startTime || new Date().toISOString(),
      endTime: values.endTime || new Date(Date.now() + 3600000).toISOString(),
      name: values.name || '',
      description: values.description || '',
      location: values.location || '',
      allDayEvent: values.allDayEvent || false,
      isPublic: values.isPublic || true,
      isRecurrenceParent: values.isRecurrenceParent || false,
      isRecurrenceException: values.isRecurrenceException || false,
      recurrenceRule: values.recurrenceRule || '',
      organizationId: values.organizationId || workgroup?.organizationId || 0,
      workgroupId: values.workgroupId || workgroup?.id || 0,
      ownerPersonId: values.ownerPersonId || 0,
      eventTypeId: values.eventTypeId || 0,
      timeZoneName: values.timeZoneName || userTimezone,
      storageKeys: values.storageKeys,
      eventReminders: values.eventReminders || [],
      eventTargets,
      // Include id if it exists
      ...(initialEvent?.id && { id: initialEvent.id }),
      // Add parentEventId if it exists
      ...(initialEvent?.parentEventId && {
        parentEventId: initialEvent.parentEventId,
      }),
    };

    // Only show modification dialog if this is an existing event (has an ID)
    // and either:
    // 1. Editing an event that's part of a recurrence series
    // 2. Changing recurrence rule settings on a recurring event
    if (
      initialEvent?.id &&
      (isPartOfRecurrenceSeries || isModifyingRecurrenceRule)
    ) {
      setPendingEventRequest(eventRequest);
      setShowRecurrenceModificationDialog(true);
      return;
    }

    try {
      await saveEvent({ data: eventRequest });
      onSave?.(eventRequest);
    } catch (error) {
      console.error('Failed to save event:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handleRecurrenceModificationConfirm = async (
    type: RecurrenceModificationTypeEnum
  ) => {
    if (!pendingEventRequest || !initialEvent?.id) return;

    try {
      // Instead of calling updateRecurringEvent, use saveEvent with recurrenceModificationType
      await saveEvent({
        data: {
          ...pendingEventRequest,
          id: initialEvent.id,
          recurrenceModificationType: type,
        },
      });
      onSave?.(pendingEventRequest);
    } catch (error) {
      console.error('Failed to update recurring event:', error);
      // Handle error (show notification, etc.)
    }
  };

  const handleStorageKeysChange = (storageKeys: string[]) => {
    form.setValues({
      ...form.values,
      storageKeys: storageKeys
    })
  }

  const getInitialContent = (content?: string) => {
    if (!content) {
      return {
        type: 'doc',
        content: [{ type: 'paragraph' }],
      };
    }

    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [{ type: 'text', text: content }],
        },
      ],
    };
  };

  return (
    <Stack h="100%" gap="md">
      <Box style={{ display: 'flex', gap: '16px', flex: 1, minHeight: 0 }}>
        <Stack gap="md" style={{ flex: 1 }}>
          <TextInput
            placeholder="Add title"
            size="lg"
            autoFocus
            data-autofocus
            {...form.getInputProps('name')}
          />

          <EventTypeSelector
            value={form.values.eventTypeId}
            onChange={(value) => form.setFieldValue('eventTypeId', value)}
          />

          <CalendarEventDates
            value={{
              startTime: form.values.startTime || new Date().toISOString(),
              endTime:
                form.values.endTime ||
                new Date(Date.now() + 3600000).toISOString(),
              timeZoneName: form.values.timeZoneName || userTimezone,
              allDayEvent: form.values.allDayEvent || false,
              hasError: hasInvalidDates,
            }}
            onChange={handleEventDateTimeChange}
          />

          <Group>
            <Checkbox
              label="All day"
              checked={form.values.allDayEvent}
              onChange={(event) =>
                handleAllDayChange(event.currentTarget.checked)
              }
            />
            <Button
              variant="subtle"
              leftSection={<IconRepeat size={16} />}
              onClick={() => setShowRecurrenceModal(true)}
            >
              {formatRecurrenceRule(form.values.recurrenceRule)}
            </Button>
          </Group>

          <CalendarEventFileUpload
            initialStorageKeys={initialEvent.storageKeys || []}
            onStorageKeysChange={handleStorageKeysChange}
          />

          <TextInput
            label="Location"
            placeholder="Add location"
            leftSection={<IconMapPin size={16} />}
            {...form.getInputProps('location')}
          />

          <Box>
            <Title order={6}>Description</Title>
            <Box style={{ height: 300 }}>
              <ContentEditor
                initialContent={form.values.description}
                onChange={(json: EditorContent, html: string) => {
                  // Extract text content from JSON structure
                  const text = json.content?.[0]?.content?.[0]?.text || '';
                  form.setFieldValue('description', text);
                }}
              />
            </Box>
          </Box>
        </Stack>

        <Box w="30%" miw={300}>
          <EventTargetList
            targets={eventTargets}
            onAddTarget={handleAddTarget}
            onRemoveTarget={handleRemoveTarget}
            workgroupId={workgroup?.id || 0} // Add this line
          />
        </Box>
      </Box>

      <RecurrenceModal
        opened={showRecurrenceModal}
        onClose={() => setShowRecurrenceModal(false)}
        value={form.values.recurrenceRule}
        onChange={handleRecurrenceChange}
      />

      <RecurrenceModificationDialog
        opened={showRecurrenceModificationDialog}
        onClose={() => setShowRecurrenceModificationDialog(false)}
        onConfirm={handleRecurrenceModificationConfirm}
        showAllOptions={true}
      />

      <RecurrenceModificationDialog
        opened={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleRecurrenceDeleteConfirm}
        showAllOptions={true}
        title="Delete Recurring Event"
        confirmLabel="Delete"
        description="This is a recurring event. How would you like to delete it?"
      />

      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Group justify="space-between" px="md" pb="md">
          {initialEvent.id && (
            <Button color="red" variant="subtle" onClick={() => handleDelete()}>
              Delete
            </Button>
          )}
          <Group ml="auto">
            <Button variant="subtle" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" disabled={hasInvalidDates}>
              Save
            </Button>
          </Group>
        </Group>
      </form>
    </Stack>
  );
};
