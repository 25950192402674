import {
  StepConfig,
  StepTypeEnum,
  TaskAssignmentConfig,
  TaskAssignmentTypeEnum,
} from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { useUserRoles } from '@btrway/security-manager';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import {
  Badge,
  Box,
  Card,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import {
  IconAlertTriangleFilled,
  IconBulb,
  IconChevronRight,
  IconEdit,
  IconUserCircle,
  IconUsers,
  IconUserSearch,
  IconUserSquareRounded,
} from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { getTypedStepProperties } from '../../../config/stepPropertiesMap';
import PersonSelector from '../../../selector-components/PersonSelector/PersonSelector';
import UserRoleSelector from '../../../selector-components/UserRoleSelector/UserRoleSelector';
import { StepConfigProps } from '../../../types/editors';
import styles from './TaskAssignmentConfiguration.module.css';

interface TaskAssignmentConfigurationProps extends StepConfigProps {
  primaryEntityDescription?: string;
}

export const TaskAssignmentConfiguration: React.FC<
  TaskAssignmentConfigurationProps
> = ({ config, onConfigChange, primaryEntityDescription }) => {
  const assignTaskProperties = getTypedStepProperties(
    config,
    StepTypeEnum.assignTask
  );
  const taskAssignmentConfig = assignTaskProperties?.taskAssignmentConfig || {};

  const [showMoreOptions, setShowMoreOptions] = useState(
    !taskAssignmentConfig.assignmentType
  );
  const [displayInstructions, setDisplayInstructions] = useState(
    !taskAssignmentConfig.assignmentType
  );
  const [selectedAssignmentType, setSelectedAssignmentType] = useState<
    TaskAssignmentTypeEnum | undefined
  >(taskAssignmentConfig.assignmentType);

  const { getUserRoleBySlug } = useUserRoles();
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  useEffect(() => {
    // Only update the state if the assignment type has changed
    if (taskAssignmentConfig.assignmentType !== selectedAssignmentType) {
      setSelectedAssignmentType(taskAssignmentConfig.assignmentType);

      // Only reset showMoreOptions if there's no assignment type
      if (!taskAssignmentConfig.assignmentType) {
        setShowMoreOptions(true);
        setDisplayInstructions(true);
      }
    }
  }, [taskAssignmentConfig.assignmentType]);

  const handleEditClick = () => {
    setShowMoreOptions(true);
  };

  const openUserRoleSelector = () => {
    setRightSectionContent(
      <UserRoleSelector
        initialUserRoleSlug={taskAssignmentConfig.userRoleSlug?.value}
        onUserRoleSelect={(selectedRoleSlug) => {
          const newAssignment: TaskAssignmentConfig = {
            assignmentType: TaskAssignmentTypeEnum.userRole,
            userRoleSlug: { value: selectedRoleSlug },
          };
          updateTaskAssignmentConfig(newAssignment);
          setShowMoreOptions(false);
          clearRightSectionContent();
        }}
        onClose={() => {
          clearRightSectionContent();
        }}
      />
    );
  };

  const openPersonSelector = () => {
    setRightSectionContent(
      <PersonSelector
        initialPersonId={taskAssignmentConfig.personId?.value}
        onPersonSelect={(selectedPerson) => {
          let newAssignment: TaskAssignmentConfig;

          if (typeof selectedPerson === 'number') {
            newAssignment = {
              assignmentType: TaskAssignmentTypeEnum.person,
              personId: { value: selectedPerson },
            };
          } else {
            newAssignment = {
              assignmentType: TaskAssignmentTypeEnum.person,
              personId: { reference: selectedPerson },
            };
          }

          updateTaskAssignmentConfig(newAssignment);
          setShowMoreOptions(false);
          clearRightSectionContent();
        }}
        onClose={() => {
          clearRightSectionContent();
        }}
      />
    );
  };

  const handleAssignmentTypeChange = (type: TaskAssignmentTypeEnum) => {
    setSelectedAssignmentType(type);
    if (type === TaskAssignmentTypeEnum.primaryWorkflowPerson) {
      updateTaskAssignmentConfig({ assignmentType: type });
      setShowMoreOptions(false);
      clearRightSectionContent();
    } else if (type === TaskAssignmentTypeEnum.userRole) {
      openUserRoleSelector();
    } else if (type === TaskAssignmentTypeEnum.person) {
      openPersonSelector();
    }
  };

  const updateTaskAssignmentConfig = (newAssignment: TaskAssignmentConfig) => {
    const updatedConfig: StepConfig = {
      ...config,
      inputProperties: {
        ...assignTaskProperties,
        taskAssignmentConfig: newAssignment,
      },
    };

    if (onConfigChange) {
      onConfigChange(updatedConfig);
    }
  };

  const getAssignmentDescription = useCallback(
    (assignment: TaskAssignmentConfig): React.ReactNode => {
      if (!assignment) return 'Unassigned';
      switch (assignment.assignmentType) {
        case TaskAssignmentTypeEnum.primaryWorkflowPerson:
          return (
            <Badge size="lg" color="blue">
              {primaryEntityDescription || 'Primary Entity'}
            </Badge>
          );
        case TaskAssignmentTypeEnum.userRole:
          const roleSlug = assignment.userRoleSlug?.value;
          const role = roleSlug ? getUserRoleBySlug(roleSlug) : undefined;
          return role ? `Assign by Role: ${role.name}` : 'Unknown Role';
        case TaskAssignmentTypeEnum.person:
          if (assignment.personId?.reference) {
            return `Assign to Person: ${
              assignment.personId.reference.label ||
              assignment.personId.reference.propertyKey
            }`;
          } else if (assignment.personId?.value) {
            return (
              <Group justify="flex-start" gap="xs" wrap="nowrap">
                <Text>Assign to Person:</Text>
                <EntityTag
                  dataType="person"
                  id={assignment.personId.value}
                  allowClick={false}
                />
              </Group>
            );
          } else {
            return 'Specific Person: Not Selected';
          }
        default:
          return 'Not assigned';
      }
    },
    [primaryEntityDescription, getUserRoleBySlug]
  );

  const getAssignmentIcon = (assignment: TaskAssignmentConfig) => {
    switch (assignment.assignmentType) {
      case TaskAssignmentTypeEnum.primaryWorkflowPerson:
        return <IconUserCircle size={20} />;
      case TaskAssignmentTypeEnum.userRole:
        return <IconUsers size={20} />;
      case TaskAssignmentTypeEnum.person:
        return assignment.personId?.reference ? (
          <IconBulb size={20} />
        ) : (
          <IconUserSearch size={20} />
        );
      default:
        return null;
    }
  };

  const renderAssignmentTypeOption = (
    type: TaskAssignmentTypeEnum,
    icon: React.ReactNode,
    label: string
  ) => {
    const isSelected = selectedAssignmentType === type;
    const showChevron = type !== TaskAssignmentTypeEnum.primaryWorkflowPerson;

    let displayLabel: React.ReactNode = label;
    if (
      type === TaskAssignmentTypeEnum.userRole &&
      isSelected &&
      taskAssignmentConfig.assignmentType === type
    ) {
      const roleSlug = taskAssignmentConfig.userRoleSlug?.value;
      const role = roleSlug ? getUserRoleBySlug(roleSlug) : undefined;
      displayLabel = role ? `${label}: ${role.name}` : label;
    } else if (
      type === TaskAssignmentTypeEnum.person &&
      isSelected &&
      taskAssignmentConfig.assignmentType === type
    ) {
      displayLabel = getAssignmentDescription(taskAssignmentConfig);
    } else if (type === TaskAssignmentTypeEnum.primaryWorkflowPerson) {
      displayLabel = (
        <Group gap="xs" wrap="nowrap">
          <Badge size="lg" color="blue">
            {primaryEntityDescription || 'Primary Entity'}
          </Badge>
        </Group>
      );
    }

    return (
      <Card
        shadow="none"
        padding="xs"
        radius="md"
        withBorder
        style={{
          cursor: 'pointer',
          backgroundColor: isSelected
            ? 'var(--mantine-color-blue-light)'
            : undefined,
        }}
        onClick={() => handleAssignmentTypeChange(type)}
      >
        <Group justify="space-between" gap="xs" wrap="nowrap">
          <Group
            gap="xs"
            wrap="nowrap"
            align="flex-start"
            className={styles.descriptionWrapper}
          >
            <div className={styles.iconWrapper}>{icon}</div>
            {displayLabel}
          </Group>
          {showChevron && (
            <div className={styles.iconWrapper}>
              <IconChevronRight size={16} />
            </div>
          )}
        </Group>
      </Card>
    );
  };

  return (
    <Stack gap={4} p={0}>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        onClick={!showMoreOptions ? handleEditClick : undefined}
        style={{ cursor: !showMoreOptions ? 'pointer' : 'default' }}
      >
        {!showMoreOptions ? (
          <Group justify="space-between" wrap="nowrap">
            <Group
              justify="flex-start"
              gap="xs"
              wrap="nowrap"
              align="flex-start"
            >
              {getAssignmentIcon(taskAssignmentConfig)}
              <Text fz="md" fw={600}>
                {getAssignmentDescription(taskAssignmentConfig)}
              </Text>
            </Group>
            <UnstyledButton>
              <IconEdit size={20} color="blue" />
            </UnstyledButton>
          </Group>
        ) : (
          <Box>
            {displayInstructions && (
              <Group justify="space-between" wrap="nowrap" mb="lg">
                <Group justify="flex-start" wrap="nowrap" gap="xs">
                  <IconUserSquareRounded
                    style={{ width: '20px', height: '20px' }}
                  />
                  <Text fz="md" fw={600}>
                    Select who to assign this task to
                  </Text>
                </Group>
                <IconAlertTriangleFilled size={20} color="orange" />
              </Group>
            )}
            <Stack gap="xs">
              {renderAssignmentTypeOption(
                TaskAssignmentTypeEnum.primaryWorkflowPerson,
                <IconUserCircle size={16} />,
                primaryEntityDescription || 'Primary Entity'
              )}
              {renderAssignmentTypeOption(
                TaskAssignmentTypeEnum.userRole,
                <IconUsers size={16} />,
                'Assign by Role'
              )}
              {renderAssignmentTypeOption(
                TaskAssignmentTypeEnum.person,
                <IconUserSearch size={16} />,
                'Assign to Person'
              )}
            </Stack>
          </Box>
        )}
      </Card>
    </Stack>
  );
};
