import { useAtomValue } from 'jotai';
import { useLocation } from 'react-router-dom';
import { tagsAtom } from '../atoms/tagsAtom';

export const useHelpTags = (): string[] => {
  const location = useLocation();
  const allLocationTags = useAtomValue(tagsAtom);

  const locationTags = allLocationTags.find(
    (lt) => lt.pathname === location.pathname
  );

  return locationTags?.tags || [];
};
