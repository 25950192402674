import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { Badge, Card, Group, Progress, Text } from '@mantine/core';
import React from 'react';
import { useWorkgroupStats } from '../../providers/WorkgroupStatsProvider';
import styles from './CertificationsTopSection.module.css';

const RequirementsCard: React.FC<{ count: number }> = ({ count }) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {count}
      </Badge>
      <Text fz="lg" fw={600} c="white">
        Requirements Left
      </Text>
    </Group>
  </Card>
);

const ProgressCard: React.FC<{ progress: number }> = ({ progress }) => (
  <Card className={styles.card} p="md" style={{ flex: '2' }} radius="lg">
    <Group justify="center" align="center" wrap="nowrap" w="100%">
      <Progress
        value={progress}
        color="blue"
        size="xl"
        radius="xl"
        style={{ flex: '1 1 auto' }}
      />
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {progress.toFixed(1)}%
      </Badge>
    </Group>
  </Card>
);

const StatusCard: React.FC<{ completed: number; total: number }> = ({
  completed,
  total,
}) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Text fz="lg" fw={600} c="white">
        Complete:
      </Text>
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {completed} of {total}
      </Badge>
    </Group>
  </Card>
);

export const CertificationsTopSection: React.FC = () => {
  const { stats } = useWorkgroupStats();

  if (!stats?.[0]) {
    return null;
  }

  const workgroupStats = stats[0];
  const certStats = workgroupStats.workflowTypeStats.find(
    (stat) => stat.workflowType === WorkflowTypeEnum.certification
  );

  if (!certStats) {
    return null;
  }

  const completedTasks = certStats.completedTasks || 0;
  const totalTasks = certStats.totalPossibleTasks || 0;
  const remainingTasks = totalTasks - completedTasks;
  const progress = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;

  return (
    <Group gap="md" style={{ width: '100%' }}>
      <RequirementsCard count={remainingTasks} />
      <ProgressCard progress={progress} />
      <StatusCard completed={completedTasks} total={totalTasks} />
    </Group>
  );
};

export default CertificationsTopSection;
