import { ChapterDTO } from '@btrway/api-courseware';
import { getFormattedDuration } from '@btrway/utils';
import { ActionIcon, Badge, Button, Group, Stack, Text } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconQuestionMark,
  IconTrash,
  IconVideo,
} from '@tabler/icons-react';
import React from 'react';
import { useChapterBuilderSet } from '../../hooks/useChapterBuilderSet';

interface ChapterSummaryProps {
  chapter: ChapterDTO;
  isOpen: boolean;
  onToggle: () => void;
}

const ChapterSummary: React.FC<ChapterSummaryProps> = ({
  chapter,
  isOpen,
  onToggle,
}) => {
  const { removeChapter } = useChapterBuilderSet();

  const handleRemove = () => {
    removeChapter(chapter.key);
  };

  return (
    <Group justify="flex-start" wrap="nowrap" w="100%" onClick={onToggle}>
      <ActionIcon variant="subtle" color="black">
        {isOpen ? (
          <IconChevronDown size={20} />
        ) : (
          <IconChevronRight size={20} />
        )}
      </ActionIcon>

      {!isOpen ? (
        <>
          <Group justify="space-between" wrap="nowrap" style={{ flex: 1 }}>
            <Text>{chapter.title}</Text>
            <Group justify="flex-end">
              {chapter.videoPlaybackIdentifier && (
                <Badge
                  color="blue"
                  variant="light"
                  aria-label="video"
                  size="xl"
                  rightSection={
                    <Badge variant="outline" bg="white">
                      {getFormattedDuration(chapter.duration || 0)}
                    </Badge>
                  }
                >
                  <Stack justify="center">
                    <IconVideo size={24} stroke={2} />
                  </Stack>
                </Badge>
              )}
              {chapter.questions && chapter.questions.length > 0 && (
                <Badge
                  color="blue"
                  variant="light"
                  aria-label="video"
                  size="xl"
                  rightSection={
                    <Badge variant="outline" bg="white">
                      {chapter.questions.length}
                    </Badge>
                  }
                >
                  <Stack justify="center">
                    <IconQuestionMark size={20} stroke={2} />
                  </Stack>
                </Badge>
              )}
            </Group>
          </Group>
        </>
      ) : (
        <Group justify="flex-end" gap="xs" w="100%">
          <Button
            onClick={handleRemove}
            variant="subtle"
            color="red"
            leftSection={<IconTrash size={20} />}
          >
            Delete Chapter
          </Button>
        </Group>
      )}
    </Group>
  );
};

export default ChapterSummary;
