/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  JobInformationResponse,
  PersonImportSubmitRequest
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const submitPersonImport = (
    personImportSubmitRequest: PersonImportSubmitRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/batch/imports/person-import/submit`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: personImportSubmitRequest
    },
      options);
    }
  


export const getSubmitPersonImportMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitPersonImport>>, TError,{data: PersonImportSubmitRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitPersonImport>>, TError,{data: PersonImportSubmitRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitPersonImport>>, {data: PersonImportSubmitRequest}> = (props) => {
          const {data} = props ?? {};

          return  submitPersonImport(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitPersonImportMutationResult = NonNullable<Awaited<ReturnType<typeof submitPersonImport>>>
    export type SubmitPersonImportMutationBody = PersonImportSubmitRequest
    export type SubmitPersonImportMutationError = unknown

    export const useSubmitPersonImport = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitPersonImport>>, TError,{data: PersonImportSubmitRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitPersonImport>>,
        TError,
        {data: PersonImportSubmitRequest},
        TContext
      > => {

      const mutationOptions = getSubmitPersonImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getJobById = (
    jobId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<JobInformationResponse>(
      {url: `/api/batch/imports/jobs/${jobId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetJobByIdQueryKey = (jobId: number,) => {
    return [`/api/batch/imports/jobs/${jobId}`] as const;
    }

    
export const getGetJobByIdQueryOptions = <TData = Awaited<ReturnType<typeof getJobById>>, TError = unknown>(jobId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJobByIdQueryKey(jobId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobById>>> = ({ signal }) => getJobById(jobId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(jobId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getJobById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetJobByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getJobById>>>
export type GetJobByIdQueryError = unknown



export function useGetJobById<TData = Awaited<ReturnType<typeof getJobById>>, TError = unknown>(
 jobId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetJobByIdQueryOptions(jobId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getJobsByUser = (
    userId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<JobInformationResponse[]>(
      {url: `/api/batch/imports/jobs/users/${userId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetJobsByUserQueryKey = (userId: number,) => {
    return [`/api/batch/imports/jobs/users/${userId}`] as const;
    }

    
export const getGetJobsByUserQueryOptions = <TData = Awaited<ReturnType<typeof getJobsByUser>>, TError = unknown>(userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobsByUser>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJobsByUserQueryKey(userId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobsByUser>>> = ({ signal }) => getJobsByUser(userId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getJobsByUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetJobsByUserQueryResult = NonNullable<Awaited<ReturnType<typeof getJobsByUser>>>
export type GetJobsByUserQueryError = unknown



export function useGetJobsByUser<TData = Awaited<ReturnType<typeof getJobsByUser>>, TError = unknown>(
 userId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobsByUser>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetJobsByUserQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getJobsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<JobInformationResponse[]>(
      {url: `/api/batch/imports/jobs/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetJobsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/batch/imports/jobs/organizations/${organizationId}`] as const;
    }

    
export const getGetJobsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getJobsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJobsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getJobsByOrganization>>> = ({ signal }) => getJobsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getJobsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetJobsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getJobsByOrganization>>>
export type GetJobsByOrganizationQueryError = unknown



export function useGetJobsByOrganization<TData = Awaited<ReturnType<typeof getJobsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJobsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetJobsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




