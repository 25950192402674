import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import { WorkgroupTaskStatsView } from '../../components/WorkgroupTaskStatsView/WorkgroupTaskStatsView';
import styles from './TodosPage.module.css';

const TodosPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <WorkgroupTaskStatsView
                workflowType={WorkflowTypeEnum.taskList}
              />
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Stack>
  );
};

export default TodosPage;
