// import { useAPIClient } from '@btrway/api-client-provider';
// import {
//   CurrentUserResponse,
//   CurrentUserWorkgroupMemberResponse,
//   OrganizationResponse,
//   PersonResponse,
//   WorkgroupResponse,
//   getGetCurrentUserQueryKey,
//   useGetCurrentUser,
// } from '@btrway/api-core';
// import { useAuthContext } from '@btrway/auth-core';
// import {
//   ReactNode,
//   createContext,
//   useContext,
//   useEffect,
//   useMemo,
//   useRef,
// } from 'react';

// type CurrentUserContextValue = {
//   currentUser: CurrentUserResponse;
//   currentPerson: PersonResponse;
//   currentOrganization: OrganizationResponse;
//   rootWorkgroupId: number;
//   workgroupMembers: CurrentUserWorkgroupMemberResponse[];
//   rootWorkgroup: WorkgroupResponse | undefined;
//   isLoading: boolean;
//   refetchCurrentUser: () => Promise<unknown>;
//   allPermissions: Set<string>;
// };

// const CurrentUserContext = createContext<CurrentUserContextValue | null>(null);

// interface CurrentUserProviderProps {
//   children: ReactNode;
//   loadingComponent?: ReactNode;
// }

// export function CurrentUserProvider({
//   children,
//   loadingComponent = <div>Loading...</div>,
// }: CurrentUserProviderProps) {
//   const { authState } = useAuthContext();
//   const { queryClient } = useAPIClient();
//   const mountedRef = useRef(true);

//   // Cleanup on unmount
//   useEffect(() => {
//     return () => {
//       console.log('Unmounting CurrentUserProvider');
//       mountedRef.current = false;
//       queryClient.clear();
//     };
//   }, [queryClient]);

//   const query = useGetCurrentUser({
//     query: {
//       enabled: authState.status === 'authenticated' && mountedRef.current,
//       queryKey: getGetCurrentUserQueryKey(),
//       select: (data) => {
//         if (!data) {
//           throw new Error('Current user data is undefined');
//         }
//         return data;
//       },
//       staleTime: 5 * 60 * 1000,
//       retry: (failureCount, error) => {
//         if (
//           error instanceof Error &&
//           'status' in error &&
//           (error.status === 401 || error.status === 403)
//         ) {
//           return false;
//         }
//         return failureCount < 3;
//       },
//     },
//   });

//   // Declare all memoized values, but make them conditional on having data
//   const rootWorkgroup = useMemo(
//     () =>
//       query.data?.workgroupMembers.find(
//         (member) => member.workgroup.id === query.data?.rootWorkgroupId
//       )?.workgroup,
//     [query.data]
//   );

//   const allPermissions = useMemo(
//     () =>
//       query.data
//         ? new Set<string>(
//             query.data.workgroupMembers.flatMap(
//               (member) => member.permissionCodes
//             )
//           )
//         : new Set<string>(),
//     [query.data]
//   );

//   const contextValue = useMemo(() => {
//     if (!query.data) {
//       return null;
//     }

//     console.log('Creating context value, data:', query.data);

//     return {
//       currentUser: query.data,
//       currentPerson: query.data.person,
//       currentOrganization: query.data.organization,
//       rootWorkgroupId: query.data.rootWorkgroupId,
//       workgroupMembers: query.data.workgroupMembers,
//       rootWorkgroup,
//       isLoading: false,
//       refetchCurrentUser: query.refetch,
//       allPermissions,
//     } as CurrentUserContextValue;
//   }, [query.data, rootWorkgroup, allPermissions, query.refetch]);

//   // Refetch when impersonation state changes
//   useEffect(() => {
//     console.log('Impersonation state changed');
//     if (authState.status === 'authenticated') {
//       console.log('Refetching current user');
//       query.refetch();
//     }
//   }, [authState.isAccessingOrg, authState.isAccessingPerson]);

//   // Clear cache on unmount
//   useEffect(() => {
//     return () => {
//       mountedRef.current = false;
//     };
//   }, []);

//   // Handle authentication states and loading after all hooks
//   if (authState.status === 'unauthenticated') {
//     throw new Error('Cannot access current user while unauthenticated');
//   }

//   if (authState.status === 'loading' || query.isLoading || !query.data) {
//     console.log('Current User is loading');
//     return <>{loadingComponent}</>;
//   }

//   if (query.error) {
//     throw query.error;
//   }

//   // We know contextValue is non-null here because query.data exists
//   return (
//     <CurrentUserContext.Provider value={contextValue!}>
//       {children}
//     </CurrentUserContext.Provider>
//   );
// }

// export function useCurrentUser(): CurrentUserContextValue {
//   const context = useContext(CurrentUserContext);
//   if (!context) {
//     throw new Error('useCurrentUser must be used within a CurrentUserProvider');
//   }
//   return context;
// }

import { useAPIClient } from '@btrway/api-client-provider';
import { useAuthContext } from '@btrway/auth-core';
import { ReactNode, createContext, useEffect, useRef } from 'react';
import { useCurrentUserQuery } from '../hooks/useCurrentUserQuery';
import { CurrentUserContextValue } from '../types/currentUserTypes';

export const CurrentUserContext = createContext<CurrentUserContextValue | null>(
  null
);

interface CurrentUserProviderProps {
  children: ReactNode;
  loadingComponent?: ReactNode;
}

export function CurrentUserProvider({
  children,
  loadingComponent = <div>Loading...</div>,
}: CurrentUserProviderProps) {
  const { authState } = useAuthContext();
  const { queryClient } = useAPIClient();
  const mountedRef = useRef(true);
  const query = useCurrentUserQuery();

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      console.log('Unmounting CurrentUserProvider');
      mountedRef.current = false;
      queryClient.clear();
    };
  }, [queryClient]);

  // Handle authentication states and loading
  if (authState.status === 'unauthenticated') {
    throw new Error('Cannot access current user while unauthenticated');
  }

  if (authState.status === 'loading' || query.isLoading || !query.data) {
    return <>{loadingComponent}</>;
  }

  if (query.error) {
    throw query.error;
  }

  const contextValue: CurrentUserContextValue = {
    currentUser: query.data,
    currentPerson: query.data.person,
    currentOrganization: query.data.organization,
    rootWorkgroupId: query.data.rootWorkgroupId,
    workgroupMembers: query.data.workgroupMembers,
    rootWorkgroup: query.rootWorkgroup,
    isLoading: false,
    refetchCurrentUser: query.refetch,
    allPermissions: query.allPermissions,
  };

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {children}
    </CurrentUserContext.Provider>
  );
}
