/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskAssignmentFrequencyEnum = typeof TaskAssignmentFrequencyEnum[keyof typeof TaskAssignmentFrequencyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskAssignmentFrequencyEnum = {
  yearly: 'yearly',
} as const;
