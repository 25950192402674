import { PublicRegistrationFormResponse } from '@btrway/api-core';
import { useEffect } from 'react';
import { useRegistrationFlow } from '../../hooks/useRegistrationFlow';
import { useRegistrationProvider } from '../../hooks/useRegistrationProvider';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import RegistrationConfirmation from '../RegistrationConfirmation/RegistrationConfirmation';
import { RegistrationFormLayout } from '../RegistrationFormLayout/RegistrationFormLayout';
import { RegistrationSidebar } from '../RegistrationSidebar/RegistrationSidebar';
import { RegistrationStepContent } from '../RegistrationStepContent/RegistrationStepContent';

interface RegistrationFormProps {
  workgroupId: number;
  publicFormRegistration: PublicRegistrationFormResponse;
  year?: number;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  workgroupId,
  publicFormRegistration,
  year,
}) => {
  const { isLoading, initialize } = useRegistrationProvider({
    workgroupId,
    publicFormRegistration,
    year,
  });

  const {
    steps,
    currentStep,
    isSubmitted,
    isSummaryReached,
    handleNext,
    handleFinish,
    handleSubmit,
    moveToStep,
  } = useRegistrationFlow();

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const ActiveComponent = steps[currentStep].component;

  return (
    <RegistrationFormLayout
      sidebar={<RegistrationSidebar steps={steps} onStepClick={moveToStep} />}
      content={
        isSubmitted ? (
          <RegistrationConfirmation />
        ) : (
          <RegistrationStepContent
            title={steps[currentStep].title}
            isLastStep={currentStep === steps.length - 1}
            isSummaryReached={isSummaryReached}
            onNext={handleNext}
            onFinish={handleFinish}
            onSubmit={handleSubmit}
          >
            <ActiveComponent />
          </RegistrationStepContent>
        )
      }
    />
  );
};
