import {
  EntityTaskResponse,
  TaskOptionTaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { useAtom } from 'jotai';
import { taskInstancesAtom } from '../atoms/taskInstancesAtoms';

/**
 * Hook to handle updating parent task options after child task completion
 */
export const useTaskOptionCompletion = () => {
  const [tasks, setTasks] = useAtom(taskInstancesAtom);

  const updateParentTaskOptionAfterCompletion = (
    completedTask: EntityTaskResponse
  ) => {
    if (!completedTask.parentWorkflowKey) {
      return;
    }

    setTasks((currentTasks) =>
      currentTasks.map((task) => {
        if (
          task.taskType === TaskTypeEnum.completeTaskOption &&
          task.taskProperties &&
          !task.completed
        ) {
          const properties = task.taskProperties as TaskOptionTaskProperties;
          if (
            properties.workflowSelection?.workflowDefinitionKey?.value ===
            completedTask.parentWorkflowKey
          ) {
            return {
              ...task,
              completed: true,
              completedAt: new Date().toISOString(),
            };
          }
        }
        return task;
      })
    );
  };

  return { updateParentTaskOptionAfterCompletion };
};
