import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';
import OtherRelatedPersonInfo from '../OtherRelatedPersonInfo/OtherRelatedPersonInfo';

const OtherRelatedPeopleInfo: React.FC = () => {
  const { registration, addOtherRelatedPerson, removePerson } =
    useRegistrationSubmission();

  const otherRelatedPeople = registration.persons.filter(
    (person) =>
      !person.isDependent &&
      person.personKey !== registration.registrantPersonKey
  );

  const handleDeletePerson = (personKey: string) => {
    removePerson(personKey);
  };

  return (
    <Stack gap="md">
      <Card withBorder radius="sm" padding="sm" shadow="none" bg="yellow.1">
        <Text size="lg" fw={500}>
          Please enter other related people you would like to have access and
          receive communications.
        </Text>
      </Card>
      {otherRelatedPeople.map((person, index) => (
        <OtherRelatedPersonInfo
          key={person.personKey}
          personKey={person.personKey}
          personIndex={index + 1}
          onDelete={handleDeletePerson}
        />
      ))}
      <Group justify="center">
        <Button variant="light" onClick={addOtherRelatedPerson}>
          {otherRelatedPeople.length === 0
            ? 'Do you want to add additional related people?'
            : 'Add Another Related Person'}
        </Button>
      </Group>
    </Stack>
  );
};

export default OtherRelatedPeopleInfo;
