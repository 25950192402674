import { useAuthenticatedUser } from '@btrway/current-user';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import {
  initializationAttemptedAtom,
  isTaskManagerInitializedAtom,
  taskManagerErrorAtom,
} from '../atoms/initializationAtoms';
import { useTaskFetcher } from './useTaskFetcher';

// Create a stable reference for tracking initialization state across all instances
const globalInitState = {
  isInitializing: false,
  currentPersonId: undefined as number | undefined,
  currentOrgId: undefined as number | undefined,
};

export const useInitializeTaskManager = () => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();

  const { fetchTaskAssignments } = useTaskFetcher();
  const [isInitialized, setIsInitialized] = useAtom(
    isTaskManagerInitializedAtom
  );
  const [error, setError] = useAtom(taskManagerErrorAtom);
  const [attempted, setAttempted] = useAtom(initializationAttemptedAtom);

  const needsInitialization = Boolean(
    currentPerson.id &&
      currentOrganization.id &&
      !globalInitState.isInitializing &&
      !isInitialized &&
      (globalInitState.currentPersonId !== currentPerson.id ||
        globalInitState.currentOrgId !== currentOrganization.id)
  );

  const initialize = useCallback(async () => {
    if (!needsInitialization) return;

    // Set initializing state immediately
    globalInitState.isInitializing = true;
    setAttempted(true);

    try {
      if (!currentPerson.id || !currentOrganization.id) {
        throw new Error('Missing required user context for initialization');
      }

      const result = await fetchTaskAssignments({
        onSuccess: () => {
          setIsInitialized(true);
          setError(null);
          // Update global state
          globalInitState.currentPersonId = currentPerson.id;
          globalInitState.currentOrgId = currentOrganization.id;
        },
        onError: (error) => {
          console.error('Failed to initialize task manager:', error);
          setError(error as Error);
          setIsInitialized(true);
        },
      });

      return result;
    } catch (err) {
      console.error('Failed to initialize task manager:', err);
      setError(err instanceof Error ? err : new Error('Unknown error'));
      setIsInitialized(true);
    } finally {
      globalInitState.isInitializing = false;
    }
  }, [
    needsInitialization,
    attempted,
    isInitialized,
    currentPerson.id,
    currentOrganization.id,
    fetchTaskAssignments,
    setIsInitialized,
    setError,
    setAttempted,
  ]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      initialize();
    }

    return () => {
      mounted = false;
    };
  }, [
    initialize,
    currentPerson.id,
    currentOrganization.id,
    needsInitialization,
  ]);

  return {
    isInitialized,
    error,
    isReady: Boolean(currentPerson.id && currentOrganization.id),
    isInitializing: globalInitState.isInitializing,
  };
};
