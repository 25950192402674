import { AssetResponse } from '@btrway/api-courseware';
import { StorageUsageEnum } from '@btrway/api-storage';
import { useImageUpload } from '@btrway/file-storage-components';
import { Badge, Box, Card, Group, Image, Text, ThemeIcon } from '@mantine/core';
import {
  IconArchive,
  IconCheck,
  IconClockPause,
  IconQuestionMark,
  IconStack,
  IconVideo,
} from '@tabler/icons-react';
import React from 'react';
import { Link } from 'react-router-dom';

interface CourseCardProps {
  course: AssetResponse;
}

const CourseCard: React.FC<CourseCardProps> = ({ course }) => {
  const { metadata, title, chapters } = course;

  const imageKey = metadata?.imageKey;

  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.course,
    initialImageKey: imageKey,
  });

  const chapterCount = chapters?.length || 0;
  const videoCount =
    chapters?.filter((chapter) => chapter.videoPlaybackIdentifier).length || 0;
  const questionCount =
    chapters?.reduce(
      (sum, chapter) => sum + (chapter.questions?.length || 0),
      0
    ) || 0;

  return (
    <Link to={`${course.id}`} style={{ textDecoration: 'none' }}>
      <Card
        shadow="sm"
        padding="xs"
        withBorder={true}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 180,
          height: 150,
          position: 'relative',
        }}
      >
        <Image
          src={imageUrl}
          height={60}
          alt={course.title}
          fallbackSrc="https://placehold.co/600x400?text=No+Course+Image"
        />
        <Text
          size="xs"
          fw={500}
          style={{
            textAlign: 'center',
            marginTop: '8px',
            marginBottom: '24px',
          }}
          lineClamp={2}
        >
          {title}
        </Text>
        <Box
          style={{
            position: 'absolute',
            bottom: 8,
            right: 8,
          }}
        >
          <Group gap={4} wrap="nowrap">
            {chapterCount > 0 && (
              <Badge
                leftSection={<IconStack size={14} />}
                color="blue"
                variant="light"
                aria-label="chapters"
              >
                {chapterCount}
              </Badge>
            )}
            {videoCount > 0 && (
              <Badge
                leftSection={<IconVideo size={14} />}
                color="blue"
                variant="light"
                aria-label="videos"
              >
                {videoCount}
              </Badge>
            )}
            {questionCount > 0 && (
              <Badge
                leftSection={<IconQuestionMark size={14} />}
                color="blue"
                variant="light"
                aria-label="quiz questions"
              >
                {questionCount}
              </Badge>
            )}
          </Group>
        </Box>
        <ThemeIcon
          color={
            course.published && course.currentPublishedVersion
              ? 'green'
              : course.published
              ? 'gray'
              : 'yellow'
          }
          size="md"
          radius="xl"
          style={{ position: 'absolute', top: 2, right: 2 }}
        >
          {course.published && course.currentPublishedVersion ? (
            <IconCheck size={10} stroke={4} />
          ) : course.published ? (
            <IconArchive size={10} stroke={4} />
          ) : (
            <IconClockPause size={10} stroke={4} />
          )}
        </ThemeIcon>
      </Card>
    </Link>
  );
};

export default CourseCard;
