import { HelpResourceResponse } from '@btrway/api-help';
import { Card, Text } from '@mantine/core';
import React from 'react';

interface HelpResourceSummaryCardProps {
  helpResource: HelpResourceResponse;
  onClick: (helpResource: HelpResourceResponse) => void;
}

const HelpResourceSummaryCard: React.FC<HelpResourceSummaryCardProps> = ({
  helpResource,
  onClick,
}) => {
  return (
    <Card
      shadow="xs"
      padding="md"
      withBorder
      onClick={() => onClick(helpResource)}
      style={{ cursor: 'pointer' }}
    >
      <Text>{helpResource.metadata?.title}</Text>
    </Card>
  );
};

export default HelpResourceSummaryCard;
