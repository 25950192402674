import { useAuthenticatedUser } from '@btrway/current-user';
import { useImpersonation } from '@btrway/impersonation';
import { Box, Button, Group, Text } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';

export const ImpersonationBanner = () => {
  const { currentOrganization, currentPerson } = useAuthenticatedUser();
  const {
    stopOrgImpersonation,
    stopPersonImpersonation,
    isImpersonatingOrg,
    isImpersonatingPerson,
  } = useImpersonation();

  if (!isImpersonatingOrg && !isImpersonatingPerson) return null;

  return (
    <Box
      bg="#A71021"
      p={4}
      style={{
        width: '100%',
      }}
    >
      <Group justify="center" wrap="nowrap">
        <Group gap="xs">
          {isImpersonatingPerson ? (
            <>
              <Text fz="sm" fw={600} c="white">
                {currentPerson?.firstName} {currentPerson?.lastName}
              </Text>
              <Text fz="sm" fw={500} c="gray.4">
                Member of {currentOrganization?.name}
              </Text>
            </>
          ) : (
            <>
              <Text fz="sm" fw={600} c="white">
                {currentOrganization?.name}
              </Text>
              {currentOrganization?.physicalAddress && (
                <Text fz="sm" fw={500} c="gray.4">
                  {currentOrganization.physicalAddress.city},{' '}
                  {currentOrganization.physicalAddress.stateProvinceCode}
                </Text>
              )}
            </>
          )}
        </Group>
        <Button
          variant="outline"
          size="compact-xs"
          bg="red.7"
          c="white"
          onClick={
            isImpersonatingPerson
              ? stopPersonImpersonation
              : stopOrgImpersonation
          }
          rightSection={<IconLogout size="1rem" stroke={1.5} />}
        >
          {isImpersonatingPerson ? 'Stop Impersonating' : 'Exit Organization'}
        </Button>
      </Group>
    </Box>
  );
};
