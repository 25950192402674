import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import {
  IncrementalProgressSaver,
  useUpdateChapterProgress,
} from '@btrway/courseware-progress-manager';
import { ActionIcon } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef } from 'react';
import { VideoTimelineLocation } from '../../types/videoTimelineLocation';
import ChapterList from '../ChapterList/ChapterList';
import ChapterTimeline from '../ChapterTimeline/ChapterTimeline';
import StyledMuxPlayer from '../StyledMuxPlayer/StyledMuxPlayer';
import styles from './VideoViewer.module.css';

interface VideoViewerProps {
  course: AssetResponse;
  initialChapter: ChapterDTO;
  initialCurrentTime?: number;
  initialMaxTime?: number;
  timelineLocation?: VideoTimelineLocation;
  onVideoEnd: () => void;
  onBack: () => void;
  onChapterSelect?: (chapterKey: string) => void;
}

function throttle<T extends (...args: any[]) => void>(
  func: T,
  limit: number
): (...args: Parameters<T>) => void {
  let inThrottle = false;
  return function (this: any, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

const VideoViewer: React.FC<VideoViewerProps> = ({
  course,
  initialChapter,
  initialCurrentTime = 0,
  initialMaxTime = 0,
  timelineLocation = VideoTimelineLocation.NONE,
  onVideoEnd,
  onBack,
  onChapterSelect,
}) => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const timelineWrapperRef = useRef<HTMLDivElement>(null);
  const controlsTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isPlayingRef = useRef(false);
  const isMouseOverTimelineRef = useRef(false);

  const { updateChapterProgress } = useUpdateChapterProgress(
    initialChapter.key
  );

  const handleMouseMove = useCallback(
    throttle(() => {
      if (videoWrapperRef.current) {
        videoWrapperRef.current.classList.add(styles.showControls);
      }
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
      controlsTimeoutRef.current = setTimeout(() => {
        if (
          isPlayingRef.current &&
          videoWrapperRef.current &&
          !isMouseOverTimelineRef.current
        ) {
          videoWrapperRef.current.classList.remove(styles.showControls);
        }
      }, 2000);
    }, 100),
    []
  );

  const handleMouseLeave = useCallback(() => {
    if (
      isPlayingRef.current &&
      !isMouseOverTimelineRef.current &&
      videoWrapperRef.current
    ) {
      videoWrapperRef.current.classList.remove(styles.showControls);
    }
  }, []);

  const handleTimelineMouseEnter = useCallback(() => {
    isMouseOverTimelineRef.current = true;
    if (videoWrapperRef.current) {
      videoWrapperRef.current.classList.add(styles.showControls);
    }
  }, []);

  const handleTimelineMouseLeave = useCallback(() => {
    isMouseOverTimelineRef.current = false;
    if (isPlayingRef.current && videoWrapperRef.current) {
      videoWrapperRef.current.classList.remove(styles.showControls);
    }
  }, []);

  useEffect(() => {
    const wrapper = videoWrapperRef.current;
    const timeline = timelineWrapperRef.current;

    if (wrapper && timeline) {
      wrapper.addEventListener('mousemove', handleMouseMove);
      wrapper.addEventListener('mouseleave', handleMouseLeave);
      timeline.addEventListener('mouseenter', handleTimelineMouseEnter);
      timeline.addEventListener('mouseleave', handleTimelineMouseLeave);

      return () => {
        wrapper.removeEventListener('mousemove', handleMouseMove);
        wrapper.removeEventListener('mouseleave', handleMouseLeave);
        timeline.removeEventListener('mouseenter', handleTimelineMouseEnter);
        timeline.removeEventListener('mouseleave', handleTimelineMouseLeave);
      };
    }
  }, [
    handleMouseMove,
    handleMouseLeave,
    handleTimelineMouseEnter,
    handleTimelineMouseLeave,
  ]);

  const handlePlayingStateChange = useCallback((isPlaying: boolean) => {
    isPlayingRef.current = isPlaying;
  }, []);

  const handleTimeUpdate = useCallback(
    (currentTime: number, maxTime: number) => {
      updateChapterProgress({
        currentTimeSeconds: currentTime,
        maxTimeSeconds: maxTime,
      });
    },
    [updateChapterProgress, initialChapter.key]
  );

  const handleEnded = useCallback(() => {
    onVideoEnd();
  }, [onVideoEnd]);

  const videoPlaybackIdentifier = initialChapter.videoPlaybackIdentifier;

  const renderTimelineComponent = useCallback(() => {
    if (timelineLocation === VideoTimelineLocation.NONE) {
      return null;
    }

    const commonProps = {
      course,
      currentChapter: initialChapter.key,
      onChapterSelect: onChapterSelect || ((chapterKey: string) => {}),
    };

    const wrapperClassName = `${styles.courseTimelineWrapper} ${
      timelineLocation === VideoTimelineLocation.RIGHT
        ? styles.rightTimeline
        : timelineLocation === VideoTimelineLocation.BOTTOM
        ? styles.bottomTimeline
        : ''
    }`;

    return (
      <div className={wrapperClassName} ref={timelineWrapperRef}>
        {timelineLocation === VideoTimelineLocation.BOTTOM ? (
          <ChapterTimeline {...commonProps} />
        ) : (
          <ChapterList {...commonProps} />
        )}
      </div>
    );
  }, [course, timelineLocation, initialChapter.key, onChapterSelect]);

  return (
    <>
      <div className={styles.videoWrapper} ref={videoWrapperRef}>
        <div className={styles.videoContainer}>
          {videoPlaybackIdentifier && (
            <StyledMuxPlayer
              playbackId={videoPlaybackIdentifier}
              onEnded={handleEnded}
              onTimeChange={handleTimeUpdate}
              onPlayingStateChange={handlePlayingStateChange}
              hideDefaultTimeline={
                timelineLocation === VideoTimelineLocation.BOTTOM
              }
              initialCurrentTime={initialCurrentTime}
              initialMaxTime={initialMaxTime}
              preventSeekBeyondMaxTime={false}
            />
          )}
          <ActionIcon
            size="xl"
            variant="transparent"
            color="white"
            className={styles.backButton}
            onClick={onBack}
          >
            <IconArrowLeft size={32} />
          </ActionIcon>
          {renderTimelineComponent()}
        </div>
      </div>
      <IncrementalProgressSaver chapter={initialChapter} frequencySeconds={5} />
    </>
  );
};

export default VideoViewer;
