import type {
  CertificationConfig,
  CertificationTaskProperties,
  CurriculumTaskProperties,
  FileUploadTaskProperties,
  FormTaskProperties,
  StepConfig,
  StepInputProperties,
  TaskConfig,
  TaskListTaskProperties,
  TaskOptionTaskProperties,
  TaskProperties,
  WorkflowSelection,
} from '@btrway/api-workflow';

// Combined type for all properties that can have workflow selection
type PropertiesWithWorkflow =
  | FormTaskProperties
  | CurriculumTaskProperties
  | TaskListTaskProperties
  | TaskOptionTaskProperties
  | FileUploadTaskProperties
  | CertificationTaskProperties;

// Combined type guard to check if properties have workflow selection
function isPropertiesWithWorkflow(
  props: TaskProperties | StepInputProperties
): props is PropertiesWithWorkflow {
  return 'workflowSelection' in props;
}

// Type guard to check if task properties has workflow selection
export function hasTaskWorkflowSelection(
  properties: TaskProperties | undefined
): properties is PropertiesWithWorkflow {
  if (!properties) return false;
  return isPropertiesWithWorkflow(properties);
}

// Type guard to check if step config has workflow selection
export function hasStepWorkflowSelection(
  config: StepConfig
): config is StepConfig & {
  inputProperties: PropertiesWithWorkflow;
} {
  if (!config.inputProperties) return false;
  return isPropertiesWithWorkflow(config.inputProperties);
}

// Type guard to check if certification config has workflow selection
export function hasCertificationWorkflowSelection(
  config: CertificationConfig
): config is CertificationConfig & {
  taskProperties: PropertiesWithWorkflow;
} {
  if (!config.taskProperties) return false;
  return isPropertiesWithWorkflow(config.taskProperties);
}

// Helper to get workflow selection from task, step, or certification config
export function getWorkflowSelection(
  config: TaskConfig | StepConfig | CertificationConfig | undefined
): WorkflowSelection | undefined {
  if (!config) return undefined;

  // Handle step config
  if ('stepTypeCode' in config) {
    if (hasStepWorkflowSelection(config)) {
      return config.inputProperties.workflowSelection;
    }
    return undefined;
  }

  // Handle task config
  if ('taskType' in config && config.taskProperties) {
    if (hasTaskWorkflowSelection(config.taskProperties)) {
      return config.taskProperties.workflowSelection;
    }
  }

  // Handle certification config
  if ('certificationType' in config && config.taskProperties) {
    if (hasCertificationWorkflowSelection(config)) {
      return config.taskProperties.workflowSelection;
    }
  }

  return undefined;
}
