import { DataTypeEnum } from '@btrway/api-core';
import { useEffect, useRef, useState } from 'react';
import { useEntityDataLookup } from './useEntityDataLookup';

interface UseElementVisibilityOptions {
  threshold?: number;
  once?: boolean;
}

export const useElementVisibility = ({
  threshold = 0.1,
  once = true,
}: UseElementVisibilityOptions = {}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (once) {
            observer.disconnect();
          }
        } else if (!once) {
          setIsVisible(false);
        }
      },
      { threshold }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [threshold, once]);

  return { ref, isVisible };
};

// hooks/useEntityDataLookup.ts
// ... [previous useEntityDataLookup code remains the same] ...

/**
 * Hook that combines visibility tracking with data fetching
 */
export const useVisibleEntityData = <T = any>(
  dataType: keyof typeof DataTypeEnum,
  id: number,
  options?: UseElementVisibilityOptions
) => {
  const { ref, isVisible } = useElementVisibility(options);
  const query = useEntityDataLookup<T>(dataType, id);

  // Only return the query result if the element is visible
  const visibleQuery = isVisible
    ? query
    : {
        data: undefined,
        error: null,
        isLoading: true,
      };

  return { ref, ...visibleQuery };
};
