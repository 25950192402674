import { CourseSelection, TaskConfig } from '@btrway/api-workflow';
import { useCourses } from '@btrway/courseware-manager';
import React from 'react';
import { CourseSelectorField } from '../../../selector-components/CourseSelectorField/CourseSelectorField';
import { TaskConfigProps } from '../../../types/editors';
import { getCourseSelection } from '../../../utils/courseSelectionUtil';

export const TaskCourseConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const { courses, isLoading } = useCourses();
  const courseSelection = getCourseSelection(config);

  const selectedCourse = courses.find(
    (course) =>
      course.uid.toString() === courseSelection?.courseUid.value?.toString()
  );

  const handleCourseSelect = (
    newCourseSelection: CourseSelection,
    name: string
  ) => {
    // Get the current course name from the existing selection
    const currentCourseName = selectedCourse?.title;

    // Get the current title from metadata
    const currentTitle = config.metadata?.title || '';

    // Determine if we should update the title:
    // 1. If the current title is empty
    // 2. If the current title exactly matches the previous course name
    const shouldUpdateTitle =
      !currentTitle || currentTitle === currentCourseName;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        // Only update title if conditions are met
        title: shouldUpdateTitle ? name : config.metadata?.title,
      },
      taskProperties: {
        ...config.taskProperties,
        courseSelection: newCourseSelection,
      },
    };

    onConfigChange(updatedConfig);
  };

  if (isLoading) {
    return null; // Or return a loading indicator
  }

  return (
    <CourseSelectorField
      courseSelection={courseSelection}
      onCourseSelect={handleCourseSelect}
    />
  );
};
