import { UserRoleResponse } from '@btrway/api-security';
import { List, Text } from '@mantine/core';
import React from 'react';

interface UserRoleSettingsProps {
  userRole: UserRoleResponse;
}

const UserRoleSettings: React.FC<UserRoleSettingsProps> = ({ userRole }) => {
  return (
    <>
      <Text mb="xs">
        Cascades to lower levels of organization by default:{' '}
        {userRole.canCascade ? 'Yes' : 'No'}
      </Text>

      <Text mb="xs">Enabled Workgroup Types:</Text>
      <List>
        {/* {userRole.enabledWorkgroupTypes.map((type, index) => (
          <List.Item key={index}>{type}</List.Item>
        ))} */}
      </List>
    </>
  );
};

export default UserRoleSettings;
