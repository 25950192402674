import {
  ColorSchemeScript,
  MantineProvider,
  useMantineColorScheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useRef } from 'react';
import { colorSchemeAtom, forcedDarkModeAtom } from '../atoms/themeAtoms';
import { createAppTheme, ThemeMode } from '../utils/theme';
import { useAuthenticatedUser } from '@btrway/current-user';
import './ThemeSchemeProvider.css';

const InnerWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userColorScheme, setUserColorScheme] = useAtom(colorSchemeAtom);
  const [forcedDarkMode] = useAtom(forcedDarkModeAtom);
  const { setColorScheme } = useMantineColorScheme();
  const transitionTimeoutRef = useRef<number>();

  // Toggle user preference
  const toggleColorScheme = useCallback(() => {
    if (!forcedDarkMode) {
      // Add transition class before changing scheme
      document.documentElement.classList.add('theme-transition');
      setUserColorScheme((current) => (current === 'dark' ? 'light' : 'dark'));

      // Remove transition class after transition completes
      transitionTimeoutRef.current = window.setTimeout(() => {
        document.documentElement.classList.remove('theme-transition');
      }, 200) as unknown as number;
    }
  }, [forcedDarkMode, setUserColorScheme]);

  useHotkeys([['mod+J', toggleColorScheme]]);

  // Effect to update Mantine's color scheme based on both atoms
  useEffect(() => {
    const effectiveScheme = forcedDarkMode ? 'dark' : userColorScheme;

    // Add transition class before updating scheme
    document.documentElement.classList.add('theme-transition');
    setColorScheme(effectiveScheme);

    // Remove transition class after transition completes
    const timeout = window.setTimeout(() => {
      document.documentElement.classList.remove('theme-transition');
    }, 200);

    return () => {
      window.clearTimeout(timeout);
      if (transitionTimeoutRef.current) {
        window.clearTimeout(transitionTimeoutRef.current);
      }
      document.documentElement.classList.remove('theme-transition');
    };
  }, [forcedDarkMode, userColorScheme, setColorScheme]);

  return <>{children}</>;
};

export const ThemeSchemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentUser } = useAuthenticatedUser();
  const isInternal = currentUser?.user?.internal || false;
  const mode: ThemeMode = isInternal ? 'internal' : 'client';

  //This approach was not working, so went back to "auto" for color scheme, but don't know if we want that approach
  // const [userColorScheme] = useAtom(colorSchemeAtom);
  // const [forcedDarkMode] = useAtom(forcedDarkModeAtom);

  // const theme = createAppTheme(mode, forcedDarkMode ? 'dark' : userColorScheme);
  // const effectiveScheme = forcedDarkMode ? 'dark' : userColorScheme;

  const theme = createAppTheme(mode);

  return (
    <>
      <ColorSchemeScript defaultColorScheme="auto" />
      <MantineProvider theme={theme} defaultColorScheme="auto">
        <InnerWrapper>
          <div className={isInternal ? 'internal-site' : ''}>{children}</div>
        </InnerWrapper>
      </MantineProvider>
    </>
  );
};
