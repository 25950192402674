import {
  OutsideTaskDefinitionRequest,
  OutsideTaskDefinitionResponse,
} from '@btrway/api-workflow';
import { Stack } from '@mantine/core';
import { useMemo } from 'react';
import { OutsideTaskCard } from '../OutsideTaskCard/OutsideTaskCard';

interface OutsideTasksAdminViewProps {
  tasks: OutsideTaskDefinitionResponse[];
  onEditTask: (task: OutsideTaskDefinitionRequest) => void;
}

export const OutsideTasksAdminView: React.FC<OutsideTasksAdminViewProps> = ({
  tasks,
  onEditTask,
}) => {
  // Sort tasks by name
  const sortedTasks = useMemo(() => {
    return tasks
      .slice()
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
  }, [tasks]);

  return (
    <Stack gap="md">
      {sortedTasks.map((task) => (
        <OutsideTaskCard
          key={task.id}
          task={task}
          onEdit={() => onEditTask(task)}
        />
      ))}
    </Stack>
  );
};
