import {
  DataTypeEnum,
  PropertyReference,
  StepConfig,
  StepTypeDTO,
  StepTypeEnum,
  WorkflowConfig,
  WorkflowProperty,
} from '@btrway/api-workflow';
import { useDataTypes, useStepTypes } from '@btrway/workflow-manager';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { Question, SurveyModel } from 'survey-core';
import { workflowConfigurationAtom } from '../atoms/workflowConfigurationAtom';
import { mapQuestionTypeToDataType } from '../utils/mapQuestionTypeToDataType';

const getPreviousSteps = (
  workflowConfig: WorkflowConfig | null,
  stepKey: string
): StepConfig[] => {
  if (!workflowConfig) return [];

  const previousSteps: StepConfig[] = [];
  const visitedStepKeys = new Set<string>();

  const traverseSteps = (currentStepKey: string) => {
    if (visitedStepKeys.has(currentStepKey)) return;
    visitedStepKeys.add(currentStepKey);

    const incomingTransitions = workflowConfig.transitions?.filter(
      (transition) => transition.targetStepKey === currentStepKey
    );

    incomingTransitions?.forEach((transition) => {
      const sourceStep = workflowConfig.steps?.find(
        (step) => step.stepKey === transition.sourceStepKey
      );
      if (sourceStep && !visitedStepKeys.has(sourceStep.stepKey)) {
        previousSteps.push(sourceStep);
        traverseSteps(sourceStep.stepKey);
      }
    });
  };

  traverseSteps(stepKey);
  return previousSteps;
};

const getPropertiesFromSurvey = (step: StepConfig): PropertyReference[] => {
  const properties: PropertyReference[] = [];
  if (step.formConfig) {
    try {
      const survey = new SurveyModel(step.formConfig);
      survey.getAllQuestions().forEach((question: Question) => {
        const dataType = mapQuestionTypeToDataType(question);
        properties.push({
          stepKey: step.stepKey,
          propertyKey: question.name,
          label: question.title || question.name,
          dataType: dataType,
        });
      });
    } catch (error) {
      console.error(
        `Error parsing form configuration for step ${step.stepKey}:`,
        error
      );
    }
  }
  return properties;
};

const getPropertiesFromStepProperties = (
  step: StepConfig
): PropertyReference[] => {
  return (step.customProperties || []).map((prop: WorkflowProperty) => ({
    stepKey: step.stepKey,
    propertyKey: prop.key,
    label: prop.label,
    dataType: prop.dataType,
  }));
};

const getStepTypeProperties = (
  step: StepConfig,
  getStepType: (stepTypeEnum: StepTypeEnum) => StepTypeDTO | undefined
): PropertyReference[] => {
  if (!step.stepTypeCode) return [];

  const stepTypeDTO = getStepType(step.stepTypeCode);
  if (!stepTypeDTO) return [];

  return (stepTypeDTO.properties || []).map((prop: WorkflowProperty) => ({
    stepKey: step.stepKey,
    propertyKey: prop.key,
    label: `${step.title} - ${prop.label}`,
    dataType: prop.dataType,
  }));
};

const addDataTypeProperties = (
  properties: PropertyReference[],
  getDataType: (dataType: DataTypeEnum) => any
): PropertyReference[] => {
  const allProperties: PropertyReference[] = [];
  properties.forEach((prop) => {
    allProperties.push(prop);

    const dataTypeDetails = getDataType(prop.dataType);
    if (dataTypeDetails?.properties) {
      dataTypeDetails.properties.forEach((subProp: any) => {
        allProperties.push({
          stepKey: prop.stepKey,
          propertyKey: `${prop.propertyKey}`,
          label: `${prop.label} - ${subProp.name}`,
          dataType: prop.dataType,
          entityProperty: subProp.key,
        });
      });
    }
  });
  return allProperties;
};

export const usePreviousStepProperties = (
  stepKey: string
): PropertyReference[] => {
  const [workflowConfig] = useAtom(workflowConfigurationAtom);
  const { getDataType } = useDataTypes();
  const { getStepType } = useStepTypes();

  return useMemo(() => {
    if (!workflowConfig?.steps) return [];

    const previousSteps = getPreviousSteps(workflowConfig, stepKey);

    const allProperties = previousSteps.flatMap((step) => {
      const surveyProperties = getPropertiesFromSurvey(step);
      const stepProperties = getPropertiesFromStepProperties(step);
      const stepTypeProperties = getStepTypeProperties(step, getStepType);

      const combinedProperties = [
        ...surveyProperties,
        ...stepProperties,
        ...stepTypeProperties,
      ];

      return addDataTypeProperties(combinedProperties, getDataType);
    });

    return allProperties;
  }, [workflowConfig, stepKey, getDataType, getStepType]);
};
