/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type DerivedTaskTypeEnum = typeof DerivedTaskTypeEnum[keyof typeof DerivedTaskTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DerivedTaskTypeEnum = {
  certification: 'certification',
  certificationRequirement: 'certificationRequirement',
  taskList: 'taskList',
  task: 'task',
  taskOption: 'taskOption',
  none: 'none',
} as const;
