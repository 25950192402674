import {
  CertificationRequiredTypeEnum,
  TimeUnitEnum,
} from '@btrway/api-workflow';
import {
  Chip,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { CertificationConfigProps } from '../../../types/editors';

const TIME_UNIT_OPTIONS = [
  { value: TimeUnitEnum.day, label: 'days' },
  { value: TimeUnitEnum.month, label: 'months' },
  { value: TimeUnitEnum.year, label: 'years' },
];

const PROPERTY_OPTIONS = [
  { value: 'hire', label: 'date of hire' },
  { value: 'workgroup', label: 'addition to workgroup' },
];

export const CertificationRequiredConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const handleRequiredTypeChange = (value: string) => {
    onConfigChange({
      ...config,
      certificationRequiredConfig: {
        requiredType: value as CertificationRequiredTypeEnum,
        timeValue: config.certificationRequiredConfig?.timeValue ?? 0,
        timeUnit:
          config.certificationRequiredConfig?.timeUnit ?? TimeUnitEnum.day,
        property: config.certificationRequiredConfig?.property ?? 'hire',
      },
    });
  };

  const handleTimeValueChange = (value: string | number) => {
    const numValue = typeof value === 'string' ? parseInt(value) : value;

    onConfigChange({
      ...config,
      certificationRequiredConfig: {
        requiredType:
          config.certificationRequiredConfig?.requiredType ??
          CertificationRequiredTypeEnum.withinTimeframe,
        timeValue: numValue,
        timeUnit:
          config.certificationRequiredConfig?.timeUnit ?? TimeUnitEnum.day,
        property: config.certificationRequiredConfig?.property ?? 'hire',
      },
    });
  };

  const handleTimeUnitChange = (value: string | null) => {
    if (value === null) return;

    onConfigChange({
      ...config,
      certificationRequiredConfig: {
        requiredType:
          config.certificationRequiredConfig?.requiredType ??
          CertificationRequiredTypeEnum.withinTimeframe,
        timeValue: config.certificationRequiredConfig?.timeValue ?? 0,
        timeUnit: value as TimeUnitEnum,
        property: config.certificationRequiredConfig?.property ?? 'hire',
      },
    });
  };

  const handleRelativeToChange = (value: string | null) => {
    if (value === null) return;

    onConfigChange({
      ...config,
      certificationRequiredConfig: {
        requiredType:
          config.certificationRequiredConfig?.requiredType ??
          CertificationRequiredTypeEnum.withinTimeframe,
        timeValue: config.certificationRequiredConfig?.timeValue ?? 0,
        timeUnit:
          config.certificationRequiredConfig?.timeUnit ?? TimeUnitEnum.day,
        property: value,
      },
    });
  };

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Title order={4}>Grace Period</Title>
        <Text size="sm" c="dimmed">
          Specify whether this item is required immediately for certification or
          within a timeframe.
        </Text>
        <Chip.Group
          multiple={false}
          value={config.certificationRequiredConfig?.requiredType || ''}
          onChange={handleRequiredTypeChange}
        >
          <Group gap="xs">
            <Chip value={CertificationRequiredTypeEnum.yes}>Required Now</Chip>
            <Chip value={CertificationRequiredTypeEnum.withinTimeframe}>
              Within timeframe
            </Chip>
          </Group>
        </Chip.Group>
      </Stack>

      {config.certificationRequiredConfig?.requiredType ===
        CertificationRequiredTypeEnum.withinTimeframe && (
        <Group align="center" gap="xs">
          <Text>Required within</Text>
          <NumberInput
            size="sm"
            w={70}
            value={config.certificationRequiredConfig?.timeValue ?? 0}
            onChange={handleTimeValueChange}
            min={0}
            styles={{ input: { textAlign: 'center' } }}
            hideControls
          />
          <Select
            size="sm"
            w={100}
            value={
              config.certificationRequiredConfig?.timeUnit ?? TimeUnitEnum.day
            }
            onChange={handleTimeUnitChange}
            data={TIME_UNIT_OPTIONS}
          />
          <Text>from the person's</Text>
          <Select
            size="sm"
            value={config.certificationRequiredConfig?.property ?? 'hire'}
            onChange={handleRelativeToChange}
            data={PROPERTY_OPTIONS}
          />
          <Text color="dimmed" size="sm">
            Until that date, overall certification can be met without this
            requirement.
          </Text>
        </Group>
      )}
    </Stack>
  );
};
