import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { Badge, Card, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { atom, useAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router';
import { useGlobalSearch } from '../../hooks/useGlobalSearch';
import {
  getEntityPath,
  getEntityTypeColor,
  getEntityTypeLabel,
} from '../../utils/entityUtils';

// Define Jotai atoms for persisting state
const selectedItemsAtom = atom<GlobalSearchResponse[]>([]);

interface GlobalSearchSideBarProps {
  onSelect: (entityType: DataTypeEnum, entityId: number) => void;
}

interface SearchResultCardProps {
  result: GlobalSearchResponse;
  onClick: (result: GlobalSearchResponse) => void;
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  result,
  onClick,
}) => (
  <Card
    shadow="sm"
    padding="sm"
    radius="md"
    withBorder
    onClick={() => onClick(result)}
    style={{ cursor: 'pointer' }}
  >
    <div className="flex justify-between items-start">
      <div className="flex flex-col">
        <Text fw={500}>{result.displayName}</Text>
        <Badge
          color={getEntityTypeColor(result.entityType as DataTypeEnum)}
          size="sm"
          variant="light"
        >
          {getEntityTypeLabel(result.entityType as DataTypeEnum)}
        </Badge>
      </div>
      <Text size="xs" c="dimmed">
        {Math.round(result.similarity * 100)}% match
      </Text>
    </div>
  </Card>
);

const GlobalSearchSideBar: React.FC<GlobalSearchSideBarProps> = ({
  onSelect,
}) => {
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useAtom(selectedItemsAtom);

  const { searchValue, setSearchValue, searchResults } = useGlobalSearch({
    initialResults: selectedItems,
    showInitialResultsOnEmpty: true,
  });

  const handleEntitySelect = (entity: GlobalSearchResponse) => {
    setSelectedItems((prevItems) => {
      if (
        !prevItems.some(
          (item) =>
            item.entityType === entity.entityType &&
            item.entityId === entity.entityId
        )
      ) {
        return [entity, ...prevItems.slice(0, 4)]; // Keep only the 5 most recent items
      }
      return prevItems;
    });
    onSelect(entity.entityType as DataTypeEnum, entity.entityId);
    navigate(getEntityPath(entity.entityType as DataTypeEnum, entity.entityId));
  };

  return (
    <Stack style={{ height: '100%' }}>
      <TextInput
        placeholder="Search..."
        value={searchValue}
        onChange={(event) => setSearchValue(event.currentTarget.value)}
        leftSection={<IconSearch size={18} stroke={1.5} />}
        autoFocus
      />
      <ScrollArea style={{ flex: 1 }}>
        <Stack gap="xs">
          {searchResults.map((result) => (
            <SearchResultCard
              key={`${result.entityType}:${result.entityId}`}
              result={result}
              onClick={handleEntitySelect}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

export default GlobalSearchSideBar;
