import { SurveyCreator } from 'survey-creator-react';

export const setupPropertyGrid = (creator: SurveyCreator) => {
  // const propertyStopList = [
  //     "visibleIf",
  //     "enableIf",
  //     "requiredIf",
  //     "bindings",
  //     "defaultValueExpression",
  //     "columnsVisibleIf",
  //     "rowsVisibleIf",
  //     "hideIfChoicesEmpty",
  //     "choicesVisibleIf",
  //     "choicesEnableIf",
  //     "minValueExpression",
  //     "maxValueExpression",
  //     "calculatedValues",
  //     "triggers",
  // ];

  // const categoryWhiteList = [
  //     "general",
  //     "navigation",
  //     "Data Binding",
  //     "conditions",
  //     "validation"
  // ];

  creator.onSurveyInstanceCreated.add((sender, options) => {
    if (options.area === 'property-grid') {
      const survey = options.survey;
      const panelsToRemove = [
        'logo',
        'question',
        'navigation',
        'validation',
        'showOnCompleted',
        'timer',
        'mask',
      ];
      panelsToRemove.forEach((tabName) => {
        const panel = survey.getPanelByName(tabName);
        if (panel) {
          panel.delete();
        }
      });
    }
  });
};
