import { ActivityResponse } from '@btrway/api-activities';
import { Stack } from '@mantine/core';
import React from 'react';
import { ActivityItem } from '../ActivityItem/ActivityItem';

interface ActivityListProps {
  activities: ActivityResponse[];
}

export const ActivityList: React.FC<ActivityListProps> = ({ activities }) => {
  return (
    <Stack gap="md">
      {activities.map((activity) => (
        <ActivityItem key={activity.id} activity={activity} />
      ))}
    </Stack>
  );
};
