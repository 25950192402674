import {
  MessageRecipientResponse,
  MessageResponse,
} from '@btrway/api-messaging';
import { Box } from '@mantine/core';
import DOMPurify from 'dompurify';

export const MessageContentView: React.FC<{
  message: MessageResponse;
  selectedRecipient?: MessageRecipientResponse;
}> = ({ message, selectedRecipient }) => {
  let finalHtml = message.messageHtml || '';
  const templateHtml = message.templateHtml || '';

  // First, handle merge data replacements
  if (selectedRecipient?.mergeData) {
    const mergeData = selectedRecipient.mergeData;
    finalHtml = finalHtml.replace(/\{\{(\w+)\}\}/g, (match, token) => {
      const value = mergeData[token as keyof typeof mergeData];
      return value?.toString() || match;
    });
  }

  // Then, handle template placeholder replacement if template exists
  if (templateHtml) {
    try {
      // Find the <td> that contains the placeholder div
      const placeholderPattern =
        /<td>(?:<div[^>]*data-email-content-placeholder="true"[^>]*>[\s\S]*?<\/div>)<\/td>/;

      // Replace the content
      if (placeholderPattern.test(templateHtml)) {
        finalHtml = templateHtml.replace(
          placeholderPattern,
          `<td>${finalHtml}</td>`
        );
      } else {
        console.warn(
          'No placeholder found in template, using content HTML only'
        );
      }
    } catch (error) {
      console.error('Error merging template with content:', error);
      // In case of error, keep finalHtml as is (just the content)
    }
  }

  const sanitizedHtml = DOMPurify.sanitize(finalHtml);

  return (
    <Box
      p="md"
      style={{
        height: '100%',
        overflow: 'auto',
        border: '1px solid var(--mantine-color-gray-5)',
      }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        style={{
          backgroundColor: 'white',
          padding: '1rem',
          borderRadius: 'var(--mantine-radius-sm)',
          border: '1px solid var(--mantine-color-gray-3)',
        }}
      />
    </Box>
  );
};
