import { ChapterDTO, ChapterProgressRequest } from '@btrway/api-courseware';
import { useRef } from 'react';
import { useChapterProgress } from './useChapterProgress';
import { useCoursewareProgressUpdate } from './useCoursewareProgressUpdate';

export const useIncrementalSave = (
  chapter: ChapterDTO,
  frequencySeconds: number
) => {
  const { chapterProgress } = useChapterProgress(chapter.key);
  const currentTimeSeconds = chapterProgress?.currentTimeSeconds || 0;
  const maxTimeSeconds = chapterProgress?.maxTimeSeconds || 0;
  const completed = chapterProgress?.completed || false;

  const { saveIncrementalProgress } = useCoursewareProgressUpdate();

  const previousCurrentTimeRef = useRef<number>(0);
  const incrementalCounterRef = useRef<number>(0);
  const savedCurrentTimeRef = useRef<number | null>(null);
  const savedMaxTimeRef = useRef<number | null>(null);

  if (Math.abs(currentTimeSeconds - previousCurrentTimeRef.current) > 1) {
    previousCurrentTimeRef.current = currentTimeSeconds;
    incrementalCounterRef.current++;
  }

  //Save when the incremental counter reaches the frequency
  if (incrementalCounterRef.current >= frequencySeconds) {
    incrementalCounterRef.current = 0;
    savedCurrentTimeRef.current = currentTimeSeconds;
    savedMaxTimeRef.current = maxTimeSeconds;

    const updatedFields: Partial<ChapterProgressRequest> = {
      chapterKey: chapter.key,
      currentTimeSeconds: currentTimeSeconds || 0,
      maxTimeSeconds: maxTimeSeconds || 0,
      completed: completed || false,
    };

    saveIncrementalProgress(updatedFields);
  }

  return {
    savedCurrentTimeRef,
    savedMaxTimeRef,
  };
};
