import { Stack, Text, Title } from '@mantine/core';
import { useAtomValue } from 'jotai';
import React from 'react';
import { publicRegistrationFormAtom } from '../../atoms/publicRegistrationFormAtom';

interface RegistrationFormHeaderProps {}

export const RegistrationFormHeader: React.FC<
  RegistrationFormHeaderProps
> = ({}) => {
  const publicFormRegistration = useAtomValue(publicRegistrationFormAtom);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  if (!publicFormRegistration) {
    return <Text>No registration form found.</Text>;
  }

  const getFormTitle = () => {
    const baseTitle =
      publicFormRegistration.registrationFormConfiguration?.title ||
      'Untitled Form';
    if (
      publicFormRegistration.registrationFormConfiguration?.availability ===
        'annual' &&
      publicFormRegistration.year
    ) {
      const nextYear = (publicFormRegistration.year + 1).toString().slice(-2);
      return `${baseTitle} for ${publicFormRegistration.year}-${nextYear}`;
    }
    return baseTitle;
  };

  return (
    <Stack gap="xs">
      <Title order={2}>{getFormTitle()}</Title>

      {publicFormRegistration.registrationFormConfiguration
        ?.shortDescription && (
        <Text>
          {
            publicFormRegistration.registrationFormConfiguration
              .shortDescription
          }
        </Text>
      )}
      {publicFormRegistration.endDate && (
        <Text>
          Registration closes on {formatDate(publicFormRegistration.endDate)}
        </Text>
      )}
    </Stack>
  );
};
