import {
  RegistrationFormAvailabilityTypeEnum,
  RegistrationFormOverrideRequest,
  RegistrationFormRequest,
  WorkgroupResponse,
  getGetRegistrationFormByIdQueryKey,
  useGetRegistrationFormById,
} from '@btrway/api-core';
import {
  Button,
  Card,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { RegistrationFormSettings } from '../../types/settings';
import { mergeRegistrationFormConfig } from '../../utils/registrationFormAdministrationUtils';
import { DateAvailabilityCard } from '../DateAvailabilityCard/DateAvailabilityCard';
import { RegistrantSettingsCard } from '../RegistrantSettingsCard/RegistrantSettingsCard';

interface RegistrationFormEditProps {
  formId: number;
  workgroup: WorkgroupResponse;
  onSave: (
    form: RegistrationFormRequest | RegistrationFormOverrideRequest
  ) => void;
  onCancel: () => void;
}

const defaultSettings: RegistrationFormSettings = {
  title: '',
  shortDescription: '',
  longDescription: '',
  workgroupTypeId: 0,
  registrantUserRoleId: undefined,
  dependentUserRoleId: undefined,
  allowChildrenCreation: false,
  allowOtherRelationshipCreation: false,
  availability: RegistrationFormAvailabilityTypeEnum.always,
  startDate: {},
  endDate: {},
};

export function RegistrationFormEdit({
  formId,
  workgroup,
  onSave,
  onCancel,
}: RegistrationFormEditProps) {
  const [settings, setSettings] = useState<RegistrationFormSettings>({
    ...defaultSettings,
    workgroupTypeId: workgroup.workgroupType.id,
  });

  const { data: form, isLoading } = useGetRegistrationFormById(formId, {
    query: {
      enabled: formId > 0,
      queryKey: getGetRegistrationFormByIdQueryKey(formId),
    },
  });

  useEffect(() => {
    if (form) {
      const mergedConfig = mergeRegistrationFormConfig(form, workgroup.id);
      setSettings({
        title: mergedConfig.title || '',
        shortDescription: mergedConfig.shortDescription || '',
        longDescription: mergedConfig.longDescription || '',
        workgroupTypeId: form.workgroupType.id,
        registrantUserRoleId: mergedConfig.registrantUserRoleId,
        dependentUserRoleId: mergedConfig.dependentUserRoleId,
        allowChildrenCreation: mergedConfig.allowChidrenCreation || false,
        allowOtherRelationshipCreation:
          mergedConfig.allowOtherRelationshipCreation || false,
        availability:
          mergedConfig.availability ||
          RegistrationFormAvailabilityTypeEnum.always,
        startDate: mergedConfig.startDate || {},
        endDate: mergedConfig.endDate || {},
      });
    } else {
      setSettings({
        ...defaultSettings,
        workgroupTypeId: workgroup.workgroupType.id,
      });
    }
  }, [form, workgroup.id]);

  const handleSettingChange = <K extends keyof RegistrationFormSettings>(
    key: K,
    value: RegistrationFormSettings[K]
  ) => {
    setSettings((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = async () => {
    let finalStartDate = {};
    let finalEndDate = {};

    if (settings.availability === RegistrationFormAvailabilityTypeEnum.annual) {
      finalStartDate = {
        ...settings.startDate,
      };
      finalEndDate = {
        ...settings.endDate,
      };
    } else if (
      settings.availability === RegistrationFormAvailabilityTypeEnum.limited
    ) {
      finalStartDate = {
        specificDate: settings.startDate.specificDate,
      };
      finalEndDate = {
        specificDate: settings.endDate.specificDate,
      };
    }

    const baseFormData = {
      organizationId: workgroup.organizationId,
      workgroupId: workgroup.id,
      enabled: true,
      optIn: false,
      workgroupTypeId: settings.workgroupTypeId,
      registrationFormConfiguration: {
        title: settings.title,
        shortDescription: settings.shortDescription,
        longDescription: settings.longDescription,
        registrantUserRoleId: settings.registrantUserRoleId,
        allowChidrenCreation: settings.allowChildrenCreation,
        allowOtherRelationshipCreation: settings.allowOtherRelationshipCreation,
        dependentUserRoleId: settings.dependentUserRoleId,
        availability: settings.availability,
        startDate: finalStartDate,
        endDate: finalEndDate,
      },
    };

    if (form && form.workgroup.id !== workgroup.id) {
      const overrideRequest: RegistrationFormOverrideRequest = {
        ...baseFormData,
        registrationFormId: form.id,
      };
      onSave(overrideRequest);
    } else {
      const formRequest: RegistrationFormRequest = {
        ...baseFormData,
        id: form?.id,
      };
      onSave(formRequest);
    }
  };

  const isNewForm = formId <= 0;
  const canEditWorkgroupType = isNewForm || form?.workgroup.id === workgroup.id;

  return (
    <Stack gap="lg" pos="relative">
      <LoadingOverlay visible={isLoading} />

      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Stack gap="md">
          <Select
            label="Available to Workgroups of Type:"
            data={[
              {
                value: workgroup.workgroupType.id.toString(),
                label: workgroup.workgroupType.name,
              },
            ]}
            value={settings.workgroupTypeId.toString()}
            onChange={(value) =>
              handleSettingChange('workgroupTypeId', Number(value))
            }
            disabled={!canEditWorkgroupType}
          />
          <TextInput
            label="Title"
            value={settings.title}
            onChange={(e) =>
              handleSettingChange('title', e.currentTarget.value)
            }
            required
          />
          <TextInput
            label="Short Description"
            value={settings.shortDescription}
            onChange={(e) =>
              handleSettingChange('shortDescription', e.currentTarget.value)
            }
          />
          <Textarea
            label="Long Description"
            value={settings.longDescription}
            onChange={(e) =>
              handleSettingChange('longDescription', e.currentTarget.value)
            }
            rows={8}
          />
        </Stack>
      </Card>

      <RegistrantSettingsCard
        registrantRoleId={settings.registrantUserRoleId}
        onRegistrantRoleChange={(value) =>
          handleSettingChange('registrantUserRoleId', value)
        }
        dependentRoleId={settings.dependentUserRoleId}
        onDependentRoleChange={(value) =>
          handleSettingChange('dependentUserRoleId', value)
        }
        allowChildren={settings.allowChildrenCreation}
        onAllowChildrenChange={(value) =>
          handleSettingChange('allowChildrenCreation', value)
        }
        allowOtherRelationships={settings.allowOtherRelationshipCreation}
        onAllowOtherRelationshipsChange={(value) =>
          handleSettingChange('allowOtherRelationshipCreation', value)
        }
      />

      <DateAvailabilityCard
        availability={settings.availability}
        onAvailabilityChange={(value) =>
          handleSettingChange('availability', value)
        }
        startDate={settings.startDate}
        onStartDateChange={(value) => handleSettingChange('startDate', value)}
        endDate={settings.endDate}
        onEndDateChange={(value) => handleSettingChange('endDate', value)}
      />

      <Group justify="flex-end" mt="xl">
        <Button variant="light" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save Changes</Button>
      </Group>
    </Stack>
  );
}
