import { Box, Card, Group, Switch, Text } from '@mantine/core';
import React from 'react';
import { SelectableItem } from '../../types/editor';

export const EditorSwitchCard: React.FC<{
  item: SelectableItem;
  isSelected: boolean;
  onToggle: (id: string) => void;
}> = ({ item, isSelected, onToggle }) => (
  <Card
    withBorder
    p="xs"
    onClick={() => onToggle(item.id)}
    style={{
      cursor: 'pointer',
      backgroundColor: isSelected
        ? 'var(--mantine-color-blue-light)'
        : undefined,
    }}
  >
    <Group justify="space-between" wrap="nowrap" align="center">
      <Text fz="sm">{item.name}</Text>
      <Box onClick={(e) => e.stopPropagation()}>
        <Switch checked={isSelected} onChange={() => onToggle(item.id)} />
      </Box>
    </Group>
  </Card>
);
