import { format } from 'date-fns';

export const formatDate = (dateString: string) => {
  if (!dateString) return '';
  //date-fns format function subtracting one day from the date while formating on a hyphened string date.
  const modifiedDate = new Date(dateString.replace(/-/g, '/'));
  return format(modifiedDate, 'M/d/yyyy');
};

export const formatDateTime = (dateTimeString: string) => {
  if (!dateTimeString) return '';
  return format(new Date(dateTimeString), 'M/d/yyyy h:mm a');
};

export const formatDateTimeSeconds = (dateTimeString: string) => {
  if (!dateTimeString) return '';
  return format(new Date(dateTimeString), 'M/d/yyyy h:mm:ss a');
};

export const formatDayDate = (dateTimeString: string) => {
  if (!dateTimeString) return '';
  return format(new Date(dateTimeString), 'EEEE MMM d, yyyy');
};
