import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  RowClickedEvent,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { AgGridReact, AgGridReactProps } from '@ag-grid-community/react';

import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { Box, Group, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinkGridCell } from '../LinkGridCell/LinkGridCell';
import MobileSortingMenu from '../MobileSortingMenu/MobileSortingMenu';
import ResponsiveGridRow from '../ResponsiveGridRow/ResponsiveGridRow';
import styles from './ResponsiveGrid.module.css';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

type ResponsiveGridProps<TData = any> = Omit<
  AgGridReactProps<TData>,
  'columnDefs'
> & {
  columnDefs: ColDef<TData>[];
  responsiveRowLayout?: 'stack' | 'flex';
  primaryNavigationField?: string;
  onRowClick?: (data: TData) => void;
  responsiveRowComponent?: React.ComponentType<{
    data: TData;
    className?: string;
    onClick?: () => void;
  }>;
  responsiveRowSpacing?: string | number;
};

interface SortConfig {
  field: string;
  sort: 'asc' | 'desc' | null;
}

const ResponsiveGrid = <TData extends Record<string, any> = any>({
  columnDefs,
  onGridReady,
  context,
  rowData,
  responsiveRowLayout = 'flex',
  primaryNavigationField,
  onRowClick,
  responsiveRowComponent: ResponsiveRowComponent,
  responsiveRowSpacing = 'md',
  ...restProps
}: ResponsiveGridProps<TData>) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const gridRef = useRef<AgGridReact>(null);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: '',
    sort: null,
  });
  const navigate = useNavigate();

  const handleSortChange = useCallback(
    (column: ColDef<TData>, sort: 'asc' | 'desc' | null) => {
      setSortConfig({ field: column.field || '', sort });
    },
    []
  );

  const sortedRowData = useMemo(() => {
    if (!isMobile || !sortConfig.field || !sortConfig.sort || !rowData) {
      return rowData;
    }

    const sortColumn = columnDefs.find((col) => col.field === sortConfig.field);
    const valueGetter = sortColumn?.valueGetter;

    return [...rowData].sort((a, b) => {
      let valueA, valueB;

      if (valueGetter) {
        const getValueFromParams = (data: TData): any => {
          const params: ValueGetterParams<TData, any> = {
            data,
            node: null as any, // TypeScript doesn't allow null here, but it's safe for sorting
            colDef: sortColumn!,
            column: sortColumn as any, // This is not ideal, but necessary due to type constraints
            api: gridApi!,
            context,
            getValue: (field: string) => data[field],
          };

          if (typeof valueGetter === 'string') {
            // If valueGetter is a string, it's a field name
            return params.getValue(valueGetter);
          } else if (typeof valueGetter === 'function') {
            return valueGetter(params);
          }
          return undefined;
        };

        valueA = getValueFromParams(a);
        valueB = getValueFromParams(b);
      } else {
        valueA = a[sortConfig.field];
        valueB = b[sortConfig.field];
      }

      if (valueA < valueB) return sortConfig.sort === 'asc' ? -1 : 1;
      if (valueA > valueB) return sortConfig.sort === 'asc' ? 1 : -1;
      return 0;
    });
  }, [isMobile, sortConfig, rowData, columnDefs, gridApi, context]);

  const handleRowClick = useCallback(
    (data: TData) => {
      if (onRowClick) {
        onRowClick(data);
      } else if (primaryNavigationField) {
        const navColumn = columnDefs.find(
          (col) => col.field === primaryNavigationField
        );
        if (navColumn?.cellRenderer === LinkGridCell) {
          const params = { data, ...context };
          const cellRendererParams = navColumn.cellRendererParams?.(params);
          if (cellRendererParams?.value?.to) {
            navigate(cellRendererParams.value.to);
          }
        }
      }
    },
    [onRowClick, primaryNavigationField, columnDefs, navigate, context]
  );

  const onRowClicked = useCallback(
    (event: RowClickedEvent<TData>) => {
      // Check if the click event originated from a element with the class 'stop-propagation'
      if (
        event.event &&
        (event.event.target as HTMLElement).closest('.stop-propagation')
      ) {
        return; // Stop propagation for elements with 'stop-propagation' class
      }
      if (event.data && onRowClick) {
        onRowClick(event.data);
      }
    },
    [onRowClick]
  );

  const responsiveColumnDefs = useMemo(() => {
    if (isMobile && columnDefs) {
      return [
        {
          field: 'mobileView',
          headerName: '',
          cellRenderer: (params: any) => (
            <Box mb={responsiveRowSpacing} style={{ width: '100%' }}>
              {ResponsiveRowComponent ? (
                <ResponsiveRowComponent
                  data={params.data}
                  className={onRowClick ? 'clickable-card' : ''}
                  onClick={() => handleRowClick(params.data)}
                />
              ) : (
                <ResponsiveGridRow
                  data={params.data}
                  columnDefs={columnDefs}
                  isMobile={true}
                  api={gridApi}
                  context={context}
                  variant={responsiveRowLayout}
                  onCardClick={() => handleRowClick(params.data)}
                />
              )}
            </Box>
          ),
          flex: 1,
          sortable: false,
          filter: false,
          autoHeight: true,
        },
      ] as ColDef<TData>[];
    } else {
      return columnDefs.map((col, index) => ({
        ...col,
        suppressHeaderMenuButton: isMobile,
        cellClass: index === 0 ? 'first-column' : '', // Add this line
      }));
    }
  }, [
    columnDefs,
    isMobile,
    gridApi,
    context,
    responsiveRowLayout,
    handleRowClick,
    ResponsiveRowComponent,
    responsiveRowSpacing,
    onRowClick,
  ]);

  const handleGridReady = (params: GridReadyEvent<TData>) => {
    setGridApi(params.api);
    if (onGridReady) {
      onGridReady(params);
    }
  };

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const mobileGridStyle: React.CSSProperties = {
    ...gridStyle,
    border: '1px solid transparent',
  };

  const gridOptions: GridOptions<TData> = useMemo(
    () => ({
      ...restProps,
      columnDefs: responsiveColumnDefs,
      headerHeight: isMobile ? 0 : undefined,
      rowClass: isMobile ? 'mobile-row' : '',
      onGridReady: handleGridReady,
      rowData: sortedRowData,
      onRowClicked: onRowClicked,
    }),
    [
      restProps,
      responsiveColumnDefs,
      isMobile,
      handleGridReady,
      sortedRowData,
      onRowClicked,
    ]
  );

  return (
    <Box className={styles.root} style={containerStyle}>
      {isMobile && (
        <Group justify="flex-end">
          <MobileSortingMenu
            columnDefs={columnDefs}
            onSortChange={handleSortChange}
            currentSort={sortConfig}
          />
        </Group>
      )}
      <Box
        className={`${styles.gridContainer} ${styles.grid} ${
          isMobile ? styles.mobileGrid : styles.desktopGrid
        } ag-theme-quartz`}
        style={isMobile ? mobileGridStyle : gridStyle}
      >
        <AgGridReact<TData> ref={gridRef} {...gridOptions} />
      </Box>
    </Box>
  );
};

export default ResponsiveGrid;
