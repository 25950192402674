import {
  CertificationConfig,
  EventConfig,
  StepConfig,
  StepTypeEnum,
  TaskConfig,
} from '@btrway/api-workflow';
import {
  useEventTypes,
  useStepTypes,
  useTaskTypes,
} from '@btrway/workflow-manager';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { SelectedItem, selectedItemAtom } from '../atoms/selectedItemAtom';
// import { useWorkflowCertification } from './useWorkflowCertification';
import { useWorkflowEvent } from './useWorkflowEvent';
import { useWorkflowStep } from './useWorkflowStep';
// import { useWorkflowTask } from './useWorkflowTask';

interface UseSelectedItemResult {
  selectedItem: SelectedItem | null;
  selectedConfig:
    | StepConfig
    | EventConfig
    | TaskConfig
    | CertificationConfig
    | null;
  selectedTitle: string | null;
  setSelectedItem: (item: SelectedItem | null) => void;
  updateSelectedConfig: (
    update: Partial<StepConfig | EventConfig | TaskConfig | CertificationConfig>
  ) => void;
  resetSelectedItem: () => void;
}

export const useSelectedItem = (): UseSelectedItemResult => {
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const resetSelectedItem = useResetAtom(selectedItemAtom);
  const { getEventType } = useEventTypes();
  const { getStepType } = useStepTypes();
  const { getTaskType } = useTaskTypes();

  const { step, setStep } = useWorkflowStep(
    selectedItem?.type === 'step' ? selectedItem.key : ''
  );
  const { event, setEvent } = useWorkflowEvent(
    selectedItem?.type === 'event' ? selectedItem.key : ''
  );
  // const { task, setTask } = useWorkflowTask(
  //   selectedItem?.type === 'task' ? selectedItem.key : ''
  // );
  // const { certification, setCertification } = useWorkflowCertification(
  //   selectedItem?.type === 'certification' ? selectedItem.key : ''
  // );

  const selectedConfig =
    selectedItem?.type === 'step'
      ? step
      : selectedItem?.type === 'event'
      ? event
      : // : selectedItem?.type === 'task'
        // ? task
        // : selectedItem?.type === 'certification'
        // ? certification
        null;

  let selectedTitle = null;
  if (selectedItem?.type === 'step') {
    if (
      step?.stepTypeCode === StepTypeEnum.assignTask &&
      step.taskConfig?.taskType
    ) {
      selectedTitle =
        step.title ||
        getTaskType(step.taskConfig.taskType)?.name ||
        'Assign Task';
    } else {
      selectedTitle = step?.title
        ? step.title
        : step?.stepTypeCode
        ? getStepType(step.stepTypeCode)?.name || 'Step'
        : 'Step';
    }
  } else if (selectedItem?.type === 'event') {
    selectedTitle = event?.title
      ? event.title
      : event?.eventTypeCode
      ? getEventType(event.eventTypeCode)?.name || 'Event'
      : 'Event';
    // } else if (selectedItem?.type === 'task') {
    //   selectedTitle = task?.metadata?.title
    //     ? task.metadata.title
    //     : task?.taskType
    //     ? getTaskType(task.taskType)?.name || 'Task'
    //     : 'Task';
    // } else if (selectedItem?.type === 'certification') {
    //   selectedTitle = certification?.certificationType
    //     ? getCertificationType(certification.certificationType)?.name ||
    //       'Certification Item'
    //     : 'Certification Item';
  }

  const updateSelectedConfig = useCallback(
    (update: Partial<StepConfig | EventConfig | TaskConfig>) => {
      if (selectedItem?.type === 'step' && step) {
        setStep(update as Partial<StepConfig>);
      } else if (selectedItem?.type === 'event' && event) {
        setEvent(update as Partial<EventConfig>);
        // } else if (selectedItem?.type === 'task' && task) {
        //   setTask(update as Partial<TaskConfig>);
        // } else if (selectedItem?.type === 'certification' && certification) {
        //   setCertification(update as Partial<CertificationConfig>);
      }
    },
    [selectedItem, step, event, setStep, setEvent]
  );

  return {
    selectedItem,
    selectedConfig,
    selectedTitle,
    setSelectedItem,
    updateSelectedConfig,
    resetSelectedItem,
  };
};
