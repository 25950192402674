import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridApi, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { useAuthenticatedUser } from '@btrway/current-user';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { UserRoleResponse } from '@btrway/api-security';
import { useUserRoles } from '@btrway/security-manager';
import { Badge } from '@mantine/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import UserRoleDetailView from '../UserRoleDetailView/UserRoleDetailView';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

const UserRolesGridView: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const { userRoles } = useUserRoles();
  const gridApiRef = useRef<GridApi | null>(null);

  const [selectedUserRole, setSelectedUserRole] =
    useState<UserRoleResponse | null>(null);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshCells({ force: true });
    }
  }, [userRoles]);

  const columnDefs = useMemo<ColDef[]>(() => [], []);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      sortable: true,
      filter: false,
    }),
    []
  );

  const CustomCellRenderer = (params: any) => {
    const { data } = params;
    if (data.userRoleGroup) {
      return (
        <Badge color="blue" variant="light">
          {data.name}
        </Badge>
      );
    }
    return data.name;
  };

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: 'Name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: CustomCellRenderer,
      },
      sort: 'asc',
    }),
    []
  );

  const getDataPath = useCallback((data: any) => {
    return data.path.split('.').map((segment: string) => {
      const [name] = segment.split(':');
      return name.trim();
    });
  }, []);

  const getRowId = useCallback((params: any) => {
    return params.data.id;
  }, []);

  const onGridReady = useCallback((params: any) => {
    gridApiRef.current = params.api;
  }, []);

  const onRowClicked = useCallback((event: any) => {
    setSelectedUserRole(event.data);
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div
        className="ag-theme-quartz"
        style={{
          height: '100%',
          width: '300px',
        }}
      >
        <AgGridReact
          rowData={userRoles}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          groupDefaultExpanded={-1}
          getDataPath={getDataPath}
          getRowId={getRowId}
          animateRows={true}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
        />
      </div>
      {selectedUserRole && (
        <div style={{ flexGrow: 1, paddingLeft: '20px' }}>
          <UserRoleDetailView userRole={selectedUserRole} />
        </div>
      )}
    </div>
  );
};

export default UserRolesGridView;
