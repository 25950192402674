import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellValueChangedEvent,
  ColDef,
  ModuleRegistry,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import {
  useDeleteTaskCategory,
  useGetTaskCategories,
  useSaveTaskCategory,
} from '@btrway/api-workflow';
import {
  AddItemPopover,
  EditableCellRenderer,
  InfoCard,
} from '@btrway/shared-components';
import { uuid } from '@btrway/utils';
import { Button, Group, Stack } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const TaskCategoriesView: React.FC = () => {
  const { data: taskCategories, refetch } = useGetTaskCategories();
  const { mutateAsync: saveTaskCategory } = useSaveTaskCategory();
  const { mutateAsync: deleteTaskCategory } = useDeleteTaskCategory();

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent) => {
      const { data } = event;
      try {
        await saveTaskCategory({
          data: {
            id: data.id,
            key: data.key,
            name: data.name,
            organizationId: data.organizationId,
            organizationTypeId: data.organizationTypeId,
          },
        });
        refetch();
      } catch (error) {
        console.error('Error saving task type:', error);
      }
    },
    [saveTaskCategory, refetch]
  );

  const onDeleteClick = useCallback(
    async (id: number) => {
      try {
        await deleteTaskCategory({ taskCategoryId: id });
        refetch();
      } catch (error) {
        console.error('Error deleting task type:', error);
      }
    },
    [deleteTaskCategory, refetch]
  );

  const actionCellRenderer = useCallback(
    (params: any) => (
      <Button
        size="compact-sm"
        variant="light"
        color="red"
        onClick={() => onDeleteClick(params.data.id)}
      >
        Delete
      </Button>
    ),
    [onDeleteClick]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        editable: true,
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: actionCellRenderer,
        editable: false,
        width: 150,
        suppressHeaderMenuButton: true,
      },
    ],
    [actionCellRenderer]
  );

  const onGridReady = (params: { api: any }) => {
    // params.api.sizeColumnsToFit();
  };

  const handleAddTaskCategory = useCallback(
    async (name: string) => {
      try {
        await saveTaskCategory({
          data: {
            key: uuid(),
            name,
          },
        });
        refetch();
      } catch (error) {
        console.error('Error adding new task type:', error);
      }
    },
    [saveTaskCategory, refetch]
  );

  return (
    <Stack gap="sm" h="100%">
      <InfoCard
        text="Task Types are currently defined globally. In the future they could be made tailored/specific to organizations or organization types."
        bg="gray.1"
      />
      <Group justify="flex-end">
        <AddItemPopover
          buttonText="Add Task Type"
          placeholder="Name of Task Type"
          onSave={handleAddTaskCategory}
        />
      </Group>
      <div
        className="ag-theme-quartz"
        style={{ height: '100%', width: '100%' }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={taskCategories}
          onGridReady={onGridReady}
          components={{ editableCellRenderer: EditableCellRenderer }}
          onCellValueChanged={onCellValueChanged}
        />
      </div>
    </Stack>
  );
};

export default TaskCategoriesView;
