import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconPhoto } from '@tabler/icons-react';
import { useState } from 'react';

import { StorageUsageEnum } from '@btrway/api-storage';
import {
  EditableImageModal,
  useImageUpload,
} from '@btrway/file-storage-components';

function ImageUploadControl() {
  const { editor } = useRichTextEditorContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { imageUrl, handleImageUpload } = useImageUpload({
    storageUsage: StorageUsageEnum.helparticles,
    onImageUpload: (imageKey) => {
      console.log('Image uploaded with key:', imageKey);
    },
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleUpload = async (file: Blob) => {
    try {
      await handleImageUpload(file);

      // After successful upload, insert the image into the editor
      if (editor) {
        const { view } = editor;
        const { state } = view;
        const pos = state.selection.from;

        // Here, we're using a placeholder URL. You might want to use the actual URL returned by your upload process.
        // const imageUrl = URL.createObjectURL(file);
        console.log('imageUrl:', imageUrl);

        view.dispatch(
          view.state.tr.insert(
            pos,
            state.schema.nodes.image.create({ src: imageUrl })
          )
        );
      }

      closeModal();
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <>
      <RichTextEditor.Control onClick={openModal} aria-label="Add image">
        <IconPhoto size={18} />
      </RichTextEditor.Control>
      <EditableImageModal
        opened={isModalOpen}
        onClose={closeModal}
        onImageUpload={handleUpload}
        existingImageUrl=""
        storageUsage={StorageUsageEnum.helparticles}
        title="Upload Image"
        aspectRatio={{ width: 16, height: 9 }}
      />
    </>
  );
}

export default ImageUploadControl;
