import { useUserRoles } from '@btrway/security-manager';
import { Box, Card, Group, Loader, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface UserRoleSelectorProps {
  initialUserRoleSlug?: string;
  onUserRoleSelect: (userRoleSlug: string) => void;
  onClose: () => void;
  displayHeader?: boolean;
}

const UserRoleSelector: React.FC<UserRoleSelectorProps> = ({
  initialUserRoleSlug,
  onUserRoleSelect,
  onClose,
  displayHeader = true,
}) => {
  const { userRoles, isLoading } = useUserRoles();
  const [selectedRoleSlug, setSelectedRoleSlug] = useState<string | undefined>(
    initialUserRoleSlug
  );

  useEffect(() => {
    setSelectedRoleSlug(initialUserRoleSlug);
  }, [initialUserRoleSlug]);

  const handleRoleSelect = (roleSlug: string) => {
    setSelectedRoleSlug(roleSlug);
    onUserRoleSelect(roleSlug);
    onClose();
  };

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {displayHeader && (
        <Box p="md" style={{ position: 'relative' }}>
          <Group justify="space-between">
            <Text fz="md" fw={700}>
              Select User Role
            </Text>
          </Group>
        </Box>
      )}

      <Stack gap="xs" p="md" pt={0}>
        {userRoles.map((role) => (
          <Card
            key={role.id}
            padding="sm"
            radius="md"
            withBorder
            style={{
              cursor: 'pointer',
              backgroundColor:
                selectedRoleSlug === role.slug
                  ? 'var(--mantine-color-blue-light)'
                  : undefined,
            }}
            onClick={() => handleRoleSelect(role.slug)}
          >
            <Text>{role.name}</Text>
          </Card>
        ))}
      </Stack>
    </Box>
  );
};

export default UserRoleSelector;
