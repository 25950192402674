import {
  OrganizationRequest,
  OrganizationResponse,
  useGetOrganizationTemplatesByOrganizationType,
  useGetOrganizationTypes,
  useSaveOrganization,
} from '@btrway/api-core';
import {
  StateProvinceTagsInput,
  TimezoneSelect,
} from '@btrway/shared-components';
import {
  Button,
  Checkbox,
  Group,
  Modal,
  Radio,
  RadioGroup,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';

interface AddOrganizationModalProps {
  refetchOrganizations: () => void;
}

const AddOrganizationModal: React.FC<AddOrganizationModalProps> = ({
  refetchOrganizations,
}) => {
  const [opened, handlers] = useDisclosure(false);
  const { data: organizationTypes } = useGetOrganizationTypes();
  const [selectedOrganizationType, setSelectedOrganizationType] = useState<
    string | null
  >(null);
  const { data: organizationTemplates } =
    useGetOrganizationTemplatesByOrganizationType(
      selectedOrganizationType ? Number(selectedOrganizationType) : 0
    );
  const { mutateAsync: saveOrganization } = useSaveOrganization();

  const [organizationName, setOrganizationName] = useState('');
  const [stateCode, setStateCode] = useState<string | undefined>(undefined);
  const [organizationCode, setOrganizationCode] = useState('abc123');
  const [organizationTimezone, setOrganizationTimezone] = useState<
    string | null
  >('America/New_York');
  const [createMockPersons, setCreateMockPersons] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  const organizationNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (organizationTypes && organizationTypes.length > 0) {
      const filteredTypes = organizationTypes.filter(
        (type) => type.name !== 'Internal Organization'
      );
      if (filteredTypes.length > 0) {
        setSelectedOrganizationType(filteredTypes[0].id.toString());
      }
    }
  }, [organizationTypes]);

  useEffect(() => {
    if (selectedOrganizationType && organizationNameRef.current) {
      organizationNameRef.current.focus();
    }
  }, [selectedOrganizationType]);

  useEffect(() => {
    if (organizationTemplates && organizationTemplates.length > 0) {
      setSelectedTemplate(organizationTemplates[0].id.toString());
    }
  }, [organizationTemplates]);

  const handleSubmit = async () => {
    if (selectedOrganizationType && organizationName && stateCode) {
      const selectedType = organizationTypes?.find(
        (type) => type.id.toString() === selectedOrganizationType
      );
      if (!selectedType) return;

      const organizationRequest: OrganizationRequest = {
        code: organizationCode,
        name: organizationName,
        createMockPersons,
        organizationType: {
          code: selectedType.code,
          id: selectedType.id,
          name: selectedType.name,
        },
        timeZoneName: organizationTimezone || 'America/New_York',
        organizationTemplateId: selectedTemplate
          ? Number(selectedTemplate)
          : undefined,
        physicalAddress: {
          stateProvinceCode: stateCode,
        },
      };

      try {
        const organizationResponse = (await saveOrganization({
          data: organizationRequest,
        })) as OrganizationResponse;
        refetchOrganizations();
        closeModal();
      } catch (error) {
        console.error('Error saving organization:', error);
      }
    }
  };

  const closeModal = () => {
    handlers.close();
    resetForm();
  };

  const resetForm = () => {
    setOrganizationName('');
    setSelectedTemplate(null);
    setStateCode(undefined);
    setCreateMockPersons(false);
    if (organizationTypes && organizationTypes.length > 0) {
      const filteredTypes = organizationTypes.filter(
        (type) => type.name !== 'Internal Organization'
      );
      if (filteredTypes.length > 0) {
        setSelectedOrganizationType(filteredTypes[0].id.toString());
      } else {
        setSelectedOrganizationType(null);
      }
    } else {
      setSelectedOrganizationType(null);
    }
  };

  const filteredOrganizationTypes =
    organizationTypes?.filter(
      (type) => type.name !== 'Internal Organization'
    ) || [];

  return (
    <>
      <Tooltip label="Add Organization" withArrow>
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={() => handlers.open()}
        >
          Add Organization
        </Button>
      </Tooltip>
      <Modal
        opened={opened}
        onClose={closeModal}
        withCloseButton={false}
        centered
        size="lg"
      >
        <Stack gap={0} p={0} mih={600} justify="space-between">
          <Stack gap="xl" p="sm">
            <Stack gap="xs">
              <Text fz="lg" fw={700}>
                Organization Information
              </Text>
              <TextInput
                label="Name"
                required
                value={organizationName}
                onChange={(event) => setOrganizationName(event.target.value)}
                ref={organizationNameRef}
                autoFocus
                data-autofocus
              />
              <Group gap="md" wrap="nowrap" align="flex-end">
                <StateProvinceTagsInput
                  onChange={setStateCode}
                  required={true}
                  defaultValue={stateCode}
                />
                <Stack gap={0}>
                  <Text fz="sm" fw={600}>
                    Timezone
                  </Text>
                  <TimezoneSelect
                    value={organizationTimezone}
                    onChange={setOrganizationTimezone}
                  />
                </Stack>
              </Group>
            </Stack>
            <Stack gap="xs">
              <Text fz="lg" fw={700}>
                Organization Type
              </Text>
              <SegmentedControl
                data={filteredOrganizationTypes.map((type) => ({
                  label: type.name,
                  value: type.id.toString(),
                }))}
                value={selectedOrganizationType || ''}
                onChange={setSelectedOrganizationType}
                fullWidth
              />
              {organizationTemplates && organizationTemplates.length > 0 && (
                <RadioGroup
                  label="Preset"
                  value={selectedTemplate || ''}
                  onChange={setSelectedTemplate}
                >
                  <Stack gap={5}>
                    {organizationTemplates.map((template) => (
                      <Radio
                        key={template.id}
                        value={template.id.toString()}
                        label={template.name}
                      />
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            </Stack>
            <Stack gap="xs">
              <Text fz="lg" fw={700}>
                Testing Data
              </Text>

              <Checkbox
                label="Generate Mock Persons"
                checked={createMockPersons}
                onChange={(event) =>
                  setCreateMockPersons(event.currentTarget.checked)
                }
              />
            </Stack>
          </Stack>
          <Group justify="flex-end" p={20}>
            <Button variant="default" onClick={closeModal}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default AddOrganizationModal;
