import {
  DataTypeEnum,
  TaskInstanceRequestTaskData,
  TaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { TaskCompletionResult, useTaskCompletion } from '@btrway/task-manager';
import React, { createContext, useContext } from 'react';

interface TaskActionContextType {
  completeTask: (
    comment?: string,
    taskData?: TaskInstanceRequestTaskData
  ) => Promise<TaskCompletionResult>;
  markTaskViewed: () => Promise<void>;
}

interface TaskActionProviderProps {
  children: React.ReactNode;
  taskProperties: TaskProperties;
  taskInstanceId?: number;
  assignedEntityType?: DataTypeEnum;
  assignedEntityId?: number;
  workgroupId?: number;
  derivedWorkflowKey?: string;
  taskKey?: string;
  taskType?: TaskTypeEnum;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

const TaskActionContext = createContext<TaskActionContextType | undefined>(
  undefined
);

export const useTaskActionContext = () => {
  const context = useContext(TaskActionContext);
  if (!context) {
    throw new Error(
      'useTaskActionContext must be used within TaskActionProvider'
    );
  }
  return context;
};

export const TaskActionProvider: React.FC<TaskActionProviderProps> = ({
  children,
  ...props
}) => {
  const taskActions = useTaskCompletion(props);

  return (
    <TaskActionContext.Provider value={taskActions}>
      {children}
    </TaskActionContext.Provider>
  );
};
