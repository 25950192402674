import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { createAxiosInstance } from './axiosInstance';

interface APIClientContextValue {
  queryClient: QueryClient;
  apiUrl: string;
}

const APIClientContext = createContext<APIClientContextValue | null>(null);

export const useAPIClient = () => {
  const apiContext = useContext(APIClientContext);
  if (!apiContext) {
    throw new Error('useAPIClient must be used within a APIClientProvider');
  }
  return apiContext;
};

interface APIClientProviderProps {
  children: ReactNode;
  initialApiUrl: string;
}

const APIClientProvider: React.FC<APIClientProviderProps> = ({
  children,
  initialApiUrl,
}) => {
  const [apiUrl] = useState(initialApiUrl);

  // Set up axios instance when component mounts
  useMemo(() => {
    if (initialApiUrl) {
      createAxiosInstance(initialApiUrl);
    }
  }, [initialApiUrl]);

  const queryClient = useMemo(() => new QueryClient(), []);

  const contextValue = useMemo<APIClientContextValue>(
    () => ({
      queryClient,
      apiUrl,
    }),
    [queryClient, apiUrl]
  );

  return (
    <APIClientContext.Provider value={contextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </APIClientContext.Provider>
  );
};

export default APIClientProvider;
