import { StorageUsageEnum } from '@btrway/api-storage';
import { useImageUpload } from '@btrway/file-storage-components';
import { Avatar, Group, Menu, Text, UnstyledButton } from '@mantine/core';
import { IconChevronDown, IconLogout, IconSettings } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router';
import classes from './UserProfileNavbarButton.module.css';
import { useAuthenticatedUser } from '@btrway/current-user';

interface UserProfileNavbarButtonProps {
  minimized?: boolean;
}

const UserProfileNavbarButton: React.FC<UserProfileNavbarButtonProps> = ({
  minimized = false,
}) => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  // const { isUsingInternalSite } = useAuth();
  const navigate = useNavigate();
  const { imageUrl: avatarUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.headshot,
    initialImageKey: currentPerson.headshot,
  });

  const handleLogout = () => {
    navigate('/logout');
  };

  // const handleProfile = () => {
  //   const path = isUsingInternalSite ? '/app-internal/profile' : '/app/profile';
  //   navigate(path);
  // };

  const buttonContent = (
    <UnstyledButton className={classes.button}>
      <Group gap={7} style={{ flexWrap: 'nowrap' }}>
        <Avatar src={avatarUrl} alt="Profile Picture" radius="xl" size={30} />
        {!minimized && (
          <>
            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {currentPerson.firstName} {currentPerson.lastName}
              </Text>
            </div>
            <IconChevronDown size="0.9rem" stroke={1.5} />
          </>
        )}
      </Group>
    </UnstyledButton>
  );

  return (
    <Menu
      width={260}
      transitionProps={{ transition: 'pop-top-right' }}
      withinPortal
    >
      <Menu.Target>{buttonContent}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          // onClick={handleProfile}
          leftSection={
            <IconSettings
              style={{ width: '0.9rem', height: '0.9rem' }}
              stroke={1.5}
            />
          }
        >
          My Profile
        </Menu.Item>
        <Menu.Item
          onClick={handleLogout}
          leftSection={
            <IconLogout
              style={{ width: '0.9rem', height: '0.9rem' }}
              stroke={1.5}
            />
          }
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserProfileNavbarButton;
