import { Anchor, List, Paper, Stack, Text, Title } from '@mantine/core';
import React from 'react';

interface RenderNodeProps {
  node: any;
}

const RenderNode: React.FC<RenderNodeProps> = ({ node }) => {
  switch (node.type) {
    case 'paragraph':
      return (
        <Text mb="md">
          {node.content.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Text>
      );
    case 'heading':
      return (
        <Title order={node.attrs.level as 1 | 2 | 3 | 4 | 5 | 6} mb="md">
          {node.content.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Title>
      );
    case 'text':
      return <>{node.text}</>;
    case 'bulletList':
      return (
        <List mb="md">
          {node.content.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </List>
      );
    case 'listItem':
      return (
        <List.Item>
          {node.content.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </List.Item>
      );
    case 'link':
      return (
        <Anchor
          href={node.attrs.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content.map((child: any, index: number) => (
            <RenderNode key={index} node={child} />
          ))}
        </Anchor>
      );
    case 'image':
      return (
        <img
          src={node.attrs.src}
          alt={node.attrs.alt || ''}
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      );
    default:
      return null;
  }
};

interface ContentRendererProps {
  content: string;
  title?: string;
}

export const ContentRenderer: React.FC<ContentRendererProps> = ({
  content,
  title,
}) => {
  let parsedContent;

  try {
    parsedContent = JSON.parse(content || '{}');
  } catch (error) {
    console.error('Failed to parse content:', error);
    return <Text c="red">Error: Invalid content format</Text>;
  }

  return (
    <Paper p="md" shadow="sm">
      {title && (
        <Title order={1} mb="lg">
          {title}
        </Title>
      )}
      <Stack>
        {parsedContent.content && Array.isArray(parsedContent.content) ? (
          parsedContent.content.map((node: any, index: number) => (
            <RenderNode key={index} node={node} />
          ))
        ) : (
          <Text>No content available or invalid content structure</Text>
        )}
      </Stack>
    </Paper>
  );
};

export default ContentRenderer;
