// hooks/useEntityDataLookup.ts
import {
  getGetFacilityByIdQueryKey,
  useGetFacilityById,
} from '@btrway/api-calendar';
import {
  getGetEntityGroupByIdQueryKey,
  getGetPersonByIdQueryKey,
  getGetWorkgroupByIdQueryKey,
  useGetEntityGroupById,
  useGetPersonById,
  useGetWorkgroupById,
} from '@btrway/api-core';
import {
  getGetAssetByUidQueryKey,
  useGetAssetByUid,
} from '@btrway/api-courseware';
import {
  getGetUserRoleByIdQueryKey,
  useGetUserRoleById,
} from '@btrway/api-security';
import {
  DataTypeEnum,
  getGetWorkflowDefinitionByIdQueryKey,
  useGetWorkflowDefinitionById,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';

const STALE_TIME = Infinity;

type QueryResult<T> = {
  data: T | undefined;
  error: Error | null;
  isLoading: boolean;
};

/**
 * Hook for fetching entity data with React Query caching
 * Simplified version that removes UI-specific concerns
 */
export const useEntityDataLookup = <T = any>(
  dataType: keyof typeof DataTypeEnum,
  id: number
): QueryResult<T> => {
  const { currentOrganization } = useAuthenticatedUser();

  if (id === 0) {
    return {
      data: undefined,
      error: new Error('Invalid ID: 0'),
      isLoading: false,
    };
  }

  const queryConfig = {
    query: {
      staleTime: STALE_TIME,
      enabled: true,
    },
  };

  switch (dataType) {
    case DataTypeEnum.person:
      if (!currentOrganization) {
        return {
          data: undefined,
          error: new Error('Current organization is not available'),
          isLoading: false,
        };
      }
      return useGetPersonById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetPersonByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.workgroup:
      return useGetWorkgroupById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetWorkgroupByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.course:
      return useGetAssetByUid(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetAssetByUidQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.workflowDefinition:
      return useGetWorkflowDefinitionById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetWorkflowDefinitionByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.entityGroup:
      return useGetEntityGroupById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetEntityGroupByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.facility:
      return useGetFacilityById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetFacilityByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    case DataTypeEnum.userRole:
      return useGetUserRoleById(id, {
        ...queryConfig,
        query: {
          ...queryConfig.query,
          queryKey: getGetUserRoleByIdQueryKey(id),
        },
      }) as QueryResult<T>;

    default:
      throw new Error(`Unsupported data type: ${dataType}`);
  }
};
