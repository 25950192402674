// import { SegmentedControl, Select, Stack, Text } from '@mantine/core';
// import { useAtom } from 'jotai';
// import React, { useMemo } from 'react';
// import { ImportedPerson } from '../../types';

// interface MatchingStepProps {
//   matchingMethod: 'byId' | 'byNameAndContact';
//   setMatchingMethod: (method: 'byId' | 'byNameAndContact') => void;
// }

// export const MatchingStep: React.FC<MatchingStepProps> = ({
//   matchingMethod,
//   setMatchingMethod,
// }) => {
//   const [fieldMapping, setFieldMapping] = useAtom(fieldMappingAtom);
//   const [fileData] = useAtom(fileDataAtom);

//   const handleFieldMapping = (
//     field: keyof ImportedPerson,
//     value: string | null
//   ) => {
//     if (value) {
//       setFieldMapping((prev) => ({ ...prev, [field]: value }));
//     } else {
//       setFieldMapping((prev) => {
//         const { [field]: _, ...rest } = prev;
//         return rest;
//       });
//     }
//   };

//   const handleMatchingMethodChange = (value: string) => {
//     if (value === 'byId' || value === 'byNameAndContact') {
//       setMatchingMethod(value);
//     }
//   };

//   const columnOptions = useMemo(() => {
//     if (!fileData || !fileData.headers) return [];
//     return fileData.headers
//       .map((header, index) => ({
//         value: index.toString(),
//         label: `${header} (Column ${index + 1})`,
//       }))
//       .filter((option) => option.label.trim() !== '');
//   }, [fileData]);

//   if (!fileData || !fileData.headers || fileData.headers.length === 0) {
//     return <Text>No file data available. Please upload a file first.</Text>;
//   }

//   return (
//     <Stack>
//       <Text>Specify how to match against existing records:</Text>
//       <SegmentedControl
//         value={matchingMethod}
//         onChange={handleMatchingMethodChange}
//         data={[
//           { label: 'By ID', value: 'byId' },
//           { label: 'By Name and Contact Info', value: 'byNameAndContact' },
//         ]}
//       />
//       {matchingMethod === 'byId' && (
//         <Select
//           label="Person ID"
//           placeholder="Select Person ID column"
//           data={columnOptions}
//           value={fieldMapping.personId || null}
//           onChange={(value) => handleFieldMapping('personId', value)}
//         />
//       )}
//       {matchingMethod === 'byNameAndContact' && (
//         <>
//           <Select
//             label="First Name"
//             placeholder="Select First Name column"
//             data={columnOptions}
//             value={fieldMapping.firstName || null}
//             onChange={(value) => handleFieldMapping('firstName', value)}
//           />
//           <Select
//             label="Last Name"
//             placeholder="Select Last Name column"
//             data={columnOptions}
//             value={fieldMapping.lastName || null}
//             onChange={(value) => handleFieldMapping('lastName', value)}
//           />
//           <Select
//             label="Email"
//             placeholder="Select Email column"
//             data={columnOptions}
//             value={fieldMapping.email || null}
//             onChange={(value) => handleFieldMapping('email', value)}
//           />
//           <Select
//             label="Phone"
//             placeholder="Select Phone column"
//             data={columnOptions}
//             value={fieldMapping.mobilePhone || null}
//             onChange={(value) => handleFieldMapping('mobilePhone', value)}
//           />
//         </>
//       )}
//     </Stack>
//   );
// };

import { SegmentedControl, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';
import { useFileData } from '../../hooks/useFileData';
import { useImportConfiguration } from '../../hooks/useImportConfiguration';

interface MatchingStepProps {
  matchingMethod: 'byId' | 'byNameAndContact';
  setMatchingMethod: (method: 'byId' | 'byNameAndContact') => void;
}

export const MatchingStep: React.FC<MatchingStepProps> = ({
  matchingMethod,
  setMatchingMethod,
}) => {
  const { importConfiguration } = useImportConfiguration();
  const { fileData } = useFileData();

  // const handleFieldMapping = (
  //   field: keyof ImportedPerson,
  //   value: string | null
  // ) => {
  //   if (value) {
  //     setFieldMapping((prev) => ({ ...prev, [field]: value }));
  //   } else {
  //     setFieldMapping((prev) => {
  //       const { [field]: _, ...rest } = prev;
  //       return rest;
  //     });
  //   }
  // };

  const handleMatchingMethodChange = (value: string) => {
    if (value === 'byId' || value === 'byNameAndContact') {
      setMatchingMethod(value);
    }
  };

  const columnOptions = useMemo(() => {
    if (!fileData || !fileData.headers) return [];
    return fileData.headers
      .map((header, index) => ({
        value: index.toString(),
        label: `${header} (Column ${index + 1})`,
      }))
      .filter((option) => option.label.trim() !== '');
  }, [fileData]);

  if (!fileData || !fileData.headers || fileData.headers.length === 0) {
    return <Text>No file data available. Please upload a file first.</Text>;
  }

  return (
    <Stack>
      <Text>Specify how to match against existing records:</Text>
      <SegmentedControl
        value={matchingMethod}
        onChange={handleMatchingMethodChange}
        data={[
          { label: 'By ID', value: 'byId' },
          { label: 'By Name and Contact Info', value: 'byNameAndContact' },
        ]}
      />
      {/* {matchingMethod === 'byId' && (
        <Select
          label="Person ID"
          placeholder="Select Person ID column"
          data={columnOptions}
          value={fieldMapping.personId || null}
          onChange={(value) => handleFieldMapping('personId', value)}
        />
      )}
      {matchingMethod === 'byNameAndContact' && (
        <>
          <Select
            label="First Name"
            placeholder="Select First Name column"
            data={columnOptions}
            value={fieldMapping.firstName || null}
            onChange={(value) => handleFieldMapping('firstName', value)}
          />
          <Select
            label="Last Name"
            placeholder="Select Last Name column"
            data={columnOptions}
            value={fieldMapping.lastName || null}
            onChange={(value) => handleFieldMapping('lastName', value)}
          />
          <Select
            label="Email"
            placeholder="Select Email column"
            data={columnOptions}
            value={fieldMapping.email || null}
            onChange={(value) => handleFieldMapping('email', value)}
          />
          <Select
            label="Phone"
            placeholder="Select Phone column"
            data={columnOptions}
            value={fieldMapping.mobilePhone || null}
            onChange={(value) => handleFieldMapping('mobilePhone', value)}
          />
        </>
      )} */}
    </Stack>
  );
};
