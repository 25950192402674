// hooks/useStepHandlers.ts
import { StepTypeEnum } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useCallback } from 'react';

interface UseStepHandlersProps {
  reset: () => void;
  openStepConfigScreen: (config: any, isNew: boolean, width?: number) => void;
  createStepConfig: (type: 'step' | 'task', stepType: StepTypeEnum) => any;
}

export const useStepHandlers = ({
  reset,
  openStepConfigScreen,
  createStepConfig,
}: UseStepHandlersProps) => {
  const handleStepTypeSelect = useCallback(
    (stepType: StepTypeEnum) => {
      console.log('handleStepTypeSelect: ', stepType);
      reset();
      const newStep = createStepConfig('step', stepType);
      newStep.stepKey = uuid();

      const needsNarrowPanel = [
        StepTypeEnum.actionSendEmail,
        StepTypeEnum.actionPushNotification,
        StepTypeEnum.actionSms,
      ].includes(stepType as any);

      openStepConfigScreen(newStep, true, needsNarrowPanel ? 400 : undefined);
    },
    [reset, openStepConfigScreen, createStepConfig]
  );

  return {
    handleStepTypeSelect,
  };
};
