import {
  FormTaskProperties,
  useGetWorkflowDefinitionByKey,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { FormSubmission } from '@btrway/form-submission';
import { Loader } from '@mantine/core';
import React from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const FormSubmissionTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  onClose,
}) => {
  const { completeTask } = useTaskActionContext();
  const { currentOrganization } = useAuthenticatedUser();
  const typedTaskProperties = taskProperties as FormTaskProperties;
  const workflowDefinitionKey =
    typedTaskProperties.workflowSelection.workflowDefinitionKey.value;

  const { data: workflowDefinition, isLoading } = useGetWorkflowDefinitionByKey(
    currentOrganization.id,
    workflowDefinitionKey || ''
  );

  if (isLoading || !workflowDefinition) {
    return <Loader className="m-auto" />;
  }

  return (
    <FormSubmission
      workflowDefinitionId={workflowDefinition.id}
      // parentTaskListId={parentTaskListId}
      taskProperties={taskProperties}
      onClose={onClose}
      onSubmitSuccess={async () => {
        console.log('FormSubmissionTask onSubmitSuccess');
        await completeTask('Form submitted successfully');
        onClose();
      }}
    />
  );
};

export default FormSubmissionTask;
