import { Card, Container } from '@mantine/core';
import React from 'react';

interface RegistrationFormShellProps {
  children: React.ReactNode;
}

export const RegistrationFormShell: React.FC<RegistrationFormShellProps> = ({
  children,
}) => {
  return (
    <Container
      p="lg"
      size="xl"
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Card
        padding="xl"
        radius="md"
        shadow="md"
        bg="gray.1"
        style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        {children}
      </Card>
    </Container>
  );
};
