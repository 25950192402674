/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ActivityEntityRequest,
  ActivityRequest,
  ActivityResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveActivity = (
    activityRequest: ActivityRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<ActivityResponse>(
      {url: `/activities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityRequest
    },
      options);
    }
  


export const getSaveActivityMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveActivity>>, TError,{data: ActivityRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveActivity>>, TError,{data: ActivityRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveActivity>>, {data: ActivityRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveActivity(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveActivityMutationResult = NonNullable<Awaited<ReturnType<typeof saveActivity>>>
    export type SaveActivityMutationBody = ActivityRequest
    export type SaveActivityMutationError = unknown

    export const useSaveActivity = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveActivity>>, TError,{data: ActivityRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveActivity>>,
        TError,
        {data: ActivityRequest},
        TContext
      > => {

      const mutationOptions = getSaveActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getActivitiesByOrganizationAndEntities = (
    organizationId: number,
    activityEntityRequest: ActivityEntityRequest[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<ActivityResponse[]>(
      {url: `/activities/organizations/${organizationId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityEntityRequest
    },
      options);
    }
  


export const getGetActivitiesByOrganizationAndEntitiesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>, {organizationId: number;data: ActivityEntityRequest[]}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  getActivitiesByOrganizationAndEntities(organizationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetActivitiesByOrganizationAndEntitiesMutationResult = NonNullable<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>>
    export type GetActivitiesByOrganizationAndEntitiesMutationBody = ActivityEntityRequest[]
    export type GetActivitiesByOrganizationAndEntitiesMutationError = unknown

    export const useGetActivitiesByOrganizationAndEntities = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntities>>,
        TError,
        {organizationId: number;data: ActivityEntityRequest[]},
        TContext
      > => {

      const mutationOptions = getGetActivitiesByOrganizationAndEntitiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getActivitiesByOrganizationAndEntitiesWithChildren = (
    organizationId: number,
    activityEntityRequest: ActivityEntityRequest[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<ActivityResponse[]>(
      {url: `/activities/organizations/${organizationId}/withchildren`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityEntityRequest
    },
      options);
    }
  


export const getGetActivitiesByOrganizationAndEntitiesWithChildrenMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>, {organizationId: number;data: ActivityEntityRequest[]}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  getActivitiesByOrganizationAndEntitiesWithChildren(organizationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetActivitiesByOrganizationAndEntitiesWithChildrenMutationResult = NonNullable<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>>
    export type GetActivitiesByOrganizationAndEntitiesWithChildrenMutationBody = ActivityEntityRequest[]
    export type GetActivitiesByOrganizationAndEntitiesWithChildrenMutationError = unknown

    export const useGetActivitiesByOrganizationAndEntitiesWithChildren = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>, TError,{organizationId: number;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesWithChildren>>,
        TError,
        {organizationId: number;data: ActivityEntityRequest[]},
        TContext
      > => {

      const mutationOptions = getGetActivitiesByOrganizationAndEntitiesWithChildrenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getActivitiesByOrganizationAndEntitiesAndType = (
    organizationId: number,
    activityType: string,
    activityEntityRequest: ActivityEntityRequest[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/activities/organizations/${organizationId}/activityType/${activityType}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activityEntityRequest
    },
      options);
    }
  


export const getGetActivitiesByOrganizationAndEntitiesAndTypeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>, TError,{organizationId: number;activityType: string;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>, TError,{organizationId: number;activityType: string;data: ActivityEntityRequest[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>, {organizationId: number;activityType: string;data: ActivityEntityRequest[]}> = (props) => {
          const {organizationId,activityType,data} = props ?? {};

          return  getActivitiesByOrganizationAndEntitiesAndType(organizationId,activityType,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetActivitiesByOrganizationAndEntitiesAndTypeMutationResult = NonNullable<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>>
    export type GetActivitiesByOrganizationAndEntitiesAndTypeMutationBody = ActivityEntityRequest[]
    export type GetActivitiesByOrganizationAndEntitiesAndTypeMutationError = unknown

    export const useGetActivitiesByOrganizationAndEntitiesAndType = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>, TError,{organizationId: number;activityType: string;data: ActivityEntityRequest[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getActivitiesByOrganizationAndEntitiesAndType>>,
        TError,
        {organizationId: number;activityType: string;data: ActivityEntityRequest[]},
        TContext
      > => {

      const mutationOptions = getGetActivitiesByOrganizationAndEntitiesAndTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getActivityById = (
    activityId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ActivityResponse>(
      {url: `/activities/${activityId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetActivityByIdQueryKey = (activityId: number,) => {
    return [`/activities/${activityId}`] as const;
    }

    
export const getGetActivityByIdQueryOptions = <TData = Awaited<ReturnType<typeof getActivityById>>, TError = unknown>(activityId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivityById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActivityByIdQueryKey(activityId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivityById>>> = ({ signal }) => getActivityById(activityId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(activityId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getActivityById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetActivityByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getActivityById>>>
export type GetActivityByIdQueryError = unknown

export const useGetActivityById = <TData = Awaited<ReturnType<typeof getActivityById>>, TError = unknown>(
 activityId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivityById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActivityByIdQueryOptions(activityId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteActivity = (
    activityId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/activities/${activityId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteActivityMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{activityId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{activityId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteActivity>>, {activityId: number}> = (props) => {
          const {activityId} = props ?? {};

          return  deleteActivity(activityId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteActivityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteActivity>>>
    
    export type DeleteActivityMutationError = unknown

    export const useDeleteActivity = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{activityId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteActivity>>,
        TError,
        {activityId: number},
        TContext
      > => {

      const mutationOptions = getDeleteActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
