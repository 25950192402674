import {
  StepCompletionDTO,
  StepConfig,
  StepTypeEnum,
  SubmitFormTaskDTO,
  TaskProperties,
  WorkflowConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { Transition } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useFormSubmission } from '../../hooks/useFormSubmission';
import FormTaskFormStepView from '../FormStep/FormStep';
import FormTaskStartStepView from '../StartStep/StartStep';

interface FormSubmissionViewProps {
  workflowConfiguration: WorkflowConfig;
  workflowDefinitionId: number;
  workflowDefinition: WorkflowDefinitionResponse;
  parentTaskListId?: number;
  taskProperties?: TaskProperties;
  onClose: () => void;
  onSubmitSuccess?: () => Promise<void>;
}

const FormSubmissionView: React.FC<FormSubmissionViewProps> = ({
  workflowConfiguration,
  workflowDefinitionId,
  workflowDefinition,
  parentTaskListId,
  taskProperties,
  onClose,
  onSubmitSuccess,
}) => {
  const { findStartStep, getNextStep, submitStepData } = useFormSubmission(
    workflowDefinitionId,
    workflowConfiguration,
    parentTaskListId,
    taskProperties
  );

  const [currentStep, setCurrentStep] = useState<StepConfig | null>(null);
  const [currentView, setCurrentView] = useState<'start' | 'form'>('start');
  const [prefilledData, setPrefilledData] = useState<any>(null);
  const [allStepData, setAllStepData] = useState<StepCompletionDTO[]>([]);
  const [transitionMounted, setTransitionMounted] = useState(true);

  useEffect(() => {
    const startStep = findStartStep();
    if (startStep) {
      setCurrentStep(startStep);
    }
  }, [findStartStep]);

  const handleStartStepComplete = async (stepData: StepCompletionDTO) => {
    try {
      const response = (await submitStepData(
        [stepData],
        true
      )) as SubmitFormTaskDTO;
      setAllStepData([stepData]);

      const nextStepData = response.stepData?.[response.stepData.length - 1];
      if (nextStepData) {
        setPrefilledData(nextStepData.values);
      }

      const nextStep = getNextStep(currentStep!);

      if (nextStep && nextStep.stepTypeCode === StepTypeEnum.formSubmission) {
        setTransitionMounted(false);
        setTimeout(() => {
          setCurrentStep(nextStep);
          setCurrentView('form');
          setTransitionMounted(true);
        }, 300); // Wait for the exit transition to complete
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error submitting initial step data:', error);
    }
  };

  const handleFormStepComplete = async (formStepData: StepCompletionDTO) => {
    try {
      const updatedStepData = [...allStepData, formStepData];
      const response = (await submitStepData(
        updatedStepData,
        false
      )) as SubmitFormTaskDTO;

      setAllStepData(updatedStepData);

      const nextStepData = response.stepData?.[response.stepData.length - 1];
      if (nextStepData) {
        setPrefilledData(nextStepData.values);
      }

      const nextStep = getNextStep(currentStep!);
      if (nextStep && nextStep.stepTypeCode === StepTypeEnum.formSubmission) {
        setTransitionMounted(false);
        setTimeout(() => {
          setCurrentStep(nextStep);
          setTransitionMounted(true);
        }, 300); // Wait for the exit transition to complete
      } else {
        try {
          await onSubmitSuccess?.();
        } finally {
          onClose();
        }
      }
    } catch (error) {
      console.error('Error submitting workflow:', error);
    }
  };

  if (!currentStep) {
    return null;
  }

  return (
    <div
      style={{
        // position: 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        paddingTop: 'var(--mantine-header-height, 0px)',
      }}
    >
      <Transition
        mounted={transitionMounted}
        transition="fade"
        duration={300}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={{
              ...styles,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {currentView === 'start' ? (
              <FormTaskStartStepView
                workflowDefinition={workflowDefinition}
                currentStep={currentStep}
                onStartClick={handleStartStepComplete}
                workflowConfiguration={workflowConfiguration}
                onCancel={onClose}
              />
            ) : (
              <FormTaskFormStepView
                workflowDefinition={workflowDefinition}
                currentStep={currentStep}
                prefilledData={prefilledData}
                onSubmit={handleFormStepComplete}
                onCancel={onClose}
                onSaveForLater={onClose}
              />
            )}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default FormSubmissionView;
