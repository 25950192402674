import {
  WorkgroupTypeRequest,
  WorkgroupTypeResponse,
  useSaveWorkgroupType,
} from '@btrway/api-core';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { workgroupTypesAtomFamily } from '../atoms/workgroupTypesAtomFamily';

export const useWorkgroupType = (workgroupTypeId: number) => {
  const [workgroupType, setWorkgroupType] = useAtom(
    workgroupTypesAtomFamily(workgroupTypeId)
  );
  const { mutateAsync: saveWorkgroupTypeMutation } = useSaveWorkgroupType();

  const updateWorkgroupType = useCallback(
    async (updatedWorkgroupType: Partial<WorkgroupTypeRequest>) => {
      if (!workgroupType) {
        console.error('WorkgroupType not found');
        return;
      }

      try {
        const saveWorkgroupTypeRequest = {
          ...workgroupType,
          ...updatedWorkgroupType,
          childWorkgroupTypes: undefined,
        } as WorkgroupTypeRequest;

        const savedWorkgroupType = (await saveWorkgroupTypeMutation({
          data: saveWorkgroupTypeRequest,
        })) as WorkgroupTypeResponse;

        console.log('savedWorkgroupType: ', savedWorkgroupType);
        // Update the workgrouptype in the atom family
        setWorkgroupType(savedWorkgroupType);

        return savedWorkgroupType;
      } catch (error) {
        console.error('Error updating workgroupType:', error);
        throw error;
      }
    },
    [workgroupType, saveWorkgroupTypeMutation, setWorkgroupType]
  );

  return {
    workgroupType,
    updateWorkgroupType,
  };
};
