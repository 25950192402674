import { MessageTargetRequest } from '@btrway/api-messaging';
import { DataTypeEnum } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { GlobalSearchControl } from '@btrway/global-search';
import { ScrollArea, Stack, Text } from '@mantine/core';
import React, { useCallback } from 'react';
import { MessageTargetCard } from '../MessageTargetCard/MessageTargetCard';

interface MessageTargetListProps {
  targets: MessageTargetRequest[];
  onAddTarget: (target: MessageTargetRequest) => void;
  onRemoveTarget: (targetId: number) => void;
}

export const MessageTargetList: React.FC<MessageTargetListProps> = ({
  targets,
  onAddTarget,
  onRemoveTarget,
}) => {
  const { currentOrganization } = useAuthenticatedUser();

  const handleEntitySelect = useCallback(
    (entityType: DataTypeEnum, entityId: number) => {
      // Check if target already exists
      const targetExists = targets.some(
        (target) =>
          target.entityType === entityType && target.entityId === entityId
      );

      if (!targetExists) {
        const newTarget: MessageTargetRequest = {
          entityId,
          entityType,
          organizationId: currentOrganization.id,
        };
        onAddTarget(newTarget);
      }
    },
    [targets, onAddTarget, currentOrganization?.id]
  );

  return (
    <Stack gap="md">
      <GlobalSearchControl
        onSelect={handleEntitySelect}
        clearOnSelect={true}
        showSelectedInInput={false}
      />
      <ScrollArea h="calc(100vh - 200px)">
        <Stack gap="xs">
          <Text size="sm" fw={500} c="dimmed">
            Recipients ({targets.length})
          </Text>
          {targets.map((target) => (
            <MessageTargetCard
              key={`${target.entityType}-${target.entityId}`}
              target={target}
              onRemove={onRemoveTarget}
            />
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};
