import { useSecuredAction } from '@btrway/recaptcha-provider';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { publicRegistrationFormAtom } from '../atoms/publicRegistrationFormAtom';
import { registrationFlowAtom } from '../atoms/registrationFlowAtom';
import AuthenticationStep from '../components/AuthenticationStep/AuthenticationStep';
import ChildrenInfo from '../components/ChildrenInfo/ChildrenInfo';
import OtherRelatedPeopleInfo from '../components/OtherRelatedPeopleInfo/OtherRelatedPeopleInfo';
import RegistrantInfo from '../components/RegistrantInfo/RegistrantInfo';
import SummaryInfo from '../components/SummaryInfo/SummaryInfo';
import { RegistrationStep } from '../types/RegistrationStep';
import { useRegistrationSubmission } from './useRegistrationSubmission';
import { useAuthContext } from '@btrway/auth-core';

export const useRegistrationFlow = () => {
  const [flowState, setFlowState] = useAtom(registrationFlowAtom);
  const publicFormRegistration = useAtomValue(publicRegistrationFormAtom);
  const { authState, checkAuthStatus } = useAuthContext();
  const { executeSecuredAction } = useSecuredAction();
  const { handleSubmit: submitRegistration } = useRegistrationSubmission();
  const navigate = useNavigate();

  // Step Configuration
  const { steps, totalSteps } = useMemo(() => {
    const config = publicFormRegistration?.registrationFormConfiguration;

    const allSteps: RegistrationStep[] = [
      {
        title: 'Authentication',
        component: AuthenticationStep,
        type: 'authentication',
        isEnabled: true,
        allowManualNavigation: false,
      },
      {
        title: 'Your Information',
        component: RegistrantInfo,
        type: 'registrant',
        isEnabled: true,
        allowManualNavigation: true,
      },
      {
        title: 'Children/Dependents',
        component: ChildrenInfo,
        type: 'children',
        isEnabled: !!config?.allowChidrenCreation,
        allowManualNavigation: true,
      },
      {
        title: 'Other Related People',
        component: OtherRelatedPeopleInfo,
        type: 'other',
        isEnabled: !!config?.allowOtherRelationshipCreation,
        allowManualNavigation: true,
      },
      {
        title: 'Summary',
        component: SummaryInfo,
        type: 'summary',
        isEnabled: true,
        allowManualNavigation: true,
      },
    ];

    const enabledSteps = allSteps.filter((step) => step.isEnabled);

    return {
      steps: enabledSteps,
      totalSteps: enabledSteps.length,
    };
  }, [publicFormRegistration]);

  useEffect(() => {
    if (authState.isAuthenticated && flowState.currentStep === 0) {
      // If user is authenticated and still on auth step, move to next step
      moveToNextStep();
    }
  }, [authState.isAuthenticated, flowState.currentStep]);

  // Navigation Actions
  const moveToNextStep = useCallback(() => {
    if (flowState.currentStep < totalSteps - 1) {
      setFlowState((prev) => ({
        ...prev,
        completedSteps: [...prev.completedSteps, prev.currentStep],
        currentStep: prev.currentStep + 1,
        reachedSteps: [...prev.reachedSteps, prev.currentStep + 1],
        isSummaryReached:
          prev.currentStep === totalSteps - 2 ? true : prev.isSummaryReached,
      }));
    }
  }, [flowState.currentStep, totalSteps, setFlowState]);

  const moveToStep = useCallback(
    (stepIndex: number) => {
      setFlowState((prev) => ({
        ...prev,
        currentStep: stepIndex,
      }));
    },
    [setFlowState]
  );

  const finishRegistration = useCallback(() => {
    setFlowState((prev) => ({
      ...prev,
      currentStep: totalSteps - 1,
      isSummaryReached: true,
    }));
  }, [totalSteps, setFlowState]);

  const markAsSubmitted = useCallback(() => {
    setFlowState((prev) => ({
      ...prev,
      isSubmitted: true,
    }));
  }, [setFlowState]);

  // Secured Actions
  const handleNext = useCallback(async () => {
    await executeSecuredAction('form_navigation', async () => {
      moveToNextStep();
    });
  }, [executeSecuredAction, moveToNextStep]);

  const handleFinish = useCallback(async () => {
    await executeSecuredAction('form_completion', async () => {
      finishRegistration();
    });
  }, [executeSecuredAction, finishRegistration]);

  const handleSubmit = useCallback(async () => {
    await executeSecuredAction('form_submission', async () => {
      const success = await submitRegistration();
      if (success) {
        markAsSubmitted();
      }

      await checkAuthStatus();

      setTimeout(() => {
        navigate('/app', {replace: true});
      }, 2000);
      return success;
    });
  }, [executeSecuredAction, submitRegistration, markAsSubmitted]);

  // Progress Utilities
  const isStepCompleted = useCallback(
    (stepIndex: number) => flowState.completedSteps.includes(stepIndex),
    [flowState.completedSteps]
  );

  const isStepReached = useCallback(
    (stepIndex: number) => flowState.reachedSteps.includes(stepIndex),
    [flowState.reachedSteps]
  );

  const canNavigateManually = useCallback(
    (stepIndex: number) => steps[stepIndex]?.allowManualNavigation ?? false,
    [steps]
  );

  // Reset Flow
  const resetFlow = useCallback(() => {
    setFlowState({
      currentStep: 0,
      completedSteps: [],
      reachedSteps: [0],
      isSummaryReached: false,
      isSubmitted: false,
    });
  }, [setFlowState]);

  return {
    // Step Configuration
    steps,
    totalSteps,
    currentStep: flowState.currentStep,

    // Progress State
    isStepCompleted,
    isStepReached,
    isSummaryReached: flowState.isSummaryReached,
    isSubmitted: flowState.isSubmitted,

    // Navigation Actions
    moveToStep,
    handleNext,
    handleFinish,
    handleSubmit,
    resetFlow,
    canNavigateManually,
  };
};
