import { DataTypeEnum } from '@btrway/api-core';
import { EntityTaskResponse, TaskInstanceResponse } from '@btrway/api-workflow';

/**
 * Converts a TaskInstanceResponse to an EntityTaskResponse.
 * Used when receiving task data from the API.
 */
export const convertToEntityTaskResponse = (
  response: TaskInstanceResponse,
  originalTask?: Partial<EntityTaskResponse>
): EntityTaskResponse => {
  return {
    // Start with the original task properties if available
    ...(originalTask || {}),

    // Core task fields
    taskInstanceId: response.id,
    organizationId: response.organizationId,
    workgroupId: response.workgroupId,
    assignedEntityId: response.assignedEntityId,
    assignedEntityType: response.assignedEntityType,
    completedBy: response.completedBy,
    completed: response.completed || false,
    completedAt: response.completedAt,
    taskKey: response.taskKey,
    taskTitle: response.taskTitle,
    taskDescription: response.taskDescription,
    taskType: response.taskType,
    dueAt: response.dueAt,
    assignedAt: response.assignedAt,
    taskProperties: response.taskProperties,

    // Workflow and task list related fields
    rollupWorkflowKey: originalTask?.rollupWorkflowKey,
    rollupWorkflowName: originalTask?.rollupWorkflowName,
    rollupWorkflowType: originalTask?.rollupWorkflowType,
    parentWorkflowKey: originalTask?.parentWorkflowKey,

    // Task list configuration
    taskListConfig: originalTask?.taskListConfig,

    // Preserve additional properties from original task
    certificationType: originalTask?.certificationType,
    year: originalTask?.year,
  };
};

/**
 * Type guard to ensure required fields are present on EntityTaskResponse
 */
export function assertRequiredTaskFields(
  task: Partial<EntityTaskResponse>
): asserts task is Required<
  Pick<
    EntityTaskResponse,
    'organizationId' | 'assignedEntityId' | 'assignedEntityType' | 'completed'
  >
> {
  const requiredFields = [
    'organizationId',
    'assignedEntityId',
    'assignedEntityType',
    'completed',
  ] as const;

  for (const field of requiredFields) {
    if (task[field] === undefined) {
      throw new Error(`Missing required field: ${field}`);
    }
  }
}

/**
 * Helper to check if an EntityTaskResponse has all required fields
 */
export const isValidEntityTaskResponse = (
  task: Partial<EntityTaskResponse>
): task is EntityTaskResponse => {
  try {
    assertRequiredTaskFields(task);
    return true;
  } catch {
    return false;
  }
};

/**
 * Creates a minimal valid EntityTaskResponse from required fields
 */
export const createMinimalEntityTaskResponse = (
  organizationId: number,
  assignedEntityId: number,
  assignedEntityType: DataTypeEnum
): EntityTaskResponse => ({
  organizationId,
  assignedEntityId,
  assignedEntityType,
  completed: false,
});
