import {
  FacilityResponse,
  useGetFacilitiesByWorkgroup,
} from '@btrway/api-calendar';
import { CalendarView, CalendarViewRef } from '@btrway/calendar-components';
import { FacilitiesDrawer } from '@btrway/facility-components';
import {
  ActionIcon,
  Box,
  Flex,
  Group,
  Paper,
  Skeleton,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSettings } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

export default function WorkgroupFacilitiesPage() {
  const { workgroup } = useWorkgroup();
  const {
    data: facilities,
    isLoading,
    refetch,
  } = useGetFacilitiesByWorkgroup(workgroup?.id);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedFacility, setSelectedFacility] =
    useState<FacilityResponse | null>(null);
  const calendarRef = useRef<CalendarViewRef>(null);

  const handleFacilityChange = () => {
    // Refetch the facilities list
    refetch();

    // If we have a selected facility and the calendar is mounted,
    // refresh the calendar events
    if (selectedFacility && calendarRef.current) {
      calendarRef.current.fetchEvents();
    }
  };

  if (!workgroup) {
    return <Skeleton height={400} />;
  }

  const sortedFacilities = facilities
    ?.slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );

  return (
    <Flex
      style={{
        height: '100%',
        backgroundColor: 'var(--mantine-color-body)',
      }}
    >
      {/* Facilities Sidebar */}
      <Paper
        withBorder
        p="md"
        m="sm"
        style={{
          width: 300,
          backgroundColor: 'var(--mantine-color-gray-1)',
        }}
      >
        <Stack gap="xl">
          <Group justify="space-between" align="center">
            <Title order={3}>Facilities</Title>
            <ActionIcon
              variant="subtle"
              color="gray"
              size="lg"
              aria-label="Manage facilities"
              onClick={open}
            >
              <IconSettings style={{ width: '70%', height: '70%' }} />
            </ActionIcon>
          </Group>

          {isLoading ? (
            <Stack gap="md">
              <Skeleton height={50} />
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Stack>
          ) : facilities?.length === 0 ? (
            <Text c="dimmed" ta="center" py="xl">
              No facilities found. Click the settings icon to add facilities.
            </Text>
          ) : (
            <Stack gap="md">
              {sortedFacilities?.map((facility) => (
                <Paper
                  key={facility.id}
                  p="md"
                  withBorder
                  style={{
                    cursor: 'pointer',
                    backgroundColor:
                      selectedFacility?.id === facility.id
                        ? 'var(--mantine-color-blue-0)'
                        : 'var(--mantine-color-white)',
                    borderColor:
                      selectedFacility?.id === facility.id
                        ? 'var(--mantine-color-blue-5)'
                        : undefined,
                  }}
                  onClick={() => setSelectedFacility(facility)}
                >
                  <Stack gap="xs">
                    <Text fw={500}>{facility.name}</Text>
                    {facility.attributes?.description && (
                      <Text size="sm" c="dimmed" lineClamp={2}>
                        {facility.attributes.description}
                      </Text>
                    )}
                  </Stack>
                </Paper>
              ))}
            </Stack>
          )}
        </Stack>
      </Paper>

      {/* Calendar View */}
      <Box
        pt="sm"
        pr="sm"
        pl="sm"
        style={{
          flex: 1,
          backgroundColor: 'var(--mantine-color-body)',
        }}
      >
        {selectedFacility ? (
          <CalendarView
            ref={calendarRef}
            workgroup={workgroup}
            facilityId={selectedFacility.id}
            defaultView="timeGridWeek"
          />
        ) : (
          <Paper
            h="100%"
            withBorder
            p="xl"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text size="lg" c="dimmed">
              Select a facility to view its calendar
            </Text>
          </Paper>
        )}
      </Box>

      <FacilitiesDrawer
        opened={opened}
        onClose={close}
        workgroup={workgroup}
        facilities={facilities ?? []}
        onFacilityChange={handleFacilityChange}
      />
    </Flex>
  );
}
