import { abwlogosymbol } from '@btrway/assets';
import { Box, Card, Center, Container, Image } from '@mantine/core';
import React from 'react';
import { LoginForm } from '../../components/LoginForm/LoginForm';

const LoginPage: React.FC = () => {
  // const navigate = useNavigate();
  // const initialCheckDone = useRef(false);
  // const { authState, checkAuthStatus } = useAuthContext();

  // useEffect(() => {
  //   if (!initialCheckDone.current) {
  //     if (authState.isAuthenticated) {
  //       navigate('/app');
  //     }
  //     initialCheckDone.current = true;
  //   }
  // }, [navigate]);

  return (
    <Box bg="gray.1" h="100vh">
      <Container size="sm" h="100%">
        <Center h="100%">
          <Card shadow="sm" bg="white" padding="lg" radius="md" withBorder>
            <Card.Section pt="md">
              <Image
                src={abwlogosymbol}
                alt="Your Company Logo"
                height={140}
                fit="contain"
              />
            </Card.Section>
            <LoginForm />
          </Card>
        </Center>
      </Container>
    </Box>
  );
};

export default LoginPage;
