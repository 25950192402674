import {
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { Button, Modal, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';

interface AddTaskTemplateModalProps {
  workflowType: WorkflowTypeEnum;
  opened: boolean;
  onClose: () => void;
  onCreateRoutine: (
    partialWorkflowTemplate: Partial<WorkflowTemplateRequest>
  ) => void;
}

const AddTaskTemplateModal = ({
  workflowType,
  opened,
  onClose,
  onCreateRoutine,
}: AddTaskTemplateModalProps) => {
  const [routineName, setRoutineName] = useState('');
  const typeDescription =
    workflowType === WorkflowTypeEnum.taskList ? 'Task List' : 'Task Flow';

  const handleCreateRoutine = () => {
    if (routineName) {
      onCreateRoutine({
        name: routineName,
        workflowType: workflowType,
        templateSettings: { defaultUserRoles: ['Coach', 'System AD'] },
      });
      resetState();
      onClose();
    }
  };

  const resetState = () => {
    setRoutineName('');
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetState();
        onClose();
      }}
      title={
        <Text fz="xl" fw={600}>
          Add {typeDescription}
        </Text>
      }
      size="80%"
      h={600}
    >
      <Modal.Body mih="calc(100vh *.6)">
        <TextInput
          label={`${typeDescription} Name`}
          placeholder={`Enter ${typeDescription} Name`}
          value={routineName}
          onChange={(event) => setRoutineName(event.currentTarget.value)}
          data-autofocus
          withAsterisk
        />
      </Modal.Body>

      <Button
        onClick={handleCreateRoutine}
        leftSection={<IconPlus size={16} />}
      >
        Save
      </Button>
    </Modal>
  );
};

export default AddTaskTemplateModal;
