import { WorkflowTemplateSettings } from '@btrway/api-workflow';
import {
  useOrganizationTypes,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/security-manager';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import { Button, Chip, Group, Stack, TagsInput, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

interface DeploymentFilterEditProps {
  templateSettings?: WorkflowTemplateSettings | null;
  onSave: (templateSettings: WorkflowTemplateSettings) => void;
}

export const DeploymentFilterEdit: React.FC<DeploymentFilterEditProps> = ({
  templateSettings,
  onSave,
}) => {
  const organizationFilter = templateSettings?.organizationFilter;
  const { closeDisplay } = useSettingsDisplay();
  const { stateProvinces, isLoading: isLoadingStates } = useStateProvinces();
  const { organizationTypes, isLoading: isLoadingOrganizationTypes } =
    useOrganizationTypes();
  const { userRoles, getUserRoleBySlug } = useUserRoles();

  const [selectedStates, setSelectedStates] = useState<string[]>(
    organizationFilter?.stateCodes || []
  );
  const [selectedOrgTypes, setSelectedOrgTypes] = useState<string[]>(
    organizationFilter?.organizationTypeIds?.map(String) || []
  );
  const [selectedUserRoles, setSelectedUserRoles] = useState<string[]>(
    templateSettings?.defaultUserRoles || []
  );

  // Create suggestions using flatMap to ensure proper array structure
  const userRoleSuggestions = useMemo(
    () =>
      userRoles?.flatMap((role) => [
        {
          value: role.slug,
          label: role.name,
        },
      ]) || [],
    [userRoles]
  );

  // Create a mapping between slugs and names
  const slugToNameMap = useMemo(() => {
    return Object.fromEntries(
      userRoles?.map((role) => [role.slug, role.name]) || []
    );
  }, [userRoles]);

  // Convert slugs to names for display
  const selectedUserRoleNames = useMemo(() => {
    return selectedUserRoles.map((slug) => slugToNameMap[slug] || slug);
  }, [selectedUserRoles, slugToNameMap]);

  const handleSave = () => {
    const newTemplateSettings: WorkflowTemplateSettings = {
      ...templateSettings,
      defaultUserRoles: selectedUserRoles,
      organizationFilter: {
        ...organizationFilter,
        stateCodes: selectedStates,
        organizationTypeIds: selectedOrgTypes.map(Number),
      },
    };
    onSave(newTemplateSettings);
    closeDisplay();
  };

  const handleUserRolesChange = (values: string[]) => {
    // Convert displayed names back to slugs if they exist in suggestions
    const newSlugs = values.map((value) => {
      const suggestion = userRoleSuggestions.find((s) => s.label === value);
      return suggestion ? suggestion.value : value;
    });
    setSelectedUserRoles(newSlugs);
  };

  const sortedStates =
    stateProvinces?.slice().sort((a, b) => a.code.localeCompare(b.code)) || [];
  const sortedOrgTypes =
    organizationTypes
      ?.filter((type) => !type.internal)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  if (isLoadingStates || isLoadingOrganizationTypes) {
    return <div>Loading...</div>;
  }

  return (
    <Stack gap="md" style={{ height: '100%', position: 'relative' }}>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <Stack p="md" gap="md">
          <div>
            <Text fz="md" fw={600} mb="md">
              Default User Roles
            </Text>
            <TagsInput
              value={selectedUserRoleNames}
              onChange={handleUserRolesChange}
              data={userRoleSuggestions.map((s) => s.label)}
              placeholder="Select user roles"
              clearable
            />
          </div>

          <div>
            <Text fz="md" fw={600} mb="md">
              Filter by Organization Type
            </Text>
            <Chip.Group
              multiple
              value={selectedOrgTypes}
              onChange={setSelectedOrgTypes}
            >
              <Group gap="xs" justify="flex-start">
                {sortedOrgTypes.map((orgType) => (
                  <Chip key={orgType.id} value={String(orgType.id)}>
                    {orgType.name}
                  </Chip>
                ))}
              </Group>
            </Chip.Group>
          </div>

          <div>
            <Text fz="md" fw={600} mb="md">
              Filter By State
            </Text>
            <Chip.Group
              multiple
              value={selectedStates}
              onChange={setSelectedStates}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: '10px',
                }}
              >
                {sortedStates.map((state) => (
                  <Chip
                    key={state.code}
                    value={state.code}
                    styles={{
                      label: {
                        width: '100%',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    {state.code}
                  </Chip>
                ))}
              </div>
            </Chip.Group>
          </div>
        </Stack>
      </div>

      <Group
        justify="flex-end"
        p="sm"
        bg="gray.1"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'var(--mantine-color-gray-1)',
        }}
      >
        <Button variant="subtle" onClick={closeDisplay}>
          Cancel
        </Button>
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </Stack>
  );
};
