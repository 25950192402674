// components/ChapterBuilder.tsx
import { ChapterDTO } from '@btrway/api-courseware';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { Box, Stack } from '@mantine/core';
import React, { useEffect } from 'react';
import ChapterList from './components/ChapterList/ChapterList';
import { useChapterBuilderSet } from './hooks/useChapterBuilderSet';

interface ChapterBuilderProps {
  initialChapters?: ChapterDTO[];
}

const ChapterBuilder: React.FC<ChapterBuilderProps> = ({
  initialChapters = [],
}) => {
  useAddHelpTags(['chapter']);

  const { initializeChapters } = useChapterBuilderSet();

  useEffect(() => {
    initializeChapters(initialChapters);
  }, [initialChapters]);

  return (
    <Stack w="100%" h="100%" gap={0} style={{ minHeight: 0 }}>
      <Box style={{ flex: 1, minHeight: 0 }}>
        <ChapterList />
      </Box>
    </Stack>
  );
};

export default ChapterBuilder;
