import { atom, useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { isFileUploadedAtom } from '../atoms/isFileUploadedAtom';
import { ColumnMappingStep } from '../components/ColumnMappingStep/ColumnMappingStep';
import { FieldValueMappingStep } from '../components/FieldValueMappingStep/FieldValueMappingStep';
import { MatchingStep } from '../components/MatchingStep/MatchingStep';
import { ReviewSubmitStep } from '../components/ReviewSubmitStep/ReviewSubmitStep';
import { SheetSelectionStep } from '../components/SheetSelectionStep/SheetSelectionStep';
import { UploadFileStep } from '../components/UploadFileStep/UploadFileStep';
import { WorkgroupMappingStep } from '../components/WorkgroupMappingStep/WorkgroupMappingStep';
import { ImportSteps, baseFields } from '../constants';
import { useImportConfiguration } from './useImportConfiguration';

interface Step {
  key: string;
  label: string;
  description: string;
  component: React.ComponentType<any>;
  group: string;
}

// Define Jotai atoms for state management
export const activeGroupAtom = atom(0);
export const activeStepInGroupAtom = atom(0);
export const maxReachedGroupAtom = atom(0);

export const useImportSteps = () => {
  //Column mappings probably re-rendering with each update of value mappings
  const { importConfiguration } = useImportConfiguration();

  const [activeGroup, setActiveGroup] = useAtom(activeGroupAtom);
  const [activeStepInGroup, setActiveStepInGroup] = useAtom(
    activeStepInGroupAtom
  );
  const [maxReachedGroup, setMaxReachedGroup] = useAtom(maxReachedGroupAtom);
  const [isFileUploaded, setIsFileUploaded] = useAtom(isFileUploadedAtom);
  // const [needsSheetSelection, setNeedsSheetSelection] = useAtom(
  //   needsSheetSelectionAtom
  // );

  const steps = useMemo(() => {
    const staticSteps: Step[] = [
      {
        key: ImportSteps.UploadFile,
        label: 'Upload File',
        description: "Let's Get This Import Started!",
        component: UploadFileStep,
        group: 'upload',
      },
      {
        key: ImportSteps.SheetSelection,
        label: 'Select Sheet',
        description: "Your file has multiple sheets. Let's pick one.",
        component: SheetSelectionStep,
        group: 'upload',
      },
      {
        key: ImportSteps.ColumnMapping,
        label: 'Mapping',
        description:
          "Now let's map your file's columns to the available fields.",
        component: ColumnMappingStep,
        group: 'mapping',
      },
      {
        key: ImportSteps.WorkgroupMapping,
        label: 'Membership',
        description:
          'Assign your data to the correct level of your organization.',
        component: WorkgroupMappingStep,
        group: 'mapping',
      },
      {
        key: ImportSteps.Matching,
        label: 'Matching',
        description: "Let's match your data against existing records.",
        component: MatchingStep,
        group: 'matching',
      },
      {
        key: ImportSteps.ReviewSubmit,
        label: 'Submit',
        description: 'All set! Ready to submit?',
        component: ReviewSubmitStep,
        group: 'submit',
      },
    ];

    const dynamicSteps: Step[] = importConfiguration.columnMappings
      .filter((mapping) => {
        const field = baseFields.find((f) => f.key === mapping.targetField);
        return field && field.fieldType.type === 'select';
      })
      .map((mapping) => {
        const field = baseFields.find((f) => f.key === mapping.targetField);
        const fieldLabel = field ? field.label : mapping.targetField;
        return {
          key: `dynamic-${mapping.targetField}`,
          label: `Map ${fieldLabel} Values`,
          description: `Map the values in the ${fieldLabel} column.`,
          component: () => (
            <FieldValueMappingStep fieldKey={mapping.targetField} />
          ),
          group: 'mapping',
        };
      });

    // console.log(
    //   'useImportSteps, steps, needsSheetSelection: ',
    //   needsSheetSelection
    // );
    const allSteps = [
      staticSteps[0], // Upload File
      // ...(needsSheetSelection ? [staticSteps[1]] : []),
      ...staticSteps.slice(2, -1), // Other static steps
      ...dynamicSteps,
      staticSteps[staticSteps.length - 1], // Submit step
    ];

    // console.log('useImportSteps, allSteps: ', allSteps);

    return allSteps;
  }, [importConfiguration.columnMappings]);

  const groupedSteps = useMemo(() => {
    const groups = steps.reduce((acc, step) => {
      if (!acc[step.group]) {
        acc[step.group] = [];
      }
      acc[step.group].push(step);
      return acc;
    }, {} as Record<string, typeof steps>);

    return Object.entries(groups).map(([groupName, groupSteps]) => ({
      label: groupSteps[0].label,
      steps: groupSteps,
    }));
  }, [steps]);

  const nextStep = useCallback(() => {
    console.log('useImportSteps, nextStep called, step count: ', steps.length);
    console.log('steps: ', steps);
    const currentGroup = groupedSteps[activeGroup];
    if (activeStepInGroup < currentGroup.steps.length - 1) {
      setActiveStepInGroup(activeStepInGroup + 1);
    } else if (activeGroup < groupedSteps.length - 1) {
      setActiveGroup(activeGroup + 1);
      setActiveStepInGroup(0);
      setMaxReachedGroup(Math.max(maxReachedGroup, activeGroup + 1));
    }
  }, [
    activeGroup,
    activeStepInGroup,
    groupedSteps,
    maxReachedGroup,
    setActiveGroup,
    setActiveStepInGroup,
    setMaxReachedGroup,
  ]);

  const prevStep = useCallback(() => {
    if (activeStepInGroup > 0) {
      setActiveStepInGroup(activeStepInGroup - 1);
    } else if (activeGroup > 0) {
      setActiveGroup(activeGroup - 1);
      setActiveStepInGroup(groupedSteps[activeGroup - 1].steps.length - 1);
    }
  }, [
    activeGroup,
    activeStepInGroup,
    groupedSteps,
    setActiveGroup,
    setActiveStepInGroup,
  ]);

  const goToStep = useCallback(
    (groupIndex: number) => {
      if (groupIndex <= maxReachedGroup) {
        setActiveGroup(groupIndex);
        setActiveStepInGroup(0);
      }
    },
    [maxReachedGroup, setActiveGroup, setActiveStepInGroup]
  );

  const handleFileUploaded = useCallback(() => {
    setIsFileUploaded(true);
    console.log('useImportSteps, handleFileUploaded called');
    nextStep();
  }, [setIsFileUploaded, nextStep]);

  const handleSheetSelected = useCallback(
    (sheet: string) => {
      nextStep();
    },
    [nextStep]
  );

  return {
    steps,
    groupedSteps,
    activeGroup,
    activeStepInGroup,
    maxReachedGroup,
    nextStep,
    prevStep,
    goToStep,
    setActiveStepInGroup,
    isFileUploaded,
    handleFileUploaded,
    handleSheetSelected,
  };
};
