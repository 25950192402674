import {
  DateAssignedTypeEnum,
  TaskConfig,
  TaskListTaskConfig,
  TaskMetadata,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { Stack, Text, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { TaskMetadataConfiguration } from '../../config-components/task/TaskMetadataConfiguration/TaskMetadataConfiguration';
import { TaskListTaskConfiguration } from '../../config-components/taskList/TaskListTaskConfiguration/TaskListTaskConfiguration';
import { taskComponentRegistry } from '../../config/componentRegistry';
import { ConfigEditorWrapper } from '../ConfigEditorWrapper/ConfigEditorWrapper';

interface TaskConfigEditorProps {
  initialTaskConfig: TaskConfig;
  workflow: CommonWorkflow;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (config: TaskConfig, isNew?: boolean) => Promise<void>;
  isNew?: boolean;
}

export const TaskConfigEditor: React.FC<TaskConfigEditorProps> = ({
  initialTaskConfig,
  // workflowType,
  workflow,
  onClose,
  onDelete,
  onSave,
  isNew = false,
}) => {
  const workflowType = workflow.workflowType;

  const [currentConfig, setCurrentConfig] = useState<TaskConfig | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  useEffect(() => {
    if (initialTaskConfig) {
      setCurrentConfig(initialTaskConfig);
    }
  }, [initialTaskConfig]);

  // Only allow TaskList workflow type
  if (
    workflowType !== WorkflowTypeEnum.taskList &&
    workflowType !== WorkflowTypeEnum.formPacket &&
    workflowType !== WorkflowTypeEnum.curriculum &&
    workflowType !== WorkflowTypeEnum.taskOption
  ) {
    console.log(
      'TaskConfigEditor can only be used with WorkflowTypes where tasks can be added to them'
    );
    return null;
  }

  if (!currentConfig) {
    return null;
  }

  const handleConfigChange = (updatedConfig: Partial<TaskConfig>) => {
    setCurrentConfig((prev) => {
      if (!prev) return null;
      const newConfig = {
        ...prev,
        ...updatedConfig,
        taskListConfig: {
          ...prev.taskListConfig,
          ...updatedConfig.taskListConfig,
        },
        taskProperties: {
          ...prev.taskProperties,
          ...updatedConfig.taskProperties,
        },
      };
      return newConfig;
    });
    setHasUnsavedChanges(true);
  };

  const handleTaskListConfigChange = (taskListConfig: TaskListTaskConfig) => {
    handleConfigChange({ taskListConfig });
  };

  const handleMetadataChange = (metadata: TaskMetadata) => {
    handleConfigChange({ metadata });
  };

  const handleSave = async () => {
    if (currentConfig) {
      await onSave(currentConfig, isNew);
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const handleCancel = () => {
    setHasUnsavedChanges(false);
    onClose();
  };

  // Get the task-specific component if one exists
  const TaskTypeComponent =
    taskComponentRegistry[currentConfig.taskType]?.component;

  //Temporary solution until we decide how to sub-type task lists
  const isAbwTaskList =
    workflow.workflowMetadata?.taskListType === 'ABW To-Do List';
  const isSpecificDays =
    workflow.workflowConfiguration?.settings?.taskListSettings
      ?.assignedDateType === DateAssignedTypeEnum.taskSpecific;

  const hideTaskComponent =
    currentConfig.taskType === TaskTypeEnum.completeTask && isAbwTaskList;

  return (
    <ConfigEditorWrapper
      hasUnsavedChanges={hasUnsavedChanges}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
      onDelete={onDelete}
    >
      <Stack gap="xl">
        {TaskTypeComponent && !hideTaskComponent && (
          <Stack gap="md">
            <Title order={4}>Options</Title>
            <TaskTypeComponent
              config={currentConfig}
              onConfigChange={handleConfigChange}
            />
          </Stack>
        )}

        {isAbwTaskList && (
          <Stack gap="md">
            <Title order={4}>ABW To-Do List Information</Title>
            <Text size="sm" c="dimmed">
              Configure task information and description
            </Text>
            <TaskMetadataConfiguration
              metadata={currentConfig.metadata || {}}
              onChange={handleMetadataChange}
            />
          </Stack>
        )}

        {isSpecificDays && (
          <Stack gap="md">
            <Title order={4}>Relative Assignment Schedule</Title>
            <Text size="sm" c="dimmed">
              Configure when tasks will be assigned and due, offset from the
              beginning of the task list
            </Text>
            <TaskListTaskConfiguration
              config={currentConfig.taskListConfig || {}}
              onChange={handleTaskListConfigChange}
            />
          </Stack>
        )}
      </Stack>
    </ConfigEditorWrapper>
  );
};
