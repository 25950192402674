import { HelpRepositoryEnum } from '@btrway/api-help';
import { IconBulb, IconFile, IconHelp, IconStar } from '@tabler/icons-react';

export const repositoryTabs = [
  {
    value: 'selected' as const,
    label: 'Selected',
    icon: IconStar,
  },
  {
    value: HelpRepositoryEnum.clientWhy,
    label: 'Why',
    icon: IconBulb,
  },
  {
    value: HelpRepositoryEnum.clientHow,
    label: 'How-To',
    icon: IconHelp,
  },
  {
    value: HelpRepositoryEnum.clientFiles,
    label: 'Files',
    icon: IconFile,
  },
];
