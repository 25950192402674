/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskListStartTypeEnum = typeof TaskListStartTypeEnum[keyof typeof TaskListStartTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskListStartTypeEnum = {
  byTaskAssignment: 'byTaskAssignment',
  bySpecificDay: 'bySpecificDay',
  byConfiguredDate: 'byConfiguredDate',
} as const;
