import { MessageTargetRequest } from '@btrway/api-messaging';
import { DataTypeEnum } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { Box, Paper } from '@mantine/core';
import React from 'react';

interface MessageTargetCardProps {
  target: MessageTargetRequest;
  onRemove: (targetId: number) => void;
}

export const MessageTargetCard: React.FC<MessageTargetCardProps> = ({
  target,
  onRemove,
}) => {
  return (
    <Paper shadow="xs" p="xs" withBorder>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <EntityTag
          dataType={target.entityType as keyof typeof DataTypeEnum}
          id={target.entityId}
          onClick={() => onRemove(target.entityId)}
        />
      </Box>
    </Paper>
  );
};
