import { rem } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import Color from '@tiptap/extension-color';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect, useRef } from 'react';
import { CustomTextStyle } from '../../extensions/CustomTextStyleExtension';
import { DynamicContent } from '../../extensions/DynamicContentExtension';
import { MergeTokenNode } from '../../extensions/MergeTokenNodeExtension';
import { EditorContent } from '../../types/editorContent';
import { parseContent } from '../../utils/contentUtils';
import { ContentToolbar } from '../ContentToolbar/ContentToolbar';

export interface ContentEditorProps {
  initialContent?: unknown;
  onChange: (content: EditorContent, html: string) => void;
  editor?: Editor | null;
  onEditorReady?: (editor: Editor) => void;
}

export const ContentEditor: React.FC<ContentEditorProps> = ({
  initialContent,
  onChange,
  editor: externalEditor,
  onEditorReady,
}) => {
  console.log('Render ContentEditor');
  // Use a ref to store the onChange callback
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const internalEditor = useEditor(
    {
      extensions: [
        StarterKit,
        CustomTextStyle,
        Color,
        MergeTokenNode,
        DynamicContent,
      ],
      content: parseContent(initialContent),
      onUpdate: ({ editor }) => {
        onChangeRef.current(
          editor.getJSON() as EditorContent,
          editor.getHTML()
        );
      },
    },
    []
  );

  const editorInstance = externalEditor || internalEditor;

  // Handle initial content updates
  useEffect(() => {
    if (editorInstance && initialContent) {
      const newContent = parseContent(initialContent);
      const currentContent = editorInstance.getJSON();

      // Only update if content actually changed
      if (JSON.stringify(newContent) !== JSON.stringify(currentContent)) {
        editorInstance.commands.setContent(newContent, false);
      }
    }
  }, [editorInstance, initialContent]);

  // Handle editor ready callback
  useEffect(() => {
    if (editorInstance && onEditorReady) {
      onEditorReady(editorInstance);
    }
  }, [editorInstance, onEditorReady]);

  if (!editorInstance) {
    return null;
  }

  return (
    <RichTextEditor
      editor={editorInstance}
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          border: `${rem(1)} solid var(--mantine-color-gray-4)`,
          backgroundColor: 'var(--mantine-color-body)',
        },
        toolbar: {
          borderBottom: `${rem(1)} solid var(--mantine-color-gray-4)`,
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--mantine-color-body)',
          zIndex: 2,
        },
        content: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        },
        typographyStylesProvider: {
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0,
        },
      }}
    >
      <ContentToolbar editor={editorInstance} />
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          padding: 'var(--mantine-spacing-xs)',
        }}
      >
        <RichTextEditor.Content />
      </div>
    </RichTextEditor>
  );
};
