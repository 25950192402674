import {
  SendEmailInputProperties,
  StepConfig,
  StepTypeEnum,
} from '@btrway/api-workflow';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { StepConfigProps } from '../../../types/editors';

const defaultEmailConfig: SendEmailInputProperties = {
  senderEmail: { value: '' },
  recipientEmail: { value: '' },
  subject: { value: '' },
  body: { value: '' },
};

export const SendEmailConfiguration: React.FC<StepConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const [emailConfig, setEmailConfig] = useState<SendEmailInputProperties>(
    (config.inputProperties as SendEmailInputProperties) || defaultEmailConfig
  );

  useEffect(() => {
    setEmailConfig(
      (config.inputProperties as SendEmailInputProperties) || defaultEmailConfig
    );
  }, [config]);

  const handleInputChange = (
    field: keyof SendEmailInputProperties,
    value: string
  ) => {
    const updatedEmailConfig = {
      ...emailConfig,
      [field]: { ...emailConfig[field], value },
    };
    setEmailConfig(updatedEmailConfig);

    const updatedConfig: StepConfig = {
      ...config,
      stepTypeCode: StepTypeEnum.actionSendEmail,
      inputProperties: updatedEmailConfig,
    };
    if (onConfigChange) {
      onConfigChange(updatedConfig);
    }
  };

  return (
    <Stack>
      <TextInput
        label="Sender Email"
        placeholder="Enter sender email"
        value={emailConfig.senderEmail?.value || ''}
        onChange={(event) =>
          handleInputChange('senderEmail', event.currentTarget.value)
        }
      />
      <TextInput
        label="Recipient Email"
        placeholder="Enter recipient email"
        value={emailConfig.recipientEmail?.value || ''}
        onChange={(event) =>
          handleInputChange('recipientEmail', event.currentTarget.value)
        }
      />
      <TextInput
        label="Subject"
        placeholder="Enter email subject"
        value={emailConfig.subject?.value || ''}
        onChange={(event) =>
          handleInputChange('subject', event.currentTarget.value)
        }
      />
      <Textarea
        label="Email Body"
        placeholder="Enter email body"
        value={emailConfig.body?.value || ''}
        onChange={(event) =>
          handleInputChange('body', event.currentTarget.value)
        }
        rows={8}
      />
    </Stack>
  );
};
