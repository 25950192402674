import { Stack } from '@mantine/core';
import { useRegistrationFlow } from '../../hooks/useRegistrationFlow';
import { RegistrationStep } from '../../types/RegistrationStep';
import { RegistrationFormHeader } from '../RegistrationFormHeader/RegistrationFormHeader';
import { RegistrationFormStep } from '../RegistrationFormStep/RegistrationFormStep';

interface RegistrationSidebarProps {
  steps: RegistrationStep[];
  onStepClick: (index: number) => void;
}

export const RegistrationSidebar: React.FC<RegistrationSidebarProps> = ({
  steps,
  onStepClick,
}) => {
  const {
    currentStep,
    isStepCompleted,
    isStepReached,
    isSummaryReached,
    isSubmitted,
  } = useRegistrationFlow();

  const visibleSteps = isSummaryReached ? steps : steps.slice(0, -1);

  return (
    <>
      <RegistrationFormHeader />
      {!isSubmitted && (
        <Stack gap="md">
          {visibleSteps.map((step, index) => (
            <RegistrationFormStep
              key={step.type}
              title={step.title}
              index={index}
              isSelected={currentStep === index}
              isCompleted={isStepCompleted(index)}
              isReached={isStepReached(index)}
              onClick={() => onStepClick(index)}
            />
          ))}
        </Stack>
      )}
    </>
  );
};
