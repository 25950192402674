import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAuthContext } from '@btrway/auth-core';
import axios from 'axios';
import { createAxiosInstance } from '@btrway/api-client-provider';

export const Callback: React.FC = () => {
  const navigate = useNavigate();
  const { checkAuthStatus, authState, updateAuthState } = useAuthContext();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const redirectUri = sessionStorage.getItem('redirect_uri');
        const savedState = sessionStorage.getItem('oauth_state');
        const apiUrl = authState.apiUrl;
        
        // Verify state parameter
        if (!state || state !== savedState) {
          throw new Error('Invalid state parameter');
        }

        // Clear stored state
        sessionStorage.removeItem('oauth_state');
        sessionStorage.removeItem('redirect_uri');

        if (!code) {
          throw new Error('No authorization code received');
        }

        let callbackUrl = `${apiUrl}/auth/callback?` +
        `code=${code}` +
        `&redirectUri=${redirectUri}`;

        // Exchange code for tokens
        const response = await axios.get(callbackUrl, {
          withCredentials: true
        });

        if (!(response.status === 200)) {
          throw new Error('Failed to exchange code for tokens');
        }

        // Immediately update auth state
        updateAuthState({
          status: 'authenticated',
          isAuthenticated: true
        });

        createAxiosInstance(apiUrl);

        await checkAuthStatus();

        navigate('/app', {replace: true});
      } catch (error) {
        console.error('Authentication error:', error);
        navigate('/login', { 
          replace: true,
          state: { error: 'Authentication failed. Please try again.' }
        });
      }
    };

    handleCallback();
  }, [navigate, checkAuthStatus]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-2">Completing Login...</h2>
        <p className="text-gray-600">Please wait while we finish authenticating you.</p>
      </div>
    </div>
  );
};