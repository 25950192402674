import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import {
  Button,
  Card,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { chaptersAtom } from '../../atoms/chaptersAtom';
import { useChapterBuilderSet } from '../../hooks/useChapterBuilderSet';
import ChapterCard from '../ChapterCard/ChapterCard';

const ChapterList: React.FC = () => {
  const [chapters, setChapters] = useAtom(chaptersAtom);
  const { addChapter } = useChapterBuilderSet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chapterInput, setChapterInput] = useState('');
  const [openChapterKey, setOpenChapterKey] = useState<string | null>(null);

  const handleAddMultipleChapters = () => {
    const newChapters = chapterInput
      .split('\n')
      .filter((title) => title.trim() !== '')
      .map((title, index) => ({
        key: `${Date.now()}-${index}`,
        title: title.trim(),
        description: '',
      }));

    newChapters.forEach(addChapter);
    setIsModalOpen(false);
    setChapterInput('');
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(chapters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setChapters(items);
  };

  return (
    <Card withBorder radius="md" p="md">
      <Stack gap="md" pb="lg">
        <Group justify="space-between" wrap="nowrap">
          <Text size="lg" fw={600}>
            Chapters
          </Text>
          <Button onClick={() => setIsModalOpen(true)}>Add Chapters</Button>
        </Group>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="chapters">
            {(provided) => (
              <Stack
                gap="md"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {chapters.map((chapter, index) => (
                  <Draggable
                    key={chapter.key}
                    draggableId={chapter.key}
                    index={index}
                    isDragDisabled={openChapterKey === chapter.key}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          opacity: snapshot.isDragging ? 0.5 : 1,
                        }}
                      >
                        <ChapterCard
                          chapterKey={chapter.key}
                          isDragging={snapshot.isDragging}
                          isOpen={openChapterKey === chapter.key}
                          onToggleOpen={(isOpen) =>
                            setOpenChapterKey(isOpen ? chapter.key : null)
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Chapters"
      >
        <Textarea
          value={chapterInput}
          onChange={(event) => setChapterInput(event.currentTarget.value)}
          placeholder="Enter chapter titles, one per line"
          autosize
          minRows={20}
          maxRows={20}
          autoFocus
          data-autoFocus
        />
        <Button onClick={handleAddMultipleChapters} mt="md" fullWidth>
          Add Chapters
        </Button>
      </Modal>
    </Card>
  );
};

export default ChapterList;
