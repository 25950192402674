import { HelpRepositoryEnum } from '@btrway/api-help';
import { useAtomValue, useSetAtom } from 'jotai';
import { helpResourceEditorActionsAtom } from '../atoms/helpResourceEditorActionsAtom';
import { helpResourceEditorStateAtom } from '../atoms/helpResourceEditorStateAtom';

export const useHelpResourceEditor = () => {
  const state = useAtomValue(helpResourceEditorStateAtom);
  const dispatch = useSetAtom(helpResourceEditorActionsAtom);

  return {
    // State
    ...state,

    // Actions
    openHelpResourceEditor: (
      helpResourceId: number | null,
      repository: HelpRepositoryEnum,
      onAfterSave?: () => void
    ) =>
      dispatch({
        type: 'open',
        helpResourceId,
        repository,
        onAfterSave,
      }),
    closeHelpResourceEditor: () => dispatch({ type: 'close' }),
  };
};
