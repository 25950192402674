import {
  EntityTaskResponse,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useTaskManager } from '@btrway/task-manager';
import { useMemo } from 'react';

interface TaskStats {
  tasks: EntityTaskResponse[];
  completed: number;
  total: number;
  progress: number;
  remainingCount: number;
  nextDueDate: string | undefined;
}

interface DashboardStats {
  certificationStats: TaskStats;
  todoStats: TaskStats;
  curriculumStats: TaskStats;
}

const EXCLUDED_TASK_TYPES = [
  TaskTypeEnum.completeTaskList,
  TaskTypeEnum.completeCurriculum,
  TaskTypeEnum.completeFormPacket,
  TaskTypeEnum.certification,
] as const;

const calculateTaskStats = (
  tasks: EntityTaskResponse[],
  workflowType: WorkflowTypeEnum
): TaskStats => {
  const filteredTasks = tasks
    .filter((task) => task.rollupWorkflowType === workflowType)
    .filter(
      (task) =>
        !task.taskType ||
        (!EXCLUDED_TASK_TYPES.includes(
          task.taskType as (typeof EXCLUDED_TASK_TYPES)[number]
        ) &&
          task.parentWorkflowType !== WorkflowTypeEnum.taskOption)
    );

  const completed = filteredTasks.filter((task) => task.completed).length;
  const total = filteredTasks.length;
  const progress = total > 0 ? (completed / total) * 100 : 0;

  // Find the next due date from incomplete tasks
  const nextDueDate = filteredTasks
    .filter((t) => !t.completed && t.dueAt)
    .sort(
      (a, b) => new Date(a.dueAt!).getTime() - new Date(b.dueAt!).getTime()
    )[0]?.dueAt;

  return {
    tasks: filteredTasks,
    completed,
    total,
    progress,
    remainingCount: total - completed,
    nextDueDate,
  };
};

export const useDashboardStats = (): DashboardStats => {
  const { tasks } = useTaskManager();

  const stats = useMemo(
    () => ({
      certificationStats: calculateTaskStats(
        tasks,
        WorkflowTypeEnum.certification
      ),
      todoStats: calculateTaskStats(tasks, WorkflowTypeEnum.taskList),
      curriculumStats: calculateTaskStats(tasks, WorkflowTypeEnum.curriculum),
    }),
    [tasks]
  );

  return stats;
};
