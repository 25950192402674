import { useAddHelpTags } from '@btrway/help-tag-manager';
import { Box, Card } from '@mantine/core';
import React from 'react';
import { useChapter } from '../../hooks/useChapter';
import ChapterDetail from '../ChapterDetail/ChapterDetail';
import ChapterSummary from '../ChapterSummary/ChapterSummary';

interface ChapterCardProps {
  chapterKey: string;
  isDragging: boolean;
  isOpen: boolean;
  onToggleOpen: (isOpen: boolean) => void;
}

const ChapterCard: React.FC<ChapterCardProps> = ({
  chapterKey,
  isDragging,
  isOpen,
  onToggleOpen,
}) => {
  useAddHelpTags(['chapter']);

  const { chapter } = useChapter(chapterKey);

  if (!chapter) return null;

  const handleToggle = () => {
    onToggleOpen(!isOpen);
  };

  return (
    <Card withBorder style={{ opacity: isDragging && !isOpen ? 0.5 : 1 }}>
      <ChapterSummary
        chapter={chapter}
        isOpen={isOpen}
        onToggle={handleToggle}
      />
      {isOpen && (
        <Box mt="lg">
          <ChapterDetail chapterKey={chapterKey} />
        </Box>
      )}
    </Card>
  );
};

export default ChapterCard;
