import { WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  LeftNavigationLayout,
  TopNavigationLayout,
} from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowDefinitionsProvider } from '@btrway/workflow-administration';
import {
  IconAlignBoxLeftTop,
  IconAward,
  IconBuilding,
  IconCalendarCheck,
  IconCalendarTime,
  IconDeviceTv,
  IconFileStack,
  IconHome,
  IconListCheck,
  IconSettings,
  IconUserCheck,
  IconUsers,
  IconWand,
} from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded components
const OrganizationalSettingsTab = lazy(() =>
  import('@btrway/settings-components').then((module) => ({
    default: module.OrganizationalSettingsTab,
  }))
);
const YearsView = lazy(() =>
  import('@btrway/settings-components').then((module) => ({
    default: module.YearsView,
  }))
);
const EventTypesPage = lazy(() =>
  import('@btrway/event-type-components').then((module) => ({
    default: module.EventTypesPage,
  }))
);
const UserRolesGridView = lazy(() =>
  import('@btrway/settings-components').then((module) => ({
    default: module.UserRolesGridView,
  }))
);
const WorkflowAdminDashboardView = lazy(() =>
  import('@btrway/workflow-administration').then((module) => ({
    default: module.WorkflowAdminDashboardView,
  }))
);
const WorkflowDefinitionsPage = lazy(() =>
  import('@btrway/workflow-administration').then((module) => ({
    default: module.WorkflowDefinitionsPage,
  }))
);
const TaskAssignmentsView = lazy(() =>
  import('@btrway/task-assignment-administration').then((module) => ({
    default: module.TaskAssignmentsView,
  }))
);

// Helper component for workflow definition pages
const WorkflowDefinitionsPageWithType: React.FC<{
  workflowType: WorkflowTypeEnum;
}> = ({ workflowType }) => {
  return <WorkflowDefinitionsPage workflowType={workflowType} />;
};

export const settingsRoutes: RouteConfig[] = [
  {
    path: 'settings',
    element: <TopNavigationLayout contentPadding={0} />,
    label: 'Settings',
    icon: IconSettings,
    navLevel: 'primary',
    navContainer: 'bottom',
    permissions: ['SETTINGS'],
    children: [
      {
        path: 'organization',
        element: <LeftNavigationLayout displayHeader={false} />,
        label: 'Organization',
        navLevel: 'secondary',
        children: [
          {
            path: 'organizational',
            element: <OrganizationalSettingsTab />,
            label: 'Organization',
            icon: IconBuilding,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'event-types',
            element: <EventTypesPage />,
            label: 'Event Types',
            icon: IconCalendarCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'years',
            element: <YearsView />,
            label: 'Years',
            icon: IconCalendarTime,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
      {
        path: 'workflow',
        element: (
          <WorkflowDefinitionsProvider>
            <LeftNavigationLayout displayHeader={false} />
          </WorkflowDefinitionsProvider>
        ),
        label: 'Workflow and Task Lists',
        navLevel: 'secondary',
        children: [
          {
            path: 'overview',
            element: <WorkflowAdminDashboardView />,
            label: 'Overview',
            icon: IconHome,
            displayType: 'icon',
            navLevel: 'secondary',
          },
          {
            path: 'forms',
            element: <WorkflowDefinitionsPageWithType workflowType="form" />,
            label: 'Forms',
            icon: IconAlignBoxLeftTop,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'form-packets',
            element: (
              <WorkflowDefinitionsPageWithType workflowType="formPacket" />
            ),
            label: 'Form Packets',
            icon: IconFileStack,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'todo-lists',
            element: (
              <WorkflowDefinitionsPageWithType workflowType="taskList" />
            ),
            label: 'To-Do Lists',
            icon: IconListCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
          {
            path: 'certifications',
            element: (
              <WorkflowDefinitionsPageWithType workflowType="certification" />
            ),
            label: 'Certifications',
            navLevel: 'secondary',
            icon: IconAward,
            displayType: 'label',
          },
          {
            path: 'curriculums',
            element: (
              <WorkflowDefinitionsPageWithType workflowType="curriculum" />
            ),
            label: 'Curriculums',
            navLevel: 'secondary',
            icon: IconDeviceTv,
            displayType: 'label',
          },
          {
            path: 'automations',
            element: (
              <WorkflowDefinitionsPageWithType workflowType="automation" />
            ),
            label: 'Automations',
            navLevel: 'secondary',
            icon: IconWand,
            displayType: 'label',
          },
          {
            path: 'task-assignments',
            element: <TaskAssignmentsView />,
            label: 'Task Assignments',
            icon: IconUserCheck,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
      {
        path: 'security',
        element: <LeftNavigationLayout displayHeader={false} />,
        label: 'Security',
        navLevel: 'secondary',
        children: [
          {
            path: 'user-roles',
            element: <UserRolesGridView />,
            label: 'User Roles',
            icon: IconUsers,
            navLevel: 'secondary',
            displayType: 'label',
          },
        ],
      },
    ],
  },
];
