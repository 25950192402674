import { useAuthImpersonation } from '@btrway/impersonation';
import { IconLogin2 } from '@tabler/icons-react';
import React from 'react';

interface OrgAccessButtonProps {
  organizationId: number;
}

const OrgAccessButton: React.FC<OrgAccessButtonProps> = ({
  organizationId,
}) => {
  const { requestOrgAccessToken } = useAuthImpersonation();

  const handleAccessOrgClick = (event: React.MouseEvent<SVGSVGElement>) => {
    // console.log('OrgAccessButton: ', organizationId);
    requestOrgAccessToken(organizationId);
  };

  return (
    <IconLogin2
      size={20}
      color="darkgrey"
      style={{ cursor: 'pointer' }} //position: 'absolute', top: 5, right: 5,
      onClick={handleAccessOrgClick}
    />
  );
};

export default OrgAccessButton;
