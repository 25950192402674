import {
  TaskAssignmentRequest,
  useDeleteTaskAssignment,
  useGetTaskAssignmentsByOrganization,
  useSaveTaskAssignment,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/security-manager';
import { Button, LoadingOverlay, Stack } from '@mantine/core';
import React from 'react';
import TaskAssignmentCard from '../TaskAssignmentCard/TaskAssignmentCard';

const TaskAssignmentsView: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const {
    data: taskAssignments,
    isLoading,
    refetch,
  } = useGetTaskAssignmentsByOrganization(currentOrganization.id);
  const { mutateAsync: deleteTaskAssignment } = useDeleteTaskAssignment();
  const { mutateAsync: saveTaskAssignment } = useSaveTaskAssignment();
  const { getUserRoleById } = useUserRoles();
  const [showNewCard, setShowNewCard] = React.useState(false);

  const handleSave = async (request: { data: TaskAssignmentRequest }) => {
    await saveTaskAssignment(request);
    setShowNewCard(false);
    refetch();
  };

  const handleDelete = async (taskAssignmentId: number) => {
    await deleteTaskAssignment({ taskAssignmentId });
    refetch();
  };

  const sortedTaskAssignments = React.useMemo(() => {
    if (!taskAssignments) return [];

    return [...taskAssignments].sort((a, b) => {
      const roleA = getUserRoleById(a.userRoleId)?.name || '';
      const roleB = getUserRoleById(b.userRoleId)?.name || '';
      return roleA.localeCompare(roleB);
    });
  }, [taskAssignments, getUserRoleById]);

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={isLoading} />
      <Stack gap="md">
        {sortedTaskAssignments.map((assignment) => (
          <TaskAssignmentCard
            key={assignment.id}
            taskAssignment={assignment}
            onSave={handleSave}
            onDelete={handleDelete}
          />
        ))}
        {showNewCard && <TaskAssignmentCard onSave={handleSave} isNew />}
        <Button onClick={() => setShowNewCard(true)} disabled={showNewCard}>
          Add Task Assignment
        </Button>
      </Stack>
    </div>
  );
};

export default TaskAssignmentsView;
