/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AssignTaskInputProperties,
  CertificationInstanceGridRequest,
  CertificationInstanceRequest,
  CertificationInstanceResponse,
  CertificationTypeDTO,
  DataTypeDTO,
  EntityTaskResponse,
  EventTypeDTO,
  GetWorkflowDefinitionByKeyParams,
  GetWorkflowTemplateByKeyParams,
  OutsideTaskDefinitionRequest,
  OutsideTaskDefinitionResponse,
  PagedResponseCertificationInstanceGridResponse,
  PagedResponseTaskInstanceGridResponse,
  StepTypeDTO,
  SubmitFormTaskDTO,
  SubmitReviewTaskDTO,
  TaskAssignmentRequest,
  TaskAssignmentResponse,
  TaskCategoryRequest,
  TaskCategoryResponse,
  TaskInstanceGridRequest,
  TaskInstanceRequest,
  TaskInstanceResponse,
  TaskRetrievalRequest,
  TaskStatisticsRequest,
  TaskTypeDTO,
  WorkflowAssignedEventRequest,
  WorkflowDefinitionConfigUpdate,
  WorkflowDefinitionRequest,
  WorkflowDefinitionResponse,
  WorkflowInstanceResponse,
  WorkflowTemplateConfigUpdate,
  WorkflowTemplateDeploymentActionProperties,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
  WorkgroupTaskStatisticsResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getWorkflowTemplates = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse[]>(
      {url: `/api/workflow/workflow-templates`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplatesQueryKey = () => {
    return [`/api/workflow/workflow-templates`] as const;
    }

    
export const getGetWorkflowTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplates>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplates>>> = ({ signal }) => getWorkflowTemplates(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplates>>>
export type GetWorkflowTemplatesQueryError = unknown



export function useGetWorkflowTemplates<TData = Awaited<ReturnType<typeof getWorkflowTemplates>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveWorkflowTemplate = (
    workflowTemplateRequest: WorkflowTemplateRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-templates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowTemplateRequest
    },
      options);
    }
  


export const getSaveWorkflowTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkflowTemplate>>, {data: WorkflowTemplateRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkflowTemplate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkflowTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkflowTemplate>>>
    export type SaveWorkflowTemplateMutationBody = WorkflowTemplateRequest
    export type SaveWorkflowTemplateMutationError = unknown

    export const useSaveWorkflowTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowTemplate>>, TError,{data: WorkflowTemplateRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkflowTemplate>>,
        TError,
        {data: WorkflowTemplateRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkflowTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deployWorkflowTemplates = (
    workflowTemplateDeploymentActionProperties: WorkflowTemplateDeploymentActionProperties,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-templates/deploy`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowTemplateDeploymentActionProperties
    },
      options);
    }
  


export const getDeployWorkflowTemplatesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: WorkflowTemplateDeploymentActionProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: WorkflowTemplateDeploymentActionProperties}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployWorkflowTemplates>>, {data: WorkflowTemplateDeploymentActionProperties}> = (props) => {
          const {data} = props ?? {};

          return  deployWorkflowTemplates(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeployWorkflowTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof deployWorkflowTemplates>>>
    export type DeployWorkflowTemplatesMutationBody = WorkflowTemplateDeploymentActionProperties
    export type DeployWorkflowTemplatesMutationError = unknown

    export const useDeployWorkflowTemplates = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployWorkflowTemplates>>, TError,{data: WorkflowTemplateDeploymentActionProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deployWorkflowTemplates>>,
        TError,
        {data: WorkflowTemplateDeploymentActionProperties},
        TContext
      > => {

      const mutationOptions = getDeployWorkflowTemplatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const submitReviewTask = (
    submitReviewTaskDTO: SubmitReviewTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-submission/submitReviewTask`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitReviewTaskDTO
    },
      options);
    }
  


export const getSubmitReviewTaskMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitReviewTask>>, {data: SubmitReviewTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  submitReviewTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitReviewTaskMutationResult = NonNullable<Awaited<ReturnType<typeof submitReviewTask>>>
    export type SubmitReviewTaskMutationBody = SubmitReviewTaskDTO
    export type SubmitReviewTaskMutationError = unknown

    export const useSubmitReviewTask = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitReviewTask>>, TError,{data: SubmitReviewTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitReviewTask>>,
        TError,
        {data: SubmitReviewTaskDTO},
        TContext
      > => {

      const mutationOptions = getSubmitReviewTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const submitFormTask = (
    submitFormTaskDTO: SubmitFormTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-submission/submitFormTask`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitFormTaskDTO
    },
      options);
    }
  


export const getSubmitFormTaskMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitFormTask>>, {data: SubmitFormTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  submitFormTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SubmitFormTaskMutationResult = NonNullable<Awaited<ReturnType<typeof submitFormTask>>>
    export type SubmitFormTaskMutationBody = SubmitFormTaskDTO
    export type SubmitFormTaskMutationError = unknown

    export const useSubmitFormTask = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitFormTask>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof submitFormTask>>,
        TError,
        {data: SubmitFormTaskDTO},
        TContext
      > => {

      const mutationOptions = getSubmitFormTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const startWorkflow = (
    submitFormTaskDTO: SubmitFormTaskDTO,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-submission/start`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: submitFormTaskDTO
    },
      options);
    }
  


export const getStartWorkflowMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startWorkflow>>, {data: SubmitFormTaskDTO}> = (props) => {
          const {data} = props ?? {};

          return  startWorkflow(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type StartWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof startWorkflow>>>
    export type StartWorkflowMutationBody = SubmitFormTaskDTO
    export type StartWorkflowMutationError = unknown

    export const useStartWorkflow = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startWorkflow>>, TError,{data: SubmitFormTaskDTO}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof startWorkflow>>,
        TError,
        {data: SubmitFormTaskDTO},
        TContext
      > => {

      const mutationOptions = getStartWorkflowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const assignWorkflows = (
    workflowAssignedEventRequest: WorkflowAssignedEventRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-events/assign-workflow`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowAssignedEventRequest
    },
      options);
    }
  


export const getAssignWorkflowsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignWorkflows>>, {data: WorkflowAssignedEventRequest}> = (props) => {
          const {data} = props ?? {};

          return  assignWorkflows(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignWorkflowsMutationResult = NonNullable<Awaited<ReturnType<typeof assignWorkflows>>>
    export type AssignWorkflowsMutationBody = WorkflowAssignedEventRequest
    export type AssignWorkflowsMutationError = unknown

    export const useAssignWorkflows = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignWorkflows>>, TError,{data: WorkflowAssignedEventRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof assignWorkflows>>,
        TError,
        {data: WorkflowAssignedEventRequest},
        TContext
      > => {

      const mutationOptions = getAssignWorkflowsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveWorkflowDefinition = (
    workflowDefinitionRequest: WorkflowDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowDefinitionRequest
    },
      options);
    }
  


export const getSaveWorkflowDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkflowDefinition>>, {data: WorkflowDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkflowDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkflowDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkflowDefinition>>>
    export type SaveWorkflowDefinitionMutationBody = WorkflowDefinitionRequest
    export type SaveWorkflowDefinitionMutationError = unknown

    export const useSaveWorkflowDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkflowDefinition>>, TError,{data: WorkflowDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkflowDefinition>>,
        TError,
        {data: WorkflowDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkflowDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateWorkflowTemplateConfig = (
    workflowTemplateConfigUpdate: WorkflowTemplateConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-config/template`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowTemplateConfigUpdate
    },
      options);
    }
  


export const getUpdateWorkflowTemplateConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, {data: WorkflowTemplateConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateWorkflowTemplateConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkflowTemplateConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>>
    export type UpdateWorkflowTemplateConfigMutationBody = WorkflowTemplateConfigUpdate
    export type UpdateWorkflowTemplateConfigMutationError = unknown

    export const useUpdateWorkflowTemplateConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>, TError,{data: WorkflowTemplateConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateWorkflowTemplateConfig>>,
        TError,
        {data: WorkflowTemplateConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateWorkflowTemplateConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const assignTask = (
    assignTaskInputProperties: AssignTaskInputProperties,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-config/step-type-properties/assign-task`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assignTaskInputProperties
    },
      options);
    }
  


export const getAssignTaskMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignTask>>, TError,{data: AssignTaskInputProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof assignTask>>, TError,{data: AssignTaskInputProperties}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof assignTask>>, {data: AssignTaskInputProperties}> = (props) => {
          const {data} = props ?? {};

          return  assignTask(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AssignTaskMutationResult = NonNullable<Awaited<ReturnType<typeof assignTask>>>
    export type AssignTaskMutationBody = AssignTaskInputProperties
    export type AssignTaskMutationError = unknown

    export const useAssignTask = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof assignTask>>, TError,{data: AssignTaskInputProperties}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof assignTask>>,
        TError,
        {data: AssignTaskInputProperties},
        TContext
      > => {

      const mutationOptions = getAssignTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const updateWorkflowDefinitionConfig = (
    workflowDefinitionConfigUpdate: WorkflowDefinitionConfigUpdate,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/workflow-config/definition`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workflowDefinitionConfigUpdate
    },
      options);
    }
  


export const getUpdateWorkflowDefinitionConfigMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, {data: WorkflowDefinitionConfigUpdate}> = (props) => {
          const {data} = props ?? {};

          return  updateWorkflowDefinitionConfig(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkflowDefinitionConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>>
    export type UpdateWorkflowDefinitionConfigMutationBody = WorkflowDefinitionConfigUpdate
    export type UpdateWorkflowDefinitionConfigMutationError = unknown

    export const useUpdateWorkflowDefinitionConfig = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>, TError,{data: WorkflowDefinitionConfigUpdate}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateWorkflowDefinitionConfig>>,
        TError,
        {data: WorkflowDefinitionConfigUpdate},
        TContext
      > => {

      const mutationOptions = getUpdateWorkflowDefinitionConfigMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskStatistics = (
    taskStatisticsRequest: TaskStatisticsRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<WorkgroupTaskStatisticsResponse[]>(
      {url: `/api/workflow/task-statistics`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskStatisticsRequest
    },
      options);
    }
  


export const getGetTaskStatisticsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getTaskStatistics>>, {data: TaskStatisticsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getTaskStatistics(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetTaskStatisticsMutationResult = NonNullable<Awaited<ReturnType<typeof getTaskStatistics>>>
    export type GetTaskStatisticsMutationBody = TaskStatisticsRequest
    export type GetTaskStatisticsMutationError = unknown

    export const useGetTaskStatistics = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskStatistics>>, TError,{data: TaskStatisticsRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getTaskStatistics>>,
        TError,
        {data: TaskStatisticsRequest},
        TContext
      > => {

      const mutationOptions = getGetTaskStatisticsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveTaskInstance = (
    taskInstanceRequest: TaskInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/task-instances/save-task-instance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskInstanceRequest
    },
      options);
    }
  


export const getSaveTaskInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskInstance>>, {data: TaskInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskInstance>>>
    export type SaveTaskInstanceMutationBody = TaskInstanceRequest
    export type SaveTaskInstanceMutationError = unknown

    export const useSaveTaskInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskInstance>>, TError,{data: TaskInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskInstance>>,
        TError,
        {data: TaskInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskInstanceGrid = (
    taskInstanceGridRequest: TaskInstanceGridRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PagedResponseTaskInstanceGridResponse>(
      {url: `/api/workflow/task-instances/grid`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskInstanceGridRequest
    },
      options);
    }
  


export const getGetTaskInstanceGridMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskInstanceGrid>>, TError,{data: TaskInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getTaskInstanceGrid>>, TError,{data: TaskInstanceGridRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getTaskInstanceGrid>>, {data: TaskInstanceGridRequest}> = (props) => {
          const {data} = props ?? {};

          return  getTaskInstanceGrid(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetTaskInstanceGridMutationResult = NonNullable<Awaited<ReturnType<typeof getTaskInstanceGrid>>>
    export type GetTaskInstanceGridMutationBody = TaskInstanceGridRequest
    export type GetTaskInstanceGridMutationError = unknown

    export const useGetTaskInstanceGrid = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getTaskInstanceGrid>>, TError,{data: TaskInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getTaskInstanceGrid>>,
        TError,
        {data: TaskInstanceGridRequest},
        TContext
      > => {

      const mutationOptions = getGetTaskInstanceGridMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskCategories = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskCategoryResponse[]>(
      {url: `/api/workflow/task-categories`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskCategoriesQueryKey = () => {
    return [`/api/workflow/task-categories`] as const;
    }

    
export const getGetTaskCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getTaskCategories>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskCategoriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskCategories>>> = ({ signal }) => getTaskCategories(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskCategories>>>
export type GetTaskCategoriesQueryError = unknown



export function useGetTaskCategories<TData = Awaited<ReturnType<typeof getTaskCategories>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskCategoriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveTaskCategory = (
    taskCategoryRequest: TaskCategoryRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/task-categories`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskCategoryRequest
    },
      options);
    }
  


export const getSaveTaskCategoryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskCategory>>, {data: TaskCategoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskCategory(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskCategory>>>
    export type SaveTaskCategoryMutationBody = TaskCategoryRequest
    export type SaveTaskCategoryMutationError = unknown

    export const useSaveTaskCategory = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskCategory>>, TError,{data: TaskCategoryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskCategory>>,
        TError,
        {data: TaskCategoryRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveTaskAssignment = (
    taskAssignmentRequest: TaskAssignmentRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/task-assignments`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskAssignmentRequest
    },
      options);
    }
  


export const getSaveTaskAssignmentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskAssignment>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveTaskAssignment>>, TError,{data: TaskAssignmentRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveTaskAssignment>>, {data: TaskAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveTaskAssignment(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveTaskAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof saveTaskAssignment>>>
    export type SaveTaskAssignmentMutationBody = TaskAssignmentRequest
    export type SaveTaskAssignmentMutationError = unknown

    export const useSaveTaskAssignment = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveTaskAssignment>>, TError,{data: TaskAssignmentRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveTaskAssignment>>,
        TError,
        {data: TaskAssignmentRequest},
        TContext
      > => {

      const mutationOptions = getSaveTaskAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveOutsideTaskDefinition = (
    outsideTaskDefinitionRequest: OutsideTaskDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/outside-task-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: outsideTaskDefinitionRequest
    },
      options);
    }
  


export const getSaveOutsideTaskDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveOutsideTaskDefinition>>, TError,{data: OutsideTaskDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveOutsideTaskDefinition>>, TError,{data: OutsideTaskDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveOutsideTaskDefinition>>, {data: OutsideTaskDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveOutsideTaskDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveOutsideTaskDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveOutsideTaskDefinition>>>
    export type SaveOutsideTaskDefinitionMutationBody = OutsideTaskDefinitionRequest
    export type SaveOutsideTaskDefinitionMutationError = unknown

    export const useSaveOutsideTaskDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveOutsideTaskDefinition>>, TError,{data: OutsideTaskDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveOutsideTaskDefinition>>,
        TError,
        {data: OutsideTaskDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveOutsideTaskDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPersonTasks = (
    taskRetrievalRequest: TaskRetrievalRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<EntityTaskResponse[]>(
      {url: `/api/workflow/entity-tasks/person`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskRetrievalRequest
    },
      options);
    }
  


export const getGetPersonTasksMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPersonTasks>>, TError,{data: TaskRetrievalRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getPersonTasks>>, TError,{data: TaskRetrievalRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPersonTasks>>, {data: TaskRetrievalRequest}> = (props) => {
          const {data} = props ?? {};

          return  getPersonTasks(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetPersonTasksMutationResult = NonNullable<Awaited<ReturnType<typeof getPersonTasks>>>
    export type GetPersonTasksMutationBody = TaskRetrievalRequest
    export type GetPersonTasksMutationError = unknown

    export const useGetPersonTasks = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPersonTasks>>, TError,{data: TaskRetrievalRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getPersonTasks>>,
        TError,
        {data: TaskRetrievalRequest},
        TContext
      > => {

      const mutationOptions = getGetPersonTasksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveCertificationInstance = (
    certificationInstanceRequest: CertificationInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/workflow/certification-instances/save-certification-instance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: certificationInstanceRequest
    },
      options);
    }
  


export const getSaveCertificationInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveCertificationInstance>>, TError,{data: CertificationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveCertificationInstance>>, TError,{data: CertificationInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveCertificationInstance>>, {data: CertificationInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveCertificationInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveCertificationInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof saveCertificationInstance>>>
    export type SaveCertificationInstanceMutationBody = CertificationInstanceRequest
    export type SaveCertificationInstanceMutationError = unknown

    export const useSaveCertificationInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveCertificationInstance>>, TError,{data: CertificationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveCertificationInstance>>,
        TError,
        {data: CertificationInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSaveCertificationInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getCertificationInstanceGrid = (
    certificationInstanceGridRequest: CertificationInstanceGridRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PagedResponseCertificationInstanceGridResponse>(
      {url: `/api/workflow/certification-instances/grid`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: certificationInstanceGridRequest
    },
      options);
    }
  


export const getGetCertificationInstanceGridMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCertificationInstanceGrid>>, TError,{data: CertificationInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getCertificationInstanceGrid>>, TError,{data: CertificationInstanceGridRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCertificationInstanceGrid>>, {data: CertificationInstanceGridRequest}> = (props) => {
          const {data} = props ?? {};

          return  getCertificationInstanceGrid(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetCertificationInstanceGridMutationResult = NonNullable<Awaited<ReturnType<typeof getCertificationInstanceGrid>>>
    export type GetCertificationInstanceGridMutationBody = CertificationInstanceGridRequest
    export type GetCertificationInstanceGridMutationError = unknown

    export const useGetCertificationInstanceGrid = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCertificationInstanceGrid>>, TError,{data: CertificationInstanceGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getCertificationInstanceGrid>>,
        TError,
        {data: CertificationInstanceGridRequest},
        TContext
      > => {

      const mutationOptions = getGetCertificationInstanceGridMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const evaluateCertification = (
    organizationId: number,
    workflowKey: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/certification-instances/evaluate/${organizationId}/${workflowKey}`, method: 'POST'
    },
      options);
    }
  


export const getEvaluateCertificationMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof evaluateCertification>>, TError,{organizationId: number;workflowKey: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof evaluateCertification>>, TError,{organizationId: number;workflowKey: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof evaluateCertification>>, {organizationId: number;workflowKey: string}> = (props) => {
          const {organizationId,workflowKey} = props ?? {};

          return  evaluateCertification(organizationId,workflowKey,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type EvaluateCertificationMutationResult = NonNullable<Awaited<ReturnType<typeof evaluateCertification>>>
    
    export type EvaluateCertificationMutationError = unknown

    export const useEvaluateCertification = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof evaluateCertification>>, TError,{organizationId: number;workflowKey: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof evaluateCertification>>,
        TError,
        {organizationId: number;workflowKey: string},
        TContext
      > => {

      const mutationOptions = getEvaluateCertificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowTemplateById = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse>(
      {url: `/api/workflow/workflow-templates/${workflowTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplateByIdQueryKey = (workflowTemplateId: number,) => {
    return [`/api/workflow/workflow-templates/${workflowTemplateId}`] as const;
    }

    
export const getGetWorkflowTemplateByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError = unknown>(workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplateByIdQueryKey(workflowTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplateById>>> = ({ signal }) => getWorkflowTemplateById(workflowTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplateByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplateById>>>
export type GetWorkflowTemplateByIdQueryError = unknown



export function useGetWorkflowTemplateById<TData = Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError = unknown>(
 workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplateByIdQueryOptions(workflowTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkflowTemplate = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-templates/${workflowTemplateId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkflowTemplateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, {workflowTemplateId: number}> = (props) => {
          const {workflowTemplateId} = props ?? {};

          return  deleteWorkflowTemplate(workflowTemplateId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkflowTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowTemplate>>>
    
    export type DeleteWorkflowTemplateMutationError = unknown

    export const useDeleteWorkflowTemplate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowTemplate>>, TError,{workflowTemplateId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkflowTemplate>>,
        TError,
        {workflowTemplateId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkflowTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowTemplateByKey = (
    workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse>(
      {url: `/api/workflow/workflow-templates/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetWorkflowTemplateByKeyQueryKey = (workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams,) => {
    return [`/api/workflow/workflow-templates/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWorkflowTemplateByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError = unknown>(workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplateByKeyQueryKey(workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>> = ({ signal }) => getWorkflowTemplateByKey(workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplateByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>>
export type GetWorkflowTemplateByKeyQueryError = unknown



export function useGetWorkflowTemplateByKey<TData = Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError = unknown>(
 workflowKey: string,
    params?: GetWorkflowTemplateByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplateByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplateByKeyQueryOptions(workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkWorkflowTemplateExists = (
    workflowKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<boolean>(
      {url: `/api/workflow/workflow-templates/workflow-key/${workflowKey}/exists`, method: 'GET', signal
    },
      options);
    }
  

export const getCheckWorkflowTemplateExistsQueryKey = (workflowKey: string,) => {
    return [`/api/workflow/workflow-templates/workflow-key/${workflowKey}/exists`] as const;
    }

    
export const getCheckWorkflowTemplateExistsQueryOptions = <TData = Awaited<ReturnType<typeof checkWorkflowTemplateExists>>, TError = unknown>(workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowTemplateExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckWorkflowTemplateExistsQueryKey(workflowKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkWorkflowTemplateExists>>> = ({ signal }) => checkWorkflowTemplateExists(workflowKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowTemplateExists>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckWorkflowTemplateExistsQueryResult = NonNullable<Awaited<ReturnType<typeof checkWorkflowTemplateExists>>>
export type CheckWorkflowTemplateExistsQueryError = unknown



export function useCheckWorkflowTemplateExists<TData = Awaited<ReturnType<typeof checkWorkflowTemplateExists>>, TError = unknown>(
 workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowTemplateExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCheckWorkflowTemplateExistsQueryOptions(workflowKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowTemplatesByType = (
    workflowDefinitionType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowTemplateResponse[]>(
      {url: `/api/workflow/workflow-templates/type/${workflowDefinitionType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowTemplatesByTypeQueryKey = (workflowDefinitionType: string,) => {
    return [`/api/workflow/workflow-templates/type/${workflowDefinitionType}`] as const;
    }

    
export const getGetWorkflowTemplatesByTypeQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError = unknown>(workflowDefinitionType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowTemplatesByTypeQueryKey(workflowDefinitionType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>> = ({ signal }) => getWorkflowTemplatesByType(workflowDefinitionType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowDefinitionType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowTemplatesByTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>>
export type GetWorkflowTemplatesByTypeQueryError = unknown



export function useGetWorkflowTemplatesByType<TData = Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError = unknown>(
 workflowDefinitionType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowTemplatesByType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowTemplatesByTypeQueryOptions(workflowDefinitionType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowInstanceById = (
    workflowInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowInstanceResponse>(
      {url: `/api/workflow/workflow-instances/${workflowInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowInstanceByIdQueryKey = (workflowInstanceId: number,) => {
    return [`/api/workflow/workflow-instances/${workflowInstanceId}`] as const;
    }

    
export const getGetWorkflowInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError = unknown>(workflowInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowInstanceByIdQueryKey(workflowInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowInstanceById>>> = ({ signal }) => getWorkflowInstanceById(workflowInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowInstanceById>>>
export type GetWorkflowInstanceByIdQueryError = unknown



export function useGetWorkflowInstanceById<TData = Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError = unknown>(
 workflowInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowInstanceByIdQueryOptions(workflowInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionById = (
    workflowDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse>(
      {url: `/api/workflow/workflow-definitions/${workflowDefinitionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionByIdQueryKey = (workflowDefinitionId: number,) => {
    return [`/api/workflow/workflow-definitions/${workflowDefinitionId}`] as const;
    }

    
export const getGetWorkflowDefinitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError = unknown>(workflowDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionByIdQueryKey(workflowDefinitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionById>>> = ({ signal }) => getWorkflowDefinitionById(workflowDefinitionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowDefinitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionById>>>
export type GetWorkflowDefinitionByIdQueryError = unknown



export function useGetWorkflowDefinitionById<TData = Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError = unknown>(
 workflowDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionByIdQueryOptions(workflowDefinitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkflowDefinition = (
    workflowDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/workflow-definitions/${workflowDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkflowDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, {workflowDefinitionId: number}> = (props) => {
          const {workflowDefinitionId} = props ?? {};

          return  deleteWorkflowDefinition(workflowDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkflowDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowDefinition>>>
    
    export type DeleteWorkflowDefinitionMutationError = unknown

    export const useDeleteWorkflowDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowDefinition>>, TError,{workflowDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkflowDefinition>>,
        TError,
        {workflowDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkflowDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkflowDefinitionsByTemplateId = (
    workflowTemplateId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/templates/${workflowTemplateId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByTemplateIdQueryKey = (workflowTemplateId: number,) => {
    return [`/api/workflow/workflow-definitions/templates/${workflowTemplateId}`] as const;
    }

    
export const getGetWorkflowDefinitionsByTemplateIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError = unknown>(workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByTemplateIdQueryKey(workflowTemplateId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>> = ({ signal }) => getWorkflowDefinitionsByTemplateId(workflowTemplateId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workflowTemplateId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByTemplateIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>>
export type GetWorkflowDefinitionsByTemplateIdQueryError = unknown



export function useGetWorkflowDefinitionsByTemplateId<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError = unknown>(
 workflowTemplateId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByTemplateId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByTemplateIdQueryOptions(workflowTemplateId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}`] as const;
    }

    
export const getGetWorkflowDefinitionsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>> = ({ signal }) => getWorkflowDefinitionsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>>
export type GetWorkflowDefinitionsByOrganizationQueryError = unknown



export function useGetWorkflowDefinitionsByOrganization<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionByKey = (
    organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionByKeyQueryKey = (organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}/workflow-key/${workflowKey}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetWorkflowDefinitionByKeyQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError = unknown>(organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionByKeyQueryKey(organizationId,workflowKey,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>> = ({ signal }) => getWorkflowDefinitionByKey(organizationId,workflowKey,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionByKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>>
export type GetWorkflowDefinitionByKeyQueryError = unknown



export function useGetWorkflowDefinitionByKey<TData = Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError = unknown>(
 organizationId: number,
    workflowKey: string,
    params?: GetWorkflowDefinitionByKeyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionByKey>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionByKeyQueryOptions(organizationId,workflowKey,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkflowDefinitionsByOrganizationAndType = (
    organizationId: number,
    workflowType: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkflowDefinitionResponse[]>(
      {url: `/api/workflow/workflow-definitions/organizations/${organizationId}/types/${workflowType}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey = (organizationId: number,
    workflowType: string,) => {
    return [`/api/workflow/workflow-definitions/organizations/${organizationId}/types/${workflowType}`] as const;
    }

    
export const getGetWorkflowDefinitionsByOrganizationAndTypeQueryOptions = <TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError = unknown>(organizationId: number,
    workflowType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkflowDefinitionsByOrganizationAndTypeQueryKey(organizationId,workflowType);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>> = ({ signal }) => getWorkflowDefinitionsByOrganizationAndType(organizationId,workflowType, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowType), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkflowDefinitionsByOrganizationAndTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>>
export type GetWorkflowDefinitionsByOrganizationAndTypeQueryError = unknown



export function useGetWorkflowDefinitionsByOrganizationAndType<TData = Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError = unknown>(
 organizationId: number,
    workflowType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkflowDefinitionsByOrganizationAndType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkflowDefinitionsByOrganizationAndTypeQueryOptions(organizationId,workflowType,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const checkWorkflowDefinitionExists = (
    organizationId: number,
    workflowKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<boolean>(
      {url: `/api/workflow/workflow-definition-exists/organizations/${organizationId}/workflow-key/${workflowKey}/exists`, method: 'GET', signal
    },
      options);
    }
  

export const getCheckWorkflowDefinitionExistsQueryKey = (organizationId: number,
    workflowKey: string,) => {
    return [`/api/workflow/workflow-definition-exists/organizations/${organizationId}/workflow-key/${workflowKey}/exists`] as const;
    }

    
export const getCheckWorkflowDefinitionExistsQueryOptions = <TData = Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>, TError = unknown>(organizationId: number,
    workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckWorkflowDefinitionExistsQueryKey(organizationId,workflowKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>> = ({ signal }) => checkWorkflowDefinitionExists(organizationId,workflowKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workflowKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>, TError, TData> & { queryKey: QueryKey }
}

export type CheckWorkflowDefinitionExistsQueryResult = NonNullable<Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>>
export type CheckWorkflowDefinitionExistsQueryError = unknown



export function useCheckWorkflowDefinitionExists<TData = Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>, TError = unknown>(
 organizationId: number,
    workflowKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkWorkflowDefinitionExists>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getCheckWorkflowDefinitionExistsQueryOptions(organizationId,workflowKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDistinctWorkflowCategories = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<string[]>(
      {url: `/api/workflow/workflow-categories/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDistinctWorkflowCategoriesQueryKey = (organizationId: number,) => {
    return [`/api/workflow/workflow-categories/organizations/${organizationId}`] as const;
    }

    
export const getGetDistinctWorkflowCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDistinctWorkflowCategoriesQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>> = ({ signal }) => getDistinctWorkflowCategories(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDistinctWorkflowCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>>
export type GetDistinctWorkflowCategoriesQueryError = unknown



export function useGetDistinctWorkflowCategories<TData = Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkflowCategories>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDistinctWorkflowCategoriesQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskTypeDTO[]>(
      {url: `/api/workflow/task-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskTypesQueryKey = () => {
    return [`/api/workflow/task-types`] as const;
    }

    
export const getGetTaskTypesQueryOptions = <TData = Awaited<ReturnType<typeof getTaskTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskTypes>>> = ({ signal }) => getTaskTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskTypes>>>
export type GetTaskTypesQueryError = unknown



export function useGetTaskTypes<TData = Awaited<ReturnType<typeof getTaskTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskInstanceById = (
    taskInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskInstanceResponse>(
      {url: `/api/workflow/task-instances/${taskInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskInstanceByIdQueryKey = (taskInstanceId: number,) => {
    return [`/api/workflow/task-instances/${taskInstanceId}`] as const;
    }

    
export const getGetTaskInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskInstanceById>>, TError = unknown>(taskInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskInstanceByIdQueryKey(taskInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskInstanceById>>> = ({ signal }) => getTaskInstanceById(taskInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskInstanceById>>>
export type GetTaskInstanceByIdQueryError = unknown



export function useGetTaskInstanceById<TData = Awaited<ReturnType<typeof getTaskInstanceById>>, TError = unknown>(
 taskInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskInstanceByIdQueryOptions(taskInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskInstance = (
    taskInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/task-instances/${taskInstanceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskInstance>>, {taskInstanceId: number}> = (props) => {
          const {taskInstanceId} = props ?? {};

          return  deleteTaskInstance(taskInstanceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskInstance>>>
    
    export type DeleteTaskInstanceMutationError = unknown

    export const useDeleteTaskInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskInstance>>, TError,{taskInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskInstance>>,
        TError,
        {taskInstanceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskAssignmentById = (
    taskAssignmentId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskAssignmentResponse>(
      {url: `/api/workflow/task-assignments/${taskAssignmentId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskAssignmentByIdQueryKey = (taskAssignmentId: number,) => {
    return [`/api/workflow/task-assignments/${taskAssignmentId}`] as const;
    }

    
export const getGetTaskAssignmentByIdQueryOptions = <TData = Awaited<ReturnType<typeof getTaskAssignmentById>>, TError = unknown>(taskAssignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskAssignmentByIdQueryKey(taskAssignmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskAssignmentById>>> = ({ signal }) => getTaskAssignmentById(taskAssignmentId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(taskAssignmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskAssignmentByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskAssignmentById>>>
export type GetTaskAssignmentByIdQueryError = unknown



export function useGetTaskAssignmentById<TData = Awaited<ReturnType<typeof getTaskAssignmentById>>, TError = unknown>(
 taskAssignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskAssignmentByIdQueryOptions(taskAssignmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteTaskAssignment = (
    taskAssignmentId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/task-assignments/${taskAssignmentId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskAssignmentMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskAssignment>>, TError,{taskAssignmentId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskAssignment>>, TError,{taskAssignmentId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskAssignment>>, {taskAssignmentId: number}> = (props) => {
          const {taskAssignmentId} = props ?? {};

          return  deleteTaskAssignment(taskAssignmentId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskAssignment>>>
    
    export type DeleteTaskAssignmentMutationError = unknown

    export const useDeleteTaskAssignment = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskAssignment>>, TError,{taskAssignmentId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskAssignment>>,
        TError,
        {taskAssignmentId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getTaskAssignmentsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskAssignmentResponse[]>(
      {url: `/api/workflow/task-assignments/organization/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskAssignmentsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/workflow/task-assignments/organization/${organizationId}`] as const;
    }

    
export const getGetTaskAssignmentsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskAssignmentsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>> = ({ signal }) => getTaskAssignmentsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskAssignmentsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>>
export type GetTaskAssignmentsByOrganizationQueryError = unknown



export function useGetTaskAssignmentsByOrganization<TData = Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskAssignmentsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTaskAssignmentsByUserRole = (
    organizationId: number,
    userRoleId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TaskAssignmentResponse[]>(
      {url: `/api/workflow/task-assignments/organization/${organizationId}/user-role/${userRoleId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskAssignmentsByUserRoleQueryKey = (organizationId: number,
    userRoleId: number,) => {
    return [`/api/workflow/task-assignments/organization/${organizationId}/user-role/${userRoleId}`] as const;
    }

    
export const getGetTaskAssignmentsByUserRoleQueryOptions = <TData = Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>, TError = unknown>(organizationId: number,
    userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTaskAssignmentsByUserRoleQueryKey(organizationId,userRoleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>> = ({ signal }) => getTaskAssignmentsByUserRole(organizationId,userRoleId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && userRoleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTaskAssignmentsByUserRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>>
export type GetTaskAssignmentsByUserRoleQueryError = unknown



export function useGetTaskAssignmentsByUserRole<TData = Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>, TError = unknown>(
 organizationId: number,
    userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTaskAssignmentsByUserRole>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTaskAssignmentsByUserRoleQueryOptions(organizationId,userRoleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDistinctUserRoleIdsWithTaskAssignments = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<number[]>(
      {url: `/api/workflow/task-assignments/organization/${organizationId}/user-role-ids`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDistinctUserRoleIdsWithTaskAssignmentsQueryKey = (organizationId: number,) => {
    return [`/api/workflow/task-assignments/organization/${organizationId}/user-role-ids`] as const;
    }

    
export const getGetDistinctUserRoleIdsWithTaskAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDistinctUserRoleIdsWithTaskAssignmentsQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>> = ({ signal }) => getDistinctUserRoleIdsWithTaskAssignments(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDistinctUserRoleIdsWithTaskAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>>
export type GetDistinctUserRoleIdsWithTaskAssignmentsQueryError = unknown



export function useGetDistinctUserRoleIdsWithTaskAssignments<TData = Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctUserRoleIdsWithTaskAssignments>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDistinctUserRoleIdsWithTaskAssignmentsQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getStepTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<StepTypeDTO[]>(
      {url: `/api/workflow/step-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStepTypesQueryKey = () => {
    return [`/api/workflow/step-types`] as const;
    }

    
export const getGetStepTypesQueryOptions = <TData = Awaited<ReturnType<typeof getStepTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStepTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStepTypes>>> = ({ signal }) => getStepTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStepTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getStepTypes>>>
export type GetStepTypesQueryError = unknown



export function useGetStepTypes<TData = Awaited<ReturnType<typeof getStepTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStepTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStepTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getOutsideTaskDefinitionById = (
    outsideTaskDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OutsideTaskDefinitionResponse>(
      {url: `/api/workflow/outside-task-definitions/${outsideTaskDefinitionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOutsideTaskDefinitionByIdQueryKey = (outsideTaskDefinitionId: number,) => {
    return [`/api/workflow/outside-task-definitions/${outsideTaskDefinitionId}`] as const;
    }

    
export const getGetOutsideTaskDefinitionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>, TError = unknown>(outsideTaskDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOutsideTaskDefinitionByIdQueryKey(outsideTaskDefinitionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>> = ({ signal }) => getOutsideTaskDefinitionById(outsideTaskDefinitionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(outsideTaskDefinitionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOutsideTaskDefinitionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>>
export type GetOutsideTaskDefinitionByIdQueryError = unknown



export function useGetOutsideTaskDefinitionById<TData = Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>, TError = unknown>(
 outsideTaskDefinitionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOutsideTaskDefinitionByIdQueryOptions(outsideTaskDefinitionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteOutsideTaskDefinition = (
    outsideTaskDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/outside-task-definitions/${outsideTaskDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOutsideTaskDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>, TError,{outsideTaskDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>, TError,{outsideTaskDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>, {outsideTaskDefinitionId: number}> = (props) => {
          const {outsideTaskDefinitionId} = props ?? {};

          return  deleteOutsideTaskDefinition(outsideTaskDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOutsideTaskDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>>
    
    export type DeleteOutsideTaskDefinitionMutationError = unknown

    export const useDeleteOutsideTaskDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>, TError,{outsideTaskDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOutsideTaskDefinition>>,
        TError,
        {outsideTaskDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteOutsideTaskDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getOutsideTaskDefinitionsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OutsideTaskDefinitionResponse[]>(
      {url: `/api/workflow/outside-task-definitions/organization/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOutsideTaskDefinitionsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/workflow/outside-task-definitions/organization/${organizationId}`] as const;
    }

    
export const getGetOutsideTaskDefinitionsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOutsideTaskDefinitionsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>> = ({ signal }) => getOutsideTaskDefinitionsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOutsideTaskDefinitionsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>>
export type GetOutsideTaskDefinitionsByOrganizationQueryError = unknown



export function useGetOutsideTaskDefinitionsByOrganization<TData = Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOutsideTaskDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOutsideTaskDefinitionsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEventTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EventTypeDTO[]>(
      {url: `/api/workflow/event-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEventTypesQueryKey = () => {
    return [`/api/workflow/event-types`] as const;
    }

    
export const getGetEventTypesQueryOptions = <TData = Awaited<ReturnType<typeof getEventTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypes>>> = ({ signal }) => getEventTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypes>>>
export type GetEventTypesQueryError = unknown



export function useGetEventTypes<TData = Awaited<ReturnType<typeof getEventTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEventTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDataTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<DataTypeDTO[]>(
      {url: `/api/workflow/data-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataTypesQueryKey = () => {
    return [`/api/workflow/data-types`] as const;
    }

    
export const getGetDataTypesQueryOptions = <TData = Awaited<ReturnType<typeof getDataTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataTypes>>> = ({ signal }) => getDataTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDataTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getDataTypes>>>
export type GetDataTypesQueryError = unknown



export function useGetDataTypes<TData = Awaited<ReturnType<typeof getDataTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDataTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCertificationTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CertificationTypeDTO[]>(
      {url: `/api/workflow/certification-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCertificationTypesQueryKey = () => {
    return [`/api/workflow/certification-types`] as const;
    }

    
export const getGetCertificationTypesQueryOptions = <TData = Awaited<ReturnType<typeof getCertificationTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificationTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCertificationTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCertificationTypes>>> = ({ signal }) => getCertificationTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCertificationTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCertificationTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getCertificationTypes>>>
export type GetCertificationTypesQueryError = unknown



export function useGetCertificationTypes<TData = Awaited<ReturnType<typeof getCertificationTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificationTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCertificationTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCertificationInstanceById = (
    certificationInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CertificationInstanceResponse>(
      {url: `/api/workflow/certification-instances/${certificationInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCertificationInstanceByIdQueryKey = (certificationInstanceId: number,) => {
    return [`/api/workflow/certification-instances/${certificationInstanceId}`] as const;
    }

    
export const getGetCertificationInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getCertificationInstanceById>>, TError = unknown>(certificationInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificationInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCertificationInstanceByIdQueryKey(certificationInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCertificationInstanceById>>> = ({ signal }) => getCertificationInstanceById(certificationInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(certificationInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCertificationInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCertificationInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCertificationInstanceById>>>
export type GetCertificationInstanceByIdQueryError = unknown



export function useGetCertificationInstanceById<TData = Awaited<ReturnType<typeof getCertificationInstanceById>>, TError = unknown>(
 certificationInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificationInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCertificationInstanceByIdQueryOptions(certificationInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteCertificationInstance = (
    certificationInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/certification-instances/${certificationInstanceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCertificationInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCertificationInstance>>, TError,{certificationInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCertificationInstance>>, TError,{certificationInstanceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCertificationInstance>>, {certificationInstanceId: number}> = (props) => {
          const {certificationInstanceId} = props ?? {};

          return  deleteCertificationInstance(certificationInstanceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteCertificationInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCertificationInstance>>>
    
    export type DeleteCertificationInstanceMutationError = unknown

    export const useDeleteCertificationInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCertificationInstance>>, TError,{certificationInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteCertificationInstance>>,
        TError,
        {certificationInstanceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteCertificationInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteTaskCategory = (
    taskCategoryId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/workflow/task-categories/${taskCategoryId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskCategoryMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskCategory>>, {taskCategoryId: number}> = (props) => {
          const {taskCategoryId} = props ?? {};

          return  deleteTaskCategory(taskCategoryId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskCategoryMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskCategory>>>
    
    export type DeleteTaskCategoryMutationError = unknown

    export const useDeleteTaskCategory = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskCategory>>, TError,{taskCategoryId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTaskCategory>>,
        TError,
        {taskCategoryId: number},
        TContext
      > => {

      const mutationOptions = getDeleteTaskCategoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
