import { useAuthenticatedUser } from '@btrway/current-user';
import { PersonImportModal } from '@btrway/custom-person-import';
import { SlidingActionPanel } from '@btrway/shared-components';
import { Box, Flex, Group, Skeleton, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { AddPersonModal } from '../../components/AddPersonModal/AddPersonModal';
import WorkgroupPeopleActionList from '../../components/WorkgroupPeopleActionList/WorkgroupPeopleActionList';
import WorkgroupPeopleFilters from '../../components/WorkgroupPeopleFilters/WorkgroupPeopleFilters';
import WorkgroupPeopleGrid, {
  SelectedPeopleState,
  WorkgroupPeopleGridRef,
} from '../../components/WorkgroupPeopleGrid/WorkgroupPeopleGrid';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

interface SelectionState extends SelectedPeopleState {
  selectedCount: number;
  isAllSelected: boolean;
  totalRows: number;
}

const WorkgroupPeopleView: React.FC = () => {
  const { workgroup } = useWorkgroup();
  const { currentOrganization, rootWorkgroupId } = useAuthenticatedUser();

  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const [selectionState, setSelectionState] = useState<SelectionState>({
    selectedCount: 0,
    isAllSelected: false,
    totalRows: 0,
    selectedPeople: [],
    selectAll: false,
  });
  const gridRef = useRef<WorkgroupPeopleGridRef>(null);

  if (!currentOrganization || !rootWorkgroupId) {
    return <Skeleton height={400} />;
  }

  const handleCloseActionPanel = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.clearSelection();
    }
    setSelectionState({
      selectedCount: 0,
      isAllSelected: false,
      totalRows: selectionState.totalRows,
      selectedPeople: [],
      selectAll: false,
    });
  }, [selectionState.totalRows]);

  const handleSelectionChanged = useCallback(
    (
      selectedCount: number,
      isAllSelected: boolean,
      total: number,
      selectedPeople: Array<{ personId: number; workgroupId: number; workgroupMemberId: number }>
    ) => {
      setSelectionState({
        selectedCount,
        isAllSelected,
        totalRows: total,
        selectedPeople,
        selectAll: isAllSelected,
      });
    },
    []
  );

  const memoizedGrid = useMemo(() => {
    if (!workgroup) {
      return <Skeleton height={300} />;
    }
    return (
      <WorkgroupPeopleGrid
        ref={gridRef}
        searchValue={debouncedSearchValue}
        selectedRoles={selectedRoles}
        workgroupIds={[workgroup.id]}
        organizationId={currentOrganization.id}
        selectedYears={selectedYears}
        onSelectionChanged={handleSelectionChanged}
      />
    );
  }, [
    debouncedSearchValue,
    selectedRoles,
    workgroup,
    currentOrganization.id,
    selectedYears,
    handleSelectionChanged,
  ]);

  if (!workgroup) {
    return <Skeleton height={400} />;
  }

  const selectionText = selectionState.isAllSelected
    ? `All ${selectionState.totalRows} rows selected`
    : selectionState.selectedCount > 0
    ? `${selectionState.selectedCount} selected`
    : '';

  return (
    <Box p="md" h="100%">
      <Flex direction="column" style={{ height: '100%', position: 'relative' }}>
        <Box style={{ height: '100%' }}>
          <Group justify="space-between" gap="lg" mb="md">
            <Group justify="flex-start" gap="lg">
              <WorkgroupPeopleFilters
                selectedRoles={selectedRoles}
                onRolesChange={setSelectedRoles}
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                selectedYears={selectedYears}
                onYearsChange={setSelectedYears}
              />
            </Group>
            <Group justify="flex-end">
              <AddPersonModal />
              <PersonImportModal rootWorkgroupId={rootWorkgroupId} />
            </Group>
          </Group>
          <Box style={{ height: 'calc(100% - 60px)' }}>{memoizedGrid}</Box>
        </Box>
        <SlidingActionPanel
          isOpen={
            selectionState.selectedCount > 0 || selectionState.isAllSelected
          }
          onClose={handleCloseActionPanel}
        >
          <Stack>
            <Text fz="lg" fw={700}>
              {selectionText}
            </Text>
            <WorkgroupPeopleActionList selectionState={selectionState} />
          </Stack>
        </SlidingActionPanel>
      </Flex>
    </Box>
  );
};

export default WorkgroupPeopleView;
