import { TaskTypeEnum, WorkflowTypeEnum } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useTaskFetcher, useTaskManager } from '@btrway/task-manager';
import { Box, Paper, Text } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import useGroupedTasks from '../../hooks/useGroupedTasks';
import TaskListGrid from '../TaskListGrid/TaskListGrid';

interface GroupedTasksViewProps {
  workflowType: WorkflowTypeEnum;
  personId?: number;
}

const EXCLUDED_TASK_TYPES = [
  TaskTypeEnum.completeTaskList,
  TaskTypeEnum.completeCurriculum,
  TaskTypeEnum.completeFormPacket,
  TaskTypeEnum.certification,
] as const;

const GroupedTasksView: React.FC<GroupedTasksViewProps> = ({
  workflowType,
  personId,
}) => {
  const { tasks } = useTaskManager();
  const { currentPerson } = useAuthenticatedUser();
  const { fetchTaskListTasks } = useTaskFetcher();

  const filteredTasks = useMemo(() => {
    return tasks.filter(
      (task) =>
        task.rollupWorkflowType === workflowType &&
        (!task.taskType ||
          !EXCLUDED_TASK_TYPES.includes(
            task.taskType as (typeof EXCLUDED_TASK_TYPES)[number]
          ))
    );
  }, [tasks, workflowType]);

  const groupedTasksResult = useGroupedTasks(filteredTasks);

  useEffect(() => {
    fetchTaskListTasks(personId || currentPerson.id);
  }, [fetchTaskListTasks, personId, currentPerson.id]);

  return (
    <Box>
      {Object.entries(groupedTasksResult.tasks).map(([group, tasks]) => (
        <Paper key={group} shadow="xs" p="md" mb="md">
          <Text size="xl" fw={700}>
            {group}
          </Text>
          <Box p="lg">
            <TaskListGrid tasks={tasks} />
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default GroupedTasksView;
