/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type RuleOperatorTypeEnum = typeof RuleOperatorTypeEnum[keyof typeof RuleOperatorTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RuleOperatorTypeEnum = {
  equal: 'equal',
  notequal: 'notequal',
  contains: 'contains',
  notcontains: 'notcontains',
  startswith: 'startswith',
  endswith: 'endswith',
  greater: 'greater',
  greaterorequal: 'greaterorequal',
  less: 'less',
  lessorequal: 'lessorequal',
  includes: 'includes',
  excludes: 'excludes',
  hasvalue: 'hasvalue',
  novalue: 'novalue',
  before: 'before',
  after: 'after',
  onorbefore: 'onorbefore',
  onorafter: 'onorafter',
} as const;
