import { AnswerDTO } from '@btrway/api-courseware';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import { Button, Group, Stack } from '@mantine/core';
import { IconGripVertical, IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';
import QuestionAnswerChip from '../QuestionAnswerChip/QuestionAnswerChip';

interface QuestionAnswersProps {
  answers: AnswerDTO[];
  onAddAnswer: (correct: boolean) => void;
  onUpdateAnswer: (index: number, updatedAnswer: AnswerDTO) => void;
  onDeleteAnswer: (index: number) => void;
  onReorderAnswers: (newAnswers: AnswerDTO[]) => void;
}

const QuestionAnswers: React.FC<QuestionAnswersProps> = ({
  answers,
  onAddAnswer,
  onUpdateAnswer,
  onDeleteAnswer,
  onReorderAnswers,
}) => {
  const [newAnswerIndex, setNewAnswerIndex] = useState<number | null>(null);

  const handleAddAnswer = (correct: boolean) => {
    onAddAnswer(correct);
    setNewAnswerIndex(answers.length);
  };

  const handleUpdateAnswer = (index: number, updatedAnswer: AnswerDTO) => {
    onUpdateAnswer(index, updatedAnswer);
    setNewAnswerIndex(null);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedAnswers = Array.from(answers);
    const [reorderedItem] = reorderedAnswers.splice(result.source.index, 1);
    reorderedAnswers.splice(result.destination.index, 0, reorderedItem);

    onReorderAnswers(reorderedAnswers);
  };

  return (
    <Stack gap="sm" align="stretch" style={{ width: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="answers">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {answers.map((answer, index) => (
                <Draggable
                  key={index}
                  draggableId={`answer-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginBottom: '8px',
                      }}
                    >
                      <Group gap="sm" wrap="nowrap">
                        <div {...provided.dragHandleProps}>
                          <IconGripVertical
                            size={18}
                            style={{ cursor: 'grab' }}
                          />
                        </div>
                        <QuestionAnswerChip
                          answer={answer}
                          onUpdate={(updatedAnswer) =>
                            handleUpdateAnswer(index, updatedAnswer)
                          }
                          onDelete={() => onDeleteAnswer(index)}
                          isNew={index === newAnswerIndex}
                        />
                      </Group>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Group justify="flex-end" gap="sm" wrap="nowrap">
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={() => handleAddAnswer(true)}
          variant="subtle"
          color="green"
          size="xs"
        >
          Correct
        </Button>
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={() => handleAddAnswer(false)}
          variant="subtle"
          color="red"
          size="xs"
        >
          Incorrect
        </Button>
      </Group>
    </Stack>
  );
};

export default QuestionAnswers;
