import { FormSubmissionModal } from '@btrway/form-submission';
import { Box, Flex, Skeleton, Stack, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { useCategoryColors } from '../../hooks/useCategoryColors';
import { useFormLibrary } from '../../hooks/useFormLibrary';
import CategorySelector from '../CategorySelector/CategorySelector';
import FormSearch from '../FormSearch/FormSearch';
import LaunchFormItem from '../LaunchFormItem/LaunchFormItem';

const LaunchFormList: React.FC = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<number | null>(
    null
  );

  const {
    filteredWorkflows,
    suggestedCategories,
    selectedCategory,
    searchTerm,
    isReady,
    error,
    setSelectedCategory,
    setSearchTerm,
  } = useFormLibrary();

  const { getCategoryColor } = useCategoryColors(suggestedCategories);

  const handleLaunchForm = (workflowDefinitionId: number) => {
    setSelectedWorkflowId(workflowDefinitionId);
  };

  const handleCloseModal = () => {
    setSelectedWorkflowId(null);
  };

  if (error) {
    return (
      <div>Error: {error instanceof Error ? error.message : String(error)}</div>
    );
  }

  if (!isReady) {
    return (
      <Box m={{ base: 'xs', sm: 'lg' }}>
        <Skeleton height={50} radius="md" mb="xl" />
        <Flex wrap="wrap" gap="md" justify="flex-start">
          {Array.from({ length: isMobile ? 3 : 6 }).map((_, index) => (
            <Skeleton
              key={index}
              height={200}
              width={isMobile ? '100%' : 300}
              radius="md"
            />
          ))}
        </Flex>
      </Box>
    );
  }

  return (
    <Stack gap="xs" align="center">
      <Box
        mb="xl"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          width: '100%',
        }}
      >
        <FormSearch
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          isMobile={isMobile || false}
        />
        <CategorySelector
          categories={suggestedCategories}
          onCategoryChange={setSelectedCategory}
        />
      </Box>
      <Flex
        wrap="wrap"
        gap={{ base: 'xs', sm: 'md' }}
        justify={{ base: 'stretch', sm: 'flex-start' }}
        direction={{ base: 'column', sm: 'row' }}
      >
        {filteredWorkflows.map((workflow) => (
          <Box key={workflow.id} mb="md" w={{ base: '100%', sm: 'auto' }}>
            <LaunchFormItem
              workflowDefinition={workflow}
              getCategoryColor={getCategoryColor}
              onLaunch={handleLaunchForm}
              isMobile={isMobile || false}
            />
          </Box>
        ))}
      </Flex>
      <FormSubmissionModal
        workflowDefinitionId={selectedWorkflowId || 0}
        isOpen={!!selectedWorkflowId}
        onClose={handleCloseModal}
      />
    </Stack>
  );
};

export default LaunchFormList;
