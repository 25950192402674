import { Select, Table, Text } from '@mantine/core';
import React from 'react';
import { baseFields } from '../../constants';
import { useFileData } from '../../hooks/useFileData';
import { useImportConfiguration } from '../../hooks/useImportConfiguration';
import { ImportedPerson } from '../../types';

export const ColumnMappingStep: React.FC = () => {
  const { importConfiguration, updateColumnMapping } = useImportConfiguration();
  const { fileData } = useFileData();

  if (!fileData) {
    return <Text>Please upload a file first.</Text>;
  }

  if (!Array.isArray(fileData.headers) || fileData.headers.length === 0) {
    return <Text>No headers found in the selected sheet.</Text>;
  }

  const fieldOptions = baseFields.map((field) => ({
    label: field.label,
    value: field.key,
  }));

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Source Column</th>
            <th>Map to Field</th>
          </tr>
        </thead>
        <tbody>
          {fileData.headers.map((header, index) => (
            <tr key={index}>
              <td>{header}</td>
              <td>
                <Select
                  data={fieldOptions}
                  value={
                    importConfiguration.columnMappings.find(
                      (mapping) => mapping.columnHeader === header
                    )?.targetField || null
                  }
                  onChange={(value) => {
                    if (value) {
                      updateColumnMapping(
                        value as keyof ImportedPerson,
                        header
                      );
                    }
                  }}
                  placeholder="Select field"
                  searchable
                  clearable
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
