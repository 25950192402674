import { Box, BoxProps } from '@mantine/core';
import React, { ReactNode } from 'react';

interface InnerContentWrapperProps extends BoxProps {
  children: ReactNode;
}

const InnerContentWrapper: React.FC<InnerContentWrapperProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      w="100%"
      h="100%"
      p="md"
      bg="var(--mantine-color-body)"
      style={{
        borderTopLeftRadius: 'var(--mantine-radius-md)',
        borderTopRightRadius: 'var(--mantine-radius-md)',
        borderLeft: '1px solid var(--mantine-color-gray-3)',
        borderTop: '1px solid var(--mantine-color-gray-3)',
        borderRight: '1px solid var(--mantine-color-gray-3)',
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default InnerContentWrapper;
