import { StepConfig, WorkflowStepInstanceResponse } from '@btrway/api-workflow';
import { Card, Text } from '@mantine/core';
import React from 'react';

interface WorkflowInstanceSidebarItemProps {
  stepInstance: WorkflowStepInstanceResponse;
  onClick: () => void;
  isSelected: boolean;
  workflowStep?: StepConfig;
}

const WorkflowInstanceSidebarItem: React.FC<
  WorkflowInstanceSidebarItemProps
> = ({ stepInstance, onClick, isSelected, workflowStep }) => {
  const title = workflowStep?.title || `Step ${stepInstance.stepKey}`;

  return (
    <Card
      onClick={onClick}
      style={{
        cursor: 'pointer',
        marginBottom: '8px',
        border: isSelected ? '2px solid blue' : 'none',
      }}
    >
      <Text>{title}</Text>
    </Card>
  );
};

export default WorkflowInstanceSidebarItem;
