import { useCallback } from 'react';
import { generateJSON } from '../utils/generateJSON';
import { useFileData } from './useFileData';
import { useImportConfiguration } from './useImportConfiguration';

export const useJSONGeneration = () => {
  const { fileData } = useFileData();
  const { importConfiguration } = useImportConfiguration();

  const generateFinalJSON = useCallback(() => {
    if (!fileData) {
      console.error('File data is missing');
      return;
    }

    if (importConfiguration.columnMappings.length === 0) {
      console.error('Column mappings are empty');
      return;
    }

    const json = generateJSON(fileData, importConfiguration);
    return json;
  }, [fileData, importConfiguration]);

  return { generateFinalJSON };
};
