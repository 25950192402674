import { useCurriculum } from '@btrway/courseware-manager';
import { Loader, Text } from '@mantine/core';
import React from 'react';
import CourseCarousel from '../CourseCarousel/CourseCarousel';

interface CurriculumCourseCarouselProps {
  curriculumId: number;
  title: string;
  onPlayCourse: (courseUid: number) => void;
  height: number;
  growthFactor?: number;
}

const CurriculumCourseCarousel: React.FC<CurriculumCourseCarouselProps> = ({
  curriculumId,
  title,
  onPlayCourse,
  height,
  growthFactor,
}) => {
  const { courses, isLoading, error } = useCurriculum(curriculumId);

  console.log('courses', courses);
  if (isLoading) return <Loader />;
  if (!courses.length)
    return <Text>No courses available for this curriculum.</Text>;

  return (
    <CourseCarousel
      courses={courses}
      onPlayCourse={onPlayCourse}
      title={title}
      height={height}
      growthFactor={growthFactor}
    />
  );
};

export default CurriculumCourseCarousel;
