import { AppShellLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import { appShellRoutes } from './appShellRoutes';
import { fullScreenRoutes } from './fullScreenRoutes';

export const appRoutes: RouteConfig[] = [
  {
    path: '/',
    element: <AppShellLayout />,
    children: appShellRoutes,
  },
  // Full screen routes are not rendered in the AppShellLayout
  ...fullScreenRoutes,
];
