import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  GetRowIdFunc,
  ModuleRegistry,
  ValueFormatterFunc,
} from '@ag-grid-community/core';
import { WorkgroupResponse } from '@btrway/api-core';
import {
  MessageResponse,
  useGetMessagesByWorkgroupId,
} from '@btrway/api-messaging';
import { ResponsiveGrid } from '@btrway/grid-components';
import { formatDate } from '@btrway/utils';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface MessagesGridProps {
  workgroup: WorkgroupResponse;
}

const MessagesGrid: React.FC<MessagesGridProps> = ({ workgroup }) => {
  const {
    data: messages,
    isLoading,
    refetch,
  } = useGetMessagesByWorkgroupId(workgroup.id);
  const navigate = useNavigate();

  const dateFormatter: ValueFormatterFunc<MessageResponse> = (params) => {
    return params.value ? formatDate(params.value) : '';
  };

  const columnDefs = useMemo<ColDef<MessageResponse>[]>(
    () => [
      {
        field: 'messageSubject',
        headerName: 'Subject',
        flex: 2,
      },
      {
        field: 'processedAt',
        headerName: 'Processed At',
        valueFormatter: dateFormatter,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
      },
    ],
    []
  );

  const defaultColDef = useMemo<ColDef<MessageResponse>>(
    () => ({
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const getRowId: GetRowIdFunc<MessageResponse> = (params) => {
    return params.data.id.toString();
  };

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const handleCardClick = (data: any) => {
    navigate(`${data.id}`);
  };

  if (isLoading) return null;

  return (
    <ResponsiveGrid
      columnDefs={columnDefs}
      rowData={messages}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      getRowId={getRowId}
      rowModelType="clientSide"
      enableRangeSelection={true}
      primaryNavigationField="messageSubject"
      responsiveRowSpacing={10}
      onRowClick={handleCardClick}
    />
  );
};

export default MessagesGrid;
