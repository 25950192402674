// import {
//   ActionIcon,
//   Grid,
//   Group,
//   Select,
//   Stack,
//   Text,
//   Title,
// } from '@mantine/core';
// import { IconChecklist, IconRefresh } from '@tabler/icons-react';
// import { useState } from 'react';
// import { useCertificationInstances } from '../../hooks/useCertificationInstances';
// import { CertificationInstancesViewProps } from '../../types/certificationTypes';
// import { CertificationCard } from '../CertificationCard/CertificationCard';

// export const CertificationInstancesView = ({
//   workgroupId,
//   organizationId,
// }: CertificationInstancesViewProps) => {
//   const { groupedCertifications, loading, refresh } = useCertificationInstances(
//     workgroupId,
//     organizationId
//   );
//   const [expandedCards, setExpandedCards] = useState<Set<number>>(new Set());
//   const [statusFilter, setStatusFilter] = useState<string>('all');

//   const toggleExpanded = (id: number) => {
//     setExpandedCards((prev) => {
//       const newSet = new Set(prev);
//       if (newSet.has(id)) {
//         newSet.delete(id);
//       } else {
//         newSet.add(id);
//       }
//       return newSet;
//     });
//   };

//   const filteredCertifications = groupedCertifications.filter((cert) => {
//     if (statusFilter === 'all') return true;
//     if (statusFilter === 'completed') return cert.completed;
//     if (statusFilter === 'pending') return !cert.completed;
//     return true;
//   });

//   return (
//     <Stack p="md" gap="lg">
//       <Group justify="space-between" align="flex-end">
//         <Title order={2}>
//           <Group gap="xs">
//             <IconChecklist size={24} />
//             <Text>Certifications</Text>
//           </Group>
//         </Title>
//         <Group gap="xs">
//           <Select
//             placeholder="Filter by status"
//             value={statusFilter}
//             onChange={(value) => setStatusFilter(value || 'all')}
//             data={[
//               { value: 'all', label: 'All Statuses' },
//               { value: 'completed', label: 'Completed' },
//               { value: 'pending', label: 'Pending' },
//             ]}
//           />
//           <ActionIcon
//             variant="light"
//             color="blue"
//             loading={loading}
//             onClick={refresh}
//           >
//             <IconRefresh size={16} />
//           </ActionIcon>
//         </Group>
//       </Group>

//       <Grid>
//         {filteredCertifications.map((cert) => (
//           <Grid.Col key={cert.certificationInstanceId} span={12}>
//             <CertificationCard
//               certification={cert}
//               expanded={expandedCards.has(cert.certificationInstanceId)}
//               onToggleExpand={() =>
//                 toggleExpanded(cert.certificationInstanceId)
//               }
//             />
//           </Grid.Col>
//         ))}
//       </Grid>
//     </Stack>
//   );
// };

// export default CertificationInstancesView;

import {
  ActionIcon,
  Box,
  Grid,
  Group,
  Select,
  Text,
  Title,
} from '@mantine/core';
import { IconChecklist, IconRefresh } from '@tabler/icons-react';
import { useState } from 'react';
import { useCertificationInstances } from '../../hooks/useCertificationInstances';
import { CertificationInstancesViewProps } from '../../types/certificationTypes';
import { CertificationCard } from '../CertificationCard/CertificationCard';

export const CertificationInstancesView = ({
  workgroupId,
  organizationId,
}: CertificationInstancesViewProps) => {
  const { groupedCertifications, loading, refresh } = useCertificationInstances(
    workgroupId,
    organizationId
  );
  const [expandedCards, setExpandedCards] = useState<Set<number>>(new Set());
  const [statusFilter, setStatusFilter] = useState<string>('all');

  const toggleExpanded = (id: number) => {
    setExpandedCards((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const filteredCertifications = groupedCertifications.filter((cert) => {
    if (statusFilter === 'all') return true;
    if (statusFilter === 'completed') return cert.completed;
    if (statusFilter === 'pending') return !cert.completed;
    return true;
  });

  return (
    <Box h="100%" display="flex" style={{ flexDirection: 'column' }}>
      {/* Fixed Header */}
      <Box
        bg="white"
        p="md"
        style={{
          borderBottom: '1px solid #dee2e6',
        }}
      >
        <Group justify="space-between" align="flex-end">
          <Title order={2}>
            <Group gap="xs">
              <IconChecklist size={24} />
              <Text>Certifications</Text>
            </Group>
          </Title>
          <Group gap="xs">
            <Select
              placeholder="Filter by status"
              value={statusFilter}
              onChange={(value) => setStatusFilter(value || 'all')}
              data={[
                { value: 'all', label: 'All Statuses' },
                { value: 'completed', label: 'Completed' },
                { value: 'pending', label: 'Pending' },
              ]}
            />
            <ActionIcon
              variant="light"
              color="blue"
              loading={loading}
              onClick={refresh}
            >
              <IconRefresh size={16} />
            </ActionIcon>
          </Group>
        </Group>
      </Box>

      {/* Scrollable Content */}
      <Box
        style={{
          flex: 1,
          overflowY: 'auto',
        }}
        p="md"
      >
        <Grid>
          {filteredCertifications.map((cert) => (
            <Grid.Col key={cert.certificationInstanceId} span={12}>
              <CertificationCard
                certification={cert}
                expanded={expandedCards.has(cert.certificationInstanceId)}
                onToggleExpand={() =>
                  toggleExpanded(cert.certificationInstanceId)
                }
              />
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CertificationInstancesView;
