import { TaskTypeEnum } from '@btrway/api-workflow';
import { lazy } from 'react';
import { TaskComponentProps } from '../types/TaskComponentProps';

type SupportedTaskTypes = Extract<
  TaskTypeEnum,
  | 'submitForm'
  | 'completeCourse'
  | 'completeTask'
  | 'completeTaskOption'
  | 'completeWorkflowStep'
  | 'createCalendarEvent'
  | 'sendEmail'
  | 'thirdParty'
>;

export const getTaskComponent = (taskType: TaskTypeEnum) => {
  const taskComponents: Record<
    SupportedTaskTypes,
    React.LazyExoticComponent<React.ComponentType<TaskComponentProps>>
  > = {
    [TaskTypeEnum.submitForm]: lazy(
      () =>
        import('../task-type-components/FormSubmissionTask/FormSubmissionTask')
    ),
    [TaskTypeEnum.completeCourse]: lazy(
      () => import('../task-type-components/CourseTask/CourseTask')
    ),
    [TaskTypeEnum.completeTask]: lazy(
      () => import('../task-type-components/CustomTask/CustomTask')
    ),
    [TaskTypeEnum.completeTaskOption]: lazy(
      () => import('../task-type-components/TaskOptionTask/TaskOptionTask')
    ),
    [TaskTypeEnum.completeWorkflowStep]: lazy(
      () => import('../task-type-components/FormReviewTask/FormReviewTask')
    ),
    [TaskTypeEnum.createCalendarEvent]: lazy(
      () =>
        import('../task-type-components/CalendarEventTask/CalendarEventTask')
    ),
    [TaskTypeEnum.sendEmail]: lazy(
      () => import('../task-type-components/SendEmailTask/SendEmailTask')
    ),
    [TaskTypeEnum.thirdParty]: lazy(
      () => import('../task-type-components/ThirdPartyTask/ThirdPartyTask')
    ),
  };

  return taskComponents[taskType as SupportedTaskTypes] || null;
};
