import {
  StepConfig,
  StepTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useWorkflowBuilderState } from '@btrway/workflow-builder-state';
import {
  CommonWorkflow,
  useWorkflowSteps,
  useWorkflowUpdater,
} from '@btrway/workflow-configuration-manager';
import { Droppable } from '@hello-pangea/dnd';
import {
  ActionIcon,
  Box,
  Card,
  Center,
  Flex,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import StepConfigCard from '../StepConfigCard/StepConfigCard';

interface StepsPanelProps {
  workflow: CommonWorkflow;
  stepConfigs: StepConfig[];
  panelTitle?: string;
  onDelete?: (stepKey: string) => void;
}

const StepsPanel: React.FC<StepsPanelProps> = ({
  workflow,
  stepConfigs,
  panelTitle,
  onDelete,
}) => {
  console.log('StepsPanel', workflow);
  const workflowKey = workflow?.workflowKey!;
  const workflowType = workflow?.workflowType as WorkflowTypeEnum;

  const { workflowConfiguration } = useWorkflowSteps(workflowKey);
  const [localSteps, setLocalSteps] = useState<StepConfig[]>(stepConfigs);
  const { insertStep, removeStep, appendStep, updateConfig } =
    useWorkflowUpdater();
  const { openScreen, openToolbox } = useWorkflowBuilderState();

  useEffect(() => {
    setLocalSteps(workflowConfiguration?.steps || []);
  }, [workflowConfiguration?.steps]);

  // const handleStepUpdate = async (updatedStep: StepConfig, isNew: boolean) => {
  //   if (isNew) {
  //     appendStep(updatedStep);
  //     setLocalSteps((prev) => [...prev, updatedStep]);
  //   } else {
  //     updateConfig({ workflowConfig: { steps: [updatedStep] } });
  //     setLocalSteps((prev) =>
  //       prev.map((step) =>
  //         step.stepKey === updatedStep.stepKey ? updatedStep : step
  //       )
  //     );
  //   }
  // };

  const handleOpenToolbox = () => {
    openToolbox(workflow, 'step');
  };

  const handleStepClick = (step: StepConfig) => {
    openScreen(
      {
        type: 'STEP_CONFIG',
        data: {
          config: step,
          workflow: workflow!,
          isNew: false,
        },
      },
      {
        mode: 'panel',
        position: 'left',
        width: 600,
      }
    );
  };

  const filteredSteps = localSteps.filter(
    (step) =>
      step.stepTypeCode !== StepTypeEnum.flowStart &&
      step.stepTypeCode !== StepTypeEnum.formSubmission
  );
  const isDropDisabled = filteredSteps.length === 0;

  return (
    <Card p="md" withBorder radius="md" mb="md" w="100%" bg="gray.3">
      <Text fz="lg" fw={600} mb="sm">
        {panelTitle || 'Do this...'}
      </Text>
      <Droppable
        droppableId="process-panel-droppable"
        isDropDisabled={isDropDisabled}
      >
        {(provided) => (
          <Stack
            {...provided.droppableProps}
            ref={provided.innerRef}
            gap={0}
            align="center"
          >
            {filteredSteps.length > 0 ? (
              filteredSteps.map((step, index) => (
                <StepConfigCard
                  key={step.stepKey}
                  workflowKey={workflowKey}
                  step={step}
                  index={index}
                  isLast={index === filteredSteps.length - 1}
                  onClick={() => handleStepClick(step)}
                />
              ))
            ) : (
              <Box
                w="100%"
                h={240}
                style={(theme) => ({
                  border: `2px dashed ${theme.colors.gray[5]}`,
                  borderRadius: theme.radius.md,
                  padding: theme.spacing.xl,
                  textAlign: 'center',
                })}
              >
                <Center
                  h="100%"
                  style={{ flexDirection: 'column', gap: '1rem' }}
                >
                  <Text fz="xl" fw={700} c="dimmed">
                    Let's Pick Something to Do!
                  </Text>
                  <ActionIcon
                    variant="light"
                    color="blue"
                    size="xl"
                    radius="xl"
                    onClick={handleOpenToolbox}
                  >
                    <IconPlus size={24} />
                  </ActionIcon>
                </Center>
              </Box>
            )}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
      {filteredSteps.length > 0 && (
        <Flex justify="center" mt="md">
          <Tooltip label="Add Task Assignment or Automated Action">
            <ActionIcon
              variant="light"
              color="blue"
              size="lg"
              radius="xl"
              onClick={handleOpenToolbox}
            >
              <IconPlus size={20} />
            </ActionIcon>
          </Tooltip>
        </Flex>
      )}
    </Card>
  );
};

export default StepsPanel;
