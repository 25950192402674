import { useEvaluateCertification } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ActionIcon, Tooltip } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconRefresh } from '@tabler/icons-react';

interface CertificationEvaluationButtonProps {
  workflowKey: string;
}

export const CertificationEvaluationButton: React.FC<
  CertificationEvaluationButtonProps
> = ({ workflowKey }) => {
  const { mutateAsync: requestEvaluation, isPending } =
    useEvaluateCertification();
  const { currentOrganization } = useAuthenticatedUser();

  const handleEvaluate = async () => {
    try {
      await requestEvaluation({
        organizationId: currentOrganization.id,
        workflowKey,
      });
      notifications.show({
        title: 'Success',
        message: 'Certification evaluation started',
        color: 'green',
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to start certification evaluation',
        color: 'red',
      });
    }
  };

  return (
    <Tooltip label="Evaluate Certifications">
      <ActionIcon
        variant="light"
        color="blue"
        size="md"
        radius="xl"
        onClick={handleEvaluate}
        loading={isPending}
      >
        <IconRefresh size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export default CertificationEvaluationButton;
