import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { OutsideTasksDrawer } from '@btrway/outside-task-administration';
import {
  Badge,
  Box,
  Card,
  Chip,
  Flex,
  Group,
  Skeleton,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getWorkflowDefinitionPath,
  WORKFLOW_TYPE_CONFIG,
  workflowTypeOptions,
} from '../../config/workflowTypeConfig';
import { useWorkflowDefinitions as useWorkflowDefinitionsProvider } from '../../providers/WorkflowDefinitionsProvider';

interface WorkflowCardProps {
  workflow: WorkflowDefinitionResponse;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({ workflow }) => {
  const navigate = useNavigate();
  const workflowConfig =
    WORKFLOW_TYPE_CONFIG[
      workflow.workflowType as keyof typeof WORKFLOW_TYPE_CONFIG
    ];
  const workflowTypeName = workflowConfig?.label || workflow.workflowType;

  const handleClick = () => {
    const redirectPath = getWorkflowDefinitionPath(
      workflow.workflowType,
      workflow.workflowKey
    );
    if (redirectPath) {
      navigate(redirectPath);
    }
  };

  return (
    <Card
      padding="sm"
      radius="md"
      withBorder
      style={{ width: '350px', height: '100px', cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Stack gap="xs">
        <Stack gap={4}>
          <Text size="sm" fw={500} lineClamp={1}>
            {workflow.name}
          </Text>
          <Badge size="xs" variant="light">
            {workflowTypeName}
          </Badge>
        </Stack>
        <Text size="xs" c="dimmed" lineClamp={2}>
          {workflow.description}
        </Text>
        <Group gap="xs">
          <Badge
            size="xs"
            variant="dot"
            color={workflow.enabled ? 'green' : 'gray'}
          >
            {workflow.enabled ? 'Enabled' : 'Disabled'}
          </Badge>
          <Badge
            size="xs"
            variant="dot"
            color={workflow.published ? 'blue' : 'yellow'}
          >
            {workflow.published ? 'Published' : 'Draft'}
          </Badge>
        </Group>
      </Stack>
    </Card>
  );
};

const WorkflowAdminDashboardView: React.FC = () => {
  const { workflowDefinitions } = useWorkflowDefinitionsProvider();
  const [selectedTypes, setSelectedTypes] = useState<string[]>(
    workflowTypeOptions.map((option) => option.value)
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [opened, { open, close }] = useDisclosure(false);

  const filteredWorkflows = React.useMemo(() => {
    if (!workflowDefinitions) return [];

    let filtered = [...workflowDefinitions];

    // Only include allowed workflow types
    filtered = filtered.filter((workflow) =>
      Object.keys(WORKFLOW_TYPE_CONFIG).includes(workflow.workflowType)
    );

    // Filter by selected types
    filtered = filtered.filter((workflow) =>
      selectedTypes.includes(workflow.workflowType)
    );

    // Filter by search term
    if (debouncedSearchTerm) {
      const searchLower = debouncedSearchTerm.toLowerCase();
      filtered = filtered.filter(
        (workflow) =>
          workflow.name?.toLowerCase().includes(searchLower) ||
          workflow.description?.toLowerCase().includes(searchLower)
      );
    }

    return filtered.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflowDefinitions, selectedTypes, debouncedSearchTerm]);

  if (!workflowDefinitions) {
    return (
      <Box m="lg">
        <Stack gap="md">
          <Skeleton height={40} radius="md" />
          <Skeleton height={40} radius="md" />
          <Flex wrap="wrap" gap="md">
            {Array.from({ length: 8 }).map((_, idx) => (
              <Skeleton key={idx} width={250} height={140} radius="md" />
            ))}
          </Flex>
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Stack gap="md">
          <Group justify="space-between" align="flex-end" wrap="nowrap">
            <Group wrap="nowrap" gap="xs">
              <Chip.Group
                multiple
                value={selectedTypes}
                onChange={setSelectedTypes}
              >
                {workflowTypeOptions.map((option) => (
                  <Chip key={option.value} value={option.value}>
                    {option.label}
                  </Chip>
                ))}
              </Chip.Group>

              <TextInput
                style={{ flex: 1, minWidth: '300px' }}
                placeholder="Search workflows..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                leftSection={<IconSearch size="1.1rem" />}
                rightSection={
                  searchTerm && (
                    <IconX
                      size="1.1rem"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setSearchTerm('')}
                    />
                  )
                }
                autoFocus
              />
            </Group>

            {/* <ActionIcon
              variant="subtle"
              color="gray"
              size="lg"
              aria-label="Manage outside tasks"
              onClick={open}
            >
              <IconSettings style={{ width: '70%', height: '70%' }} />
            </ActionIcon> */}
          </Group>

          <Flex wrap="wrap" gap="md">
            {filteredWorkflows.map((workflow) => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))}
          </Flex>

          {filteredWorkflows.length === 0 && (
            <Text c="dimmed" ta="center" py="xl">
              No workflows found matching your criteria
            </Text>
          )}
        </Stack>
      </Box>
      <OutsideTasksDrawer opened={opened} onClose={close} />
    </>
  );
};

export default WorkflowAdminDashboardView;
