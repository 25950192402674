/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type RegistrationFormAvailabilityTypeEnum = typeof RegistrationFormAvailabilityTypeEnum[keyof typeof RegistrationFormAvailabilityTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationFormAvailabilityTypeEnum = {
  always: 'always',
  limited: 'limited',
  annual: 'annual',
} as const;
