import { EventTypeRequest } from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  ActionIcon,
  Button,
  Card,
  ColorPicker,
  Group,
  LoadingOverlay,
  Popover,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useState } from 'react';

interface EventTypeCardProps {
  eventType?: {
    id?: number;
    name: string;
    organizationId: number;
    primaryColor?: string;
  };
  onCancel: () => void;
  onSave: (eventType: EventTypeRequest) => Promise<void>;
  onDelete?: (id: number) => Promise<void>;
  isNew?: boolean;
}

export const EventTypeCard: React.FC<EventTypeCardProps> = ({
  eventType,
  onCancel,
  onSave,
  onDelete,
  isNew = false,
}) => {
  const [isEditing, setIsEditing] = useState(isNew);
  const [name, setName] = useState(eventType?.name ?? '');
  const [primaryColor, setPrimaryColor] = useState(
    eventType?.primaryColor ?? '#228BE6'
  );
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentOrganization } = useAuthenticatedUser();

  const handleSave = async () => {
    if (!name.trim()) return;
    setIsSaving(true);
    try {
      await onSave({
        id: eventType?.id,
        name: name.trim(),
        organizationId: currentOrganization.id,
        primaryColor,
      });
      setIsEditing(false);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!eventType?.id || !onDelete) return;
    setIsDeleting(true);
    try {
      await onDelete(eventType.id);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancel = () => {
    if (isNew) {
      onCancel();
    } else {
      setName(eventType?.name ?? '');
      setPrimaryColor(eventType?.primaryColor ?? '#228BE6');
      setIsEditing(false);
    }
  };

  const ColorCircle = ({
    color,
    size = 24,
  }: {
    color: string;
    size?: number;
  }) => (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: color,
        border: '1px solid #e9ecef',
      }}
    />
  );

  return (
    <Card shadow="sm" padding="md" radius="md" withBorder>
      <LoadingOverlay visible={isSaving || isDeleting} />
      {isEditing ? (
        <Stack>
          <Group align="flex-end">
            <TextInput
              label="Event Type Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter event type name"
              required
              style={{ flex: 1 }}
            />
            <Popover position="bottom" shadow="md">
              <Popover.Target>
                <ActionIcon
                  variant="light"
                  size="lg"
                  mb={2}
                  style={{ backgroundColor: primaryColor }}
                >
                  <ColorCircle color={primaryColor} size={20} />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <ColorPicker
                  format="hex"
                  value={primaryColor}
                  onChange={setPrimaryColor}
                  swatches={[
                    '#25262b',
                    '#868e96',
                    '#fa5252',
                    '#e64980',
                    '#be4bdb',
                    '#7950f2',
                    '#4c6ef5',
                    '#228be6',
                    '#15aabf',
                    '#12b886',
                    '#40c057',
                    '#82c91e',
                    '#fab005',
                    '#fd7e14',
                  ]}
                />
              </Popover.Dropdown>
            </Popover>
          </Group>
          <Group justify="flex-end">
            {!isNew && eventType?.id && onDelete && (
              <Button
                variant="light"
                color="red"
                onClick={handleDelete}
                style={{ marginRight: 'auto' }}
              >
                Delete
              </Button>
            )}
            <Button variant="light" color="gray" onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={!name.trim()}>
              Save
            </Button>
          </Group>
        </Stack>
      ) : (
        <Group>
          <ColorCircle color={primaryColor} />
          <Text size="lg" style={{ flex: 1 }}>
            {eventType?.name}
          </Text>
          <Button variant="light" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        </Group>
      )}
    </Card>
  );
};
