import { Button, Group, Modal, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { RecurrenceState } from '../../types/recurrenceState';
import { generateRule } from '../../utils/recurrenceUtils';
import { RecurrenceSettings } from '../RecurrenceSettings/RecurrenceSettings';

interface RecurrenceModalProps {
  opened: boolean;
  onClose: () => void;
  value?: string;
  onChange: (rule: string) => void;
}

export const RecurrenceModal: React.FC<RecurrenceModalProps> = ({
  opened,
  onClose,
  value,
  onChange,
}) => {
  const [currentState, setCurrentState] = useState<RecurrenceState | null>(
    null
  );

  const handleSave = () => {
    if (currentState) {
      const rule = generateRule(currentState);
      onChange(rule);
      onClose();
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Set Recurrence" size="lg">
      <Stack>
        <RecurrenceSettings value={value} onStateChange={setCurrentState} />
        <Group justify="flex-end" mt="md">
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export { generateRule };
