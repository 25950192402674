import {
  CertificationConfig,
  CertificationRequiredTypeEnum,
  CertificationTypeEnum,
  CertificationValidTypeEnum,
  EventConfig,
  EventTypeEnum,
  StepConfig,
  StepTypeEnum,
  TaskConfig,
  TaskMetadata,
  TaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useStepTypes, useTaskTypes } from '@btrway/workflow-manager';

export const useConfigFactory = () => {
  const { getTaskType } = useTaskTypes();
  const { getStepType } = useStepTypes();

  // Base configs
  const createTaskConfig = (taskType: TaskTypeEnum): TaskConfig => {
    return {
      taskKey: uuid(),
      taskType,
      metadata: {
        title: '',
        description: '',
      } as TaskMetadata,
      taskProperties: {} as TaskProperties,
    };
  };

  const createCertificationConfig = (
    certType: CertificationTypeEnum
  ): CertificationConfig => {
    return {
      certificationKey: uuid(),
      certificationType: certType,
      title: '',
      triggerSets: [],
      taskProperties: {},
      certificationValidConfig: {
        validType: CertificationValidTypeEnum.thisYear,
        withinDays: 0,
      },
      certificationRequiredConfig: {
        requiredType: CertificationRequiredTypeEnum.yes,
        timeValue: 0,
      },
    };
  };

  const createEventConfig = (eventType: EventTypeEnum): EventConfig => {
    return {
      eventKey: uuid(),
      eventTypeCode: eventType,
      title: '',
      triggerSets: [],
      basePropertyConfig: {},
    };
  };

  // Step configs
  const createStepConfig = (
    type: 'step' | 'task',
    code: StepTypeEnum | TaskTypeEnum
  ): StepConfig => {
    const stepKey = uuid();
    let title: string;
    let stepTypeCode: StepTypeEnum;

    if (type === 'step') {
      const stepType = getStepType(code as StepTypeEnum);
      title = stepType ? stepType.name : 'New Step';
      stepTypeCode = code as StepTypeEnum;
    } else {
      const taskType = getTaskType(code as TaskTypeEnum);
      title = taskType ? taskType.name : 'New Task';
      stepTypeCode = StepTypeEnum.assignTask;
    }

    const baseConfig: StepConfig = {
      stepKey,
      stepTypeCode,
      title,
    };

    if (type === 'task') {
      const taskConfig = createTaskConfig(code as TaskTypeEnum);
      return { ...baseConfig, taskConfig };
    }

    return baseConfig;
  };

  // Workflow-specific configs
  const createWorkflowTaskConfig = (
    workflowKey: string,
    workflowName: string,
    taskType: TaskTypeEnum
  ): TaskConfig => {
    return {
      ...createTaskConfig(taskType),
      metadata: {
        title: workflowName,
      } as TaskMetadata,
      taskProperties: {
        workflowSelection: {
          workflowDefinitionKey: { value: workflowKey },
        },
      } as TaskProperties,
    };
  };

  const createWorkflowCertificationConfig = (
    workflowKey: string,
    workflowName: string,
    certType: CertificationTypeEnum
  ): CertificationConfig => {
    return {
      ...createCertificationConfig(certType),
      title: workflowName,
      taskProperties: {
        workflowSelection: {
          workflowDefinitionKey: { value: workflowKey },
        },
      },
    };
  };

  // Course-specific configs
  const createCourseTaskConfig = (
    courseUid: string,
    courseName: string
  ): TaskConfig => {
    return {
      ...createTaskConfig(TaskTypeEnum.completeCourse),
      metadata: {
        title: courseName,
      } as TaskMetadata,
      taskProperties: {
        courseSelection: {
          courseUid: { value: courseUid },
        },
      } as TaskProperties,
    };
  };

  const createCourseCertificationConfig = (
    courseUid: string,
    courseName: string
  ): CertificationConfig => {
    return {
      ...createCertificationConfig(CertificationTypeEnum.completeCourse),
      title: courseName,
      taskProperties: {
        courseSelection: {
          courseUid: { value: courseUid },
        },
      },
    };
  };

  return {
    // Base creators
    createTaskConfig,
    createStepConfig,
    createCertificationConfig,
    createEventConfig,

    // Workflow-specific creators
    createWorkflowTaskConfig,
    createWorkflowCertificationConfig,

    // Course-specific creators
    createCourseTaskConfig,
    createCourseCertificationConfig,
  };
};
