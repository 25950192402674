/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type StorageUsageEnum =
  (typeof StorageUsageEnum)[keyof typeof StorageUsageEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StorageUsageEnum = {
  headshot: 'headshot',
  import: 'import',
  course: 'course',
  curriculum: 'curriculum',
  helparticles: 'helparticles',
  form: 'form',
  email: 'email',
  certificate: 'certificate',
  thirdparty: 'thirdparty',
  event: 'event',
  logo: 'logo',
} as const;
