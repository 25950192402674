import { abwicon } from '@btrway/assets';
import {
  ActionIcon,
  Burger,
  Group,
  Image,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';

interface NavHeaderProps {
  openedNav: boolean;
  toggleNav: () => void;
  toggleAside: (content: 'search') => void;
}

export const NavHeader: React.FC<NavHeaderProps> = ({
  openedNav,
  toggleNav,
  toggleAside,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  if (!isMobile) {
    return null;
  }

  return (
    <Group h="100%" px="md" justify="space-between" pos="relative">
      <Burger
        opened={openedNav}
        onClick={toggleNav}
        size="sm"
        aria-label="Toggle navigation"
        style={{ cursor: 'pointer', position: 'relative', zIndex: 2 }}
      />
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30px',
          height: '30px',
          zIndex: 1,
        }}
      >
        <Image src={abwicon} alt="ABW Icon" height={30} fit="contain" />
      </div>
      <ActionIcon
        variant="subtle"
        color="dark"
        onClick={() => toggleAside('search')}
        style={{ position: 'relative', zIndex: 2 }}
      >
        <IconSearch size={20} stroke={3.5} />
      </ActionIcon>
    </Group>
  );
};
