import {
  RelationshipResponse,
  useGetRelationshipsByPersonIdAndOrganizationId,
} from '@btrway/api-core';
import { HeaderLayout } from '@btrway/layout-components';
import { PersonTaskListGridView } from '@btrway/task-grids';
import { useTaskFetcher } from '@btrway/task-manager';
import { Alert, Paper, Stack, Text, Title } from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from '@btrway/current-user';

const ParentDashboardPage: React.FC = () => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const { fetchMultiPersonTaskListTasks } = useTaskFetcher();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { data: relationshipsResponse, isLoading: isLoadingRelationships } =
    useGetRelationshipsByPersonIdAndOrganizationId(
      currentPerson.organizationId,
      currentPerson.id
    );

  // Collect all personIds that we need to fetch tasks for
  const personIds = useMemo(() => {
    const ids = currentPerson ? [currentPerson.id] : [];
    if (relationshipsResponse) {
      ids.push(...relationshipsResponse.map((rel) => rel.toPerson.id));
    }
    return ids;
  }, [currentPerson, relationshipsResponse]);

  // Fetch tasks for all people when relationships are loaded
  useEffect(() => {
    if (
      !currentOrganization.id ||
      isLoadingRelationships ||
      !personIds.length
    ) {
      return;
    }

    setIsLoading(true);
    setError(null);

    // Create a single request for all person IDs
    const fetchAllTasks = async () => {
      try {
        await fetchMultiPersonTaskListTasks(personIds, {
          onSuccess: () => setIsLoading(false),
          onError: (err) => {
            console.error('Error fetching tasks:', err);
            setError(err as Error);
            setIsLoading(false);
          },
        });
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError(err as Error);
        setIsLoading(false);
      }
    };

    fetchAllTasks();
  }, [
    currentOrganization.id,
    isLoadingRelationships,
    personIds,
    fetchMultiPersonTaskListTasks,
  ]);

  if (!currentPerson) {
    return null;
  }

  if (isLoading || isLoadingRelationships) {
    return null;
  }

  if (error) {
    return (
      <Alert color="red" title="Error">
        Failed to load tasks: {error.message}
      </Alert>
    );
  }

  return (
    <HeaderLayout
      header={<Title order={3}>Hi, {currentPerson.firstName}</Title>}
      headerBg="transparent"
      subtitleComponent={
        <Text fz={14} c="dimmed">
          View and manage your tasks and your children's tasks
        </Text>
      }
    >
      <Stack gap="lg">
        {/* Current Person's Tasks */}
        <Paper shadow="xs" p="md">
          <Stack gap="md">
            <Title order={4}>My Tasks</Title>
            <PersonTaskListGridView personId={currentPerson.id} />
          </Stack>
        </Paper>

        {/* Children's Tasks */}
        {relationshipsResponse && relationshipsResponse.length > 0 && (
          <Paper shadow="xs" p="md">
            <Stack gap="md">
              <Title order={4}>Children's Tasks</Title>
              {relationshipsResponse.map(
                (relationship: RelationshipResponse) => (
                  <Stack key={relationship.id} gap="md">
                    <Title order={5}>
                      {relationship.toPerson.firstName}'s Tasks
                    </Title>
                    <PersonTaskListGridView
                      personId={relationship.toPerson.id}
                    />
                  </Stack>
                )
              )}
            </Stack>
          </Paper>
        )}
      </Stack>
    </HeaderLayout>
  );
};

export default ParentDashboardPage;
