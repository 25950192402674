import { Box, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { ToolboxItem } from '../../types/itemType';
import { ToolboxIcon } from '../ToolboxIcon/ToolboxIcon';

interface ToolboxCardProps {
  item: ToolboxItem;
  onClick: (item: ToolboxItem) => void;
  index?: number;
  isDraggable?: boolean;
  variant?: 'compact' | 'full';
}

export const ToolboxCard: React.FC<ToolboxCardProps> = ({
  item,
  onClick,
  index,
  isDraggable = false,
  variant = 'compact',
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(item);
  };

  const CardContent = () =>
    variant === 'compact' ? (
      <Stack align="center" justify="flex-start" h="100%" py="xs" gap="xs">
        <Box>
          <ToolboxIcon type={item.type} code={item.data.code} />
        </Box>
        <Box>
          <Text size="xs" ta="center" lineClamp={2}>
            {item.data.name}
          </Text>
        </Box>
      </Stack>
    ) : (
      <Group align="flex-start" wrap="nowrap">
        <Box
          w={32}
          h={32}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ToolboxIcon type={item.type} code={item.data.code} />
        </Box>
        <Stack gap="xs" style={{ flex: 1, minWidth: 0 }}>
          <Text fz="md" fw={500} lineClamp={1}>
            {item.data.name}
          </Text>
          {item.data.description && (
            <Text size="sm" c="dimmed" lineClamp={2}>
              {item.data.description}
            </Text>
          )}
        </Stack>
      </Group>
    );

  const card = (
    <Card
      bg="white"
      radius="md"
      p="xs"
      withBorder
      onClick={handleClick}
      h={variant === 'compact' ? undefined : 100}
      style={{
        cursor: 'pointer',
        aspectRatio: variant === 'compact' ? '1 / 1' : undefined,
      }}
    >
      <CardContent />
    </Card>
  );

  if (!isDraggable || typeof index === 'undefined') {
    return card;
  }

  return <>{card}</>;

  // return (
  //   <Draggable draggableId={`${item.type}-${item.data.code}`} index={index}>
  //     {(provided, snapshot) => {
  //       const offset = 60;
  //       let style = { ...provided.draggableProps.style };

  //       if (snapshot.isDragging) {
  //         const draggingStyle = style as DraggingStyle;
  //         if (typeof draggingStyle.top === 'number') {
  //           draggingStyle.top -= offset;
  //         }
  //       }

  //       return (
  //         <>
  //           <div
  //             ref={provided.innerRef}
  //             {...provided.draggableProps}
  //             {...provided.dragHandleProps}
  //             style={style}
  //           >
  //             {card}
  //           </div>
  //           {snapshot.isDragging && <div style={{ opacity: 0.5 }}>{card}</div>}
  //         </>
  //       );
  //     }}
  //   </Draggable>
  // );
};
