/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TimeUnitEnum = typeof TimeUnitEnum[keyof typeof TimeUnitEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeUnitEnum = {
  year: 'year',
  month: 'month',
  week: 'week',
  day: 'day',
  hour: 'hour',
  minute: 'minute',
} as const;
