// Layout constants used throughout the application
export const LAYOUT = {
  PANEL: {
    DEFAULT_WIDTH: 400,
    HEADER_HEIGHT: 41,
    FOOTER_HEIGHT: 72,
    FOOTER_PADDING: 16,
    ANIMATION_DURATION: 300,
  },
  TRANSITION: {
    DURATION: 300,
    TIMING: 'ease',
  },
  Z_INDEX: {
    PANEL: 100,
    MODAL: 200,
  },
} as const;
