import {
  MessageDeliveryTypeEnum,
  MessageRecipientResponse,
  useGetMessageRecipientsByMessageId,
} from '@btrway/api-messaging';
import { EntityTag } from '@btrway/entity-tags';
import {
  Badge,
  Card,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconDeviceMobileMessage,
  IconMail,
  IconMessage2,
} from '@tabler/icons-react';
import React from 'react';

interface MessageRecipientListProps {
  messageId: number;
  selectedRecipient?: MessageRecipientResponse;
  onSelectRecipient: (recipient: MessageRecipientResponse) => void;
}

const DeliveryTypeIcon = {
  [MessageDeliveryTypeEnum.email]: IconMail,
  [MessageDeliveryTypeEnum.sms]: IconMessage2,
  [MessageDeliveryTypeEnum.push]: IconDeviceMobileMessage,
} as const;

export const MessageRecipientList: React.FC<MessageRecipientListProps> = ({
  messageId,
  selectedRecipient,
  onSelectRecipient,
}) => {
  const { data: messageRecipients, isLoading } =
    useGetMessageRecipientsByMessageId(messageId);

  if (isLoading) return <Loader />;
  if (!messageRecipients?.length)
    return <Text c="dimmed">No recipients found</Text>;

  return (
    <ScrollArea h="100%">
      <Stack>
        {messageRecipients.map((recipient) => {
          const Icon =
            DeliveryTypeIcon[
              recipient.messageDeliveryType as keyof typeof MessageDeliveryTypeEnum
            ];
          return (
            <Card
              key={recipient.id}
              withBorder
              shadow={recipient.id === selectedRecipient?.id ? 'md' : 'sm'}
              onClick={() => onSelectRecipient(recipient)}
              style={{ cursor: 'pointer' }}
            >
              <Group justify="space-between" wrap="nowrap">
                <EntityTag
                  allowClick={false}
                  dataType="person"
                  id={recipient.personId}
                />
                <Group gap="xs">
                  <Icon size={24} />

                  <Badge>{recipient.status}</Badge>
                </Group>
              </Group>
            </Card>
          );
        })}
      </Stack>
    </ScrollArea>
  );
};
