import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { chapterProgressAtom } from '../atoms/chapterProgressAtom';
import {
  ChapterProgress,
  ChapterWithProgress,
  CourseProgress,
  CourseStatus,
} from '../types/progressTypes';

export const useInitialCoursewareProgress = () => {
  const getInitialChapterProgress = useAtomCallback(
    useCallback((get, _set, chapter: ChapterDTO): ChapterProgress => {
      const progressList = get(chapterProgressAtom);
      const chapterProgress = progressList.find(
        (p) => p.chapterKey === chapter.key
      );

      return {
        progress: chapterProgress,
        isComplete: Boolean(chapterProgress?.completed),
        currentTimeSeconds: chapterProgress?.currentTimeSeconds || 0,
        maxTimeSeconds: chapterProgress?.maxTimeSeconds || 0,
      };
    }, [])
  );

  const getInitialFirstIncompleteChapter = useAtomCallback(
    useCallback(
      (get, _set, course: AssetResponse): ChapterWithProgress | null => {
        const progressList = get(chapterProgressAtom);
        const chapters = course.chapters || [];

        const firstIncompleteChapter = chapters.find(
          (chapter) =>
            !progressList.some(
              (p) => p.chapterKey === chapter.key && p.completed
            )
        );

        const targetChapter = firstIncompleteChapter || chapters[0];
        const progress = progressList.find(
          (p) => p.chapterKey === targetChapter?.key
        );

        if (!targetChapter) {
          return null;
        }

        return {
          chapter: targetChapter,
          progress,
        };
      },
      []
    )
  );

  const getInitialCourseProgress = useAtomCallback(
    useCallback((get, _set, course: AssetResponse): CourseProgress => {
      const progressList = get(chapterProgressAtom);
      const courseChapters = course.chapters || [];

      const chapterProgress = courseChapters.map((chapter) =>
        progressList.find((p) => p.chapterKey === chapter.key)
      );

      const totalDuration = courseChapters.reduce(
        (sum, chapter) => sum + (chapter.duration || 0),
        0
      );

      const completedDuration = chapterProgress.reduce(
        (sum, progress, index) =>
          sum + (progress?.completed ? courseChapters[index].duration || 0 : 0),
        0
      );

      const status = (() => {
        if (chapterProgress.every((p) => p?.completed)) {
          return CourseStatus.Complete;
        }
        if (chapterProgress.some((p) => p)) {
          return CourseStatus.Started;
        }
        return CourseStatus.NotStarted;
      })();

      const resumePoint = (() => {
        let duration = 0;
        for (let i = 0; i < courseChapters.length; i++) {
          const chapter = courseChapters[i];
          const progress = chapterProgress[i];

          if (!progress?.completed) {
            return duration + (progress?.currentTimeSeconds || 0);
          }

          duration += chapter.duration || 0;
        }
        return duration;
      })();

      return {
        status,
        resumePoint,
        completionPercent: totalDuration
          ? (completedDuration / totalDuration) * 100
          : 0,
        totalDuration,
        completedDuration,
      };
    }, [])
  );

  return {
    getInitialChapterProgress,
    getInitialCourseProgress,
    getInitialFirstIncompleteChapter,
  };
};
