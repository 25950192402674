import {
  ColDef,
  GridOptions,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ModuleRegistry,
  RowSelectedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import {
  PagedResponseSearchUserResponse,
  SearchGlobalUserRequest,
  useSearchGlobalUsers,
} from '@btrway/api-core';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { formatPhoneNumber } from '@btrway/utils';
import { Box, Flex, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import UserAccessButton from '../UserAccessButton/UserAccessButton';
import styles from './GlobalUsersGrid.module.css';

ModuleRegistry.registerModules([ServerSideRowModelModule]);

interface GlobalUsersGridProps {
  searchValue: string;
  onRowSelected: (params: RowSelectedEvent) => void;
  organizationId?: number;
}

const NameCellRenderer: React.FC<any> = (props) => {
  const { data } = props;
  if (!data) return null;

  return (
    <Flex align="center" gap="sm">
      <span>{`${data.lastName || ''}, ${data.firstName || ''}`}</span>
      <UserAccessButton
        personId={data.personId}
        organizationId={data.organizationId}
        email={data.email}
        size={14}
      />
    </Flex>
  );
};

const columnDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    cellRenderer: NameCellRenderer,
    valueGetter: (params: ValueGetterParams) => {
      const { data } = params;
      return data ? `${data.lastName || ''}, ${data.firstName || ''}` : '';
    },
    sort: 'asc',
    flex: 2,
  },
  {
    headerName: 'Email',
    field: 'userEmail',
  },
  {
    headerName: 'Phone',
    field: 'mobilePhone',
    valueFormatter: (params: ValueFormatterParams) => {
      return formatPhoneNumber(params.value);
    },
  },
  {
    headerName: 'Organization',
    field: 'organizationName',
    valueGetter: (params: ValueGetterParams) => {
      const { data } = params;
      return data ? data.organizationName || '' : '';
    },
  },
];

const GlobalUsersGrid: React.FC<GlobalUsersGridProps> = ({
  searchValue,
  onRowSelected,
  organizationId,
}) => {
  useAddHelpTags(['global-users']);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const [gridApi, setGridApi] = useState<any>(null);
  const { mutate: getUsers } = useSearchGlobalUsers();
  const isInitialLoadRef = useRef(true);
  const prevSearchValueRef = useRef(searchValue);

  const datasource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const { startRow = 0, endRow = 100, sortModel } = params.request;

      if (searchValue.length < 2) {
        params.success({ rowData: [], rowCount: 0 });
        return;
      }

      //Set Default Sort for the case that no column is sorted
      const sortFields: string[] = ['lastName', 'firstName'];
      const sortOrders: string[] = ['asc', 'asc'];

      sortModel.forEach((model) => {
        if (model.colId === 'name') {
          sortFields.push('lastName', 'firstName');
          sortOrders.push(model.sort, model.sort);
        } else if (model.colId === 'userEmail') {
          sortFields.push('userEmail', 'lastName', 'firstName');
          sortOrders.push(model.sort, model.sort, model.sort);
        } else if (model.colId === 'phone') {
          sortFields.push('phone', 'lastName', 'firstName');
          sortOrders.push(model.sort, model.sort, model.sort);
        } else if (model.colId === 'organizationName') {
          sortFields.push('organizationName', 'lastName', 'firstName');
          sortOrders.push(model.sort, model.sort, model.sort);
        }
      });

      const searchRequest: SearchGlobalUserRequest = {
        searchValue,
        startRow,
        endRow,
        sortFields,
        sortOrders,
        organizationId,
      };

      getUsers(
        { data: searchRequest },
        {
          onSuccess: (data: unknown) => {
            const searchResponse = data as PagedResponseSearchUserResponse;
            const rowsThisBlock = searchResponse.content;
            const lastRow =
              searchResponse.totalElements <= endRow
                ? searchResponse.totalElements
                : -1;
            params.success({ rowData: rowsThisBlock, rowCount: lastRow });
            if (gridApi) {
              gridApi.sizeColumnsToFit();
            }
          },
          onError: () => {
            params.fail();
          },
        }
      );
    },
  };

  useEffect(() => {
    if (gridApi) {
      if (searchValue.length >= 2) {
        gridApi.updateGridOptions({ serverSideDatasource: datasource });
      } else {
        gridApi.setGridOption('serverSideDatasource', null);
        // gridApi.setRowData([]);
      }
    }
  }, [gridApi, searchValue]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
    if (searchValue.length >= 2) {
      params.api.setGridOption('serverSideDatasource', datasource);
    } else {
      params.api.setGridOption('serverSideDatasource', null);
      // params.api.setRowData([]);
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!gridApi) return;

      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        const currentNode = gridApi.getSelectedNodes()[0];
        const currentIndex = currentNode ? currentNode.rowIndex : -1;
        const nextIndex =
          event.key === 'ArrowDown' ? currentIndex + 1 : currentIndex - 1;
        const nextNode = gridApi.getDisplayedRowAtIndex(nextIndex);

        if (nextNode) {
          nextNode.setSelected(true);
          gridApi.ensureNodeVisible(nextNode);

          // Manually trigger the onRowSelected callback
          onRowSelected({
            type: 'rowSelected',
            api: gridApi,
            node: nextNode,
            rowIndex: nextIndex,
            data: nextNode.data,
            context: null,
          } as RowSelectedEvent);
        }
      }
    },
    [gridApi, onRowSelected]
  );

  const gridOptions: GridOptions = {
    defaultColDef: {
      sortable: true,
    },
    onGridReady,
    onRowSelected,
    rowSelection: 'single',
  };

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const mobileGridStyle: React.CSSProperties = {
    ...gridStyle,
    border: '1px solid transparent',
  };

  return (
    <Box className={styles.root} style={containerStyle}>
      <Box
        className={`${styles.gridContainer} ${styles.grid} ${
          isMobile ? styles.mobileGrid : styles.desktopGrid
        } ${isMobile ? '' : 'ag-theme-quartz'}`}
        style={isMobile ? mobileGridStyle : gridStyle}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowModelType="serverSide"
          cacheBlockSize={100}
          maxConcurrentDatasourceRequests={1}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
        />
      </Box>
    </Box>
  );
};

export default GlobalUsersGrid;
