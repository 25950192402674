import { atom } from 'jotai';

interface CourseViewerTransitionTarget {
  courseUid: number;
  resume: boolean;
}

export const isCourseViewerTransitioningAtom = atom(false);
export const courseViewerTransitionTargetAtom =
  atom<CourseViewerTransitionTarget | null>(null);

export const startCourseViewerTransitionAtom = atom(
  null,
  (get, set, { courseUid, resume }: CourseViewerTransitionTarget) => {
    set(isCourseViewerTransitioningAtom, true);
    set(courseViewerTransitionTargetAtom, { courseUid, resume });
  }
);

export const endCourseViewerTransitionAtom = atom(null, (get, set) => {
  set(isCourseViewerTransitioningAtom, false);
  set(courseViewerTransitionTargetAtom, null);
});
