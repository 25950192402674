import {
  Box,
  Group,
  MultiSelect,
  NumberInput,
  Radio,
  Select,
  Stack,
  type ComboboxItem,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import React, { useEffect } from 'react';
import { RecurrenceState } from '../../types/recurrenceState';
import { parseRecurrenceRule } from '../../utils/recurrenceUtils';

interface RecurrenceSettingsProps {
  value?: string;
  onStateChange: (state: RecurrenceState) => void;
  initialState?: RecurrenceState;
}

export const RecurrenceSettings = ({
  value,
  onStateChange,
  initialState,
}: RecurrenceSettingsProps) => {
  // Parse the initial recurrence rule if no initialState is provided
  const parsedRule = initialState || parseRecurrenceRule(value);
  const [frequency, setFrequency] = React.useState(parsedRule.frequency);
  const [interval, setInterval] = React.useState(parsedRule.interval);
  const [weekDays, setWeekDays] = React.useState(parsedRule.weekDays);
  const [endDate, setEndDate] = React.useState<Date | null>(parsedRule.endDate);
  const [endType, setEndType] = React.useState<'never' | 'on'>(
    parsedRule.endType
  );

  const weekDayOptions = [
    { value: 'MO', label: 'Monday' },
    { value: 'TU', label: 'Tuesday' },
    { value: 'WE', label: 'Wednesday' },
    { value: 'TH', label: 'Thursday' },
    { value: 'FR', label: 'Friday' },
    { value: 'SA', label: 'Saturday' },
    { value: 'SU', label: 'Sunday' },
  ];

  // Update state when value or initialState changes
  useEffect(() => {
    const newState = initialState || parseRecurrenceRule(value);
    setFrequency(newState.frequency);
    setInterval(newState.interval);
    setWeekDays(newState.weekDays);
    setEndDate(newState.endDate);
    setEndType(newState.endType);
  }, [value, initialState]);

  // Notify parent of state changes
  useEffect(() => {
    onStateChange({
      frequency,
      interval,
      weekDays,
      endType,
      endDate,
    });
  }, [frequency, interval, weekDays, endType, endDate, onStateChange]);

  return (
    <Box>
      <Stack>
        <Radio.Group
          label="Repeat"
          value={frequency}
          onChange={(newFreq) => {
            setFrequency(newFreq);
            if (newFreq !== 'WEEKLY') {
              setWeekDays([]);
            }
          }}
        >
          <Group mt="xs">
            <Radio value="DAILY" label="Daily" />
            <Radio value="WEEKLY" label="Weekly" />
            <Radio value="MONTHLY" label="Monthly" />
            <Radio value="YEARLY" label="Yearly" />
          </Group>
        </Radio.Group>

        <Group grow>
          <NumberInput
            label="Repeat every"
            value={interval}
            onChange={(val) => setInterval(Number(val))}
            min={1}
            max={99}
          />

          {frequency === 'WEEKLY' && (
            <MultiSelect
              label="Repeat on"
              data={weekDayOptions}
              value={weekDays}
              onChange={setWeekDays}
              clearable={false}
              searchable={false}
            />
          )}
        </Group>

        <Group grow>
          <Select
            label="Ends"
            data={[
              { value: 'never', label: 'Never' },
              { value: 'on', label: 'On date' },
            ]}
            value={endType}
            onChange={(value: string | null, _item: ComboboxItem) => {
              const newValue = (value || 'never') as 'never' | 'on';
              setEndType(newValue);
              if (newValue === 'never') {
                setEndDate(null);
              }
            }}
          />

          <DatePickerInput
            label="End date"
            value={endDate}
            onChange={setEndDate}
            disabled={endType === 'never'}
          />
        </Group>
      </Stack>
    </Box>
  );
};
