import { render } from '@react-email/render';
import BannerEmailTemplate from '../../templates/BannerEmailTemplate/BannerEmailTemplate';

interface TemplateConfig {
  component: React.ComponentType<any>;
  defaultSettings: Record<string, any>;
  previewContent: React.ReactNode;
}

// Define a standard content placeholder that will be used across all templates
const CONTENT_PLACEHOLDER = (
  <div
    data-email-content-placeholder="true"
    style={{
      padding: '40px 20px',
      backgroundColor: '#f8f9fa',
      border: '2px dashed #dee2e6',
      borderRadius: '4px',
      textAlign: 'center',
      margin: '20px 0',
    }}
  >
    <div
      style={{
        fontSize: '20px',
        color: '#6c757d',
        marginBottom: '10px',
      }}
    >
      Email Content Area
    </div>
    <div
      style={{
        fontSize: '14px',
        color: '#adb5bd',
      }}
    >
      Your email content will be inserted here
    </div>
  </div>
);

export const TEMPLATE_REGISTRY: Record<string, TemplateConfig> = {
  banner: {
    component: BannerEmailTemplate,
    defaultSettings: {
      bannerImageUrl:
        'https://adash.io/wp-content/uploads/2021/06/Your-Logo-here.png', // Replace with your default logo
      bannerHeight: 120,
      bannerColor: '#003366',
      previewText: 'BetterWay message',
    },
    previewContent: CONTENT_PLACEHOLDER,
  },
};

// Helper function to generate template HTML
export const generateTemplateHtml = async (
  templateType: string,
  settings?: Record<string, any>
) => {
  const config = TEMPLATE_REGISTRY[templateType];
  if (!config) throw new Error(`Template type ${templateType} not found`);

  const Template = config.component;
  const templateSettings = {
    ...config.defaultSettings,
    ...settings,
  };

  const email = (
    <Template {...templateSettings}>{config.previewContent}</Template>
  );

  return render(email);
};
