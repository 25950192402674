import { RecurrenceModificationTypeEnum } from '@btrway/api-calendar';
import { Button, Group, Modal, Radio, Stack, Text } from '@mantine/core';
import { useState } from 'react';

interface RecurrenceModificationDialogProps {
  opened: boolean;
  onClose: () => void;
  onConfirm: (type: RecurrenceModificationTypeEnum) => void;
  showAllOptions?: boolean;
  title?: string;
  confirmLabel?: string;
  description?: string;
}

export const RecurrenceModificationDialog = ({
  opened,
  onClose,
  onConfirm,
  showAllOptions = true,
  title = 'Modify Recurring Event',
  confirmLabel = 'Continue',
  description = 'This is a recurring event. How would you like to modify it?',
}: RecurrenceModificationDialogProps) => {
  const [selectedType, setSelectedType] =
    useState<RecurrenceModificationTypeEnum>(
      RecurrenceModificationTypeEnum.thisEvent
    );

  const handleConfirm = () => {
    onConfirm(selectedType);
    onClose();
  };

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="md">
      <Stack>
        <Text size="sm">{description}</Text>

        <Radio.Group
          value={selectedType}
          onChange={(value) =>
            setSelectedType(value as RecurrenceModificationTypeEnum)
          }
        >
          <Stack gap="xs">
            {showAllOptions && (
              <Radio
                value={RecurrenceModificationTypeEnum.thisEvent}
                label="This event only"
                description="Only affects this specific occurrence"
              />
            )}
            <Radio
              value={RecurrenceModificationTypeEnum.thisAndFollowingEvents}
              label="This and following events"
              description="Affects this event and all future occurrences"
            />
            <Radio
              value={RecurrenceModificationTypeEnum.allEvents}
              label="All events"
              description="Affects all occurrences in the series"
            />
          </Stack>
        </Radio.Group>

        <Group justify="flex-end" mt="md">
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button
            color={confirmLabel === 'Delete' ? 'red' : 'blue'}
            onClick={handleConfirm}
          >
            {confirmLabel}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
