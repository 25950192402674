import {
  CertificationTaskProperties,
  DateAssignedTypeEnum,
  TaskConfig,
  TaskListTaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { useHelpResources, useTaskHelpResources } from '@btrway/help-manager';
import { ResourcesPanel } from '@btrway/workflow-builder-resources-panel';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import { TaskTypeIcon } from '@btrway/workflow-components';
import {
  CommonWorkflow,
  useSelectedItem,
} from '@btrway/workflow-configuration-manager';
import { useTaskTypes } from '@btrway/workflow-manager';
import { Card, Group, Stack, Text, Tooltip } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React, { useCallback, useState } from 'react';
import { NestedWorkflowView } from '../NestedWorkflowView/NestedWorkflowView';
import { TaskCardDateBadges } from '../TaskCardDateBadges/TaskCardDateBadges';
import { TaskCardResourcesButton } from '../TaskCardResourcesButton/TaskCardResourcesButton';

interface TaskCardProps {
  workflow: CommonWorkflow;
  taskConfig: TaskConfig;
  index: number;
  isLast: boolean;
  onClick: () => void;
}

const TaskCard: React.FC<TaskCardProps> = ({
  workflow,
  taskConfig,
  index,
  isLast,
  onClick,
}) => {
  const workflowKey = workflow.workflowKey || '';
  const { taskKey, taskType } = taskConfig;

  const [isExpanded, setIsExpanded] = useState(false);
  const { selectedItem, setSelectedItem } = useSelectedItem();
  const { openDisplay } = useSettingsDisplay();
  const { getTaskType } = useTaskTypes();

  const selectedTaskKey =
    selectedItem?.type === 'task' ? selectedItem.key : null;

  const { allHelpResources, isLoadingActive, refetchAllResources } =
    useHelpResources();
  const { getTaskHelpUsages, updateHelpResourceAssociations } =
    useTaskHelpResources(workflowKey);

  const helpResources = getTaskHelpUsages(workflowKey, taskKey, taskType);
  const helpResourceKeys = helpResources
    .map((resource) => resource.helpResourceKey)
    .filter(Boolean);

  const handleResourcesClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const handleUpdateResources = async (resourceKeys: string[]) => {
        await updateHelpResourceAssociations(taskKey, taskType, resourceKeys);
      };

      const handleRefetch = async () => {
        await refetchAllResources();
      };

      console.log('handleResourcesClick', taskKey);
      setSelectedItem({ type: 'task', key: taskKey });

      openDisplay(
        <ResourcesPanel
          workflowKey={workflowKey}
          taskConfig={taskConfig}
          allHelpResources={allHelpResources}
          isLoadingActive={isLoadingActive}
          initialSelectedResources={helpResourceKeys}
          onUpdateResources={handleUpdateResources}
          onRefetchResources={handleRefetch}
        />,
        { mode: 'panel', position: 'right' }
      );
    },
    [
      workflowKey,
      taskConfig,
      taskKey,
      taskType,
      allHelpResources,
      isLoadingActive,
      helpResourceKeys,
      updateHelpResourceAssociations,
      refetchAllResources,
      openDisplay,
    ]
  );

  const handleCardClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onClick();
    },
    [taskKey, onClick]
  );

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };
  const renderNestedContent = () => {
    let taskListWorkflowKey: string | undefined;
    let certificationWorkflowKey: string | undefined;

    if (
      taskType === TaskTypeEnum.completeTaskList ||
      taskType === TaskTypeEnum.completeCurriculum ||
      taskType === TaskTypeEnum.completeFormPacket ||
      taskType === TaskTypeEnum.completeTaskOption
    ) {
      const properties = taskConfig.taskProperties as TaskListTaskProperties;
      taskListWorkflowKey =
        properties?.workflowSelection?.workflowDefinitionKey?.value;
    } else if (taskType === TaskTypeEnum.certification) {
      const properties =
        taskConfig.taskProperties as CertificationTaskProperties;
      certificationWorkflowKey =
        properties?.workflowSelection?.workflowDefinitionKey?.value;
    }

    if (!isExpanded) return null;

    return (
      <>
        {taskListWorkflowKey && (
          <NestedWorkflowView
            workflowKey={taskListWorkflowKey}
            parentWorkflowKey={workflowKey}
            type="task"
          />
        )}
        {certificationWorkflowKey && (
          <NestedWorkflowView
            workflowKey={certificationWorkflowKey}
            parentWorkflowKey={workflowKey}
            type="certification"
          />
        )}
      </>
    );
  };

  const hasNestedContent =
    taskType === TaskTypeEnum.completeTaskList ||
    taskType === TaskTypeEnum.completeCurriculum ||
    taskType === TaskTypeEnum.certification ||
    taskType === TaskTypeEnum.completeFormPacket ||
    taskType === TaskTypeEnum.completeTaskOption;

  //Temporary solution until we decide how to sub-type task lists
  const isAbwTaskList =
    workflow.workflowMetadata?.taskListType === 'ABW To-Do List';
  const isSpecificDays =
    workflow.workflowConfiguration?.settings?.taskListSettings
      ?.assignedDateType === DateAssignedTypeEnum.taskSpecific;
  const taskTypeData = getTaskType(taskType);

  return (
    <div onClick={handleCardClick} style={{ width: '100%' }}>
      <Card
        p="md"
        w="100%"
        radius="md"
        withBorder
        shadow="xs"
        style={{
          marginBottom: 0,
          cursor: 'pointer',
          borderColor: taskKey === selectedTaskKey ? 'blue' : undefined,
          borderWidth: taskKey === selectedTaskKey ? 2 : 1,
        }}
      >
        <Group wrap="nowrap" align="flex-start">
          <div style={{ flexShrink: 0 }}>
            <Tooltip label={taskTypeData?.name ?? taskType}>
              <span>
                {' '}
                {/* Add this wrapper */}
                <TaskTypeIcon iconName={taskType} size={24} />
              </span>
            </Tooltip>
          </div>
          <Stack gap="md" style={{ flex: 1, minWidth: 0 }}>
            <Group justify="space-between" wrap="nowrap" align="flex-start">
              <Stack gap="xs" style={{ flex: 1, minWidth: 0 }}>
                <Group justify="flex-start" align="center" wrap="nowrap">
                  <Text fz="md" fw={600} truncate>
                    {taskConfig.metadata?.title || 'No title'}
                  </Text>
                  {hasNestedContent && (
                    <div
                      onClick={handleExpandClick}
                      style={{ cursor: 'pointer', display: 'flex' }}
                    >
                      {isExpanded ? (
                        <IconChevronDown size={20} />
                      ) : (
                        <IconChevronRight size={20} />
                      )}
                    </div>
                  )}
                </Group>
                {taskConfig.metadata?.subtitle && (
                  <Text fz="xs" fw={500} style={{ wordBreak: 'break-word' }}>
                    {taskConfig.metadata?.subtitle}
                  </Text>
                )}
              </Stack>
              <Group gap="md" wrap="nowrap" style={{ flexShrink: 0 }}>
                {isSpecificDays && (
                  <TaskCardDateBadges
                    startDate={taskConfig.taskListConfig?.startDate?.offsetDays}
                    endDate={taskConfig.taskListConfig?.endDate?.offsetDays}
                  />
                )}
                {isAbwTaskList && (
                  <TaskCardResourcesButton
                    resourceCount={helpResources.length}
                    onClick={handleResourcesClick}
                  />
                )}
              </Group>
            </Group>
            {renderNestedContent()}
          </Stack>
        </Group>
      </Card>
    </div>
  );
};

export default TaskCard;
