import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';

// Shared components and hooks
export const EditorSearchInput: React.FC<{
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}> = ({ searchTerm, setSearchTerm }) => (
  <TextInput
    placeholder="Search by name..."
    value={searchTerm}
    onChange={(event) => setSearchTerm(event.currentTarget.value)}
    leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
    rightSection={
      searchTerm ? (
        <CloseButton onClick={() => setSearchTerm('')} size="sm" />
      ) : null
    }
  />
);
