import { ActionIcon, Button, Grid, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

interface MonthDaySelectorProps {
  month: number | undefined;
  day: number | undefined;
  onChange: (month: number | undefined, day: number | undefined) => void;
}

const MonthDaySelector: React.FC<MonthDaySelectorProps> = ({
  month,
  day,
  onChange,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(month);
  const [selectedDay, setSelectedDay] = useState<number | undefined>(day);

  useEffect(() => {
    setSelectedMonth(month);
    setSelectedDay(day);
  }, [month, day]);

  const handleMonthClick = (newMonth: number) => {
    setSelectedMonth(newMonth);
    onChange(newMonth, selectedDay);
  };

  const handleDayClick = (newDay: number) => {
    setSelectedDay(newDay);
    onChange(selectedMonth, newDay);
  };

  const renderDayButtons = () => {
    if (!selectedMonth) return null;

    const rows = [];
    const numberOfDays = daysInMonth[selectedMonth - 1];

    for (let i = 0; i < numberOfDays; i += 7) {
      const week = [];
      for (let j = i; j < i + 7 && j < numberOfDays; j++) {
        const day = j + 1;
        week.push(
          <Grid.Col span={1} key={day} p={4}>
            <ActionIcon
              variant={selectedDay === day ? 'filled' : 'subtle'}
              color={selectedDay === day ? 'blue' : 'gray'}
              radius="xs"
              size="md"
              aria-label={`Select day ${day}`}
              onClick={() => handleDayClick(day)}
            >
              <Text size="xs">{day}</Text>
            </ActionIcon>
          </Grid.Col>
        );
      }
      rows.push(
        <Grid columns={7} key={i}>
          {week}
        </Grid>
      );
    }

    return <>{rows}</>;
  };

  return (
    <Grid gutter={0}>
      <Grid.Col span={4}>
        <Stack p={6} bg="gray.1" h="100%" justify="center">
          <Grid columns={2} p={6} style={{ height: '100%' }}>
            {months.map((monthName, index) => (
              <Grid.Col key={monthName} span={1} p={2}>
                <Button
                  fullWidth
                  size="compact-xs"
                  variant={selectedMonth === index + 1 ? 'filled' : 'subtle'}
                  color={selectedMonth === index + 1 ? 'blue' : 'gray'}
                  onClick={() => handleMonthClick(index + 1)}
                >
                  {monthName.slice(0, 3).toUpperCase()}
                </Button>
              </Grid.Col>
            ))}
          </Grid>
        </Stack>
      </Grid.Col>
      <Grid.Col span={8} p="md">
        {renderDayButtons()}
      </Grid.Col>
    </Grid>
  );
};

export default MonthDaySelector;
