import { RegistrationFormResponse, WorkgroupResponse } from '@btrway/api-core';
import { Card, Stack, Text } from '@mantine/core';
import { mergeRegistrationFormConfig } from '../../utils/registrationFormAdministrationUtils';

interface RegistrationFormCardProps {
  form: RegistrationFormResponse;
  onClick: () => void;
  workgroup: WorkgroupResponse;
}

export function RegistrationFormCard({
  form,
  onClick,
  workgroup,
}: RegistrationFormCardProps) {
  const mergedConfig = mergeRegistrationFormConfig(form, workgroup.id);

  return (
    <Card
      shadow="sm"
      padding="lg"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <Stack gap="xs">
        <Text fw={500}>{mergedConfig.title}</Text>
        {mergedConfig.shortDescription && (
          <Text size="sm" c="dimmed">
            {mergedConfig.shortDescription}
          </Text>
        )}
      </Stack>
    </Card>
  );
}
