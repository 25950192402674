import { EntityTaskResponse } from '@btrway/api-workflow';
import {
  useTaskFetcher,
  useTaskManager,
  useTaskModal,
} from '@btrway/task-manager';
import { TaskTypeIcon } from '@btrway/workflow-components';
import { Badge, Card, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { format } from 'date-fns';
import React, { useCallback, useEffect } from 'react';

interface PersonTaskViewProps {
  personId: number;
}

interface TaskGroup {
  title: string;
  tasks: EntityTaskResponse[];
}

const EXCLUDED_TASK_TYPES = [
  'completeTaskList',
  'certification',
  'completeFormPacket',
  'completeCurriculum',
] as const;

export const PersonTaskView: React.FC<PersonTaskViewProps> = ({ personId }) => {
  const { openTask } = useTaskModal();
  const { fetchTasksWithCriteria } = useTaskFetcher();
  const { getTaskListTasksByPerson } = useTaskManager();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);

  // Fetch tasks on mount and when personId changes
  useEffect(() => {
    setIsLoading(true);
    setError(null);

    fetchTasksWithCriteria(
      [personId],
      {},
      {
        onSuccess: () => setIsLoading(false),
        onError: (err) => {
          setError(err as Error);
          setIsLoading(false);
        },
      }
    );
  }, [personId, fetchTasksWithCriteria]);

  const tasks = getTaskListTasksByPerson(personId);

  // Group tasks by taskListName
  const taskGroups = React.useMemo(() => {
    const groups = new Map<string, EntityTaskResponse[]>();

    // Group tasks by taskListName, using "Other Tasks" for those without a list name
    tasks.forEach((task) => {
      const groupName = task.rollupWorkflowName || 'Other Tasks';
      if (!groups.has(groupName)) {
        groups.set(groupName, []);
      }
      groups.get(groupName)!.push(task);
    });

    // Convert map to array of TaskGroup objects and sort tasks
    return Array.from(groups.entries()).map(([title, tasks]) => ({
      title,
      tasks: tasks.sort((a, b) => {
        // Sort by due date if available, then by assigned date
        if (a.dueAt && b.dueAt)
          return new Date(a.dueAt).getTime() - new Date(b.dueAt).getTime();
        if (a.assignedAt && b.assignedAt)
          return (
            new Date(a.assignedAt).getTime() - new Date(b.assignedAt).getTime()
          );
        return 0;
      }),
    }));
  }, [tasks]);

  const handleTaskClick = useCallback(
    (task: EntityTaskResponse) => {
      openTask({
        taskProperties: task.taskProperties || {},
        taskInstanceId: task.taskInstanceId,
        assignedEntityType: task.assignedEntityType,
        assignedEntityId: task.assignedEntityId,
        workgroupId: task.workgroupId,
        derivedWorkflowKey: task.parentWorkflowKey,
        taskKey: task.taskKey,
        taskType: task.taskType,
      });
    },
    [openTask]
  );

  if (isLoading) return <Loader />;
  if (error) return <Text c="red">Error loading tasks: {error.message}</Text>;

  return (
    <Stack gap="xl">
      {taskGroups.map((group) => (
        <Card key={group.title} withBorder shadow="sm">
          <Title order={4} mb="md">
            {group.title}
          </Title>
          <Stack gap="sm">
            {group.tasks
              .filter(
                (task) => !EXCLUDED_TASK_TYPES.includes(task.taskType as any)
              )
              .map((task) => (
                <Card
                  key={task.taskInstanceId || task.taskKey}
                  radius="sm"
                  withBorder
                  onClick={() => handleTaskClick(task)}
                  style={{ cursor: 'pointer' }}
                >
                  <Group justify="space-between" mb="xs">
                    <Group gap="sm">
                      {task.taskType && (
                        <TaskTypeIcon iconName={task.taskType} size={20} />
                      )}
                      <Text fw={500}>{task.taskTitle}</Text>
                    </Group>
                    <Group gap="xs">
                      {task.completed && (
                        <Badge color={task.completed ? 'green' : 'blue'}>
                          {task.completed ? 'Completed' : 'Open'}
                        </Badge>
                      )}
                      {/* {task.taskType && (
                        <Badge variant="outline" color="gray">
                          {task.taskType}
                        </Badge>
                      )} */}
                    </Group>
                  </Group>

                  {task.taskDescription && (
                    <Text size="sm" c="dimmed" mb="md">
                      {task.taskDescription}
                    </Text>
                  )}

                  <Group gap="xs">
                    {task.dueAt && (
                      <Text size="sm" c="dimmed">
                        Due: {format(new Date(task.dueAt), 'MMM dd, yyyy')}
                      </Text>
                    )}
                    {task.assignedAt && (
                      <Text size="sm" c="dimmed">
                        Assigned:{' '}
                        {format(new Date(task.assignedAt), 'MMM dd, yyyy')}
                      </Text>
                    )}
                    {/* {task.taskListConfig?.startDate?.offsetDays !==
                      undefined && (
                      <Badge variant="dot" color="blue">
                        Start Offset: {task.taskListConfig.startDate.offsetDays}{' '}
                        days
                      </Badge>
                    )}
                    {task.taskListConfig?.endDate?.offsetDays !== undefined && (
                      <Badge variant="dot" color="grape">
                        Due Offset: {task.taskListConfig.endDate.offsetDays}{' '}
                        days
                      </Badge>
                    )} */}
                  </Group>
                </Card>
              ))}
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};
