import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, GridApi, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import {
  WorkgroupRequest,
  WorkgroupResponse,
  WorkgroupTypeResponse,
} from '@btrway/api-core';
import { useWorkgroups, useWorkgroupTypes } from '@btrway/workgroup-manager';
import { Box, Button, Group } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { AddWorkgroupModal } from '../AddWorkgroupModal/AddWorkgroupModal';
import WorkgroupDetailView from '../WorkgroupDetailView/WorkgroupDetailView';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule]);

const WorkgroupsGridView: React.FC = () => {
  const { organization } = useOrganization();

  const { workgroups, deleteWorkgroup, addWorkgroup } = useWorkgroups(
    organization.id
  );
  const { workgroupTypes, getWorkgroupType } = useWorkgroupTypes(
    organization.id
  );
  const gridApiRef = useRef<GridApi | null>(null);

  console.log('workgroupTypes: ', workgroupTypes);
  const [isAddingWorkgroup, setIsAddingWorkgroup] = useState(false);
  const [selectedParentWorkgroup, setSelectedParentWorkgroup] =
    useState<WorkgroupResponse | null>(null);
  const [selectedWorkgroup, setSelectedWorkgroup] =
    useState<WorkgroupResponse | null>(null);
  const [isDetailView, setIsDetailView] = useState(false);

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.refreshCells({ force: true });
    }
  }, [workgroupTypes]);

  const handleDelete = useCallback(
    (id: number) => {
      if (window.confirm('Are you sure you want to delete this workgroup?')) {
        deleteWorkgroup(id);
      }
    },
    [deleteWorkgroup]
  );

  const handleAdd = useCallback((parentWorkgroup: WorkgroupResponse) => {
    setSelectedParentWorkgroup(parentWorkgroup);
    setIsAddingWorkgroup(true);
  }, []);

  const handleAddWorkgroup = useCallback(
    (workgroupRequest: WorkgroupRequest) => {
      addWorkgroup(workgroupRequest);
    },
    [addWorkgroup]
  );

  const getAddButtonLabel = useCallback(
    (workgroupType: WorkgroupTypeResponse) => {
      if (
        !workgroupType.childWorkgroupTypes ||
        workgroupType.childWorkgroupTypes.length === 0
      ) {
        return 'Add';
      }
      if (workgroupType.childWorkgroupTypes.length === 1) {
        return `Add ${workgroupType.childWorkgroupTypes[0].name}`;
      }
      return 'Add';
    },
    []
  );

  const actionsRenderer = useCallback(
    (params: any) => {
      const workgroup = params.data as WorkgroupResponse;
      const workgroupType = getWorkgroupType(workgroup.workgroupType.id);
      const isRootRow = params.node.level === 1;

      return (
        <Group justify="flex-end" gap="sm" align="center" h="100%">
          {workgroupType &&
            workgroupType.childWorkgroupTypes &&
            workgroupType.childWorkgroupTypes.length > 0 && (
              <Button
                variant="light"
                size="compact-sm"
                onClick={() => handleAdd(workgroup)}
                leftSection={<IconPlus size={12} />}
              >
                {getAddButtonLabel(workgroupType)}
              </Button>
            )}
          <Box w={36} h={28}>
            {' '}
            {!isRootRow && (
              <Button
                variant="light"
                size="compact-sm"
                color="red"
                onClick={() => handleDelete(params.data.id)}
                style={{ position: 'absolute' }}
              >
                <IconTrash size={12} />
              </Button>
            )}
          </Box>
        </Group>
      );
    },
    [
      handleDelete,
      handleAdd,
      getAddButtonLabel,
      getWorkgroupType,
      workgroupTypes,
    ]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'workgroupType.name',
        headerName: 'Workgroup Type',
        filter: false,
      },
      {
        field: 'address',
        headerName: 'Address',
        valueGetter: (params) => {
          const { physicalAddress } = params.data;
          if (!physicalAddress) return '';
          const { street1, city, stateProvinceCode, postalCode } =
            physicalAddress;
          return `${street1 || ''}, ${city || ''}, ${stateProvinceCode || ''} ${
            postalCode || ''
          }`.trim();
        },
        filter: false,
      },
      {
        headerName: 'Actions',
        cellRenderer: actionsRenderer,
        minWidth: 200,
        filter: false,
      },
    ],
    [actionsRenderer]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      sortable: true,
      filter: false,
    }),
    []
  );

  const autoGroupColumnDef = useMemo<ColDef>(
    () => ({
      headerName: 'Name',
      minWidth: 300,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: (params: any) => params.data.name,
      },
      sort: 'asc',
    }),
    []
  );

  const getDataPath = useCallback((data: any) => {
    return data.path.split('.').map((segment: string) => {
      const [name] = segment.split(':');
      return name.trim();
    });
  }, []);

  const getRowId = useCallback((params: any) => {
    return params.data.id;
  }, []);

  const onGridReady = useCallback((params: any) => {
    gridApiRef.current = params.api;
  }, []);

  const onRowClicked = useCallback((event: any) => {
    setSelectedWorkgroup(event.data);
    setIsDetailView(true);
    if (gridApiRef.current) {
      const allColumns = gridApiRef.current.getColumns();
      if (allColumns) {
        const columnKeys = allColumns.map((col) => col.getColId());
        gridApiRef.current.setColumnsVisible(columnKeys.slice(1), false);
      }
      gridApiRef.current.sizeColumnsToFit();
    }
  }, []);

  const handleBackToGrid = useCallback(() => {
    setIsDetailView(false);
    setSelectedWorkgroup(null);
    if (gridApiRef.current) {
      const allColumns = gridApiRef.current.getColumns();
      if (allColumns) {
        const columnKeys = allColumns.map((col) => col.getColId());
        gridApiRef.current.setColumnsVisible(columnKeys, true);
      }
      gridApiRef.current.sizeColumnsToFit();
    }
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div
        className="ag-theme-quartz"
        style={{
          height: '100%',
          width: isDetailView ? '300px' : '100%',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <AgGridReact
          rowData={workgroups}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          groupDefaultExpanded={1}
          getDataPath={getDataPath}
          getRowId={getRowId}
          animateRows={true}
          onGridReady={onGridReady}
          onRowClicked={onRowClicked}
        />
      </div>
      {isDetailView && selectedWorkgroup && (
        <div style={{ flexGrow: 1, paddingLeft: '20px' }}>
          <WorkgroupDetailView
            workgroup={selectedWorkgroup}
            onClose={handleBackToGrid}
          />
        </div>
      )}
      {selectedParentWorkgroup && (
        <AddWorkgroupModal
          isOpen={isAddingWorkgroup}
          onClose={() => setIsAddingWorkgroup(false)}
          onAdd={handleAddWorkgroup}
          organizationId={organization.id}
          parentWorkgroupId={selectedParentWorkgroup.id}
          availableWorkgroupTypes={
            getWorkgroupType(selectedParentWorkgroup.workgroupType.id)
              ?.childWorkgroupTypes || []
          }
        />
      )}
    </div>
  );
};

export default WorkgroupsGridView;
