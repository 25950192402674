import { AssetResponse } from '@btrway/api-courseware';
import {
  Box,
  Card,
  Group,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconChevronRight, IconSearch, IconX } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';

interface CourseSelectorProps {
  courses: AssetResponse[];
  selectedCourseUid: string | null;
  onCourseSelect: (courseUid: string, name: string) => void;
  onClose: () => void;
}

interface GroupedCourses {
  folderId: number;
  folderTitle: string;
  courses: AssetResponse[];
}

const CourseSelectionCard: React.FC<{
  course: AssetResponse;
  onSelect: (uid: string, name: string) => void;
  isSelected: boolean;
}> = ({ course, onSelect, isSelected }) => (
  <Card
    shadow="none"
    padding="xs"
    radius="md"
    withBorder
    onClick={() => onSelect(course.uid.toString(), course.title)}
    style={{
      cursor: 'pointer',
      backgroundColor: isSelected
        ? 'var(--mantine-color-blue-light)'
        : undefined,
    }}
  >
    <Group justify="space-between" wrap="nowrap">
      <Text fz="sm" fw={isSelected ? 600 : 400}>
        {course.title}
      </Text>
      <IconChevronRight
        size={18}
        color={isSelected ? 'var(--mantine-color-blue-6)' : undefined}
      />
    </Group>
  </Card>
);

export const CourseSelector: React.FC<CourseSelectorProps> = ({
  courses,
  selectedCourseUid,
  onCourseSelect,
  onClose,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);

  const groupedCourses = useMemo((): GroupedCourses[] => {
    // Group courses by folder
    const groupsMap = new Map<number, GroupedCourses>();

    courses.forEach((course) => {
      const folderId = course.assetFolder.id;
      const folderTitle = course.assetFolder.title;

      if (!groupsMap.has(folderId)) {
        groupsMap.set(folderId, {
          folderId,
          folderTitle,
          courses: [],
        });
      }

      groupsMap.get(folderId)?.courses.push(course);
    });

    // Convert map to array and sort folders alphabetically
    return Array.from(groupsMap.values())
      .sort((a, b) => a.folderTitle.localeCompare(b.folderTitle))
      .map((group) => ({
        ...group,
        courses: group.courses.sort((a, b) => a.title.localeCompare(b.title)),
      }));
  }, [courses]);

  const filteredGroups = useMemo(() => {
    if (!debouncedSearchTerm) {
      return groupedCourses;
    }

    const searchTermLower = debouncedSearchTerm.toLowerCase();
    return groupedCourses
      .map((group) => ({
        ...group,
        courses: group.courses.filter((course) =>
          course.title.toLowerCase().includes(searchTermLower)
        ),
      }))
      .filter((group) => group.courses.length > 0);
  }, [groupedCourses, debouncedSearchTerm]);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box p="md" style={{ position: 'relative' }}>
        <TextInput
          placeholder="Search by title..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
          rightSection={
            searchTerm && (
              <IconX
                size="1rem"
                style={{ cursor: 'pointer' }}
                onClick={handleClearSearch}
              />
            )
          }
          styles={(theme) => ({
            input: {
              height: 'auto',
              paddingTop: '0.3rem',
              paddingBottom: '0.3rem',
              paddingLeft: '2.5rem',
              paddingRight: searchTerm ? '2.5rem' : theme.spacing.xl,
            },
            rightSection: {
              pointerEvents: 'auto',
              paddingRight: theme.spacing.xs,
            },
            leftSection: {
              width: '2.5rem',
              pointerEvents: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          })}
        />
      </Box>

      <ScrollArea style={{ flex: 1 }}>
        <Stack gap="xs" p="md" pt={0}>
          {filteredGroups.map((group) => (
            <Stack key={group.folderId} gap="xs">
              <Text fw={500} fz="sm" mt="sm">
                {group.folderTitle}
              </Text>
              {group.courses.map((course) => (
                <CourseSelectionCard
                  key={course.uid}
                  course={course}
                  onSelect={onCourseSelect}
                  isSelected={course.uid.toString() === selectedCourseUid}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </ScrollArea>
    </Box>
  );
};
