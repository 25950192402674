/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type EntityGroupTypeEnum = typeof EntityGroupTypeEnum[keyof typeof EntityGroupTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityGroupTypeEnum = {
  manual: 'manual',
  dynamic: 'dynamic',
} as const;
