import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';

interface GlobalUserSearchInputProps {
  onSearch: (searchValue: string) => void;
}

const GlobalUserSearchInput: React.FC<GlobalUserSearchInputProps> = ({
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchValue(value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    onSearch('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (searchValue) {
      onSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue, onSearch]);

  return (
    <TextInput
      ref={inputRef}
      placeholder="Search by name, email, phone, or organization name..."
      value={searchValue}
      onChange={handleSearch}
      w="50%"
      autoFocus
      size="lg"
      radius="xl"
      leftSection={<IconSearch size={18} stroke={1.5} />}
      rightSection={
        searchValue && (
          <CloseButton
            onClick={handleClearSearch}
            size="sm"
            style={{ marginRight: '8px' }}
          />
        )
      }
      styles={{
        input: {
          '&:focus': {
            borderColor: 'blue',
            boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.3)',
          },
        },
      }}
    />
  );
};

export default GlobalUserSearchInput;
