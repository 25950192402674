import {
  EntityTaskResponse,
  TaskRetrievalRequest,
  TaskRetrievalRequestCompletionStatus,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { taskInstancesAtom } from '../atoms/taskInstancesAtoms';
import { reconcileTaskInstances } from '../utils/reconcileTaskInstances';
import { TaskQueryResponse, useTaskQueries } from './useTaskQueries';

interface TaskOptions {
  onSuccess?: (data: EntityTaskResponse[]) => void;
  onError?: (error: unknown) => void;
}

export const useTaskFetcher = () => {
  const setTasks = useSetAtom(taskInstancesAtom);
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const {
    fetchTasks,
    getTaskAssignmentsRequest,
    getTaskListTasksRequest,
    getMultiPersonTaskListRequest,
  } = useTaskQueries();

  // Core fetch and update function
  const managedFetchTasks = useCallback(
    async (request: TaskRetrievalRequest): Promise<TaskQueryResponse> => {
      const response = await fetchTasks(request);

      setTasks((prevTasks) =>
        reconcileTaskInstances(prevTasks, response.content)
      );
      return response;
    },
    [fetchTasks, setTasks]
  );

  // Fetch assignments for current user
  const fetchTaskAssignments = useCallback(
    async (options?: TaskOptions) => {
      if (!currentPerson.id || !currentOrganization.id) return;

      try {
        const request = getTaskAssignmentsRequest(
          currentPerson.id,
          currentOrganization.id
        );
        const response = await managedFetchTasks(request);
        options?.onSuccess?.(response.content);
        return response;
      } catch (error) {
        options?.onError?.(error);
        throw error;
      }
    },
    [
      currentPerson.id,
      currentOrganization.id,
      getTaskAssignmentsRequest,
      managedFetchTasks,
    ]
  );

  // Fetch task list tasks for a single person
  const fetchTaskListTasks = useCallback(
    async (personId: number, options?: TaskOptions) => {
      if (!currentOrganization.id) {
        const error = new Error('No current organization');
        options?.onError?.(error);
        throw error;
      }

      try {
        const request = getTaskListTasksRequest(
          personId,
          currentOrganization.id
        );
        const response = await managedFetchTasks(request);
        options?.onSuccess?.(response.content);
        return response;
      } catch (error) {
        options?.onError?.(error);
        throw error;
      }
    },
    [currentOrganization.id, getTaskListTasksRequest, managedFetchTasks]
  );

  // Fetch task list tasks for multiple people
  const fetchMultiPersonTaskListTasks = useCallback(
    async (personIds: number[], options?: TaskOptions) => {
      if (!currentOrganization.id) {
        const error = new Error('No current organization');
        options?.onError?.(error);
        throw error;
      }

      try {
        const request = getMultiPersonTaskListRequest(
          personIds,
          currentOrganization.id
        );
        const response = await managedFetchTasks(request);
        options?.onSuccess?.(response.content);
        return response;
      } catch (error) {
        options?.onError?.(error);
        throw error;
      }
    },
    [currentOrganization.id, getMultiPersonTaskListRequest, managedFetchTasks]
  );

  // New helper function to fetch tasks with custom criteria
  const fetchTasksWithCriteria = useCallback(
    async (
      personIds: number[],
      criteria: {
        includeRoleBasedTasks?: boolean;
        workflowType?: WorkflowTypeEnum;
        taskTypes?: TaskTypeEnum[];
        completionStatus?: TaskRetrievalRequestCompletionStatus;
      },
      options?: TaskOptions
    ) => {
      if (!currentOrganization.id) {
        const error = new Error('No current organization');
        options?.onError?.(error);
        throw error;
      }

      try {
        const request: TaskRetrievalRequest = {
          organizationId: currentOrganization.id,
          personIds,
          includeRoleBasedTasks: criteria.includeRoleBasedTasks,
          workflowType: criteria.workflowType,
          taskTypes: criteria.taskTypes,
          completionStatus: criteria.completionStatus,
        };

        const response = await managedFetchTasks(request);
        options?.onSuccess?.(response.content);
        return response;
      } catch (error) {
        options?.onError?.(error);
        throw error;
      }
    },
    [currentOrganization.id, managedFetchTasks]
  );

  return {
    fetchTaskAssignments,
    fetchTaskListTasks,
    fetchMultiPersonTaskListTasks,
    fetchTasksWithCriteria, // New flexible fetching method
    managedFetchTasks,
  };
};
