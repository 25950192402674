import { FC, ReactNode } from 'react';
import { useInitializeProgress } from '../hooks/useInitializeProgress';

interface CoursewareProgressManagerProps {
  children: ReactNode;
}

export const CoursewareProgressManager: FC<CoursewareProgressManagerProps> = ({
  children,
}) => {
  const { isLoading } = useInitializeProgress();

  // Simple wrapper that initializes progress data
  return <>{children}</>;
};
