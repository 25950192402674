import { ICellRendererParams } from '@ag-grid-community/core';
import { Anchor } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface ValueObject {
  to: string;
  displayText: string;
}

export interface LinkGridCellParams extends ICellRendererParams {
  value: ValueObject;
}

export const LinkGridCell: React.FC<LinkGridCellParams> = ({ value }) => {
  const navigate = useNavigate();

  if (
    !value ||
    typeof value !== 'object' ||
    !('to' in value) ||
    !('displayText' in value)
  ) {
    return null;
  }

  return (
    <Anchor
      href="#"
      fz={{ base: 'xl', sm: 'sm' }}
      onClick={(e) => {
        e.preventDefault();
        navigate(value.to, { replace: true });
      }}
    >
      {value.displayText}
    </Anchor>
  );
};
