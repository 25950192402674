import { Box, Group, Text } from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import { IconCalendar, IconClock } from '@tabler/icons-react';
import React, { useCallback } from 'react';
import { EventDateTime } from '../../types/eventDateTime';
import {
  formatTimeString,
  getDateFromISOString,
  parseTimeString,
} from '../../utils/timeUtils';

interface CalendarEventDatesProps {
  value: EventDateTime;
  onChange: (value: EventDateTime) => void;
}

export const CalendarEventDates: React.FC<CalendarEventDatesProps> = ({
  value,
  onChange,
}) => {
  // Memoized handlers
  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      if (!date) return;

      const currentStartDate = getDateFromISOString(value.startTime);
      const newStartDate = new Date(date);
      newStartDate.setHours(
        currentStartDate.getHours(),
        currentStartDate.getMinutes(),
        0,
        0
      );

      // Calculate new end date maintaining the same duration
      const duration =
        getDateFromISOString(value.endTime).getTime() -
        currentStartDate.getTime();
      const newEndDate = new Date(newStartDate.getTime() + duration);

      onChange({
        ...value,
        startTime: newStartDate.toISOString(),
        endTime: newEndDate.toISOString(),
      });
    },
    [value, onChange]
  );

  const handleStartTimeChange = useCallback(
    (timeStr: string) => {
      const { hours, minutes } = parseTimeString(timeStr);

      const newStartDate = getDateFromISOString(value.startTime);
      newStartDate.setHours(hours, minutes, 0, 0);

      // Maintain duration when changing start time
      const duration =
        getDateFromISOString(value.endTime).getTime() -
        getDateFromISOString(value.startTime).getTime();
      const newEndDate = new Date(newStartDate.getTime() + duration);

      onChange({
        ...value,
        startTime: newStartDate.toISOString(),
        endTime: newEndDate.toISOString(),
      });
    },
    [value, onChange]
  );

  const handleEndDateChange = useCallback(
    (date: Date | null) => {
      if (!date) return;

      const currentEndDate = getDateFromISOString(value.endTime);
      const newEndDate = new Date(date);
      newEndDate.setHours(
        currentEndDate.getHours(),
        currentEndDate.getMinutes(),
        0,
        0
      );

      onChange({
        ...value,
        endTime: newEndDate.toISOString(),
      });
    },
    [value, onChange]
  );

  const handleEndTimeChange = useCallback(
    (timeStr: string) => {
      const { hours, minutes } = parseTimeString(timeStr);

      const newEndDate = getDateFromISOString(value.endTime);
      newEndDate.setHours(hours, minutes, 0, 0);

      onChange({
        ...value,
        endTime: newEndDate.toISOString(),
      });
    },
    [value, onChange]
  );

  const handleTimezoneChange = useCallback(
    (timezone: string | null) => {
      onChange({
        ...value,
        timeZoneName: timezone || 'America/New_York',
      });
    },
    [value, onChange]
  );

  const startDate = getDateFromISOString(value.startTime);
  const endDate = getDateFromISOString(value.endTime);

  return (
    <Box>
      <Group justify="flex-start" align="flex-end">
        <DateInput
          label="Start Date"
          leftSection={<IconCalendar size={16} />}
          value={startDate}
          onChange={handleStartDateChange}
          clearable={false}
        />

        {!value.allDayEvent && (
          <TimeInput
            label="Start Time"
            leftSection={<IconClock size={16} />}
            value={formatTimeString(startDate)}
            onChange={(event) =>
              handleStartTimeChange(event.currentTarget.value)
            }
          />
        )}

        <Text size="sm" style={{ marginBottom: '8px' }}>
          to
        </Text>

        {!value.allDayEvent && (
          <TimeInput
            label="End Time"
            leftSection={<IconClock size={16} />}
            value={formatTimeString(endDate)}
            onChange={(event) => handleEndTimeChange(event.currentTarget.value)}
            error={value.hasError}
          />
        )}

        <DateInput
          label="End Date"
          leftSection={<IconCalendar size={16} />}
          value={endDate}
          onChange={handleEndDateChange}
          error={value.hasError}
          clearable={false}
        />

        {/* <TimezoneSelect
          value={value.timeZoneName}
          onChange={handleTimezoneChange}
        /> */}
      </Group>
    </Box>
  );
};
