import { UnauthenticatedCurrentUserValue } from '../types/currentUserTypes';
import { useCurrentUserQuery } from './useCurrentUserQuery';

export function useCurrentUser(): UnauthenticatedCurrentUserValue {
  const query = useCurrentUserQuery();

  return {
    currentUser: query.data,
    currentPerson: query.data?.person,
    currentOrganization: query.data?.organization,
    rootWorkgroupId: query.data?.rootWorkgroupId,
    workgroupMembers: query.data?.workgroupMembers ?? [],
    rootWorkgroup: query.rootWorkgroup,
    isLoading: query.isLoading,
    refetchCurrentUser: query.refetch,
    allPermissions: query.allPermissions,
  };
}
