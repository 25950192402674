import { AssetResponse } from '@btrway/api-courseware';
import { Carousel } from '@mantine/carousel';
import { Box, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import React, { useState } from 'react';
import CourseCarouselItem from '../CourseCarouselItem/CourseCarouselItem';

interface CourseCarouselProps {
  courses: AssetResponse[];
  onPlayCourse: (courseUid: number) => void;
  title: string;
  height: number;
  growthFactor?: number;
}

const CourseCarousel: React.FC<CourseCarouselProps> = ({
  courses,
  onPlayCourse,
  title,
  height,
  growthFactor = 1,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const isTablet = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isLaptop = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);

  const getVisibleSlides = () => {
    if (isMobile) return 1;
    if (isTablet) return 2;
    if (isLaptop) return 3;
    return 5;
  };

  const visibleSlides = getVisibleSlides();
  const slidesToScroll = Math.max(1, visibleSlides - 1);

  const growthSpace = height * (growthFactor - 1);
  const paddingArea = Math.max(growthSpace, 40); // Minimum 40px for title
  const carouselHeight = height + paddingArea;

  return (
    <Box
      style={{
        width: '100%',
        height: `${carouselHeight}px`,
        position: 'relative',
      }}
    >
      <Text
        fz="xl"
        fw={600}
        c="white"
        style={{
          position: 'absolute',
          bottom: `${height}px`,
          left: 0,
          zIndex: 1,
          padding: '5px',
          height: `${paddingArea}px`,
          display: 'flex',
          alignItems: 'flex-end',
        }}
      >
        {title}
      </Text>
      <Box
        style={{
          position: 'relative',
          height: `${carouselHeight}px`,
          zIndex: 2,
        }}
      >
        <Carousel
          withIndicators
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          height={`${carouselHeight}px`}
          slideSize={{
            base: '100%',
            sm: '50%',
            md: '33.333333%',
            lg: '25%',
            xl: '20%',
          }}
          slideGap="xs"
          align="start"
          slidesToScroll={slidesToScroll}
          w="100%"
          previousControlProps={{
            style: { left: '-50px' },
          }}
          nextControlProps={{
            style: { right: '-50px' },
          }}
          previousControlIcon={<IconChevronLeft size={32} />}
          nextControlIcon={<IconChevronRight size={32} />}
          styles={{
            viewport: {
              paddingTop: `${paddingArea}px`,
            },
            control: {
              color: 'white',
              border: 'none',
              boxShadow: 'none',
              background: 'transparent',
              width: '40px',
              height: '40px',
              '&:hover': {
                color: 'rgba(255, 255, 255, 0.8)',
                background: 'transparent',
              },
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out, color 0.3s ease-in-out',
            },
            controls: {
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: '-50px',
              right: '-50px',
              width: 'auto',
            },
          }}
        >
          {courses.map((course, index) => (
            <Carousel.Slide key={course.id}>
              <CourseCarouselItem
                course={course}
                onPlay={onPlayCourse}
                isFirst={index === 0}
                isLast={
                  (index + 1) % visibleSlides === 0 ||
                  index === courses.length - 1
                }
                growthFactor={growthFactor}
                itemHeight={height}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default CourseCarousel;
