import { Paper, Stack, Text } from '@mantine/core';
import React from 'react';

interface TaskStatisticMetricProps {
  value: string | number;
  label: string;
}

export const TaskStatisticMetric: React.FC<TaskStatisticMetricProps> = ({
  value,
  label,
}) => (
  <Paper p="xs" shadow="sm" radius="sm">
    <Stack gap={2} align="center">
      <Text size="lg" fw={600}>
        {value}
      </Text>
      <Text size="xs" color="dimmed">
        {label}
      </Text>
    </Stack>
  </Paper>
);
