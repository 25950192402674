import { CertificationInstanceGridResponse } from '@btrway/api-workflow';
import { RequirementCounts } from '../types/certificationTypes';

export const getStatusColor = (completed: boolean) => {
  return completed ? 'green' : 'orange';
};

export const getProgressColor = (
  completedCount: number,
  totalCount: number
) => {
  if (completedCount === 0) return 'red';
  if (completedCount === totalCount) return 'green';
  return 'blue';
};

export const getRequirementCounts = (
  requirements: CertificationInstanceGridResponse[]
): RequirementCounts => {
  const completedCount = requirements.filter((req) => req.completed).length;
  const totalCount = requirements.length;
  return {
    count: `${completedCount}/${totalCount}`,
    completedCount,
    totalCount,
  };
};

export const getRequirementStatusColor = (
  completed: boolean,
  requirementMet: boolean | undefined
) => {
  if (completed) return 'green';
  if (requirementMet === false) return 'red';
  return 'yellow';
};

export const getRequirementStatusText = (
  completed: boolean,
  requirementMet: boolean | undefined
) => {
  if (completed) return 'Completed';
  if (requirementMet === false) return 'Not Satisfied';
  return 'Pending';
};
