import { Box } from '@mantine/core';
import MuxPlayer, {
  MaxResolution,
  MinResolution,
  RenditionOrder,
} from '@mux/mux-player-react';
import React, { ReactNode } from 'react';

interface VideoBackgroundProps {
  playbackId: string;
  currentTime?: number;
  children: ReactNode;
  height?: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({
  playbackId,
  currentTime,
  children,
  height = '100%',
}) => {
  return (
    <Box style={{ width: '100%', height, position: 'relative' }}>
      <MuxPlayer
        style={
          {
            width: '100%',
            height: '100%',
            '--media-object-fit': 'cover',
            '--media-object-position': 'center',
            '--controls': 'none',
          } as React.CSSProperties
        }
        streamType="on-demand"
        maxResolution={MaxResolution.upTo720p}
        minResolution={MinResolution.noLessThan480p}
        renditionOrder={RenditionOrder.DESCENDING}
        playbackId={playbackId}
        metadataVideoTitle="A Better Way"
        autoPlay
        muted
        loop
        defaultHiddenCaptions
        currentTime={currentTime}
      />
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'linear-gradient(135deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.6) 100%)',
          zIndex: 1,
        }}
      />
      <Box
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          height: '30%', // Adjust this value to control the fade height
          background:
            'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 100%)',
          zIndex: 2,
        }}
      />
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default VideoBackground;
