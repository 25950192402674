import { EntityTaskResponse } from '@btrway/api-workflow';
import { getStableTaskIdentifier } from './getStableTaskIdentifier';

/**
 * Reconciles existing and new tasks, maintaining the most up-to-date version of each task
 * based on their stable identifiers
 */
export const reconcileTaskInstances = (
  existingTasks: EntityTaskResponse[],
  newTasks: EntityTaskResponse[]
): EntityTaskResponse[] => {
  // Create a map of existing tasks using stable identifiers
  const taskMap = new Map<string, EntityTaskResponse>();

  // Add existing tasks to the map
  existingTasks.forEach((task) => {
    const key = getStableTaskIdentifier(task);
    taskMap.set(key, task);
  });

  // Process new tasks
  newTasks.forEach((newTask) => {
    const newTaskKey = getStableTaskIdentifier(newTask);

    // Check for potential previous versions
    let previousKey: string | undefined;

    if (!newTask.taskInstanceId) {
      // For unsaved tasks, check if there's a saved version
      const savedVersion = newTask.taskInstanceId
        ? `instance.${newTask.taskInstanceId}`
        : undefined;
      if (savedVersion && taskMap.has(savedVersion)) {
        previousKey = savedVersion;
      }
    } else {
      // For saved tasks, check if there's an unsaved version
      const derivedKey = getStableTaskIdentifier({
        assignedEntityType: newTask.assignedEntityType,
        assignedEntityId: newTask.assignedEntityId,
        taskKey: newTask.taskKey,
      });
      if (taskMap.has(derivedKey)) {
        previousKey = derivedKey;
      }
    }

    // Remove any previous version if found
    if (previousKey) {
      taskMap.delete(previousKey);
    }

    // Add the new version
    taskMap.set(newTaskKey, newTask);
  });

  return Array.from(taskMap.values());
};

/**
 * Helper function to create a task identifier from individual parts
 */
export const createTaskIdentifier = (
  entityType: string,
  entityId: number,
  taskKey: string
): string => {
  return `derived.${entityType}.${entityId}.${taskKey}`;
};

/**
 * Helper function to check if two tasks represent the same logical task
 */
export const isSameTask = (
  task1: Partial<EntityTaskResponse>,
  task2: Partial<EntityTaskResponse>
): boolean => {
  try {
    return getStableTaskIdentifier(task1) === getStableTaskIdentifier(task2);
  } catch {
    return false;
  }
};
