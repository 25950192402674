import {
  TaskConfig,
  TaskOptionTaskProperties,
  useGetWorkflowDefinitionByKey,
  WorkflowConfig,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useTaskModal } from '@btrway/task-manager';
import { TaskTypeIcon } from '@btrway/workflow-components';
import {
  Box,
  Button,
  Card,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const TaskOptionTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  workgroupId,
  derivedWorkflowKey,
  onClose,
}) => {
  const { completeTask } = useTaskActionContext();
  const { currentOrganization } = useAuthenticatedUser();
  const { openTask } = useTaskModal();
  const [selectedTask, setSelectedTask] = useState<TaskConfig | null>(null);

  const typedTaskProperties = taskProperties as TaskOptionTaskProperties;
  const workflowDefinitionKey =
    typedTaskProperties.workflowSelection.workflowDefinitionKey.value;

  const { data: workflowDefinition, isLoading } = useGetWorkflowDefinitionByKey(
    currentOrganization.id,
    workflowDefinitionKey || ''
  );

  if (isLoading || !workflowDefinition) {
    return <Loader />;
  }

  const handleTaskSelection = (taskConfig: TaskConfig) => {
    setSelectedTask(taskConfig);
  };

  const handleContinue = () => {
    if (!selectedTask || !selectedTask.taskProperties || !selectedTask.taskType)
      return;

    openTask({
      taskProperties: selectedTask.taskProperties,
      taskType: selectedTask.taskType,
      assignedEntityType,
      assignedEntityId,
      workgroupId,
      derivedWorkflowKey,
      taskKey: selectedTask.taskKey,
    });
  };

  const workflowConfig: WorkflowConfig =
    workflowDefinition.workflowConfiguration || {};

  const tasks = workflowConfig.tasks || [];

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <Box
        style={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
          borderBottom: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Text size="xl" fw={700}>
          {typedTaskProperties.title}
        </Text>
      </Box>

      <Box style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
        <Stack gap="xl">
          {typedTaskProperties.shortDescription && (
            <Text size="lg" fw={500} c="dimmed">
              {typedTaskProperties.shortDescription}
            </Text>
          )}

          {typedTaskProperties.longDescription && (
            <Text c="dimmed">{typedTaskProperties.longDescription}</Text>
          )}

          {/* Container Card for Task Options */}
          <Card shadow="sm" radius="md" withBorder bg="gray.2" p="xl">
            {tasks.length > 0 ? (
              <Stack gap="md">
                {tasks.map((task: TaskConfig, index: number) => (
                  <React.Fragment key={task.taskKey}>
                    <Card
                      shadow="sm"
                      p="lg"
                      radius="md"
                      withBorder
                      className="cursor-pointer hover:shadow-lg transition-shadow"
                      onClick={() => handleTaskSelection(task)}
                      style={{
                        cursor: 'pointer',
                        background: 'white',
                        borderColor:
                          selectedTask?.taskKey === task.taskKey
                            ? 'var(--mantine-color-blue-filled)'
                            : undefined,
                        borderWidth:
                          selectedTask?.taskKey === task.taskKey
                            ? '2px'
                            : '1px',
                      }}
                    >
                      <Stack gap="sm">
                        <Group justify="flex-start">
                          <TaskTypeIcon iconName={task.taskType} size={24} />
                          <Stack gap="xs">
                            <Title order={3} size="h4">
                              {task.metadata?.title || task.taskKey}
                            </Title>
                            {task.metadata?.subtitle && (
                              <Text fw={500}>{task.metadata.subtitle}</Text>
                            )}
                          </Stack>
                        </Group>
                      </Stack>
                    </Card>
                    {index < tasks.length - 1 && (
                      <Center>
                        <Text fz="xl" fw={700} c="dimmed">
                          OR
                        </Text>
                      </Center>
                    )}
                  </React.Fragment>
                ))}
              </Stack>
            ) : (
              <Text ta="center" fw={500} c="dimmed">
                No task options available
              </Text>
            )}
          </Card>
        </Stack>
      </Box>

      <Group
        justify="center"
        gap="md"
        style={{
          padding: '20px',
          borderTop: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Button onClick={onClose} variant="outline" size="md">
          Cancel
        </Button>
        <Button onClick={handleContinue} size="md" disabled={!selectedTask}>
          Continue
        </Button>
      </Group>
    </Stack>
  );
};

export default TaskOptionTask;
