import {
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { Button, Modal, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';

interface AddWorkflowTemplateModalProps {
  workflowType: WorkflowTypeEnum;
  workflowTypeDescription: string;
  opened: boolean;
  onClose: () => void;
  onSave: (partialWorkflowTemplate: Partial<WorkflowTemplateRequest>) => void;
}

const AddWorkflowTemplateModal = ({
  workflowType,
  workflowTypeDescription,
  opened,
  onClose,
  onSave,
}: AddWorkflowTemplateModalProps) => {
  const [workflowName, setWorkflowName] = useState('');

  const handleCreateWorkflow = () => {
    if (workflowName) {
      onSave({
        name: workflowName,
        workflowType: workflowType,
      });
      resetState();
      onClose();
    }
  };

  const resetState = () => {
    setWorkflowName('');
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetState();
        onClose();
      }}
      title={
        <Text fz="xl" fw={600}>
          Add {workflowTypeDescription}
        </Text>
      }
      size="80%"
      h={600}
    >
      <Modal.Body mih="calc(100vh *.6)">
        <TextInput
          label={`${workflowTypeDescription} Name`}
          placeholder={`Enter ${workflowTypeDescription} Name`}
          value={workflowName}
          onChange={(event) => setWorkflowName(event.currentTarget.value)}
          data-autofocus
          withAsterisk
        />
      </Modal.Body>

      <Button
        onClick={handleCreateWorkflow}
        leftSection={<IconPlus size={16} />}
      >
        Save
      </Button>
    </Modal>
  );
};

export default AddWorkflowTemplateModal;
