// import { WorkflowBuilderPage } from '@btrway/workflow-builder';
// import React from 'react';
// import { useParams } from 'react-router-dom';

// const WorkflowTemplatePage: React.FC = () => {
//   const { workflowTemplateId } = useParams<{
//     workflowTemplateId: string;
//   }>();
//   const workflowId = parseInt(workflowTemplateId ?? '', 10);

//   return <WorkflowBuilderPage sourceType="template" workflowId={workflowId} />;
// };

// export default WorkflowTemplatePage;

import { WorkflowBuilderPage } from '@btrway/workflow-builder';
import React from 'react';
import { useParams } from 'react-router-dom';

const WorkflowDefinitionPage: React.FC = () => {
  const { workflowKey } = useParams<{
    workflowKey: string;
  }>();

  if (!workflowKey) {
    return null;
  }

  return (
    <WorkflowBuilderPage sourceType="template" workflowKey={workflowKey} />
  );
};

export default WorkflowDefinitionPage;
