import { Card, Group, Loader, Stack, Text } from '@mantine/core';
import { IconWand } from '@tabler/icons-react';
import {
  ChatCompletionContentPartImage,
  ChatCompletionContentPartText,
  ChatCompletionMessageParam,
} from 'openai/resources';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './AssistantMessage.module.css';

interface AssistantMessageProps {
  message: ChatCompletionMessageParam;
  backgroundColor?: string;
  loading: boolean;
  functionLoading: boolean;
}

const AssistantMessage: React.FC<AssistantMessageProps> = ({
  message,
  backgroundColor,
  loading,
  functionLoading,
}) => {
  const isUserMessage = message.role === 'user';
  const messageContent =
    typeof message.content === 'string'
      ? message.content
      : message.content
          ?.map((part) => {
            if (part.type === 'text') {
              return (part as ChatCompletionContentPartText).text;
            } else if (part.type === 'image_url') {
              const imagePart = part as ChatCompletionContentPartImage;
              return `![Image](${imagePart.image_url.url})`;
            }
            return '';
          })
          .join('') || '';

  return (
    <Card
      padding="xs"
      bg={isUserMessage ? 'transparent' : backgroundColor}
      radius="md"
    >
      <Stack gap={0}>
        <Group justify="space-between" align="center" gap={0}>
          <Text fz={12} fw={500} c={isUserMessage ? 'dark' : 'purple'}>
            {isUserMessage ? 'You' : 'Heath'}
          </Text>
          {isUserMessage ? null : <IconWand size={16} color="purple" />}
        </Group>
        {loading && messageContent.length === 0 ? (
          <Loader variant="dots" size="sm" type="dots" color="purple" />
        ) : (
          <>
            <ReactMarkdown
              className={`${styles.markdownContainer} ${
                isUserMessage ? styles.userMessage : styles.assistantMessage
              }`}
            >
              {messageContent || (loading ? 'Thinking...' : '')}
            </ReactMarkdown>
            {functionLoading && (
              <Group mt="xs">
                <Loader variant="dots" size="sm" type="dots" color="purple" />
              </Group>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
};

export default AssistantMessage;
