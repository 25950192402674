import {
  FileTypeGroupingEnum,
  FileUploadInputProperties,
  StepConfig,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { TaskTypeIcon } from '@btrway/workflow-components';
import {
  CommonWorkflow,
  useSelectedItem,
} from '@btrway/workflow-configuration-manager';
import { Card, Chip, Group, List, Stack, Text, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface FileUploadCardProps {
  fileUploadStep: StepConfig;
  onClick: (stepKey: string) => void;
  // workflowDefinition: WorkflowDefinitionRequest;
  workflow: CommonWorkflow;
}

const fileTypeDetails = {
  [FileTypeGroupingEnum.images]: ['JPG', 'PNG', 'GIF', 'SVG'],
  [FileTypeGroupingEnum.pdf]: ['PDF'],
  [FileTypeGroupingEnum.documents]: ['DOC', 'DOCX', 'TXT', 'RTF'],
  [FileTypeGroupingEnum.audioVideo]: ['MP3', 'WAV', 'MP4', 'AVI'],
  [FileTypeGroupingEnum.videos]: ['MP4', 'AVI', 'MOV'],
  [FileTypeGroupingEnum.spreadsheets]: ['XLS', 'XLSX', 'CSV'],
};

const FileUploadCard: React.FC<FileUploadCardProps> = ({
  fileUploadStep,
  onClick,
  workflow,
}) => {
  const { selectedItem, selectedConfig, updateSelectedConfig } =
    useSelectedItem();

  const [title, setTitle] = useState('');
  const [allowedTypes, setAllowedTypes] = useState<FileTypeGroupingEnum[]>([]);

  useEffect(() => {
    if (
      selectedConfig &&
      'stepTypeCode' in selectedConfig &&
      selectedConfig.inputProperties
    ) {
      const config =
        selectedConfig.inputProperties as FileUploadInputProperties;
      setTitle(config.title || '');
      setAllowedTypes(
        config.allowedFileTypes || [
          FileTypeGroupingEnum.images,
          FileTypeGroupingEnum.pdf,
        ]
      );
    }
  }, [selectedConfig]);

  const handleClick = () => {
    onClick(fileUploadStep.stepKey);
  };

  const isSelected =
    selectedItem?.type === 'step' &&
    selectedItem.key === fileUploadStep.stepKey;

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.currentTarget.value;
    setTitle(newTitle);
    if (selectedConfig && 'stepTypeCode' in selectedConfig) {
      updateSelectedConfig({
        inputProperties: {
          ...(selectedConfig.inputProperties as FileUploadInputProperties),
          title: newTitle,
        },
      });
    }
  };

  const handleChipToggle = (value: FileTypeGroupingEnum) => {
    const newAllowedTypes = allowedTypes.includes(value)
      ? allowedTypes.filter((type) => type !== value)
      : [...allowedTypes, value];

    setAllowedTypes(newAllowedTypes);
    if (selectedConfig && 'stepTypeCode' in selectedConfig) {
      updateSelectedConfig({
        inputProperties: {
          ...(selectedConfig.inputProperties as FileUploadInputProperties),
          allowedFileTypes: newAllowedTypes,
        },
      });
    }
  };

  return (
    <Card
      p="md"
      w={600}
      mih={240}
      radius="md"
      withBorder
      shadow="xs"
      onClick={handleClick}
      style={{
        marginBottom: 0,
        cursor: 'pointer',
        borderColor: isSelected ? 'blue' : undefined,
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      <Stack gap="sm">
        <Group justify="space-between" gap="xs" wrap="nowrap">
          <Group gap="xs" wrap="nowrap" justify="flex-start" align="flex-start">
            <TaskTypeIcon iconName={TaskTypeEnum.uploadFile} size={24} />
            <Text fz="md" fw={600}>
              {workflow.name}
            </Text>
          </Group>
        </Group>
        <TextInput
          label="Title"
          value={title}
          onChange={handleTitleChange}
          placeholder="Enter title for file upload"
        />
        <Text size="sm" fw={500}>
          Allowed File Types:
        </Text>
        <Group>
          {Object.values(FileTypeGroupingEnum).map((type) => (
            <Chip
              key={type}
              checked={allowedTypes.includes(type)}
              onChange={() => handleChipToggle(type)}
            >
              {type}
            </Chip>
          ))}
        </Group>
        <Text size="sm" fw={500}>
          Summary of Allowed File Types:
        </Text>
        <List size="sm">
          {allowedTypes.map((type) => (
            <List.Item key={type}>
              {type}: {fileTypeDetails[type].join(', ')}
            </List.Item>
          ))}
        </List>
      </Stack>
    </Card>
  );
};

export default FileUploadCard;
