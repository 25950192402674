import { PersonResponse } from '@btrway/api-core';
import { StorageUsageEnum } from '@btrway/api-storage';
import { DataTypeEnum } from '@btrway/api-workflow';
import { useImageUpload } from '@btrway/file-storage-components';
import { Avatar, Tooltip } from '@mantine/core';
import React from 'react';
import { useEntityData } from '../../hooks/useEntityData';

type SupportedEntityType = 'person' | 'userRole';

interface EntityAvatarProps {
  id: number;
  entityType: SupportedEntityType;
  size?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  radius?: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  withTooltip?: boolean;
}

export const EntityAvatar: React.FC<EntityAvatarProps> = ({
  id,
  entityType,
  size = 'md',
  radius = 'xl',
  withTooltip = false,
}) => {
  const {
    data: entity,
    isLoading,
    error,
  } = useEntityData(entityType as keyof typeof DataTypeEnum, id, true);

  const { imageUrl: avatarUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.headshot,
    initialImageKey:
      entityType === 'person'
        ? (entity as PersonResponse)?.headshot
        : undefined,
  });

  if (isLoading) {
    return <Avatar size={size} radius={radius} />;
  }

  if (error || !entity) {
    return (
      <Avatar size={size} radius={radius} color="red">
        !
      </Avatar>
    );
  }

  let displayName = '';
  if (entityType === 'person') {
    const person = entity as PersonResponse;
    displayName = `${person.firstName || ''} ${person.lastName || ''}`.trim();
  } else if (entityType === 'userRole') {
    displayName = entity.name || '';
  }

  const avatarComponent = (
    <Avatar
      src={entityType === 'person' ? avatarUrl : undefined}
      alt={displayName}
      size={size}
      radius={radius}
      color={entityType === 'person' && avatarUrl ? undefined : 'blue'}
    >
      {!avatarUrl && displayName ? getInitials(displayName) : null}
    </Avatar>
  );

  if (withTooltip) {
    return (
      <Tooltip label={displayName} withArrow openDelay={300} closeDelay={100}>
        {avatarComponent}
      </Tooltip>
    );
  }

  return avatarComponent;
};

const getInitials = (name: string): string => {
  return name
    .split(' ')
    .map((part) => part.charAt(0))
    .join('')
    .toUpperCase()
    .slice(0, 2);
};
