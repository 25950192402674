import {
  EventTypeEnum,
  TriggerConfig,
  TriggerSetConfig,
} from '@btrway/api-workflow';
import { useUserRoles } from '@btrway/security-manager';
import { TriggerDetail, TriggerSetsView } from '@btrway/trigger-builder';
import { uuid } from '@btrway/utils';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import { useEventTypes } from '@btrway/workflow-manager';
import { useEffect, useState } from 'react';

interface TriggerConfigurationProps {
  initialTriggerSets: TriggerSetConfig[];
  onChangesMade: () => void;
  onTriggerSetsChange: (newTriggerSets: TriggerSetConfig[]) => void;
}

export const TriggerConfiguration: React.FC<TriggerConfigurationProps> = ({
  initialTriggerSets,
  onChangesMade,
  onTriggerSetsChange,
}) => {
  const { eventTypeProperties } = useEventTypes();
  const { getUserRoleById } = useUserRoles();
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();
  const [triggerSets, setTriggerSets] = useState<TriggerSetConfig[]>([]);
  const [selectedTriggerSetKey, setSelectedTriggerSetKey] = useState<
    string | null
  >(null);
  const [selectedTrigger, setSelectedTrigger] = useState<TriggerConfig | null>(
    null
  );

  useEffect(() => {
    setTriggerSets(initialTriggerSets || []);
  }, [initialTriggerSets]);

  const properties = eventTypeProperties[EventTypeEnum.personCreated] || [];

  const handleTriggerSetsChange = (updatedTriggerSets: TriggerSetConfig[]) => {
    setTriggerSets(updatedTriggerSets);
    onTriggerSetsChange(updatedTriggerSets);
    onChangesMade();
  };

  const handleTriggerSelect = (
    triggerSetKey: string | null,
    trigger: TriggerConfig | null
  ) => {
    setSelectedTriggerSetKey(triggerSetKey);
    setSelectedTrigger(trigger);
    setRightSectionContent(
      <TriggerDetail
        properties={properties}
        initialConfig={trigger || undefined}
        triggerSetKey={triggerSetKey}
        onSave={handleSaveTrigger}
        onCancel={handleCancelTriggerDetail}
      />
    );
  };

  const handleSaveTrigger = (
    triggerSetKey: string | null,
    newTrigger: TriggerConfig
  ) => {
    const updatedTriggerSets = triggerSets.map((ts) => {
      if (ts.triggerSetKey === triggerSetKey) {
        const updatedTriggers = ts.triggers ? [...ts.triggers] : [];
        const existingTriggerIndex = updatedTriggers.findIndex(
          (t) => t.triggerKey === newTrigger.triggerKey
        );
        if (existingTriggerIndex !== -1) {
          // Update existing trigger
          updatedTriggers[existingTriggerIndex] = newTrigger;
        } else {
          // Add new trigger
          updatedTriggers.push(newTrigger);
        }

        return {
          ...ts,
          triggers: updatedTriggers,
        };
      }
      return ts;
    });

    if (!triggerSetKey) {
      // Adding a new trigger set
      const newTriggerSet: TriggerSetConfig = {
        triggerSetKey: uuid(),
        triggers: [newTrigger],
      };
      updatedTriggerSets.push(newTriggerSet);
    }

    setTriggerSets(updatedTriggerSets);
    onTriggerSetsChange(updatedTriggerSets);
    onChangesMade();
    clearRightSectionContent();
    setSelectedTriggerSetKey(null);
    setSelectedTrigger(null);
  };

  const handleCancelTriggerDetail = () => {
    clearRightSectionContent();
    setSelectedTriggerSetKey(null);
    setSelectedTrigger(null);
  };

  return (
    <TriggerSetsView
      triggerSets={triggerSets}
      properties={properties}
      onTriggerSetsChange={handleTriggerSetsChange}
      onTriggerSelect={handleTriggerSelect}
    />
  );
};

export default TriggerConfiguration;

//   useEffect(() => {
//     setTriggerSets(initialTriggerSets || []);
//   }, [initialTriggerSets]);

// const handleSave = () => {
//     // const updatedConfig: EventConfig = {
//     //   ...selectedConfig,
//     //   triggerSets: triggerSets,
//     // };
//     // updateSelectedConfig(updatedConfig);

//     onChangesMade();
//   };
