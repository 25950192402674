import { useGetDistinctWorkgroupMemberYears } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/security-manager';
import { Group, MultiSelect, TextInput } from '@mantine/core';
import React from 'react';

interface WorkgroupPeopleFiltersProps {
  selectedRoles: number[];
  onRolesChange: (roles: number[]) => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  selectedYears: number[];
  onYearsChange: (years: number[]) => void;
}

const WorkgroupPeopleFilters: React.FC<WorkgroupPeopleFiltersProps> = ({
  selectedRoles,
  onRolesChange,
  searchValue,
  onSearchChange,
  selectedYears,
  onYearsChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { userRoles } = useUserRoles();

  const { data: years, isLoading: isLoadingYears } =
    useGetDistinctWorkgroupMemberYears();

  const groupedRoleOptions = userRoles
    ? [
        {
          group: 'Groups',
          items: userRoles
            .filter((role) => role.userRoleGroup)
            .map((role) => ({
              value: role.id.toString(),
              label: role.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        },
        {
          group: 'Roles',
          items: userRoles
            .filter((role) => !role.userRoleGroup)
            .map((role) => ({
              value: role.id.toString(),
              label: role.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        },
      ]
    : [];

  const yearOptions = years
    ? years.map((year) => ({ value: year.toString(), label: year.toString() }))
    : [];

  return (
    <Group wrap="nowrap">
      <TextInput
        placeholder="Search by name"
        value={searchValue}
        onChange={(event) => onSearchChange(event.currentTarget.value)}
      />
      <MultiSelect
        data={groupedRoleOptions}
        value={selectedRoles.map(String)}
        onChange={(values) => onRolesChange(values.map(Number))}
        placeholder="Filter by role or group"
        clearable
        searchable
      />
      <MultiSelect
        data={yearOptions}
        value={selectedYears.map(String)}
        onChange={(values) => onYearsChange(values.map(Number))}
        placeholder="Filter by year"
        clearable
        searchable
        disabled={isLoadingYears}
      />
    </Group>
  );
};

export default WorkgroupPeopleFilters;
