import {
  TaskConfig,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowService } from '@btrway/workflow-configuration-manager';
import { Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useMemo, useState } from 'react';
import { getTypedTaskProperties } from '../../config/taskPropertiesMap';
import { EditorProps } from '../../types/editor';
import { EditorSearchInput } from '../EditorSearchInput/EditorSearchInput';
import { EditorSwitchCard } from '../EditorSwitchCard/EditorSwitchCard';
import { EditorWrapper } from '../EditorWrapper/EditorWrapper';

export const FormPacketEditor: React.FC<EditorProps> = ({
  initialSelectedTasks,
  onSave,
  onClose,
  workflowKey,
  workflowName,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const workflowService = useWorkflowService();
  const { workflows, isLoading } = workflowService.getWorkflows();

  const formWorkflows = useMemo(() => {
    return (workflows || [])
      .filter((w) => w.workflowType === WorkflowTypeEnum.form)
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflows]);

  const createTaskConfig = (
    workflow: (typeof formWorkflows)[0]
  ): TaskConfig => ({
    taskKey: uuid(),
    taskType: TaskTypeEnum.submitForm,
    metadata: {
      title: workflow.name || '',
    },
    taskProperties: {
      workflowSelection: {
        workflowDefinitionKey: {
          value:
            workflow.sourceType === 'definition'
              ? workflow.workflowKey!
              : workflow.templateKey!,
        },
      },
    },
  });

  const filteredWorkflows = useMemo(() => {
    const searchTermLower = debouncedSearchTerm.toLowerCase();
    return formWorkflows.filter((workflow) =>
      workflow.name?.toLowerCase().includes(searchTermLower)
    );
  }, [formWorkflows, debouncedSearchTerm]);

  if (isLoading) {
    return <Text>Loading forms...</Text>;
  }

  return (
    <EditorWrapper
      initialSelectedTasks={initialSelectedTasks}
      onSave={onSave}
      onClose={onClose}
    >
      {({ selectedTasks, onToggle }) => (
        <Stack p="md">
          <Text fz="lg" fw={600}>
            Select Forms
          </Text>
          <EditorSearchInput
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Stack gap="xs">
            {filteredWorkflows.map((workflow) => {
              const workflowKey =
                workflow.sourceType === 'definition'
                  ? workflow.workflowKey!
                  : workflow.templateKey!;

              const isSelected = selectedTasks.some((task) => {
                const selectedTaskProperties = getTypedTaskProperties(
                  task,
                  TaskTypeEnum.submitForm
                );
                return (
                  selectedTaskProperties?.workflowSelection
                    .workflowDefinitionKey.value === workflowKey
                );
              });

              // Find existing task config if it exists
              const existingTask = selectedTasks.find((task) => {
                const props = getTypedTaskProperties(
                  task,
                  TaskTypeEnum.submitForm
                );
                return (
                  props?.workflowSelection.workflowDefinitionKey.value ===
                  workflowKey
                );
              });

              return (
                <EditorSwitchCard
                  key={workflowKey}
                  item={{
                    id: workflowKey,
                    name: workflow.name || '',
                  }}
                  isSelected={isSelected}
                  onToggle={() =>
                    onToggle(existingTask || createTaskConfig(workflow))
                  }
                />
              );
            })}
          </Stack>
        </Stack>
      )}
    </EditorWrapper>
  );
};
