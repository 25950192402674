// import { Group, Loader, Text, useMantineTheme } from '@mantine/core';
// import { Dropzone, FileWithPath } from '@mantine/dropzone';
// import { IconFile, IconUpload, IconX } from '@tabler/icons-react';
// import { useAtom } from 'jotai';
// import React, { useCallback, useState } from 'react';
// import { needsSheetSelectionAtom } from '../../atoms/needsSheetSelectionAtom';
// import { MAX_FILE_SIZE, SUPPORTED_FILE_TYPES } from '../../constants';
// import { useFileData } from '../../hooks/useFileData';
// import { useImportSteps } from '../../hooks/useImportSteps';
// import { getSheetNames } from '../../utils';

// export const UploadFileStep: React.FC = () => {
//   const theme = useMantineTheme();
//   const { handleFileUploaded } = useImportSteps();
//   const { file, handleFileUpload, setFileDataFromSheet } = useFileData();
//   const [, setNeedsSheetSelection] = useAtom(needsSheetSelectionAtom);
//   const [loading, setLoading] = useState(false);

//   const processFile = useCallback(
//     async (uploadedFile: File) => {
//       setLoading(true);
//       try {
//         await handleFileUpload(uploadedFile);
//         const sheets = await getSheetNames(uploadedFile);
//         console.log('processFile, sheets', sheets.length);
//         if (sheets.length > 1) {
//           setNeedsSheetSelection(true);
//         } else {
//           await setFileDataFromSheet(uploadedFile);
//         }
//         handleFileUploaded();
//       } catch (error) {
//         console.error('Error processing file:', error);
//       } finally {
//         setLoading(false);
//       }
//     },
//     [
//       handleFileUpload,
//       setNeedsSheetSelection,
//       setFileDataFromSheet,
//       handleFileUploaded,
//     ]
//   );

//   const handleDrop = useCallback(
//     async (files: FileWithPath[]) => {
//       if (files.length > 0) {
//         await processFile(files[0]);
//       }
//     },
//     [processFile]
//   );

//   return (
//     <>
//       <Dropzone
//         onDrop={handleDrop}
//         maxSize={MAX_FILE_SIZE}
//         accept={SUPPORTED_FILE_TYPES}
//         multiple={false}
//         styles={(theme) => ({
//           root: {
//             borderStyle: 'dashed',
//             borderWidth: '1px',
//             backgroundColor: theme.colors.gray[0],
//             cursor: 'pointer',
//             height: '500px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           },
//         })}
//       >
//         <Group
//           justify="center"
//           gap="xl"
//           style={{ pointerEvents: 'none' }}
//           wrap="nowrap"
//         >
//           <Dropzone.Accept>
//             <IconUpload size={50} stroke={1.5} color={theme.colors.blue[6]} />
//           </Dropzone.Accept>
//           <Dropzone.Reject>
//             <IconX size={50} stroke={1.5} color={theme.colors.red[6]} />
//           </Dropzone.Reject>
//           <Dropzone.Idle>
//             <IconFile size={50} stroke={1.5} />
//           </Dropzone.Idle>

//           <div>
//             <Text size="xl" inline>
//               Drag file here or click to choose a file.
//             </Text>
//             <Text size="sm" color="dimmed" inline mt={7}>
//               CSV and Excel Files are supported.
//             </Text>
//           </div>
//         </Group>
//       </Dropzone>

//       {loading && <Loader mt="md" />}

//       {file && (
//         <Text mt="md" color="green">
//           File uploaded successfully: {file.name}
//         </Text>
//       )}
//     </>
//   );
// };

import {
  Chip,
  Group,
  Loader,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, FileWithPath } from '@mantine/dropzone';
import { IconFile, IconUpload, IconX } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import React, { useCallback, useState } from 'react';
import { importConfigurationAtom } from '../../atoms/importConfigurationAtom';
import { MAX_FILE_SIZE, SUPPORTED_FILE_TYPES } from '../../constants';
import { useFileData } from '../../hooks/useFileData';
import { useImportSteps } from '../../hooks/useImportSteps';
import { getSheetNames } from '../../utils';

export const UploadFileStep: React.FC = () => {
  const theme = useMantineTheme();
  const { handleFileUploaded } = useImportSteps();
  const { file, handleFileUpload, setFileDataFromSheet } = useFileData();
  const [loading, setLoading] = useState(false);
  const [sheetOptions, setSheetOptions] = useState<string[]>([]);
  const [importConfiguration, setImportConfiguration] = useAtom(
    importConfigurationAtom
  );

  const processFile = useCallback(
    async (uploadedFile: File) => {
      setLoading(true);
      try {
        await handleFileUpload(uploadedFile);
        const sheets = await getSheetNames(uploadedFile);
        console.log('processFile, sheets', sheets.length);
        if (sheets.length > 1) {
          setSheetOptions(sheets);
        } else {
          await setFileDataFromSheet(uploadedFile);
          handleFileUploaded();
        }
      } catch (error) {
        console.error('Error processing file:', error);
      } finally {
        setLoading(false);
      }
    },
    [handleFileUpload, setFileDataFromSheet, handleFileUploaded]
  );

  const handleDrop = useCallback(
    async (files: FileWithPath[]) => {
      if (files.length > 0) {
        await processFile(files[0]);
      }
    },
    [processFile]
  );

  const handleSheetSelection = async (sheetName: string) => {
    setImportConfiguration((prev) => ({ ...prev, sheet: sheetName }));
    await setFileDataFromSheet(file!, sheetName);
    handleFileUploaded();
  };

  return (
    <>
      {!file && (
        <Dropzone
          onDrop={handleDrop}
          maxSize={MAX_FILE_SIZE}
          accept={SUPPORTED_FILE_TYPES}
          multiple={false}
          styles={(theme) => ({
            root: {
              borderStyle: 'dashed',
              borderWidth: '1px',
              backgroundColor: theme.colors.gray[0],
              cursor: 'pointer',
              height: '500px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          })}
        >
          <Group
            justify="center"
            gap="xl"
            style={{ pointerEvents: 'none' }}
            wrap="nowrap"
          >
            <Dropzone.Accept>
              <IconUpload size={50} stroke={1.5} color={theme.colors.blue[6]} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={50} stroke={1.5} color={theme.colors.red[6]} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconFile size={50} stroke={1.5} />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Drag file here or click to choose a file.
              </Text>
              <Text size="sm" color="dimmed" inline mt={7}>
                CSV and Excel Files are supported.
              </Text>
            </div>
          </Group>
        </Dropzone>
      )}

      {loading && <Loader mt="md" />}

      {file && sheetOptions.length === 0 && (
        <Text mt="md" color="green">
          File uploaded successfully: {file.name}
        </Text>
      )}

      {file && sheetOptions.length > 0 && (
        <Stack align="center" mt="xl">
          <Text size="lg" fw={700}>
            Select a sheet to import:
          </Text>
          <Chip.Group
            value={importConfiguration.sheet}
            onChange={handleSheetSelection}
            multiple={false}
          >
            <Stack gap="md" align="center">
              {sheetOptions.map((sheet) => (
                <Chip key={sheet} value={sheet} size="lg">
                  {sheet}
                </Chip>
              ))}
            </Stack>
          </Chip.Group>
        </Stack>
      )}
    </>
  );
};
