import { FormBuilderModal } from '@btrway/form-builder';
import {
  ProcessPanelDragDrop,
  WorkflowConfigurationView,
} from '@btrway/process-builder';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowBuilderStateProvider } from '@btrway/workflow-builder-state';
import {
  useSelectedItem,
  useWorkflowService,
  workflowConfigurationAtom,
} from '@btrway/workflow-configuration-manager';
import { Box } from '@mantine/core';
import { useSetAtom } from 'jotai';
import React, { useEffect } from 'react';

interface ProcessBuilderProps {
  workflowKey: string;
}

const ProcessBuilder: React.FC<ProcessBuilderProps> = ({ workflowKey }) => {
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();
  const { selectedTitle } = useSelectedItem();
  const setWorkflowConfiguration = useSetAtom(workflowConfigurationAtom);

  useEffect(() => {
    if (!isLoading && workflow && workflow.workflowConfiguration) {
      setWorkflowConfiguration(workflow.workflowConfiguration);
    }
  }, [workflow, isLoading]);

  if (!workflow || !workflow.workflowConfiguration) {
    return null;
  }

  console.log('parentWorkflow of type: ' + workflow.workflowType, workflow);

  return (
    <Box style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <ProcessPanelDragDrop workflowKey={workflowKey}>
        <RightSectionProvider>
          <SettingsDisplayProvider>
            <WorkflowBuilderStateProvider parentWorkflow={workflow}>
              <Box
                style={{
                  flexGrow: 1,
                  height: '100%',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <WorkflowConfigurationView
                  workflow={workflow}
                  updateWorkflow={() => {}}
                  workflowConfiguration={workflow.workflowConfiguration}
                  workflowKey={workflowKey}
                />
              </Box>
            </WorkflowBuilderStateProvider>
          </SettingsDisplayProvider>
        </RightSectionProvider>
      </ProcessPanelDragDrop>

      <FormBuilderModal title={selectedTitle || ''} workflowKey={workflowKey} />
    </Box>
  );
};

export default ProcessBuilder;
