import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ModuleRegistry,
  SelectionChangedEvent,
  SideBarDef,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { Box, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ResponsiveGridRow from '../ResponsiveGridRow/ResponsiveGridRow';
import styles from './ResponsiveInfiniteGrid.module.css';

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  RangeSelectionModule,
]);

interface ResponsiveInfiniteGridProps {
  columnDefs: ColDef[];
  defaultColDef: ColDef;
  onRowClicked?: (event: any) => void;
  getRowId?: (params: any) => string;
  serverSideDatasource?: any;
  cacheBlockSize?: number;
  maxConcurrentDatasourceRequests?: number;
  infiniteInitialRowCount?: number;
  maxBlocksInCache?: number;
  suppressMenuHide?: boolean;
  enableRangeSelection?: boolean;
  sideBar?: SideBarDef | string | string[] | boolean;
  context?: any;
  rowSelection?: 'single' | 'multiple'; // Add this line
  onGridReady?: (event: GridReadyEvent) => void;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
}

const ResponsiveInfiniteGrid = forwardRef<
  AgGridReact,
  ResponsiveInfiniteGridProps
>((props, ref) => {
  const {
    columnDefs,
    defaultColDef,
    onRowClicked,
    getRowId,
    serverSideDatasource,
    cacheBlockSize = 100,
    maxConcurrentDatasourceRequests = 1,
    infiniteInitialRowCount = 1000,
    maxBlocksInCache = 10,
    suppressMenuHide = false,
    enableRangeSelection = false,
    sideBar,
    context,
    rowSelection,
    onGridReady,
    onSelectionChanged,
  } = props;

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [gridApi, setGridApi] = useState<any>(null);

  const responsiveColumnDefs = useMemo(() => {
    if (isMobile) {
      return [
        {
          field: 'mobileView',
          headerName: '',
          cellRenderer: (params: any) => (
            <ResponsiveGridRow
              data={params.data}
              columnDefs={columnDefs}
              isMobile={true}
              api={gridApi}
              context={context}
            />
          ),
          flex: 1,
          sortable: false,
          filter: false,
          autoHeight: true,
        },
      ];
    } else {
      return columnDefs;
    }
  }, [columnDefs, isMobile, gridApi, context]);

  const defaultSideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
        },
      },
    ],
    defaultToolPanel: '',
    position: 'right',
  };

  const gridOptions: GridOptions = {
    defaultColDef: {
      ...defaultColDef,
      sortable: true,
      resizable: true,
      suppressHeaderMenuButton: true,
    },
    onRowClicked,
    getRowId,
    rowModelType: 'serverSide',
    suppressRowTransform: true,
    enableRangeSelection,
    rowSelection,
    context,
    serverSideDatasource,
    cacheBlockSize,
    maxConcurrentDatasourceRequests,
    infiniteInitialRowCount,
    maxBlocksInCache,
    onSelectionChanged,
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOption('serverSideDatasource', serverSideDatasource);
    }
  }, [gridApi]);

  const handleGridReady = useCallback(
    (params: GridReadyEvent) => {
      setGridApi(params.api);
      params.api.sizeColumnsToFit();
      if (onGridReady) {
        onGridReady(params);
      }
    },
    [onGridReady]
  );

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const mobileGridStyle: React.CSSProperties = {
    ...gridStyle,
    border: '1px solid transparent',
  };

  return (
    <Box className={styles.root} style={containerStyle}>
      <Box
        className={`${styles.gridContainer} ${styles.grid} ${
          isMobile ? styles.mobileGrid : styles.desktopGrid
        } ${isMobile ? '' : 'ag-theme-quartz'}`}
        style={isMobile ? mobileGridStyle : gridStyle}
      >
        <AgGridReact
          ref={ref}
          columnDefs={responsiveColumnDefs}
          gridOptions={gridOptions}
          headerHeight={isMobile ? 0 : undefined}
          rowClass={isMobile ? styles.mobileRow : ''}
          onGridReady={handleGridReady}
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
    </Box>
  );
});

export default ResponsiveInfiniteGrid;
