import { WorkgroupResponse } from '@btrway/api-core';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workgroupsAtom } from './workgroupsAtom';

export const workgroupsAtomFamily = atomFamily((id: number) =>
  atom(
    (get) => get(workgroupsAtom).find((workgroup) => workgroup.id === id),
    (get, set, update: Partial<WorkgroupResponse>) => {
      const prev = get(workgroupsAtom);
      const updatedWorkgroups = prev.map((workgroup) => {
        if (workgroup.id === id) {
          return { ...workgroup, ...update };
        }
        return workgroup;
      });
      set(workgroupsAtom, updatedWorkgroups);
    }
  )
);
