import { Card, type CardProps } from '@mantine/core';
import React from 'react';

interface CardWrapperProps extends Omit<CardProps, 'h' | 'w'> {
  children: React.ReactNode;
  onClick?: () => void;
}

export const CardWrapper: React.FC<CardWrapperProps> = ({
  children,
  onClick,
  style,
  p = 'md',
  ...props
}) => {
  return (
    <Card
      radius="lg"
      h={200}
      w={320}
      p={p}
      style={{
        ...style,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Card>
  );
};
