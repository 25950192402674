import {
  IconBallBasketball,
  IconCalendarEvent,
  IconClipboardText,
  IconPlane,
  IconUsers,
  IconVideo,
} from '@tabler/icons-react';
import { DynamicContentOption } from '../types/dynamicContentOption';

export const DYNAMIC_CONTENT_OPTIONS: DynamicContentOption[] = [
  {
    id: 'weekly-schedule',
    type: 'WeeklySchedule',
    name: "This Week's Schedule",
    description: 'Displays a summary of upcoming calendar events for the week',
    placeholder: 'Weekly schedule will be displayed here',
    icon: IconCalendarEvent,
  },
  {
    id: 'game-summary',
    type: 'GameSummary',
    name: 'Game Summary',
    description: 'Shows detailed information about a selected game or event',
    placeholder: 'Game details will appear here',
    icon: IconBallBasketball,
  },
  {
    id: 'important-contacts',
    type: 'ImportantContacts',
    name: 'Important Contacts',
    description: 'Lists key contacts and their information',
    placeholder: 'Contact information will be shown here',
    icon: IconUsers,
  },
  {
    id: 'travel-itinerary',
    type: 'TravelItinerary',
    name: 'Travel Itinerary',
    description: 'Shows travel details for away games',
    placeholder: 'Travel details will be displayed here',
    icon: IconPlane,
  },
  {
    id: 'registration-form',
    type: 'RegistrationForm',
    name: 'Registration Form',
    description: 'Provides a link to the registration form',
    placeholder: 'Registration form link will appear here',
    icon: IconClipboardText,
  },
  {
    id: 'course',
    type: 'Course',
    name: 'Course',
    description: 'Links to video education courses in the system',
    placeholder: 'Course information will be shown here',
    icon: IconVideo,
  },
];
