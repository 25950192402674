import { OrganizationResponse, useGetOrganizationById } from '@btrway/api-core';
import { LoadingSpinner } from '@btrway/shared-components';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router';

interface OrganizationContextValue {
  organization: OrganizationResponse;
  isLoading: boolean;
}

const OrganizationContext = createContext<OrganizationContextValue | undefined>(
  undefined
);

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider'
    );
  }
  return context;
};

interface OrganizationProviderProps {
  children: React.ReactNode;
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const { organizationId } = useParams();

  const {
    data: organization,
    isLoading,
    error,
  } = useGetOrganizationById(organizationId ? Number(organizationId) : 0);

  if (isLoading || !organization) {
    return <LoadingSpinner />;
  }

  //   if (error || !organization) {
  //     console.log('OrganizationProvider -> error', error);
  //     return <div>Error loading organization</div>;
  //   }

  console.log('OrganizationProvider -> organization', organization);

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        isLoading: false,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
