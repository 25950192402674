import { Button, ButtonProps, Loader } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';

interface SaveButtonProps extends Omit<ButtonProps, 'children'> {
  onSave: () => Promise<void>;
  children?: React.ReactNode;
}

const SaveButton: React.FC<SaveButtonProps> = ({
  onSave,
  children,
  ...props
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const saveCompleted = useRef(false);
  const loaderStartTime = useRef<number | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSuccess) {
      timer = setTimeout(() => {
        setIsSuccess(false);
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [isSuccess]);

  const handleClick = async () => {
    setIsSaving(true);
    saveCompleted.current = false;
    loaderStartTime.current = null;

    const loaderTimer = setTimeout(() => {
      if (!saveCompleted.current) {
        setShowLoader(true);
        loaderStartTime.current = Date.now();
      }
    }, 100);

    const startTime = Date.now();
    try {
      await onSave();
      saveCompleted.current = true;
      const elapsedTime = Date.now() - startTime;

      if (showLoader) {
        const loaderDuration =
          Date.now() - (loaderStartTime.current || Date.now());
        if (loaderDuration < 500) {
          await new Promise((resolve) =>
            setTimeout(resolve, 500 - loaderDuration)
          );
        }
      }

      setIsSuccess(true);
    } catch (error) {
      console.error('Save failed:', error);
    } finally {
      clearTimeout(loaderTimer);
      setIsSaving(false);
      setShowLoader(false);
    }
  };

  const buttonProps = {
    ...props,
    ...(isSuccess ? { variant: 'filled' as const, bg: 'green' } : {}),
  };

  const leftSection = showLoader ? (
    <Loader size="xs" color="white" />
  ) : isSuccess ? (
    <IconCircleCheck size={16} />
  ) : null;

  const buttonText = isSuccess ? 'Saved' : children || 'Save';

  return (
    <Button
      onClick={handleClick}
      disabled={isSaving}
      leftSection={leftSection}
      {...buttonProps}
      style={{
        transition: 'background-color 0.3s, color 0.3s',
        ...props.style,
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SaveButton;
