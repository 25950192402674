/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type CertificationValidTypeEnum = typeof CertificationValidTypeEnum[keyof typeof CertificationValidTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CertificationValidTypeEnum = {
  thisYear: 'thisYear',
  ever: 'ever',
  withinDays: 'withinDays',
} as const;
