import { EventTargetRequest } from '@btrway/api-calendar';
import { DataTypeEnum } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { Box, Paper } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React from 'react';

interface EventTargetCardProps {
  target: EventTargetRequest;
  onRemove: (targetId: number) => void;
}

export const EventTargetCard: React.FC<EventTargetCardProps> = ({
  target,
  onRemove,
}) => {
  return (
    <Paper shadow="xs" p="xs" withBorder>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '8px',
          width: '100%',
        }}
      >
        <EntityTag
          dataType={target.entityType as keyof typeof DataTypeEnum}
          id={target.entityId}
        />
        <IconX
          size={16}
          style={{ cursor: 'pointer', opacity: 0.7 }}
          onClick={() => onRemove(target.entityId)}
        />
      </Box>
    </Paper>
  );
};
