import { useGetAssetsByUids } from '@btrway/api-courseware';
import {
  CourseTaskProperties,
  TaskTypeEnum,
  useGetWorkflowDefinitionById,
} from '@btrway/api-workflow';
import { useEffect, useMemo } from 'react';

export const useCurriculum = (workflowId: number) => {
  const {
    data: curriculum,
    isLoading: isCurriculumLoading,
    error: curriculumError,
  } = useGetWorkflowDefinitionById(workflowId);

  const courseIds = useMemo(() => {
    return (
      (curriculum?.workflowConfiguration?.tasks
        ?.filter((task) => task.taskType === TaskTypeEnum.completeCourse)
        .map((task) => {
          const properties =
            task.taskProperties as unknown as CourseTaskProperties;
          return properties.courseSelection.courseUid.value;
        })
        .filter(Boolean) as number[]) || []
    );
  }, [curriculum]);

  const {
    mutate: fetchCourses,
    data: courses,
    isPending: isCoursesLoading,
    error: coursesError,
  } = useGetAssetsByUids();

  useEffect(() => {
    if (courseIds.length > 0) {
      fetchCourses({ data: courseIds });
    }
  }, [courseIds, fetchCourses]);

  const isLoading = isCurriculumLoading || isCoursesLoading;
  const error = curriculumError || coursesError;

  return {
    curriculum,
    courses: courses || [],
    isLoading,
    error,
  };
};
