import {
  MessageRequest,
  MessageRequestMessageContent,
} from '@btrway/api-messaging';
import { TaskTypeEnum } from '@btrway/api-workflow';
import { MessageComposerCore } from '@btrway/message-composer';
import { StyledModal } from '@btrway/styled-modal';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import {
  Box,
  Button,
  Card,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import {
  IconAlertTriangleFilled,
  IconBook,
  IconEdit,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { getTypedTaskProperties } from '../../../config/taskPropertiesMap';
import { TaskConfigProps } from '../../../types/editors';

const taskType = TaskTypeEnum.sendEmail;

export const SendEmailTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  const taskProperties = getTypedTaskProperties(config, taskType);
  const subject = taskProperties?.subject;
  const body = taskProperties?.body;

  const handleMessageChange = (messageData: Partial<MessageRequest>) => {
    onConfigChange({
      ...config,
      taskProperties: {
        ...config.taskProperties,
        subject: messageData.messageSubject,
        body: messageData.messageContent,
      },
    });
  };

  const openMessageComposer = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Convert the body content into a proper MessageRequestMessageContent object
  const getInitialMessageContent = (
    bodyContent: unknown
  ): MessageRequestMessageContent => {
    // If body is already in the correct format, return it
    if (
      typeof bodyContent === 'object' &&
      bodyContent !== null &&
      'type' in bodyContent &&
      bodyContent.type === 'doc'
    ) {
      return bodyContent as MessageRequestMessageContent;
    }

    // If body is a string, try to parse it as JSON
    if (typeof bodyContent === 'string') {
      try {
        const parsed = JSON.parse(bodyContent);
        if (parsed.type === 'doc') {
          return parsed as MessageRequestMessageContent;
        }
      } catch (e) {
        // If parsing fails, create a simple text content
        return {
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: bodyContent,
                },
              ],
            },
          ],
        };
      }
    }

    // Return empty content if no valid content is found
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [],
        },
      ],
    };
  };

  const initialContent = getInitialMessageContent(body);

  return (
    <>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        onClick={openMessageComposer}
        style={{ cursor: 'pointer' }}
      >
        <Group justify="space-between" wrap="nowrap">
          <Group justify="flex-start" gap="xs" wrap="nowrap" align="flex-start">
            <IconBook style={{ width: '20px', height: '20px' }} />
            <Text fz="md" fw={600}>
              Configure Email Message
            </Text>
          </Group>
          <UnstyledButton>
            {subject && body ? (
              <IconEdit size={20} color="blue" />
            ) : (
              <IconAlertTriangleFilled size={20} color="orange" />
            )}
          </UnstyledButton>
        </Group>
      </Card>

      <StyledModal
        opened={isModalOpen}
        onClose={handleClose}
        title="Configure Email Message"
        variant="xl"
      >
        <Stack h="100%" p="md">
          <Box style={{ flex: 1, minHeight: 0 }}>
            <MessageComposerCore
              initialMessage={{
                messageSubject: subject,
                messageContent: initialContent,
              }}
              onChange={handleMessageChange}
              showTargetSelector={false}
            />
          </Box>
          <Group justify="flex-end">
            <Button variant="subtle" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleClose}>Save</Button>
          </Group>
        </Stack>
      </StyledModal>
    </>
  );
};
