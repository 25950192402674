import { Group, Stack } from '@mantine/core';
import { ReactNode } from 'react';
import { RegistrationFormContentCard } from '../../components/RegistrationFormContentCard/RegistrationFormContentCard';
import { RegistrationFormShell } from '../../components/RegistrationFormShell/RegistrationFormShell';

interface RegistrationFormLayoutProps {
  sidebar: ReactNode;
  content: ReactNode;
}

export const RegistrationFormLayout: React.FC<RegistrationFormLayoutProps> = ({
  sidebar,
  content,
}) => (
  <RegistrationFormShell>
    <Group align="stretch" gap="xl" style={{ flex: 1 }}>
      <Stack gap="lg" style={{ flex: 1 }}>
        {sidebar}
      </Stack>
      <RegistrationFormContentCard>{content}</RegistrationFormContentCard>
    </Group>
  </RegistrationFormShell>
);
