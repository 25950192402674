import {
  WorkflowTypeEnum,
  useGetWorkflowDefinitionsByOrganizationAndType,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';

export const useCurriculums = () => {
  const { currentOrganization } = useAuthenticatedUser();

  const {
    data: curriculums,
    isLoading,
    error,
  } = useGetWorkflowDefinitionsByOrganizationAndType(
    currentOrganization.id,
    WorkflowTypeEnum.curriculum
  );

  return { curriculums, isLoading, error };
};
