import { SETTINGS_PANEL_LAYOUT } from '@btrway/workflow-builder-settings-panel';
import { Box, Button, Group, ScrollArea } from '@mantine/core';
import React from 'react';

interface ConfigEditorWrapperProps {
  children: React.ReactNode;
  onDelete?: () => void;
  onClose: () => void;
  hasUnsavedChanges: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export const ConfigEditorWrapper: React.FC<ConfigEditorWrapperProps> = ({
  children,
  onDelete,
  onClose,
  hasUnsavedChanges,
  onSave,
  onCancel,
}) => {
  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <ScrollArea
        style={{
          flex: 1,
          marginBottom: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT}px`,
        }}
      >
        <Box p="md">{children}</Box>
      </ScrollArea>
      <Group
        justify="space-between"
        p="md"
        bg="gray.1"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'var(--mantine-color-gray-1)',
          height: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT}px`,
        }}
      >
        {onDelete && (
          <Button variant="outline" color="red" onClick={onDelete}>
            Delete
          </Button>
        )}
        <Group gap="xs" justify="flex-end">
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </Group>
      </Group>
    </Box>
  );
};
