import type { MantineTransition } from '@mantine/core';
import { Box, Modal, ModalProps, Text } from '@mantine/core';
import React from 'react';
import { useStyledModal } from '../../hooks/useStyledModal';

export type ModalVariant = 'full' | 'xl' | 'xlwide';

interface StyledModalProps extends Omit<ModalProps, 'children'> {
  children: React.ReactNode;
  variant?: ModalVariant;
  title?: React.ReactNode;
}

const renderTitle = (title: React.ReactNode) => {
  if (typeof title === 'string') {
    return (
      <Text fz="xl" fw={700}>
        {title}
      </Text>
    );
  }

  return title;
};

export const StyledModal: React.FC<StyledModalProps> = ({
  children,
  opened,
  transitionProps,
  variant = 'xl',
  title,
  ...modalProps
}) => {
  const { modalState } = useStyledModal();

  // Determine the effective title - prop takes precedence over state
  const effectiveTitle = title ?? modalState.modalTitle;

  // Default transition props
  const defaultTransitionProps = {
    transition: 'fade-up' as MantineTransition,
    duration: 500,
    timingFunction: 'ease' as const,
  };

  // Base styles that are common between variants
  const baseStyles = {
    body: {
      display: 'flex',
      flexDirection: 'column' as const,
      flex: 1,
      padding: 0,
      overflow: 'hidden',
    },
    content: {
      display: 'flex',
      flexDirection: 'column' as const,
      overflow: 'hidden',
    },
  };

  // Variant-specific props and styles
  const variantProps: Record<ModalVariant, Partial<ModalProps>> = {
    full: {
      fullScreen: true,
      radius: 0,
      styles: {
        ...baseStyles,
        inner: {
          padding: 0,
        },
        content: {
          ...baseStyles.content,
          height: '100vh',
          width: '100vw',
          maxWidth: '100vw',
          borderRadius: 0,
          padding: 0,
        } as React.CSSProperties,
        body: baseStyles.body as React.CSSProperties,
      },
    },
    xl: {
      size: '95vw',
      radius: 'lg',
      centered: true,
      styles: {
        ...baseStyles,
        inner: {
          padding: '2.5vh 2.5vw',
        },
        content: {
          ...baseStyles.content,
          height: '95vh',
          borderRadius: 'var(--modal-radius)',
          overflow: 'hidden',
        } as React.CSSProperties,
        header: {
          marginBottom: 0,
        },
        body: baseStyles.body as React.CSSProperties,
      },
    },
    xlwide: {
      size: '100vw',
      radius: 'lg',
      centered: true,
      styles: {
        ...baseStyles,
        inner: {
          paddingTop: '3vh',
        },
        content: {
          ...baseStyles.content,
          height: '97vh',
          borderTopLeftRadius: 'var(--modal-radius)',
          borderTopRightRadius: 'var(--modal-radius)',
          overflow: 'hidden',
        } as React.CSSProperties,
        header: {
          marginBottom: 0,
        },
        body: baseStyles.body as React.CSSProperties,
      },
    },
  };

  const selectedVariant = variantProps[variant];

  return (
    <Modal
      opened={opened}
      transitionProps={{
        ...defaultTransitionProps,
        ...(transitionProps || {}),
      }}
      {...selectedVariant}
      {...modalProps}
      title={effectiveTitle ? renderTitle(effectiveTitle) : undefined}
    >
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
