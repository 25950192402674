import { TipTapPocPage } from '@btrway/email-poc';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowTemplatePage } from '@btrway/workflow-template-components';

export const fullScreenRoutes: RouteConfig[] = [
  {
    path: 'template-admin/automations/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/forms/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/form-packets/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/files/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/certifications/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/todo-lists/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'template-admin/curriculums/:workflowKey',
    element: <WorkflowTemplatePage />,
  },
  {
    path: 'tiptap',
    element: <TipTapPocPage />,
    label: 'TipTap POC',
  },
];
