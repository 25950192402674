import { StepTypeEnum } from '@btrway/api-workflow';
import { SlidingActionPanel } from '@btrway/shared-components';
import { TasksInfiniteGrid } from '@btrway/task-grids';
import { Box, Flex, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useCallback, useRef, useState } from 'react';
import { useAuthenticatedUser } from '@btrway/current-user';

interface SelectionState {
  selectedCount: number;
  isAllSelected: boolean;
  totalRows: number;
}

const SubmittedFormsView: React.FC = () => {
  const { currentPerson } = useAuthenticatedUser();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 300);
  const [selectionState, setSelectionState] = useState<SelectionState>({
    selectedCount: 0,
    isAllSelected: false,
    totalRows: 0,
  });
  const gridRef = useRef<any>(null);

  const handleCloseActionPanel = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.clearSelection();
    }
    setSelectionState({
      selectedCount: 0,
      isAllSelected: false,
      totalRows: selectionState.totalRows,
    });
  }, [selectionState.totalRows]);

  const handleSelectionChanged = useCallback(
    (selectedCount: number, isAllSelected: boolean, totalRows: number) => {
      setSelectionState({
        selectedCount,
        isAllSelected,
        totalRows,
      });
    },
    []
  );

  const selectionText = selectionState.isAllSelected
    ? `All ${selectionState.totalRows} rows selected`
    : selectionState.selectedCount > 0
    ? `${selectionState.selectedCount} selected`
    : '';

  return (
    <Flex direction="column" style={{ height: '100%', position: 'relative' }}>
      <Box style={{ height: '100%' }} m="lg">
        <TasksInfiniteGrid
          ref={gridRef}
          filterByPersonIds={currentPerson ? [currentPerson.id] : []}
          filterByStepTypes={[StepTypeEnum.formSubmission]}
          statusOption="completed"
          taskListOption="all"
          directOption="include"
          onSelectionChanged={handleSelectionChanged}
        />
      </Box>
      <SlidingActionPanel
        isOpen={
          selectionState.selectedCount > 0 || selectionState.isAllSelected
        }
        onClose={handleCloseActionPanel}
      >
        <Stack>
          <Text fz="lg" fw={700}>
            {selectionText}
          </Text>
          <Text>Actions TBD</Text>
        </Stack>
      </SlidingActionPanel>
    </Flex>
  );
};

export default SubmittedFormsView;
