import { CalendarAgenda, MetricCard } from '@btrway/mockup-components';
import { Box, Grid, Stack } from '@mantine/core';
import React from 'react';
import WorkgroupSubgroupsGrid from '../../components/WorkgroupSubgroupsGrid/WorkgroupSubgroupsGrid';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupDashboardView: React.FC = () => {
  // const { workgroup } = useWorkgroupContext();
  const { workgroup } = useWorkgroup();

  return (
    <Box m="lg">
      <Grid>
        <Grid.Col span={9}>
          <Stack gap="lg">
            <Grid>
              <Grid.Col span={4}>
                <MetricCard title="Student-Athletes" value={1054} />
              </Grid.Col>
              <Grid.Col span={4}>
                <MetricCard title="Hours of Curriculum Watched" value={312} />
              </Grid.Col>
              <Grid.Col span={4}>
                <MetricCard title="Tasks Overdue" value={49} />
              </Grid.Col>
            </Grid>
            <Box h={300}>
              <WorkgroupSubgroupsGrid />
            </Box>
          </Stack>
        </Grid.Col>
        <Grid.Col span={3} style={{ height: '100%' }}>
          <CalendarAgenda />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default WorkgroupDashboardView;
