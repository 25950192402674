import { EventTargetRequest } from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ScrollArea, Stack } from '@mantine/core';
import React from 'react';
import EventAttendeeSelector from '../EventAttendeeSelector/EventAttendeeSelector';
import EventFacilitySelector from '../EventFacilitySelector/EventFacilitySelector';

interface EventTargetListProps {
  targets: EventTargetRequest[];
  onAddTarget: (target: EventTargetRequest) => void;
  onRemoveTarget: (targetId: number) => void;
  workgroupId: number;
}

export const EventTargetList: React.FC<EventTargetListProps> = ({
  targets,
  onAddTarget,
  onRemoveTarget,
  workgroupId,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const organizationId = currentOrganization.id;

  return (
    <ScrollArea h="calc(100vh - 200px)">
      <Stack gap="xl">
        <EventFacilitySelector
          workgroupId={workgroupId}
          targets={targets}
          onAddTarget={onAddTarget}
          onRemoveTarget={onRemoveTarget}
          organizationId={organizationId}
        />
        <EventAttendeeSelector
          targets={targets}
          onAddTarget={onAddTarget}
          onRemoveTarget={onRemoveTarget}
          organizationId={organizationId}
        />
      </Stack>
    </ScrollArea>
  );
};
