import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface RecaptchaProviderProps {
  children: ReactNode;
  siteKey: string;
}

export const RecaptchaProvider: React.FC<RecaptchaProviderProps> = ({
  children,
  siteKey,
}) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={siteKey}
    scriptProps={{
      async: true,
      defer: true,
      appendTo: 'head',
    }}
  >
    {children}
  </GoogleReCaptchaProvider>
);
