/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskRetrievalRequestCompletionStatus = typeof TaskRetrievalRequestCompletionStatus[keyof typeof TaskRetrievalRequestCompletionStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskRetrievalRequestCompletionStatus = {
  ALL: 'ALL',
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
} as const;
