import {
  CertificationConfig,
  EventConfig,
  StepConfig,
  TaskConfig,
  TransitionConfig,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useAtom } from 'jotai';
import { workflowConfigurationAtom } from '../atoms/workflowConfigurationAtom';
import { useWorkflowService } from '../providers/WorkflowServiceProvider';
import { WorkflowService, WorkflowServiceConfig } from '../types/service';

export function useWorkflowUpdater(providedService?: WorkflowService) {
  const contextService = useWorkflowService();
  const workflowService = providedService || contextService;
  const { refetch } = workflowService.getByKey();
  const [, setWorkflowConfiguration] = useAtom(workflowConfigurationAtom);

  // Get the effective workflowKey, preferring the provided one
  const getEffectiveWorkflowKey = (overrideKey?: string) => {
    if (overrideKey) return overrideKey;
    if (!workflowService.workflowKey) {
      throw new Error('No workflowKey available');
    }
    return workflowService.workflowKey;
  };

  // Base update method
  const updateConfig = async (
    config: WorkflowServiceConfig,
    workflowKey?: string
  ) => {
    const effectiveKey = getEffectiveWorkflowKey(workflowKey);
    await workflowService.updateConfig(effectiveKey, config);
    // Get fresh result for the specific key and refetch it
    const result = workflowService.getByKey(effectiveKey);
    await result.refetch();
  };

  // Direct workflow update methods (no atom updates)
  const addTask = async (newTask: TaskConfig, workflowKey?: string) => {
    const taskWithKey = {
      ...newTask,
      taskKey: newTask.taskKey || uuid(),
    };

    await updateConfig(
      {
        workflowConfig: { tasks: [taskWithKey] },
      },
      workflowKey
    );
    return taskWithKey;
  };

  const removeTask = async (taskKey: string, workflowKey?: string) => {
    await updateConfig(
      {
        deleteConfigs: [taskKey],
      },
      workflowKey
    );
  };

  const addCertification = async (
    newCertification: CertificationConfig,
    workflowKey?: string
  ) => {
    const certificationWithKey = {
      ...newCertification,
      certificationKey: newCertification.certificationKey || uuid(),
    };

    await updateConfig(
      {
        workflowConfig: { certifications: [certificationWithKey] },
      },
      workflowKey
    );
    return certificationWithKey;
  };

  const removeCertification = async (
    certificationKey: string,
    workflowKey?: string
  ) => {
    await updateConfig(
      {
        deleteConfigs: [certificationKey],
      },
      workflowKey
    );
  };

  // Step methods with atom updates
  const addStep = async (newStep: StepConfig, workflowKey?: string) => {
    const stepWithKey = { ...newStep, stepKey: newStep.stepKey || uuid() };
    setWorkflowConfiguration((prev) => ({
      ...prev,
      steps: [...(prev?.steps || []), stepWithKey],
    }));

    await updateConfig(
      {
        workflowConfig: { steps: [stepWithKey] },
      },
      workflowKey
    );
  };

  const removeStep = async (stepKeyToRemove: string, workflowKey?: string) => {
    setWorkflowConfiguration((prev) => {
      if (!prev?.steps) return prev;
      const updatedSteps = prev.steps.filter(
        (step) => step.stepKey !== stepKeyToRemove
      );
      const updatedTransitions = updatedSteps
        .slice(0, -1)
        .map((step, index) => ({
          transitionKey: uuid(),
          sourceStepKey: step.stepKey,
          targetStepKey: updatedSteps[index + 1].stepKey,
          index,
        }));

      return { ...prev, steps: updatedSteps, transitions: updatedTransitions };
    });

    await updateConfig(
      {
        deleteConfigs: [stepKeyToRemove],
      },
      workflowKey
    );
  };

  const updateSteps = async (
    updatedSteps: StepConfig[],
    workflowKey?: string
  ) => {
    setWorkflowConfiguration((prev) => ({
      ...prev,
      steps: updatedSteps,
    }));

    await updateConfig(
      {
        workflowConfig: { steps: updatedSteps },
      },
      workflowKey
    );
  };

  const appendStep = async (newStep: StepConfig, workflowKey?: string) => {
    const updatedStep = { ...newStep, stepKey: newStep.stepKey || uuid() };
    let currentTransitions: TransitionConfig[] = [];

    setWorkflowConfiguration((prev) => {
      const lastStep = prev?.steps?.[prev.steps?.length - 1];
      const updatedTransition = {
        transitionKey: uuid(),
        sourceStepKey: lastStep?.stepKey || '',
        targetStepKey: updatedStep.stepKey,
        index: 0,
      };

      currentTransitions = [updatedTransition];
      return {
        ...prev,
        steps: [...(prev?.steps || []), updatedStep],
        transitions: [...(prev?.transitions || []), updatedTransition],
      };
    });

    await updateConfig(
      {
        workflowConfig: {
          steps: [updatedStep],
          transitions: currentTransitions,
        },
      },
      workflowKey
    );
  };

  const insertStep = async (
    newStep: StepConfig,
    newIndex: number,
    workflowKey?: string
  ) => {
    const updatedStep = { ...newStep, stepKey: newStep.stepKey || uuid() };
    let currentTransitions: TransitionConfig[] = [];

    setWorkflowConfiguration((prev) => {
      if (!prev) return { steps: [updatedStep], transitions: [] };

      let updatedSteps = [...(prev.steps || [])];
      updatedSteps.splice(newIndex, 0, updatedStep);

      const updatedTransitions = updatedSteps
        .slice(0, -1)
        .map((step, index) => ({
          transitionKey: uuid(),
          sourceStepKey: step.stepKey,
          targetStepKey: updatedSteps[index + 1].stepKey,
          index,
        }));

      currentTransitions = updatedTransitions;
      return {
        ...prev,
        steps: updatedSteps,
        transitions: updatedTransitions,
      };
    });

    await updateConfig(
      {
        workflowConfig: {
          steps: [updatedStep],
          transitions: currentTransitions,
        },
      },
      workflowKey
    );
  };

  const reorderStep = async (
    stepKey: string,
    newIndex: number,
    workflowKey?: string
  ) => {
    let currentSteps: StepConfig[] = [];
    let currentTransitions: TransitionConfig[] = [];

    setWorkflowConfiguration((prev) => {
      if (!prev?.steps) return prev;

      const steps = [...prev.steps];
      const oldIndex = steps.findIndex((step) => step.stepKey === stepKey);
      if (oldIndex === -1 || oldIndex === newIndex) return prev;

      const [movedStep] = steps.splice(oldIndex, 1);
      steps.splice(newIndex, 0, movedStep);

      const updatedTransitions = steps.slice(0, -1).map((step, index) => ({
        transitionKey: uuid(),
        sourceStepKey: step.stepKey,
        targetStepKey: steps[index + 1].stepKey,
        index,
      }));

      currentSteps = steps;
      currentTransitions = updatedTransitions;
      return { ...prev, steps, transitions: updatedTransitions };
    });

    await updateConfig(
      {
        workflowConfig: {
          steps: currentSteps,
          transitions: currentTransitions,
        },
      },
      workflowKey
    );
  };

  const addEvent = async (newEvent: EventConfig, workflowKey?: string) => {
    const eventWithKey = {
      ...newEvent,
      eventKey: newEvent.eventKey || uuid(),
    };

    setWorkflowConfiguration((prev) => {
      if (!prev) return { events: [eventWithKey] };
      return {
        ...prev,
        events: [...(prev.events || []), eventWithKey],
      };
    });

    await updateConfig(
      {
        workflowConfig: { events: [eventWithKey] },
      },
      workflowKey
    );
  };

  const removeEvent = async (eventKey: string, workflowKey?: string) => {
    setWorkflowConfiguration((prev) => {
      if (!prev || !prev.events) return prev;
      return {
        ...prev,
        events: prev.events.filter((event) => event.eventKey !== eventKey),
      };
    });

    await updateConfig(
      {
        deleteConfigs: [eventKey],
      },
      workflowKey
    );
  };

  const removeAllTasks = async (taskKeys: string[], workflowKey?: string) => {
    if (taskKeys.length === 0) return;

    await updateConfig(
      {
        deleteConfigs: taskKeys,
      },
      workflowKey
    );
  };

  return {
    // Base update method
    updateConfig,

    // Direct workflow update methods
    addTask,
    removeTask,
    addCertification,
    removeCertification,

    // Step methods with atom updates
    addStep,
    removeStep,
    updateSteps,
    appendStep,
    insertStep,
    reorderStep,

    // Event methods
    addEvent,
    removeEvent,

    // Utility methods
    removeAllTasks,
  };
}
