import { GlobalSearchControl } from '@btrway/global-search';
import React from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import 'survey-core/defaultV2.min.css'; // Make sure the default SurveyJS styles are imported
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';

const CUSTOM_TYPE = 'personSearch';

export class PersonSearch extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
}

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
  return new PersonSearch(name);
});

Serializer.addClass(
  CUSTOM_TYPE,
  [],
  function () {
    return new PersonSearch('');
  },
  'question'
);

export class SurveyPersonSearch extends SurveyQuestionElementBase {
  constructor(props: any) {
    super(props);
  }

  get question() {
    return this.questionBase;
  }

  get value() {
    return this.question.value;
  }

  handleValueChange = (val: any) => {
    this.question.value = val;
  };

  handleValueDeselect = () => {
    this.question.value = '';
  };

  renderSearch() {
    // Apply SurveyJS input and control styling classes
    return (
      <div className="sv-row">
        <div className="sv-question__content">
          <GlobalSearchControl
            onSelect={this.handleValueChange}
            showSelectedInInput={true}
            // className="sv-text sv-question__input" // Apply SurveyJS styles here
          />
        </div>
      </div>
    );
  }

  renderElement() {
    // Apply SurveyJS question wrapper class
    return (
      <div className="sv-question sv-question--text sv-row__question">
        {this.renderSearch()}
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return React.createElement(SurveyPersonSearch, props);
});
