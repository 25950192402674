import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAuthContext } from '@btrway/auth-core';
import { useCurrentUser } from '@btrway/current-user';
import { useAtom } from 'jotai';
import { createAxiosInstance, getClient } from '@btrway/api-client-provider';
import { registrationFlowAtom } from '../../atoms/registrationFlowAtom';

export const RegistrationCallback: React.FC = () => {
  const navigate = useNavigate();
  const { checkAuthStatus, authState, updateAuthState } = useAuthContext();
  const { currentUser } = useCurrentUser();
  const [flowState, setFlowState] = useAtom(registrationFlowAtom);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const state = params.get('state');
        const redirectUri = sessionStorage.getItem('redirect_uri');
        const savedState = sessionStorage.getItem('oauth_state');
        const apiUrl = authState.apiUrl;
        const axios = getClient();
        
        // Verify state parameter
        if (!state || state !== savedState) {
          throw new Error('Invalid state parameter');
        }

        // Clear stored state
        sessionStorage.removeItem('oauth_state');
        sessionStorage.removeItem('redirect_uri');

        if (!code) {
          throw new Error('No authorization code received');
        }

        let callbackUrl = `${apiUrl}/auth/callback?` +
        `code=${code}` +
        `&redirectUri=${redirectUri}`;

        // Exchange code for tokens
        const response = await axios.get(callbackUrl, {
          withCredentials: true
        });

        if (!(response.status === 200)) {
          throw new Error('Failed to exchange code for tokens');
        }

        // Immediately update auth state
        await updateAuthState({
          status: 'authenticated',
          isAuthenticated: true
        });

        createAxiosInstance(apiUrl);

        // Wait for auth status and current user to be loaded
        await checkAuthStatus();
        
        // Pre-populate registration data with user info
        if (currentUser) {
          setFlowState((prev: any) => ({
            ...prev,
            currentStep: 1, // Move to registrant info step
            completedSteps: [0], // Mark authentication step as complete
            reachedSteps: [0],
            registrantData: {
              email: currentUser.user.email,
              firstName: currentUser.person?.firstName || '',
              lastName: currentUser.person?.lastName || '',
              phoneNumber: currentUser.person?.mobilePhone
              // Add any other relevant user data
            }
          }));
        }

        // Get return path from session storage
        const returnTo = sessionStorage.getItem('returnTo');
        sessionStorage.removeItem('returnTo');

        // Navigate back to registration flow
        if (returnTo && returnTo.startsWith('/register')) {
          navigate(returnTo, { replace: true });
        } else {
          // Fallback to registration start
          navigate('/register', { replace: true });
        }
      } catch (error) {
        console.error('Authentication error:', error);
        navigate('/register', { 
          replace: true,
          state: { error: 'Authentication failed. Please try again.' }
        });
      }
    };

    handleCallback();
  }, [navigate, checkAuthStatus, updateAuthState, currentUser]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-2">Completing Authentication...</h2>
        <p className="text-gray-600">Please wait while we prepare your registration.</p>
      </div>
    </div>
  );
};