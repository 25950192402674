import {
  PublicRegistrationFormResponse,
  PublicWorkgroupResponse,
  RegistrationFormAvailabilityTypeEnum,
} from '@btrway/api-core';
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface WorkgroupLandingDetailProps {
  workgroup: PublicWorkgroupResponse;
}

const WorkgroupLandingDetail: React.FC<WorkgroupLandingDetailProps> = ({
  workgroup,
}) => {
  const navigate = useNavigate();

  const handleChildWorkgroupClick = (
    childWorkgroup: PublicWorkgroupResponse
  ) => {
    const path = childWorkgroup.workgroupSlug
      ? `/register/${childWorkgroup.workgroupSlug}`
      : `/register/${childWorkgroup.id}`;
    navigate(path);
  };

  const handleFormClick = (formId: number, year?: number) => {
    const yearPath = year ? `/${year}` : '';
    const path = workgroup.workgroupSlug
      ? `/register/${workgroup.workgroupSlug}/${formId}${yearPath}`
      : `/register/${workgroup.id}/${formId}${yearPath}`;
    navigate(path);
  };

  const sortedChildWorkgroups = [...(workgroup.childWorkgroups || [])].sort(
    (a, b) => a.workgroupName.localeCompare(b.workgroupName)
  );

  const parentWorkgroupLink = workgroup.parentWorkgroupSlug
    ? `/register/${workgroup.parentWorkgroupSlug}`
    : workgroup.parentWorkgroupId
    ? `/register/${workgroup.parentWorkgroupId}`
    : null;

  const currentDate = new Date();
  const sixMonthsAgo = new Date(
    currentDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000
  );
  const sixMonthsFromNow = new Date(
    currentDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000 * 2
  );

  const filteredForms = workgroup.registrationForms?.filter((form) => {
    const availability = form.registrationFormConfiguration?.availability;
    if (availability === RegistrationFormAvailabilityTypeEnum.always) {
      return true;
    }
    const endDate = form.endDate ? new Date(form.endDate) : null;
    const startDate = form.startDate ? new Date(form.startDate) : null;
    return (
      (endDate === null || endDate > sixMonthsAgo) &&
      (startDate === null || startDate < sixMonthsFromNow)
    );
  });

  const getFormTitle = (form: PublicRegistrationFormResponse) => {
    const baseTitle =
      form.registrationFormConfiguration?.title || 'Untitled Form';
    if (
      form.registrationFormConfiguration?.availability ===
        RegistrationFormAvailabilityTypeEnum.annual &&
      form.year
    ) {
      const nextYear = (form.year + 1).toString().slice(-2);
      return `${baseTitle} for ${form.year}-${nextYear}`;
    }
    return baseTitle;
  };

  const getFormAvailabilityText = (form: PublicRegistrationFormResponse) => {
    const availability = form.registrationFormConfiguration?.availability;
    if (availability === RegistrationFormAvailabilityTypeEnum.always) {
      return 'Always available';
    }
    const startDate = form.startDate ? new Date(form.startDate) : null;
    const endDate = form.endDate ? new Date(form.endDate) : null;
    if (startDate && endDate) {
      if (startDate > currentDate) {
        return `Registration Opens ${startDate.toLocaleDateString()}`;
      } else if (endDate < currentDate) {
        return 'Registration is now closed';
      } else {
        return `Available: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      }
    }
    return 'Dates not specified';
  };

  const isRegistrationOpen = (form: PublicRegistrationFormResponse) => {
    const availability = form.registrationFormConfiguration?.availability;
    if (availability === RegistrationFormAvailabilityTypeEnum.always) {
      return true;
    }
    const startDate = form.startDate ? new Date(form.startDate) : null;
    const endDate = form.endDate ? new Date(form.endDate) : null;
    return (
      (startDate === null || startDate <= currentDate) &&
      (endDate === null || currentDate <= endDate)
    );
  };

  return (
    <Container size="xl">
      <Stack gap="xl" pt="lg">
        <Center>
          <Stack align="center" gap="xs">
            <Title order={1} fw={700}>
              {workgroup.workgroupName}
            </Title>
            {parentWorkgroupLink && (
              <Anchor component={Link} to={parentWorkgroupLink} size="sm">
                {workgroup.parentWorkgroupName || 'Parent Workgroup'}
              </Anchor>
            )}
          </Stack>
        </Center>

        {filteredForms && filteredForms.length > 0 && (
          <Stack gap="md">
            <Title order={2} fw={600}>
              Register Now!
            </Title>
            <Stack gap="md">
              {filteredForms.map((form) => (
                <Card
                  key={form.id}
                  shadow="sm"
                  padding="lg"
                  radius="md"
                  withBorder
                  style={{ width: '100%', cursor: 'pointer' }}
                >
                  <Group gap="sm" justify="space-between">
                    <Stack gap="sm">
                      <Text fz="xl" fw={600}>
                        {getFormTitle(form)}
                      </Text>
                      <Text fz="md">{getFormAvailabilityText(form)}</Text>
                      <Text>
                        {form.registrationFormConfiguration?.shortDescription}
                      </Text>
                    </Stack>
                    {isRegistrationOpen(form) && (
                      <Group gap="sm" justify="flex-end">
                        <Button
                          size="sm"
                          variant="default"
                          onClick={() => handleFormClick(form.id, form.year)}
                        >
                          Register Now!
                        </Button>
                      </Group>
                    )}
                  </Group>
                </Card>
              ))}
            </Stack>
          </Stack>
        )}

        {sortedChildWorkgroups.length > 0 && (
          <Stack gap="md">
            <Flex gap="md" wrap="wrap" style={{ margin: '-8px' }}>
              {sortedChildWorkgroups.map((childWorkgroup) => (
                <div
                  key={childWorkgroup.id}
                  style={{ width: 'calc(25% - 16px)', padding: '8px' }}
                >
                  <Card
                    padding="sm"
                    radius="md"
                    withBorder
                    style={{
                      height: '100%',
                      cursor: 'pointer',
                      backgroundColor: '#e6f2ff',
                      boxShadow: 'none',
                    }}
                    onClick={() => handleChildWorkgroupClick(childWorkgroup)}
                  >
                    <Text fw={700} fz="sm">
                      {childWorkgroup.workgroupName}
                    </Text>
                  </Card>
                </div>
              ))}
            </Flex>
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default WorkgroupLandingDetail;
