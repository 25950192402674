import { getFormattedDuration } from '@btrway/utils';
import { MuxAssetList } from '@btrway/video-components';
import {
  Anchor,
  Box,
  Button,
  Card,
  Drawer,
  Flex,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import MuxPlayer from '@mux/mux-player-react';
import React, { useCallback } from 'react';
import { useChapter } from '../../hooks/useChapter';

interface VideoSelectorProps {
  chapterKey: string;
}

const VideoSelector: React.FC<VideoSelectorProps> = ({ chapterKey }) => {
  const { chapter, setChapter } = useChapter(chapterKey);
  const [opened, { open, close }] = useDisclosure(false);

  const handleVideoSelect = useCallback(
    (newPlaybackId: string, newDuration: number) => {
      setChapter({
        ...chapter,
        videoPlaybackIdentifier: newPlaybackId,
        duration: newDuration,
      });
      close();
    },
    [chapter, setChapter, close]
  );

  const handleAddVideo = useCallback(() => {
    open();
  }, [open]);

  const handleRemoveVideo = useCallback(() => {
    setChapter({
      ...chapter,
      videoPlaybackIdentifier: undefined,
      duration: undefined,
    });
  }, [chapter, setChapter]);

  if (!chapter) return null;

  return (
    <Stack gap="md">
      {!chapter.videoPlaybackIdentifier ? (
        <Card withBorder>
          <Flex align="center" justify="center" style={{ height: '100%' }}>
            <Button onClick={handleAddVideo} variant="subtle">
              Add Video
            </Button>
          </Flex>
        </Card>
      ) : (
        <Box>
          <MuxPlayer
            streamType="on-demand"
            playbackId={chapter.videoPlaybackIdentifier}
            metadataVideoTitle="Chapter Video"
            primaryColor="#FFFFFF"
            secondaryColor="#000000"
            theme="microvideo"
            style={{ width: '100%', height: 150 }}
          />
          <Group justify="space-between" mt="sm">
            {chapter.duration && (
              <Text size="sm">
                Duration: {getFormattedDuration(chapter.duration)}
              </Text>
            )}
            <Group gap="xs">
              <Anchor size="sm" onClick={handleRemoveVideo} color="red">
                Remove Video
              </Anchor>
            </Group>
          </Group>
        </Box>
      )}

      <Drawer
        opened={opened}
        onClose={close}
        title="Choose a Video"
        position="right"
        size="xl"
      >
        <MuxAssetList onSelect={handleVideoSelect} isSelector={true} />
      </Drawer>
    </Stack>
  );
};

export default VideoSelector;
