// timeUtils.ts
export const getDefaultStartEndTimes = (): {
  startTime: string;
  endTime: string;
} => {
  const today = new Date();
  const start = new Date(today);
  const end = new Date(today);

  // Set to 8:00 AM
  start.setHours(8, 0, 0, 0);
  // Set to 6:00 PM
  end.setHours(18, 0, 0, 0);

  return {
    startTime: start.toISOString(),
    endTime: end.toISOString(),
  };
};
