import { WorkgroupResponse } from '@btrway/api-core';
import { useWorkgroups } from '@btrway/workgroup-manager';
import Fuse, { FuseResult } from 'fuse.js';
import { useCallback, useMemo } from 'react';
import { ColumnMapping } from '../types/ColumnMapping';
import { useImportConfiguration } from './useImportConfiguration';

const MINIMUM_MATCH_SCORE = 0.6; // 60% match threshold

export const useFuzzyWorkgroupMapping = (organizationId: number) => {
  const { importConfiguration, updateImportConfiguration } =
    useImportConfiguration();
  const { workgroups } = useWorkgroups(organizationId);

  const fuse = useMemo(() => {
    return new Fuse(workgroups, {
      keys: ['name'],
      threshold: 0.3,
      includeScore: true,
    });
  }, [workgroups]);

  const getWorkgroupFullPath = (workgroup: WorkgroupResponse): string => {
    return workgroup.path;
  };

  const findBestMatch = useCallback(
    (
      workgroupName: string,
      results: FuseResult<WorkgroupResponse>[]
    ): FuseResult<WorkgroupResponse> | null => {
      if (results.length === 0) return null;

      const sortedResults = results.sort((a, b) => {
        if (a.item.name.toLowerCase() === workgroupName.toLowerCase())
          return -1;
        if (b.item.name.toLowerCase() === workgroupName.toLowerCase()) return 1;

        const aIsPrefix = a.item.name
          .toLowerCase()
          .startsWith(workgroupName.toLowerCase());
        const bIsPrefix = b.item.name
          .toLowerCase()
          .startsWith(workgroupName.toLowerCase());
        if (aIsPrefix && !bIsPrefix) return -1;
        if (bIsPrefix && !aIsPrefix) return 1;

        return (a.score || 1) - (b.score || 1);
      });

      const bestMatch = sortedResults[0];
      return bestMatch && (bestMatch.score || 1) <= 1 - MINIMUM_MATCH_SCORE
        ? bestMatch
        : null;
    },
    []
  );

  const executeWorkgroupMatching = useCallback(
    (columnMapping: ColumnMapping) => {
      const distinctWorkgroups = Array.from(
        new Set(columnMapping.valueMappings.map((vm) => vm.sourceValue))
      );

      const newValueMappings = distinctWorkgroups.map((workgroupName) => {
        const results = fuse.search(workgroupName);
        const bestMatch = findBestMatch(workgroupName, results);

        if (bestMatch && 1 - (bestMatch.score || 0) >= MINIMUM_MATCH_SCORE) {
          return {
            sourceValue: workgroupName,
            targetValue: bestMatch.item.id,
            targetDescription: getWorkgroupFullPath(bestMatch.item),
            autoMatched: true,
            autoMatchedValue: 1 - (bestMatch.score || 0),
          };
        } else {
          return {
            sourceValue: workgroupName,
            targetValue: null,
            targetDescription: undefined,
            autoMatched: false,
            autoMatchedValue: undefined,
          };
        }
      });

      return newValueMappings;
    },
    [fuse, findBestMatch]
  );

  const updateWorkgroupMapping = useCallback(
    (originalWorkgroup: string, selectedWorkgroup: WorkgroupResponse) => {
      updateImportConfiguration({
        columnMappings: importConfiguration.columnMappings.map((mapping) =>
          mapping.targetField === 'rootWorkgroup'
            ? {
                ...mapping,
                valueMappings: mapping.valueMappings.map((vm) =>
                  vm.sourceValue === originalWorkgroup
                    ? {
                        ...vm,
                        targetValue: selectedWorkgroup.id,
                        autoMatched: false,
                      }
                    : vm
                ),
              }
            : mapping
        ),
      });
    },
    [updateImportConfiguration, importConfiguration]
  );

  return {
    executeWorkgroupMatching,
    updateWorkgroupMapping,
    workgroups,
    fuse,
  };
};
