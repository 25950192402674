import type {
  CertificationConfig,
  CourseSelection,
  CourseTaskProperties,
  TaskConfig,
  TaskProperties,
} from '@btrway/api-workflow';

// Type guard to check if properties have course selection
function isPropertiesWithCourse(
  props: TaskProperties
): props is CourseTaskProperties {
  return 'courseSelection' in props;
}

// Type guard to check if task config has course selection
export function hasTaskCourseSelection(
  properties: TaskProperties | undefined
): properties is CourseTaskProperties {
  if (!properties) return false;
  return isPropertiesWithCourse(properties);
}

// Type guard to check if certification config has course selection
export function hasCertificationCourseSelection(
  config: CertificationConfig
): config is CertificationConfig & {
  taskProperties: CourseTaskProperties;
} {
  if (!config.taskProperties) return false;
  return isPropertiesWithCourse(config.taskProperties);
}

// Helper to get course selection from task or certification config
export function getCourseSelection(
  config: TaskConfig | CertificationConfig | undefined
): CourseSelection | undefined {
  if (!config) return undefined;

  // Handle task config
  if ('taskType' in config && config.taskProperties) {
    if (hasTaskCourseSelection(config.taskProperties)) {
      return config.taskProperties.courseSelection;
    }
  }

  // Handle certification config
  if ('certificationType' in config && config.taskProperties) {
    if (hasCertificationCourseSelection(config)) {
      return config.taskProperties.courseSelection;
    }
  }

  return undefined;
}
