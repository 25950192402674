import {
  TaskConfig,
  TaskListStartTypeEnum,
  TaskTypeEnum,
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { useState } from 'react';
import { Data } from 'react-spreadsheet-import/types/types';
import { ImportedTask } from '../types/ImportedTask';

const translateValidDataToWorkflowTemplateRequests = (
  validData: Data<keyof ImportedTask>[]
): Partial<WorkflowTemplateRequest>[] => {
  const groupedData: { [key: string]: ImportedTask[] } = {};

  // Group data by templateTitle
  validData.forEach((item) => {
    if (!item.templateTitle) {
      throw new Error('Each item must have a templateTitle');
    }
    const templateTitle = item.templateTitle as string;
    if (!groupedData[templateTitle]) {
      groupedData[templateTitle] = [];
    }
    groupedData[templateTitle].push(item as unknown as ImportedTask);
  });

  // Process each group and create WorkflowTemplateRequest
  return Object.entries(groupedData).map(([templateTitle, tasks]) => {
    console.log('tasks', tasks);
    const taskDefinitions: TaskConfig[] = tasks.map((task) => ({
      taskKey: uuid(),
      taskType: task.taskTypeCode as TaskTypeEnum,
      metadata: {
        title: task.taskTitle,
        subtitle: task.taskSubtitle,
        content: task.taskContent,
      },
      taskListConfig: {
        taskCategoryKey: task.taskCategory,
        startDate: {
          offsetDays: parseInt(task.startOffsetDays),
        },
        endDate: {
          offsetDays: parseInt(task.endOffsetDays),
        },
      },
    }));

    // Process userRoleString for all tasks in the template
    const allRoles = new Set<string>();
    tasks.forEach((task) => {
      if (task.userRoleString) {
        const roles = task.userRoleString
          .split(',')
          .map((role: string) => role.trim());
        roles.forEach((role: string) => allRoles.add(role));
      }
    });

    const defaultUserRoles = Array.from(allRoles);

    // Get the template configuration from the first task (assuming all tasks in a template have the same configuration)
    const firstTask = tasks[0];
    const templateSettings = {
      defaultUserRoles,
      taskListStartType: firstTask.taskListStartType as TaskListStartTypeEnum,
      taskListStartMonth: parseInt(firstTask.taskListStartMonth),
      taskListStartDay: parseInt(firstTask.taskListStartDay),
    };

    return {
      name: templateTitle,
      workflowType: WorkflowTypeEnum.taskList,
      workflowConfiguration: {
        tasks: taskDefinitions,
      },
      templateSettings,
    } as Partial<WorkflowTemplateRequest>;
  });
};

export const useTaskListImport = () => {
  const { addWorkflowTemplate } = useWorkflowTemplates();
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState<string | null>(null);

  const processImportedData = (validData: Data<keyof ImportedTask>[]) => {
    setIsImporting(true);
    setImportError(null);

    try {
      const workflowTemplateRequests =
        translateValidDataToWorkflowTemplateRequests(validData);

      workflowTemplateRequests.forEach((template) => {
        addWorkflowTemplate(template);
      });
    } catch (error) {
      setImportError(
        error instanceof Error ? error.message : 'An unknown error occurred'
      );
    } finally {
      setIsImporting(false);
    }
  };

  return { processImportedData, isImporting, importError };
};
