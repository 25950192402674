import {
  TaskConfig,
  TaskTypeEnum,
  ThirdPartyTaskProperties,
} from '@btrway/api-workflow';
import { UrlTextInput } from '@btrway/shared-components';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTypedTaskProperties } from '../../../config/taskPropertiesMap';
import { TaskConfigProps } from '../../../types/editors';

export const ThirdPartyTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.thirdParty
  );

  const [properties, setProperties] = useState<ThirdPartyTaskProperties>({
    title: taskProperties?.title || '',
    shortDescription: taskProperties?.shortDescription || '',
    url: taskProperties?.url || '',
    price: taskProperties?.price || 0,
  });

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        title: taskProperties.title,
        shortDescription: taskProperties.shortDescription || '',
        url: taskProperties.url || '',
        price: taskProperties.price || 0,
      });
    }
  }, [taskProperties]);

  const handlePropertyChange = (
    field: keyof ThirdPartyTaskProperties,
    value: string | number | null
  ) => {
    const updatedProperties = {
      ...properties,
      [field]: value ?? '',
    };
    setProperties(updatedProperties);

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        title: updatedProperties.title,
      },
      taskProperties: updatedProperties,
    };
    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <TextInput
        label="Title"
        placeholder="Enter task title"
        value={properties.title}
        onChange={(e) => handlePropertyChange('title', e.currentTarget.value)}
      />
      <Textarea
        label="Short Description"
        placeholder="Enter a brief description"
        value={properties.shortDescription || ''}
        onChange={(e) =>
          handlePropertyChange('shortDescription', e.currentTarget.value)
        }
      />
      <UrlTextInput
        label="URL"
        placeholder="Enter task URL"
        value={properties.url || ''}
        onUrlChange={(value) => handlePropertyChange('url', value)}
      />
      <TextInput
        label="Price"
        type="number"
        placeholder="Enter price"
        value={properties.price?.toString() || '0'}
        onChange={(e) =>
          handlePropertyChange('price', parseFloat(e.currentTarget.value) || 0)
        }
      />
    </Stack>
  );
};
