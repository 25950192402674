import { useAuth } from '@btrway/auth-core';
import { Box, Button, Flex, Space, Text } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthWeb } from '../../hooks/useAuthWeb';

interface MFAFormValues {
  code: string[];
}

export function MultiFactorAuthForm() {
  const { control, handleSubmit, setValue, watch } = useForm<MFAFormValues>({
    defaultValues: { code: Array(6).fill('') },
  });
  const [message, setMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const { verifyMultiFactorAuth } = useAuth();
  // const { getCurrentUser } = useAuthenticatedUser();
  const { handleLoginSuccess } = useAuthWeb();

  const code = watch('code');

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, 6);
    console.log(email);
  }, []);

  const onSubmit = async (data: MFAFormValues) => {
    const fullCode = data.code.join('');
    if (fullCode.length < 6) {
      setMessage('Code needs to be 6 digits');
      return;
    }
    if (!email) {
      setMessage('Email is missing');
      return;
    }
    const verifyMultiFactorAuthBody = {
      code: fullCode,
      email,
    };
    try {
      const response = await verifyMultiFactorAuth(verifyMultiFactorAuthBody);
      if (response.success) {
        setMessage('Successfully verified. Redirecting...');
        // const currentUser = await getCurrentUser();
        // if (currentUser) {
        //   handleLoginSuccess(false, email, currentUser);
        // } else {
        //   setMessage('Error fetching user data');
        // }
      } else {
        setMessage('Verification unsuccessful');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred. Please try again.');
    }
  };

  const handleChange = (index: number, value: string) => {
    if (value.length <= 1) {
      setValue(`code.${index}`, value);
      if (value !== '' && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' && index > 0 && code[index] === '') {
      setValue(`code.${index - 1}`, '');
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData.length === 6 && /^\d+$/.test(pastedData)) {
      pastedData.split('').forEach((char, i) => {
        setValue(`code.${i}`, char);
      });
      inputRefs.current[5]?.focus();
    }
  };

  return (
    <Box
      style={{
        maxWidth: 740,
        backgroundColor: 'white',
        padding: '40px',
        margin: 'auto',
      }}
    >
      <Text size="xl" fw={700} ta="center">
        Enter Verification Code
      </Text>
      <Space h="xl" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justify="center" align="center" gap="md">
          {Array.from({ length: 6 }).map((_, index) => (
            <Controller
              key={index}
              name={`code.${index}`}
              control={control}
              rules={{ required: true, maxLength: 1 }}
              render={({ field }) => (
                <input
                  {...field}
                  ref={(el) => {
                    inputRefs.current[index] = el;
                    field.ref(el);
                  }}
                  type="text"
                  inputMode="numeric"
                  maxLength={1}
                  style={{
                    width: '50px',
                    height: '50px',
                    fontSize: '24px',
                    textAlign: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    backgroundColor: '#f0f0f0',
                  }}
                  onChange={(e) => handleChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                />
              )}
            />
          ))}
        </Flex>
        {message && (
          <Text
            c={message.includes('error') ? 'red' : 'green'}
            mt="md"
            ta="center"
          >
            {message}
          </Text>
        )}
        <Button type="submit" mt="lg" fullWidth color="#2F667F">
          Verify Code
        </Button>
      </form>
    </Box>
  );
}
