import { StepConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { stepComponentRegistry } from '../../config/componentRegistry';
import { ConfigEditorWrapper } from '../ConfigEditorWrapper/ConfigEditorWrapper';

interface StepConfigEditorProps {
  initialStepConfig: StepConfig;
  workflow: CommonWorkflow;
  onClose: () => void;
  onDelete?: () => void;
  onSave: (config: StepConfig, isNew?: boolean) => Promise<void>;
  isNew?: boolean;
}

export const StepConfigEditor: React.FC<StepConfigEditorProps> = ({
  initialStepConfig,
  workflow,
  onClose,
  onDelete,
  onSave,
  isNew = false,
}) => {
  const workflowType = workflow.workflowType;

  const [currentConfig, setCurrentConfig] = useState<StepConfig | null>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  // const taskListConfigRef = useRef<TaskListConfigurationRef>(null);

  useEffect(() => {
    if (initialStepConfig) {
      setCurrentConfig(initialStepConfig);
    }
  }, [initialStepConfig]);

  if (
    workflowType !== WorkflowTypeEnum.form &&
    workflowType !== WorkflowTypeEnum.automation
  ) {
    console.error(
      'StepConfigEditor can only be used with Form and Automation workflows'
    );
    return null;
  }

  if (!currentConfig) {
    return null;
  }

  const handleConfigChange = (updatedConfig: StepConfig) => {
    setCurrentConfig(updatedConfig);
    setHasUnsavedChanges(true);
  };

  // const handleTaskConfigChange = (updatedTaskConfig: TaskConfig) => {
  //   if (currentConfig) {
  //     handleConfigChange({
  //       ...currentConfig,
  //       taskConfig: updatedTaskConfig,
  //     });
  //   }
  // };

  const handleSave = async () => {
    if (currentConfig) {
      // if (
      //   isTaskListAssignmentStep(currentConfig) &&
      //   taskListConfigRef.current
      // ) {
      //   taskListConfigRef.current.save();
      // }
      await onSave(currentConfig, isNew);
      setHasUnsavedChanges(false);
      onClose();
    }
  };

  const handleCancel = () => {
    setCurrentConfig(initialStepConfig);
    setHasUnsavedChanges(false);
    onClose();
  };

  const StepComponent =
    stepComponentRegistry[currentConfig.stepTypeCode]?.component;

  if (!StepComponent) {
    return (
      <Text c="red">
        No configuration component found for step type:{' '}
        {currentConfig.stepTypeCode}
      </Text>
    );
  }

  // const isTaskListAssignmentStep = (config: StepConfig): boolean => {
  //   return (
  //     config.stepTypeCode === StepTypeEnum.assignTask &&
  //     config.taskConfig?.taskType === 'completeTaskList'
  //   );
  // };

  return (
    <ConfigEditorWrapper
      hasUnsavedChanges={hasUnsavedChanges}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
      onDelete={onDelete}
    >
      <Stack gap="xl">
        <StepComponent
          config={currentConfig}
          onConfigChange={handleConfigChange}
          // workflowType={workflowType}
        />

        {/* {isTaskListAssignmentStep(currentConfig) &&
          currentConfig.taskConfig && (
            <TaskListConfiguration
              ref={taskListConfigRef}
              config={currentConfig.taskConfig}
              onConfigChange={handleTaskConfigChange}
              onChangesMade={(hasChanges: boolean) =>
                setHasUnsavedChanges(hasChanges)
              }
            />
          )} */}
      </Stack>
    </ConfigEditorWrapper>
  );
};
