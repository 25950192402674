import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { TopNavigationLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowDefinitionsProvider } from '@btrway/workflow-administration';
import {
  IconConfetti,
  IconHome,
  IconListCheck,
  IconRowInsertBottom,
  IconSettingsSpark,
  IconShieldCheckFilled,
  IconTemplate,
} from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded workflow components
const WorkflowAdminDashboardView = lazy(() =>
  import('@btrway/workflow-administration').then((module) => ({
    default: module.WorkflowAdminDashboardView,
  }))
);

const WorkflowTypeTemplatesPage = lazy(() =>
  import('@btrway/workflow-template-components').then((module) => ({
    default: module.WorkflowTypeTemplatesPage,
  }))
);

const WorkflowTemplatesPage = lazy(() =>
  import('@btrway/workflow-template-components').then((module) => ({
    default: module.WorkflowTemplatesPage,
  }))
);

// Helper component to create workflow definition views with type
const WorkflowTemplatesViewWithType: React.FC<{
  workflowType: WorkflowTypeEnum;
}> = ({ workflowType }) => {
  return <WorkflowTypeTemplatesPage workflowType={workflowType} />;
};

export const templateAdminRoutes: RouteConfig[] = [
  {
    path: 'template-admin',
    element: (
      <WorkflowDefinitionsProvider>
        <TopNavigationLayout />
      </WorkflowDefinitionsProvider>
    ),
    label: 'Process Templates',
    icon: IconSettingsSpark,
    navLevel: 'primary',
    navContainer: 'second',
    // permissions: ['WORKFLOW_ADMIN'],
    children: [
      {
        path: 'dashboard',
        element: <WorkflowTemplatesPage />,
        label: 'Dashboard',
        icon: IconHome,
        displayType: 'icon',
        navLevel: 'secondary',
      },
      {
        path: 'forms',
        element: <WorkflowTemplatesViewWithType workflowType="form" />,
        label: 'Forms',
        icon: IconTemplate,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'form-packets',
        element: <WorkflowTemplatesViewWithType workflowType="formPacket" />,
        label: 'Form Packets',
        icon: IconTemplate,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'todo-lists',
        element: <WorkflowTemplatesViewWithType workflowType="taskList" />,
        label: 'To-Do Lists',
        icon: IconListCheck,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'curriculums',
        element: <WorkflowTemplatesViewWithType workflowType="curriculum" />,
        label: 'Curriculums',
        navLevel: 'secondary',
        icon: IconRowInsertBottom,
        displayType: 'label',
      },
      {
        path: 'automations',
        element: <WorkflowTemplatesViewWithType workflowType="automation" />,
        label: 'Automations',
        navLevel: 'secondary',
        icon: IconConfetti,
        displayType: 'label',
      },
      {
        path: 'certifications',
        element: <WorkflowTemplatesViewWithType workflowType="certification" />,
        label: 'Certifications',
        navLevel: 'secondary',
        icon: IconShieldCheckFilled,
        displayType: 'label',
      },
    ],
  },
];
