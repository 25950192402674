import { WorkgroupResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Button, Popover, Text } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWorkgroup } from '../../providers/WorkgroupProvider';
import WorkgroupHierarchyTree from '../WorkgroupHierarchyTree/WorkgroupHierarchyTree';

const buttonStyles = {
  root: {
    '--button-bg': 'var(--mantine-color-gray-0)',
    '--button-hover': 'var(--mantine-color-gray-3)',
    '--button-radius': 'var(--mantine-radius-sm)',
    '--button-fz': 'var(--mantine-font-size-sm)',
    '--button-height': 'auto',
    '--button-width': 'auto',
    border: 'none',
    padding: '8px 12px',
    minWidth: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    color: 'black',
  },
};

const popoverStyles = {
  dropdown: {
    width: '300px',
    height: '400px',
    overflowY: 'auto' as const,
  },
};

interface WorkgroupHeaderProps {
  path: string;
  variant?: 'dark' | 'light';
}

export const WorkgroupHeader: React.FC<WorkgroupHeaderProps> = ({
  path,
  variant = 'light',
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const { workgroup } = useWorkgroup();
  const { currentUser } = useAuthenticatedUser();
  const rootWorkgroupId = currentUser?.rootWorkgroupId;
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectWorkgroup = (selectedWorkgroup: WorkgroupResponse) => {
    const pathSegments = location.pathname.split('/');
    const currentWorkgroupIndex = pathSegments.findIndex(
      (segment) => segment === workgroup?.id.toString()
    );

    if (currentWorkgroupIndex !== -1) {
      pathSegments[currentWorkgroupIndex] = selectedWorkgroup.id.toString();
      const hasSecondLevelNav = pathSegments.length > currentWorkgroupIndex + 1;
      const secondLevelNavPath = hasSecondLevelNav
        ? pathSegments[currentWorkgroupIndex + 1]
        : null;
      const hasChildren =
        Array.isArray(selectedWorkgroup.children) &&
        selectedWorkgroup.children.length > 0;

      if (secondLevelNavPath === 'workgroups' && !hasChildren) {
        navigate(`/app/${path}/${selectedWorkgroup.id}`, { replace: true });
      } else if (hasSecondLevelNav) {
        const newPath = pathSegments
          .slice(0, currentWorkgroupIndex + 2)
          .join('/');
        navigate(newPath, { replace: true });
      } else {
        navigate(`/app/${path}/${selectedWorkgroup.id}`, { replace: true });
      }
    } else {
      navigate(`/app/${path}/${selectedWorkgroup.id}`, { replace: true });
    }

    setPopoverOpened(false);
  };

  if (!workgroup || !rootWorkgroupId) {
    return null;
  }

  return (
    <Popover
      opened={popoverOpened}
      onChange={setPopoverOpened}
      position="bottom-start"
      withArrow
      shadow="md"
      styles={popoverStyles}
    >
      <Popover.Target>
        <Button
          radius="xl"
          onClick={() => setPopoverOpened((o) => !o)}
          styles={buttonStyles}
          rightSection={<IconChevronDown size={20} stroke={3} />}
        >
          <Text fz="md" fw={600}>
            {workgroup.name}
          </Text>
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <WorkgroupHierarchyTree
          rootWorkgroupId={rootWorkgroupId}
          selectedWorkgroup={workgroup}
          onSelectWorkgroup={handleSelectWorkgroup}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
