import { ActivityTypeEnum } from '@btrway/api-activities';

const getFriendlyDescription = (activityType: ActivityTypeEnum): string => {
  switch (activityType) {
    // User Related
    case ActivityTypeEnum.userCreated:
      return 'User Account Created';
    case ActivityTypeEnum.userUpdated:
      return 'User Account Updated';
    case ActivityTypeEnum.login:
      return 'User Login';

    // Comments
    case ActivityTypeEnum.comment:
      return 'Commented';

    // Tasks
    case ActivityTypeEnum.taskViewed:
      return 'Viewed Task';
    case ActivityTypeEnum.taskCompleted:
      return 'Completed Task';
    case ActivityTypeEnum.taskAssigned:
      return 'Assigned Task';

    // Learning/Curriculum
    case ActivityTypeEnum.curriculumTaskAssigned:
      return 'Assigned Curriculum';
    case ActivityTypeEnum.courseTaskCompleted:
      return 'Finished Course';

    // Workgroup Members
    case ActivityTypeEnum.workgroupMemberCreated:
      return 'Added Group Member';
    case ActivityTypeEnum.workgroupMemberUpdated:
      return 'Updated Group Member';
    case ActivityTypeEnum.workgroupMemberDeleted:
      return 'Removed Group Member';

    // Person Import
    case ActivityTypeEnum.personImportCompleted:
      return 'Completed Person Import';
    case ActivityTypeEnum.personImportReverted:
      return 'Reverted Person Import';

    // Forms
    case ActivityTypeEnum.formSubmitted:
      return 'Submitted Form';

    // Registration
    case ActivityTypeEnum.registrationSubmitted:
      return 'Submitted Registration';
    case ActivityTypeEnum.registrationProcessed:
      return 'Processed Registration';

    // Communications
    case ActivityTypeEnum.sentEmail:
      return 'Sent Email';

    // Relationships
    case ActivityTypeEnum.relationshipCreated:
      return 'Created Relationship';
    case ActivityTypeEnum.relationshipUpdated:
      return 'Updated Relationship';
    case ActivityTypeEnum.relationshipDeleted:
      return 'Deleted Relationship';

    // Default/Unknown
    case ActivityTypeEnum.missing_enum_value:
      return 'Unknown Activity Type';
    default:
      // If we get an activity type we don't recognize, return it formatted
      return activityType;
  }
};

// Helper function to get past tense of the description
export const getPastTenseDescription = (
  activityType: ActivityTypeEnum
): string => {
  const description = getFriendlyDescription(activityType);

  // For descriptions already in past tense, return as is
  if (description.endsWith('ed')) {
    return description;
  }

  // Special cases where we need custom past tense
  switch (activityType) {
    case ActivityTypeEnum.comment:
      return 'Made Comment';
    default:
      return description;
  }
};

// Helper function to get present tense of the description
export const getPresentTenseDescription = (
  activityType: ActivityTypeEnum
): string => {
  const description = getFriendlyDescription(activityType);

  // Remove 'ed' ending and add appropriate present tense
  return description
    .replace(/ed$/, '') // Remove 'ed' from the end
    .replace(/Created$/, 'Create')
    .replace(/Updated$/, 'Update')
    .replace(/Deleted$/, 'Delete')
    .replace(/Viewed$/, 'View')
    .replace(/Completed$/, 'Complete')
    .replace(/Assigned$/, 'Assign')
    .replace(/Submitted$/, 'Submit')
    .replace(/Processed$/, 'Process')
    .replace(/Commented$/, 'Comment');
};

export { getFriendlyDescription };
