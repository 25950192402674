import { ResponsiveModal } from '@btrway/shared-components';
import {
  Box,
  Button,
  Container,
  Group,
  rem,
  Stepper,
  Text,
} from '@mantine/core';
import React, { useCallback } from 'react';
import { useFileData } from '../../hooks/useFileData';
import { useImportConfiguration } from '../../hooks/useImportConfiguration';
import { useImportSteps } from '../../hooks/useImportSteps';
import { ImportConfiguration, ImportedPerson } from '../../types';
import { generateJSON } from '../../utils/generateJSON';

interface PersonImportProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  onSubmit: (
    data: Result<ImportedPerson>,
    file: File,
    configuration: ImportConfiguration
  ) => void | Promise<any>;
  displayGroupedSteps?: boolean;
}

interface ImportSettings {
  matchingMethod: 'byId' | 'byNameAndContact';
}

interface Result<T> {
  validData: T[];
  invalidData: T[];
}

const StepComponentWrapper: React.FC<{
  component: React.ComponentType<any>;
}> = ({ component: Component }) => {
  return <Component />;
};

export const PersonImport: React.FC<PersonImportProps> = ({
  opened,
  onClose,
  title,
  onSubmit,
  displayGroupedSteps = true,
}) => {
  const { file, fileData } = useFileData();

  // const [matchingMethod, setMatchingMethod] = useAtom(matchingMethodAtom);

  const {
    steps,
    groupedSteps,
    activeGroup,
    activeStepInGroup,
    maxReachedGroup,
    nextStep,
    prevStep,
    goToStep,
    setActiveStepInGroup,
    isFileUploaded,
  } = useImportSteps();

  const { importConfiguration } = useImportConfiguration();

  const handleSubmit = useCallback(() => {
    if (file && fileData) {
      const json = generateJSON(fileData, importConfiguration);

      const result = {
        validData: json,
        invalidData: [],
      };
      // const settings = {
      //   matchingMethod,
      // };
      onSubmit(result, file, importConfiguration);
    }
  }, [file, fileData, importConfiguration, onSubmit]);

  const currentStep = displayGroupedSteps
    ? groupedSteps[activeGroup].steps[activeStepInGroup]
    : steps[activeGroup * steps.length + activeStepInGroup];

  const isLastStep = displayGroupedSteps
    ? activeGroup === groupedSteps.length - 1 &&
      activeStepInGroup === groupedSteps[activeGroup].steps.length - 1
    : activeGroup * steps.length + activeStepInGroup === steps.length - 1;

  return (
    <ResponsiveModal
      opened={opened}
      onClose={onClose}
      title={title}
      footer={
        <Group justify="center">
          <Button
            variant="default"
            onClick={prevStep}
            disabled={activeGroup === 0 && activeStepInGroup === 0}
          >
            Back
          </Button>
          {!isLastStep ? (
            <Button
              onClick={nextStep}
              disabled={
                activeGroup === 0 && activeStepInGroup === 0 && !isFileUploaded
              }
            >
              Next step
            </Button>
          ) : (
            <Button onClick={handleSubmit}>Submit</Button>
          )}
        </Group>
      }
    >
      <Container size="xl">
        {(displayGroupedSteps ? groupedSteps : steps).length > 0 ? (
          <>
            <Stepper
              active={
                displayGroupedSteps
                  ? activeGroup
                  : activeGroup * steps.length + activeStepInGroup
              }
              onStepClick={(index) => {
                if (displayGroupedSteps) {
                  goToStep(index);
                } else {
                  const newGroup = Math.floor(index / steps.length);
                  const newStepInGroup = index % steps.length;
                  if (newGroup <= maxReachedGroup) {
                    goToStep(newGroup);
                    setActiveStepInGroup(newStepInGroup);
                  }
                }
              }}
              allowNextStepsSelect={false}
              mb="xl"
              iconSize="sm"
              styles={(theme) => ({
                steps: {
                  justifyContent: 'space-between',
                },
                step: {
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 0,
                  flex: '1 1 0%',
                },
                stepWrapper: {
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                },
                stepIcon: {
                  marginBottom: rem(8),
                  width: `calc(${theme.fontSizes.xl} * 1.6)`,
                  height: `calc(${theme.fontSizes.xl} * 1.6)`,
                  fontSize: theme.fontSizes.sm,
                },
                stepBody: {
                  textAlign: 'center',
                  width: '100%',
                  marginLeft: 0,
                  '&[dataIconPosition="left"]': {
                    marginInlineStart: 0,
                  },
                },
                stepLabel: {
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: rem(12),
                  lineHeight: 1.2,
                  textAlign: 'center',
                },
                separator: {
                  marginLeft: 0,
                  marginRight: 0,
                  top: '50%',
                  width: 'calc(100% - 1.6rem)',
                },
              })}
            >
              {displayGroupedSteps
                ? groupedSteps.map((group, index) => (
                    <Stepper.Step
                      key={group.label}
                      label={group.label}
                      allowStepSelect={index <= maxReachedGroup}
                    />
                  ))
                : steps.map((step, index) => (
                    <Stepper.Step
                      key={step.key}
                      label={step.label}
                      allowStepSelect={
                        index <=
                        maxReachedGroup * steps.length + activeStepInGroup
                      }
                    />
                  ))}
            </Stepper>

            <Box mb="xl">
              <Text fz={36} fw={700} mb="md" ta="center">
                {currentStep.description}
              </Text>
              <StepComponentWrapper component={currentStep.component} />
            </Box>
          </>
        ) : (
          <Text>Loading steps...</Text>
        )}
      </Container>
    </ResponsiveModal>
  );
};
