import { OrganizationResponse } from '@btrway/api-core';
import {
  useOrganizationTypes,
  useStateProvinces,
} from '@btrway/reference-data-manager';
import {
  Checkbox,
  CloseButton,
  Stack,
  TagsInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface OrganizationSearchProps {
  onSearchChange: (organizations: OrganizationResponse[]) => void;
  organizations: OrganizationResponse[];
}

const OrganizationSearch: React.FC<OrganizationSearchProps> = ({
  onSearchChange,
  organizations,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const { organizationTypes } = useOrganizationTypes();
  const { stateProvinces } = useStateProvinces();

  const filterOrganizations = useCallback(() => {
    return organizations.filter((org) => {
      const nameMatch = searchText
        ? org.name.toLowerCase().includes(searchText.toLowerCase())
        : true;

      const typeMatch = selectedTypes.length
        ? selectedTypes.includes(org.organizationType.id.toString())
        : true;

      const stateMatch = selectedStates.length
        ? selectedStates.includes(org.physicalAddress?.stateProvinceCode ?? '')
        : true;

      return nameMatch && typeMatch && stateMatch;
    });
  }, [organizations, searchText, selectedTypes, selectedStates]);

  useEffect(() => {
    const filtered = filterOrganizations();
    onSearchChange(filtered);
  }, [
    searchText,
    selectedTypes,
    selectedStates,
    filterOrganizations,
    onSearchChange,
  ]);

  const stateData = useMemo(
    () => stateProvinces.map((state) => state.code),
    [stateProvinces]
  );

  const filteredOrganizationTypes = useMemo(
    () => organizationTypes.filter((type) => !type.internal),
    [organizationTypes]
  );

  return (
    <Stack w={200} gap="md" mr="md">
      <TextInput
        label="Organization Name"
        value={searchText}
        onChange={(e) => setSearchText(e.currentTarget.value)}
        rightSection={
          searchText ? <CloseButton onClick={() => setSearchText('')} /> : null
        }
        placeholder="Search..."
        autoFocus
      />

      <Stack gap="xs">
        <Text fz="sm" fw={600}>
          Organization Type
        </Text>
        <Checkbox.Group value={selectedTypes} onChange={setSelectedTypes}>
          <Stack gap="xs">
            {filteredOrganizationTypes.map((type) => (
              <Checkbox
                key={type.id}
                value={type.id.toString()}
                label={type.name}
              />
            ))}
          </Stack>
        </Checkbox.Group>
      </Stack>

      <TagsInput
        label="States"
        value={selectedStates}
        onChange={setSelectedStates}
        data={stateData}
        placeholder="Select states..."
        clearable
      />
    </Stack>
  );
};

export default OrganizationSearch;
