import {
  EventTypeEnum,
  getGetEventTypesQueryKey,
  PropertyReference,
  useGetEventTypes,
  WorkflowProperty,
} from '@btrway/api-workflow';
import { useCallback, useMemo } from 'react';
import { useDataTypes } from './useDataTypes';

export const useEventTypes = () => {
  const {
    data: eventTypes,
    isLoading,
    error,
    refetch,
  } = useGetEventTypes({
    query: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      queryKey: getGetEventTypesQueryKey(),
    },
  });

  const { getDataType } = useDataTypes();

  const getEventType = useCallback(
    (eventTypeCode: EventTypeEnum) => {
      return eventTypes?.find((type) => type.code === eventTypeCode);
    },
    [eventTypes]
  );

  const getEventTypeProperties = useCallback(
    (eventTypeCode: EventTypeEnum): PropertyReference[] => {
      const eventType = getEventType(eventTypeCode);
      if (!eventType || !eventType.outputProperties) return [];

      const createPropertyReference = (
        prop: WorkflowProperty,
        parentLabel?: string,
        entityProperty?: string
      ): PropertyReference => ({
        dataType: prop.dataType,
        label: parentLabel ? `${parentLabel} - ${prop.label}` : prop.label,
        entityProperty,
        propertyKey: prop.key,
        stepKey: '',
      });

      const addDataTypeProperties = (
        properties: PropertyReference[]
      ): PropertyReference[] => {
        const allProperties: PropertyReference[] = [];

        properties.forEach((prop) => {
          allProperties.push(prop);

          const dataTypeDetails = getDataType(prop.dataType);
          if (dataTypeDetails?.properties) {
            dataTypeDetails.properties.forEach((childProp) => {
              allProperties.push(
                createPropertyReference(
                  {
                    dataType: childProp.dataTypeCode,
                    key: prop.propertyKey,
                    label: childProp.name,
                  },
                  prop.label,
                  childProp.key
                )
              );
            });
          }
        });

        return allProperties;
      };

      const initialProperties = eventType.outputProperties.map((prop) =>
        createPropertyReference(prop)
      );

      return addDataTypeProperties(initialProperties);
    },
    [getEventType, getDataType]
  );

  const memoizedEventTypeProperties = useMemo(() => {
    const allEventTypeProperties: Record<EventTypeEnum, PropertyReference[]> =
      {} as Record<EventTypeEnum, PropertyReference[]>;

    eventTypes?.forEach((eventType) => {
      allEventTypeProperties[eventType.code] = getEventTypeProperties(
        eventType.code
      );
    });

    return allEventTypeProperties;
  }, [eventTypes, getEventTypeProperties]);

  return {
    eventTypes: eventTypes ?? [],
    isLoading,
    error,
    getEventType,
    getEventTypeProperties,
    eventTypeProperties: memoizedEventTypeProperties,
    refetchEventTypes: refetch,
  };
};
