import { HelpResourceResponse } from '@btrway/api-help';
import { Stack } from '@mantine/core';
import React from 'react';
import HelpResourceSummaryCard from '../HelpResourceSummaryCard/HelpResourceSummaryCard';

interface HelpResourceListProps {
  helpResources: HelpResourceResponse[];
  onHelpResourceClick: (helpResource: HelpResourceResponse) => void;
}

const HelpResourceList: React.FC<HelpResourceListProps> = ({
  helpResources,
  onHelpResourceClick,
}) => {
  return (
    <Stack gap="sm">
      {helpResources.map((helpResource) => (
        <HelpResourceSummaryCard
          key={helpResource.id}
          helpResource={helpResource}
          onClick={onHelpResourceClick}
        />
      ))}
    </Stack>
  );
};

export default HelpResourceList;
