import {
  RuleOperatorTypeEnum,
  TriggerConfig,
  TriggerSetConfig,
} from '@btrway/api-workflow';

export interface TriggerEvaluationResult {
  description: string;
  propertyKeys: Set<string>;
}

export class TriggerDescriptionService {
  buildTriggerSetDescriptionWithProperties(
    triggerSets?: TriggerSetConfig[]
  ): TriggerEvaluationResult {
    if (!triggerSets?.length) {
      return { description: '', propertyKeys: new Set() };
    }

    const allPropertyKeys = new Set<string>();
    const description = triggerSets
      .map((ts) => this.describeTriggerSetWithProperties(ts, allPropertyKeys))
      .filter(Boolean)
      .join(' OR ');

    return { description, propertyKeys: allPropertyKeys };
  }

  private describeTriggerSetWithProperties(
    triggerSet: TriggerSetConfig,
    propertyKeys: Set<string>
  ): string {
    if (!triggerSet.triggers?.length) {
      return '';
    }

    triggerSet.triggers.forEach((t) => {
      if (t.property?.propertyKey) {
        propertyKeys.add(t.property.propertyKey);
      }
    });

    return triggerSet.triggers
      .map((t) => this.describeTrigger(t))
      .filter(Boolean)
      .join(' AND ');
  }

  buildTriggerSetDescription(triggerSets?: TriggerSetConfig[]): string {
    if (!triggerSets?.length) {
      return '';
    }

    return triggerSets
      .map((ts) => this.describeTriggerSet(ts))
      .filter(Boolean)
      .join(' OR ');
  }

  private describeTriggerSet(triggerSet: TriggerSetConfig): string {
    if (!triggerSet.triggers?.length) {
      return '';
    }

    return triggerSet.triggers
      .map((t) => this.describeTrigger(t))
      .filter(Boolean)
      .join(' AND ');
  }

  private describeTrigger(trigger: TriggerConfig): string {
    const propertyLabel = trigger.property?.label ?? '';
    const operator = this.formatOperator(trigger.ruleOperator);

    if (trigger.ruleOperator === RuleOperatorTypeEnum.hasvalue) {
      return `${propertyLabel} has a value`;
    }

    if (trigger.ruleOperator === RuleOperatorTypeEnum.novalue) {
      return `${propertyLabel} has no value`;
    }

    const value = trigger.ruleValues?.[0]
      ? this.extractValue(trigger.ruleValues[0])
      : '';

    return `${propertyLabel} ${operator} ${value}`;
  }

  private formatOperator(operator?: RuleOperatorTypeEnum): string {
    if (!operator) return '';

    switch (operator) {
      case RuleOperatorTypeEnum.equal:
        return 'is';
      case RuleOperatorTypeEnum.notequal:
        return 'is not';
      case RuleOperatorTypeEnum.contains:
        return 'contains';
      case RuleOperatorTypeEnum.notcontains:
        return 'does not contain';
      case RuleOperatorTypeEnum.startswith:
        return 'starts with';
      case RuleOperatorTypeEnum.endswith:
        return 'ends with';
      case RuleOperatorTypeEnum.greater:
        return 'is greater than';
      case RuleOperatorTypeEnum.greaterorequal:
        return 'is greater than or equal to';
      case RuleOperatorTypeEnum.less:
        return 'is less than';
      case RuleOperatorTypeEnum.lessorequal:
        return 'is less than or equal to';
      case RuleOperatorTypeEnum.includes:
        return 'includes';
      case RuleOperatorTypeEnum.excludes:
        return 'excludes';
      case RuleOperatorTypeEnum.before:
        return 'is before';
      case RuleOperatorTypeEnum.after:
        return 'is after';
      case RuleOperatorTypeEnum.onorbefore:
        return 'is on or before';
      case RuleOperatorTypeEnum.onorafter:
        return 'is on or after';
      default:
        return operator;
    }
  }

  private extractValue(item: Record<string, unknown>): unknown {
    return item.value ?? item;
  }
}
