import { Chip, Stack, Text } from '@mantine/core';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { importConfigurationAtom } from '../../atoms/importConfigurationAtom';
import { useFileData } from '../../hooks/useFileData';

export const SheetSelectionStep: React.FC = () => {
  const [importConfiguration, setImportConfiguration] = useAtom(
    importConfigurationAtom
  );

  const handleSheetSelection = (sheetName: string) => {
    setImportConfiguration((prev) => ({ ...prev, sheet: sheetName }));
  };

  const { getAvailableSheets } = useFileData();
  const [sheetOptions, setSheetOptions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { file } = useFileData();

  useEffect(() => {
    const fetchSheets = async () => {
      if (!file) {
        setError('No file available. Please upload a file first.');
        return;
      }

      try {
        const sheets = await getAvailableSheets();
        setSheetOptions(sheets);
        setError(null);
      } catch (err) {
        console.error('Error fetching sheets:', err);
        setError(
          'Failed to fetch sheets. Please try uploading the file again.'
        );
      }
    };

    fetchSheets();
  }, [file, getAvailableSheets]);

  if (error) {
    return <Text c="red">{error}</Text>;
  }

  return (
    <Stack align="center" mt="xl">
      <Chip.Group
        value={importConfiguration.sheet}
        onChange={handleSheetSelection}
        multiple={false}
      >
        <Stack gap="md" align="center">
          {sheetOptions.map((sheet) => (
            <Chip key={sheet} value={sheet} size="lg">
              {sheet}
            </Chip>
          ))}
        </Stack>
      </Chip.Group>
    </Stack>
  );
};
