import {
  useGetDistinctWorkflowCategories,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import {
  Button,
  Group,
  Modal,
  SegmentedControl,
  Stack,
  TagsInput,
  Textarea,
  TextInput,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { IconList, IconPicker } from 'react-fa-icon-picker';

interface WorkflowDefinitionMetadataEditProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedWorkflow: CommonWorkflow) => void;
  initialData: CommonWorkflow;
}

export const WorkflowDefinitionMetadataEdit: React.FC<
  WorkflowDefinitionMetadataEditProps
> = ({ isOpen, onClose, onSave, initialData }) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [suggestedCategories, setSuggestedCategories] = useState<string[]>([]);
  const { data: categoriesResponse } = useGetDistinctWorkflowCategories(
    currentOrganization.id
  );

  const [name, setName] = useState(initialData.name);
  const [shortDescription, setShortDescription] = useState(
    initialData.workflowMetadata?.shortDescription || ''
  );
  const [longDescription, setLongDescription] = useState(
    initialData.workflowMetadata?.longDescription || ''
  );
  const [categories, setCategories] = useState<string[]>(
    initialData.workflowMetadata?.categories || []
  );
  const [icon, setIcon] = useState<IconList>(
    (initialData.workflowMetadata?.iconName as IconList) || ''
  );
  const [taskListType, setTaskListType] = useState(
    initialData.workflowMetadata?.taskListType || 'To-Do List'
  );

  useEffect(() => {
    if (categoriesResponse) {
      setSuggestedCategories(categoriesResponse);
    }
  }, [categoriesResponse]);

  const handleSave = () => {
    const updatedWorkflow = {
      ...initialData,
      name,
      workflowMetadata: {
        shortDescription,
        longDescription,
        categories,
        iconName: icon,
        // Only update taskListType if the control is shown
        taskListType: showTaskListType
          ? taskListType
          : initialData.workflowMetadata?.taskListType,
      },
    };
    onSave(updatedWorkflow);
    onClose();
  };

  const showTaskListType =
    initialData.sourceType === 'template' &&
    (initialData.workflowType === WorkflowTypeEnum.taskList ||
      initialData.workflowType === WorkflowTypeEnum.certification);

  const getSegmentedControlData = () => {
    if (initialData.workflowType === WorkflowTypeEnum.taskList) {
      return [
        { label: 'To-Do List', value: 'To-Do List' },
        { label: 'ABW To-Do List', value: 'ABW To-Do List' },
        { label: 'Task Assignment', value: 'Task Assignment' },
      ];
    } else if (initialData.workflowType === WorkflowTypeEnum.certification) {
      return [
        { label: 'Coach Certification', value: 'Coach Certification' },
        {
          label: 'Student-Athlete Eligibility',
          value: 'Student-Athlete Eligibility',
        },
        { label: 'Certification', value: 'Certification' },
      ];
    }
    return [];
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Edit Workflow Description"
      size="lg"
    >
      <Stack>
        {showTaskListType && (
          <SegmentedControl
            value={taskListType}
            onChange={setTaskListType}
            data={getSegmentedControlData()}
          />
        )}
        <Group align="flex-end" style={{ position: 'relative' }}>
          <TextInput
            label="Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            style={{ flex: 1 }}
          />
          <div style={{ position: 'relative' }}>
            <IconPicker
              value={icon}
              onChange={(v: IconList) => setIcon(v)}
              buttonStyles={{ height: '36px' }}
              containerStyles={{
                position: 'absolute',
                top: '100%',
                right: '-10px',
                marginTop: '5px',
                zIndex: 1000,
              }}
            />
          </div>
        </Group>
        <TagsInput
          label="Categories"
          placeholder="Type a category and press enter"
          value={categories}
          onChange={setCategories}
          data={suggestedCategories}
          clearable
        />
        <Textarea
          label="Short Description"
          value={shortDescription}
          onChange={(event) => setShortDescription(event.currentTarget.value)}
          minRows={2}
        />
        <Textarea
          label="Long Description"
          value={longDescription}
          onChange={(event) => setLongDescription(event.currentTarget.value)}
          rows={8}
          minRows={4}
        />
        <Group justify="flex-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Stack>
    </Modal>
  );
};
