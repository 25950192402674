import {
  ActionIcon,
  Box,
  Group,
  Highlight,
  Stack,
  Textarea,
} from '@mantine/core';
import { IconSend } from '@tabler/icons-react';
import React from 'react';
import { useAssistantChat } from '../../hooks/useAssistantChat';
import AssistantMessageList from '../AssistantMessageList/AssistantMessageList';
import styles from './AssistantBar.module.css';

const AssistantBar: React.FC = () => {
  const {
    conversation,
    userInput,
    setUserInput,
    loading,
    handleSendMessage,
    assistantConfig,
    functionLoading,
  } = useAssistantChat({ apiKey: import.meta.env.VITE_OPENAI_API_KEY });

  if (!assistantConfig) {
    return <div>no assistant config</div>;
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box style={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
        <AssistantMessageList
          conversationHistory={conversation}
          loading={loading}
          functionLoading={functionLoading}
        />
      </Box>
      <Stack gap={0} w="100%" mt="md" p="sm">
        <Group
          align="start"
          wrap="nowrap"
          gap={0}
          w="100%"
          p={4}
          style={{
            borderRadius: 4,
          }}
        >
          <Textarea
            className={styles.textareaAutosize}
            placeholder="Reply to Heath..."
            value={userInput}
            onChange={(e) => setUserInput(e.currentTarget.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !loading) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            autosize
            minRows={1}
            maxRows={5}
            disabled={loading}
          />
          <ActionIcon
            variant="gradient"
            size="md"
            mt={4}
            mr={4}
            aria-label="Send message"
            gradient={{ from: 'purple', to: 'blue', deg: 180 }}
            onClick={handleSendMessage}
            disabled={loading}
          >
            <IconSend size={16} />
          </ActionIcon>
        </Group>
        <Group justify="flex-end">
          <Highlight
            highlight="shift + return"
            mt={6}
            c="dark.9"
            fz={10}
            highlightStyles={{
              // padding: '3px',
              // borderRadius: 5,
              // backgroundColor: 'var(--mantine-color-gray-8)',
              color: 'var(--mantine-color-purple-9)',
            }}
          >
            Use shift + return for new line
          </Highlight>
        </Group>
      </Stack>
    </Box>
  );
};

export default AssistantBar;
