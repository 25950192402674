import {
  default as axios,
  default as Axios,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';

let AXIOS_INSTANCE: AxiosInstance | undefined;

export const createAxiosInstance = (apiUrl: string) => {
  const instance = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Small delay to ensure cookies are set

      const originalRequest = error.config;

      // Skip refresh token attempt for auth status checks on login page
      const isAuthStatusCheck = originalRequest.url === '/auth/status';
      const isLoginPage = window.location.pathname === '/login';

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        !(isAuthStatusCheck && isLoginPage)
      ) {
        originalRequest._retry = true;

        try {
          await axios.post(
            '/auth/refresh-token',
            {},
            {
              withCredentials: true,
              baseURL: apiUrl,
            }
          );

          return instance(originalRequest);
        } catch (refreshError) {
          if (!isLoginPage) {
            window.location.href = '/login';
          }
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );

  AXIOS_INSTANCE = instance;
  return instance;
};

export const getClient = () => {
  if (!AXIOS_INSTANCE) {
    throw new Error(
      'Axios client not initialized. Call initializeClient first.'
    );
  }
  return AXIOS_INSTANCE;
};

export const axiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const instance = getClient();
  const source = Axios.CancelToken.source();

  const promise = instance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};
