import { EventTargetRequest } from '@btrway/api-calendar';
import { DataTypeEnum } from '@btrway/api-workflow';
import { GlobalSearchControl } from '@btrway/global-search';
import { Stack, Text } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import { EventTargetCard } from '../EventTargetCard/EventTargetCard';

interface EventAttendeeSelectorProps {
  targets: EventTargetRequest[];
  onAddTarget: (target: EventTargetRequest) => void;
  onRemoveTarget: (targetId: number) => void;
  organizationId: number;
}

const EventAttendeeSelector: React.FC<EventAttendeeSelectorProps> = ({
  targets,
  onAddTarget,
  onRemoveTarget,
  organizationId,
}) => {
  // Filter for person and entitygroup targets
  const attendeeTargets = useMemo(
    () =>
      targets.filter(
        (target) =>
          target.entityType === DataTypeEnum.person ||
          target.entityType === DataTypeEnum.entityGroup
      ),
    [targets]
  );

  const handleEntitySelect = useCallback(
    (entityType: DataTypeEnum, entityId: number) => {
      // Only allow person and entityGroup types
      if (
        entityType !== DataTypeEnum.person &&
        entityType !== DataTypeEnum.entityGroup
      ) {
        return;
      }

      // Check if target already exists
      const targetExists = targets.some(
        (target) =>
          target.entityType === entityType && target.entityId === entityId
      );

      if (!targetExists) {
        const newTarget: EventTargetRequest = {
          entityId,
          entityType,
          organizationId,
        };
        onAddTarget(newTarget);
      }
    },
    [targets, onAddTarget, organizationId]
  );

  return (
    <Stack gap="md">
      <GlobalSearchControl
        onSelect={handleEntitySelect}
        clearOnSelect={true}
        showSelectedInInput={false}
      />
      <Stack gap="xs">
        <Text size="sm" fw={500} c="dimmed">
          Recipients ({attendeeTargets.length})
        </Text>
        {attendeeTargets.map((target) => (
          <EventTargetCard
            key={`${target.entityType}-${target.entityId}`}
            target={target}
            onRemove={onRemoveTarget}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default EventAttendeeSelector;
