import { ActionIcon, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import React from 'react';

interface FormSearchProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  isMobile: boolean;
}

const FormSearch: React.FC<FormSearchProps> = ({
  searchTerm,
  onSearchChange,
  isMobile,
}) => {
  const handleClearSearch = () => {
    onSearchChange('');
  };

  return (
    <TextInput
      placeholder="Find the form you're looking for!"
      leftSection={<IconSearch size="1.1rem" stroke={1.5} />}
      rightSection={
        searchTerm && (
          <ActionIcon
            onClick={handleClearSearch}
            variant="transparent"
            color="dark"
          >
            <IconX size="1rem" />
          </ActionIcon>
        )
      }
      style={{
        width: '100%',
        maxWidth: isMobile ? '100%' : '500px',
      }}
      value={searchTerm}
      onChange={(event) => onSearchChange(event.currentTarget.value)}
      styles={(theme) => ({
        input: {
          fontSize: theme.fontSizes.lg,
          height: 'auto',
          paddingTop: '0.3rem',
          paddingBottom: '0.3rem',
          paddingLeft: '2.5rem',
          paddingRight: searchTerm ? '2.5rem' : theme.spacing.xl,
        },
        rightSection: {
          pointerEvents: 'auto',
          paddingRight: theme.spacing.xs,
        },
        leftSection: {
          width: '2.5rem',
          pointerEvents: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      })}
    />
  );
};

export default FormSearch;
