import { MergeToken } from '../types/mergeToken';

export const AVAILABLE_TOKENS: MergeToken[] = [
  {
    type: 'field',
    name: 'First Name',
    fieldId: 'firstName',
    description: "Recipient's first name",
  },
  {
    type: 'field',
    name: 'Last Name',
    fieldId: 'lastName',
    description: "Recipient's last name",
  },
  {
    type: 'field',
    name: 'Email',
    fieldId: 'email',
    description: "Recipient's email address",
  },
];
