import { StepConfig } from '@btrway/api-workflow';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workflowConfigurationAtom } from './workflowConfigurationAtom';

export const workflowStepAtomFamily = atomFamily((stepKey: string) =>
  atom(
    (get) => {
      const workflowConfig = get(workflowConfigurationAtom);
      return (
        workflowConfig?.steps?.find((step) => step.stepKey === stepKey) || null
      );
    },
    (get, set, update: Partial<StepConfig>) => {
      set(workflowConfigurationAtom, (prev) => {
        if (!prev?.steps) return prev;
        const stepIndex = prev.steps.findIndex(
          (step) => step.stepKey === stepKey
        );
        if (stepIndex === -1) return prev;

        return {
          ...prev,
          steps: [
            ...prev.steps.slice(0, stepIndex),
            { ...prev.steps[stepIndex], ...update },
            ...prev.steps.slice(stepIndex + 1),
          ],
        };
      });
    }
  )
);
