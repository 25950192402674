import { Stack } from '@mantine/core';
import { ChatCompletionMessageParam } from 'openai/resources';
import React, { useEffect, useRef } from 'react';
import AssistantMessage from '../AssistantMessage/AssistantMessage';

interface AssistantMessageListProps {
  conversationHistory: ChatCompletionMessageParam[];
  backgroundColor?: string;
  loading: boolean;
  functionLoading: boolean;
}

const AssistantMessageList: React.FC<AssistantMessageListProps> = ({
  conversationHistory,
  backgroundColor,
  loading,
  functionLoading,
}) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [conversationHistory]);

  return (
    <Stack gap={8}>
      {conversationHistory.map((message, index) => (
        <div
          key={index}
          ref={index === conversationHistory.length - 1 ? lastMessageRef : null}
        >
          <AssistantMessage
            message={message}
            backgroundColor={backgroundColor}
            loading={loading && index === conversationHistory.length - 1}
            functionLoading={
              functionLoading && index === conversationHistory.length - 1
            }
          />
        </div>
      ))}
    </Stack>
  );
};

export default AssistantMessageList;
