import {
  RegistrationFormConfiguration,
  RegistrationFormResponse,
} from '@btrway/api-core';

export const mergeRegistrationFormConfig = (
  form: RegistrationFormResponse,
  currentWorkgroupId: number
): RegistrationFormConfiguration => {
  const baseConfig = form.registrationFormConfiguration || {};
  const override = form.registrationFormOverrides?.find(
    (o) => o.workgroupId === currentWorkgroupId
  );
  const overrideConfig = override?.registrationFormConfiguration || {};

  return {
    ...baseConfig,
    ...overrideConfig,
  };
};
