import {
  DerivedTaskTypeEnum,
  EntityTaskResponse,
  TaskInstanceRequest,
} from '@btrway/api-workflow';

/**
 * Converts an EntityTaskResponse to a TaskInstanceRequest.
 * Used when updating or completing tasks.
 */
export const convertToTaskInstanceRequest = (
  entityTask: Partial<EntityTaskResponse>,
  comment?: string
): TaskInstanceRequest => {
  const {
    taskInstanceId,
    rollupWorkflowType,
    rollupWorkflowKey,
    parentWorkflowKey,
    taskListConfig,
    rollupWorkflowName,
    certificationType,
    ...commonFields
  } = entityTask;

  return {
    ...commonFields,
    id: taskInstanceId,
    comment,
    // Default required fields if not provided
    completed: entityTask.completed ?? false,
    organizationId: entityTask.organizationId!,
    assignedEntityId: entityTask.assignedEntityId!,
    assignedEntityType: entityTask.assignedEntityType!,
    // Map workflow instance fields if needed
    // workflowInstanceId: undefined,
    // Ensure all required TaskInstanceRequest fields are included
    viewed: true, // entityTask.viewed ?? false
    derivedTaskType: taskInstanceId
      ? DerivedTaskTypeEnum.none
      : DerivedTaskTypeEnum.task,
    derivedWorkflowKey: entityTask.parentWorkflowKey,
  };
};
