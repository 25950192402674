import { CalendarView } from '@btrway/calendar-components';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import styles from './CalendarPage.module.css';

const CalendarPage: React.FC = () => {
  const { currentPerson } = useAuthenticatedUser();

  return (
    <Stack p={0} gap={0} bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Card p="md" radius="lg" mih={800} bg="white">
            <CalendarView person={currentPerson} />
          </Card>
        </Box>
      </Flex>
    </Stack>
  );
};

export default CalendarPage;
