import {
  PersonImportSubmitRequest,
  useSubmitPersonImport,
} from '@btrway/api-batch';
import { StorageUploadRequest, StorageUsageEnum } from '@btrway/api-storage';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useStorageUpload } from '@btrway/file-storage-components';
import { Button } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import React, { useState } from 'react';
import { ImportConfiguration } from '../../types';
import { PersonImport } from '../PersonImport/PersonImport';

interface PersonImportModalProps {
  rootWorkgroupId: number;
}

const PersonImportModal: React.FC<PersonImportModalProps> = ({
  rootWorkgroupId,
}) => {
  const { currentUser, currentOrganization } = useAuthenticatedUser();
  const { mutate: submitPersonImport } = useSubmitPersonImport();
  const { storageUpload } = useStorageUpload();

  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (
    data: any,
    file: File,
    configuration: ImportConfiguration
  ) => {
    console.log('onSubmit, data: ', data);
    console.log('onSubmit, file: ', file);
    console.log('onSubmit, configuration: ', configuration);

    if (currentUser?.user && currentOrganization) {
      const storageUploadRequest: StorageUploadRequest = {
        organizationId: currentOrganization.id,
        userId: currentUser.user.id,
        fileNames: [file.name, 'validData.json', 'invalidData.json'],
        storageUsageEnum: StorageUsageEnum.import,
      };

      try {
        const validDataBlob = new Blob([JSON.stringify(data.validData)], {
          type: 'application/json',
        });
        const invalidDataBlob = new Blob([JSON.stringify(data.invalidData)], {
          type: 'application/json',
        });

        const res = await storageUpload(storageUploadRequest, [
          { file: file, contentType: file.type, fileName: file.name },
          {
            file: validDataBlob,
            contentType: 'application/json',
            fileName: 'validData.json',
          },
          {
            file: invalidDataBlob,
            contentType: 'application/json',
            fileName: 'invalidData.json',
          },
        ]);

        if (res.storageKeys && res.storageKeys.length === 3) {
          const personImportRequest: PersonImportSubmitRequest = {
            organizationId: currentOrganization.id,
            userId: currentUser.user?.id,
            jobKey: res.uuid || '',
            originalFileName: file.name,
          };
          submitPersonImport(
            { data: personImportRequest },
            {
              onSuccess: () => {
                // Handle success if needed
              },
              onError: (error: unknown) => {
                console.error('Error submitting person import:', error);
              },
            }
          );
        } else {
          console.error('Failed to upload all files');
        }
      } catch (error) {
        console.error('Error uploading file or saving person:', error);
      }
    }

    onClose();
  };

  if (!currentOrganization || !currentUser) {
    return null;
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <IconUpload size={16} />
      </Button>
      <PersonImport
        opened={isOpen}
        onClose={onClose}
        title="Import People"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default PersonImportModal;
