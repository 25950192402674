import { DataTypeEnum } from '@btrway/api-core';
import { TaskProperties, TaskTypeEnum } from '@btrway/api-workflow';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { taskModalAtom } from '../atoms/taskModalAtom';

export const useTaskModal = () => {
  const [modalState, setModalState] = useAtom(taskModalAtom);
  const resetModalState = useResetAtom(taskModalAtom);

  const openTask = useCallback(
    (params: {
      taskProperties: TaskProperties;
      taskInstanceId?: number;
      assignedEntityType?: DataTypeEnum;
      assignedEntityId?: number;
      workgroupId?: number;
      derivedWorkflowKey?: string;
      taskKey?: string;
      taskType?: TaskTypeEnum;
    }) => {
      setModalState({
        isOpen: true,
        taskProperties: params.taskProperties,
        taskInstanceId: params.taskInstanceId,
        assignedEntityType: params.assignedEntityType,
        assignedEntityId: params.assignedEntityId,
        workgroupId: params.workgroupId,
        derivedWorkflowKey: params.derivedWorkflowKey,
        taskKey: params.taskKey,
        taskType: params.taskType,
      });
    },
    [setModalState]
  );
  const closeTask = useCallback(() => {
    resetModalState();
  }, [resetModalState]);

  return {
    modalState,
    openTask,
    closeTask,
  };
};
