import { HeaderLayout } from '@btrway/layout-components';
import { PersonTaskListCardView } from '@btrway/task-grids';
import { Paper, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { useAuthenticatedUser } from '@btrway/current-user';

const StudentDashboardPage: React.FC = () => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();

  if (!currentPerson) {
    return null;
  }

  return (
    <HeaderLayout
      header={<Title order={3}>Hi, {currentPerson.firstName}</Title>}
      headerBg="transparent"
      subtitleComponent={
        <Text fz={14} c="dimmed">
          Your tasks
        </Text>
      }
    >
      <Stack gap="lg">
        {/* Current Person's Tasks */}
        <Paper shadow="xs" p="md">
          <Stack gap="md">
            <Title order={4}>My Tasks</Title>
            <PersonTaskListCardView personId={currentPerson.id} />
          </Stack>
        </Paper>
      </Stack>
    </HeaderLayout>
  );
};

export default StudentDashboardPage;
