import { useWorkflowTemplateAutosave } from '@btrway/workflow-manager';
import React from 'react';

interface WorkflowAutosaverProps {}

const WorkflowAutosaver: React.FC<WorkflowAutosaverProps> = () => {
  useWorkflowTemplateAutosave();

  return <></>;
};

export default WorkflowAutosaver;
