import { useMemo } from 'react';
import { ProcessedRouteConfig, RouteConfig } from '../types/route';

export const useRouteGeneration = (
  routes: RouteConfig[],
  userPermissions: Set<string>,
  personId?: number,
  organizationId?: number
) => {
  const hasRequiredPermissions = useMemo(() => {
    return (route: RouteConfig): boolean => {
      if (!route.permissions || route.permissions.length === 0) {
        return true;
      }
      return route.permissions.some((permission) =>
        userPermissions.has(permission)
      );
    };
  }, [userPermissions]);

  const findFirstAccessibleRoute = useMemo(() => {
    return (
      routesToSearch: RouteConfig[],
      parentPath: string = ''
    ): string | undefined => {
      for (const route of routesToSearch) {
        if (route.path.includes(':')) continue;
        if (!hasRequiredPermissions(route)) continue;

        if (route.path === '/') {
          const firstChildPath = route.children
            ? findFirstAccessibleRoute(route.children, '')
            : undefined;
          if (firstChildPath) return firstChildPath;
        }

        if (route.navLevel === 'primary') {
          return `${parentPath}${route.path}`.replace('//', '/');
        }

        if (route.children?.length) {
          const firstChildPath = findFirstAccessibleRoute(
            route.children,
            `${parentPath}${route.path}/`
          );
          if (firstChildPath) return firstChildPath;
        }
      }
      return undefined;
    };
  }, [hasRequiredPermissions]);

  const findFirstAccessibleChildRoute = useMemo(() => {
    return (route: RouteConfig): string | undefined => {
      if (!route.children?.length) return undefined;

      const secondaryNavItems = route.children.filter(
        (child) =>
          child.navLevel === 'secondary' && hasRequiredPermissions(child)
      );

      if (!secondaryNavItems.length) return undefined;
      return secondaryNavItems[0].path;
    };
  }, [hasRequiredPermissions]);

  const processRouteConfigs = useMemo(() => {
    return (routesToProcess: RouteConfig[]): ProcessedRouteConfig[] => {
      return routesToProcess
        .map((route) => {
          const hasPermission = hasRequiredPermissions(route);
          if (!hasPermission) {
            return undefined;
          }

          const firstAccessibleChild = route.children
            ? findFirstAccessibleChildRoute(route)
            : undefined;

          const childRoutes = route.children
            ? processRouteConfigs(route.children)
            : undefined;

          return {
            ...route,
            firstAccessibleChild,
            childRoutes,
            hasPermission: true,
          } as ProcessedRouteConfig;
        })
        .filter((route): route is ProcessedRouteConfig => route !== undefined);
    };
  }, [hasRequiredPermissions, findFirstAccessibleChildRoute]);

  // Memoize the entire output based on all dependencies
  return useMemo(
    () => ({
      firstAccessibleRoute: findFirstAccessibleRoute(routes),
      processRouteConfigs: () => processRouteConfigs(routes),
      hasRequiredPermissions,
    }),
    [
      routes,
      findFirstAccessibleRoute,
      processRouteConfigs,
      hasRequiredPermissions,
      personId,
      organizationId,
    ]
  );
};
