import { CourseTaskProperties } from '@btrway/api-workflow';
import { CourseLanding, CourseViewer } from '@btrway/courseware-course-viewer';
import { useAsset } from '@btrway/courseware-manager';
import { Stack } from '@mantine/core';
import React, { useCallback, useState } from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const CourseTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  onClose,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [shouldResume, setShouldResume] = useState(false);
  const { completeTask } = useTaskActionContext();

  const courseTaskProperties = taskProperties as CourseTaskProperties;
  const courseUid = courseTaskProperties.courseSelection.courseUid.value;

  const { asset: course, isLoading: isAssetLoading } = useAsset(courseUid || 0);

  const handlePlay = (resume: boolean) => {
    setIsPlaying(true);
    setShouldResume(resume);
  };

  const handleCourseComplete = useCallback(async () => {
    console.log('CourseTask handleCourseComplete');
    try {
      await completeTask('Course completed successfully');
      onClose();
    } catch (error) {
      console.error('Failed to complete task:', error);
    }
  }, [completeTask, onClose]);

  if (isAssetLoading || !course || !courseUid) {
    return <div>Loading...</div>;
  }

  return (
    <Stack style={{ height: '100%', backgroundColor: 'black' }} gap={0}>
      {isPlaying ? (
        <CourseViewer
          course={course}
          resume={shouldResume}
          onBack={onClose}
          onCourseComplete={handleCourseComplete}
        />
      ) : (
        <CourseLanding
          courseUid={courseUid}
          onPlay={handlePlay}
          onClose={onClose}
        />
      )}
    </Stack>
  );
};

export default CourseTask;
