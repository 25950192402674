import { WorkgroupTypeResponse } from '@btrway/api-core';
import { useWorkgroupType, useWorkgroupTypes } from '@btrway/workgroup-manager';
import {
  Button,
  Card,
  Group,
  MultiSelect,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';

interface WorkgroupTypeCardProps {
  workgroupType: WorkgroupTypeResponse;
  allWorkgroupTypes: WorkgroupTypeResponse[];
}

const WorkgroupTypeCard: React.FC<WorkgroupTypeCardProps> = ({
  workgroupType,
  allWorkgroupTypes,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(workgroupType.name);
  const [selectedChildTypes, setSelectedChildTypes] = useState(
    workgroupType.childWorkgroupTypes?.map((child) => child.id.toString()) || []
  );

  const { updateWorkgroupType } = useWorkgroupType(workgroupType.id);
  const { deleteWorkgroupType } = useWorkgroupTypes(
    workgroupType.organizationId
  );

  const handleSave = async () => {
    await updateWorkgroupType({
      name,
      childWorkgroupTypeIds: selectedChildTypes.map(Number),
    });
    setIsEditing(false);
  };

  const handleDelete = async () => {
    await deleteWorkgroupType(workgroupType.id);
  };

  return (
    <Card shadow="xs" padding="md" h="auto" w={300} bg="gray.0">
      <Stack gap={10} p={0}>
        <Group justify="space-between">
          {isEditing ? (
            <TextInput
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
              placeholder="Workgroup Type Name"
            />
          ) : (
            <Text size="sm" fw={600}>
              {workgroupType.name}
            </Text>
          )}
          <Group>
            <Button
              variant="subtle"
              size="xs"
              onClick={() => setIsEditing(!isEditing)}
            >
              {isEditing ? 'Cancel' : <IconEdit size={16} />}
            </Button>
            <Button
              variant="subtle"
              color="red"
              size="xs"
              onClick={handleDelete}
            >
              <IconTrash size={16} />
            </Button>
          </Group>
        </Group>

        {isEditing ? (
          <>
            <MultiSelect
              data={allWorkgroupTypes.map((type) => ({
                value: type.id.toString(),
                label: type.name,
              }))}
              value={selectedChildTypes}
              onChange={setSelectedChildTypes}
              placeholder="Select child workgroup types"
            />
            <Button onClick={handleSave}>Save</Button>
          </>
        ) : (
          workgroupType.childWorkgroupTypes &&
          workgroupType.childWorkgroupTypes.length > 0 && (
            <Stack gap="xs">
              <Text size="xs" fw={500} c="dimmed">
                Child Workgroup Types:
              </Text>
              {workgroupType.childWorkgroupTypes.map((child) => (
                <Text key={child.id} size="xs">
                  {child.name}
                </Text>
              ))}
            </Stack>
          )
        )}
      </Stack>
    </Card>
  );
};

export default WorkgroupTypeCard;

// {workgroupType.properties?.map((property) => (
//   <WorkgroupPropertyCard key={property.key} property={property} />
// ))}
