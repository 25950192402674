// hooks/useEventHandlers.ts
import { EventTypeEnum } from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { useCallback } from 'react';

interface UseEventHandlersProps {
  reset: () => void;
  openScreen: (screen: any, options?: any) => void;
  getEventType: (eventType: EventTypeEnum) => any;
  createEventConfig: (eventType: EventTypeEnum) => any;
  parentWorkflow: CommonWorkflow | null;
}

export const useEventHandlers = ({
  reset,
  openScreen,
  getEventType,
  createEventConfig,
  parentWorkflow,
}: UseEventHandlersProps) => {
  const handleEventTypeSelect = useCallback(
    (eventType: EventTypeEnum) => {
      reset();

      const eventTypeDetails = getEventType(eventType);
      const newEvent = createEventConfig(eventType);
      newEvent.eventKey = uuid();

      if (eventTypeDetails) {
        newEvent.title = eventTypeDetails.name;
      }

      openScreen({
        type: 'EVENT_CONFIG',
        data: {
          config: newEvent,
          workflow: parentWorkflow!,
          isNew: true,
        },
      });
    },
    [reset, getEventType, createEventConfig, openScreen, parentWorkflow]
  );

  return {
    handleEventTypeSelect,
  };
};
