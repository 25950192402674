import {
  useSaveWorkflowTemplate,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
} from '@btrway/api-workflow';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { workflowTemplateAtom } from '../atoms/workflowTemplateAtoms';
import {
  removeStepTypeFromSteps,
  updateStepsWithStepTypes,
} from '../utils/stepTypeUtils';
import { convertToWorkflowTemplateRequest } from '../utils/workflowTemplateUtils';
import { useStepTypes } from './useStepTypes';

export const useWorkflowTemplate = () => {
  const [workflowTemplate, setWorkflowTemplate] = useAtom(workflowTemplateAtom);

  const { mutateAsync: saveTemplate } = useSaveWorkflowTemplate();
  const { getStepType } = useStepTypes();

  const updateWorkflowTemplate = useCallback(
    (
      update: Partial<WorkflowTemplateRequest>
    ): WorkflowTemplateResponse | null => {
      if (!workflowTemplate) {
        console.error('No workflow template to update');
        return null;
      }
      const updatedTemplate = {
        ...workflowTemplate,
        ...update,
      } as WorkflowTemplateResponse;

      setWorkflowTemplate(updatedTemplate);
      return updatedTemplate;
    },
    [workflowTemplate, setWorkflowTemplate]
  );

  const saveWorkflowTemplate = useCallback(
    async (
      partialUpdate?: Partial<WorkflowTemplateRequest>
    ): Promise<WorkflowTemplateResponse | null> => {
      if (!workflowTemplate && !partialUpdate) {
        console.error('No workflow template to save');
        return null;
      }

      try {
        // Convert the current workflowTemplate to WorkflowTemplateRequest, or use an empty object if undefined
        const currentTemplateRequest = workflowTemplate
          ? convertToWorkflowTemplateRequest(workflowTemplate)
          : ({} as WorkflowTemplateRequest);

        // Merge the partial update with the converted current template
        const mergedTemplate: WorkflowTemplateRequest = {
          ...currentTemplateRequest,
          ...partialUpdate,
          workflowConfiguration: {
            ...currentTemplateRequest.workflowConfiguration,
            ...partialUpdate?.workflowConfiguration,
            steps:
              partialUpdate?.workflowConfiguration?.steps ||
              currentTemplateRequest.workflowConfiguration?.steps,
          },
        };

        // Remove stepType from steps
        if (mergedTemplate.workflowConfiguration?.steps) {
          mergedTemplate.workflowConfiguration.steps = removeStepTypeFromSteps(
            mergedTemplate.workflowConfiguration.steps
          );
        }

        const savedTemplate = (await saveTemplate({
          data: mergedTemplate,
        })) as WorkflowTemplateResponse;

        if (savedTemplate) {
          // Update the steps with step types before setting the workflowTemplate
          const updatedTemplate = {
            ...savedTemplate,
            workflowConfiguration: savedTemplate.workflowConfiguration
              ? {
                  ...savedTemplate.workflowConfiguration,
                  steps: updateStepsWithStepTypes(
                    savedTemplate.workflowConfiguration.steps || [],
                    getStepType
                  ),
                }
              : undefined,
          };
          setWorkflowTemplate(updatedTemplate);
        }
        return savedTemplate;
      } catch (err) {
        console.error('Failed to save workflow template:', err);
        return null;
      }
    },
    [workflowTemplate, saveTemplate, setWorkflowTemplate, getStepType]
  );

  return {
    workflowTemplate,
    updateWorkflowTemplate,
    saveWorkflowTemplate,
  };
};
