import { createContext, useContext } from 'react';
import { useRouteGeneration } from '../hooks/useRouteGeneration';
import { RouteConfig } from '../types/route';
import { useAuthenticatedUser } from '@btrway/current-user';

interface RouteContextValue {
  childRoutes: RouteConfig[];
  currentRoute: RouteConfig;
}

export const RouteContext = createContext<RouteContextValue>({
  childRoutes: [],
  currentRoute: {} as RouteConfig,
});

export const useRouteContext = () => {
  const context = useContext(RouteContext);
  if (!context) {
    throw new Error(
      'useRouteContext must be used within a RouteContextProvider'
    );
  }
  return context;
};

interface RouteContextProviderProps {
  childRoutes: RouteConfig[];
  currentRoute: RouteConfig;
  children: React.ReactNode;
}

export const RouteContextProvider: React.FC<RouteContextProviderProps> = ({
  childRoutes,
  currentRoute,
  children,
}) => {
  const { allPermissions } = useAuthenticatedUser();
  const { hasRequiredPermissions } = useRouteGeneration(
    childRoutes,
    allPermissions
  );

  // Filter child routes based on permissions
  const filteredChildRoutes = childRoutes.filter(hasRequiredPermissions);

  return (
    <RouteContext.Provider
      value={{
        childRoutes: filteredChildRoutes,
        currentRoute,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};
