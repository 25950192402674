import {
  ActivityResponse,
  DataTypeEnum,
  useGetActivitiesByOrganizationAndEntitiesWithChildren,
} from '@btrway/api-activities';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useEffect, useState } from 'react';

export const useActivities = (entityType: DataTypeEnum, entityId: number) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [activities, setActivities] = useState<ActivityResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { mutate: getActivities } =
    useGetActivitiesByOrganizationAndEntitiesWithChildren();

  const organizationId = currentOrganization.id;

  useEffect(() => {
    setIsLoading(true);
    getActivities(
      {
        organizationId,
        data: [{ entityType, entityId }],
      },
      {
        onSuccess: (data) => {
          setActivities(data);
          setIsLoading(false);
        },
        onError: (error) => {
          setError(error as Error);
          setIsLoading(false);
        },
      }
    );
  }, [organizationId, entityType, entityId]);

  return { activities, isLoading, error };
};
