import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { Badge, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './WorkflowDefinitionCard.module.css';

interface WorkflowDefinitionCardProps {
  data: WorkflowDefinitionResponse;
  className?: string;
  onClick?: () => void;
}

const WorkflowDefinitionCard: React.FC<WorkflowDefinitionCardProps> = ({
  data: workflow,
  className,
  onClick,
}) => {
  const navigate = useNavigate();

  const handleWorkflowClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(`${workflow.workflowKey}`);
    }
  };

  return (
    <Card
      p="md"
      withBorder={true}
      shadow="sm"
      mih="150px"
      className={`${classes.card} ${className}`}
      onClick={handleWorkflowClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <Stack gap={10}>
        <Text fw={600} size="sm">
          {workflow.name || workflow.workflowTemplate?.name}
        </Text>

        <Group>
          {workflow.workflowTemplate && (
            <Badge size="sm" variant="filled" color="red">
              BetterWay Provided
            </Badge>
          )}
          <Badge
            size="sm"
            variant="filled"
            color={workflow.published ? 'green' : 'yellow'}
          >
            {workflow.published ? 'Published' : 'Draft'}
          </Badge>
        </Group>

        <Text mt="sm" c="dimmed" fz="xs">
          {workflow.description || 'No description available'}
        </Text>
      </Stack>

      <Group
        px="xs"
        w="100%"
        justify="space-between"
        style={{ position: 'absolute', bottom: 5, left: 0 }}
      >
        <Text size="xs">Type: {workflow.workflowType}</Text>
        <Text size="xs">ID: {workflow.id}</Text>
      </Group>
    </Card>
  );
};

export default WorkflowDefinitionCard;
