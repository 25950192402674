import {
  AssetRequest,
  AssetResponse,
  useGetAssetById,
  usePublishAsset,
  useSaveAsset,
} from '@btrway/api-courseware';
import { StorageUsageEnum } from '@btrway/api-storage';
import { ChapterBuilder, useChapterBuilder } from '@btrway/course-builder';
import { mapAssetResponseToRequest } from '@btrway/courseware-administration-manager';
import { EditableImage } from '@btrway/file-storage-components';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { HeaderLayout } from '@btrway/layout-components';
import { SaveButton } from '@btrway/shared-components';
import {
  Box,
  Button,
  Card,
  Group,
  Stack,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CoursePage: React.FC = () => {
  useAddHelpTags(['course']);

  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { data: assetResponse, isLoading } = useGetAssetById(
    parseInt(courseId!, 10)
  );
  const { mutateAsync: saveCourse } = useSaveAsset();
  const { mutateAsync: publishCourse } = usePublishAsset();
  const [course, setCourse] = useState<AssetRequest | null>(null);
  const { getChapters } = useChapterBuilder();

  useEffect(() => {
    if (assetResponse) {
      setCourse(mapAssetResponseToRequest(assetResponse));
    }
  }, [assetResponse]);

  if (isLoading || !course) {
    return <div>Loading...</div>;
  }

  const handleSave = async () => {
    if (course) {
      try {
        const chaptersUpdates = getChapters();
        const updatedCourse = {
          ...course,
          chapters: getChapters(),
        };
        const response = (await saveCourse({
          data: updatedCourse,
        })) as AssetResponse;
        // Update URL if the ID has changed
        if (response.id !== parseInt(courseId!, 10)) {
          navigate(`/app-internal/courseware/courses/${response.id}`, {
            replace: true,
          });
        }
        // Update the course state with the response
        setCourse(mapAssetResponseToRequest(response));
      } catch (error) {
        console.error('Error saving course:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handlePublish = async () => {
    if (course && course.id) {
      try {
        const response = (await publishCourse({
          assetId: course.id,
        })) as AssetResponse;
        setCourse(mapAssetResponseToRequest(response));
      } catch (error) {
        console.error('Error publishing course:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };

  const handleImageUpload = (imageKey: string) => {
    setCourse((prevCourse) => {
      if (!prevCourse) return null;
      return {
        ...prevCourse,
        metadata: {
          ...prevCourse.metadata,
          imageKey: imageKey,
        },
      };
    });
  };

  return (
    <HeaderLayout
      header={course.title}
      rightComponent={
        <Group justify="flex-end" mb="md">
          <SaveButton onSave={handleSave}>Save</SaveButton>
          {!course.published && (
            <Button onClick={handlePublish}>Publish</Button>
          )}
        </Group>
      }
    >
      <div style={{ flex: '1' }}>
        <Stack gap="md">
          <Card withBorder radius="md">
            <Group justify="space-between" wrap="nowrap">
              <Stack gap="sm" w="100%">
                <Group justify="space-between" wrap="nowrap">
                  <Text size="lg" fw={600}>
                    Course Information
                  </Text>
                </Group>
                <TextInput
                  label="Title"
                  value={course.title}
                  onChange={(event) =>
                    setCourse({ ...course, title: event.currentTarget.value })
                  }
                  mb="md"
                />

                <Textarea
                  label="Description"
                  value={course.metadata?.description || ''}
                  onChange={(event) =>
                    setCourse({
                      ...course,
                      metadata: {
                        ...course.metadata,
                        description: event.currentTarget.value,
                      },
                    })
                  }
                  mb="md"
                  rows={3}
                />
              </Stack>
              <Box w={300}>
                <EditableImage
                  storageUsage={StorageUsageEnum.course}
                  initialImageKey={course.metadata?.imageKey}
                  onImageUpload={handleImageUpload}
                  editButtonLabel="Edit Course Image"
                  modalTitle="Upload Course Image"
                  aspectRatio={{ width: 16, height: 9 }}
                />
              </Box>
            </Group>
          </Card>
          <ChapterBuilder initialChapters={course.chapters} />
        </Stack>
      </div>
    </HeaderLayout>
  );
};

export default CoursePage;
