import { AuthLoginRequest, useAuth } from '@btrway/auth-core';
import {
  Button,
  Divider,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { IconBrandGoogle } from '@tabler/icons-react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRegistrationAuth } from '../../hooks/useRegistrationAuth';
import { useAuthenticatedUser, useCurrentUser } from '@btrway/current-user';
import { getGetCurrentUserQueryKey } from '@btrway/api-core';
import { useAPIClient } from '@btrway/api-client-provider';

interface LoginFormProps {
  onSuccess: (userData: any) => void;
  onCreateAccount: () => void;
}

export function LoginForm({ onSuccess, onCreateAccount }: LoginFormProps) {
  const { registrationLoginWithCredentials, registrationLoginWithOAuth, clearTempToken } = useRegistrationAuth();
  const [error, setError] = useState<string | null>(null);
  const { currentUser, refetchCurrentUser } = useCurrentUser();
  const { queryClient } = useAPIClient();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AuthLoginRequest>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (data: AuthLoginRequest) => {
    try {
      const result = await registrationLoginWithCredentials(data);

      if (result.success) {
        await clearTempToken();
        // First invalidate the current user query
        await queryClient.invalidateQueries({ 
          queryKey: getGetCurrentUserQueryKey() 
        });

        // Then refetch with await to ensure we get fresh data
        const { data: freshUserData } = await refetchCurrentUser();

        if (freshUserData) {
          onSuccess({
            firstName: freshUserData.user.firstName,
            lastName: freshUserData.user.lastName,
            email: freshUserData.user.email,
            phoneNumber: freshUserData.user.mobilePhone,
          });
        } else {
          setError('Unable to retrieve user data');
          console.error('User data not available after refetch');
        }
      } else {
        setError(result.error || 'Invalid email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      // await registrationLoginWithOAuth('google');

      await clearTempToken();
      
      // Invalidate and refetch after OAuth login
      await queryClient.invalidateQueries({ 
        queryKey: getGetCurrentUserQueryKey() 
      });

      const { data: freshUserData } = await refetchCurrentUser();

      if (freshUserData) {
        onSuccess({
          firstName: freshUserData.user.firstName,
          lastName: freshUserData.user.lastName,
          email: freshUserData.user.email,
          phoneNumber: freshUserData.user.mobilePhone,
        });
      } else {
        setError('Unable to retrieve user data after Google login');
      }
    } catch (error) {
      console.error('Google login error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Stack gap="md">
      <Button
        leftSection={<IconBrandGoogle />}
        variant="default"
        onClick={handleGoogleLogin}
        disabled={isSubmitting}
        size="lg"
      >
        Continue with Google
      </Button>

      <Divider label="or use email" labelPosition="center" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap="md">
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <TextInput
                label="Email"
                placeholder="Your email"
                {...field}
                error={errors.email?.message}
                disabled={isSubmitting}
                size="md"
              />
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: 'Password is required' }}
            render={({ field }) => (
              <PasswordInput
                label="Password"
                placeholder="Your password"
                {...field}
                error={errors.password?.message}
                disabled={isSubmitting}
                size="md"
              />
            )}
          />

          {error && (
            <Text color="red" size="sm">
              {error}
            </Text>
          )}

          <Button type="submit" fullWidth loading={isSubmitting} size="lg">
            Sign In
          </Button>
        </Stack>
      </form>

      <Group gap="xs" justify="center">
        <Text size="sm" c="dimmed">
          Don't have an account?
        </Text>
        <Button variant="subtle" size="sm" onClick={onCreateAccount}>
          Create one now
        </Button>
      </Group>
    </Stack>
  );
}
