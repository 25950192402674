import {
  EventReminderRequest,
  EventReminderResponse,
  EventRequest,
  EventResponse,
  EventTargetRequest,
  EventTargetResponse,
} from '@btrway/api-calendar';

export const convertEventResponseToRequest = (
  eventResponse: EventResponse
): EventRequest => {
  const {
    id,
    name,
    description,
    startTime,
    endTime,
    location,
    allDayEvent,
    isPublic,
    isRecurrenceParent,
    isRecurrenceException,
    organizationId,
    workgroupId,
    storageKeys,
    ownerPersonId,
    eventType,
    timeZoneName,
    recurrenceRule,
    parentEventId,
  } = eventResponse;

  // Convert event targets
  const eventTargets =
    eventResponse.eventTargets?.map(convertEventTargetToRequest) || [];

  // Convert event reminders
  const eventReminders =
    eventResponse.eventReminders?.map(convertEventReminderToRequest) || [];

  return {
    id,
    name,
    description,
    startTime,
    endTime,
    location,
    allDayEvent,
    isPublic,
    isRecurrenceParent,
    isRecurrenceException,
    organizationId,
    workgroupId,
    storageKeys,
    ownerPersonId,
    eventTypeId: eventType.id,
    timeZoneName,
    recurrenceRule,
    parentEventId,
    eventTargets,
    eventReminders,
  };
};

export const convertEventTargetToRequest = (
  targetResponse: EventTargetResponse
): EventTargetRequest => {
  return {
    id: targetResponse.id,
    entityId: targetResponse.entityId || 0,
    entityType: targetResponse.entityType,
    eventId: targetResponse.eventId,
    organizationId: targetResponse.organizationId,
  };
};

export const convertEventReminderToRequest = (
  reminderResponse: EventReminderResponse
): EventReminderRequest => {
  return {
    id: reminderResponse.id,
    reminderTime: reminderResponse.reminderTime,
    reminderType: reminderResponse.reminderType,
    event: convertEventResponseToRequest(reminderResponse.event),
  };
};
