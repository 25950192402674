import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import ToDosView from '../../components/ToDosView/ToDosView';
import styles from './ToDosPage.module.css';

const ToDosPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <ToDosView />
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </Stack>
  );
};

export default ToDosPage;
