import { Box, Drawer, Text } from '@mantine/core';
import { IconArrowBarToRight } from '@tabler/icons-react';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { footerAtom, headerAtom } from '../atoms/componentAtoms';

interface ScrollableDrawerProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export function ScrollableDrawer({
  opened,
  onClose,
  title,
  children,
}: ScrollableDrawerProps) {
  const footer = useAtomValue(footerAtom);
  const header = useAtomValue(headerAtom);

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      offset={8}
      radius="md"
      position="right"
      size="lg"
      styles={{
        root: { overflow: 'hidden' },
        overlay: { overflow: 'hidden' },
        inner: { overflow: 'hidden' },
        content: {
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
        header: { display: 'none' },
        body: {
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box
        style={{
          height: '60px',
          minHeight: '60px',
          borderBottom: '1px solid var(--mantine-color-gray-3)',
          display: 'flex',
          alignItems: 'center',
          padding: '0 var(--mantine-spacing-md)',
          gap: '16px',
        }}
      >
        <IconArrowBarToRight
          size={24}
          style={{ cursor: 'pointer', flexShrink: 0 }}
          onClick={onClose}
        />
        <Text fz="lg" fw={600} style={{ flexGrow: 1, lineHeight: 1 }}>
          {title}
        </Text>
        <Box style={{ flexShrink: 0 }}>{header}</Box>
      </Box>
      <Box
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '16px',
          paddingBottom: '80px',
        }}
        bg="gray.2"
      >
        {children}
      </Box>
      {footer && (
        <Box
          style={{
            borderTop: '1px solid var(--mantine-color-gray-3)',
            padding: '16px',
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100%',
            zIndex: 1000,
          }}
          bg="gray.2"
        >
          {footer}
        </Box>
      )}
    </Drawer>
  );
}
