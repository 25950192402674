import { useMemo } from 'react';
import { useImpersonation } from '../providers/ImpersonationProvider';

export const useAuthImpersonation = () => {
  const { startOrgImpersonation, startPersonImpersonation } =
    useImpersonation();

  const requestOrgAccessToken = async (
    organizationId: number
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      await startOrgImpersonation(organizationId);
      return { success: true };
    } catch (error) {
      console.error('Error starting org impersonation:', error);
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred during org impersonation',
      };
    }
  };

  const requestPersonAccessToken = async (
    personId: number,
    organizationId: number,
    email: string
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      await startPersonImpersonation(personId, organizationId, email);
      return { success: true };
    } catch (error) {
      console.error('Error starting person impersonation:', error);
      return {
        success: false,
        error:
          error instanceof Error
            ? error.message
            : 'An error occurred during person impersonation',
      };
    }
  };

  return useMemo(
    () => ({
      requestOrgAccessToken,
      requestPersonAccessToken,
    }),
    []
  );
};
