import { AssetResponse } from '@btrway/api-courseware';
import { Card, Group, Text } from '@mantine/core';
import React, { useState } from 'react';
import classes from './CourseCardSmall.module.css';

interface CourseCardSmallProps {
  course: AssetResponse;
}

const CourseCardSmall: React.FC<CourseCardSmallProps> = ({ course }) => {
  const { title, metadata } = course;
  const [imageLoaded, setImageLoaded] = useState(false);

  // const imageURL = imagePath
  //   ? `https://dfcu4ujunrrq2.cloudfront.net/course_assets/${imagePath}`
  //   : ''; // Consider adding a placeholder image URL here

  return (
    // <Link
    //   to={`curriculum/${course.curriculum?.id}/course/${course.id}`}
    //   style={{ textDecoration: 'none' }}
    // >
    <Card
      withBorder
      padding={'sm'}
      bg="gray.0"
      className={classes.card}
      style={{ width: 200, height: 220 }}
    >
      <Card.Section withBorder p={0} h={121}>
        {/* {course.imagePath && (
            <Image
              src={imageURL}
              alt={course.title}
              // w="auto"
              h={120}
              // fit="fill"
              onLoad={() => setImageLoaded(true)}
              style={{ display: imageLoaded ? 'initial' : 'none' }}
              mb={0}
            />
          )}
          {!imageLoaded && (
            <div
              style={{
                width: 200,
                height: 120, // Adjust height as needed
                backgroundColor: '#f0f0f0', // Placeholder color
              }}
            />
          )} */}
      </Card.Section>

      <Group justify="space-between" mt="xs">
        <Text fz="sm" fw={700} className={classes.title} h={40}>
          {course.title}
        </Text>
        {/* {(personCourse.completionPercent || 0) > 0 && (
            <Group gap={5}>
              <Text fz="xs" c="dimmed">
                {(personCourse.completionPercent || 0).toFixed(0)}% completed
              </Text>
              <RingProgress
                size={18}
                thickness={2}
                sections={[
                  {
                    value: personCourse.completionPercent || 0,
                    color: 'blue',
                  },
                ]}
              />
            </Group>
          )} */}
      </Group>
      {/* <Text mt="sm" mb="md" c="dimmed" fz="xs">
          {course.description}
        </Text> */}
      {/* <Card.Section className={classes.footer}></Card.Section> */}
    </Card>
    // </Link>
  );
};

export default CourseCardSmall;
