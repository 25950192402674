/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetBlobParams,
  GetPresignedUrlParams,
  StorageUploadRequest,
  StorageUploadResponse,
} from './models';
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const uploadFile = (
  storageUploadRequest: StorageUploadRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<StorageUploadResponse>(
    {
      url: `/api/client-storage/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: storageUploadRequest,
    },
    options
  );
};

export const getUploadFileMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: StorageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadFile>>,
  TError,
  { data: StorageUploadRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadFile>>,
    { data: StorageUploadRequest }
  > = (props) => {
    const { data } = props ?? {};

    return uploadFile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadFile>>
>;
export type UploadFileMutationBody = StorageUploadRequest;
export type UploadFileMutationError = unknown;

export const useUploadFile = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadFile>>,
    TError,
    { data: StorageUploadRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadFile>>,
  TError,
  { data: StorageUploadRequest },
  TContext
> => {
  const mutationOptions = getUploadFileMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getPresignedUrl = (
  storageUsageEnum: string,
  params: GetPresignedUrlParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/client-storage/${storageUsageEnum}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetPresignedUrlQueryKey = (
  storageUsageEnum: string,
  params: GetPresignedUrlParams
) => {
  return [
    `/api/client-storage/${storageUsageEnum}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetPresignedUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getPresignedUrl>>,
  TError = unknown
>(
  storageUsageEnum: string,
  params: GetPresignedUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPresignedUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPresignedUrlQueryKey(storageUsageEnum, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPresignedUrl>>> = ({
    signal,
  }) => getPresignedUrl(storageUsageEnum, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!storageUsageEnum,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPresignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPresignedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPresignedUrl>>
>;
export type GetPresignedUrlQueryError = unknown;

export const useGetPresignedUrl = <
  TData = Awaited<ReturnType<typeof getPresignedUrl>>,
  TError = unknown
>(
  storageUsageEnum: string,
  params: GetPresignedUrlParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPresignedUrl>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPresignedUrlQueryOptions(
    storageUsageEnum,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBlob = (
  storageUsageEnum: string,
  params: GetBlobParams,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<string>(
    {
      url: `/api/client-storage/blob/${storageUsageEnum}`,
      method: 'GET',
      params,
      signal,
    },
    options
  );
};

export const getGetBlobQueryKey = (
  storageUsageEnum: string,
  params: GetBlobParams
) => {
  return [
    `/api/client-storage/blob/${storageUsageEnum}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetBlobQueryOptions = <
  TData = Awaited<ReturnType<typeof getBlob>>,
  TError = unknown
>(
  storageUsageEnum: string,
  params: GetBlobParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBlob>>, TError, TData>;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBlobQueryKey(storageUsageEnum, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBlob>>> = ({
    signal,
  }) => getBlob(storageUsageEnum, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!storageUsageEnum,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getBlob>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetBlobQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBlob>>
>;
export type GetBlobQueryError = unknown;

export const useGetBlob = <
  TData = Awaited<ReturnType<typeof getBlob>>,
  TError = unknown
>(
  storageUsageEnum: string,
  params: GetBlobParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getBlob>>, TError, TData>;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBlobQueryOptions(
    storageUsageEnum,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
