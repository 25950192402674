import { useAuth } from '@btrway/auth-core';
import { Box, Button, Space, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export function PasswordRecoveryForm() {
  const [message, setMessage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
  });
  const { sendPasswordResetRequest } = useAuth();
  const onSubmit = async (data: { email: string }) => {
    try {
      const response = await sendPasswordResetRequest(data.email);
      if (response.success) {
        setMessage('Password reset link has been sent to your email.');
      } else {
        console.error(response.error);
        setMessage('Error sending email');
      }
    } catch (error) {
      console.error(error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      style={{
        maxWidth: 740,
        backgroundColor: 'white',
        padding: '40px',
        margin: 'auto',
      }}
    >
      <Text size="xl" fw={700} ta="center">
        Recover Password
      </Text>
      <Space h="xl" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextInput
              label="Email"
              placeholder="Your email"
              {...field}
              error={errors.email?.message}
            />
          )}
        />
        {message && (
          <Text color={message.includes('error') ? 'red' : 'green'}>
            {message}
          </Text>
        )}
        <Button type="submit" mt="lg" fullWidth color="#2F667F">
          Send Reset Link
        </Button>
      </form>
    </Box>
  );
}
