import { CertificationInstancesView } from '@btrway/certification-components';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupCertificationsView = () => {
  const { workgroup } = useWorkgroup();

  if (!workgroup?.id) {
    return null;
  }

  return (
    <CertificationInstancesView
      workgroupId={workgroup.id}
      organizationId={workgroup.organizationId}
    />
  );
};

export default WorkgroupCertificationsView;
