import { ChapterProgressResponse } from '@btrway/api-courseware';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { chapterProgressAtom } from './chapterProgressAtom';

export const chapterProgressAtomFamily = atomFamily((chapterKey: string) =>
  atom(
    (get) => {
      const progress = get(chapterProgressAtom);
      return progress.find((chapter) => chapter.chapterKey === chapterKey);
    },
    (get, set, arg: Partial<ChapterProgressResponse>) => {
      const prev = get(chapterProgressAtom);
      const chapterExists = prev.some(
        (chapter) => chapter.chapterKey === chapterKey
      );

      const updatedChapters = chapterExists
        ? prev.map((chapter) =>
            chapter.chapterKey === chapterKey ? { ...chapter, ...arg } : chapter
          )
        : [...prev, { chapterKey, ...arg } as ChapterProgressResponse];

      set(chapterProgressAtom, updatedChapters);
    }
  )
);
