import { TaskDateProperties, TaskListTaskConfig } from '@btrway/api-workflow';
import { Grid, NumberInput, Stack } from '@mantine/core';
import React from 'react';

interface TaskListTaskConfigurationProps {
  config: TaskListTaskConfig;
  onChange: (config: TaskListTaskConfig) => void;
}

export const TaskListTaskConfiguration: React.FC<
  TaskListTaskConfigurationProps
> = ({ config, onChange }) => {
  const handleDateChange = (
    dateField: 'startDate' | 'endDate',
    offsetDays: number | string
  ) => {
    const dateConfig: TaskDateProperties = {
      offsetDays:
        typeof offsetDays === 'string' ? parseInt(offsetDays) : offsetDays,
    };

    onChange({
      ...config,
      [dateField]: dateConfig,
    });
  };

  return (
    <Stack gap="md">
      <Grid>
        <Grid.Col span={6}>
          <NumberInput
            label="Start Day"
            value={config.startDate?.offsetDays || 0}
            onChange={(value) => handleDateChange('startDate', value || 0)}
            min={0}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <NumberInput
            label="End Day"
            value={config.endDate?.offsetDays || 0}
            onChange={(value) => handleDateChange('endDate', value || 0)}
            min={0}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
