import { CertificationConfig } from '@btrway/api-workflow';
import { useWorkflowBuilderState } from '@btrway/workflow-builder-state';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-configuration-manager';
import { DeploymentFilterView } from '@btrway/workflow-template-deployment';
import { ActionIcon, Center, Group, Stack, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import CertificationCard from '../CertificationCard/CertificationCard';
import CertificationEvaluationButton from '../CertificationEvaluationButton/CertificationEvaluationButton';

interface CertificationListViewInnerProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  onCertificationSelected: (certificationKey: string) => void;
  onCertificationDeleted: (certificationKey: string) => void;
  nestedList: boolean;
}

const CertificationListViewInner: React.FC<CertificationListViewInnerProps> = ({
  workflowKey,
  workflow,
  onCertificationSelected,
  onCertificationDeleted,
  nestedList = false,
}) => {
  const [localCertifications, setLocalCertifications] = useState<
    CertificationConfig[]
  >([]);
  const { openInitialScreen, openToolbox } = useWorkflowBuilderState();
  const workflowService = useWorkflowService();

  useEffect(() => {
    if (!workflow) return;

    const workflowCertifications =
      workflow.workflowConfiguration?.certifications ?? [];
    setLocalCertifications(workflowCertifications);
  }, [workflow]);

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow?.workflowType) return;

    openToolbox(
      workflow,
      'certification',
      nestedList ? workflowService : undefined
    );
  };

  const handleCertificationClick = (certification: CertificationConfig) => {
    if (!workflow) return;

    console.log('handleCertificationClick', nestedList);

    openInitialScreen(
      {
        type: 'CERTIFICATION_CONFIG',
        data: {
          config: certification,
          workflow,
          isNew: false,
        },
      },
      undefined,
      nestedList ? workflowService : null,
      nestedList ? workflow : null
    );
    onCertificationSelected(certification.certificationKey);
  };

  if (!workflow) {
    return <Center>No workflow found</Center>;
  }

  return (
    <Center>
      <Stack gap="xs" align="stretch" p="md" w="100%">
        {!nestedList && <DeploymentFilterView workflow={workflow} />}
        <Group justify="flex-end" gap="xs">
          <CertificationEvaluationButton workflowKey={workflowKey} />
          <Tooltip label="Add New Requirement">
            <ActionIcon
              variant="light"
              color="blue"
              size="md"
              radius="xl"
              onClick={handleAddClick}
            >
              <IconPlus size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>

        <Stack gap="lg">
          {localCertifications.map((certification, index) => (
            <CertificationCard
              key={certification.certificationKey}
              workflowKey={workflowKey}
              certificationConfig={certification}
              index={index}
              isLast={index === localCertifications.length - 1}
              onCertificationSelected={(certificationKey) => {
                const cert = localCertifications.find(
                  (c) => c.certificationKey === certificationKey
                );
                if (cert) handleCertificationClick(cert);
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Center>
  );
};

interface CertificationListViewProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  onCertificationSelected: (certificationKey: string) => void;
  onCertificationDeleted: (certificationKey: string) => void;
  nestedList?: boolean;
}

const CertificationListView: React.FC<CertificationListViewProps> = ({
  workflowKey,
  workflow,
  onCertificationSelected,
  onCertificationDeleted,
  nestedList = false,
}) => {
  const workflowService = useWorkflowService();
  const { data: fetchedWorkflow, isLoading } = workflow
    ? { data: workflow, isLoading: false }
    : workflowService.getByKey();

  if (!fetchedWorkflow || isLoading) {
    return null;
  }

  return (
    <CertificationListViewInner
      workflowKey={workflowKey}
      workflow={fetchedWorkflow}
      onCertificationSelected={onCertificationSelected}
      onCertificationDeleted={onCertificationDeleted}
      nestedList={nestedList}
    />
  );
};

export default CertificationListView;
