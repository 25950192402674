import { Box, Chip, Group } from '@mantine/core';
import React from 'react';

interface RelationshipChipSelectorProps {
  value: string;
  onChange: (value: string) => void;
  relationshipTypes: { id: number; name: string }[];
}

const RelationshipChipSelector: React.FC<RelationshipChipSelectorProps> = ({
  value,
  onChange,
  relationshipTypes,
}) => {
  return (
    <Box pt="sm">
      <Chip.Group multiple={false} value={value} onChange={onChange}>
        <Group gap="md">
          {relationshipTypes.map((type) => (
            <Chip key={type.id} value={type.id.toString()}>
              {type.name}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Box>
  );
};

export default RelationshipChipSelector;
