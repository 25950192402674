import { MantineColor } from '@mantine/core';
import { useMemo } from 'react';

const mantineColors: MantineColor[] = [
  'red',
  'pink',
  'grape',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'green',
  'lime',
  'yellow',
  'orange',
  'gray',
  'dark',
  'blue-gray',
  'purple',
  'fuchsia',
  'rose',
  'sky',
  'mint',
];

interface UseCategoryColorsReturn {
  categoryColorMap: Map<string, MantineColor>;
  getCategoryColor: (category: string) => MantineColor;
  allCategoryColors: MantineColor[];
}

export const useCategoryColors = (
  categories: string[]
): UseCategoryColorsReturn => {
  const categoryColorMap = useMemo(() => {
    const map = new Map<string, MantineColor>();
    categories.forEach((category, index) => {
      map.set(category, mantineColors[index % mantineColors.length]);
    });
    return map;
  }, [categories]);

  const getCategoryColor = (category: string): MantineColor => {
    return categoryColorMap.get(category) || 'gray';
  };

  return {
    categoryColorMap,
    getCategoryColor,
    allCategoryColors: mantineColors,
  };
};
