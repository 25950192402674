import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

interface SearchBarProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  onSearchChange,
}) => (
  <TextInput
    placeholder="Search resources..."
    leftSection={<IconSearch size={16} />}
    rightSection={
      searchQuery ? (
        <CloseButton
          onClick={() => onSearchChange('')}
          size="sm"
          aria-label="Clear search"
        />
      ) : null
    }
    value={searchQuery}
    onChange={(e) => onSearchChange(e.currentTarget.value)}
  />
);
