import {
  EventConfig,
  EventTypeEnum,
  TriggerSetConfig,
} from '@btrway/api-workflow';
import { Stack, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import { EventConfigProps } from '../../../types/editors';
import TriggerConfiguration from '../../trigger/TriggerConfiguration/TriggerConfiguration';
import { EventFormConfiguration } from '../EventFormConfiguration/EventFormConfiguration';

export const EventConfiguration: React.FC<EventConfigProps> = ({
  config,
  onConfigChange,
  workflowType,
  workflow,
  pendingWorkflowUpdates,
  onPendingWorkflowUpdates,
}) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const handleEntityNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEntityName = event.currentTarget.value;

    if (onPendingWorkflowUpdates) {
      onPendingWorkflowUpdates({
        ...pendingWorkflowUpdates,
        entityName: newEntityName,
      });
    }
    setHasUnsavedChanges(true);
  };

  const handleTriggerSetsChange = (newTriggerSets: TriggerSetConfig[]) => {
    const updatedConfig: EventConfig = {
      ...config,
      triggerSets: newTriggerSets,
    };
    onConfigChange(updatedConfig);
    setHasUnsavedChanges(true);
  };

  // Use either the pending update value or the current workflow value
  const currentEntityName =
    (pendingWorkflowUpdates?.entityName !== undefined
      ? pendingWorkflowUpdates.entityName
      : workflow?.entityName) || 'Thing the Automation is About';

  // Render event type specific configuration
  const renderEventTypeConfig = () => {
    switch (config.eventTypeCode) {
      case EventTypeEnum.formSubmitted:
        return (
          <EventFormConfiguration
            config={config}
            onConfigChange={onConfigChange}
            workflowType={workflowType}
            workflow={workflow}
            pendingWorkflowUpdates={pendingWorkflowUpdates}
            onPendingWorkflowUpdates={onPendingWorkflowUpdates}
          />
        );
      case EventTypeEnum.personCreated:
      case EventTypeEnum.workgroupMemberCreated:
        return (
          <TextInput
            label="Helpful Description of New Person Added"
            value={currentEntityName}
            onChange={handleEntityNameChange}
            placeholder="Enter a description"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Stack gap="xl">
      {/* Event Type Specific Configuration */}
      {renderEventTypeConfig()}

      {/* Trigger Configuration */}
      <TriggerConfiguration
        initialTriggerSets={config.triggerSets || []}
        onChangesMade={() => setHasUnsavedChanges(true)}
        onTriggerSetsChange={handleTriggerSetsChange}
      />
    </Stack>
  );
};
