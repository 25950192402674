import { useAtom } from 'jotai';
import { workflowStepAtomFamily } from '../atoms/workflowStepAtomFamily';

export const useWorkflowStep = (stepKey: string) => {
  const [step, setStep] = useAtom(workflowStepAtomFamily(stepKey));

  return {
    step,
    setStep,
  };
};
