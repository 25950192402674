import { Card, Text, Button, Badge, Group } from '@mantine/core';
import { IconEye, IconPower } from '@tabler/icons-react';
import { EmailTemplateResponse } from '@btrway/api-messaging';
import styles from './EmailTemplateCard.module.css';

interface EmailTemplateCardProps {
  template: EmailTemplateResponse;
  onPreview: (template: EmailTemplateResponse) => void;
  onTogglePublish: (templateId: number) => Promise<void>;
}

export const EmailTemplateCard: React.FC<EmailTemplateCardProps> = ({
  template,
  onPreview,
  onTogglePublish,
}) => {
  return (
    <Card 
      shadow="sm" 
      padding="lg"
      radius="md"
      withBorder
      className={styles.templateCard}
      w={400} // Fixed width for consistency
    >
      <Card.Section>
        <div className={styles.templatePreviewContainer}>
          <iframe
            srcDoc={template.templateHtml}
            className={styles.templatePreviewIframe}
          />
          <div className={styles.templateHoverOverlay}>
            <Group>
              <Button
                variant="white"
                size="sm"
                leftSection={<IconEye size={16} />}
                onClick={() => onPreview(template)}
              >
                Preview
              </Button>
              <Button
                variant={template.published ? 'red' : 'green'}
                size="sm"
                leftSection={<IconPower size={16} />}
                onClick={() => onTogglePublish(template.id)}
              >
                {template.published ? 'Unpublish' : 'Publish'}
              </Button>
            </Group>
          </div>
        </div>
      </Card.Section>

      <Group justify="space-between" mt="md">
        <Text fw={500}>{template.templateName}</Text>
        <Badge color={template.published ? 'green' : 'gray'}>
          {template.published ? 'Published' : 'Draft'}
        </Badge>
      </Group>
    </Card>
  );
};