import { QuestionDTO } from '@btrway/api-courseware';
import { Anchor, Button, Card, Flex, Stack } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import { useChapter } from '../../hooks/useChapter';
import QuestionCard, { QuestionCardRef } from '../QuestionCard/QuestionCard';

interface QuestionListProps {
  chapterKey: string;
}

const QuestionList: React.FC<QuestionListProps> = ({ chapterKey }) => {
  const { chapter, setChapter } = useChapter(chapterKey);
  const lastQuestionRef = useRef<QuestionCardRef>(null);

  useEffect(() => {
    if (lastQuestionRef.current) {
      lastQuestionRef.current.focus();
    }
  }, [chapter?.questions?.length]);

  if (!chapter) return null;

  const handleAddQuestion = () => {
    const newQuestion: QuestionDTO = {
      questionValue: '',
      answers: [],
    };
    setChapter({
      ...chapter,
      questions: [...(chapter.questions || []), newQuestion],
    });
  };

  const handleDeleteQuestion = (index: number) => {
    const updatedQuestions = (chapter.questions || []).filter(
      (_, i) => i !== index
    );
    setChapter({ ...chapter, questions: updatedQuestions });
  };

  if (!chapter.questions || chapter.questions.length === 0) {
    return (
      <Card withBorder style={{ height: '100%' }}>
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <Button onClick={handleAddQuestion} variant="subtle">
            Add Quiz
          </Button>
        </Flex>
      </Card>
    );
  }

  return (
    <Stack gap="md">
      {chapter.questions.map((question, index) => (
        <QuestionCard
          key={index}
          ref={
            index === (chapter.questions?.length || 0) - 1
              ? lastQuestionRef
              : null
          }
          chapterKey={chapterKey}
          questionIndex={index}
          onDeleteQuestion={() => handleDeleteQuestion(index)}
        />
      ))}
      <Anchor
        onClick={handleAddQuestion}
        style={{ alignSelf: 'flex-end' }}
        fz="xs"
      >
        Add Question
      </Anchor>
    </Stack>
  );
};

export default QuestionList;
