import { useAddHelpTags } from '@btrway/help-tag-manager';
import { MuxAssetList, MuxVideoUploader } from '@btrway/video-components';
import { Box, Space } from '@mantine/core';

const VideosPage: React.FC = () => {
  useAddHelpTags(['video', 'subtitles']);

  return (
    <Box m="lg">
      <MuxVideoUploader
        onUploadComplete={() => {
          // Here you can trigger a refresh of your MuxAssetList
        }}
      />
      <Space h="xl" />
      <MuxAssetList />
    </Box>
  );
};

export default VideosPage;
