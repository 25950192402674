import { TopNavigationLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import {
  IconCalendar,
  IconClipboard,
  IconForms,
  IconHome,
  IconSettings,
} from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy load components from @btrway/people-components
const PersonProfileHeader = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonProfileHeader,
  }))
);

const PersonProvider = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonProvider,
  }))
);

// Lazy load view components
const PersonDashboardView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonDashboardView,
  }))
);

const PersonScheduleView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonScheduleView,
  }))
);

const PersonTasksView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonTasksView,
  }))
);

const PersonFormsView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonFormsView,
  }))
);

const PersonActivityView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonActivityView,
  }))
);

const PersonLearningView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonLearningView,
  }))
);

const PersonSettingsView = lazy(() =>
  import('@btrway/people-components').then((module) => ({
    default: module.PersonSettingsView,
  }))
);

export const personRoutes: RouteConfig[] = [
  {
    path: 'person/:personId',
    element: (
      <PersonProvider>
        <TopNavigationLayout header={<PersonProfileHeader />} />
      </PersonProvider>
    ),
    children: [
      {
        path: 'dashboard',
        element: <PersonDashboardView />,
        label: 'Dashboard',
        icon: IconHome,
        navLevel: 'secondary',
        displayType: 'icon',
      },
      {
        path: 'schedule',
        element: <PersonScheduleView />,
        label: 'Schedule',
        icon: IconCalendar,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'tasks',
        element: <PersonTasksView />,
        label: 'Tasks',
        icon: IconClipboard,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'forms',
        element: <PersonFormsView />,
        label: 'Forms',
        icon: IconForms,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'activity',
        element: <PersonActivityView />,
        label: 'Activity',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'learning',
        element: <PersonLearningView />,
        label: 'Learning',
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'settings',
        element: <PersonSettingsView />,
        label: 'Settings',
        icon: IconSettings,
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
];
