import { EditorContent } from '../types/editorContent';

export const createEmptyContent = (): EditorContent => ({
  type: 'doc',
  content: [{ type: 'paragraph' }],
});

export const createContentFromText = (text: string): EditorContent => ({
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [{ type: 'text', text }],
    },
  ],
});

export const isEditorContent = (content: unknown): content is EditorContent => {
  if (!content || typeof content !== 'object') return false;
  const doc = content as Partial<EditorContent>;
  return (
    doc.type === 'doc' &&
    Array.isArray(doc.content) &&
    doc.content.every((node) => 'type' in node)
  );
};

export const parseContent = (content: unknown): EditorContent => {
  if (!content) {
    return {
      type: 'doc',
      content: [{ type: 'paragraph' }],
    };
  }

  if (typeof content === 'string') {
    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [{ type: 'text', text: content }],
        },
      ],
    };
  }

  // Handle EditorContent-shaped objects
  if (
    typeof content === 'object' &&
    'type' in content &&
    content.type === 'doc' &&
    Array.isArray((content as any).content)
  ) {
    return content as EditorContent;
  }

  return {
    type: 'doc',
    content: [{ type: 'paragraph' }],
  };
};
