import { HelpRepositoryEnum, HelpResourceResponse } from '@btrway/api-help';
import { useMemo } from 'react';
import { repositoryTabs } from '../config/tabs';

type TabValue = HelpRepositoryEnum | 'selected';
type FilteredResources = Record<TabValue, HelpResourceResponse[]>;

export const useResourceFiltering = (
  allHelpResources: HelpResourceResponse[],
  searchQuery: string,
  selectedResources: Set<string>
) => {
  return useMemo(() => {
    const searchLower = searchQuery.toLowerCase();
    const result = {} as FilteredResources;

    // Add selected resources
    result.selected = allHelpResources
      .filter((resource) => selectedResources.has(resource.helpResourceKey))
      .filter((resource) => matchesSearch(resource, searchLower));

    // Add repository-based resources
    allHelpResources
      .filter((resource) => {
        if (
          !resource.enabled ||
          !resource.published ||
          !repositoryTabs.some((tab) => tab.value === resource.repository)
        ) {
          return false;
        }
        return matchesSearch(resource, searchLower);
      })
      .forEach((resource) => {
        const repo = resource.repository as HelpRepositoryEnum;
        if (!result[repo]) {
          result[repo] = [];
        }
        result[repo].push(resource);
      });

    return result;
  }, [allHelpResources, searchQuery, selectedResources]);
};

const matchesSearch = (resource: HelpResourceResponse, searchLower: string) => {
  if (!searchLower) return true;
  const metadata = resource.metadata;
  if (!metadata) return false;

  return (
    metadata.title?.toLowerCase().includes(searchLower) ||
    metadata.description?.toLowerCase().includes(searchLower) ||
    metadata.category?.toLowerCase().includes(searchLower)
  );
};
