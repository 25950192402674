import {
  ChapterProgressRequest,
  ChapterProgressResponse,
  useSaveChapterProgress,
} from '@btrway/api-courseware';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { chapterProgressAtom } from '../atoms/chapterProgressAtom';

export const useCoursewareProgressUpdate = () => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const setChapterProgress = useSetAtom(chapterProgressAtom);

  const { mutateAsync: saveChapterProgress } = useSaveChapterProgress();

  const updateProgressAtoms = useCallback(
    (updatedProgress: ChapterProgressResponse) => {
      setChapterProgress((prev) => {
        const index = prev.findIndex(
          (p) => p.chapterKey === updatedProgress.chapterKey
        );
        if (index !== -1) {
          return [
            ...prev.slice(0, index),
            updatedProgress,
            ...prev.slice(index + 1),
          ];
        }
        return [...prev, updatedProgress];
      });
    },
    [setChapterProgress, currentOrganization.id, currentPerson.id]
  );

  const saveProgressCommon = useCallback(
    (data: Partial<ChapterProgressRequest>, updatePersistedAtom: boolean) => {
      const updatedFields: ChapterProgressRequest = {
        organizationId: currentOrganization.id,
        personId: currentPerson.id,
        completed: data.completed || false,
        currentTimeSeconds: data.currentTimeSeconds || 0,
        maxTimeSeconds: data.maxTimeSeconds || 0,
        chapterKey: data.chapterKey || '',
        completedAt: data.completedAt || '',
        chapterQuizResult: data.chapterQuizResult,
        correctQuestionCount: data.correctQuestionCount,
        totalQuestionCount: data.totalQuestionCount,
      };

      return saveChapterProgress({ data: updatedFields }).then((response) => {
        const updatedChapterProgress = response as ChapterProgressResponse;

        if (updatePersistedAtom) {
          updateProgressAtoms(updatedChapterProgress);
        }
        // else {
        //   setUnpersistedProgress((prev) => {
        //     const index = prev.findIndex((p) => p.chapterKey === updatedChapterProgress.chapterKey);
        //     if (index !== -1) {
        //       return [...prev.slice(0, index), updatedFields, ...prev.slice(index + 1)];
        //     }
        //     return [...prev, updatedFields];
        //   });
        // }

        return updatedChapterProgress;
      });
    },
    [
      currentOrganization.id,
      currentPerson.id,
      saveChapterProgress,
      setChapterProgress,
      updateProgressAtoms,
    ]
  );

  const saveIncrementalProgress = useCallback(
    (data: Partial<ChapterProgressRequest>) => {
      return saveProgressCommon(data, false);
    },
    [saveProgressCommon]
  );

  const saveProgress = useCallback(
    (data: Partial<ChapterProgressRequest>) => {
      return saveProgressCommon(data, true);
    },
    [saveProgressCommon]
  );

  return {
    saveIncrementalProgress,
    saveProgress,
  };
};
