import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  GetRowIdFunc,
  ModuleRegistry,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  JobInformationResponse,
  useGetJobsByOrganization,
} from '@btrway/api-batch';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ResponsiveGrid } from '@btrway/grid-components';
import { FileDownloadRenderer } from '@btrway/shared-components';
import React, { useMemo } from 'react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const ImportJobsGrid: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();

  if (!currentOrganization) {
    return null;
  }

  const { data: jobs } = useGetJobsByOrganization(currentOrganization.id);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Start Time',
        field: 'startTime',
        valueFormatter: (params) => {
          return new Date(params.value).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          });
        },
      },
      {
        headerName: 'Source File',
        field: 'originalFileKey',
        cellRenderer: FileDownloadRenderer,
        valueGetter: (params: ValueGetterParams) => ({
          key: params.data.originalFileKey,
        }),
      },
      {
        field: 'inserts',
        headerName: 'Records Created',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) => params.data.inserts,
      },
      {
        field: 'updates',
        headerName: 'Records Updated',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) => params.data.updates,
      },
      {
        headerName: 'Status',
        field: 'status',
      },
    ],
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const getRowId: GetRowIdFunc<JobInformationResponse> = (params) => {
    return params.data?.jobInstanceId?.toString() || '';
  };

  return (
    <ResponsiveGrid
      columnDefs={columnDefs}
      rowData={jobs}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      getRowId={getRowId}
      rowModelType="clientSide"
      enableRangeSelection={true}
      primaryNavigationField="startTime"
      responsiveRowSpacing={10}
    />
  );
};

export default ImportJobsGrid;
