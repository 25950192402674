/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type ChapterQuizResultEnum = typeof ChapterQuizResultEnum[keyof typeof ChapterQuizResultEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChapterQuizResultEnum = {
  passed: 'passed',
  failed: 'failed',
} as const;
