import { useRouteContext } from '@btrway/web-routing';
import { Divider, Group, NavLink, Stack, Title } from '@mantine/core';
import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useLayoutNavigation } from '../../hooks/useLayoutNavigation';
import styles from './LeftNavigationLayout.module.css';

interface LeftNavigationLayoutProps {
  header?: React.ReactNode | string;
  variant?: 'filled' | 'light' | 'subtle';
  displayHeader?: boolean;
}

export const LeftNavigationLayout: React.FC<LeftNavigationLayoutProps> = ({
  header,
  variant = 'light',
  displayHeader = true,
}) => {
  const { childRoutes, currentRoute } = useRouteContext();
  const outletContext = useOutletContext();
  const { navItems, currentValue, handleNavigation } = useLayoutNavigation(
    childRoutes,
    currentRoute
  );

  const renderHeader = () => {
    if (React.isValidElement(header)) {
      return header;
    }

    if (typeof header === 'string') {
      return <Title order={3}>{header}</Title>;
    }

    return <Title order={3}>{currentRoute.label}</Title>;
  };

  return (
    <Stack gap={0} justify="flex-start" p={0} className={styles.pageContainer}>
      {displayHeader && (
        <Group
          justify="flex-start"
          align="flex-start"
          gap="xs"
          wrap="nowrap"
          bg="gray.2"
          p="xs"
          className={styles.headerGroup}
        >
          {renderHeader()}
        </Group>
      )}
      <div className={styles.contentContainer}>
        <div className={styles.navigationSidebar}>
          <Stack gap="xs" p="md">
            {navItems.map((item) => (
              <NavLink
                key={item.value}
                label={
                  <Group gap="sm" wrap="nowrap">
                    {item.icon && <item.icon size={20} />}
                    <span>{item.label}</span>
                  </Group>
                }
                active={currentValue === item.value}
                onClick={() => handleNavigation(item.value)}
                variant={variant}
              />
            ))}
          </Stack>
        </div>
        <Divider orientation="vertical" />
        <div className={styles.contentWrapper}>
          <Outlet context={outletContext} />
        </div>
      </div>
    </Stack>
  );
};
