import { EventRequest } from '@btrway/api-calendar';
import { CalendarEventEdit } from '@btrway/calendar-event-editor';
import { useAuthenticatedUser } from '@btrway/current-user';
import React from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const CalendarEventTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  onClose,
}) => {
  const { completeTask } = useTaskActionContext();
  const { currentUser, currentOrganization, rootWorkgroup } =
    useAuthenticatedUser();

  // Create initial event configuration based on task properties
  const initialEvent: Partial<EventRequest> = {
    workgroupId: rootWorkgroup?.id,
    organizationId: currentOrganization.id,
    timeZoneName: currentOrganization.timeZoneName,
    // // If task has start/end dates, use those
    // ...(taskConfig.startDate && {
    //   startTime: taskConfig.startDate.value,
    // }),
    // ...(taskConfig.endDate && {
    //   endTime: taskConfig.endDate.value,
    // }),
  };

  const handleSave = async (event: EventRequest) => {
    try {
      await completeTask('Calendar event created successfully');
      onClose();
    } catch (error) {
      console.error('Failed to complete calendar event task:', error);
    }
  };

  return (
    <CalendarEventEdit
      workgroup={rootWorkgroup}
      initialEvent={initialEvent}
      onCancel={onClose}
      onSave={handleSave}
      userTimezone={currentOrganization.timeZoneName}
    />
  );
};

export default CalendarEventTask;
