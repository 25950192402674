import {
  WorkflowServiceContext,
  WorkflowServiceProvider,
} from '@btrway/workflow-configuration-manager';
import { Card, ScrollArea } from '@mantine/core';
import React from 'react';
import CertificationListView from '../CertificationListView/CertificationListView';
import TaskListView from '../TaskListView/TaskListView';

interface NestedWorkflowViewProps {
  workflowKey: string;
  parentWorkflowKey: string;
  type: 'task' | 'certification';
}

export const NestedWorkflowView: React.FC<NestedWorkflowViewProps> = ({
  workflowKey,
  parentWorkflowKey,
  type,
}) => {
  if (!workflowKey || workflowKey === parentWorkflowKey) return null;

  return (
    <Card bg="gray.3" withBorder p={0} radius="md">
      <ScrollArea h={300} offsetScrollbars type="auto" pl="xs" pt={0} pb={0}>
        <WorkflowServiceProvider workflowKey={workflowKey}>
          <WorkflowServiceContext.Consumer>
            {(service) => {
              if (!service) return null;
              const nestedWorkflow = service.getByKey().data;

              return type === 'task' ? (
                <TaskListView
                  workflowKey={workflowKey}
                  nestedTaskList={true}
                  workflow={nestedWorkflow}
                  onTaskSelected={() => {}}
                  onTaskDeleted={() => {}}
                />
              ) : (
                <CertificationListView
                  workflowKey={workflowKey}
                  workflow={nestedWorkflow}
                  nestedList={true}
                  onCertificationSelected={() => {}}
                  onCertificationDeleted={() => {}}
                />
              );
            }}
          </WorkflowServiceContext.Consumer>
        </WorkflowServiceProvider>
      </ScrollArea>
    </Card>
  );
};
