import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { OrganizationResponse, useGetOrganizations } from '@btrway/api-core';
import { Group, Stack, Text } from '@mantine/core';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddOrganizationModal from '../AddOrganizationModal/AddOrganizationModal';
import OrgAccessButton from '../OrgAccessButton/OrgAccessButton';
import OrganizationSearch from '../OrganizationsSearch/OrganizationsSearch';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

const OrganizationsView: FC = () => {
  const navigate = useNavigate();
  const { data: organizations, refetch: refetchOrganizations } =
    useGetOrganizations();
  const [filteredOrganizations, setFilteredOrganizations] = useState<
    OrganizationResponse[]
  >([]);

  useEffect(() => {
    if (organizations) {
      setFilteredOrganizations(organizations.filter((org) => !org.template));
    }
  }, [organizations]);

  const onRowClick = useCallback(
    (event: any) => {
      navigate(`/app-internal/organizations/${event.data.id}`);
    },
    [navigate]
  );

  const nameCellRenderer = useCallback(
    (params: any) => (
      <Group justify="flex-start" w="100%" align="center">
        <span>{params.value}</span>
        <div className="stop-propagation">
          <OrgAccessButton organizationId={params.data.id} />
        </div>
      </Group>
    ),
    []
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        sortable: true,
        flex: 2,
        suppressHeaderMenuButton: true,
        cellRenderer: nameCellRenderer,
      },
      {
        headerName: 'Type',
        field: 'organizationType.name',
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'City',
        field: 'physicalAddress.city',
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'State',
        field: 'physicalAddress.stateProvinceCode',
        flex: 1,
        suppressHeaderMenuButton: true,
      },
    ],
    [nameCellRenderer]
  );

  if (!organizations) {
    return null;
  }

  return (
    <Stack h="100%" gap={0}>
      <Group justify="space-between" mb="md">
        <Stack gap={0}>
          <Text fz="xl" fw={700}>
            {filteredOrganizations.length}
          </Text>
          <Text fz="sm" fw={600}>
            organizations
          </Text>
        </Stack>
        <AddOrganizationModal refetchOrganizations={refetchOrganizations} />
      </Group>
      <Group align="flex-start" h="100%" gap={0}>
        <OrganizationSearch
          organizations={organizations}
          onSearchChange={setFilteredOrganizations}
        />
        <div
          className="ag-theme-quartz"
          style={{ height: '100%', width: '100%', flex: 1 }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredOrganizations}
            onRowClicked={onRowClick}
          />
        </div>
      </Group>
    </Stack>
  );
};

export default OrganizationsView;
