import { WorkgroupResponse } from '@btrway/api-core';

export const buildWorkgroupHierarchy = (
  workgroups: WorkgroupResponse[]
): WorkgroupResponse[] => {
  const workgroupMap = new Map<number, WorkgroupResponse>();
  const rootWorkgroups: WorkgroupResponse[] = [];

  // First pass: create a map of all workgroups
  workgroups.forEach((workgroup) => {
    workgroupMap.set(workgroup.id, {
      ...workgroup,
      children: workgroup.children || [],
      parents: workgroup.parents || [],
    });
  });

  // Second pass: build the hierarchy
  workgroups.forEach((workgroup) => {
    const currentWorkgroup = workgroupMap.get(workgroup.id)!;
    const parentId = workgroup.pathList[workgroup.pathList.length - 2]; // Get the parent ID from the path

    if (parentId) {
      const parent = workgroupMap.get(parentId);
      if (parent) {
        if (!parent.children) parent.children = [];
        parent.children.push(currentWorkgroup);
        if (!currentWorkgroup.parents) currentWorkgroup.parents = [];
        currentWorkgroup.parents.push(parent);
      }
    } else {
      rootWorkgroups.push(currentWorkgroup);
    }

    // Set all ancestors as parents
    for (let i = workgroup.pathList.length - 2; i >= 0; i--) {
      const ancestorId = workgroup.pathList[i];
      const ancestor = workgroupMap.get(ancestorId);
      if (ancestor) {
        if (!currentWorkgroup.parents) currentWorkgroup.parents = [];
        if (!currentWorkgroup.parents.some((p) => p.id === ancestor.id)) {
          currentWorkgroup.parents.push(ancestor);
        }
      }
    }
  });

  return rootWorkgroups;
};
