import { HelpResourceResponse } from '@btrway/api-help';
import { Card, Checkbox, Group, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface ResourceCardProps {
  resource: HelpResourceResponse;
  isSelected: boolean;
  onSelectionChange: (resourceKey: string, selected: boolean) => void;
}

export const ResourceCard: React.FC<ResourceCardProps> = ({
  resource,
  isSelected: initialIsSelected,
  onSelectionChange,
}) => {
  const [isSelected, setIsSelected] = useState(initialIsSelected);

  useEffect(() => {
    setIsSelected(initialIsSelected);
  }, [initialIsSelected]);

  const handleSelectionChange = (selected: boolean) => {
    setIsSelected(selected);
    onSelectionChange(resource.helpResourceKey, selected);
  };

  const handleCardClick = () => {
    handleSelectionChange(!isSelected);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleSelectionChange(e.currentTarget.checked);
  };

  // const handlePreviewClick = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  //   setRightSectionContent(
  //     <Box p="md">
  //       <HelpArticleRenderer helpResource={resource} />
  //     </Box>,
  //     400
  //   );
  // };

  return (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      p="md"
      onClick={handleCardClick}
      style={{
        backgroundColor: isSelected
          ? 'var(--mantine-color-blue-light)'
          : undefined,
        cursor: 'pointer',
      }}
    >
      <Group align="flex-start" wrap="nowrap">
        <Checkbox
          checked={isSelected}
          onChange={handleCheckboxChange}
          onClick={(e) => e.stopPropagation()}
        />
        <Stack gap="xs" style={{ flex: 1 }}>
          <Group justify="space-between" align="flex-start">
            <Text fw={500} size="md">
              {resource.metadata?.title}
            </Text>
            <Group gap="xs" align="center">
              {resource.metadata?.category && (
                <Text size="xs" c="dimmed">
                  {resource.metadata.category}
                </Text>
              )}
              {/* <UnstyledButton
                onClick={handlePreviewClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--mantine-color-blue-6)',
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </UnstyledButton> */}
            </Group>
          </Group>
          {resource.metadata?.description && (
            <Text size="sm" c="dimmed" lineClamp={2}>
              {resource.metadata.description}
            </Text>
          )}
          {resource.tags && resource.tags.length > 0 && (
            <Group gap="xs">
              {resource.tags.map((tag) => (
                <Text key={tag} size="xs" c="blue">
                  #{tag}
                </Text>
              ))}
            </Group>
          )}
        </Stack>
      </Group>
    </Card>
  );
};
