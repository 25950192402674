// import { useCourseProgress } from '@btrway/courseware-progress-manager';
import { useAsset } from '@btrway/courseware-manager';
import { useInitialCoursewareProgress } from '@btrway/courseware-progress-manager';
import { getFormattedDuration } from '@btrway/utils';
import { ActionIcon, Button, Container, Group, Text } from '@mantine/core';
import {
  IconPlayerPlayFilled,
  IconRotateClockwise2,
  IconX,
} from '@tabler/icons-react';
import React from 'react';
import VideoBackground from '../VideoBackground/VideoBackground';
import styles from './CourseLanding.module.css';

interface CourseLandingProps {
  courseUid: number;
  onPlay: (resume: boolean) => void;
  onClose?: () => void;
}

const CourseLanding: React.FC<CourseLandingProps> = ({
  courseUid,
  onPlay,
  onClose,
}) => {
  const { asset, isLoading: isAssetLoading } = useAsset(courseUid);
  const { getInitialCourseProgress } = useInitialCoursewareProgress();

  if (!asset) return <div>Loading...</div>;

  const { status, resumePoint } = getInitialCourseProgress(asset);

  if (isAssetLoading || !asset) return <div>Loading...</div>;

  if (!asset.chapters || asset.chapters.length === 0) {
    return <div>Course has no chapters</div>;
  }

  const backgroundVideo = asset.chapters[0].videoPlaybackIdentifier || '';

  const renderButtons = () => {
    switch (status) {
      case 'NotStarted':
        return (
          <Button
            leftSection={<IconPlayerPlayFilled size={24} />}
            size="xl"
            bg="white"
            c="black"
            onClick={() => onPlay(false)}
            style={{ width: 'fit-content' }}
          >
            Play
          </Button>
        );
      case 'Started':
        return (
          <Group>
            <Button
              leftSection={<IconPlayerPlayFilled size={24} />}
              size="xl"
              bg="white"
              c="black"
              onClick={() => onPlay(false)}
              style={{ width: 'fit-content' }}
            >
              Play from Beginning
            </Button>
            <Button
              leftSection={<IconPlayerPlayFilled size={24} />}
              size="xl"
              bg="white"
              c="black"
              onClick={() => onPlay(true)}
              style={{ width: 'fit-content' }}
            >
              Resume at {getFormattedDuration(resumePoint)}
            </Button>
          </Group>
        );
      case 'Complete':
        return (
          <Button
            leftSection={<IconRotateClockwise2 size={24} />}
            size="xl"
            bg="white"
            c="black"
            onClick={() => onPlay(false)}
            style={{ width: 'fit-content' }}
          >
            Watch Again
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.courseLanding}>
      <VideoBackground playbackId={backgroundVideo} height="100%">
        <Container size="95%" className={styles.content}>
          {onClose && (
            <ActionIcon
              size="xl"
              variant="transparent"
              color="white"
              onClick={onClose}
              className={styles.closeButton}
            >
              <IconX size={32} />
            </ActionIcon>
          )}

          <div className={styles.contentInner}>
            <Text className={styles.title}>{asset.title}</Text>
            <Text className={styles.description}>
              {asset.metadata?.description}
            </Text>
            <div className={styles.buttonContainer}>{renderButtons()}</div>
          </div>
        </Container>
      </VideoBackground>
    </div>
  );
};

export default CourseLanding;
