import { AuthLoginRequest, useAuth, useAuthContext } from '@btrway/auth-core';
import {
  Anchor,
  Box,
  Button,
  Divider,
  Grid,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from '@mantine/core';
import { IconBrandGoogle } from '@tabler/icons-react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthWeb } from '../../hooks/useAuthWeb';

export function LoginForm() {
  const { loginWithCredentials, loginWithOAuth } = useAuth();
  const { handleLoginSuccess } = useAuthWeb();
  const { authState, checkAuthStatus } = useAuthContext();
  // const { currentUser } = useAuthenticatedUser();

  const [error, setError] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthLoginRequest>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const navigate = useNavigate();

  const handleRecoverPassword = () => {
    navigate('/recover-password');
  };

  const handleCreateAccount = () => {
    navigate('/register/rcs');
  };

  const onSubmit = async (data: AuthLoginRequest) => {
    loginWithCredentials(data)
      .then(async ({ success, error, requireMfa, email }) => {
        if (success) {
          if (requireMfa) {
            navigate(`/multi-factor-auth?email=${email}`);
          } else {
            checkAuthStatus();
            // console.log(currentUser);
            // if (currentUser) {
            handleLoginSuccess(false, email);
            // }
          }
        } else {
          setError(error || 'An error occurred during login');
        }
      })
      .catch((error) => {
        console.error('An error occurred during login:', error);
        setError('An unexpected error occurred');
      });
  };

  const handleGoogleLogin = async () => {
    // loginWithOAuth('google');
    console.log("Google doesn't work right now");
  };

  return (
    <Box
      style={{
        maxWidth: 740,
        backgroundColor: 'white',
        padding: '40px',
        margin: 'auto',
      }}
    >
      <Text size="xl" fw={700} ta="center">
        Sign in
      </Text>
      <Text size="md" ta="center" style={{ marginTop: '10px' }}>
        Access your account and help us fulfill a Better Way!
      </Text>
      <Space h="xl" />
      <Button
        leftSection={<IconBrandGoogle />}
        variant="default"
        onClick={handleGoogleLogin}
        styles={(theme) => ({
          root: {
            backgroundColor: 'white',
            borderColor: theme.colors.gray[4],
            '&:hover': {
              backgroundColor: theme.colors.gray[0],
            },
          },
        })}
        fullWidth
      >
        Sign in with Google
      </Button>
      <Space h="xl" />
      <Divider label="or with email" labelPosition="center" my="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Invalid email address',
            },
          }}
          render={({ field }) => (
            <TextInput
              label="Email"
              placeholder="Your email"
              {...field}
              error={errors.email?.message}
              styles={{
                input: {
                  backgroundColor: 'white',
                  borderColor: '#ced4da',
                },
              }}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <PasswordInput
              label="Password"
              placeholder="Your password"
              {...field}
              error={errors.password?.message}
              mt="md"
              styles={{
                input: {
                  backgroundColor: 'white',
                  borderColor: '#ced4da',
                },
              }}
            />
          )}
        />
        {error && (
          <Text color="red" mt="sm">
            {error}
          </Text>
        )}
        <Anchor
          onClick={handleRecoverPassword}
          size="sm"
          style={{ marginTop: '10px', display: 'block' }}
        >
          Recover Password
        </Anchor>
        <Grid gutter="20px">
          <Grid.Col span={12}>
            <Button
              type="submit"
              mt="lg"
              fullWidth
              styles={{
                root: {
                  backgroundColor: '#2F667F',
                  '&:hover': {
                    backgroundColor: '#245169',
                  },
                },
              }}
            >
              Sign In
            </Button>
          </Grid.Col>
        </Grid>
      </form>
      <Divider
        my="xl"
        label="OR"
        labelPosition="center"
        styles={{
          label: {
            color: '#666',
            fontWeight: 500,
          },
        }}
      />

      <Button
        onClick={handleCreateAccount}
        variant="outline"
        color="blue"
        fullWidth
        styles={{
          root: {
            borderColor: '#2F667F',
            color: '#2F667F',
            '&:hover': {
              backgroundColor: '#f0f8ff',
            },
          },
        }}
      >
        Create an Account
      </Button>
    </Box>
  );
}
