import {
  DataTypeEnum,
  PropertyReference,
  RuleOperatorTypeEnum,
  TriggerConfig,
  TriggerConfigRuleValuesItem,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useDataTypes, useRuleOperatorTypes } from '@btrway/workflow-manager';
import {
  Button,
  Card,
  Group,
  ScrollArea,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import React, { useEffect, useMemo, useState } from 'react';
import DataTypeValueSelector from '../DataTypeValueSelector/DataTypeValueSelector';
import UserRoleSelector from '../UserRoleSelector/UserRoleSelector';

interface SelectionCardProps {
  label: string;
  onSelect: () => void;
}

const SelectionCard: React.FC<SelectionCardProps> = ({ label, onSelect }) => (
  <Card
    shadow="sm"
    padding="xs"
    radius="md"
    withBorder
    onClick={onSelect}
    style={{ cursor: 'pointer' }}
  >
    <Group justify="space-between" wrap="nowrap">
      <Text fz="sm">{label}</Text>
    </Group>
  </Card>
);

interface TriggerDetailProps {
  properties: PropertyReference[];
  initialConfig?: TriggerConfig;
  triggerSetKey: string | null;
  onSave: (triggerSetKey: string | null, config: TriggerConfig) => void;
  onCancel: () => void;
}

const TriggerDetail: React.FC<TriggerDetailProps> = ({
  properties,
  initialConfig,
  triggerSetKey,
  onSave,
  onCancel,
}) => {
  const [config, setConfig] = useState<TriggerConfig>(
    initialConfig || {
      triggerKey: uuid(),
      property: undefined,
      ruleOperator: undefined,
      ruleValues: [],
    }
  );
  const { getDataType } = useDataTypes();
  const ruleOperatorOptions = useRuleOperatorTypes();

  useEffect(() => {
    if (initialConfig) {
      setConfig(initialConfig);
    }
  }, [initialConfig]);

  const propertyOptions = useMemo(() => {
    return properties.map((p) => ({
      value: `${p.propertyKey}${
        p.entityProperty ? `-${p.entityProperty}` : ''
      }`,
      label: p.label,
    }));
  }, [properties]);

  const handlePropertySelect = (selectedProperty: PropertyReference) => {
    setConfig((prev) => ({
      ...prev,
      property: selectedProperty,
      ruleOperator: undefined,
      ruleValues: [],
    }));
  };

  const handlePropertyChange = (value: string | null) => {
    const selectedProperty = properties.find(
      (p) =>
        `${p.propertyKey}${p.entityProperty ? `-${p.entityProperty}` : ''}` ===
        value
    );
    if (selectedProperty) {
      handlePropertySelect(selectedProperty);
    }
  };

  const handleOperatorSelect = (operator: RuleOperatorTypeEnum) => {
    setConfig((prev) => ({
      ...prev,
      ruleOperator: operator,
      ruleValues:
        operator === RuleOperatorTypeEnum.hasvalue ||
        operator === RuleOperatorTypeEnum.novalue
          ? [{ value: true }]
          : [],
    }));
  };

  const handleOperatorChange = (value: string | null) => {
    const operator = value as RuleOperatorTypeEnum | undefined;
    setConfig((prev) => ({
      ...prev,
      ruleOperator: operator,
      ruleValues:
        operator === RuleOperatorTypeEnum.hasvalue ||
        operator === RuleOperatorTypeEnum.novalue
          ? [{ value: true }]
          : [],
    }));
  };

  const handleValueChange = (values: any[]) => {
    setConfig((prev) => ({
      ...prev,
      ruleValues: values.map((value) => ({ value })),
    }));
  };

  const handleSave = () => {
    if (config.property) {
      if (config.property.dataType === DataTypeEnum.userRole) {
        if (config.ruleValues && config.ruleValues.length > 0) {
          onSave(triggerSetKey, {
            ...config,
            ruleOperator: RuleOperatorTypeEnum.includes,
          });
        }
      } else if (config.ruleOperator) {
        onSave(triggerSetKey, config);
      }
    }
  };

  const dataType = config.property
    ? getDataType(config.property.dataType)
    : null;
  const filteredOperatorOptions = useMemo(() => {
    if (!dataType) return [];
    return ruleOperatorOptions.filter((op) =>
      dataType.ruleOperators?.includes(op.value as RuleOperatorTypeEnum)
    );
  }, [dataType, ruleOperatorOptions]);

  const showValueSelector =
    config.ruleOperator !== RuleOperatorTypeEnum.hasvalue &&
    config.ruleOperator !== RuleOperatorTypeEnum.novalue;

  const isSaveEnabled =
    config.property &&
    ((config.property.dataType === DataTypeEnum.userRole &&
      config.ruleValues &&
      config.ruleValues.length > 0) ||
      (config.ruleOperator &&
        (config.ruleOperator === RuleOperatorTypeEnum.hasvalue ||
          config.ruleOperator === RuleOperatorTypeEnum.novalue ||
          (config.ruleValues && config.ruleValues.length > 0))));

  const renderContent = () => {
    if (!config.property && !initialConfig) {
      return (
        <Stack gap="xs">
          <Text fz="sm" fw={500}>
            Select a Property
          </Text>
          {properties.map((property) => (
            <SelectionCard
              key={`${property.propertyKey}${
                property.entityProperty ? `-${property.entityProperty}` : ''
              }`}
              label={property.label}
              onSelect={() => handlePropertySelect(property)}
            />
          ))}
        </Stack>
      );
    } else if (config.property) {
      return (
        <Stack>
          <Select
            label="Property"
            placeholder="Select a property"
            data={propertyOptions}
            value={
              config.property
                ? `${config.property.propertyKey}${
                    config.property.entityProperty
                      ? `-${config.property.entityProperty}`
                      : ''
                  }`
                : null
            }
            onChange={handlePropertyChange}
          />
          {config.property.dataType === DataTypeEnum.userRole ? (
            <UserRoleSelector
              initialUserRoleSlugs={
                config.ruleValues?.map(
                  (rv) => (rv as TriggerConfigRuleValuesItem).value as string
                ) || []
              }
              onChange={handleValueChange}
            />
          ) : (
            <>
              {!config.ruleOperator ? (
                <>
                  <Text fz="sm" fw={500} mt="md">
                    Select an Operator
                  </Text>
                  {filteredOperatorOptions.map((op) => (
                    <SelectionCard
                      key={op.value}
                      label={op.label}
                      onSelect={() =>
                        handleOperatorSelect(op.value as RuleOperatorTypeEnum)
                      }
                    />
                  ))}
                </>
              ) : (
                <>
                  <Select
                    label="Rule Operator"
                    placeholder="Select an operator"
                    data={filteredOperatorOptions}
                    value={config.ruleOperator}
                    onChange={handleOperatorChange}
                  />
                  {showValueSelector && (
                    <DataTypeValueSelector
                      dataType={config.property.dataType}
                      operator={config.ruleOperator}
                      values={config.ruleValues?.map((rv) => rv.value) || []}
                      onChange={handleValueChange}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Stack>
      );
    }
    return null;
  };

  return (
    <Card
      p="md"
      bg="transparent"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Group justify="space-between" mb="md">
        <Text fw={700} fz="lg">
          {initialConfig ? 'Edit Trigger' : 'Add New Trigger'}
        </Text>
        <Group gap="xs">
          <Button variant="outline" onClick={onCancel} size="compact-sm">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            size="compact-sm"
            disabled={!isSaveEnabled}
          >
            Save
          </Button>
        </Group>
      </Group>

      <ScrollArea style={{ flex: 1 }}>{renderContent()}</ScrollArea>
    </Card>
  );
};

export default TriggerDetail;
