import {
  DataTypeEnum,
  getGetDataTypesQueryKey,
  useGetDataTypes,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export const useDataTypes = () => {
  const {
    data: dataTypes,
    isLoading,
    error,
    refetch,
  } = useGetDataTypes({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetDataTypesQueryKey(),
    },
  });

  const getDataType = useCallback(
    (dataTypeCode: DataTypeEnum) => {
      return dataTypes?.find((type) => type.code === dataTypeCode);
    },
    [dataTypes]
  );

  return {
    dataTypes: dataTypes ?? [],
    isLoading,
    error,
    getDataType,
    refetchDataTypes: refetch,
  };
};
