import { createAxiosInstance } from '@btrway/api-client-provider';
import { CurrentUserResponse } from '@btrway/api-core';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider';
import { InviteNewUserRequest } from '../types/InviteNewUserRequest';
import { LoginWithOAuthResult } from '../types/LoginWithOAuthResult';
import { NewUserRequest } from '../types/NewUserRequest';
import { ValidateNewUserRequest } from '../types/ValidateNewUserRequest';
import axios from 'axios';

export const useAuth = () => {
  const { authState, checkAuthStatus, login, logout } = useAuthContext();


  const apiUrl = authState.apiUrl;
  const navigate = useNavigate();

  const loginWithCredentials = async (credentials: {
    email: string;
    password: string;
  }): Promise<{
    success: boolean;
    error?: string;
    requireMfa?: boolean;
    email?: string;
    currentUser?: CurrentUserResponse;
  }> => {
    try {
      const response = await login(credentials);

      if (response.success) {
        // Initialize axios instance
        createAxiosInstance(authState.apiUrl);

        // Force refresh auth cache
        await checkAuthStatus();

        return { success: true };
      } else {
        return { success: false, error: 'Invalid email or password' };
      }
    } catch (error) {
      console.error('Error occurred during login:', error);
      return { success: false, error: 'An error occurred during login' };
    }
  };

  const loginWithOAuth = async (idpHint?: string): Promise<void> => {
    const clientId = 'btrway-client';
    const redirectUri = encodeURIComponent(`${window.location.origin}/callback`);
    sessionStorage.setItem('redirect_uri', redirectUri);
    
    // Add state parameter for security
    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('oauth_state', state);
  
    // Build authorization URL
    let authUrl = `http://localhost:8080/realms/btrwayapp/protocol/openid-connect/auth?` + 
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent('openid email profile')}` +
      `&state=${state}`;
  
    if (idpHint) {
      authUrl += `&kc_idp_hint=${idpHint}`;
    }
  
    // Redirect in same window
    window.location.href = authUrl;
  };

  const loginWithToken = async (
    token: string
  ): Promise<{
    success: boolean;
    error?: string;
    currentUser?: CurrentUserResponse;
  }> => {
    try {
      // const { currentUser } = await initializeCurrentUser(token);
      return { success: true, error: undefined }; //, currentUser: currentUser
    } catch (error) {
      console.error('Error occurred during token login:', error);
      return { success: false, error: 'An error occurred during token login' };
    }
  };

  const setPassword = async (setPasswordBody: {
    token: string;
    password: string;
  }): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const passwordResetUrl = `${apiUrl}/auth/set-password`;
      const response = await fetch(passwordResetUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(setPasswordBody),
      });
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: 'Error setting password' };
      }
    } catch (error) {
      console.error('Error occured during password reset: ', error);
      return { success: false, error: 'Error setting password' };
    }
  };

  const sendPasswordResetRequest = async (
    email: string
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const passwordResetRequestUrl = `${apiUrl}/auth/request-password-reset`;
      const response = await fetch(passwordResetRequestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: email,
      });
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: 'Error sending email' };
      }
    } catch (error) {
      console.error('Error occured during password reset request: ', error);
      return { success: false, error: 'Error sending email' };
    }
  };

  

  const verifyMultiFactorAuth = async (verifyMultiFactorAuthBody: {
    code: string;
    email: string;
  }): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const verifyMultiFactorAuthUrl = `${apiUrl}/auth/verify-multi-factor-auth`;
      const response = await fetch(verifyMultiFactorAuthUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(verifyMultiFactorAuthBody),
      });
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: 'Error verifying user' };
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: 'Error verifying user' };
    }
  };

  const handleTokenRefresh = async (): Promise<{
    success: boolean;
    token?: string;
    error?: string;
  }> => {
    try {
      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // RefreshToken: `${refreshToken}`,
        },
      });
      if (response.ok) {
        const token = response.headers.get('Authorization')?.split(' ')[1];
        if (token) {
          return { success: true, token: token };
        } else {
          return { success: false, error: 'Could not get tokens' };
        }
      } else {
        return { success: false, error: 'Error refreshing tokens' };
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: 'Error refreshing tokens' };
    }
  };

  const handleLogout = async () => {
    logout();
  };

  const inviteNewUser = async (
    request: InviteNewUserRequest
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const response = await fetch(`${apiUrl}/auth/send-invite-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        console.log('Invitation email successfully sent');
        return { success: true };
      } else {
        console.error('Error sending invitation email');
        return { success: false, error: 'Error sending invitation email' };
      }
    } catch (error) {
      console.error('Error sending invitation email: ', error);
      return {
        success: false,
        error: 'Error sending invitation email: ' + error,
      };
    }
  };

  const createNewUser = async (
    request: NewUserRequest
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const response = await fetch(`${apiUrl}/auth/new-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      });

      if (response.ok) {
        const token = response.headers.get('Authorization')?.split(' ')[1];
        if (token) {
          // updateUserApiToken(token);
          // const { currentUser } = await initializeCurrentUser(token);
          // console.log('Current User set as new user: ', currentUser);
          return { success: true };
        } else {
          return { success: false, error: 'Could not get token from response' };
        }
      } else {
        let errorMessage: string;
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const errorData = await response.json();
          errorMessage = errorData.error || 'Unknown error occurred';
        } else {
          errorMessage = await response.text();
        }
        console.log('Error response:', errorMessage);
        return { success: false, error: errorMessage };
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: 'Error creating new user: ' + error };
    }
  };

  const validateNewUser = async (
    request: ValidateNewUserRequest
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const response = await fetch(`${apiUrl}/auth/new-user-validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${apiToken}`,
        },
        body: JSON.stringify(request),
      });
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: 'Error authenticating user' };
      }
    } catch (error) {
      console.error('Error occured during validation: ', error);
      return { success: false, error: 'Error validating' };
    }
  };

  return useMemo(
    () => ({
      loginWithCredentials,
      loginWithToken,
      logout,
      // requestOrgAccessToken,
      // requestPersonAccessToken,
      setPassword,
      sendPasswordResetRequest,
      verifyMultiFactorAuth,
      loginWithOAuth,
      createNewUser,
      validateNewUser,
      inviteNewUser,
      handleTokenRefresh,
      isAuthenticated: authState.isAuthenticated,
    }),
    []
  );
};
