import { Button, Group, Stack, Title } from '@mantine/core';
import { ReactNode } from 'react';
import { useRegistrationFlow } from '../../hooks/useRegistrationFlow';

interface RegistrationStepContentProps {
  title: string;
  isLastStep: boolean;
  isSummaryReached: boolean;
  onNext: () => void;
  onFinish: () => void;
  onSubmit: () => void;
  children: ReactNode;
}

export const RegistrationStepContent: React.FC<
  RegistrationStepContentProps
> = ({
  title,
  isLastStep,
  isSummaryReached,
  onNext,
  onFinish,
  onSubmit,
  children,
}) => {
  const { steps, currentStep, totalSteps, canNavigateManually } =
    useRegistrationFlow();

  const renderButton = () => {
    //Don't render any buttons if manual naviagation is not allowed
    if (!canNavigateManually(currentStep)) {
      return null;
    }

    if (isLastStep) {
      return (
        <Button fullWidth onClick={onSubmit}>
          Submit your Registration
        </Button>
      );
    }

    if (isSummaryReached) {
      return <Button onClick={onFinish}>Return to Summary</Button>;
    }

    // Check if the next step is the summary step
    const isNextStepSummary = currentStep === totalSteps - 2;

    return (
      <Button onClick={onNext}>
        {isNextStepSummary
          ? 'Review Before Submitting'
          : `Continue to ${steps[currentStep + 1].title}`}
      </Button>
    );
  };

  return (
    <Stack gap="md" style={{ flex: 1 }}>
      <Group justify="flex-start" align="center">
        <Title order={3}>{title}</Title>
      </Group>
      <div style={{ flex: 1 }}>{children}</div>
      <Group justify="flex-end" align="center">
        {renderButton()}
      </Group>
    </Stack>
  );
};

export default RegistrationStepContent;
