import { ICellRendererParams } from '@ag-grid-community/core';
import { WorkgroupResponse } from '@btrway/api-core';
import { DataTypeEnum } from '@btrway/api-workflow';
import { useEntityDataLookup } from '@btrway/entity-tags';
import { Text } from '@mantine/core';
import React from 'react';

interface WorkgroupCellProps extends ICellRendererParams {
  value: number;
}

export const WorkgroupCell: React.FC<WorkgroupCellProps> = ({ value }) => {
  const { data, isLoading, error } = useEntityDataLookup<WorkgroupResponse>(
    DataTypeEnum.workgroup,
    value
  );

  if (!value) return null;
  if (isLoading) return <Text>Loading...</Text>;
  if (error) return <Text c="red">Error</Text>;
  if (!data) return null;

  return data.name;
};
