import { AssetRequest, AssetResponse } from '@btrway/api-courseware';
import { mapAssetFolderResponseToRequest } from './mapAssetFolderResponseToRequest';

export const mapAssetResponseToRequest = (
  response: AssetResponse
): AssetRequest => {
  return {
    id: response.id,
    uid: response.uid,
    title: response.title,
    published: response.published,
    publishedAt: response.publishedAt,
    chapters: response.chapters,
    metadata: response.metadata,
    assetFolder: mapAssetFolderResponseToRequest(response.assetFolder),
    assetType: response.assetType,
  };
};
