import { PersonResponse, useGetPersonById } from '@btrway/api-core';
import { LoadingSpinner } from '@btrway/shared-components';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router';

interface PersonContextValue {
  person: PersonResponse;
  isLoading: boolean;
}

const PersonContext = createContext<PersonContextValue | undefined>(undefined);

export const usePerson = () => {
  const context = useContext(PersonContext);
  if (!context) {
    throw new Error('usePerson must be used within a PersonProvider');
  }
  return context;
};

interface PersonProviderProps {
  children: React.ReactNode;
}

export const PersonProvider: React.FC<PersonProviderProps> = ({ children }) => {
  const { personId } = useParams();

  const {
    data: person,
    isLoading,
    error,
  } = useGetPersonById(personId ? Number(personId) : 0);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !person) {
    return <div>Error loading person</div>;
  }

  return (
    <PersonContext.Provider
      value={{
        person,
        isLoading: false,
      }}
    >
      {children}
    </PersonContext.Provider>
  );
};
