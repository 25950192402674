import { CalendarAgenda, MetricCard } from '@btrway/mockup-components';
import { PersonTaskView } from '@btrway/workflow-reporting';
import { Grid, Stack } from '@mantine/core';
import React from 'react';
import PersonRelationshipsView from '../../components/PersonRelationshipsView/PersonRelationshipsView';
import { usePerson } from '../../providers/PersonProvider';

export const PersonDashboardView: React.FC = () => {
  const { person } = usePerson();

  return (
    <Grid gutter="xs" m="lg">
      <Grid.Col span={9}>
        <Stack gap="lg">
          <Grid>
            <Grid.Col span={4}>
              <MetricCard title="Student-Athletes" value={1054} />
            </Grid.Col>
            <Grid.Col span={4}>
              <MetricCard title="Hours of Curriculum Watched" value={312} />
            </Grid.Col>
            <Grid.Col span={4}>
              <MetricCard title="Tasks Overdue" value={49} />
            </Grid.Col>
          </Grid>
          <Stack gap="md">
            {/* <PersonRelationshipsView person={person} /> */}
            {/* <AssignedCurriculums person={person} /> */}
            <PersonTaskView personId={person.id} />
          </Stack>
        </Stack>
      </Grid.Col>
      <Grid.Col span={3} style={{ height: '100%' }}>
        <Stack gap="md">
          <PersonRelationshipsView person={person} />
          <CalendarAgenda />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
