// commonWorkflow.ts
import type {
  WorkflowDefinitionRequest,
  WorkflowDefinitionResponse,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '../types/commonWorkflow';

// commonWorkflowConversions.ts
export const convertResponseToCommon = (
  response: WorkflowDefinitionResponse | WorkflowTemplateResponse
): CommonWorkflow => {
  const isDefinition = 'workflowKey' in response;

  const common: CommonWorkflow = {
    id: response.id,
    description: response.description,
    entityName: response.entityName,
    entityType: response.entityType,
    name: response.name,
    published: response.published,
    versionDescription: response.versionDescription,
    versionPublishedAt: response.versionPublishedAt,
    workflowConfiguration: response.workflowConfiguration,
    workflowMetadata: response.workflowMetadata,
    workflowType: response.workflowType,
    sourceType: isDefinition ? 'definition' : 'template',
  };

  if (isDefinition) {
    const def = response as WorkflowDefinitionResponse;
    return {
      ...common,
      workflowKey: def.workflowKey,
      enabled: def.enabled,
      organizationId: def.organizationId,
      templateKey: def.templateKey,
      versionDiff: def.versionDiff,
      workflowTemplateId: def.workflowTemplate?.id,
      workgroupId: def.workgroupId,
    };
  } else {
    const template = response as WorkflowTemplateResponse;
    return {
      ...common,
      name: template.name, // Required for templates
      templateKey: template.templateKey,
      workflowKey: template.templateKey,
      templateSettings: template.templateSettings,
    };
  }
};

export const convertCommonToWorkflowRequest = (
  common: CommonWorkflow
): WorkflowDefinitionRequest | WorkflowTemplateRequest => {
  if (common.sourceType === 'definition') {
    const request: WorkflowDefinitionRequest = {
      description: common.description,
      enabled: common.enabled ?? false, // Providing default as it's required
      entityName: common.entityName,
      entityType: common.entityType,
      id: common.id,
      name: common.name,
      organizationId: common.organizationId!, // Assuming this is required and set
      published: common.published,
      templateKey: common.templateKey,
      versionDescription: common.versionDescription,
      versionDiff: common.versionDiff,
      versionPublishedAt: common.versionPublishedAt,
      workflowConfiguration: common.workflowConfiguration,
      workflowKey: common.workflowKey!, // Assuming this is required and set
      workflowMetadata: common.workflowMetadata,
      workflowTemplateId: common.workflowTemplateId,
      workflowType: common.workflowType,
      workgroupId: common.workgroupId,
    };
    return request;
  } else {
    const request: WorkflowTemplateRequest = {
      description: common.description,
      entityName: common.entityName,
      entityType: common.entityType,
      id: common.id,
      name: common.name!, // Required for templates
      published: common.published,
      templateKey: common.workflowKey!, // Required for templates
      templateSettings: common.templateSettings,
      versionDescription: common.versionDescription,
      versionPublishedAt: common.versionPublishedAt,
      workflowConfiguration: common.workflowConfiguration,
      workflowMetadata: common.workflowMetadata,
      workflowType: common.workflowType,
    };
    return request;
  }
};

// Type guard utility for checking if required fields are present
export const isValidDefinitionWorkflow = (
  common: CommonWorkflow
): common is CommonWorkflow & {
  sourceType: 'definition';
  workflowKey: string;
  organizationId: number;
  enabled: boolean;
} => {
  return (
    common.sourceType === 'definition' &&
    typeof common.workflowKey === 'string' &&
    typeof common.organizationId === 'number' &&
    typeof common.enabled === 'boolean'
  );
};

export const isValidTemplateWorkflow = (
  common: CommonWorkflow
): common is CommonWorkflow & {
  sourceType: 'template';
  name: string;
  templateKey: string;
} => {
  return (
    common.sourceType === 'template' &&
    typeof common.name === 'string' &&
    typeof common.templateKey === 'string'
  );
};

// Utility to validate before converting to request
export const validateWorkflowBeforeConversion = (
  common: CommonWorkflow
): string[] => {
  const errors: string[] = [];

  if (common.sourceType === 'definition') {
    if (!common.workflowKey) errors.push('Workflow key is required');
    if (common.organizationId === undefined)
      errors.push('Organization ID is required');
    if (common.enabled === undefined) errors.push('Enabled flag is required');
  } else {
    if (!common.name) errors.push('Name is required for templates');
    if (!common.templateKey) errors.push('Template key is required');
  }

  return errors;
};
