import { useAuthenticatedUser } from '@btrway/current-user';
import { Stack, Text, Title } from '@mantine/core';
import React from 'react';

export const MyHeader: React.FC = () => {
  const { currentPerson } = useAuthenticatedUser();

  return (
    <Stack gap={0}>
      <Title c="white" order={3}>
        Hi, {currentPerson.firstName}
      </Title>
      <Text fz={14} c="white">
        Let's Get Started Getting Things Done!
      </Text>
    </Stack>
  );
};
