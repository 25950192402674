import { Box } from '@mantine/core';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { MessageDetail } from '../../components/MessageDetail/MessageDetail';

const MessageDetailPage: React.FC = () => {
  const { messageId } = useParams<{ messageId: string }>();
  const parsedMessageId = parseInt(messageId ?? '', 10);

  if (!messageId || isNaN(parsedMessageId)) {
    return <Navigate to="/messages" replace />;
  }

  return (
    <Box p="md" h="100%" style={{ display: 'flex', flexDirection: 'column' }}>
      <MessageDetail messageId={parsedMessageId} />
    </Box>
  );
};

export default MessageDetailPage;
