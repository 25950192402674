// hooks/useScreenHelpers.ts
import { StepConfig, TaskConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  CommonWorkflow,
  useStepConfigFactory,
} from '@btrway/workflow-configuration-manager';
import { useCallback } from 'react';

interface UseScreenHelpersProps {
  openScreen: (screen: any, options?: any) => void;
  parentWorkflow: CommonWorkflow | null;
}

interface ScreenOptions {
  mode: 'panel';
  position: 'left';
  width?: number;
}

export const useScreenHelpers = ({
  openScreen,
  parentWorkflow,
}: UseScreenHelpersProps) => {
  const { createStepConfig } = useStepConfigFactory();
  const isAutomationWorkflow =
    parentWorkflow?.workflowType === WorkflowTypeEnum.automation;

  const openStepConfigScreen = useCallback(
    (config: StepConfig, isNew: boolean = true, width?: number) => {
      openScreen(
        {
          type: 'STEP_CONFIG',
          data: {
            config,
            workflow: parentWorkflow!,
            isNew,
          },
        },
        {
          mode: 'panel',
          position: 'left',
          ...(width && { width }),
        } as ScreenOptions
      );
    },
    [openScreen, parentWorkflow]
  );

  const openTaskConfigScreen = useCallback(
    (config: TaskConfig, isNew: boolean = true, width?: number) => {
      if (isAutomationWorkflow) {
        const newStep = createStepConfig('task', config.taskType);
        if (newStep.taskConfig) {
          newStep.taskConfig = {
            ...newStep.taskConfig,
            taskKey: config.taskKey,
            taskType: config.taskType,
            taskProperties: config.taskProperties,
            metadata: config.metadata,
          };
        }
        openStepConfigScreen(newStep, isNew, width);
      } else {
        openScreen(
          {
            type: 'TASK_CONFIG',
            data: {
              config,
              workflow: parentWorkflow!,
              isNew,
            },
          },
          {
            mode: 'panel',
            position: 'left',
            ...(width && { width }),
          } as ScreenOptions
        );
      }
    },
    [
      isAutomationWorkflow,
      openScreen,
      openStepConfigScreen,
      parentWorkflow,
      createStepConfig,
    ]
  );

  return {
    isAutomationWorkflow,
    openStepConfigScreen,
    openTaskConfigScreen,
  };
};
