import {
  AssignTaskInputProperties,
  StepTypeEnum,
  TaskAssignmentConfig,
} from '@btrway/api-workflow';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workflowConfigurationAtom } from './workflowConfigurationAtom';

export const taskAssignmentAtomFamily = atomFamily((stepKey: string) =>
  atom(
    (get) => {
      const workflow = get(workflowConfigurationAtom);
      const step = workflow?.steps?.find((s) => s.stepKey === stepKey);
      if (
        step?.stepTypeCode === StepTypeEnum.assignTask &&
        step.inputProperties
      ) {
        return (
          (step.inputProperties as AssignTaskInputProperties)
            .taskAssignmentConfig || null
        );
      }
      return null;
    },
    (get, set, update: Partial<TaskAssignmentConfig>) => {
      set(workflowConfigurationAtom, (prev) => {
        if (!prev?.steps) return prev;

        const updatedSteps = prev.steps.map((step) => {
          if (
            step.stepKey === stepKey &&
            step.stepTypeCode === StepTypeEnum.assignTask
          ) {
            const inputProperties = step.inputProperties as
              | AssignTaskInputProperties
              | undefined;
            if (inputProperties) {
              return {
                ...step,
                inputProperties: {
                  ...inputProperties,
                  taskAssignmentConfig: {
                    ...inputProperties.taskAssignmentConfig,
                    ...update,
                  },
                },
              };
            }
          }
          return step;
        });

        return {
          ...prev,
          steps: updatedSteps,
        };
      });
    }
  )
);
