import { useAtom } from 'jotai';
import { workflowEventAtomFamily } from '../atoms/workflowEventAtomFamily';

export const useWorkflowEvent = (eventKey: string) => {
  const [event, setEvent] = useAtom(workflowEventAtomFamily(eventKey));

  return {
    event,
    setEvent,
  };
};
