import { Box, Space, Text } from '@mantine/core';
import MuxUploader from '@mux/mux-uploader-react';
import { useCallback, useState } from 'react';

interface MuxVideoUploaderProps {
  onUploadComplete?: () => void;
}

const MuxVideoUploader: React.FC<MuxVideoUploaderProps> = ({
  onUploadComplete,
}) => {
  const [uploadCount, setUploadCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const tokenId = import.meta.env.VITE_MUX_TOKEN_ID;
  const tokenSecret = import.meta.env.VITE_MUX_TOKEN_SECRET;

  const endpointFunction = useCallback(async () => {
    try {
      const response = await fetch('https://api.mux.com/video/v1/uploads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
        },
        body: JSON.stringify({
          cors_origin: 'http://localhost/',
          new_asset_settings: {
            playback_policy: ['public'],
            encoding_tier: 'smart',
            max_resolution_tier: '2160p',
            input: [
              {
                generated_subtitles: [
                  { language_code: 'en', name: 'English CC' },
                  { language_code: 'es', name: 'Spanish' },
                  { language_code: 'it', name: 'Italian' },
                  { language_code: 'pt', name: 'Portuguese' },
                  { language_code: 'fr', name: 'French' },
                ],
              },
            ],
          },
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.data.url;
      } else {
        console.error('Error generating endpoint:', response.statusText);
        throw new Error('Failed to generate upload URL');
      }
    } catch (error) {
      console.error('Error generating endpoint:', error);
      throw error;
    }
  }, []);

  const handleUploadSuccess = useCallback(() => {
    console.log('Upload successful');
    setUploadCount((prevCount) => prevCount + 1);
    setIsUploading(false);
    onUploadComplete?.();
  }, [onUploadComplete]);

  const handleUploadStart = useCallback(() => {
    console.log('Upload started');
    setIsUploading(true);
  }, []);

  return (
    <Box>
      <MuxUploader
        endpoint={endpointFunction}
        onSuccess={handleUploadSuccess}
        onUploadStart={handleUploadStart}
      />
      <Space h="md" />
      <Text>Uploads completed: {uploadCount}</Text>
      {isUploading && <Text>Upload in progress...</Text>}
    </Box>
  );
};

export default MuxVideoUploader;
