import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useSecuredAction = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const executeSecuredAction = useCallback(
    async (action: string, callback: () => Promise<void | boolean>) => {
      if (!executeRecaptcha) return;

      try {
        await executeRecaptcha(action);
        return await callback();
      } catch (error) {
        console.error(`Secured action "${action}" failed:`, error);
        throw error;
      }
    },
    [executeRecaptcha]
  );

  return { executeSecuredAction };
};
