import { WorkgroupResponse } from '@btrway/api-core';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workgroupHierarchyAtom } from './workgroupHierarchyAtom';

export const workgroupHierarchyAtomFamily = atomFamily((id: number) =>
  atom((get) => {
    const hierarchy = get(workgroupHierarchyAtom);
    const findWorkgroupInHierarchy = (
      workgroups: WorkgroupResponse[]
    ): WorkgroupResponse | undefined => {
      for (const workgroup of workgroups) {
        if (workgroup.id === id) {
          return workgroup;
        }
        if (workgroup.children) {
          const found = findWorkgroupInHierarchy(workgroup.children);
          if (found) return found;
        }
      }
      return undefined;
    };
    return findWorkgroupInHierarchy(hierarchy);
  })
);
