import { HelpRepositoryEnum } from '@btrway/api-help';
import { atom } from 'jotai';
import { helpResourceEditorStateAtom } from './helpResourceEditorStateAtom';

interface OpenHelpResourceEditorAction {
  type: 'open';
  helpResourceId: number | null;
  repository: HelpRepositoryEnum;
  onAfterSave?: () => void;
}

interface CloseHelpResourceEditorAction {
  type: 'close';
}

type HelpResourceEditorAction =
  | OpenHelpResourceEditorAction
  | CloseHelpResourceEditorAction;

export const helpResourceEditorActionsAtom = atom(
  null,
  (get, set, action: HelpResourceEditorAction) => {
    switch (action.type) {
      case 'open':
        set(helpResourceEditorStateAtom, {
          isOpen: true,
          helpResourceId: action.helpResourceId,
          repository: action.repository,
          onAfterSave: action.onAfterSave,
        });
        break;
      case 'close':
        set(helpResourceEditorStateAtom, {
          isOpen: false,
          helpResourceId: null,
          repository: null,
          onAfterSave: undefined,
        });
        break;
    }
  }
);
