import { Button, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import React from 'react';

interface CloneWorkflowModalProps {
  opened: boolean;
  onClose: () => void;
  onClone: (name: string) => void;
  originalName: string;
}

const CloneWorkflowModal: React.FC<CloneWorkflowModalProps> = ({
  opened,
  onClose,
  onClone,
  originalName,
}) => {
  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) => (!value ? 'Name is required' : null),
    },
  });

  const handleSubmit = form.onSubmit((values) => {
    onClone(values.name);
    form.reset();
    onClose();
  });

  return (
    <Modal opened={opened} onClose={onClose} title="Clone Template" size="md">
      <form onSubmit={handleSubmit}>
        <Stack>
          <TextInput
            label="New Template Name"
            placeholder="Enter template name"
            required
            {...form.getInputProps('name')}
            autoFocus
            data-autoFocus
          />
          <Button type="submit" fullWidth>
            Clone
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default CloneWorkflowModal;
