import { Stack } from '@mantine/core';
import React from 'react';
import SurveyJsWrapper from '../SurveyJsWrapper/SurveyJsWrapper';

interface FormBuilderProps {
  workflowKey: string;
}

export const FormBuilder: React.FC<FormBuilderProps> = ({ workflowKey }) => {
  return (
    <Stack h="100%" gap={0}>
      <SurveyJsWrapper workflowKey={workflowKey} />
    </Stack>
  );
};

export default FormBuilder;
