import { TaskTypeEnum } from '@btrway/api-workflow';
import {
  IconAlignBoxLeftTop,
  IconAward,
  IconCalendarEvent,
  IconCheckbox,
  IconClipboardCheck,
  IconDeviceTv,
  IconDirections,
  IconExternalLink,
  IconEye,
  IconFileStack,
  IconListCheck,
  IconMail,
  IconMoodSmile,
  IconProps,
  IconUpload,
  IconUserPlus,
} from '@tabler/icons-react';
import React from 'react';

const iconMap: Record<TaskTypeEnum, React.ComponentType<IconProps>> = {
  [TaskTypeEnum.completeCourse]: IconDeviceTv,
  [TaskTypeEnum.completeCurriculum]: IconClipboardCheck,
  [TaskTypeEnum.sendEmail]: IconMail,
  [TaskTypeEnum.createCalendarEvent]: IconCalendarEvent,
  [TaskTypeEnum.submitForm]: IconAlignBoxLeftTop,
  [TaskTypeEnum.completeWorkflowStep]: IconEye,
  [TaskTypeEnum.completeTaskList]: IconListCheck,
  [TaskTypeEnum.completeTask]: IconCheckbox,
  [TaskTypeEnum.uploadFile]: IconUpload,
  [TaskTypeEnum.addtoWorkgroup]: IconUserPlus,
  [TaskTypeEnum.thirdParty]: IconExternalLink,
  [TaskTypeEnum.certification]: IconAward,
  [TaskTypeEnum.completeFormPacket]: IconFileStack,
  [TaskTypeEnum.completeTaskOption]: IconDirections,
  [TaskTypeEnum.chooseTask]: IconDirections,
};

interface TaskTypeIconProps {
  iconName: TaskTypeEnum;
  color?: string;
  size?: string | number;
}

export const TaskTypeIcon: React.FC<TaskTypeIconProps> = ({
  iconName,
  color = 'currentColor',
  size = 24,
}) => {
  const IconComponent = iconMap[iconName] || IconMoodSmile;
  return <IconComponent color={color} size={size} />;
};
