import { Menu } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconBrackets } from '@tabler/icons-react';
import { AVAILABLE_TOKENS } from '../../config/mailMergeTokens';
import { MergeToken } from '../../types/mergeToken';

// Define a type for the merge token data

export const MergeTokensSelector = () => {
  const { editor } = useRichTextEditorContext();

  const insertToken = (token: MergeToken) => {
    if (!editor) return;

    editor
      .chain()
      .focus()
      .setMergeToken({
        type: token.type,
        fieldName: token.name,
        fieldId: token.fieldId,
      })
      .run();
  };

  if (!editor) {
    return null;
  }

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <RichTextEditor.Control
          aria-label="Insert Merge Token"
          title="Insert Merge Token"
        >
          <IconBrackets size={16} />
        </RichTextEditor.Control>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Insert Field</Menu.Label>
        {AVAILABLE_TOKENS.map((token) => (
          <Menu.Item
            key={token.fieldId}
            onClick={() => insertToken(token)}
            title={token.description}
          >
            {token.name}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
