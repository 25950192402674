// constants/index.ts

import { MIME_TYPES } from '@mantine/dropzone';
import { Field, ImportedPerson } from '../types';

// Define the base fields for person import
export const baseFields: Field<keyof ImportedPerson>[] = [
  {
    label: 'Person ID',
    key: 'personId',
    alternateMatches: ['id', 'person_id', 'personid'],
    fieldType: { type: 'input' },
    example: '12345',
  },
  {
    label: 'First Name',
    key: 'firstName',
    alternateMatches: ['first name', 'first', 'first_name', 'fname'],
    fieldType: { type: 'input' },
    example: 'John',
    validations: [
      {
        rule: 'required',
        errorMessage: 'First Name is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Last Name',
    key: 'lastName',
    alternateMatches: ['last name', 'last', 'last_name', 'lname', 'surname'],
    fieldType: { type: 'input' },
    example: 'Doe',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Last Name is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Email',
    key: 'email',
    alternateMatches: ['email address', 'email_address', 'e-mail'],
    fieldType: { type: 'input' },
    example: 'john.doe@example.com',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Email is required',
        level: 'error',
      },
      {
        rule: 'regex',
        value: '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
        errorMessage: 'Invalid email format',
        level: 'error',
      },
    ],
  },
  {
    label: 'Mobile Phone',
    key: 'mobilePhone',
    alternateMatches: ['mobile', 'cell', 'cell phone', 'mobile_phone', 'phone'],
    fieldType: { type: 'input' },
    example: '123-456-7890',
    validations: [
      {
        rule: 'regex',
        value: '^\\d{3}-\\d{3}-\\d{4}$',
        errorMessage: 'Invalid phone number format (use XXX-XXX-XXXX)',
        level: 'warning',
      },
    ],
  },
  {
    label: 'System, School, or Team',
    key: 'rootWorkgroup',
    alternateMatches: ['school', 'workgroup', 'team', 'organization'],
    fieldType: {
      type: 'custom',
    },
    example: 'Main High School',
    validations: [
      {
        rule: 'required',
        errorMessage: 'System, School, or Team is required',
        level: 'error',
      },
    ],
  },
  {
    label: 'Role',
    key: 'userRole',
    alternateMatches: ['user role', 'position', 'job title'],
    fieldType: {
      type: 'select',
      options: [], // This should be populated with actual options
    },
    example: 'Teacher',
    validations: [
      {
        rule: 'required',
        errorMessage: 'Role is required',
        level: 'error',
      },
    ],
  },
];

// Define the steps in the import process
export enum ImportSteps {
  UploadFile = 'Upload File',
  SheetSelection = 'Sheet Selection',
  Matching = 'Matching',
  ColumnMapping = 'Column Mapping',
  WorkgroupMapping = 'Workgroup Mapping',
  ReviewSubmit = 'Review and Submit',
}

// Define validation levels
export enum ValidationLevel {
  Error = 'error',
  Warning = 'warning',
}

// You might want to add more constants here as needed, such as:
// - Maximum file size
// - Supported file types
// - Default values for certain fields
// - Any other configuration constants

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const SUPPORTED_FILE_TYPES = [
  MIME_TYPES.csv,
  MIME_TYPES.xlsx,
  MIME_TYPES.xls,
];
