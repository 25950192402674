import { TaskListView } from '@btrway/task-list-builder';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowBuilderStateProvider } from '@btrway/workflow-builder-state';
import { useWorkflowService } from '@btrway/workflow-configuration-manager';
import { Box, Container, ScrollArea } from '@mantine/core';
import React from 'react';

interface TaskListBuilderProps {
  workflowKey: string;
}

const TaskListBuilder: React.FC<TaskListBuilderProps> = ({ workflowKey }) => {
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();

  return (
    <Box style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <RightSectionProvider>
        <SettingsDisplayProvider>
          <WorkflowBuilderStateProvider parentWorkflow={workflow}>
            <ScrollArea style={{ height: '100%', width: '100%' }}>
              <Container size="md" px="md" style={{ maxWidth: 800 }}>
                <TaskListView
                  workflowKey={workflowKey}
                  nestedTaskList={false}
                  onTaskSelected={(taskKey) => {
                    // Any additional task selection handling if needed
                  }}
                  onTaskDeleted={(taskKey) => {
                    // Any additional task deletion handling if needed
                  }}
                />
              </Container>
            </ScrollArea>
          </WorkflowBuilderStateProvider>
        </SettingsDisplayProvider>
      </RightSectionProvider>
    </Box>
  );
};

export default TaskListBuilder;
