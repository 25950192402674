import {
  WorkflowConfig,
  WorkflowStepInstanceResponse,
} from '@btrway/api-workflow';
import { Box, Stack } from '@mantine/core';
import React from 'react';
import WorkflowInstanceSidebarItem from '../WorkflowInstanceSidebarItem/WorkflowInstanceSidebarItem';

interface WorkflowInstanceSidebarProps {
  onStepClick: (stepInstance: WorkflowStepInstanceResponse) => void;
  selectedStepInstance: WorkflowStepInstanceResponse | null;
  workflowStepInstances: WorkflowStepInstanceResponse[];
  workflowConfiguration?: WorkflowConfig;
}

const WorkflowInstanceSidebar: React.FC<WorkflowInstanceSidebarProps> = ({
  onStepClick,
  selectedStepInstance,
  workflowStepInstances,
  workflowConfiguration,
}) => {
  return (
    <Box
      style={{
        width: '150px',
        height: '100%',
        backgroundColor: 'var(--mantine-color-dark-6)',
        overflowY: 'auto',
      }}
    >
      <Stack gap="xs" p="xs">
        {workflowStepInstances.map((stepInstance) => (
          <WorkflowInstanceSidebarItem
            key={stepInstance.id}
            stepInstance={stepInstance}
            onClick={() => onStepClick(stepInstance)}
            isSelected={stepInstance.id === selectedStepInstance?.id}
            workflowStep={workflowConfiguration?.steps?.find(
              (step) => step.stepKey === stepInstance.stepKey
            )}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default WorkflowInstanceSidebar;
