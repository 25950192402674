import { useUserRoles } from '@btrway/security-manager';
import { Checkbox, Loader, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface UserRoleSelectorProps {
  initialUserRoleSlugs: string[];
  onChange: (selectedRoleSlugs: string[]) => void;
}

const UserRoleSelector: React.FC<UserRoleSelectorProps> = ({
  initialUserRoleSlugs,
  onChange,
}) => {
  const { userRoles, isLoading, getUserRoleById } = useUserRoles();
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    initialUserRoleSlugs.map((slug) => slug)
  );

  useEffect(() => {
    setSelectedRoles(initialUserRoleSlugs.map((slug) => slug));
  }, [initialUserRoleSlugs]);

  const handleRoleSelect = (selectedRoleSlugs: string[]) => {
    setSelectedRoles(selectedRoleSlugs);
    onChange(selectedRoleSlugs);
  };

  if (isLoading) {
    return <Loader size="sm" />;
  }

  return (
    <>
      <Checkbox.Group
        value={selectedRoles}
        onChange={handleRoleSelect}
        label="Select User Roles"
        description="Choose the user roles that apply to this trigger"
      >
        <Stack mt="md" gap="xs">
          {userRoles.map((role) => (
            <Checkbox
              key={role.slug}
              value={role.slug}
              label={role.name}
              checked={selectedRoles.includes(role.slug)}
            />
          ))}
        </Stack>
      </Checkbox.Group>
    </>
  );
};

export default UserRoleSelector;
