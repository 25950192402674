import {
  getGetPublicRegistrationFormQueryKey,
  getLookupWorkgroupBySlugQueryKey,
  useGetPublicRegistrationForm,
  useLookupWorkgroupBySlug,
} from '@btrway/api-core';
import { RecaptchaProvider } from '@btrway/recaptcha-provider';
import { Text } from '@mantine/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RegistrationForm } from '../../components/RegistrationForm/RegistrationForm';
import { useRegistrationAuth } from '../../hooks/useRegistrationAuth';

const RegistrationFormPage: React.FC = () => {
  const { workgroupIdentifier, formId, year } = useParams<{
    workgroupIdentifier: string;
    formId: string;
    year?: string;
  }>();
  

  const isNumeric = /^\d+$/.test(workgroupIdentifier || '');

  const { data: workgroupBySlug, isLoading: isLoadingBySlug } =
    useLookupWorkgroupBySlug(workgroupIdentifier || '', {
      query: {
        enabled: !isNumeric,
        queryKey: getLookupWorkgroupBySlugQueryKey(workgroupIdentifier || ''),
      },
    });

  const workgroupId = isNumeric
    ? Number(workgroupIdentifier)
    : workgroupBySlug?.workgroupId;

  const yearParam = year ? parseInt(year, 10) : undefined;
  const { data: publicFormRegistration, isLoading: isLoadingForm } =
    useGetPublicRegistrationForm(
      workgroupId || 0,
      Number(formId),
      { year: yearParam },
      {
        query: {
          enabled: !!workgroupId,
          queryKey: getGetPublicRegistrationFormQueryKey(
            workgroupId || 0,
            Number(formId),
            { year: yearParam }
          ),
        },
      }
    );

  if (isLoadingBySlug || isLoadingForm) {
    return <Text>Loading...</Text>;
  }

  if (!workgroupId) {
    return <Text>No workgroup found.</Text>;
  }

  if (!publicFormRegistration) {
    return <Text>No registration form found.</Text>;
  }

  return (
    <RecaptchaProvider siteKey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}>
      <RegistrationForm
        workgroupId={workgroupId}
        publicFormRegistration={publicFormRegistration}
        year={yearParam}
      />
    </RecaptchaProvider>
  );
};

export default RegistrationFormPage;
