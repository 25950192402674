import { Group } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { NavItem } from '../../types/navItem';
import styles from './ButtonNavigation.module.css';

interface ButtonNavigationProps {
  navItems: NavItem[];
  currentValue: string;
  onChange: (value: string) => void;
  variant?: 'light' | 'dark';
}

export const ButtonNavigation: React.FC<ButtonNavigationProps> = ({
  navItems,
  currentValue,
  onChange,
  variant = 'light',
}) => {
  const [indicatorStyle, setIndicatorStyle] = useState({
    width: 0,
    height: 0,
    transform: 'translate(0, 0)',
  });
  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    const activeIndex = navItems.findIndex(
      (item) => item.value === currentValue
    );
    if (activeIndex >= 0 && buttonsRef.current[activeIndex]) {
      const button = buttonsRef.current[activeIndex];
      const rect = button?.getBoundingClientRect();
      const parentRect = button?.parentElement?.getBoundingClientRect();

      if (rect && parentRect) {
        setIndicatorStyle({
          width: rect.width,
          height: rect.height,
          transform: `translate(${rect.left - parentRect.left}px, 0)`,
        });
      }
    }
  }, [currentValue, navItems]);

  return (
    <div className={`${styles.wrapper} ${styles[variant]}`}>
      {/* Animated indicator */}
      <div className={styles.indicator} style={indicatorStyle} />

      {/* Navigation buttons */}
      <div className={styles.buttonContainer}>
        {navItems.map((item, index) => (
          <button
            key={item.value}
            ref={(el) => (buttonsRef.current[index] = el)}
            onClick={() => onChange(item.value)}
            className={`${styles.button} ${
              currentValue === item.value ? styles.active : ''
            }`}
          >
            <Group
              align="center"
              justify="center"
              wrap="nowrap"
              style={{ gap: item.displayType === 'both' ? '8px' : '0' }}
            >
              {(item.displayType === 'icon' || item.displayType === 'both') &&
                item.icon && <item.icon size={20} />}
              {(item.displayType === 'label' ||
                item.displayType === 'both') && (
                <>
                  {item.labelComponent ? (
                    <item.labelComponent
                      fallbackLabel={
                        typeof item.label === 'string'
                          ? item.label
                          : 'Navigation'
                      }
                    />
                  ) : (
                    <span className={styles.label}>
                      {typeof item.label === 'string'
                        ? item.label
                        : 'Navigation'}
                    </span>
                  )}
                </>
              )}
            </Group>
          </button>
        ))}
      </div>
    </div>
  );
};
