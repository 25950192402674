import { StepConfig, StepConfigFormConfig } from '@btrway/api-workflow';
import { FormAssistantResponse } from '@btrway/assistant-components';
import {
  useSelectedItem,
  workflowStepAtomFamily,
} from '@btrway/workflow-configuration-manager';
import {
  Box,
  Button,
  Group,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useAtomCallback } from 'jotai/utils';
import OpenAI from 'openai';
import { ChatCompletionTool } from 'openai/resources';
import React, { useCallback, useState } from 'react';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

interface EmptyFormPromptProps {
  onBuild: (formStructure: FormAssistantResponse) => void;
  onSkip: () => void;
  style?: React.CSSProperties;
}

const convertToStepConfigFormConfig = (
  formAssistant: FormAssistantResponse
): StepConfigFormConfig => {
  return {
    ...formAssistant,
    [Symbol.iterator]: undefined,
  } as StepConfigFormConfig;
};

const EmptyFormPrompt: React.FC<EmptyFormPromptProps> = ({
  onBuild,
  onSkip,
  style,
}) => {
  const [formDescription, setFormDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [progressUpdate, setProgressUpdate] = useState('');
  const { selectedItem } = useSelectedItem();
  const selectedStepKey = selectedItem?.key;

  const setStep = useAtomCallback(
    useCallback(
      (get, set, update: Partial<StepConfig>) => {
        if (selectedStepKey) {
          set(workflowStepAtomFamily(selectedStepKey), update);
        }
      },
      [selectedStepKey]
    )
  );

  const formatSurveyFormFunction: ChatCompletionTool = {
    type: 'function',
    function: {
      name: 'formatSurveyForm',
      description:
        'Format a single-page survey structure based on the provided prompt',
      parameters: {
        type: 'object',
        properties: {
          pages: {
            type: 'array',
            description: 'Array containing a single form page',
            maxItems: 1,
            items: {
              type: 'object',
              properties: {
                name: { type: 'string', description: 'Page name' },
                elements: {
                  type: 'array',
                  description: 'Array of form elements on the page',
                  items: {
                    type: 'object',
                    properties: {
                      type: {
                        type: 'string',
                        description:
                          'The type of form element (e.g., text, checkbox)',
                      },
                      name: {
                        type: 'string',
                        description: 'Unique identifier for the form element',
                      },
                      title: {
                        type: 'string',
                        description: 'The label or title of the form element',
                      },
                    },
                    required: ['type', 'name', 'title'],
                  },
                },
              },
              required: ['name', 'elements'],
            },
          },
        },
        required: ['pages'],
      },
    },
  };

  const extractTextFromPDF = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map((item: any) => item.str).join(' ') + '\n';
    }
    return text;
  };

  const handleBuildClick = async () => {
    if (!formDescription && !file) {
      console.error('No input provided');
      return;
    }

    setLoading(true);
    const openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    try {
      let content: string;
      if (file) {
        if (file.type === 'application/pdf') {
          content = await extractTextFromPDF(file);
        } else {
          content = await file.text();
        }
      } else {
        content = formDescription;
      }

      const response = await openai.chat.completions.create({
        model: 'gpt-4o-mini',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant that helps users create single-page SurveyJS form structures.
            The user will describe a form in natural language, and you will create a JSON structure with all questions on a single page.
            The JSON format must include a "pages" array with exactly one page containing all form elements.
            Each form element must have "type", "name", and "title" properties.
            When responding, always use the formatSurveyForm function and ensure all elements are on a single page.`,
          },
          { role: 'user', content },
        ],
        tools: [formatSurveyFormFunction],
        tool_choice: {
          type: 'function',
          function: { name: 'formatSurveyForm' },
        },
        temperature: 0.7,
      });

      const functionCall = response.choices[0].message.tool_calls?.[0];
      if (functionCall && functionCall.function.name === 'formatSurveyForm') {
        const formStructure: FormAssistantResponse = JSON.parse(
          functionCall.function.arguments
        );
        const configFormStructure =
          convertToStepConfigFormConfig(formStructure);
        setStep({ formConfig: configFormStructure });
        onBuild(formStructure);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error processing input or calling OpenAI API:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDrop = (files: File[]) => {
    setFile(files[0]);
  };

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <Stack
        align="center"
        gap="xl"
        style={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          flex: 1,
          overflow: 'auto',
          padding: '20px',
        }}
      >
        <Title order={1} ta="center">
          Let's Build this Form!
        </Title>

        <Text fz="lg" fw={500} ta="center">
          Describe what you'd like to see in the form, or simply paste in an
          existing document.
        </Text>

        <Textarea
          placeholder="Describe your form here..."
          rows={10}
          style={{ width: '100%' }}
          value={formDescription}
          onChange={(event) => setFormDescription(event.currentTarget.value)}
        />

        <Dropzone
          onDrop={handleDrop}
          maxSize={5 * 1024 ** 2}
          accept={['text/plain', 'application/pdf']}
          multiple={false}
          styles={(theme) => ({
            root: {
              borderWidth: 1,
              borderStyle: 'dashed',
              width: '100%',
            },
          })}
        >
          <Group
            justify="center"
            gap="xl"
            style={{ minHeight: 80, pointerEvents: 'none' }}
          >
            <Text ta="center">Or Optionally Upload an Existing File</Text>
          </Group>
        </Dropzone>

        {file && (
          <Text size="sm" ta="center">
            Selected file: {file.name}
          </Text>
        )}
      </Stack>

      <Group justify="center" gap="md" style={{ padding: '20px' }}>
        <Button onClick={handleBuildClick} size="lg" loading={loading}>
          Build This Form!
        </Button>
        <Button onClick={onSkip} variant="outline" size="lg">
          Start From Scratch
        </Button>
      </Group>
    </Box>
  );
};

export default EmptyFormPrompt;
