import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Anchor } from '@mantine/core';
import React from 'react';

export interface ValueObject {
  key: string;
}

export interface FileDownloadRendererParams extends CustomCellRendererProps {
  value: ValueObject;
}

export const FileDownloadRenderer: React.FC<FileDownloadRendererParams> = ({
  value,
}) => {
  const cloudFrontBaseUrl = 'https://d3ia3v9pvmqoyk.cloudfront.net/';
  const key = value.key;

  // Extract the file name from the S3 key
  const fileName = key.split('/').pop() || '';

  return (
    <Anchor
      href={`${cloudFrontBaseUrl}${key}`}
      target="_blank"
      rel="noopener noreferrer"
      fz="sm"
    >
      {fileName}
    </Anchor>
  );
};
