import { WorkflowTemplateResponse } from '@btrway/api-workflow';
import { useOrganizationTypes } from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/security-manager';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { DeploymentButton } from '@btrway/workflow-template-deployment';
import {
  Badge,
  Box,
  Card,
  Flex,
  Group,
  SegmentedControl,
  Skeleton,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getWorkflowTemplatePath,
  WORKFLOW_TYPE_CONFIG,
} from '../../config/workflowTypeConfig';

// Convert allowed types to SegmentedControl options
const workflowTypeOptions = Object.entries(WORKFLOW_TYPE_CONFIG).map(
  ([value, config]) => ({
    label: config.label,
    value,
  })
);

interface WorkflowCardProps {
  workflow: WorkflowTemplateResponse;
}

const WorkflowCard: React.FC<WorkflowCardProps> = ({ workflow }) => {
  const navigate = useNavigate();
  const { getOrganizationTypeById } = useOrganizationTypes();
  const { getUserRoleBySlug } = useUserRoles();
  const workflowConfig =
    WORKFLOW_TYPE_CONFIG[
      workflow.workflowType as keyof typeof WORKFLOW_TYPE_CONFIG
    ];
  const workflowTypeName = workflowConfig?.label || workflow.workflowType;

  const handleClick = () => {
    const redirectPath = getWorkflowTemplatePath(
      workflow.workflowType,
      workflow.templateKey
    );
    if (redirectPath) {
      navigate(redirectPath);
    }
  };

  const renderFilterSummary = () => {
    const filter = workflow.templateSettings?.organizationFilter;
    if (!filter) return '';

    const states = filter.stateCodes || [];
    const orgTypeIds = filter.organizationTypeIds || [];

    if (!states.length && !orgTypeIds.length) {
      return '';
    }

    const parts: string[] = [];

    if (orgTypeIds.length > 0) {
      const orgTypes = orgTypeIds
        .map((id) => getOrganizationTypeById(id)?.name)
        .filter(Boolean)
        .sort()
        .join(', ');
      if (orgTypes) {
        parts.push(`Org Types: ${orgTypes}`);
      }
    }

    if (states.length > 0) {
      parts.push(`States: ${[...states].sort().join(', ')}`);
    }

    return parts.join(' • ');
  };

  const renderRoleSummary = () => {
    const roles = workflow.templateSettings?.defaultUserRoles || [];
    if (!roles.length) return '';

    const roleNames = roles
      .map((slug) => {
        const role = getUserRoleBySlug(slug);
        return role?.name;
      })
      .filter(Boolean)
      .sort()
      .join(', ');

    return roleNames ? `Default Roles: ${roleNames}` : '';
  };

  return (
    <Card
      padding="sm"
      radius="md"
      withBorder
      style={{ width: '300px', height: '120px', cursor: 'pointer' }}
      onClick={handleClick}
    >
      <Stack gap="xs">
        <Stack gap={4}>
          <Text size="sm" fw={500} lineClamp={1}>
            {workflow.name}
          </Text>
          <Group gap="xs">
            <Badge size="xs" variant="light">
              {workflowTypeName}
            </Badge>
          </Group>
        </Stack>
        <Text size="xs" c="dimmed" lineClamp={2}>
          {workflow.description}
        </Text>
        <Stack gap={2}>
          <Text size="xs" c="dimmed" style={{ fontStyle: 'italic' }}>
            {renderFilterSummary()}
          </Text>
          <Text size="xs" c="dimmed" style={{ fontStyle: 'italic' }}>
            {renderRoleSummary()}
          </Text>
        </Stack>
      </Stack>
    </Card>
  );
};

const WorkflowTemplatesPage: React.FC = () => {
  const {
    workflowTemplates,
    isLoading,
    error,
    addWorkflowTemplate,
    addFormWorkflowTemplate,
    deleteWorkflowTemplate,
  } = useWorkflowTemplates();

  const [selectedType, setSelectedType] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [opened, { open, close }] = useDisclosure(false);

  const filteredWorkflows = React.useMemo(() => {
    if (!workflowTemplates) return [];

    let filtered = [...workflowTemplates];

    // Only include allowed workflow types
    filtered = filtered.filter((workflow) =>
      Object.keys(WORKFLOW_TYPE_CONFIG).includes(workflow.workflowType)
    );

    // Filter by type
    if (selectedType !== 'all') {
      filtered = filtered.filter(
        (workflow) => workflow.workflowType === selectedType
      );
    }

    // Filter by search term
    if (debouncedSearchTerm) {
      const searchLower = debouncedSearchTerm.toLowerCase();
      filtered = filtered.filter(
        (workflow) =>
          workflow.name?.toLowerCase().includes(searchLower) ||
          workflow.description?.toLowerCase().includes(searchLower)
      );
    }

    return filtered.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflowTemplates, selectedType, debouncedSearchTerm]);

  if (!workflowTemplates) {
    return (
      <Box m="lg">
        <Stack gap="md">
          <Skeleton height={40} radius="md" />
          <Skeleton height={40} radius="md" />
          <Flex wrap="wrap" gap="md">
            {Array.from({ length: 8 }).map((_, idx) => (
              <Skeleton key={idx} width={250} height={140} radius="md" />
            ))}
          </Flex>
        </Stack>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Stack gap="md">
          <Group justify="space-between" align="flex-end">
            <Group gap="md" style={{ flex: 1 }}>
              <SegmentedControl
                variant="chip"
                value={selectedType}
                onChange={setSelectedType}
                data={[{ label: 'All', value: 'all' }, ...workflowTypeOptions]}
                style={{ flex: 1 }}
              />
              <TextInput
                placeholder="Search workflows..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                leftSection={<IconSearch size="1.1rem" />}
                rightSection={
                  searchTerm && (
                    <IconX
                      size="1.1rem"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setSearchTerm('')}
                    />
                  )
                }
                style={{ width: '400px' }}
              />
              <Box style={{ width: 'auto' }}>
                <DeploymentButton />
              </Box>
            </Group>
          </Group>

          <Flex wrap="wrap" gap="md">
            {filteredWorkflows.map((workflow) => (
              <WorkflowCard key={workflow.id} workflow={workflow} />
            ))}
          </Flex>

          {filteredWorkflows.length === 0 && (
            <Text c="dimmed" ta="center" py="xl">
              No workflows found matching your criteria
            </Text>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default WorkflowTemplatesPage;
