import { useAddHelpTags } from '@btrway/help-tag-manager';
import { HeaderLayout } from '@btrway/layout-components';
import React from 'react';

const InternalDashboardPage: React.FC = () => {
  console.log('InternalDashboardPage');
  useAddHelpTags(['internal-dashboard']);

  return (
    <HeaderLayout>
      <div
        style={{
          flex: '1',
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        Internal Support Dashboard
      </div>
    </HeaderLayout>
  );
};

export default InternalDashboardPage;
