import {
  RegistrationFormDate,
  RegistrationFormDateTypeEnum,
} from '@btrway/api-core';
import { MonthDaySelector } from '@btrway/shared-components';
import {
  Center,
  Group,
  MantineColor,
  NumberInput,
  SegmentedControl,
  Stack,
} from '@mantine/core';
import React from 'react';

interface RegistrationFormDateSelectorProps {
  value: RegistrationFormDate;
  onChange: (date: RegistrationFormDate) => void;
  bg?: MantineColor;
  color?: MantineColor;
}

export const RegistrationFormDateSelector: React.FC<
  RegistrationFormDateSelectorProps
> = ({ value, onChange, bg, color }) => {
  const handleTypeChange = (type: string) => {
    const newValue: RegistrationFormDate = {
      type: type as RegistrationFormDateTypeEnum,
    };

    switch (type as RegistrationFormDateTypeEnum) {
      case RegistrationFormDateTypeEnum.annualDate:
        newValue.annualMonth = value.annualMonth;
        newValue.annualDay = value.annualDay;
        break;
      case RegistrationFormDateTypeEnum.yearStartOffset:
        newValue.yearOffset = value.yearOffset;
        break;
      case RegistrationFormDateTypeEnum.yearEndOffset:
        newValue.yearOffset = value.yearOffset;
        break;
    }

    onChange(newValue);
  };

  const handleValueChange = (
    key: keyof RegistrationFormDate,
    newValue: number | undefined
  ) => {
    onChange({ ...value, [key]: newValue });
  };

  const handleDateChange = (
    month: number | undefined,
    day: number | undefined
  ) => {
    onChange({ ...value, annualMonth: month, annualDay: day });
  };

  return (
    <Stack gap={0}>
      <SegmentedControl
        value={value.type}
        onChange={handleTypeChange}
        fullWidth
        bg={bg}
        color={color}
        data={[
          {
            label: 'Year Start',
            value: RegistrationFormDateTypeEnum.yearStartOffset,
          },
          {
            label: 'Year End',
            value: RegistrationFormDateTypeEnum.yearEndOffset,
          },
          //Problematic approach: what year is a month/day associated with considering yearstart will be often by middle of year
          //   {
          //     label: 'Specific Date',
          //     value: RegistrationFormDateTypeEnum.annualDate,
          //   },
        ]}
      />
      <Group align="flex-start">
        {value.type === RegistrationFormDateTypeEnum.annualDate && (
          <div>
            <MonthDaySelector
              month={value.annualMonth || 6}
              day={value.annualDay || 1}
              onChange={handleDateChange}
            />
          </div>
        )}
        {value.type === RegistrationFormDateTypeEnum.yearStartOffset && (
          <Center w="100%" p="md">
            <NumberInput
              label="Days Relative to Start of Year"
              description="Use negative number to specify days before the start of the year"
              value={value.yearOffset}
              onChange={(val) =>
                handleValueChange(
                  'yearOffset',
                  val !== '' ? Number(val) : undefined
                )
              }
            />
          </Center>
        )}
        {value.type === RegistrationFormDateTypeEnum.yearEndOffset && (
          <Center w="100%" p="md">
            <NumberInput
              label="Days Relative to End of Year"
              description="Use negative number to specify days before the end of the year"
              value={value.yearOffset}
              onChange={(val) =>
                handleValueChange(
                  'yearOffset',
                  val !== '' ? Number(val) : undefined
                )
              }
            />
          </Center>
        )}
      </Group>
    </Stack>
  );
};
