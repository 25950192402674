import { ThirdPartyTaskProperties } from '@btrway/api-workflow';
import { Box, Button, Card, Group, Stack, Text, TextInput } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';
import TaskFileUpload from '../../components/TaskFileUploader/TaskFileUploader';

const ThirdPartyTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  onClose,
}) => {
  const [storageKeys, setStorageKeys] = useState<string[]>([]);
  const { completeTask } = useTaskActionContext();

  const thirdPartyTaskProps = taskProperties as ThirdPartyTaskProperties;
  const { title, shortDescription, longDescription, price, url } = thirdPartyTaskProps;

  const handleSubmit = async () => {
    try {
      const taskData = {
        storageKeys: {
          storageKeys: storageKeys,
        },
      };

      const result = await completeTask(
        `Third party task completed with ${storageKeys.length} confirmation file(s)`,
        taskData
      );

      if (result.success) {
        onClose();
      }
    } catch (error) {
      console.error('Failed to complete third party task:', error);
    }
  };

  const handleStorageKeysChange = (newStorageKeys: string[]) => {
    setStorageKeys(newStorageKeys);
  };

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <Box
        style={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
          borderBottom: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Text size="xl" fw={700}>
          {title}
        </Text>
      </Box>

      <Box style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
        <Stack gap="md">
          {shortDescription && (
            <Text size="sm" c="dimmed">
              {shortDescription}
            </Text>
          )}

          {longDescription && <Text>{longDescription}</Text>}

          {price && (
            <Text size="lg" fw={500}>
              Price: ${price.toFixed(2)}
            </Text>
          )}

          {url && (
            <Card
              withBorder
              padding="sm"
              component="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ 
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                backgroundColor: 'var(--mantine-color-blue-0)',
                border: '1px solid var(--mantine-color-blue-2)',
              }}
              styles={{
                root: {
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    backgroundColor: 'var(--mantine-color-blue-1)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  }
                }
              }}
            >
              <Group align="center" gap="xs">
                <IconExternalLink size={18} color="var(--mantine-color-blue-6)" />
                <Box>
                  <Text size="sm" fw={500} c="blue.7">
                    Complete this task at external website
                  </Text>
                  <Text size="xs" c="dimmed" style={{ wordBreak: 'break-all' }}>
                    {url}
                  </Text>
                </Box>
              </Group>
            </Card>
          )}

          <Box mt="xl">
            <Text size="sm" fw={500} mb="xs">
              Upload Confirmation
            </Text>
            <Text size="sm" c="dimmed" mb="md">
              Please upload your confirmation or receipt from the external site
            </Text>
            <TaskFileUpload
              storageKeys={storageKeys}
              onStorageKeysChange={handleStorageKeysChange}
            />
          </Box>
        </Stack>
      </Box>

      <Group
        justify="center"
        gap="md"
        style={{
          padding: '20px',
          borderTop: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Button onClick={onClose} variant="outline" size="md">
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          size="md"
          disabled={storageKeys.length === 0}
        >
          Submit
        </Button>
      </Group>
    </Stack>
  );
};

export default ThirdPartyTask;