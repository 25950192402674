/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type CertificationTypeEnum = typeof CertificationTypeEnum[keyof typeof CertificationTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CertificationTypeEnum = {
  taskData: 'taskData',
  personData: 'personData',
  completeCourse: 'completeCourse',
  completeCurriculum: 'completeCurriculum',
  completeTask: 'completeTask',
  completeTaskList: 'completeTaskList',
  completeTaskOption: 'completeTaskOption',
  completeFormPacket: 'completeFormPacket',
  submitForm: 'submitForm',
  thirdParty: 'thirdParty',
  certification: 'certification',
} as const;
