import { AssetResponse } from '@btrway/api-courseware';
import { StorageUsageEnum } from '@btrway/api-storage';
import { useImageUpload } from '@btrway/file-storage-components';
import { startCourseViewerTransitionAtom } from '@btrway/web-routing';
import { ActionIcon, Box, Group, Image, Progress, Text } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';

interface CourseWidgetProps {
  course: AssetResponse;
  completionPercent?: number;
}

const CourseWidget: React.FC<CourseWidgetProps> = ({
  course,
  completionPercent = 0,
}) => {
  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.course,
    initialImageKey: course.metadata?.imageKey,
  });

  const startCourseViewerTransition = useSetAtom(
    startCourseViewerTransitionAtom
  );

  const [animatedGifUrl, setAnimatedGifUrl] = useState('');
  const [isGifLoaded, setIsGifLoaded] = useState(false);

  const handleClick = () => {
    startCourseViewerTransition({ courseUid: course.uid, resume: true });
  };

  useEffect(() => {
    if (course.chapters && course.chapters.length > 0) {
      const playbackId = course.chapters[0].videoPlaybackIdentifier;
      if (playbackId) {
        setAnimatedGifUrl(
          `https://image.mux.com/${playbackId}/animated.gif?start=20&end=30&width=320&fps=15`
        );
      }
    }
  }, [course]);

  return (
    <Box
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <Box style={{ height: '100%', position: 'relative' }}>
        <Image
          src={imageUrl}
          fit="cover"
          height="100%"
          width="100%"
          style={{
            filter: 'brightness(1.1)',
          }}
        />
        {animatedGifUrl && (
          <Image
            src={animatedGifUrl}
            fit="cover"
            height="100%"
            width="100%"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: isGifLoaded ? 1 : 0,
              transition: 'opacity 0.3s ease',
            }}
            onLoad={() => setIsGifLoaded(true)}
          />
        )}
        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <Group justify="flex-start" wrap="nowrap" p="xs">
            <ActionIcon variant="white" radius="xl" color="black">
              <IconPlayerPlayFilled size={16} />
            </ActionIcon>
            <Box style={{ flex: 1 }}>
              <Text fw={500} size="xs" c="white" style={{ lineHeight: 1 }}>
                {course.title}
              </Text>
              <Group gap="xs" mt={4}>
                <Progress
                  value={completionPercent}
                  color="blue"
                  size="sm"
                  style={{ flex: 1 }}
                />
                <Text size="xs" c="white" fw={500}>
                  {Math.round(completionPercent)}%
                </Text>
              </Group>
            </Box>
          </Group>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseWidget;
