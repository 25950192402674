import MuxPlayer, {
  MuxPlayerProps,
  MuxPlayerRefAttributes,
} from '@mux/mux-player-react';
import React, { useCallback, useEffect, useRef } from 'react';
import styles from './StyledMuxPlayer.module.css';

interface StyledMuxPlayerCustomProps {
  initialMaxTime?: number;
  initialCurrentTime?: number;
  preventSeekBeyondMaxTime?: boolean;
  pauseOnMute?: boolean;
  hideDefaultTimeline?: boolean;
  onTimeChange?: (currentTime: number, maxTime: number) => void;
  onPlayingStateChange?: (isPlaying: boolean) => void;
}

type StyledMuxPlayerProps = MuxPlayerProps & StyledMuxPlayerCustomProps;

const StyledMuxPlayer: React.FC<StyledMuxPlayerProps> = (props) => {
  const defaultCustomProps: Required<StyledMuxPlayerCustomProps> = {
    initialCurrentTime: 0,
    initialMaxTime: 0,
    preventSeekBeyondMaxTime: true,
    pauseOnMute: true,
    hideDefaultTimeline: false,
    onTimeChange: () => {},
    onPlayingStateChange: () => {},
  };

  const defaultMuxProps: Partial<MuxPlayerProps> = {};

  const mergedProps = { ...defaultCustomProps, ...defaultMuxProps, ...props };

  const playerRef = useRef<MuxPlayerRefAttributes>(null);
  const maxTimeRef = useRef<number>(mergedProps.initialMaxTime);
  const wasPausedDueToMuteRef = useRef<boolean>(false);
  const isPlayingRef = useRef<boolean>(false);

  useEffect(() => {
    if (playerRef.current && mergedProps.initialCurrentTime) {
      playerRef.current.currentTime = mergedProps.initialCurrentTime;
    }
  }, [mergedProps.initialCurrentTime]);

  const updatePlayingState = useCallback(
    (isPlaying: boolean) => {
      if (isPlayingRef.current !== isPlaying) {
        isPlayingRef.current = isPlaying;
        mergedProps.onPlayingStateChange(isPlaying);
      }
    },
    [mergedProps.onPlayingStateChange]
  );

  const handleVolumeChanged = useCallback(
    (event: Event) => {
      if (playerRef.current && mergedProps.pauseOnMute) {
        const player = playerRef.current;
        if (isPlayingRef.current && (player.volume === 0 || player.muted)) {
          wasPausedDueToMuteRef.current = true;
          player.pause();
        } else if (
          wasPausedDueToMuteRef.current &&
          player.volume > 0 &&
          !player.muted
        ) {
          wasPausedDueToMuteRef.current = false;
          player.play();
        }
      }

      if (props.onVolumeChange) {
        props.onVolumeChange(event);
      }
    },
    [mergedProps.pauseOnMute, props.onVolumeChange]
  );

  const handlePlaying = useCallback(
    (event: Event) => {
      if (playerRef.current && mergedProps.pauseOnMute) {
        const player = playerRef.current;
        if (player.volume === 0 || player.muted) {
          player.pause();
          return;
        }
      }
      updatePlayingState(true);

      if (props.onPlaying) {
        props.onPlaying(event);
      }
    },
    [mergedProps.pauseOnMute, props.onPlaying, updatePlayingState]
  );

  const handlePause = useCallback(
    (event: Event) => {
      updatePlayingState(false);

      if (props.onPause) {
        props.onPause(event);
      }
    },
    [props.onPause, updatePlayingState]
  );

  const handleTimeUpdate = useCallback(
    (event: Event) => {
      if (playerRef.current) {
        const currentTime = playerRef.current.currentTime;
        if (currentTime > maxTimeRef.current) {
          maxTimeRef.current = currentTime;
        }
        if (mergedProps.onTimeChange) {
          mergedProps.onTimeChange(currentTime, maxTimeRef.current);
        }
      }

      if (props.onTimeUpdate) {
        props.onTimeUpdate(event);
      }
    },
    [mergedProps.onTimeChange, props.onTimeUpdate]
  );

  const canSeek = useCallback(
    (requestedTime: number) => {
      return (
        !mergedProps.preventSeekBeyondMaxTime ||
        requestedTime <= maxTimeRef.current
      );
    },
    [mergedProps.preventSeekBeyondMaxTime]
  );

  const handleSeeking = useCallback(
    (event: Event) => {
      if (playerRef.current && mergedProps.preventSeekBeyondMaxTime) {
        const requestedTime = playerRef.current.currentTime;
        if (!canSeek(requestedTime)) {
          event.preventDefault();
          playerRef.current.currentTime = maxTimeRef.current;
        }
      }

      if (props.onSeeking) {
        props.onSeeking(event);
      }
    },
    [canSeek, mergedProps.preventSeekBeyondMaxTime, props.onSeeking]
  );

  const containerClasses = [
    styles.muxPlayerWrapper,
    mergedProps.hideDefaultTimeline ? styles.hideTimeline : '',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={containerClasses}>
      <MuxPlayer
        {...mergedProps}
        style={{ width: '100%', height: '100%' }}
        ref={playerRef}
        onVolumeChange={handleVolumeChanged}
        onPlaying={handlePlaying}
        onPause={handlePause}
        onTimeUpdate={handleTimeUpdate}
        onSeeking={handleSeeking}
        poster=""
        autoPlay
      />
    </div>
  );
};

export default StyledMuxPlayer;
