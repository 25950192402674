// hooks/useChapterBuilderSet.ts
import { ChapterDTO } from '@btrway/api-courseware';
import { uuid } from '@btrway/utils';
import { useSetAtom } from 'jotai';
import { chapterAtomFamily } from '../atoms/chapterAtomFamily';
import { chaptersAtom } from '../atoms/chaptersAtom';

export const useChapterBuilderSet = () => {
  const setChapters = useSetAtom(chaptersAtom);

  const addChapter = (newChapter: ChapterDTO) => {
    setChapters((prev) => [
      ...prev,
      { ...newChapter, key: newChapter.key || uuid() },
    ]);
  };

  const updateChapter = (key: string, update: Partial<ChapterDTO>) => {
    const setChapter = useSetAtom(chapterAtomFamily(key));
    setChapter(update);
  };

  const removeChapter = (key: string) => {
    setChapters((prev) => prev.filter((chapter) => chapter.key !== key));
  };

  const initializeChapters = (initialChapters: ChapterDTO[]) => {
    setChapters(
      initialChapters.map((chapter) => ({
        ...chapter,
        key: chapter.key || uuid(),
      }))
    );
  };

  return {
    addChapter,
    updateChapter,
    removeChapter,
    initializeChapters,
  };
};
