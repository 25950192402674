import { TaskCompleter } from '@btrway/task-completer';
import { useInitializeTaskManager, useTaskModal } from '@btrway/task-manager';
import { memo } from 'react';

const TaskManager: React.FC<{
  children: React.ReactNode;
}> = memo(({ children }) => {
  const { modalState, closeTask } = useTaskModal();
  const { isInitialized, error } = useInitializeTaskManager();

  return (
    <>
      {children}
      <TaskCompleter
        isOpen={modalState.isOpen}
        taskProperties={modalState.taskProperties}
        taskInstanceId={modalState.taskInstanceId}
        assignedEntityType={modalState.assignedEntityType}
        assignedEntityId={modalState.assignedEntityId}
        workgroupId={modalState.workgroupId}
        derivedWorkflowKey={modalState.derivedWorkflowKey}
        taskKey={modalState.taskKey}
        taskType={modalState.taskType}
        onClose={closeTask}
      />
    </>
  );
});

TaskManager.displayName = 'TaskManager';

export default TaskManager;
