// import { useRightSection } from '@btrway/workflow-builder-rightsection';
import {
  Box,
  CloseButton,
  Group,
  Stack,
  Text,
  Transition,
} from '@mantine/core';
import React from 'react';
import { SETTINGS_PANEL_LAYOUT } from '../../constants/layout';
import { PanelPosition } from '../../types/settingsDisplay';

export interface SettingsPanelProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: PanelPosition;
  width?: string | number;
  title?: string;
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({
  isOpen,
  onClose,
  children,
  position = 'left',
  width = 400,
  title = 'Settings',
}) => {
  // const { rightSectionContent, clearRightSectionContent } = useRightSection();
  // const rightSectionWidth = rightSectionContent?.width || '300px';

  const getTransform = () => {
    if (position === 'left') {
      return 'translateX(-100%)';
    }
    return 'translateX(100%)';
  };

  return (
    <Transition
      mounted={isOpen}
      transition={{
        in: { transform: 'translateX(0)' },
        out: { transform: getTransform() },
        common: { transition: 'transform 300ms ease' },
        transitionProperty: 'transform',
      }}
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <Box
          style={{
            ...styles,
            position: 'absolute',
            top: 0,
            [position]: 0,
            height: '100%',
            backgroundColor: 'white',
            borderRight: position === 'left' ? '1px solid #e9ecef' : undefined,
            borderLeft: position === 'right' ? '1px solid #e9ecef' : undefined,
            zIndex: 1,
            display: 'flex',
            flexDirection: position === 'left' ? 'row' : 'row-reverse',
            boxShadow: `${
              position === 'left' ? '5px' : '-5px'
            } 0 15px rgba(0, 0, 0, 0.1)`,
          }}
        >
          <Stack
            style={{
              width: typeof width === 'number' ? `${width}px` : width,
              minWidth: typeof width === 'number' ? `${width}px` : width,
              height: '100%',
            }}
            gap={0}
          >
            <Group
              justify="space-between"
              p="xs"
              wrap="nowrap"
              bg="gray.1"
              style={{
                borderBottom: '1px solid var(--mantine-color-gray-3)',
                height: `${SETTINGS_PANEL_LAYOUT.HEADER_HEIGHT}px`,
                flexShrink: 0,
              }}
            >
              <Text fz="md" fw={600}>
                {title}
              </Text>
              <CloseButton onClick={onClose} aria-label="Close panel" />
            </Group>
            <Box
              style={{
                height: `calc(100% - ${SETTINGS_PANEL_LAYOUT.HEADER_HEIGHT}px)`,
                position: 'relative',
              }}
            >
              {children}
            </Box>
          </Stack>
        </Box>
      )}
    </Transition>
  );
};
