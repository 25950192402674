import {
  EventConfig,
  EventTypeEnum,
  FormSubmittedEventProperties,
} from '@btrway/api-workflow';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-configuration-manager';
import { useEventTypes } from '@btrway/workflow-manager';
import { Badge, Text } from '@mantine/core';
import React, { useMemo } from 'react';

interface EventConfigContentProps {
  event: EventConfig;
  workflow: CommonWorkflow;
}

export const EventConfigContent: React.FC<EventConfigContentProps> = ({
  event,
  workflow,
}) => {
  const workflowService = useWorkflowService();
  const { workflows } = workflowService.getWorkflows();
  const { getEventType } = useEventTypes();

  const eventType = getEventType(event.eventTypeCode);

  const getWorkflowByKey = useMemo(() => {
    return (workflowKey: string) =>
      workflows.find((w) => {
        const key =
          w.sourceType === 'definition' ? w.workflowKey : w.templateKey;
        return key === workflowKey;
      });
  }, [workflows]);

  switch (event.eventTypeCode) {
    case EventTypeEnum.personCreated:
    case EventTypeEnum.workgroupMemberCreated:
      return (
        <>
          {eventType?.name}
          {workflow.entityName && (
            <>
              :{' '}
              <Badge size="lg" color="blue">
                {workflow.entityName}
              </Badge>
            </>
          )}
        </>
      );

    case EventTypeEnum.formSubmitted: {
      const baseProps = event.basePropertyConfig;
      const props = baseProps as FormSubmittedEventProperties;
      const form = props?.workflowDefinitionKey
        ? getWorkflowByKey(props.workflowDefinitionKey)
        : undefined;
      return (
        <>
          {form ? (
            <>
              <Text component="span" c="blue.8" fw={600} inherit>
                {form.name}
              </Text>{' '}
              submitted
            </>
          ) : (
            'Form submitted'
          )}
        </>
      );
    }

    default:
      return <>{event.title || event.eventTypeCode}</>;
  }
};
