// src/components/TaskListImportModal.tsx

import { useGetTaskCategories } from '@btrway/api-workflow';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { useTaskTypes } from '@btrway/workflow-manager';
import { Button } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { ReactSpreadsheetImport } from 'react-spreadsheet-import';
import { Result } from 'react-spreadsheet-import/types/types';
import { useTaskListImport } from '../../hooks/useTaskListImport';
import { ImportedTask } from '../../types/ImportedTask';
import { createTaskListImportFields } from '../../utils/createTaskListImportFields';

interface TaskListImportModalProps {}

const TaskListImportModal: React.FC<TaskListImportModalProps> = () => {
  useAddHelpTags(['import-task-list']);

  const [isOpen, setIsOpen] = useState(false);
  const { taskTypes, isLoading, error } = useTaskTypes();
  const { data: taskCategories } = useGetTaskCategories();
  const { processImportedData, isImporting, importError } = useTaskListImport();

  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data: Result<keyof ImportedTask>, file: File) => {
    console.log('Imported data:', data);
    try {
      if (data && Array.isArray(data.validData)) {
        processImportedData(data.validData);
        setIsOpen(false);
      } else {
        throw new Error(
          'Invalid data structure: expected an array in validData property'
        );
      }
    } catch (err) {
      console.error('Error processing imported data:', err);
    }
  };

  const fields = useMemo(
    () => createTaskListImportFields(taskTypes, taskCategories || []),
    [taskTypes, taskCategories]
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error loading task types:', error);
    return <div>Error loading task types</div>;
  }

  return (
    <>
      <Button
        leftSection={<IconUpload size={16} />}
        onClick={() => setIsOpen(true)}
        disabled={isImporting}
      >
        Import To-Do List
      </Button>
      {isOpen && (
        <ReactSpreadsheetImport
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          fields={fields}
          autoMapSelectValues={true}
          // autoMapDistance={0.3}
          autoMapHeaders={true}
        />
      )}
      {importError && <div>Error: {importError}</div>}
    </>
  );
};

export default TaskListImportModal;
