import { useAuth } from '@btrway/auth-core';
import React, { useEffect } from 'react';
import { useAuthWeb } from '../../hooks/useAuthWeb';

const OAuth2Success: React.FC = () => {
  const { loginWithToken } = useAuth();
  const { handleLoginSuccess } = useAuthWeb();

  // const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      loginWithToken(token)
        .then(({ success, error, currentUser }) => {
          if (success && currentUser) {
            // handleLoginSuccess(token, currentUser);
          } else {
            console.error('Google login failed:', error);
          }
        })
        .catch((error) => {
          console.error('An error occurred during Google login:', error);
        });
    }
  }, []);

  return <></>;
};

export default OAuth2Success;
