import { Container, Loader, Stack, Text } from '@mantine/core';
import React from 'react';

export const LoadingIndicator: React.FC = () => {
  return (
    <Container size="sm" style={{ textAlign: 'center', padding: '2rem' }}>
      <Stack gap="md" align="center">
        <Loader size="lg" />
        <Text>Initializing registration form...</Text>
      </Stack>
    </Container>
  );
};
