import { Button } from '@mantine/core';
import { IconUpload } from '@tabler/icons-react';
import React, { DependencyList, useEffect, useRef, useState } from 'react';
import ReactCrop, {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

interface ImageUploadCropProps {
  onCroppedImage: (croppedImageUrl: string) => void;
  currentImageUrl: string;
  isEditingCurrent: boolean;
  aspectRatio?: { width: number; height: number };
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 80,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ImageUploadCrop: React.FC<ImageUploadCropProps> = ({
  onCroppedImage,
  isEditingCurrent,
  currentImageUrl,
  aspectRatio = { width: 1, height: 1 },
}) => {
  const [imgSrc, setImgSrc] = useState<string>(currentImageUrl);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const aspect = aspectRatio.width / aspectRatio.height;
  const hiddenCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingCurrent && currentImageUrl) {
      setImgSrc(currentImageUrl);
      if (fileInputRef.current) {
        fetch(currentImageUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], 'currentProfilePic.png', {
              type: 'image/png',
            });
            const reader = new FileReader();
            reader.onload = () => {
              setImgSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
          })
          .catch((error) => {
            console.error('Error fetching image:', error);
          });
      }
    } else {
      setImgSrc('');
    }
  }, [isEditingCurrent, currentImageUrl]);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  function useDebounceEffect(
    fn: () => void,
    waitTime: number,
    deps?: DependencyList
  ) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn();
      }, waitTime);

      return () => {
        clearTimeout(t);
      };
    }, deps || []);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        hiddenCanvasRef.current
      ) {
        const canvas = hiddenCanvasRef.current;
        const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
        const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
        canvas.width = completedCrop.width * scaleX;
        canvas.height = completedCrop.height * scaleY;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('No 2d context');
        }

        ctx.drawImage(
          imgRef.current,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY
        );

        const blob = await new Promise<Blob | null>((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/png');
        });

        if (blob) {
          if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current);
          }
          blobUrlRef.current = URL.createObjectURL(blob);
          onCroppedImage(blobUrlRef.current);
        }
      }
    },
    100,
    [completedCrop]
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onSelectFile}
        style={{ display: 'none' }}
      />
      <Button onClick={handleButtonClick} style={{ marginBottom: '1rem' }}>
        <IconUpload size={14} />
        &nbsp; Choose Image
      </Button>
      {!!imgSrc && (
        <div
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
            marginBottom: '20px',
          }}
        >
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            minHeight={100}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
              }}
            />
          </ReactCrop>
        </div>
      )}
      <canvas ref={hiddenCanvasRef} style={{ display: 'none' }} />
    </div>
  );
};

export default ImageUploadCrop;
