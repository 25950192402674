import {
  CourseTaskProperties,
  EntityTaskResponse,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { useCoursewareProgress } from '@btrway/courseware-progress-manager';
import { EntityAvatar } from '@btrway/entity-tags';
import { useTaskModal } from '@btrway/task-manager';
import { formatDateTime } from '@btrway/utils';
import { TaskTypeIcon } from '@btrway/workflow-components';
import {
  ColDef,
  GetRowIdParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useMemo, useRef } from 'react';
import styles from './TaskListGrid.module.css';

interface TaskListGridProps {
  tasks: EntityTaskResponse[];
  onTaskUpdate?: () => void;
}

const getTaskKey = (task: EntityTaskResponse): string => {
  if (task.taskInstanceId) {
    return `instance-${task.taskInstanceId}`;
  }
  return `derived-${task.parentWorkflowKey}-${task.taskKey}-${task.assignedEntityId}`;
};

const TaskListGrid: React.FC<TaskListGridProps> = ({ tasks, onTaskUpdate }) => {
  const { openTask } = useTaskModal();
  const gridRef = useRef<AgGridReact>(null);
  const { getAllCourseProgress } = useCoursewareProgress();

  const handleTaskClick = useCallback(
    (task: EntityTaskResponse) => {
      if (!task.taskProperties) {
        console.warn('Task clicked without taskProperties:', task);
        return;
      }

      openTask({
        taskProperties: task.taskProperties,
        taskInstanceId: task.taskInstanceId,
        assignedEntityType: task.assignedEntityType,
        assignedEntityId: task.assignedEntityId,
        workgroupId: task.workgroupId,
        derivedWorkflowKey: task.parentWorkflowKey,
        taskKey: task.taskKey,
        taskType: task.taskType,
      });
    },
    [openTask]
  );

  const getRowId = useCallback(
    (params: GetRowIdParams<EntityTaskResponse>) => getTaskKey(params.data),
    []
  );

  const getTaskStatus = useCallback(
    (task: EntityTaskResponse) => {
      if (
        task.taskType === TaskTypeEnum.completeCourse &&
        task.taskProperties
      ) {
        const properties = task.taskProperties as CourseTaskProperties;
        const courseId = properties.courseSelection.courseUid?.value;

        if (courseId !== undefined) {
          const allProgress = getAllCourseProgress();
          return allProgress[courseId];
        }
      }
      return null;
    },
    [getAllCourseProgress]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'taskTitle',
        headerName: 'Task',
        flex: 3,
        getQuickFilterText: (params) => params.value || '',
      },
      {
        field: 'assignedEntityId',
        headerName: 'Owner',
        flex: 1,
        cellRenderer: 'entityAvatarRenderer',
      },
      {
        field: 'taskType',
        headerName: 'Type',
        flex: 1,
        cellRenderer: 'taskTypeRenderer',
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      {
        field: 'assignedAt',
        headerName: 'Assigned',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams<EntityTaskResponse>) => {
          if (!params.value) return '';
          const date = new Date(params.value);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        },
      },
      {
        field: 'dueAt',
        headerName: 'Due',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams<EntityTaskResponse>) => {
          if (!params.value) return '';
          const date = new Date(params.value);
          return `${date.getMonth() + 1}/${date.getDate()}`;
        },
        cellClass: (params: ValueFormatterParams<EntityTaskResponse>) => {
          if (!params.value) return '';
          const dueDate = new Date(params.value);
          return dueDate < new Date() ? styles.overdueCellHighlight : '';
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 2,
        valueGetter: (params: ValueGetterParams<EntityTaskResponse>) => {
          const task = params.data;
          if (!task) return '';

          const courseStatus = getTaskStatus(task);
          if (courseStatus) {
            if (courseStatus.status === 'Complete') {
              return 'Completed';
            } else if (courseStatus.completionPercent > 0) {
              return `${courseStatus.completionPercent.toFixed(1)}% Complete`;
            }
          }

          if (task.completed) {
            return task.completedAt
              ? `Completed: ${formatDateTime(task.completedAt)}`
              : 'Completed';
          }
          return 'Not Started Yet';
        },
        cellClass: (params: ValueFormatterParams<EntityTaskResponse>) => {
          const task = params.data;
          if (!task) return '';

          const courseStatus = getTaskStatus(task);
          if (courseStatus?.status === 'Complete' || task.completed) {
            return styles.completedCellHighlight;
          }
          return '';
        },
      },
    ],
    [getTaskStatus]
  );

  const components = useMemo(
    () => ({
      entityAvatarRenderer: (props: { data: EntityTaskResponse }) => {
        const entityType = props.data.assignedEntityType;
        if (entityType !== 'person' && entityType !== 'userRole') {
          return null;
        }

        return (
          <EntityAvatar
            id={props.data.assignedEntityId}
            entityType={entityType}
            size="sm"
            withTooltip
          />
        );
      },
      taskTypeRenderer: (props: { data: EntityTaskResponse }) => {
        const taskType = props.data?.taskType;
        return taskType ? <TaskTypeIcon iconName={taskType} size={20} /> : null;
      },
    }),
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: false,
      filter: false,
      resizable: false,
      suppressMovable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onRowClicked = useCallback(
    (event: any) => {
      handleTaskClick(event.data);
    },
    [handleTaskClick]
  );

  const sortedTasks = useMemo(() => {
    return [...tasks].sort((a, b) => {
      const aTitle = a.taskTitle || '';
      const bTitle = b.taskTitle || '';
      return aTitle.localeCompare(bTitle);
    });
  }, [tasks]);

  return (
    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
      <AgGridReact
        ref={gridRef}
        rowData={sortedTasks}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        domLayout="autoHeight"
        rowHeight={50}
        headerHeight={50}
        className={styles.taskListGrid}
        onRowClicked={onRowClicked}
        getRowId={getRowId}
        components={components}
        animateRows={true}
        suppressRowTransform={true}
        enableCellTextSelection={false}
        suppressPropertyNamesCheck={true}
        suppressColumnVirtualisation={true}
      />
    </div>
  );
};

export default TaskListGrid;
