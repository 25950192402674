import { TopNavigationLayout } from '@btrway/layout-components';
import {
  CertificationsPage,
  CurriculumsPage,
  OversightPage,
  TodosPage,
  WorkgroupStatsProvider,
} from '@btrway/oversight-components';
import { ApprovalsPage } from '@btrway/scoreboard-components';
import { RouteConfig } from '@btrway/web-routing';
import {
  WorkgroupHeader,
  WorkgroupProvider,
  WorkgroupRedirect,
} from '@btrway/workgroup-components';
import { IconEye, IconHome } from '@tabler/icons-react';
import { lazy } from 'react';

// Lazy loaded workgroup components
const WorkgroupCalendarView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupCalendarView,
  }))
);
const WorkgroupCommunicationsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupCommunicationsView,
  }))
);
const MessageDetailPage = lazy(() =>
  import('@btrway/message-detail').then((module) => ({
    default: module.MessageDetailPage,
  }))
);
const WorkgroupDashboardView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupDashboardView,
  }))
);
const WorkgroupPeopleView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupPeopleView,
  }))
);
const WorkgroupRegistrationsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupRegistrationsView,
  }))
);
const WorkgroupSubgroupsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupSubgroupsView,
  }))
);
const WorkgroupTasksView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupTasksView,
  }))
);
const WorkgroupFacilitiesPage = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupFacilitiesPage,
  }))
);
const WorkgroupCertificationsView = lazy(() =>
  import('@btrway/workgroup-components').then((module) => ({
    default: module.WorkgroupCertificationsView,
  }))
);

// Lazy loaded registration form components
const RegistrationFormsView = lazy(() =>
  import('@btrway/registration-form-administration').then((module) => ({
    default: module.RegistrationFormsView,
  }))
);
const RegistrationFormDetail = lazy(() =>
  import('@btrway/registration-form-administration').then((module) => ({
    default: module.RegistrationFormDetail,
  }))
);

export const oversightRoutes: RouteConfig[] = [
  {
    path: 'oversight',
    element: <WorkgroupRedirect path="oversight" />,
    label: 'Oversight',
    // labelComponent: WorkgroupNavLabel,
    icon: IconEye,
    navLevel: 'primary',
    navContainer: 'second',
    permissions: ['WORKGROUP'],
  },
  {
    path: 'oversight/:workgroupId',
    label: 'Oversight',
    element: (
      <WorkgroupProvider>
        <WorkgroupStatsProvider>
          <TopNavigationLayout
            subHeader={<WorkgroupHeader path="oversight" />}
            contentPadding={0}
            headerBackgroundColor="#25274D"
            variant="dark"
          />
        </WorkgroupStatsProvider>
      </WorkgroupProvider>
    ),
    children: [
      {
        path: 'home',
        element: <OversightPage />,
        label: 'Dashboard',
        icon: IconHome,
        navLevel: 'secondary',
        displayType: 'icon',
      },
      {
        path: 'certifications',
        element: <CertificationsPage />,
        label: 'Certifications',
        navLevel: 'secondary',
        displayType: 'label',
        // permissions: ['WORKGROUP_TASKS'],
      },
      {
        path: 'todos',
        element: <TodosPage />,
        label: 'To-Dos',
        navLevel: 'secondary',
        displayType: 'label',
        permissions: ['WORKGROUP_TASKS'],
      },
      {
        path: 'approvals',
        element: <ApprovalsPage />,
        label: 'Approvals',
        navLevel: 'secondary',
        displayType: 'label',
        permissions: ['WORKGROUP_TASKS'],
      },
      {
        path: 'curriculums',
        element: <CurriculumsPage />,
        label: 'Curriculums',
        navLevel: 'secondary',
        displayType: 'label',
        permissions: ['WORKGROUP_TASKS'],
      },
    ],
  },
];
