import { useAtom } from 'jotai';
import { chapterAtomFamily } from '../atoms/chapterAtomFamily';

export const useChapter = (key: string) => {
  const [chapter, setChapter] = useAtom(chapterAtomFamily(key));

  return {
    chapter,
    setChapter,
  };
};
