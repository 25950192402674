import React from 'react';
import { useScreen } from '../../hooks/useScreen';
import { useWorkflowBuilderState } from '../../hooks/useWorkflowBuilderState';
import { ScreenHandlers } from '../../types/handlers';
import { Modal } from '../Modal/Modal';
import { Panel } from '../Panel/Panel';
import { ScreenRenderer } from '../ScreenRenderer/ScreenRenderer';

interface DisplayLayoutProps {
  children: React.ReactNode;
}

export const DisplayLayout: React.FC<DisplayLayoutProps> = ({ children }) => {
  const { state, closeScreen } = useWorkflowBuilderState();
  const { displayState, currentScreen } = state;
  const screenContext = useScreen();

  // Ensure handlers are properly constructed with all required properties
  const handlers: ScreenHandlers = {
    onClose: closeScreen,
    onToolboxSelect: screenContext.screenHandlers.onToolboxSelect,
    onWorkflowSelect: screenContext.screenHandlers.onWorkflowSelect,
    onCourseSelect: screenContext.screenHandlers.onCourseSelect,
    onSave: screenContext.screenHandlers.onSave,
    onDelete: screenContext.screenHandlers.onDelete,
    onEditorSave: screenContext.screenHandlers.onEditorSave,
  };

  if (!currentScreen) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      {displayState.isOpen && (
        <>
          {displayState.config.mode === 'panel' ? (
            <Panel
              isOpen={displayState.isOpen}
              position={displayState.config.position}
              width={displayState.config.width}
              title={displayState.config.modalProps?.title as string}
              onClose={closeScreen}
            >
              <ScreenRenderer screen={currentScreen} handlers={handlers} />
            </Panel>
          ) : (
            <Modal
              isOpen={displayState.isOpen}
              onClose={closeScreen}
              config={displayState.config.modalProps}
            >
              <ScreenRenderer screen={currentScreen} handlers={handlers} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};
