import {
  EventTypeRequest,
  SystemEventTypeEnum,
  useDeleteEventType,
  useGetEventTypesByOrganizationId,
  useSaveEventType,
} from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Button, Group, LoadingOverlay, Stack, Title } from '@mantine/core';
import React, { useState } from 'react';
import { EventTypeCard } from '../../components/EventTypeCard/EventTypeCard';

export const EventTypesPage: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const {
    data: eventTypes,
    isLoading,
    refetch,
  } = useGetEventTypesByOrganizationId(currentOrganization.id);
  const { mutateAsync: saveEventType } = useSaveEventType();
  const { mutateAsync: deleteEventType } = useDeleteEventType();
  const [showNewCard, setShowNewCard] = useState(false);

  const handleSave = async (eventType: EventTypeRequest) => {
    const updatedEventType = {
      ...eventType,
      organizationId: currentOrganization.id,
    };
    await saveEventType({ data: updatedEventType });
    await refetch();
    if (!eventType.id) {
      setShowNewCard(false);
    }
  };

  const handleDelete = async (id: number) => {
    await deleteEventType({ eventTypeId: id });
    await refetch();
  };

  const filteredAndSortedEventTypes = eventTypes
    ?.filter(
      (eventType) => eventType.systemType !== SystemEventTypeEnum.availability
    )
    ?.slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return (
    <Stack gap="md">
      <Group justify="space-between" align="center">
        <Title order={2}>Event Types</Title>
        <Button onClick={() => setShowNewCard(true)} disabled={showNewCard}>
          Add Event Type
        </Button>
      </Group>

      {showNewCard && (
        <EventTypeCard
          isNew
          eventType={{
            name: '',
            organizationId: currentOrganization.id,
          }}
          onCancel={() => setShowNewCard(false)}
          onSave={handleSave}
        />
      )}

      {filteredAndSortedEventTypes?.map((eventType) => (
        <EventTypeCard
          key={eventType.id}
          eventType={eventType}
          onCancel={() => {}}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      ))}
    </Stack>
  );
};
