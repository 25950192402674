import { useAuthContext } from '@btrway/auth-core';
import { useCurrentUser } from '@btrway/current-user';
import { Alert, Card, Stack, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { registrationFlowAtom } from '../../atoms/registrationFlowAtom';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';
import { LoginForm } from '../LoginForm/LoginForm';
import { SignUpForm } from '../SignUpForm/SignUpForm';

const AuthenticationStep: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [flowState, setFlowState] = useAtom(registrationFlowAtom);
  const { updateRegistrant } = useRegistrationSubmission();

  const { authState } = useAuthContext();
  const { currentUser, currentPerson } = useCurrentUser();

  //TODO: Needs to get the person for the appropriate/respective org

  if (
    authState.isAuthenticated &&
    currentUser != undefined &&
    currentPerson != undefined
  ) {
    updateRegistrant('firstName', currentUser.user.firstName || '');
    updateRegistrant('lastName', currentUser.user.lastName || '');
    updateRegistrant('email', currentUser.user.email);
    updateRegistrant('mobilePhone', currentUser.user.mobilePhone || '');
    updateRegistrant('personId', currentPerson.id);

    const userData = {
      firstName: currentUser.user.firstName,
      lastName: currentUser.user.lastName,
      email: currentUser.user.email,
      mobilePhone: currentUser.user.mobilePhone,
      personId: currentPerson.id,
    };

    setFlowState((prev) => ({
      ...prev,
      completedSteps: [...prev.completedSteps, prev.currentStep],
      currentStep: prev.currentStep + 1,
      reachedSteps: [...prev.reachedSteps, prev.currentStep + 1],
      userData,
    }));
  }

  const handleAuthSuccess = (userData: any) => {
    console.log('handleAuthSuccess -> userData', userData);

    updateRegistrant('firstName', userData.firstName);
    updateRegistrant('lastName', userData.lastName);
    updateRegistrant('email', userData.email);
    updateRegistrant('mobilePhone', userData.phoneNumber);
    updateRegistrant('personId', userData.personId);

    setFlowState((prev) => ({
      ...prev,
      completedSteps: [...prev.completedSteps, prev.currentStep],
      currentStep: prev.currentStep + 1,
      reachedSteps: [...prev.reachedSteps, prev.currentStep + 1],
      userData,
    }));
  };

  return (
    <Stack>
      <Card withBorder radius="sm" padding="sm" shadow="none" bg="blue.0">
        <Alert
          icon={<IconLock size="1.1rem" />}
          title="Secure & Private"
          color="blue"
          variant="light"
        >
          <Text size="sm">
            Your information is protected and will only be used for registration
            purposes. We never share your personal details with third parties.
          </Text>
        </Alert>
      </Card>

      <Card withBorder radius="sm" padding="lg" shadow="none">
        {isSignUp ? (
          <SignUpForm
            onSuccess={handleAuthSuccess}
            onBackToLogin={() => setIsSignUp(false)}
          />
        ) : (
          <LoginForm
            onSuccess={handleAuthSuccess}
            onCreateAccount={() => setIsSignUp(true)}
          />
        )}
      </Card>
    </Stack>
  );
};

export default AuthenticationStep;
