// src/components/SearchResultOption/SearchResultOption.tsx
import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { Badge } from '@mantine/core';
import React from 'react';
import { getEntityTypeColor, getEntityTypeIcon } from '../../utils/entityUtils';

interface SearchResultOptionProps {
  result: GlobalSearchResponse;
  showSimilarity?: boolean;
  showBadge?: boolean;
  variant?: 'default' | 'compact';
}

export const SearchResultOption: React.FC<SearchResultOptionProps> = ({
  result,
  showSimilarity = true,
  showBadge = false,
  variant = 'default',
}) => (
  <div className="flex justify-between items-center gap-2">
    <span className="flex items-center gap-2">
      <span>{getEntityTypeIcon(result.entityType as DataTypeEnum)}</span>
      <span>{result.displayName}</span>
    </span>
    <span className="flex items-center gap-2">
      {showBadge && (
        <Badge
          color={getEntityTypeColor(result.entityType as DataTypeEnum)}
          size="sm"
          variant="light"
        >
          {result.entityType}
        </Badge>
      )}
      {showSimilarity && (
        <span className="text-xs text-gray-500">
          {Math.round(result.similarity * 100)}%
        </span>
      )}
    </span>
  </div>
);
