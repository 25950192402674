import { atom } from 'jotai';
import { formBuilderStateAtom } from './formBuilderStateAtom';

interface OpenFormBuilderAction {
  type: 'open';
  stepKey: string;
  enableFormBuilderAssistant?: boolean;
}

interface CloseFormBuilderAction {
  type: 'close';
}

type FormBuilderAction = OpenFormBuilderAction | CloseFormBuilderAction;

export const formBuilderActionsAtom = atom(
  null,
  (get, set, action: FormBuilderAction) => {
    switch (action.type) {
      case 'open':
        set(formBuilderStateAtom, {
          isOpen: true,
          stepKey: action.stepKey,
          enableFormBuilderAssistant:
            action.enableFormBuilderAssistant ?? false,
        });
        break;
      case 'close':
        set(formBuilderStateAtom, {
          isOpen: false,
          stepKey: null,
          enableFormBuilderAssistant: false,
        });
        break;
    }
  }
);
