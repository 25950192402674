import { CurrentUserResponse } from '@btrway/api-core';
import { ComponentCollection } from 'survey-core';

let isSetupComplete = false;

export const setupCustomComponents = (
  apiUrl: string,
  currentUser: CurrentUserResponse
) => {
  if (isSetupComplete) {
    return;
  }

  const setupTeamDropdown = () => {
    ComponentCollection.Instance.add({
      name: 'teamdropdown',
      title: 'Team Dropdown',
      questionJSON: {
        type: 'dropdown',
        name: 'teamSelect',
        title: 'Select a Team',
        placeholder: 'Choose a team...',
        choicesByUrl: {
          url: `${apiUrl}/api/teams`,
          valueName: 'id',
          titleName: 'name',
        },
      },
    });
  };

  const setupCountryPicker = () => {
    ComponentCollection.Instance.add({
      name: 'countrypicker',
      title: 'Country Picker',
      questionJSON: {
        type: 'dropdown',
        name: 'countrySelect',
        title: 'Select a Country',
        placeholder: 'Choose a country...',
        choicesByUrl: {
          url: `${apiUrl}/api/countries`,
          valueName: 'id',
          titleName: 'name',
        },
      },
    });
  };

  const setupStateProvincePicker = () => {
    ComponentCollection.Instance.add({
      name: 'statepicker',
      title: 'State/Province Picker',
      questionJSON: {
        type: 'dropdown',
        name: 'stateSelect',
        title: 'Select a State/Province',
        placeholder: 'Choose a state/province...',
        choicesByUrl: {
          url: `${apiUrl}/api/state-provinces`,
          valueName: 'id',
          titleName: 'name',
        },
      },
    });
  };

  const setupPersonDropdown = () => {
    ComponentCollection.Instance.add({
      name: 'persondropdown',
      title: 'Person Dropdown',
      questionJSON: {
        type: 'dropdown',
        name: 'personSelect',
        title: 'Select a Person',
        placeholder: 'Choose a person...',
        choicesByUrl: {
          url: `${apiUrl}/api/persons/workgroups/${currentUser?.rootWorkgroupId}`,
          valueName: 'firstName' + ' ' + 'lastName',
          titleName: 'id',
        },
      },
    });
  };

  setupTeamDropdown();
  setupCountryPicker();
  setupStateProvincePicker();
  setupPersonDropdown();

  isSetupComplete = true;
};
