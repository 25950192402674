import { TriggerConfig, TriggerSetConfig } from '@btrway/api-workflow';
import { Button, Card, Group, Stack, Tooltip } from '@mantine/core';
import { IconCopy, IconPlus, IconTrash } from '@tabler/icons-react';
import React from 'react';
import TriggerCard from '../TriggerCard/TriggerCard';

interface TriggerSetCardProps {
  triggerSet: TriggerSetConfig;
  onAddTrigger: (triggerSetKey: string) => void;
  onCopyTriggerSet: (triggerSetKey: string) => void;
  onDeleteTriggerSet: (triggerSetKey: string) => void;
  onDeleteTrigger: (triggerSetKey: string, triggerKey: string) => void;
  onEditTrigger: (triggerSetKey: string, trigger: TriggerConfig) => void;
}

const TriggerSetCard: React.FC<TriggerSetCardProps> = ({
  triggerSet,
  onAddTrigger,
  onCopyTriggerSet,
  onDeleteTriggerSet,
  onDeleteTrigger,
  onEditTrigger,
}) => {
  const handleAddTrigger = () => {
    onAddTrigger(triggerSet.triggerSetKey);
  };

  const handleCopyTriggerSet = () => {
    onCopyTriggerSet(triggerSet.triggerSetKey);
  };

  const handleDeleteTriggerSet = () => {
    onDeleteTriggerSet(triggerSet.triggerSetKey);
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack gap="md">
        <Stack gap={0}>
          {triggerSet.triggers?.map((trigger: TriggerConfig, index) => (
            <TriggerCard
              key={trigger.triggerKey}
              trigger={trigger}
              onDelete={() =>
                onDeleteTrigger(triggerSet.triggerSetKey, trigger.triggerKey)
              }
              onEdit={(editedTrigger) =>
                onEditTrigger(triggerSet.triggerSetKey, editedTrigger)
              }
              isFirstTrigger={index === 0}
            />
          ))}
        </Stack>
        <Group justify="space-between">
          {(triggerSet.triggers?.length ?? 0) > 0 && (
            <Button
              variant="outline"
              size="compact-xs"
              color="gray"
              onClick={handleAddTrigger}
            >
              <IconPlus size={12} />
              And
            </Button>
          )}
          <Group gap="xs" justify="flex-end">
            {(triggerSet.triggers?.length ?? 0) > 0 && (
              <Tooltip label="Copy to Create New Trigger Group">
                <IconCopy
                  size={16}
                  color="gray"
                  onClick={handleCopyTriggerSet}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            )}
            {(triggerSet.triggers?.length ?? 0) > 0 && (
              <IconTrash
                size={16}
                color="gray"
                onClick={handleDeleteTriggerSet}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Group>
        </Group>
      </Stack>
    </Card>
  );
};

export default TriggerSetCard;
