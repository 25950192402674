// src/components/DateAvailabilityCard/DateAvailabilityCard.tsx
import {
  RegistrationFormAvailabilityTypeEnum,
  RegistrationFormDate,
} from '@btrway/api-core';
import { Card, SegmentedControl, Stack, Text } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { RegistrationFormDateSelector } from '../RegistrationFormDateSelector/RegistrationFormDateSelector';

interface DateAvailabilityCardProps {
  availability: RegistrationFormAvailabilityTypeEnum;
  onAvailabilityChange: (value: RegistrationFormAvailabilityTypeEnum) => void;
  startDate: RegistrationFormDate;
  onStartDateChange: (date: RegistrationFormDate) => void;
  endDate: RegistrationFormDate;
  onEndDateChange: (date: RegistrationFormDate) => void;
}

export function DateAvailabilityCard({
  availability,
  onAvailabilityChange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
}: DateAvailabilityCardProps) {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack gap="md">
        <Text size="lg" fw={500}>
          Date Availability
        </Text>

        <SegmentedControl
          value={availability}
          fullWidth
          onChange={(value) =>
            onAvailabilityChange(value as RegistrationFormAvailabilityTypeEnum)
          }
          data={[
            {
              label: 'Always',
              value: RegistrationFormAvailabilityTypeEnum.always,
            },
            {
              label: 'Annually',
              value: RegistrationFormAvailabilityTypeEnum.annual,
            },
            {
              label: 'Limited',
              value: RegistrationFormAvailabilityTypeEnum.limited,
            },
          ]}
        />

        {availability === RegistrationFormAvailabilityTypeEnum.limited && (
          <Stack gap="sm">
            <DatePickerInput
              label="Start Date"
              placeholder="Pick start date"
              value={
                startDate.specificDate ? new Date(startDate.specificDate) : null
              }
              onChange={(date) =>
                onStartDateChange({
                  ...startDate,
                  specificDate: date?.toISOString(),
                })
              }
            />
            <DatePickerInput
              label="End Date"
              placeholder="Pick end date"
              value={
                endDate.specificDate ? new Date(endDate.specificDate) : null
              }
              onChange={(date) =>
                onEndDateChange({
                  ...endDate,
                  specificDate: date?.toISOString(),
                })
              }
            />
          </Stack>
        )}

        {availability === RegistrationFormAvailabilityTypeEnum.annual && (
          <Stack gap="md">
            <Text size="sm" fw={600}>
              Starting On:
            </Text>
            <Card withBorder radius="md" p={0} shadow="none">
              <RegistrationFormDateSelector
                value={startDate}
                onChange={onStartDateChange}
                bg="green.2"
                color="green.7"
              />
            </Card>
            <Text size="sm" fw={600}>
              Ending On:
            </Text>
            <Card withBorder radius="md" p={0} shadow="none">
              <RegistrationFormDateSelector
                value={endDate}
                onChange={onEndDateChange}
                bg="red.2"
                color="red.7"
              />
            </Card>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
