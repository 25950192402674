// atoms/chapterAtomFamily.ts
import { ChapterDTO } from '@btrway/api-courseware';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { chaptersAtom } from './chaptersAtom';

export const chapterAtomFamily = atomFamily((key: string) =>
  atom(
    (get) => get(chaptersAtom).find((chapter) => chapter.key === key),
    (get, set, update: Partial<ChapterDTO>) => {
      set(chaptersAtom, (prev) =>
        prev.map((chapter) =>
          chapter.key === key ? { ...chapter, ...update } : chapter
        )
      );
    }
  )
);
