import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ModuleRegistry,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import {
  getGetYearDefinitionsByOrganizationQueryKey,
  useDeleteYearDefinition,
  useGetYearDefinitionsByOrganization,
  useSaveYearDefinition,
  YearDefinitionRequest,
  YearDefinitionResponse,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ResponsiveGrid } from '@btrway/grid-components';
import { InnerContentWrapper } from '@btrway/layout-components';
import { formatDate } from '@btrway/utils';
import { Button, Card, Center, Group, Modal, Stack, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { format, parse } from 'date-fns';
import React, { useMemo, useState } from 'react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const YearsView: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const {
    data: years,
    isLoading,
    refetch,
  } = useGetYearDefinitionsByOrganization(currentOrganization.id, {
    query: {
      enabled: !!currentOrganization,
      queryKey: getGetYearDefinitionsByOrganizationQueryKey(
        currentOrganization.id
      ),
    },
  });

  const [opened, { open, close }] = useDisclosure(false);
  const [selectedYear, setSelectedYear] =
    useState<YearDefinitionResponse | null>(null);
  const [editStartDate, setEditStartDate] = useState<Date | null>(null);

  const { mutateAsync: saveYear } = useSaveYearDefinition();
  const { mutateAsync: deleteYear } = useDeleteYearDefinition();

  const handleEdit = (year: YearDefinitionResponse) => {
    setSelectedYear(year);
    const startDate = parse(year.startDate, 'yyyy-MM-dd', new Date());
    setEditStartDate(startDate);
    open();
  };

  const handleSave = async () => {
    if (selectedYear && editStartDate) {
      const yearDefinitionRequest: YearDefinitionRequest = {
        id: selectedYear.id || 0,
        organizationId: selectedYear.organizationId,
        startDate: format(editStartDate, 'yyyy-MM-dd'),
        year: selectedYear.year,
      };

      await saveYear({ data: yearDefinitionRequest });
      refetch();
      close();
    }
  };

  const handleDelete = async () => {
    if (selectedYear && selectedYear.id) {
      await deleteYear({ yearDefinitionId: selectedYear.id });
      refetch();
      close();
    }
  };

  const dateFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
      const formattedDate = formatDate(params.value);
      return formattedDate;
    }
    return '';
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: 'year', headerName: 'Year' },
      {
        field: 'startDate',
        headerName: 'Start Date',
        valueFormatter: dateFormatter,
      },
      {
        field: 'endDate',
        headerName: 'End Date',
        valueFormatter: dateFormatter,
      },
      {
        headerName: 'Actions',
        cellRenderer: (params: any) => (
          <Button onClick={() => handleEdit(params.data)}>Edit</Button>
        ),
      },
    ],
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const getRowId = (params: { data: YearDefinitionResponse }) => {
    return params.data.id?.toString() || params.data.year.toString();
  };

  const excludeDate = (date: Date) => {
    // Exclude February 29th
    return date.getMonth() === 1 && date.getDate() === 29;
  };

  if (isLoading) {
    return null;
  }

  console.log('years', years);

  return (
    <InnerContentWrapper>
      <ResponsiveGrid
        columnDefs={columnDefs}
        rowData={years}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        getRowId={getRowId}
        rowModelType="clientSide"
        enableRangeSelection={true}
        primaryNavigationField="year"
        responsiveRowSpacing={10}
      />

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text fz="lg" fw={600}>
            Edit Start Date for {selectedYear?.year}
          </Text>
        }
      >
        <Stack>
          <Text>
            Selected date will be used for subsequent years unless otherwise
            specified for that year.
          </Text>
          <Card withBorder radius="md" p="md">
            <Center>
              <DatePicker
                value={editStartDate}
                onChange={setEditStartDate}
                minDate={new Date(selectedYear?.year || 0, 0, 1)}
                maxDate={new Date(selectedYear?.year || 0, 11, 31)}
                firstDayOfWeek={0}
                defaultDate={editStartDate || undefined}
                excludeDate={excludeDate}
              />
            </Center>
          </Card>
          <Group justify="flex-end" wrap="nowrap">
            {/* <Button onClick={handleDelete} color="red">
              Delete
            </Button> */}
            <Group justify="flex-end" wrap="nowrap">
              <Button onClick={close} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </Group>
          </Group>
        </Stack>
      </Modal>
    </InnerContentWrapper>
  );
};

export default YearsView;
