import { InnerContentWrapper } from '@btrway/layout-components';
import { InfoCard } from '@btrway/shared-components';
import { useWorkgroupTypes } from '@btrway/workgroup-manager';
import { Flex, Group, Space, Stack, Text } from '@mantine/core';
import React from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import AddWorkgroupTypeModal from '../AddWorkgroupTypeModal/AddWorkgroupTypeModal';
import WorkgroupTypeCard from '../WorkgroupTypeCard/WorkgroupTypeCard';

export const WorkgroupTypesView: React.FC = () => {
  const { organization } = useOrganization();
  const { workgroupTypes } = useWorkgroupTypes(organization.id);

  return (
    <InnerContentWrapper>
      <Stack gap="lg">
        <InfoCard
          text="Custom Types are used to tailor the platform to the Organization's
              industry-specific nomenclature and structure."
          bg="gray.1"
        />
        <Group justify="space-between">
          <Stack gap={5} p={0}>
            <Text fz={16} fw={500}>
              Workgroup Types
            </Text>
            <Text fz={12}>
              Customize the different organizational units within your
              organization
            </Text>
          </Stack>
          <AddWorkgroupTypeModal organizationId={organization.id} />
        </Group>
        <Space h={20} />
        <Flex wrap="wrap" gap="md">
          {workgroupTypes?.map((workgroupType) => (
            <WorkgroupTypeCard
              key={workgroupType.id}
              workgroupType={workgroupType}
              allWorkgroupTypes={workgroupTypes}
            />
          ))}
        </Flex>
      </Stack>
    </InnerContentWrapper>
  );
};
