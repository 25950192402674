import { WorkgroupResponse } from '@btrway/api-core';
import { ActionIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconSettings } from '@tabler/icons-react';
import { RegistrationFormAdministrationDrawer } from '../RegistrationFormAdministrationDrawer/RegistrationFormAdministrationDrawer';

interface RegistrationFormAdministrationButtonProps {
  workgroup: WorkgroupResponse;
}

export function RegistrationFormAdministrationButton({
  workgroup,
}: RegistrationFormAdministrationButtonProps) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <ActionIcon
        variant="subtle"
        color="gray"
        size="lg"
        aria-label="Manage registration forms"
        onClick={open}
      >
        <IconSettings style={{ width: '70%', height: '70%' }} />
      </ActionIcon>

      <RegistrationFormAdministrationDrawer
        opened={opened}
        onClose={close}
        workgroup={workgroup}
      />
    </>
  );
}
