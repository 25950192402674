import { Card } from '@mantine/core';
import React from 'react';

import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { GroupedTasksView } from '@btrway/task-grids';

interface ToDosViewProps {
  personId?: number; // Optional - if not provided, uses current user
}

const ToDosView: React.FC<ToDosViewProps> = ({ personId }) => {
  return (
    <Card p="md" radius="lg" mih={800}>
      {/* <Text fz="lg" fw={600} mb="sm">
        Certification Requirements
      </Text> */}
      <GroupedTasksView workflowType={WorkflowTypeEnum.taskList} />
    </Card>
  );
};

export default ToDosView;
