import {
  useWorkflowUpdater,
  workflowStepAtomFamily,
} from '@btrway/workflow-configuration-manager';
import { Box, Button, Group, Modal, Text } from '@mantine/core';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback, useState } from 'react';
import { useFormBuilder } from '../../hooks/useFormBuilder';
import EmptyFormPrompt from '../EmptyFormPrompt/EmptyFormPrompt';
import FormBuilder from '../FormBuilder/FormBuilder';

interface FormBuilderModalProps {
  title: string;
  workflowKey: string;
}

const FormBuilderModal: React.FC<FormBuilderModalProps> = ({
  title,
  workflowKey,
}) => {
  const { isOpen, stepKey, enableFormBuilderAssistant, closeFormBuilder } =
    useFormBuilder();
  const [showEmptyPrompt, setShowEmptyPrompt] = useState(
    enableFormBuilderAssistant
  );
  const [isSaving, setIsSaving] = useState(false);
  const { updateConfig } = useWorkflowUpdater();

  const getSelectedStep = useAtomCallback(
    useCallback(
      (get) => {
        console.log('getSelectedStep, stepKey: ', stepKey);
        if (!stepKey) return null;
        console.log('calling the atom family for stepKey: ', stepKey);
        return get(workflowStepAtomFamily(stepKey));
      },
      [stepKey]
    )
  );

  const hasElements = useCallback((formConfig: any): boolean => {
    if (formConfig && formConfig.elements && formConfig.elements?.length > 0) {
      return true;
    }
    if (
      formConfig &&
      formConfig.pages &&
      formConfig.pages?.some(
        (page: any) => page.elements && page.elements.length > 0
      )
    ) {
      return true;
    }
    return false;
  }, []);

  React.useEffect(() => {
    const checkFormConfig = async () => {
      const selectedStep = await getSelectedStep();
      if (selectedStep && hasElements(selectedStep.formConfig)) {
        setShowEmptyPrompt(false);
      } else {
        setShowEmptyPrompt(true);
      }
    };
    if (isOpen && enableFormBuilderAssistant) {
      checkFormConfig();
    }
  }, [isOpen, getSelectedStep, hasElements, enableFormBuilderAssistant]);

  const handleBuild = useCallback(() => {
    setShowEmptyPrompt(false);
  }, []);

  const handleSkip = useCallback(() => {
    setShowEmptyPrompt(false);
  }, []);

  const handleSave = async () => {
    console.log('FormBuilderModal, handleSave, stepKey', stepKey);
    try {
      setIsSaving(true);
      const selectedStep = await getSelectedStep();
      console.log('FormBuilderModal, handleSave, selectedStep', selectedStep);
      if (!selectedStep) return;

      await updateConfig({
        workflowConfig: {
          steps: [selectedStep],
        },
      });

      closeFormBuilder();
    } catch (error) {
      console.error('Error saving form configuration:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={closeFormBuilder}
      title={
        <Group justify="space-between" w="100%" wrap="nowrap">
          <Text fz="lg" fw={600}>
            {title}
          </Text>
          <Button
            variant="light"
            size="sm"
            onClick={handleSave}
            loading={isSaving}
          >
            Save
          </Button>
        </Group>
      }
      size="95vw"
      radius="lg"
      centered
      styles={{
        inner: { padding: '2.5vh 2.5vw' },
        content: {
          height: '95vh',
          display: 'flex',
          flexDirection: 'column',
        },
        header: {
          marginBottom: 0,
        },
        body: {
          flex: 1,
          padding: 0,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box style={{ flex: 1, overflow: 'hidden' }}>
        {showEmptyPrompt && enableFormBuilderAssistant ? (
          <EmptyFormPrompt onBuild={handleBuild} onSkip={handleSkip} />
        ) : (
          <FormBuilder workflowKey={workflowKey} />
        )}
      </Box>
    </Modal>
  );
};

export default FormBuilderModal;
