import {
  CertificationTypeEnum,
  getGetCertificationTypesQueryKey,
  useGetCertificationTypes,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export const useCertificationTypes = () => {
  const {
    data: certificationTypes,
    isLoading,
    error,
    refetch,
  } = useGetCertificationTypes({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetCertificationTypesQueryKey(),
    },
  });

  const getCertificationType = useCallback(
    (CertificationTypeCode: CertificationTypeEnum) => {
      return certificationTypes?.find(
        (type) => type.code === CertificationTypeCode
      );
    },
    [certificationTypes]
  );

  return {
    certificationTypes: certificationTypes ?? [],
    isLoading,
    error,
    getCertificationType,
    refetchCertificationTypes: refetch,
  };
};
