import { EventRequest, useUpdateEventDates } from '@btrway/api-calendar';
import { PersonResponse, WorkgroupResponse } from '@btrway/api-core';
import { CalendarEventModal } from '@btrway/calendar-event-editor';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import {
  CalendarApi,
  DateSelectArg,
  EventClickArg,
  EventDropArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useCalendarEvents } from '../../hooks/useCalendarEvents';
import CalendarHeader from '../CalendarHeader/CalendarHeader';
import { renderEventContent } from '../EventContent/EventContent';
import './Calendar.css';

interface CalendarViewProps {
  workgroup?: WorkgroupResponse;
  person?: PersonResponse;
  facilityId?: number;
  defaultView?: 'dayGridMonth' | 'timeGridWeek' | 'timeGridDay';
}

export interface CalendarViewRef {
  fetchEvents: () => void;
}

export const CalendarView = forwardRef<CalendarViewRef, CalendarViewProps>(
  ({ workgroup, person, facilityId, defaultView = 'dayGridMonth' }, ref) => {
    useAddHelpTags(['calendar']);

    const [opened, { open, close }] = useDisclosure(false);
    const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);
    const [title, setTitle] = useState<string>('');
    const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
    const [newEventConfig, setNewEventConfig] = useState<
      Partial<EventRequest> | undefined
    >();
    const { currentOrganization } = useAuthenticatedUser();
    const [selectedTimezone, setSelectedTimezone] = useState<string>(
      currentOrganization.timeZoneName || 'America/New_York'
    );
    const { mutateAsync: updateDates } = useUpdateEventDates();

    // Use a ref to track the previous facilityId
    const prevFacilityIdRef = useRef<number | undefined>(facilityId);

    const { calendarEvents, fetchEvents } = useCalendarEvents({
      organizationId: currentOrganization.id,
      workgroupId: workgroup?.id,
      personId: person?.id,
      facilityId,
      timezone: selectedTimezone,
    });

    // Expose fetchEvents method via ref
    useImperativeHandle(ref, () => ({
      fetchEvents: () => {
        if (calendarApi) {
          const view = calendarApi.view;
          fetchEvents({
            startDate: view.activeStart.toISOString(),
            endDate: view.activeEnd.toISOString(),
          });
        }
      },
    }));

    const createNewEvent = useCallback(
      (start: Date, end: Date, allDayEvent: boolean = false) => {
        setSelectedEventId(null);
        const config = {
          startTime: start.toISOString(),
          endTime: end.toISOString(),
          allDayEvent,
          organizationId: currentOrganization.id,
          workgroupId: workgroup?.id,
        };
        setNewEventConfig(config);
        open();
      },
      [currentOrganization.id, workgroup?.id, open]
    );

    const handleEventClick = (clickInfo: EventClickArg) => {
      const eventId = parseInt(clickInfo.event.id);
      setSelectedEventId(eventId);
      setNewEventConfig(undefined);
      open();
    };

    const handleNewEvent = () => {
      const now = new Date();
      const oneHourLater = new Date(now.getTime() + 3600000);
      createNewEvent(now, oneHourLater);
    };

    const handleDateSelect = (selectInfo: DateSelectArg) => {
      const { start, end, allDay, view } = selectInfo;
      if (
        view.type !== 'dayGridMonth' ||
        start.getDate() !== end.getDate() - 1
      ) {
        createNewEvent(start, end, allDay);
      }
    };

    const handleDateClick = (arg: DateClickArg) => {
      const start = new Date(arg.date);
      let end;

      if (arg.view.type === 'dayGridMonth') {
        const now = new Date();
        start.setHours(now.getHours(), now.getMinutes(), 0, 0);
        end = new Date(start.getTime() + 3600000);
        createNewEvent(start, end, false);
      } else {
        end = new Date(start.getTime() + 3600000);
        createNewEvent(start, end);
      }
    };

    const handleCloseModal = () => {
      setSelectedEventId(null);
      setNewEventConfig(undefined);
      close();
    };

    const handleViewChange = (viewType: string) => {
      if (calendarApi) {
        calendarApi.changeView(viewType);
      }
    };

    // Effect for facility changes
    useEffect(() => {
      if (calendarApi && facilityId !== prevFacilityIdRef.current) {
        const view = calendarApi.view;
        fetchEvents({
          startDate: view.activeStart.toISOString(),
          endDate: view.activeEnd.toISOString(),
        });
        prevFacilityIdRef.current = facilityId;
      }
    }, [facilityId, calendarApi, fetchEvents]);

    const handleSaveEvent = () => {
      if (calendarApi) {
        const view = calendarApi.view;
        fetchEvents({
          startDate: view.activeStart.toISOString(),
          endDate: view.activeEnd.toISOString(),
        });
      }
      handleCloseModal();
    };

    const handleDeleteEvent = () => {
      if (calendarApi) {
        const view = calendarApi.view;
        fetchEvents({
          startDate: view.activeStart.toISOString(),
          endDate: view.activeEnd.toISOString(),
        });
      }
      handleCloseModal();
    };

    const handleEventDrop = async (dropInfo: EventDropArg) => {
      try {
        if (!dropInfo.event.start || !dropInfo.event.end) {
          dropInfo.revert();
          return;
        }

        const eventId = parseInt(dropInfo.event.id);
        await updateDates({
          id: eventId,
          data: {
            startTime: dropInfo.event.start.toISOString(),
            endTime: dropInfo.event.end.toISOString(),
            allDayEvent: dropInfo.event.allDay,
          },
        });

        if (calendarApi) {
          const view = calendarApi.view;
          fetchEvents({
            startDate: view.activeStart.toISOString(),
            endDate: view.activeEnd.toISOString(),
          });
        }
      } catch (error) {
        dropInfo.revert();
      }
    };

    const handleEventResize = async (resizeInfo: EventResizeDoneArg) => {
      try {
        if (!resizeInfo.event.start || !resizeInfo.event.end) {
          resizeInfo.revert();
          return;
        }

        const eventId = parseInt(resizeInfo.event.id);
        await updateDates({
          id: eventId,
          data: {
            startTime: resizeInfo.event.start.toISOString(),
            endTime: resizeInfo.event.end.toISOString(),
            allDayEvent: resizeInfo.event.allDay,
          },
        });

        if (calendarApi) {
          const view = calendarApi.view;
          fetchEvents({
            startDate: view.activeStart.toISOString(),
            endDate: view.activeEnd.toISOString(),
          });
        }
      } catch (error) {
        resizeInfo.revert();
      }
    };

    return (
      <>
        <Box>
          <CalendarHeader
            calendarApi={calendarApi}
            onViewChange={handleViewChange}
            title={title}
            workgroup={workgroup}
            onNewEvent={handleNewEvent}
            selectedTimezone={selectedTimezone}
            onTimezoneChange={setSelectedTimezone}
            showNewEventButton={!!(workgroup || facilityId)}
          />
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              momentTimezonePlugin,
            ]}
            initialView={defaultView}
            headerToolbar={false}
            timeZone={selectedTimezone}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={5}
            weekends={true}
            events={calendarEvents}
            eventDisplay="block"
            slotMinTime="06:00:00"
            slotMaxTime="22:00:00"
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            datesSet={fetchEvents}
            nowIndicator={true}
            select={handleDateSelect}
            dateClick={handleDateClick}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            ref={(el) => {
              if (el) {
                setCalendarApi(el.getApi());
                setTitle(el.getApi().view.title);
              }
            }}
          />
        </Box>

        <CalendarEventModal
          opened={opened}
          onClose={handleCloseModal}
          eventId={selectedEventId || undefined}
          newEventConfig={newEventConfig}
          workgroup={workgroup}
          facilityId={facilityId}
          onSave={handleSaveEvent}
          onDelete={handleDeleteEvent}
          userTimezone={selectedTimezone}
        />
      </>
    );
  }
);
