import { Box } from '@mantine/core';
import React from 'react';
import { usePerson } from '../../providers/PersonProvider';

export const PersonLearningView: React.FC = () => {
  const { person } = usePerson();

  return (
    <Box m="lg">
      <h3>Learning View for {person.firstName}</h3>
    </Box>
  );
};
