import { WorkgroupRequest, WorkgroupTypeResponse } from '@btrway/api-core';
import {
  Button,
  Chip,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';

interface AddWorkgroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (workgroupRequest: WorkgroupRequest) => void;
  organizationId: number;
  parentWorkgroupId: number;
  availableWorkgroupTypes: WorkgroupTypeResponse[];
}

export const AddWorkgroupModal: React.FC<AddWorkgroupModalProps> = ({
  isOpen,
  onClose,
  onAdd,
  organizationId,
  parentWorkgroupId,
  availableWorkgroupTypes,
}) => {
  const [selectedWorkgroupTypeId, setSelectedWorkgroupTypeId] = useState<
    number | null
  >(null);
  const [workgroupName, setWorkgroupName] = useState('');

  const resetState = useCallback(() => {
    setSelectedWorkgroupTypeId(null);
    setWorkgroupName('');
  }, []);

  useEffect(() => {
    if (isOpen) {
      // Set initial state when modal opens
      if (availableWorkgroupTypes.length === 1) {
        setSelectedWorkgroupTypeId(availableWorkgroupTypes[0].id);
      }
    } else {
      // Reset state when modal closes
      resetState();
    }
  }, [isOpen, availableWorkgroupTypes, resetState]);

  const handleSubmit = useCallback(() => {
    if (selectedWorkgroupTypeId && workgroupName) {
      const workgroupRequest: WorkgroupRequest = {
        name: workgroupName,
        organizationId,
        parentWorkgroupId,
        workgroupTypeId: selectedWorkgroupTypeId,
      };
      onAdd(workgroupRequest);
      // Reset state after submission
      resetState();
      // Close the modal
      onClose();
    }
  }, [
    selectedWorkgroupTypeId,
    workgroupName,
    organizationId,
    parentWorkgroupId,
    onAdd,
    resetState,
    onClose,
  ]);

  const modalTitle =
    availableWorkgroupTypes.length === 1
      ? `Add ${availableWorkgroupTypes[0].name}`
      : 'Add Organizational Group';

  const selectedWorkgroupType = availableWorkgroupTypes.find(
    (wt) => wt.id === selectedWorkgroupTypeId
  );

  return (
    <Modal opened={isOpen} onClose={onClose} title={modalTitle}>
      <Stack>
        {availableWorkgroupTypes.length > 1 && (
          <>
            <Text size="sm">Select Workgroup Type:</Text>
            <Chip.Group
              value={selectedWorkgroupTypeId?.toString()}
              onChange={(value) => setSelectedWorkgroupTypeId(Number(value))}
            >
              <Group justify="flex-start" gap="sm">
                {availableWorkgroupTypes.map((wt) => (
                  <Chip key={wt.id} value={wt.id.toString()}>
                    {wt.name}
                  </Chip>
                ))}
              </Group>
            </Chip.Group>
          </>
        )}
        <TextInput
          label={`${
            selectedWorkgroupType ? selectedWorkgroupType.name : 'Workgroup'
          } Name:`}
          value={workgroupName}
          onChange={(e) => setWorkgroupName(e.target.value)}
          autoFocus
          data-autofocus
        />
        <Button
          onClick={handleSubmit}
          disabled={!selectedWorkgroupTypeId || !workgroupName}
        >
          Add
        </Button>
      </Stack>
    </Modal>
  );
};
