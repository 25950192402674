import { CalendarView } from '@btrway/calendar-components';
import { Box } from '@mantine/core';
import React from 'react';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupCalendarView: React.FC = () => {
  const { workgroup } = useWorkgroup();
  return (
    <Box p="md">
      <CalendarView workgroup={workgroup} />
    </Box>
  );
};

export default WorkgroupCalendarView;
