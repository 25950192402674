import { atom } from 'jotai';
import { ImportConfiguration } from '../types/ImportConfiguration';

const initialImportConfiguration: ImportConfiguration = {
  sheet: null,
  columnMappings: [],
};

export const importConfigurationAtom = atom<ImportConfiguration>(
  initialImportConfiguration
);
