import { FacilityAttributes } from '@btrway/api-calendar';
import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';

interface FacilityCardProps {
  facility: {
    id?: number;
    name: string;
    attributes?: FacilityAttributes;
    organizationId: number;
    workgroupId: number;
    path?: string;
  };
  onEdit: () => void;
}

export const FacilityCard: React.FC<FacilityCardProps> = ({
  facility,
  onEdit,
}) => {
  return (
    <Card shadow="xs" padding="sm" radius="md">
      <Group>
        <Stack style={{ flex: 1 }}>
          <Text size="md" fw={500}>
            {facility.name}
          </Text>
          {facility.attributes?.description && (
            <Text size="sm" c="dimmed" lineClamp={1}>
              {facility.attributes.description}
            </Text>
          )}
        </Stack>
        <ActionIcon size="xs" variant="subtle" onClick={onEdit}>
          <IconEdit />
        </ActionIcon>
      </Group>
    </Card>
  );
};
