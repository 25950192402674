import { useEmailTemplates } from '@btrway/internal-email-components';
import { Loader, Select } from '@mantine/core';
import React from 'react';

interface TemplateSelection {
  id: number;
  templateHtml: string;
}

interface MessageTemplateSelectorProps {
  value?: number;
  onChange: (selection: TemplateSelection | null) => void;
}

export const MessageTemplateSelector: React.FC<
  MessageTemplateSelectorProps
> = ({ value, onChange }) => {
  const { templates, isLoading } = useEmailTemplates();

  const templateOptions = React.useMemo(
    () =>
      templates
        ?.filter((template) => template.published)
        ?.map((template) => ({
          value: template.id.toString(),
          label: template.templateName,
        })) || [],
    [templates]
  );

  const handleTemplateChange = (selectedValue: string | null) => {
    if (!selectedValue) {
      onChange(null);
      return;
    }

    const selectedId = parseInt(selectedValue);
    const selectedTemplate = templates?.find((t) => t.id === selectedId);

    if (selectedTemplate) {
      onChange({
        id: selectedTemplate.id,
        templateHtml: selectedTemplate.templateHtml,
      });
    }
  };

  return (
    <Select
      label="Email Template"
      placeholder={isLoading ? 'Loading templates...' : 'Select a template'}
      data={templateOptions}
      value={value?.toString()}
      onChange={handleTemplateChange}
      clearable
      rightSection={isLoading ? <Loader size="xs" /> : null}
      disabled={isLoading}
    />
  );
};

export default MessageTemplateSelector;
