import { Question, Serializer } from 'survey-core';
import { PropertyGridEditorCollection } from 'survey-creator-core';
import { SurveyCreator } from 'survey-creator-react';
import { createDataBindingAction } from '../components/DataBindingAdorner/DataBindingAdorner';

export const setupCustomProperties = (
  creator: SurveyCreator,
  openModal: (question: Question) => void
) => {
  Serializer.addProperty('question', {
    name: 'dataBinding',
    type: 'string',
    category: 'data',
    visible: true,
    isSerializable: true,
  });

  Serializer.addProperty('question', {
    name: 'Data Binding',
    category: 'Data Binding',
    isSerializable: true,
  });

  PropertyGridEditorCollection.register({
    fit: (prop) => prop.type === 'string' && prop.name === 'Data Binding',
    getJSON: () => ({
      type: 'text',
      readOnly: true,
      text: 'boundData',
    }),
  });

  creator.onPropertyEditorUpdateTitleActions.add((_, options) => {
    if (options.property.name === 'Data Binding') {
      options.titleActions.push({
        id: 'dataBinding',
        title: 'Data Binding',
        iconName: 'icon-wizard',
        showTitle: false,
        action: () => {
          openModal(options.obj as Question);
        },
      });
    }
  });

  creator.onDefineElementMenuItems.add((_, options) => {
    const question = options.obj;

    options.items.forEach((item) => {
      if (['convertTo', 'convertInputType'].indexOf(item.id || '') < 0) {
        item.showTitle = false;
      }
    });

    const dataBindAdorner = createDataBindingAction(question as Question, () =>
      openModal(question as Question)
    );
    options.items.push(dataBindAdorner);
  });
};
