import { OrganizationResponse } from '@btrway/api-core';
import { Card, Group, Text } from '@mantine/core';
import React from 'react';
import OrgAccessButton from '../OrgAccessButton/OrgAccessButton';
import classes from './OrganizationCard.module.css';

interface OrganizationCardProps {
  data: OrganizationResponse;
  className?: string;
  onClick?: () => void;
}

const OrganizationCard: React.FC<OrganizationCardProps> = ({
  data: organization,
  className,
  onClick,
}) => {
  return (
    <Card
      p="md"
      withBorder={true}
      shadow="sm"
      mih="150px"
      className={`${classes.card} ${className}`}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <OrgAccessButton organizationId={organization.id} />
      <Group
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Text fw={600} ta="center" style={{ width: '100%' }}>
          {organization.name}
        </Text>
      </Group>
      <Group
        px="xs"
        w="100%"
        justify="space-between"
        style={{ position: 'absolute', bottom: 5, left: 0 }}
      >
        <Text size="xs">{organization.organizationType.name}</Text>
        <Text size="xs">{organization.physicalAddress?.stateProvinceCode}</Text>
      </Group>
    </Card>
  );
};

export default OrganizationCard;
