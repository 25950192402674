import { ContentEditor, EditorContent } from '@btrway/content-editor';
import React, { useCallback, useState } from 'react';
import ReactJson from 'react-json-view';

// Utility function to sanitize content for ReactJson
const sanitizeContent = (content: any): any => {
  if (Array.isArray(content)) {
    return content.map(sanitizeContent);
  }

  if (content && typeof content === 'object') {
    const sanitized: Record<string, any> = {};

    // Convert object-like arrays to regular objects
    if (
      Object.getPrototypeOf(content) === Object.prototype ||
      Array.isArray(content)
    ) {
      Object.keys(content).forEach((key) => {
        sanitized[key] = sanitizeContent(content[key]);
      });
      return sanitized;
    }

    // Handle other object types by converting to plain object
    const plainObj: Record<string, any> = {};
    Object.getOwnPropertyNames(content).forEach((key) => {
      if (typeof content[key] !== 'function') {
        plainObj[key] = sanitizeContent(content[key]);
      }
    });
    return plainObj;
  }

  return content;
};

export const TipTapPocPage: React.FC = () => {
  const [content, setContent] = useState<EditorContent>({
    type: 'doc',
    content: [{ type: 'paragraph' }],
  });
  const [html, setHtml] = useState<string>('');

  const handleContentChange = useCallback(
    (json: EditorContent, newHtml: string) => {
      // Handle content updates here
      // Consider debouncing this if you need to save to a backend
      setContent(json);
      setHtml(newHtml);
    },
    []
  );

  // Sanitize content before passing to ReactJson
  const sanitizedContent = sanitizeContent(content);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100%',
        backgroundColor: 'var(--mantine-color-body)',
        gap: 'var(--mantine-spacing-md)',
      }}
    >
      <div
        style={{
          flex: '0 0 60%',
          height: '100%',
          padding: 'var(--mantine-spacing-md)',
        }}
      >
        <ContentEditor
          initialContent={content}
          onChange={handleContentChange}
        />
      </div>
      <div
        style={{
          flex: '1',
          height: '100%',
          padding: 'var(--mantine-spacing-md)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            flex: '1',
            position: 'relative',
            backgroundColor: 'var(--mantine-color-dark-7)',
            borderRadius: 'var(--mantine-radius-md)',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'auto',
            }}
          >
            <ReactJson
              src={sanitizedContent}
              theme="monokai"
              iconStyle="square"
              collapsed={false}
              displayDataTypes={false}
              enableClipboard={true}
              displayObjectSize={true}
              onEdit={false}
              onAdd={false}
              onDelete={false}
              style={{
                padding: '20px',
                fontSize: '14px',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
