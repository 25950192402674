import { ChapterProgressResponse } from '@btrway/api-courseware';
import { useAtom } from 'jotai';
import { chapterProgressAtomFamily } from '../atoms/chapterProgressAtomFamily';

export const useChapterProgress = (chapterKey: string) => {
  const [chapterProgress, setChapterProgress] = useAtom(
    chapterProgressAtomFamily(chapterKey)
  );

  const updateChapterProgress = (updates: Partial<ChapterProgressResponse>) => {
    setChapterProgress(updates);
  };

  return { chapterProgress, updateChapterProgress };
};
