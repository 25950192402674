// import { ComboboxItem, Select } from '@mantine/core';
// import React from 'react';

// const timezones = [
//   { value: 'America/New_York', label: 'Eastern Time (ET)' },
//   { value: 'America/Chicago', label: 'Central Time (CT)' },
//   { value: 'America/Denver', label: 'Mountain Time (MT)' },
//   { value: 'America/Phoenix', label: 'Mountain Time - Arizona (MT)' },
//   { value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
//   { value: 'America/Anchorage', label: 'Alaska Time (AKT)' },
//   { value: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Time (HAT)' },
// ];

// interface TimezoneSelectProps {
//   value: string | null;
//   onChange: (value: string | null) => void;
// }

// const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ value, onChange }) => {
//   const handleChange = (newValue: string | null, option: ComboboxItem) => {
//     onChange(newValue);
//   };

//   return (
//     <Select
//       // label="Time Zone"
//       placeholder="Select a time zone"
//       data={timezones}
//       value={value}
//       onChange={handleChange}
//       searchable
//     />
//   );
// };

// export default TimezoneSelect;

import { Button, Combobox, useCombobox } from '@mantine/core';
import React, { useState } from 'react';

const timezones = [
  { value: 'America/New_York', label: 'Eastern Time (ET)', abbr: 'ET' },
  { value: 'America/Chicago', label: 'Central Time (CT)', abbr: 'CT' },
  { value: 'America/Denver', label: 'Mountain Time (MT)', abbr: 'MT' },
  {
    value: 'America/Phoenix',
    label: 'Mountain Time - Arizona (MT)',
    abbr: 'MT',
  },
  { value: 'America/Los_Angeles', label: 'Pacific Time (PT)', abbr: 'PT' },
  { value: 'America/Anchorage', label: 'Alaska Time (AKT)', abbr: 'AKT' },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian Time (HAT)',
    abbr: 'HAT',
  },
];

interface TimezoneSelectProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ value, onChange }) => {
  const [search, setSearch] = useState('');
  const selectedTimezone = timezones.find((tz) => tz.value === value);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },
    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const options = timezones
    .filter(
      (item) =>
        item.label.toLowerCase().includes(search.toLowerCase().trim()) ||
        item.abbr.toLowerCase().includes(search.toLowerCase().trim())
    )
    .map((item) => (
      <Combobox.Option value={item.value} key={item.value}>
        {item.label}
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      position="bottom-start"
      withArrow
      withinPortal={false}
      onOptionSubmit={(val) => {
        onChange(val);
        combobox.closeDropdown();
      }}
      styles={{
        dropdown: {
          width: 'auto',
          minWidth: '300px',
        },
      }}
    >
      <Combobox.Target withAriaAttributes={false}>
        <Button
          c="gray"
          color="gray.5"
          bg="white"
          variant="outline"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedTimezone?.abbr || 'TZ'}
        </Button>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search timezones"
        />
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default TimezoneSelect;
