import { Stack } from '@mantine/core';
import React from 'react';
import { TimeEvent } from '../../data/DailySummary';
import {
  defaultDailyEvents,
  defaultGames,
} from '../../data/DashboardDefaultEvents';
import { TimeEventList } from '../TimeEventList/TimeEventList';

interface DailySummaryColumnProps {
  dailyEvents?: TimeEvent[];
  games?: TimeEvent[];
}

export const DailySummaryColumn: React.FC<DailySummaryColumnProps> = ({
  dailyEvents = defaultDailyEvents,
  games = defaultGames,
}) => {
  return (
    <Stack gap={10}>
      <TimeEventList title="Today" events={dailyEvents} />
      <TimeEventList title="Games Today" events={games} />
    </Stack>
  );
};
