import {
  TaskConfig,
  TaskOptionTaskProperties,
  TaskTypeEnum,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTypedTaskProperties } from '../../../config/taskPropertiesMap';
import { WorkflowSelectorField } from '../../../selector-components/WorkflowSelectorField/WorkflowSelectorField';
import { TaskConfigProps } from '../../../types/editors';
import { getWorkflowSelection } from '../../../utils/workflowSelectionUtil';

export const TaskOptionConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.completeTaskOption
  ) as TaskOptionTaskProperties | undefined;

  const [properties, setProperties] = useState<TaskOptionTaskProperties>({
    title: taskProperties?.title || '',
    shortDescription: taskProperties?.shortDescription || '',
    longDescription: taskProperties?.longDescription || '',
    workflowSelection: taskProperties?.workflowSelection || {
      workflowDefinitionKey: {
        value: '',
      },
    },
  });

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        title: taskProperties.title || '',
        shortDescription: taskProperties.shortDescription || '',
        longDescription: taskProperties.longDescription || '',
        workflowSelection: taskProperties.workflowSelection,
      });
    }
  }, [taskProperties]);

  const handlePropertyChange = (
    field: keyof Omit<TaskOptionTaskProperties, 'workflowSelection'>,
    value: string
  ) => {
    const updatedProperties: TaskOptionTaskProperties = {
      ...properties,
      [field]: value,
    };

    setProperties(updatedProperties);

    onConfigChange({
      ...config,
      metadata: {
        ...config.metadata,
        title: updatedProperties.title,
      },
      taskProperties: updatedProperties,
    });
  };

  const handleWorkflowSelect = (
    newWorkflowSelection: WorkflowSelection,
    name: string
  ) => {
    const updatedProperties: TaskOptionTaskProperties = {
      ...properties,
      workflowSelection: newWorkflowSelection,
    };

    setProperties(updatedProperties);

    const updatedConfig: TaskConfig = {
      ...config,
      // title: name,
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  const workflowSelection = getWorkflowSelection(config);
  const workflowType = WorkflowTypeEnum.taskOption;

  return (
    <Stack gap="md">
      <Stack>
        <TextInput
          label="Title"
          placeholder="Enter task title"
          value={properties.title}
          onChange={(event) =>
            handlePropertyChange('title', event.currentTarget.value)
          }
        />
        <Textarea
          label="Short Description"
          placeholder="Enter detailed description"
          value={properties.shortDescription || ''}
          onChange={(event) =>
            handlePropertyChange('shortDescription', event.currentTarget.value)
          }
          rows={2}
        />
        <Textarea
          label="Long Description"
          placeholder="Enter detailed description"
          value={properties.longDescription || ''}
          onChange={(event) =>
            handlePropertyChange('longDescription', event.currentTarget.value)
          }
          rows={8}
        />
      </Stack>
      <WorkflowSelectorField
        workflowType={workflowType}
        workflowSelection={workflowSelection}
        onWorkflowSelect={handleWorkflowSelect}
      />
    </Stack>
  );
};

export default TaskOptionConfiguration;
