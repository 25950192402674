import {
  CertificationConfig,
  CertificationTaskProperties,
  CertificationTypeEnum,
  TaskListTaskProperties,
  ThirdPartyTaskProperties,
} from '@btrway/api-workflow';
import { TriggerDescriptionService } from '@btrway/trigger-builder';
import { CertificationTypeIcon } from '@btrway/workflow-components';
import { useCertificationTypes } from '@btrway/workflow-manager';
import { Card, Group, Stack, Text } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { CertificationInfo } from '../CertificationInfo/CertificationInfo';
import { NestedWorkflowView } from '../NestedWorkflowView/NestedWorkflowView';

interface CertificationCardProps {
  workflowKey: string;
  certificationConfig: CertificationConfig;
  index: number;
  isLast: boolean;
  onCertificationSelected: (certificationKey: string) => void;
}

const CertificationCard: React.FC<CertificationCardProps> = ({
  workflowKey,
  certificationConfig,
  index,
  isLast,
  onCertificationSelected,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { getCertificationType } = useCertificationTypes();

  const certificationKey = certificationConfig.certificationKey;
  const certificationType = certificationConfig.certificationType;

  const handleCardClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onCertificationSelected(certificationConfig.certificationKey);
    },
    [certificationKey, onCertificationSelected]
  );

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const triggerDescriptionService = new TriggerDescriptionService();
  const triggerDescription =
    triggerDescriptionService.buildTriggerSetDescription(
      certificationConfig.triggerSets
    );

  const renderNestedContent = () => {
    let taskListWorkflowKey: string | undefined;
    let certificationWorkflowKey: string | undefined;

    if (
      certificationType === CertificationTypeEnum.completeTaskList ||
      certificationType === CertificationTypeEnum.completeCurriculum ||
      certificationType === CertificationTypeEnum.completeFormPacket ||
      certificationType === CertificationTypeEnum.completeTaskOption
    ) {
      const properties =
        certificationConfig.taskProperties as TaskListTaskProperties;
      taskListWorkflowKey =
        properties?.workflowSelection?.workflowDefinitionKey?.value;
    } else if (certificationType === CertificationTypeEnum.certification) {
      const properties =
        certificationConfig.taskProperties as CertificationTaskProperties;
      certificationWorkflowKey =
        properties?.workflowSelection?.workflowDefinitionKey?.value;
    }

    if (!isExpanded) return null;

    return (
      <>
        {taskListWorkflowKey && (
          <NestedWorkflowView
            workflowKey={taskListWorkflowKey}
            parentWorkflowKey={workflowKey}
            type="task"
          />
        )}
        {certificationWorkflowKey && (
          <NestedWorkflowView
            workflowKey={certificationWorkflowKey}
            parentWorkflowKey={workflowKey}
            type="certification"
          />
        )}
      </>
    );
  };

  const hasNestedContent =
    certificationType === CertificationTypeEnum.completeTaskList ||
    certificationType === CertificationTypeEnum.completeCurriculum ||
    certificationType === CertificationTypeEnum.certification ||
    certificationType === CertificationTypeEnum.completeFormPacket ||
    certificationType === CertificationTypeEnum.completeTaskOption;

  return (
    <div
      onClick={handleCardClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ width: '100%' }}
    >
      <motion.div
        initial={{ opacity: 0, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.3 }}
      >
        <Card
          p="md"
          w="100%"
          radius="md"
          withBorder
          shadow="xs"
          style={{
            marginBottom: 0,
            cursor: 'pointer',
          }}
        >
          <Group wrap="nowrap" align="flex-start">
            <div style={{ flexShrink: 0 }}>
              <CertificationTypeIcon
                iconName={certificationType as CertificationTypeEnum}
                size={24}
              />
            </div>
            <Stack gap="md" style={{ flex: 1, minWidth: 0 }}>
              <Group justify="space-between" wrap="nowrap" align="flex-start">
                <Stack gap="xs">
                  <Group justify="flex-start" align="center" wrap="nowrap">
                    <Text fz="md" fw={600} style={{ wordBreak: 'break-word' }}>
                      {certificationConfig.certificationType ===
                      CertificationTypeEnum.thirdParty
                        ? (
                            certificationConfig.taskProperties as ThirdPartyTaskProperties
                          ).title
                        : certificationConfig.title ||
                          getCertificationType(
                            certificationConfig.certificationType
                          )?.name ||
                          'No title'}
                    </Text>
                    {hasNestedContent && (
                      <div
                        onClick={handleExpandClick}
                        style={{ cursor: 'pointer', display: 'flex' }}
                      >
                        {isExpanded ? (
                          <IconChevronDown size={20} />
                        ) : (
                          <IconChevronRight size={20} />
                        )}
                      </div>
                    )}
                  </Group>
                  {triggerDescription && (
                    <Text fz="xs" fw={500} style={{ wordBreak: 'break-word' }}>
                      {triggerDescription}
                    </Text>
                  )}
                </Stack>
                <div style={{ flexShrink: 0 }}>
                  <CertificationInfo
                    requiredConfig={
                      certificationConfig.certificationRequiredConfig
                    }
                    validConfig={certificationConfig.certificationValidConfig}
                  />
                </div>
              </Group>
              {renderNestedContent()}
            </Stack>
          </Group>
        </Card>
      </motion.div>
    </div>
  );
};

export default CertificationCard;
