import OpenAI from 'openai';
import {
  ChatCompletionMessageParam,
  ChatCompletionTool,
} from 'openai/resources';
import { useEffect, useState } from 'react';
import { useGetAssistantConfig } from './useGetAssistantConfig';

interface UseAssistantChatProps {
  apiKey: string;
}

export const useAssistantChat = ({ apiKey }: UseAssistantChatProps) => {
  const assistantConfig = useGetAssistantConfig();
  const [conversation, setConversation] = useState<
    ChatCompletionMessageParam[]
  >([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [functionLoading, setFunctionLoading] = useState(false);
  const [client, setClient] = useState<OpenAI | null>(null);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);

  useEffect(() => {
    if (assistantConfig?.initialMessage) {
      setConversation([
        { role: 'assistant', content: assistantConfig.initialMessage },
      ]);
    }

    if (assistantConfig?.client === 'openai') {
      const openai = new OpenAI({
        apiKey: apiKey,
        dangerouslyAllowBrowser: true,
      });
      setClient(openai);
    }
  }, [assistantConfig, apiKey]);

  const handleSendMessage = async () => {
    console.log('handleSendMessage');
    console.log('userInput', userInput);
    console.log('assistantConfig', assistantConfig);
    console.log('client', client);

    if (!userInput.trim() || !assistantConfig || !client) return;

    setLoading(true);
    const updatedConversation: ChatCompletionMessageParam[] = [
      ...conversation,
      { role: 'user', content: userInput },
      { role: 'assistant', content: '' }, // Add an empty assistant message
    ];
    setConversation(updatedConversation);
    setUserInput('');

    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    try {
      const stream = await client.chat.completions.create(
        {
          model: assistantConfig.clientConfig.model,
          messages: [
            { role: 'system', content: assistantConfig.initialPrompt },
            ...updatedConversation.slice(0, -1), // Exclude the empty assistant message
          ],
          tools: assistantConfig.clientConfig.tools as ChatCompletionTool[],
          tool_choice: 'auto',
          temperature: assistantConfig.clientConfig.temperature,
          stream: true,
        },
        { signal: newAbortController.signal }
      );

      let assistantContent = '';
      let currentFunctionCall: { name?: string; arguments?: string } | null =
        null;

      for await (const chunk of stream) {
        if (chunk.choices[0]?.delta?.content) {
          assistantContent += chunk.choices[0].delta.content;
          setConversation((prev) => [
            ...prev.slice(0, -1),
            { role: 'assistant', content: assistantContent },
          ]);
        }
        if (chunk.choices[0]?.delta?.tool_calls) {
          const toolCall = chunk.choices[0].delta.tool_calls[0];
          if (toolCall?.function) {
            if (toolCall.function.name) {
              currentFunctionCall = {
                name: toolCall.function.name,
                arguments: '',
              };
              setFunctionLoading(true);
            }
            if (toolCall.function.arguments) {
              currentFunctionCall!.arguments += toolCall.function.arguments;
            }
          }
        }
      }

      if (currentFunctionCall?.name && currentFunctionCall.arguments) {
        try {
          const parsedArguments = JSON.parse(currentFunctionCall.arguments);
          if (assistantConfig.functionCallback) {
            await assistantConfig.functionCallback(parsedArguments);
          }
        } catch (parseError) {
          console.error('Error parsing function arguments:', parseError);
        } finally {
          setFunctionLoading(false);
        }
      }

      if (!assistantContent && !currentFunctionCall) {
        setConversation((prev) => [
          ...prev,
          {
            role: 'assistant',
            content:
              "I've processed your request. Is there anything else I can help you with?",
          },
        ]);
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        setConversation((prev) => [
          ...prev,
          {
            role: 'assistant',
            content:
              "I'm sorry, but I encountered an error while processing your request.",
          },
        ]);
      }
    } finally {
      setLoading(false);
      setAbortController(null);
    }
  };

  const cancelStream = () => {
    if (abortController) {
      abortController.abort();
    }
  };

  return {
    conversation,
    userInput,
    setUserInput,
    loading,
    functionLoading,
    handleSendMessage,
    cancelStream,
    assistantConfig,
  };
};
