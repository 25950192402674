import { Stack } from '@mantine/core';
import WorkgroupSubgroupsCard from '../../components/WorkgroupSubgroupsCard/WorkgroupSubgroupsCard';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupSubgroupsView: React.FC = () => {
  const { workgroup } = useWorkgroup();
  const subgroups = workgroup?.children || [];

  return (
    <Stack p="md" gap="md">
      {subgroups.map((subgroup) => (
        <WorkgroupSubgroupsCard key={subgroup.id} workgroup={subgroup} />
      ))}
    </Stack>
  );
};

export default WorkgroupSubgroupsView;
