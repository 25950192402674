import { CourseLanding } from '@btrway/courseware-course-viewer';
import { startCourseViewerTransitionAtom } from '@btrway/web-routing';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useParams } from 'react-router-dom';

const CourseLandingPage: React.FC = () => {
  const startCourseViewerTransition = useSetAtom(
    startCourseViewerTransitionAtom
  );
  const { courseUid: courseUidParam } = useParams<{ courseUid: string }>();
  const courseUid = parseInt(courseUidParam ?? '0', 10);

  const handlePlay = (resume: boolean) => {
    startCourseViewerTransition({ courseUid, resume });
  };

  return <CourseLanding courseUid={courseUid} onPlay={handlePlay} />;
};

export default CourseLandingPage;
