import { AssetResponse } from '@btrway/api-courseware';
import { useCourses } from '@btrway/courseware-manager';
import {
  CourseProgress,
  CourseStatus,
  useCoursewareProgress,
} from '@btrway/courseware-progress-manager';

interface RecentCourseInfo {
  course: AssetResponse | null;
  progress: CourseProgress | null;
}

export const useMostRecentCourse = (): RecentCourseInfo => {
  const { courses } = useCourses();
  const { getAllCourseProgress } = useCoursewareProgress();

  const allProgress = getAllCourseProgress();

  // Find courses that have been started but not completed
  const inProgressCourses = courses.filter((course) => {
    const progress = allProgress[course.uid];
    return progress?.status === CourseStatus.Started;
  });

  if (inProgressCourses.length === 0) {
    return { course: null, progress: null };
  }

  // Sort by highest resumePoint to find the most recently watched
  const mostRecentCourse = inProgressCourses.reduce((latest, current) => {
    const latestProgress = allProgress[latest.uid];
    const currentProgress = allProgress[current.uid];

    return currentProgress.resumePoint > latestProgress.resumePoint
      ? current
      : latest;
  }, inProgressCourses[0]);

  return {
    course: mostRecentCourse,
    progress: allProgress[mostRecentCourse.uid],
  };
};
