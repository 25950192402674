import { StorageUploadRequest, StorageUsageEnum } from "@btrway/api-storage";
import { useAuthenticatedUser } from "@btrway/current-user";
import { useStorageUpload } from "@btrway/file-storage-components";
import { ActionIcon, Box, Button, FileButton, Group, Paper, Stack, Text } from "@mantine/core";
import { IconPaperclip, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";

interface TaskFileUploadProps {
    storageKeys: string[];
    onStorageKeysChange: (storagekeys: string[]) => void;
}

export const TaskFileUpload: React.FC<TaskFileUploadProps> = ({
    storageKeys, 
    onStorageKeysChange
}) => {
    const [uploading, setUploading] = useState(false);
    const {storageUpload} = useStorageUpload();
    const { currentUser, currentOrganization } = useAuthenticatedUser();

    const handleFileUpload = async(files: File[]) => {
        setUploading(true);
        try {
            const storageUploadRequest: StorageUploadRequest = {
                organizationId: currentOrganization.id,
                userId: currentUser.user.id,
                fileNames: files.map(file => file.name),
                storageUsageEnum: StorageUsageEnum.thirdparty,
            };

            const res = await storageUpload(storageUploadRequest, files.map(file => ({
                file: file,
                contentType: file.type,
                fileName: file.name
            })));

            const newStorageKeys = res.storageKeys;
            onStorageKeysChange([...storageKeys, ...newStorageKeys]);
        } finally {
            setUploading(false);
        }
    }

    const handleRemoveFile = (storageKeyToRemove: string) => {
        onStorageKeysChange(storageKeys.filter(key => key !== storageKeyToRemove));
    };

    return (
        <Box>
          <Stack>
            <Group p="left">
              <FileButton onChange={handleFileUpload} accept="*/*" multiple>
                {(props) => (
                  <Button {...props} leftSection={<IconPaperclip size={16} />} loading={uploading}>
                    Attach Files
                  </Button>
                )}
              </FileButton>
            </Group>
    
            {storageKeys?.length > 0 && ( // Check for length
              <Paper p="xs" withBorder>
                <Stack>
                  {storageKeys.map((key) => (
                    <Group key={key} p="apart">
                      <Text size="sm">{key.split('/').pop()}</Text>
                      <ActionIcon
                        color="red"
                        onClick={() => handleRemoveFile(key)}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                    </Group>
                  ))}
                </Stack>
              </Paper>
            )}
          </Stack>
        </Box>
      );
    };

export default TaskFileUpload;