import { Group } from '@mantine/core';
import { IconAward, IconBulb, IconListCheck } from '@tabler/icons-react';
import { formatDistanceToNow } from 'date-fns';
import pluralize from 'pluralize';
import { useNavigate } from 'react-router';
import { useDashboardStats } from '../../hooks/useDashboardStats';
import { MostRecentCourseWidget } from '../MostRecentCourseWidget/MostRecentCourseWidget';
import { TaskStatusCard } from '../TaskStatusCard/TaskStatusCard';

export const DashboardCards: React.FC = () => {
  const { certificationStats, todoStats, curriculumStats } =
    useDashboardStats();
  const navigate = useNavigate();

  return (
    <Group gap="md">
      <TaskStatusCard
        title="Certifications"
        backgroundColor="#b5e5cf"
        icon={IconAward}
        details={[
          `${certificationStats.remainingCount} Requirements Left`,
          certificationStats.nextDueDate
            ? `Due in ${formatDistanceToNow(
                new Date(certificationStats.nextDueDate)
              )}`
            : 'No upcoming due dates',
        ]}
        progress={certificationStats.progress}
        onClick={() => navigate('/app/my/certifications')}
      />
      <TaskStatusCard
        title="To-Dos"
        backgroundColor="#c3e0e5"
        icon={IconListCheck}
        details={[
          `${todoStats.completed} of ${todoStats.total} complete`,
          todoStats.nextDueDate
            ? `Next To-Do in ${formatDistanceToNow(
                new Date(todoStats.nextDueDate)
              )}`
            : 'No upcoming to-dos',
        ]}
        progress={todoStats.progress}
        onClick={() => navigate('/app/my/todos')}
      />
      <TaskStatusCard
        title="Curriculums"
        backgroundColor="#ece3f0"
        icon={IconBulb}
        details={[
          `${pluralize('course', curriculumStats.completed, true)} finished`,
          `${pluralize(
            'course',
            curriculumStats.remainingCount,
            true
          )} remaining`,
        ]}
        progress={curriculumStats.progress}
        onClick={() => navigate('/app/my/curriculums')}
      />
      <MostRecentCourseWidget />
    </Group>
  );
};
