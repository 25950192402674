import { DataTypeEnum } from '@btrway/api-core';

export const getEntityTypeIcon = (entityType: DataTypeEnum) => {
  switch (entityType) {
    case DataTypeEnum.person:
      return '👤';
    case DataTypeEnum.entityGroup:
      return '👥';
    default:
      return '📄';
  }
};

export const getEntityTypeColor = (entityType: DataTypeEnum) => {
  switch (entityType) {
    case DataTypeEnum.person:
      return 'blue';
    case DataTypeEnum.entityGroup:
      return 'green';
    default:
      return 'gray';
  }
};

export const getEntityTypeLabel = (entityType: DataTypeEnum) => {
  switch (entityType) {
    case DataTypeEnum.person:
      return 'Person';
    case DataTypeEnum.entityGroup:
      return 'Group';
    default:
      return entityType;
  }
};

export const getEntityPath = (entityType: DataTypeEnum, entityId: number) => {
  switch (entityType) {
    case DataTypeEnum.person:
      return `/app/person/${entityId}`;
    case DataTypeEnum.entityGroup:
      return `/app/entity-groups/${entityId}`;
    default:
      return '/';
  }
};
