import { StepTypeEnum } from '@btrway/api-workflow';
import { SurveyCreator } from 'survey-creator-react';
import { registerDescriptionEditor } from '../components/DescriptionQuestion/DescriptionQuestion';

export const setupToolbox = (
  creator: SurveyCreator,
  stepTypeCode?: StepTypeEnum
) => {
  // Always start with full mode
  creator.toolbox.forceCompact = false;

  if (stepTypeCode === StepTypeEnum.flowStart) {
    // For flowStart, we only want personSearch
    const personSearchItem = creator.toolbox.items.find(
      (item) => item.name === 'personSearch'
    );
    creator.toolbox.clearItems();

    if (personSearchItem) {
      creator.toolbox.addItem({
        name: 'personSearch',
        title: 'Person Search',
        iconName: 'icon-search',
        json: personSearchItem.json,
      });
    }

    // No categories for flowStart
    creator.toolbox.changeCategories([]);
  } else {
    // Show category titles
    creator.toolbox.showCategoryTitles = true;

    // Define categories with customized order and items
    creator.toolbox.defineCategories(
      [
        {
          category: 'Text Input',
          items: [
            { name: 'text', title: 'Single-Line Text' },
            { name: 'comment', title: 'Multi-Line Input' },
            { name: 'description-editor', title: 'Description'}
          ],
        },
        {
          category: 'Choice Based',
          items: ['checkbox', 'radiogroup', 'dropdown', 'tagbox', 'boolean'],
        },
        {
          category: 'Matrix',
          items: ['matrix', 'matrixdropdown', 'matrixdynamic'],
        },
        {
          category: 'Layout',
          items: ['panel', 'paneldynamic'],
        },
        {
          category: 'Custom',
          items: ['personSearch'],
        },
      ],
      true
    ); // The 'true' parameter keeps existing items that aren't explicitly listed

    const personSearchItem = creator.toolbox.getItemByName('personSearch');
    if (personSearchItem) {
      personSearchItem.title = 'Person Search';
      personSearchItem.iconName = 'icon-search';
    }

    registerDescriptionEditor();

    const descriptionItem = creator.toolbox.getItemByName('description-editor');
    if(descriptionItem) {
      descriptionItem.iconName = 'icon-pg-specific-24x24';
      descriptionItem.showTitle = false;
    }

    // Allow all categories to be expanded at once
    creator.toolbox.allowExpandMultipleCategories = true;
    creator.toolbox.keepAllCategoriesExpanded = true;
  }
};
