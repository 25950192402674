import { HeaderLayout } from '@btrway/layout-components';
import { Button, Flex, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';
import { EmailTemplateResponse } from '@btrway/api-messaging';
import { useEmailTemplates } from '../../hooks/useEmailTemplates';
import { EmailTemplateCard } from '../../components/EmailTemplateCard/EmailTemplateCard';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { useAuthContext } from '@btrway/auth-core';

export const EmailTemplatesPage: React.FC = () => {
  useAddHelpTags(['email-templates']);

  const { templates, isLoading, initializeTemplates, togglePublished } = useEmailTemplates();
  const {authState} = useAuthContext();
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateResponse | null>(null);
  const [previewOpened, { open: openPreview, close: closePreview }] = useDisclosure(false);
  const apiUrl = authState.apiUrl;

  const handlePreview = (template: EmailTemplateResponse) => {
    setSelectedTemplate(template);
    openPreview();
  };

  const handleTogglePublish = async (templateId: number) => {
    await togglePublished(templateId);
  };

  const handleDynamicContent = () => {
    const response = fetch(`${apiUrl}/api/messaging/messages/process`, {
      method: 'GET',
      credentials: 'include'
    });
    console.log(response);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <HeaderLayout
        header="Email Templates"
        rightComponent={
          <Flex gap="md">
            <Button
              onClick={() => handleDynamicContent()}
            >
              Test Dynamic Content Engine
            </Button>
            <Button
              onClick={() => initializeTemplates()}
              leftSection={<IconPlus size={16} />}
            >
              Initialize Templates
            </Button>
          </Flex>
        }
      >
        <Flex
          gap="xl"
          justify="flex-start"
          align="flex-start"
          direction="row"
          wrap="wrap"
          style={{
            margin: '0 -8px', // Negative margin to offset card padding
          }}
        >
          {templates.map((template) => (
            <div key={template.id} style={{ padding: '8px' }}>
              <EmailTemplateCard
                template={template}
                onPreview={handlePreview}
                onTogglePublish={handleTogglePublish}
              />
            </div>
          ))}
        </Flex>
      </HeaderLayout>

      {/* Modal remains the same */}
      <Modal
        opened={previewOpened}
        onClose={closePreview}
        size="xl"
        title={selectedTemplate?.templateName}
      >
        {selectedTemplate && (
          <div style={{ height: '70vh', overflow: 'auto' }}>
            <iframe
              srcDoc={selectedTemplate.templateHtml}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default EmailTemplatesPage;