import { Card, Stack, Text, Title } from '@mantine/core';
import React from 'react';
import { TimeEvent } from '../../data/DailySummary';

interface TimeEventListProps {
  title: string;
  events: TimeEvent[];
}

export const TimeEventList: React.FC<TimeEventListProps> = ({
  title,
  events,
}) => {
  return (
    <Card withBorder p="md">
      <Title order={4} mb="md">
        {title}
      </Title>
      <Stack gap="xs">
        {events.map((event, index) => (
          <Text key={index} size="sm">
            <strong>{event.time}</strong> - {event.description}
          </Text>
        ))}
      </Stack>
    </Card>
  );
};
