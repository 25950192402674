import { ColDef } from '@ag-grid-community/core';
import {
  WorkflowTemplateResponse,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { GridCardView } from '@btrway/grid-components';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { Group, Stack } from '@mantine/core';
import React from 'react';
import AddTaskTemplateButtons from '../AddTaskTemplateButtons/AddTaskTemplateButtons';
import TaskListSummaryCard from '../TaskListSummaryCard/TaskListSummaryCard';

const TaskListTemplatesView: React.FC = () => {
  const { workflowTemplates, isLoading } = useWorkflowTemplates();

  const workflowType = WorkflowTypeEnum.taskList;
  const taskListTemplates = workflowTemplates.filter(
    (template) => template.workflowType === workflowType
  );

  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Published',
      field: 'published',
      sortable: true,
    },
    {
      headerName: 'Start Type',
      field: 'templateSettings.taskListStartType',
      sortable: true,
    },
    {
      headerName: 'Month',
      field: 'templateSettings.taskListStartMonth',
      sortable: true,
    },
    {
      headerName: 'Day',
      field: 'templateSettings.taskListStartDay',
      sortable: true,
    },
    {
      headerName: 'Default User Roles',
      field: 'templateSettings.defaultUserRoles',
      sortable: true,
      valueGetter: (params) => {
        const roles = params.data.templateSettings?.defaultUserRoles;
        return roles ? roles.sort().join(', ') : '';
      },
    },
    {
      headerName: 'Task Count',
      field: 'workflowConfiguration.steps',
      sortable: true,
      valueGetter: (params) => {
        const steps = params.data.workflowConfiguration?.steps;
        return steps ? steps.length : 0;
      },
    },
  ];

  const getNavigationPath = (template: WorkflowTemplateResponse) =>
    `/app-internal/task-lists/templates/${template.templateKey}`;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Stack h="100%">
      <Group justify="flex-end">
        <AddTaskTemplateButtons />
      </Group>
      <GridCardView
        data={taskListTemplates}
        gridCols={columnDefs}
        cardComponent={TaskListSummaryCard}
        defaultView="grid"
        getNavigationPath={getNavigationPath}
      />
    </Stack>
  );
};

export default TaskListTemplatesView;
