import {
  StepCompletionDTO,
  StepConfig,
  StepTypeEnum,
  SubmitFormTaskDTO,
  TaskProperties,
  useStartWorkflow,
  useSubmitFormTask,
  WorkflowConfig,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback } from 'react';

export const useFormSubmission = (
  workflowDefinitionId: number,
  workflowConfiguration: WorkflowConfig,
  parentTaskListId?: number,
  taskProperties?: TaskProperties
) => {
  const { currentPerson, rootWorkgroup } = useAuthenticatedUser();
  const { mutateAsync: submitFormStart } = useStartWorkflow();
  const { mutateAsync: submitFormTask } = useSubmitFormTask();

  const findStartStep = useCallback((): StepConfig | null => {
    return (
      workflowConfiguration.steps?.find(
        (step) =>
          step.stepTypeCode === StepTypeEnum.flowStart &&
          !workflowConfiguration.transitions?.some(
            (transition) => transition.targetStepKey === step.stepKey
          )
      ) || null
    );
  }, [workflowConfiguration]);

  const hasElements = useCallback((formConfig: any): boolean => {
    if (formConfig.elements && formConfig.elements.length > 0) {
      return true;
    }
    if (
      formConfig.pages &&
      formConfig.pages.some(
        (page: any) => page.elements && page.elements.length > 0
      )
    ) {
      return true;
    }
    return false;
  }, []);

  const getNextStep = useCallback(
    (currentStep: StepConfig): StepConfig | null => {
      const nextTransition = workflowConfiguration.transitions?.find(
        (transition) => transition.sourceStepKey === currentStep.stepKey
      );
      if (nextTransition) {
        return (
          workflowConfiguration.steps?.find(
            (step) => step.stepKey === nextTransition.targetStepKey
          ) || null
        );
      }
      return null;
    },
    [workflowConfiguration]
  );

  const submitStepData = useCallback(
    async (stepData: StepCompletionDTO[], isFirstStep: boolean) => {
      // const taskCompletionData: TaskCompletionDTO = {
      //   personId: currentPerson.id,
      //   parentTaskListId,
      //   taskConfig,
      // };

      const workflowCompletionData: SubmitFormTaskDTO = {
        workflowDefinitionId: workflowDefinitionId,
        personId: currentPerson.id,
        workgroupId: rootWorkgroup?.id || 0,
        stepData,
        // taskCompletion: taskCompletionData,
      };

      try {
        if (isFirstStep) {
          return await submitFormStart({ data: workflowCompletionData });
        } else {
          const result = await submitFormTask({ data: workflowCompletionData });
          return result;
        }
      } catch (error) {
        console.error('Error submitting form data:', error);
        throw error;
      }
    },
    [
      workflowDefinitionId,
      currentPerson,
      rootWorkgroup,
      submitFormStart,
      submitFormTask,
      parentTaskListId,
      taskProperties,
    ]
  );

  return {
    findStartStep,
    hasElements,
    getNextStep,
    submitStepData,
  };
};
