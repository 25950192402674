import { Box } from '@mantine/core';
import React from 'react';

const WorkgroupRosterView: React.FC = () => {
  return (
    <Box m="lg">
      <h3>Roster View</h3>
    </Box>
  );
};

export default WorkgroupRosterView;
