// import { Badge, Group } from '@mantine/core';
// import React from 'react';

// interface TaskCardDateBadgesProps {
//   startDate?: number;
//   endDate?: number;
// }

// export const TaskCardDateBadges: React.FC<TaskCardDateBadgesProps> = ({
//   startDate,
//   endDate,
// }) => (
//   <Group gap={0}>
//     <Badge
//       variant="outline"
//       size="lg"
//       radius="sm"
//       color="gray"
//       styles={{
//         root: {
//           borderTopRightRadius: 2,
//           borderBottomRightRadius: 2,
//           borderRight: 0,
//           height: '32px',
//           paddingTop: '4px',
//           paddingBottom: '4px',
//           width: '120px',
//           color: 'var(--mantine-color-dark-9)',
//           fontWeight: 500,
//         },
//       }}
//     >
//       Start: {startDate ?? '-'}d
//     </Badge>
//     <Badge
//       variant="outline"
//       size="lg"
//       radius="sm"
//       color="gray"
//       styles={{
//         root: {
//           borderTopLeftRadius: 2,
//           borderBottomLeftRadius: 2,
//           marginLeft: -1,
//           height: '32px',
//           paddingTop: '4px',
//           paddingBottom: '4px',
//           width: '120px',
//           color: 'var(--mantine-color-dark-9)',
//           fontWeight: 500,
//         },
//       }}
//     >
//       End: {endDate ?? '-'}d
//     </Badge>
//   </Group>
// );

import { Badge, Group } from '@mantine/core';
import { IconChevronsRight } from '@tabler/icons-react';
import React from 'react';

interface TaskCardDateBadgesProps {
  startDate?: number;
  endDate?: number;
}

export const TaskCardDateBadges: React.FC<TaskCardDateBadgesProps> = ({
  startDate,
  endDate,
}) => (
  <Badge
    variant="outline"
    size="lg"
    radius="sm"
    color="gray"
    styles={{
      root: {
        height: '32px',
        padding: '4px 12px',
        color: 'var(--mantine-color-dark-9)',
        fontWeight: 500,
        minWidth: '100px', // Set minimum width to accommodate larger numbers
      },
    }}
  >
    <Group gap={4} justify="center" align="center" w="100%">
      <span style={{ minWidth: '24px', textAlign: 'right' }}>
        {startDate ?? 0}
      </span>
      <IconChevronsRight size={14} />
      <span style={{ minWidth: '24px', textAlign: 'left' }}>
        {endDate ?? 0}
      </span>
    </Group>
  </Badge>
);
