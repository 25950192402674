// hooks/useCertificationHandlers.ts
import {
  CertificationTypeEnum,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { useCallback } from 'react';

interface UseCertificationHandlersProps {
  reset: () => void;
  openScreen: (screen: any, options?: any) => void;
  createCertificationConfig: (certType: CertificationTypeEnum) => any;
  parentWorkflow: CommonWorkflow | null;
  workflows: any[];
  getWorkflowTypeForCertification: (
    certType: CertificationTypeEnum
  ) => WorkflowTypeEnum | undefined;
}

export const useCertificationHandlers = ({
  reset,
  openScreen,
  createCertificationConfig,
  parentWorkflow,
  workflows,
  getWorkflowTypeForCertification,
}: UseCertificationHandlersProps) => {
  const handleCertificationTypeSelect = useCallback(
    (certType: CertificationTypeEnum) => {
      reset();

      switch (certType) {
        case CertificationTypeEnum.completeCurriculum:
        case CertificationTypeEnum.submitForm:
        case CertificationTypeEnum.completeTaskOption:
        case CertificationTypeEnum.completeTaskList:
        case CertificationTypeEnum.certification:
          const workflowType = getWorkflowTypeForCertification(certType);
          openScreen(
            {
              type: 'WORKFLOW_SELECTOR',
              data: {
                taskType: certType as unknown as TaskTypeEnum,
                ...(workflowType && { workflowType }),
                workflows: workflows || [],
                selectedWorkflowKey: undefined,
              },
            },
            {
              mode: 'panel',
              position: 'left',
            }
          );
          break;

        case CertificationTypeEnum.completeCourse:
          openScreen(
            {
              type: 'COURSE_SELECTOR',
              data: { taskType: TaskTypeEnum.completeCourse },
            },
            {
              mode: 'panel',
              position: 'left',
            }
          );
          break;

        default: {
          openScreen(
            {
              type: 'CERTIFICATION_CONFIG',
              data: {
                config: createCertificationConfig(certType),
                workflow: parentWorkflow!,
                isNew: true,
              },
            },
            {
              mode: 'panel',
              position: 'left',
              width: 400,
            }
          );
          break;
        }
      }
    },
    [
      reset,
      openScreen,
      createCertificationConfig,
      parentWorkflow,
      workflows,
      getWorkflowTypeForCertification,
    ]
  );

  return {
    handleCertificationTypeSelect,
  };
};
