import { FacilityRequest, FacilityResponse } from '@btrway/api-calendar';
import { WorkgroupResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  ScrollableDrawer,
  useScrollableDrawer,
} from '@btrway/scrollable-drawer';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { FacilitiesAdminView } from '../FacilitiesAdminView/FacilitiesAdminView';
import { FacilityEdit } from '../FacilityEdit/FacilityEdit';

interface FacilitiesDrawerProps {
  opened: boolean;
  onClose: () => void;
  workgroup: WorkgroupResponse;
  facilities: FacilityResponse[];
  onFacilityChange: () => void;
}

export function FacilitiesDrawer({
  opened,
  onClose,
  workgroup,
  facilities,
  onFacilityChange,
}: FacilitiesDrawerProps) {
  const [editingFacility, setEditingFacility] =
    useState<FacilityRequest | null>(null);

  const { currentOrganization } = useAuthenticatedUser();
  const { setDrawerHeader } = useScrollableDrawer();

  const handleAddFacility = useCallback(() => {
    const newFacility = {
      name: '',
      organizationId: currentOrganization.id,
      workgroupId: workgroup.id,
    };
    setEditingFacility(newFacility);
  }, [currentOrganization.id, workgroup.id]);

  useEffect(() => {
    if (!editingFacility) {
      setDrawerHeader(
        <Button
          leftSection={<IconPlus size={16} />}
          variant="light"
          onClick={handleAddFacility}
        >
          Add Facility
        </Button>
      );
    } else {
      setDrawerHeader(null);
    }

    return () => {
      setDrawerHeader(null);
    };
  }, [editingFacility, handleAddFacility, setDrawerHeader]);

  const handleEditFacility = useCallback((facility: FacilityRequest) => {
    setEditingFacility(facility);
  }, []);

  const handleSaveFacility = useCallback(() => {
    onFacilityChange();
    setEditingFacility(null);
  }, [onFacilityChange]);

  const handleCancelEdit = useCallback(() => {
    setEditingFacility(null);
  }, []);

  const renderContent = () => {
    if (editingFacility) {
      return (
        <FacilityEdit
          facility={editingFacility}
          isNew={!editingFacility.id}
          onSave={handleSaveFacility}
          onCancel={handleCancelEdit}
        />
      );
    }

    return (
      <FacilitiesAdminView
        facilities={facilities}
        workgroup={workgroup}
        onEditFacility={handleEditFacility}
        onAddFacility={handleAddFacility}
      />
    );
  };

  return (
    <ScrollableDrawer
      opened={opened}
      onClose={onClose}
      title={
        editingFacility
          ? editingFacility.id
            ? 'Edit Facility'
            : 'Add Facility'
          : 'Manage Facilities'
      }
    >
      {renderContent()}
    </ScrollableDrawer>
  );
}
