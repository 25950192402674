import { Card, Skeleton, Stack } from '@mantine/core';
import React from 'react';

// Skeleton Loading Component
export const TaskStatisticsSkeleton: React.FC = () => (
  <Stack gap="md">
    {[1, 2, 3].map((i) => (
      <Card key={i} shadow="sm" padding="lg" withBorder>
        <Skeleton height={20} radius="sm" mb="md" />
        <Skeleton height={8} radius="sm" mb="sm" />
        <Skeleton height={8} radius="sm" mb="sm" width="70%" />
        <Skeleton height={8} radius="sm" width="50%" />
      </Card>
    ))}
  </Stack>
);
