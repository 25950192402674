import {
  CertificationConfig,
  CertificationTypeEnum,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import React from 'react';
import { WorkflowSelectorField } from '../../../selector-components/WorkflowSelectorField/WorkflowSelectorField';
import { CertificationConfigProps } from '../../../types/editors';
import { getWorkflowSelection } from '../../../utils/workflowSelectionUtil';

export const CertificationWorkflowConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const workflowType =
    config.certificationType === CertificationTypeEnum.submitForm
      ? WorkflowTypeEnum.form
      : config.certificationType === CertificationTypeEnum.completeCurriculum
      ? WorkflowTypeEnum.curriculum
      : config.certificationType === CertificationTypeEnum.completeTaskList
      ? WorkflowTypeEnum.taskList
      : config.certificationType === CertificationTypeEnum.completeTaskOption
      ? WorkflowTypeEnum.taskOption
      : config.certificationType === CertificationTypeEnum.certification
      ? WorkflowTypeEnum.certification
      : config.certificationType === CertificationTypeEnum.completeFormPacket
      ? WorkflowTypeEnum.formPacket
      : null;

  if (!workflowType) {
    throw new Error(
      'Workflow type could not be determined for CertificationWorkflowConfiguration component'
    );
  }

  const workflowSelection = getWorkflowSelection(config);

  const handleWorkflowSelect = (
    newWorkflowSelection: WorkflowSelection,
    name: string
  ) => {
    const updatedConfig: CertificationConfig = {
      ...config,
      title: name,
      taskProperties: {
        ...config.taskProperties,
        workflowSelection: newWorkflowSelection,
      },
    };
    onConfigChange(updatedConfig);
  };

  return (
    <WorkflowSelectorField
      workflowType={workflowType}
      workflowSelection={workflowSelection}
      onWorkflowSelect={handleWorkflowSelect}
    />
  );
};
