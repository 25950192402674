import { ComponentCollection } from 'survey-core';

ComponentCollection.Instance.add({
  name: 'address',
  title: 'Address',
  elementsJSON: [
    {
      type: 'text',
      name: 'street1',
      title: 'Address line 1',
      placeholder: '495 Grove Street',
      isRequired: true,
    },
    {
      type: 'text',
      name: 'street2',
      title: 'Address line 2',
      placeholder: 'Apartment #20',
    },
    {
      type: 'text',
      name: 'city',
      title: 'City/Town',
      placeholder: 'New York',
      isRequired: true,
    },
    {
      type: 'statepicker',
      name: 'stateProvince',
      startWithNewLine: false,
      title: 'State/Region/Province',
      isRequired: true,
    },
    {
      type: 'text',
      name: 'postalCode',
      title: 'Zip/Postal code',
      inputType: 'number',
      placeholder: '10014',
      isRequired: true,
    },
    {
      type: 'countrypicker',
      name: 'country',
      title: 'Country',
    },
  ],
});
