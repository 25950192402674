import { useContext } from 'react';
import { CurrentUserContext } from '../providers/CurrentUserProvider';
import { CurrentUserContextValue } from '../types/currentUserTypes';

export function useAuthenticatedUser(): CurrentUserContextValue {
  const context = useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      'useAuthenticatedUser must be used within a CurrentUserProvider'
    );
  }
  return context;
}
