import {
  AssignTaskInputProperties,
  SendEmailInputProperties,
  StepConfig,
  StepTypeEnum,
} from '@btrway/api-workflow';

// Define step properties map
export type STEP_PROPERTIES_MAP = {
  [StepTypeEnum.actionSendEmail]: SendEmailInputProperties;
  [StepTypeEnum.assignTask]: AssignTaskInputProperties;
};

// Function to get typed step properties
export function getTypedStepProperties<T extends keyof STEP_PROPERTIES_MAP>(
  config: StepConfig,
  expectedStepType: T
): STEP_PROPERTIES_MAP[T] | undefined {
  if (config.stepTypeCode === expectedStepType) {
    return config.inputProperties as STEP_PROPERTIES_MAP[T];
  }
  return undefined;
}

// Helper to determine if a step type has configurable properties
export function hasConfigurableProperties(stepType: StepTypeEnum): boolean {
  switch (stepType) {
    case StepTypeEnum.actionSendEmail:
    case StepTypeEnum.assignTask:
      return true;

    case StepTypeEnum.actionCreateCertificate:
    case StepTypeEnum.actionCreateEvent:
    case StepTypeEnum.actionCreateWorkgroupMember:
    case StepTypeEnum.actionDeleteWorkgroupMember:
    case StepTypeEnum.actionPushNotification:
    case StepTypeEnum.actionSendUserInvite:
    case StepTypeEnum.actionSms:
    case StepTypeEnum.actionUpdatePersonData:
    case StepTypeEnum.eventChapterCompleted:
    case StepTypeEnum.eventCourseCompleted:
    case StepTypeEnum.eventCurriculumCompleted:
    case StepTypeEnum.eventFormSubmitted:
    case StepTypeEnum.eventReviewCompleted:
    case StepTypeEnum.eventUserCreated:
    case StepTypeEnum.flowCondition:
    case StepTypeEnum.flowStart:
    case StepTypeEnum.flowWait:
    case StepTypeEnum.systemWorkflowActionCompleted:
    case StepTypeEnum.systemWorkflowAssigned:
    case StepTypeEnum.systemWorkflowInstanceCreated:
    case StepTypeEnum.systemWorkflowTaskCompleted:
    case StepTypeEnum.systemCreateWorkflowInstance:
    case StepTypeEnum.systemMarkWorkflowStepInstanceCompleted:
    case StepTypeEnum.formSubmission:
    case StepTypeEnum.fileUpload:
    case StepTypeEnum.missing_enum_value:
      return false;
  }
}
