import { TaskMetadata } from '@btrway/api-workflow';
import { EditorContent } from '@btrway/content-editor';
import { useTaskCategories } from '@btrway/workflow-manager';
import { Select, Stack, TextInput } from '@mantine/core';
import React, { useState } from 'react';

interface TaskMetadataConfigurationProps {
  metadata: TaskMetadata;
  onChange: (metadata: TaskMetadata) => void;
}

export const TaskMetadataConfiguration: React.FC<
  TaskMetadataConfigurationProps
> = ({ metadata, onChange }) => {
  const { taskCategories } = useTaskCategories();
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const handleMetadataChange = (field: keyof TaskMetadata, value: any) => {
    onChange({
      ...metadata,
      [field]: value,
    });
  };

  const handleContentChange = (_json: EditorContent, html: string) => {
    handleMetadataChange('content', html);
  };

  return (
    <>
      <Stack gap="md">
        <Stack gap="sm">
          <TextInput
            label="Title"
            placeholder="Enter task title"
            value={metadata?.title || ''}
            onChange={(event) =>
              handleMetadataChange('title', event.currentTarget.value)
            }
          />
          <TextInput
            label="Subtitle"
            placeholder="Enter task subtitle"
            value={metadata?.subtitle || ''}
            onChange={(event) =>
              handleMetadataChange('subtitle', event.currentTarget.value)
            }
          />
          <Select
            label="Task Category"
            placeholder="Select a category"
            data={taskCategories.map((type) => ({
              value: type.key,
              label: type.name,
            }))}
            value={metadata?.taskCategoryKey || ''}
            onChange={(value) => handleMetadataChange('taskCategoryKey', value)}
            styles={{ dropdown: { zIndex: 9999 } }}
          />
          {/* <Stack gap="xs">
            <TextInput
              label="Description"
              placeholder="Enter task description"
              value={taskConfig.metadata?.content || ''}
              onChange={(event) =>
                handleMetadataChange('content', event.currentTarget.value)
              }
            />
            <Button
              variant="light"
              onClick={() => setIsEditorOpen(true)}
              fullWidth
            >
              Open Rich Text Editor
            </Button>
          </Stack> */}
        </Stack>
      </Stack>

      {/* <StyledModal
        opened={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        title="Edit Task Description"
        variant="xl"
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
          }}
        >
          <ContentEditor
            initialContent={taskConfig.metadata?.content}
            onChange={handleContentChange}
          />
        </div>
      </StyledModal> */}
    </>
  );
};
