import { WorkgroupResponse } from '@btrway/api-core';
import { MessageRequest, MessageStatusEnum } from '@btrway/api-messaging';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Box, Button, Group, Stack } from '@mantine/core';
import React from 'react';
import { MessageComposerCore } from '../MessageComposerCore/MessageComposerCore';

interface MessageComposerProps {
  workgroup?: WorkgroupResponse;
  initialMessage?: Partial<MessageRequest>;
  onChange?: (message: MessageRequest) => void;
  onCancel?: () => void;
  onSend?: (message: MessageRequest) => Promise<void>;
  isLoading?: boolean;
}

export const MessageComposer: React.FC<MessageComposerProps> = ({
  workgroup,
  initialMessage,
  onChange,
  onCancel,
  onSend,
  isLoading = false,
}) => {
  const { currentOrganization, rootWorkgroup } = useAuthenticatedUser();
  const [message, setMessage] = React.useState<MessageRequest>(() => ({
    organizationId: currentOrganization.id,
    workgroupId: workgroup?.id || rootWorkgroup?.id || 0,
    status: MessageStatusEnum.draft,
    messageSubject: '',
    messageContent: initialMessage?.messageContent,
    messageTargets: [],
    ...initialMessage,
  }));

  const handleChange = (updatedFields: Partial<MessageRequest>) => {
    const updatedMessage = { ...message, ...updatedFields };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const handleSend = async () => {
    if (onSend) {
      await onSend(message);
    }
  };

  const isValid = Boolean(
    message.messageContent &&
      message.messageTargets &&
      message.messageTargets.length > 0
  );

  return (
    <Stack style={{ height: '100%', display: 'flex' }}>
      <Box style={{ flex: 1, minHeight: 0 }}>
        <MessageComposerCore initialMessage={message} onChange={handleChange} />
      </Box>
      <Group justify="flex-end" px="md">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSend} loading={isLoading} disabled={!isValid}>
          Send
        </Button>
      </Group>
    </Stack>
  );
};
