import { EntityTaskResponse } from '@btrway/api-workflow';
import { useMemo } from 'react';

const FIXED_DATE_GROUPS = [
  'Overdue',
  'Today',
  'Tomorrow',
  'This Week',
  'Next Week',
] as const;
type DateGroup = (typeof FIXED_DATE_GROUPS)[number] | string;

interface GroupedTasks {
  [key: string]: EntityTaskResponse[];
}

interface GroupedTasksWithMore {
  tasks: GroupedTasks;
  hasMore: { [key: string]: boolean };
}

const useGroupedTasks = (tasks: EntityTaskResponse[]): GroupedTasksWithMore => {
  return useMemo(() => {
    // Initialize groups with fixed date categories
    const groups: GroupedTasks = FIXED_DATE_GROUPS.reduce((acc, group) => {
      acc[group] = [];
      return acc;
    }, {} as GroupedTasks);

    const hasMore: { [key: string]: boolean } = {};

    // Set up date boundaries
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const thisWeekEnd = new Date(today);
    thisWeekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const nextWeekStart = new Date(thisWeekEnd);
    nextWeekStart.setDate(nextWeekStart.getDate() + 1);

    const nextWeekEnd = new Date(nextWeekStart);
    nextWeekEnd.setDate(nextWeekEnd.getDate() + 6);

    // Group tasks based on assigned date
    tasks.forEach((task) => {
      if (!task.assignedAt) return;

      const assignedDate = new Date(task.assignedAt);

      let group: DateGroup;
      if (assignedDate < today) {
        group = 'Overdue';
      } else if (assignedDate.toDateString() === today.toDateString()) {
        group = 'Today';
      } else if (assignedDate.toDateString() === tomorrow.toDateString()) {
        group = 'Tomorrow';
      } else if (assignedDate <= thisWeekEnd) {
        group = 'This Week';
      } else if (assignedDate <= nextWeekEnd) {
        group = 'Next Week';
      } else {
        group = assignedDate.toLocaleString('default', {
          month: 'long',
          year: 'numeric',
        });
      }

      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push(task);
    });

    // Sort tasks within each group
    Object.keys(groups).forEach((group) => {
      groups[group].sort((a, b) => {
        // Safe date comparison with fallback to 0
        const getAssignedTime = (task: EntityTaskResponse): number => {
          return task.assignedAt ? new Date(task.assignedAt).getTime() : 0;
        };

        const getDueTime = (task: EntityTaskResponse): number => {
          return task.dueAt ? new Date(task.dueAt).getTime() : 0;
        };

        const dateA = getAssignedTime(a);
        const dateB = getAssignedTime(b);

        // Primary sort by Assigned Date
        if (dateA !== dateB) {
          return dateA - dateB;
        }

        // Secondary sort by Due Date
        return getDueTime(a) - getDueTime(b);
      });

      // Limit group size and track if there are more items
      if (groups[group].length > 100) {
        hasMore[group] = true;
        groups[group] = groups[group].slice(0, 100);
      }
    });

    // Sort and organize final groups
    const sortedGroups: GroupedTasks = {};

    // Add fixed date groups first
    FIXED_DATE_GROUPS.forEach((group) => {
      if (groups[group] && groups[group].length > 0) {
        sortedGroups[group] = groups[group];
      }
    });

    // Sort and add monthly groups
    const monthlyGroups = Object.keys(groups).filter(
      (g) => !FIXED_DATE_GROUPS.includes(g as any)
    );
    monthlyGroups.sort((a, b) => {
      // Safe date comparison for monthly groups
      try {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
      } catch {
        return a.localeCompare(b);
      }
    });

    monthlyGroups.forEach((group) => {
      if (groups[group] && groups[group].length > 0) {
        sortedGroups[group] = groups[group];
      }
    });

    return { tasks: sortedGroups, hasMore };
  }, [tasks]);
};

export default useGroupedTasks;
