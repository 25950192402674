import { PublicRegistrationFormResponse } from '@btrway/api-core';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { publicRegistrationFormAtom } from '../atoms/publicRegistrationFormAtom';
import {
  registrationYearAtom,
  workgroupIdAtom,
} from '../atoms/registrationAtoms';
import { useRegistrationSubmission } from './useRegistrationSubmission';

interface UseRegistrationProviderProps {
  workgroupId: number;
  publicFormRegistration: PublicRegistrationFormResponse;
  year?: number;
}

export const useRegistrationProvider = ({
  workgroupId,
  publicFormRegistration,
  year,
}: UseRegistrationProviderProps) => {
  const setPublicFormRegistration = useSetAtom(publicRegistrationFormAtom);
  const setWorkgroupId = useSetAtom(workgroupIdAtom);
  const setRegistrationYear = useSetAtom(registrationYearAtom);
  const { initializeRegistration } = useRegistrationSubmission();

  const initialize = useCallback(async () => {
    setWorkgroupId(workgroupId);
    setPublicFormRegistration(publicFormRegistration);
    if (year) {
      setRegistrationYear(year);
    }
    await initializeRegistration();
  }, [
    workgroupId,
    publicFormRegistration,
    year,
    setWorkgroupId,
    setPublicFormRegistration,
    setRegistrationYear,
    initializeRegistration,
  ]);

  return {
    isLoading: false, // You might want to track loading state internally
    initialize,
  };
};
