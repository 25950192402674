import { WorkflowTypeEnum } from '@btrway/api-workflow';

export const WORKFLOW_TYPE_CONFIG = {
  [WorkflowTypeEnum.automation]: {
    label: 'Automation',
    path: 'automations',
  },
  [WorkflowTypeEnum.taskList]: {
    label: 'To-do List',
    path: 'todo-lists',
  },
  [WorkflowTypeEnum.form]: {
    label: 'Form',
    path: 'forms',
  },
  [WorkflowTypeEnum.formPacket]: {
    label: 'Form Packet',
    path: 'form-packets',
  },
  [WorkflowTypeEnum.curriculum]: {
    label: 'Curriculums',
    path: 'curriculums',
  },
  [WorkflowTypeEnum.certification]: {
    label: 'Certification',
    path: 'certifications',
  },
} as const;

export const getWorkflowTemplatePath = (
  workflowType: WorkflowTypeEnum,
  templateKey: string
): string => {
  const config =
    WORKFLOW_TYPE_CONFIG[workflowType as keyof typeof WORKFLOW_TYPE_CONFIG];
  if (!config) return '';

  return `/app-internal/template-admin/${config.path}/${templateKey}`;
};
