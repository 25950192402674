import { AssetResponse } from '@btrway/api-courseware';
import { StorageUsageEnum } from '@btrway/api-storage';
import { useImageUpload } from '@btrway/file-storage-components';
import { ActionIcon, Box, Card, Group, Image, Text } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface CourseCarouselItemProps {
  course: AssetResponse;
  onPlay: (courseUid: number) => void;
  isFirst: boolean;
  isLast: boolean;
  growthFactor: number;
  itemHeight: number;
}

const CourseCarouselItem: React.FC<CourseCarouselItemProps> = ({
  course,
  onPlay,
  isFirst,
  isLast,
  growthFactor,
  itemHeight,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.course,
    initialImageKey: course.metadata?.imageKey,
  });

  const [animatedGifUrl, setAnimatedGifUrl] = useState('');
  const [isGifLoaded, setIsGifLoaded] = useState(false);

  useEffect(() => {
    if (course.chapters && course.chapters.length > 0) {
      const playbackId = course.chapters[0].videoPlaybackIdentifier;
      if (playbackId) {
        setAnimatedGifUrl(
          `https://image.mux.com/${playbackId}/animated.gif?start=20&end=30&width=320&fps=15`
        );
      }
    }
  }, [course]);

  const getTransformOrigin = () => {
    if (isFirst) return 'bottom left';
    if (isLast) return 'bottom right';
    return 'bottom center';
  };

  const getZIndex = () => {
    if (isFirst) return 3;
    if (isLast) return 3;
    return isHovered ? 4 : 2;
  };

  return (
    <Box
      style={{
        position: 'relative',
        height: `${itemHeight}px`,
        width: '100%',
        overflow: 'visible',
      }}
    >
      <Card
        shadow={isHovered ? 'lg' : 'sm'}
        bg="black"
        p={0}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
          setIsGifLoaded(false);
        }}
        style={{
          height: '100%',
          width: '100%',
          transition: 'all 0.3s ease',
          position: 'absolute',
          bottom: 0,
          left: 0,
          transformOrigin: getTransformOrigin(),
          transform: isHovered ? `scale(${growthFactor})` : 'scale(1)',
          zIndex: getZIndex(),
          overflow: 'hidden',
          boxShadow: isHovered ? '0 8px 16px rgba(0, 0, 0, 0.5)' : 'none',
        }}
      >
        <Box style={{ height: '100%', position: 'relative' }}>
          <Image
            src={imageUrl}
            fit="cover"
            height="100%"
            width="100%"
            style={{
              transition: 'filter 0.3s ease',
              filter: isHovered ? 'brightness(1.1)' : 'brightness(1)',
            }}
          />
          {isHovered && animatedGifUrl && (
            <Image
              src={animatedGifUrl}
              fit="cover"
              height="100%"
              width="100%"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: isGifLoaded ? 1 : 0,
                transition: 'opacity 0.3s ease',
              }}
              onLoad={() => setIsGifLoaded(true)}
            />
          )}
          <Box
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'rgba(0, 0, 0, 0.7)',
              transform: `translateY(${isHovered ? '0' : '100%'})`,
              transition: 'transform 0.3s ease',
            }}
          >
            <Group justify="flex-start" wrap="nowrap" p="xs">
              <ActionIcon
                variant="white"
                onClick={() => onPlay(course.uid)}
                radius="xl"
                color="black"
              >
                <IconPlayerPlayFilled size={16} />
              </ActionIcon>
              <Text fw={500} size="xs" c="white" style={{ lineHeight: 1 }}>
                {course.title}
              </Text>
            </Group>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default CourseCarouselItem;
