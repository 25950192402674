import { Button } from '@mantine/core';
import React from 'react';
import DayValuePicker from '../../selects/DayValuePicker/DayValuePicker';

interface DatePickerButtonProps {
  month: number | undefined;
  day: number | undefined;
  onChange: (month: number | undefined, day: number | undefined) => void;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
  month,
  day,
  onChange,
}) => {
  const handleSaveDateValues = (
    month: number | undefined,
    day: number | undefined,
    offsetDays: number | undefined
  ) => {
    onChange(month, day);
  };

  const getButtonText = () => {
    if (month && day) {
      return `${month}/${day}`;
    }
    return `Select Month and Day`;
  };

  const hasDate = month && day;

  return (
    <DayValuePicker
      initialMonth={month}
      initialDay={day}
      initialOffsetDays={0}
      onSave={handleSaveDateValues}
    >
      {(toggle) => (
        <Button
          color="blue"
          variant={hasDate ? 'light' : 'subtle'}
          size="compact-xs"
          radius="lg"
          onClick={toggle}
        >
          {getButtonText()}
        </Button>
      )}
    </DayValuePicker>
  );
};
