import { ICellRendererParams } from '@ag-grid-community/core';
import { Badge, useMantineColorScheme } from '@mantine/core';
import React from 'react';

export interface BadgeGridCellParams extends Partial<ICellRendererParams> {
  value: number;
  color: string;
}

export const BadgeGridCell: React.FC<BadgeGridCellParams> = ({
  value,
  color,
}) => {
  const { colorScheme } = useMantineColorScheme();

  if (typeof value !== 'number') {
    return null;
  }

  const variant = colorScheme === 'dark' ? 'light' : undefined;

  return (
    <Badge color={color} variant={variant}>
      {value.toFixed(2)}%
    </Badge>
  );
};
