import { SearchUserResponse } from '@btrway/api-core';
import { HeaderLayout } from '@btrway/layout-components';
import { Box, Button, Center, Flex } from '@mantine/core';
import { IconUserPlus } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import { GlobalInviteNewUserModal } from '../../components/GlobalInviteNewUserModal/GlobalInviteNewUserModal';
import GlobalUserDetailCard from '../../components/GlobalUserDetailCard/GlobalUserDetailCard';
import GlobalUserSearchInput from '../../components/GlobalUserSearchInput/GlobalUserSearchInput';
import GlobalUsersGrid from '../../components/GlobalUsersGrid/GlobalUsersGrid';

const GlobalUserManagementPage: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<SearchUserResponse | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleRowSelected = useCallback((params: any) => {
    if (params.node.isSelected()) {
      setSelectedUser(params.data as SearchUserResponse);
    }
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setSelectedUser(null);
  }, [searchValue]);

  return (
    <HeaderLayout header="Users">
      <Box mb="md">
        <Flex justify="space-between" align="center">
          <Center w={1500}>
            <GlobalUserSearchInput onSearch={handleSearch} />
          </Center>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              leftSection={<IconUserPlus size={14} />}
              onClick={handleOpenModal}
              size="sm"
              // compact
            >
              Invite User
            </Button>
          </Box>
        </Flex>
      </Box>

      <div
        style={{
          display: 'flex',
          flex: '1',
          marginTop: '20px',
          height: '100%',
        }}
      >
        <div style={{ flex: '1', marginRight: '20px' }}>
          <GlobalUsersGrid
            searchValue={searchValue}
            onRowSelected={handleRowSelected}
          />
        </div>

        {selectedUser && (
          <div style={{ width: '30%' }}>
            <GlobalUserDetailCard selectedUser={selectedUser} />
          </div>
        )}
      </div>

      <GlobalInviteNewUserModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </HeaderLayout>
  );
};

export default GlobalUserManagementPage;
