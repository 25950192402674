import {
  WorkgroupTypeRequest,
  WorkgroupTypeResponse,
  getGetWorkgroupTypesByOrganizationIdQueryKey,
  useDeleteWorkgroupType,
  useGetWorkgroupTypesByOrganizationId,
  useSaveWorkgroupType,
} from '@btrway/api-core';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import { workgroupTypesAtom } from '../atoms/workgroupTypesAtom';

export const useWorkgroupTypes = (organizationId: number) => {
  const [workgroupTypes, setWorkgroupTypes] = useAtom(workgroupTypesAtom);
  const { mutateAsync: saveWorkgroupTypeMutation } = useSaveWorkgroupType();
  const { mutateAsync: deleteWorkgroupTypeMutation } = useDeleteWorkgroupType();
  const queryClient = useQueryClient();

  const queryKey = getGetWorkgroupTypesByOrganizationIdQueryKey(organizationId);

  const {
    data: fetchedWorkgroupTypes,
    isLoading,
    error,
  } = useGetWorkgroupTypesByOrganizationId(organizationId, {
    query: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      queryKey: queryKey,
    },
  });

  useEffect(() => {
    if (fetchedWorkgroupTypes) {
      setWorkgroupTypes(fetchedWorkgroupTypes);
    }
  }, [fetchedWorkgroupTypes, setWorkgroupTypes]);

  const addWorkgroupType = useCallback(
    async (workgroupTypeRequest: WorkgroupTypeRequest) => {
      try {
        const savedWorkgroupType = (await saveWorkgroupTypeMutation({
          data: workgroupTypeRequest,
        })) as WorkgroupTypeResponse;

        setWorkgroupTypes((prevWorkgroupTypes) => {
          const updatedWorkgroupTypes = [
            ...prevWorkgroupTypes,
            savedWorkgroupType,
          ];
          // Update React Query cache
          queryClient.setQueryData(queryKey, updatedWorkgroupTypes);
          return updatedWorkgroupTypes;
        });

        return savedWorkgroupType;
      } catch (error) {
        console.error('Error saving workgroup:', error);
        throw error;
      }
    },
    [saveWorkgroupTypeMutation, setWorkgroupTypes, queryClient, queryKey]
  );

  const deleteWorkgroupType = useCallback(
    async (workgroupTypeId: number) => {
      try {
        await deleteWorkgroupTypeMutation({ workgroupTypeId: workgroupTypeId });
        setWorkgroupTypes((prevWorkgroupTypes) => {
          const updatedWorkgroupTypes = prevWorkgroupTypes.filter(
            (t) => t.id !== workgroupTypeId
          );
          // Update React Query cache
          queryClient.setQueryData(queryKey, updatedWorkgroupTypes);
          return updatedWorkgroupTypes;
        });
      } catch (error) {
        console.error('Error deleting workgroup:', error);
        throw error;
      }
    },
    [deleteWorkgroupTypeMutation, setWorkgroupTypes, queryClient, queryKey]
  );

  const getWorkgroupType = (workgroupTypeId: number) => {
    return workgroupTypes?.find((type) => type.id === workgroupTypeId);
  };

  return {
    workgroupTypes,
    getWorkgroupType,
    isLoading,
    error,
    addWorkgroupType,
    deleteWorkgroupType,
  };
};
