import { Card, Group, Text, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import React from 'react';

interface RegistrationFormStepProps {
  title: string;
  index: number;
  isSelected: boolean;
  isCompleted: boolean;
  isReached: boolean;
  onClick: () => void;
}

export const RegistrationFormStep: React.FC<RegistrationFormStepProps> = ({
  title,
  index,
  isSelected,
  isCompleted,
  isReached,
  onClick,
}) => {
  return (
    <Card
      padding="md"
      radius="md"
      shadow="none"
      withBorder
      bg={isSelected ? 'white' : 'gray.2'}
      onClick={isReached ? onClick : undefined}
      style={{ cursor: isReached ? 'pointer' : 'default' }}
    >
      <Group wrap="nowrap">
        <ThemeIcon
          radius="xl"
          size="md"
          color={isCompleted ? 'green' : isSelected ? 'blue' : 'gray'}
        >
          {isCompleted ? (
            <IconCheck style={{ width: '70%', height: '70%' }} />
          ) : (
            <Text c="white" fw={700}>
              {index + 1}
            </Text>
          )}
        </ThemeIcon>
        <Text fz="xl" fw={600}>
          {title}
        </Text>
      </Group>
    </Card>
  );
};
