import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const WorkgroupLandingPage = lazy(() =>
  import('@btrway/public-sites').then((module) => ({
    default: module.WorkgroupLandingPage,
  }))
);
const RegistrationFormPage = lazy(() =>
  import('@btrway/registration-forms').then((module) => ({
    default: module.RegistrationFormPage,
  }))
);

const RegistrationCallback = lazy(() => 
  import('@btrway/registration-forms').then((module) => ({
    default: module.RegistrationCallback,
  }))
);

const RegistrationRouter: React.FC = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path=":workgroupIdentifier" element={<WorkgroupLandingPage />} />
        <Route
          path=":workgroupIdentifier/:formId/:year?"
          element={<RegistrationFormPage />}
        />
        <Route path="callback" element={<RegistrationCallback />} />
      </Routes>
    </Suspense>
  );
};

export default RegistrationRouter;
