import { ColDef } from '@ag-grid-community/core';
import { ActionIcon, Group, Menu, Text } from '@mantine/core';
import { IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { useState } from 'react';

interface MobileSortingMenuProps<TData> {
  columnDefs: ColDef<TData>[];
  onSortChange: (column: ColDef<TData>, sort: 'asc' | 'desc' | null) => void;
  currentSort: { field: string; sort: 'asc' | 'desc' | null };
}

const MobileSortingMenu = <TData extends Record<string, any> = any>({
  columnDefs,
  onSortChange,
  currentSort,
}: MobileSortingMenuProps<TData>) => {
  const [opened, setOpened] = useState(false);
  const sortableColumns = columnDefs.filter((col) => col.sortable !== false);

  const handleSortChange = (column: ColDef<TData>) => {
    const sortingOrder = column.sortingOrder || ['asc', 'desc', null];

    let nextSort: 'asc' | 'desc' | null;

    if (column.field === currentSort.field) {
      // If it's the same column, cycle through the sort orders
      const currentSortIndex = sortingOrder.indexOf(currentSort.sort);
      const nextSortIndex = (currentSortIndex + 1) % sortingOrder.length;
      nextSort = sortingOrder[nextSortIndex] as 'asc' | 'desc' | null;
    } else {
      // If it's a new column, start with the first sort order defined for the column
      nextSort = sortingOrder[0] as 'asc' | 'desc' | null;
    }

    onSortChange(column, nextSort);
    setOpened(false);
  };

  const getSortIcon = (sort: 'asc' | 'desc' | null) => {
    switch (sort) {
      case 'asc':
        return <IconSortAscending size={16} />;
      case 'desc':
        return <IconSortDescending size={16} />;
      default:
        return null;
    }
  };

  return (
    <Menu opened={opened} onChange={setOpened} position="bottom-end">
      <Menu.Target>
        <ActionIcon size="lg" variant="subtle" color="blue">
          <IconSortDescending size={24} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Sort by</Menu.Label>
        {sortableColumns.map((col) => (
          <Menu.Item key={col.field} onClick={() => handleSortChange(col)}>
            <Group justify="space-between">
              <Text fw={currentSort.field === col.field ? 'bold' : 'normal'}>
                {col.headerName}
              </Text>
              {getSortIcon(
                currentSort.field === col.field ? currentSort.sort : null
              )}
            </Group>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default MobileSortingMenu;
