import { EventConfig } from '@btrway/api-workflow';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workflowConfigurationAtom } from './workflowConfigurationAtom';

export const workflowEventAtomFamily = atomFamily((eventKey: string) =>
  atom(
    (get) => {
      const workflowConfig = get(workflowConfigurationAtom);
      return (
        workflowConfig?.events?.find((event) => event.eventKey === eventKey) ||
        null
      );
    },
    (get, set, update: Partial<EventConfig>) => {
      set(workflowConfigurationAtom, (prev) => {
        if (!prev?.events) return prev;
        const eventIndex = prev.events.findIndex(
          (event) => event.eventKey === eventKey
        );
        if (eventIndex === -1) return prev;

        return {
          ...prev,
          events: [
            ...prev.events.slice(0, eventIndex),
            { ...prev.events[eventIndex], ...update },
            ...prev.events.slice(eventIndex + 1),
          ],
        };
      });
    }
  )
);
