import {
  SystemEventTypeEnum,
  useGetEventTypesByOrganizationId,
} from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Chip, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import React, { useEffect } from 'react';

interface EventTypeSelectorProps {
  value?: number;
  onChange: (value: number) => void;
}

export const EventTypeSelector: React.FC<EventTypeSelectorProps> = ({
  value,
  onChange,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { data: eventTypes, isLoading } = useGetEventTypesByOrganizationId(
    currentOrganization.id
  );

  const filteredAndSortedEventTypes = eventTypes
    ?.filter(
      (eventType) => eventType.systemType !== SystemEventTypeEnum.availability
    )
    ?.slice()
    .sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
    );

  // Set default value when event types are loaded
  useEffect(() => {
    if (filteredAndSortedEventTypes?.length && !value && onChange) {
      onChange(filteredAndSortedEventTypes[0].id);
    }
  }, [filteredAndSortedEventTypes, value, onChange]);

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  if (!filteredAndSortedEventTypes?.length) {
    return <Text c="dimmed">No event types available</Text>;
  }

  return (
    <Stack gap="xs">
      <Text size="sm" fw={500}>
        Event Type
      </Text>
      <Chip.Group
        multiple={false}
        value={value?.toString() ?? ''}
        onChange={(val) => {
          if (val) {
            onChange(parseInt(val, 10));
          }
        }}
      >
        <Group gap="xs">
          {filteredAndSortedEventTypes.map((eventType) => (
            <Chip
              key={eventType.id}
              value={eventType.id.toString()}
              style={
                eventType.primaryColor
                  ? {
                      backgroundColor: `${eventType.primaryColor}20`,
                      borderColor: eventType.primaryColor,
                    }
                  : undefined
              }
            >
              {eventType.name}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Stack>
  );
};
