// @btrway/courseware-progress-manager/src/types/progressTypes.ts

import { ChapterDTO, ChapterProgressResponse } from '@btrway/api-courseware';

export enum CourseStatus {
  Complete = 'Complete',
  Started = 'Started',
  NotStarted = 'NotStarted',
}

export enum ChapterStatus {
  INCOMPLETE = 'INCOMPLETE',
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
}

export interface ChapterProgress {
  progress: ChapterProgressResponse | undefined;
  isComplete: boolean;
  currentTimeSeconds: number;
  maxTimeSeconds: number;
}

export interface CourseProgress {
  status: CourseStatus;
  resumePoint: number;
  completionPercent: number;
  totalDuration: number;
  completedDuration: number;
}

export interface CurriculumProgress {
  completionPercent: number;
  completedCourses: number;
  totalCourses: number;
  isComplete: boolean;
}

export interface CoursewareProgress {
  completedTimeSeconds: number;
  completionPercent: number;
  totalTimeSeconds: number;
}

export interface ChapterWithProgress {
  chapter: ChapterDTO;
  progress: ChapterProgressResponse | undefined;
}
