import { HelpRepositoryEnum } from '@btrway/api-help';
import React from 'react';
import { useLocation } from 'react-router-dom';
import HelpResourcesView from '../../components/HelpResourcesView/HelpResourcesView';

const HelpResourcesPage: React.FC = () => {
  const location = useLocation();
  const pathSegment = location.pathname.split('/').pop() as string;

  // Check if the path segment is a valid enum value
  const isValidRepository = (value: string): value is HelpRepositoryEnum => {
    return Object.values(HelpRepositoryEnum).includes(
      value as HelpRepositoryEnum
    );
  };

  if (!isValidRepository(pathSegment)) {
    return <div>Invalid repository</div>;
  }

  return <HelpResourcesView repository={pathSegment} />;
};

export default HelpResourcesPage;
