import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { ActionIcon, Group, Text } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';

interface WorkflowDefinitionMetadataViewProps {
  workflow: CommonWorkflow;
  onEditClick: () => void;
}

export const WorkflowDefinitionMetadataView: React.FC<
  WorkflowDefinitionMetadataViewProps
> = ({ workflow, onEditClick }) => {
  return (
    <Group justify="space-between" align="center">
      <Text size="xl" c="white" fw={700}>
        {workflow?.name || ''}
      </Text>
      <ActionIcon variant="subtle" color="white" onClick={onEditClick}>
        <IconEdit size={20} />
      </ActionIcon>
    </Group>
  );
};
