import {
  CreatePersonRequest,
  PersonResponse,
  useCreatePerson,
  useGetWorkgroupsByOrganizationId,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/security-manager';
import { Box, Button, Modal } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { Model } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import { Survey } from 'survey-react-ui';

export function AddPersonModal() {
  const [opened, setOpened] = useState(false);
  const { currentOrganization } = useAuthenticatedUser();
  const { mutateAsync: savePerson } = useCreatePerson();
  const [survey, setSurvey] = useState<Model | null>(null);

  const { data: workgroupsResponse } = useGetWorkgroupsByOrganizationId(
    currentOrganization.id
  );

  const { userRoles } = useUserRoles();

  const workgroupOptions = useMemo(() => {
    if (!workgroupsResponse) return [];
    return workgroupsResponse
      .filter(
        (wg) =>
          wg.workgroupType.name === 'School' ||
          wg.workgroupType.name === 'School System'
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((wg) => ({ value: wg.id.toString(), label: wg.name }));
  }, [workgroupsResponse]);

  const userRoleOptions = useMemo(() => {
    if (!userRoles) return [];
    return userRoles
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((role) => ({ value: role.id.toString(), label: role.name }));
  }, [userRoles]);

  useEffect(() => {
    const surveyJson = {
      elements: [
        {
          type: 'text',
          name: 'firstName',
          title: 'First Name',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'lastName',
          title: 'Last Name',
          isRequired: true,
        },
        {
          type: 'text',
          name: 'email',
          title: 'Email',
          isRequired: true,
          validators: [{ type: 'email' }],
        },
        {
          type: 'dropdown',
          name: 'workgroupId',
          title: 'Workgroup',
          isRequired: true,
          choices: [],
        },
        {
          type: 'dropdown',
          name: 'userRoleId',
          title: 'User Role',
          isRequired: true,
          choices: [],
        },
      ],
    };

    const newSurvey = new Model(surveyJson);
    setSurvey(newSurvey);
  }, []);

  useEffect(() => {
    if (survey) {
      survey.getQuestionByName('workgroupId').choices = workgroupOptions.map(
        (workgroup) => ({ value: workgroup.value, text: workgroup.label })
      );
      survey.getQuestionByName('userRoleId').choices = userRoleOptions.map(
        (role) => ({ value: role.value, text: role.label })
      );
    }
  }, [survey, workgroupOptions, userRoleOptions]);

  const handleSubmit = async (sender: any) => {
    if (!currentOrganization) {
      console.error('No current organization');
      return;
    }

    const results = sender.data;

    const newPerson: CreatePersonRequest = {
      firstName: results.firstName,
      lastName: results.lastName,
      email: results.email,
      organizationId: currentOrganization.id,
      workgroupId: parseInt(results.workgroupId),
      userRoleId: parseInt(results.userRoleId),
    };

    console.log('newPerson');
    try {
      const data = (await savePerson({
        data: newPerson,
      })) as PersonResponse;
      console.log('Person saved:', data);
      setOpened(false);
      if (survey) {
        survey.clear();
      }
    } catch (error) {
      console.error('Error saving person:', error);
    }
  };

  // Custom CSS to ensure all fields are visible without scrolling
  const customCss = {
    root: 'survey-container',
    container: 'survey-content-container',
    body: 'survey-body',
  };

  return (
    <>
      <Button onClick={() => setOpened(true)}>Add Person</Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Add Person"
        size="xl"
        centered
      >
        <Box style={{ minHeight: '400px' }}>
          {survey && (
            <Survey model={survey} onComplete={handleSubmit} css={customCss} />
          )}
        </Box>
        {/* <Group justify="right" mt="md">
          <Button onClick={() => setOpened(false)}>Cancel</Button>
        </Group> */}
      </Modal>
    </>
  );
}
