import { MessageRequest } from '@btrway/api-messaging';
import { EditorContent, isEditorContent } from '@btrway/content-editor';
import { MessageComposer } from '@btrway/message-composer';
import { Box } from '@mantine/core';
import React from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

interface SendEmailTaskProperties {
  subject?: string;
  body?: string | EditorContent;
}

const SendEmailTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  onClose,
}) => {
  const { completeTask } = useTaskActionContext();
  const emailProperties = taskProperties as SendEmailTaskProperties;

  const handleSend = async (message: MessageRequest) => {
    try {
      await completeTask('Email sent successfully');
      onClose();
    } catch (error) {
      console.error('Failed to complete email task:', error);
    }
  };

  const getInitialMessageContent = (
    content: string | EditorContent | undefined
  ) => {
    if (!content) {
      return { type: 'doc', content: [] };
    }

    if (typeof content === 'object' && isEditorContent(content)) {
      return content;
    }

    return {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [{ type: 'text', text: content }],
        },
      ],
    };
  };

  return (
    <Box p="md" style={{ height: '100%' }}>
      <MessageComposer
        initialMessage={{
          messageSubject: emailProperties?.subject,
          messageContent: getInitialMessageContent(emailProperties?.body),
        }}
        onSend={handleSend}
        onCancel={onClose}
      />
    </Box>
  );
};

export default SendEmailTask;
