import {
  AssetFolderRequest,
  AssetFolderResponse,
} from '@btrway/api-courseware';

export const mapAssetFolderResponseToRequest = (
  response: AssetFolderResponse
): AssetFolderRequest => {
  return {
    id: response.id,
    title: response.title,
  };
};
