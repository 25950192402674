/**
 * Formats a duration in seconds into a string representation of minutes and seconds.
 * @param durationSeconds The duration in seconds.
 * @returns A formatted string in the format "Xm Ys".
 */
export const getFormattedDuration = (durationSeconds: number): string => {
  const minutes = Math.floor(durationSeconds / 60);
  const seconds = Math.round(durationSeconds % 60);
  return `${minutes}m ${seconds}s`;
};
