import {
  useDeployWorkflowTemplates,
  WorkflowTemplateDeploymentActionProperties,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import pluralize from 'pluralize';
import React from 'react';

interface DeploymentButtonProps {
  workflow?: CommonWorkflow | null;
  workflowType?: WorkflowTypeEnum;
}

const getFriendlyWorkflowType = (type: WorkflowTypeEnum): string => {
  const friendlyNames: Record<WorkflowTypeEnum, string> = {
    automation: 'Automation',
    curriculum: 'Curriculum',
    taskList: 'Task List',
    form: 'Form',
    formPacket: 'Form Packet',
    file: 'File',
    certification: 'Certification',
    taskOption: 'Task Option',
  };
  return friendlyNames[type];
};

export const DeploymentButton: React.FC<DeploymentButtonProps> = ({
  workflow,
  workflowType,
}) => {
  const { mutateAsync: deployWorkflowTemplates } = useDeployWorkflowTemplates();

  const handleClick = async () => {
    try {
      const deploymentProps: WorkflowTemplateDeploymentActionProperties = {
        workflowTemplateIds: workflow?.id ? [workflow.id] : undefined,
        workflowType: workflow?.workflowType || workflowType,
      };
      notifications.show({
        title: 'Success',
        message: 'Deployment Initiated',
        color: 'green',
      });

      await deployWorkflowTemplates({ data: deploymentProps });
    } catch (error) {
      console.error('Failed to initiate deployment:', error);
    }
  };

  const buttonText = workflow
    ? `Deploy ${getFriendlyWorkflowType(workflow.workflowType)}`
    : workflowType
    ? `Deploy All ${pluralize(getFriendlyWorkflowType(workflowType))}`
    : 'Deploy All';

  return <Button onClick={handleClick}>{buttonText}</Button>;
};
