import { StorageUsageEnum } from '@btrway/api-storage';
import { Box, Button, Image } from '@mantine/core';
import React, { useState } from 'react';
import { useImageUpload } from '../../hooks/useImageUpload';
import EditableImageModal from '../EditableImageModal/EditableImageModal';

interface EditableImageProps {
  storageUsage: StorageUsageEnum;
  initialImageKey?: string;
  onImageUpload?: (imageKey: string) => void;
  width?: number;
  height?: number;
  circular?: boolean;
  editable?: boolean;
  editButtonLabel?: string;
  modalTitle?: string;
  aspectRatio?: { width: number; height: number };
}

const EditableImage: React.FC<EditableImageProps> = ({
  storageUsage,
  initialImageKey,
  onImageUpload,
  width,
  height,
  circular = false,
  editable = false,
  editButtonLabel = 'Edit Image',
  modalTitle = 'Upload Image',
  aspectRatio,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { imageUrl, handleImageUpload } = useImageUpload({
    storageUsage,
    initialImageKey,
    onImageUpload,
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  let size: number | undefined;
  if (circular) {
    size = width || height;
  }

  const containerStyle: React.CSSProperties = {
    width: circular ? `${size}px` : width ? `${width}px` : '100%',
    height: circular ? `${size}px` : height ? `${height}px` : 'auto',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: circular ? '50%' : undefined,
  };

  const imageStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: circular ? 'cover' : 'contain',
  };

  return (
    <>
      <Box
        style={containerStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {imageUrl ? (
          <Image src={imageUrl} alt="Editable Image" style={imageStyle} />
        ) : (
          <Box
            style={{
              ...imageStyle,
              backgroundColor: '#f0f0f0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            No Image
          </Box>
        )}
        {editable && isHovered && (
          <Box
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              transition: 'opacity 0.3s ease',
              opacity: isHovered ? 1 : 0,
            }}
          >
            <Button
              variant="subtle"
              color="white"
              radius="md"
              onClick={openModal}
            >
              {imageUrl ? editButtonLabel : 'Upload Image'}
            </Button>
          </Box>
        )}
      </Box>
      {editable && (
        <EditableImageModal
          opened={isModalOpen}
          onClose={closeModal}
          onImageUpload={handleImageUpload}
          existingImageUrl={imageUrl}
          storageUsage={storageUsage}
          title={modalTitle}
          aspectRatio={aspectRatio}
        />
      )}
    </>
  );
};

export default EditableImage;
