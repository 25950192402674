import { ICellRendererParams } from '@ag-grid-community/core';
import { TextInput } from '@mantine/core';
import { useState } from 'react';

const EditableCellRenderer: React.FC<ICellRendererParams> = (params) => {
  const [value, setValue] = useState(params.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (params.api && params.node && params.column) {
      const rowNode = params.api.getRowNode(params.node.id as string);
      const colId = params.column.getColId();
      if (rowNode && colId) {
        rowNode.setDataValue(colId, event.target.value);
      }
    }
  };

  return <TextInput value={value} onChange={handleChange} ta="right" />;
};

export default EditableCellRenderer;
