import { RegistrationDTO } from '@btrway/api-core';
import { atom } from 'jotai';

export const registrationAtom = atom<RegistrationDTO>({
  persons: [],
  registrantPersonKey: '',
  relationships: [],
});

export const workgroupIdAtom = atom<number | null>(null);

export const registrationYearAtom = atom<number | null>(null);
