import {
  StepConfig,
  StepTypeEnum,
  TaskConfig,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { useCallback } from 'react';

interface ScreenOptions {
  mode: 'panel';
  position: 'left';
  width?: number;
}

interface CourseSelectorScreen {
  type: 'COURSE_SELECTOR';
  data: {
    taskType: TaskTypeEnum;
  };
}

interface WorkflowSelectorScreen {
  type: 'WORKFLOW_SELECTOR';
  data: {
    taskType: TaskTypeEnum;
    workflowType: WorkflowTypeEnum;
    workflows: CommonWorkflow[];
    selectedWorkflowKey?: string;
  };
}

type Screen = CourseSelectorScreen | WorkflowSelectorScreen;

interface UseTaskHandlersProps {
  reset: () => void;
  openScreen: (screen: Screen, options: ScreenOptions) => void;
  openTaskConfigScreen: (
    config: TaskConfig,
    isNew: boolean,
    width?: number
  ) => void;
  openStepConfigScreen: (
    config: StepConfig,
    isNew: boolean,
    width?: number
  ) => void;
  createTaskConfig: (taskType: TaskTypeEnum) => TaskConfig;
  createStepConfig: (
    type: 'step' | 'task',
    code: StepTypeEnum | TaskTypeEnum
  ) => StepConfig;
  workflows: CommonWorkflow[];
  getWorkflowTypeForTask: (
    taskType: TaskTypeEnum
  ) => WorkflowTypeEnum | undefined;
  parentWorkflow?: CommonWorkflow;
}

export const useTaskHandlers = ({
  reset,
  openScreen,
  openTaskConfigScreen,
  openStepConfigScreen,
  createTaskConfig,
  createStepConfig,
  workflows,
  getWorkflowTypeForTask,
  parentWorkflow,
}: UseTaskHandlersProps) => {
  const handleTaskTypeSelect = useCallback(
    (taskType: TaskTypeEnum) => {
      reset();

      // Check if we're in a form or automation workflow
      const isFormOrAutomation =
        parentWorkflow?.workflowType === WorkflowTypeEnum.form ||
        parentWorkflow?.workflowType === WorkflowTypeEnum.automation;

      if (isFormOrAutomation) {
        // Create a StepConfig with embedded TaskConfig
        const stepConfig = createStepConfig('task', taskType);
        openStepConfigScreen(stepConfig, true, 400);
        return;
      }

      // Regular task handling flow
      switch (taskType) {
        case TaskTypeEnum.thirdParty:
        case TaskTypeEnum.sendEmail: {
          const config = createTaskConfig(taskType);
          config.taskKey = uuid();
          openTaskConfigScreen(config, true, 400);
          break;
        }

        case TaskTypeEnum.completeCourse:
          openScreen(
            {
              type: 'COURSE_SELECTOR',
              data: { taskType },
            },
            {
              mode: 'panel',
              position: 'left',
            }
          );
          break;

        default: {
          const workflowType = getWorkflowTypeForTask(taskType);
          if (workflowType) {
            openScreen(
              {
                type: 'WORKFLOW_SELECTOR',
                data: {
                  taskType,
                  workflowType,
                  workflows: workflows || [],
                  selectedWorkflowKey: undefined,
                },
              },
              {
                mode: 'panel',
                position: 'left',
              }
            );
          } else {
            // Handle cases where there's no workflow type
            const config = createTaskConfig(taskType);
            config.taskKey = uuid();
            openTaskConfigScreen(config, true, 400);
          }
          break;
        }
      }
    },
    [
      reset,
      openTaskConfigScreen,
      openStepConfigScreen,
      openScreen,
      createTaskConfig,
      createStepConfig,
      workflows,
      getWorkflowTypeForTask,
      parentWorkflow,
    ]
  );

  return {
    handleTaskTypeSelect,
  };
};
