import { ChapterDTO } from '@btrway/api-courseware';
import {
  ChapterStatus,
  useChapterProgress,
} from '@btrway/courseware-progress-manager';
import { getFormattedDuration } from '@btrway/utils';
import { Stack, Text, ThemeIcon, Timeline } from '@mantine/core';
import { IconCheck, IconPlayerPlayFilled } from '@tabler/icons-react';
import React from 'react';

interface ChapterListItemProps {
  chapter: ChapterDTO;
  isActive: boolean;
  onClick: () => void;
}

const highlightColor = (chapterStatus: ChapterStatus) => {
  switch (chapterStatus) {
    case ChapterStatus.ACTIVE:
      return 'orange';
    case ChapterStatus.COMPLETE:
      return 'cyan';
    case ChapterStatus.INCOMPLETE:
    default:
      return 'lightgrey';
  }
};

const bulletIcon = (chapterStatus: ChapterStatus) => {
  switch (chapterStatus) {
    case ChapterStatus.ACTIVE:
      return <IconPlayerPlayFilled size={16} />;
    case ChapterStatus.COMPLETE:
      return <IconCheck size={16} />;
    case ChapterStatus.INCOMPLETE:
    default:
      return null;
  }
};

const ChapterListItem: React.FC<ChapterListItemProps> = ({
  chapter,
  isActive,
  onClick,
}) => {
  const { chapterProgress, updateChapterProgress } = useChapterProgress(
    chapter.key
  );

  if (!chapter) {
    return null;
  }

  const chapterStatus = isActive
    ? ChapterStatus.ACTIVE
    : chapterProgress?.completed
    ? ChapterStatus.COMPLETE
    : ChapterStatus.INCOMPLETE;

  return (
    <Timeline.Item
      color="grey"
      bullet={
        <ThemeIcon
          size={24}
          variant="gradient"
          gradient={{
            from: highlightColor(chapterStatus),
            to: highlightColor(chapterStatus),
          }}
          radius="xl"
        >
          {bulletIcon(chapterStatus)}
        </ThemeIcon>
      }
    >
      <Text
        size="md"
        fz="md"
        fw={600}
        style={{ cursor: 'pointer' }}
        c={highlightColor(chapterStatus)}
        onClick={onClick}
      >
        {chapter.title}
      </Text>
      <Stack gap={0}>
        <Text size="xs" mt={4} c="lightgrey">
          {getFormattedDuration(chapter.duration || 0)}
        </Text>
      </Stack>
    </Timeline.Item>
  );
};

export default ChapterListItem;
