import { Button } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import React from 'react';

interface QuizAnswerProps {
  answer: string;
  isSelected: boolean;
  isCorrect: boolean;
  onClick: () => void;
  showResult: boolean;
  allAnswersCorrect: boolean;
}

const QuizAnswer: React.FC<QuizAnswerProps> = ({
  answer,
  isSelected,
  isCorrect,
  onClick,
  showResult,
  allAnswersCorrect,
}) => {
  return (
    <Button
      onClick={onClick}
      variant={isSelected ? 'filled' : 'outline'}
      styles={(theme) => ({
        root: {
          width: '100%',
          height: '60px',
          margin: '10px 0',
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor:
            showResult && isSelected
              ? allAnswersCorrect
                ? theme.colors.blue[1]
                : isCorrect
                ? theme.colors.green[1]
                : theme.colors.red[1]
              : theme.white,
          border: `2px solid ${
            showResult && isSelected
              ? allAnswersCorrect
                ? theme.colors.blue[6]
                : isCorrect
                ? theme.colors.green[6]
                : theme.colors.red[6]
              : isSelected
              ? theme.colors.blue[6]
              : theme.colors.gray[4]
          }`,
          '&:hover': {
            backgroundColor:
              showResult && isSelected
                ? allAnswersCorrect
                  ? theme.colors.blue[1]
                  : isCorrect
                  ? theme.colors.green[1]
                  : theme.colors.red[1]
                : theme.colors.gray[1],
          },
        },
        inner: {
          justifyContent: 'space-between',
          width: '100%',
        },
        label: {
          fontSize: '18px',
          fontWeight: 500,
          color: theme.black,
          flexGrow: 1,
          textAlign: 'center',
        },
      })}
      rightSection={
        showResult && isSelected ? (
          allAnswersCorrect ? (
            <IconCheck size={32} color="blue" />
          ) : isCorrect ? (
            <IconCheck size={32} color="green" />
          ) : (
            <IconX size={32} color="red" />
          )
        ) : undefined
      }
    >
      {answer}
    </Button>
  );
};

export default QuizAnswer;
