import { SearchUserResponse } from '@btrway/api-core';
import { formatPhoneNumber } from '@btrway/utils';
import { Card, Text } from '@mantine/core';
import React from 'react';
import UserAccessButton from '../UserAccessButton/UserAccessButton';

interface GlobalUserDetailCardProps {
  selectedUser: SearchUserResponse;
}

const GlobalUserDetailCard: React.FC<GlobalUserDetailCardProps> = ({
  selectedUser,
}) => {
  if (!selectedUser) {
    return null;
  }

  return (
    <Card padding="lg" withBorder w="100%" h="400px">
      <Text fz="lg" fw={500} mb="md">
        User Details
      </Text>
      <Text>Name: {`${selectedUser.lastName}, ${selectedUser.firstName}`}</Text>
      <Text>Email: {selectedUser.userEmail}</Text>
      <Text>Phone: {formatPhoneNumber(selectedUser.mobilePhone)}</Text>
      <Text>Organization: {selectedUser.organizationName}</Text>
      <UserAccessButton
        personId={selectedUser.personId}
        organizationId={selectedUser.organizationId}
        email={selectedUser.email}
        size={16}
        style={{ position: 'absolute', top: 5, right: 5 }}
      />
    </Card>
  );
};

export default GlobalUserDetailCard;
