import { useTaskManager } from '@btrway/task-manager';
import { ActionIcon, Badge, Card, Group, Stack, Text } from '@mantine/core';
import { IconColumns, IconLayoutList } from '@tabler/icons-react';
import React, { useState } from 'react';
import ApprovalsGrid from '../ApprovalsGrid/ApprovalsGrid';
import ApprovalsView from '../ApprovalsView/ApprovalsView';

interface ApprovalsWidgetProps {
  personId?: number;
}

const ApprovalsWidget: React.FC<ApprovalsWidgetProps> = ({ personId }) => {
  const [viewMode, setViewMode] = useState<'list' | 'grid'>('list');
  const { assignedTasks } = useTaskManager();

  return (
    <Card
      p="md"
      radius="lg"
      mih={800}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Group justify="space-between" mb="sm">
        <Group gap="xs">
          <Text fz="lg" fw={600}>
            Approvals
          </Text>
          <Badge size="lg" variant="filled">
            {assignedTasks.length}
          </Badge>
        </Group>
        <Group>
          <ActionIcon
            variant={viewMode === 'list' ? 'filled' : 'subtle'}
            onClick={() => setViewMode('list')}
            aria-label="List view"
          >
            <IconLayoutList size={18} />
          </ActionIcon>
          <ActionIcon
            variant={viewMode === 'grid' ? 'filled' : 'subtle'}
            onClick={() => setViewMode('grid')}
            aria-label="Grid view"
          >
            <IconColumns size={18} />
          </ActionIcon>
        </Group>
      </Group>

      <Stack style={{ flex: 1, minHeight: 0 }}>
        {viewMode === 'list' ? (
          <ApprovalsView personId={personId} />
        ) : (
          <div
            style={{
              flex: 1,
              minHeight: 0,
              position: 'relative',
              height: 900,
            }}
          >
            <ApprovalsGrid />
          </div>
        )}
      </Stack>
    </Card>
  );
};

export default ApprovalsWidget;
