import {
  DataTypeEnum,
  TaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { StyledModal } from '@btrway/styled-modal';
import { Box, Text } from '@mantine/core';
import React from 'react';
import { TaskActionProvider } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';
import { getTaskComponent } from '../../utils/getTaskComponent';

const HIDE_MODAL_HEADER_TASK_TYPES = new Set<TaskTypeEnum>([
  TaskTypeEnum.completeCourse,
]);

interface TaskCompleterProps {
  taskProperties: TaskProperties | undefined;
  taskInstanceId?: number;
  assignedEntityType?: DataTypeEnum;
  assignedEntityId?: number;
  workgroupId?: number;
  derivedWorkflowKey?: string;
  taskKey?: string;
  taskType?: TaskTypeEnum;
  isOpen: boolean;
  onClose: () => void;
}

const TaskContent: React.FC<{
  taskProperties: TaskProperties;
  taskType?: TaskTypeEnum;
  taskInstanceId?: number;
  assignedEntityType?: DataTypeEnum;
  assignedEntityId?: number;
  workgroupId?: number;
  derivedWorkflowKey?: string;
  taskKey?: string;
  onClose: () => void;
}> = ({
  taskProperties,
  taskType,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  workgroupId,
  derivedWorkflowKey,
  taskKey,
  onClose,
}) => {
  if (!taskType) {
    return <Text>Task type is not provided</Text>;
  }

  const TaskComponent = getTaskComponent(taskType);

  if (!TaskComponent) {
    return <Text>Unsupported task type: {taskType}</Text>;
  }

  const taskProps: TaskComponentProps = {
    taskProperties,
    taskInstanceId,
    assignedEntityType,
    assignedEntityId,
    workgroupId,
    derivedWorkflowKey,
    taskKey,
    taskType,
    onClose,
  };

  return (
    <React.Suspense fallback={<Text>Loading task...</Text>}>
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TaskComponent {...taskProps} />
      </Box>
    </React.Suspense>
  );
};

export const TaskCompleter: React.FC<TaskCompleterProps> = ({
  taskProperties,
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  workgroupId,
  derivedWorkflowKey,
  taskKey,
  taskType,
  isOpen,
  onClose,
}) => {
  const shouldHideHeader =
    taskType && HIDE_MODAL_HEADER_TASK_TYPES.has(taskType);

  console.log('TaskCompleter, workgroupId', workgroupId);
  return (
    <StyledModal
      opened={isOpen}
      onClose={onClose}
      fullScreen
      withCloseButton={shouldHideHeader ? false : undefined}
      variant="xl"
    >
      {taskProperties ? (
        <TaskActionProvider
          taskProperties={taskProperties}
          taskInstanceId={taskInstanceId}
          assignedEntityType={assignedEntityType}
          assignedEntityId={assignedEntityId}
          workgroupId={workgroupId}
          derivedWorkflowKey={derivedWorkflowKey}
          taskKey={taskKey}
          taskType={taskType}
          onSuccess={onClose}
        >
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <TaskContent
              taskProperties={taskProperties}
              taskType={taskType}
              taskInstanceId={taskInstanceId}
              assignedEntityType={assignedEntityType}
              assignedEntityId={assignedEntityId}
              workgroupId={workgroupId}
              derivedWorkflowKey={derivedWorkflowKey}
              taskKey={taskKey}
              onClose={onClose}
            />
          </Box>
        </TaskActionProvider>
      ) : (
        <Box
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Text>No task configuration provided</Text>
        </Box>
      )}
    </StyledModal>
  );
};
