import { useAuthenticatedUser } from '@btrway/current-user';
import { useTaskFetcher } from '@btrway/task-manager';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React, { useEffect } from 'react';
import ApprovalsWidget from '../../components/ApprovalsWidget/ApprovalsWidget';
import CalendarWidget from '../../components/CalendarWidget/CalendarWidget';
import { DashboardCards } from '../../components/DashboardCards/DashboardCards';
import styles from './HomePage.module.css';

const HomePage: React.FC = () => {
  const { currentPerson } = useAuthenticatedUser();

  const { fetchTasksWithCriteria } = useTaskFetcher();

  useEffect(() => {
    if (currentPerson.id) {
      fetchTasksWithCriteria([currentPerson.id], {
        includeRoleBasedTasks: true,
        workflowType: undefined, // Fetch all workflow types
      });
    }
  }, [currentPerson.id, fetchTasksWithCriteria]);

  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        <DashboardCards />
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <ApprovalsWidget />
            </Stack>
          </Stack>
        </Box>
        <Box visibleFrom="md" style={{ flexShrink: 0 }}>
          <CalendarWidget />
        </Box>
      </Flex>
    </Stack>
  );
};

export default HomePage;
