import { TimeEvent } from './DailySummary';

export const defaultDailyEvents: TimeEvent[] = [
  {
    time: '9:00 AM',
    description: 'Weekly staff meeting with School Athletic Directors',
  },
  {
    time: '10:00 AM',
    description: 'Monthly review with Superintendent',
  },
  {
    time: '1:00 PM',
    description: 'Next year budget planning',
  },
  {
    time: '3:00 PM',
    description: 'Equipment Meeting',
  },
  {
    time: '7:00 PM',
    description: 'Midlothian HS Football vs James River',
  },
  {
    time: '8:00 PM',
    description: 'Fundraising banquet',
  },
];

export const defaultGames: TimeEvent[] = [
  {
    time: '3:00 PM',
    description: "Midlothian HS Boy's Soccer @ Clover Hill",
  },
  {
    time: '3:30 PM',
    description: "Clover Hill Girl's Volleyball @ James River",
  },
  {
    time: '4:00 PM',
    description: "Cosby HS Girl's Field Hockey vs Thomas Dale",
  },
  {
    time: '4:30 PM',
    description: "Manchester HS Boy's Tennis @ Lloyd C. Bird",
  },
  {
    time: '5:00 PM',
    description: "James River Boy's Soccer @ Huguenot",
  },
  {
    time: '5:30 PM',
    description: "Meadowbrook HS Girl's Basketball vs Matoaca",
  },
  {
    time: '6:00 PM',
    description: "Thomas Dale Boy's Lacrosse @ Cosby HS",
  },
  {
    time: '6:30 PM',
    description: "Lloyd C. Bird Girl's Softball vs Manchester HS",
  },
  {
    time: '7:00 PM',
    description: 'Midlothian HS Football vs James River',
  },
];
