import { Badge, Button } from '@mantine/core';
import React from 'react';

interface TaskCardResourcesButtonProps {
  resourceCount: number;
  onClick: (e: React.MouseEvent) => void;
}

export const TaskCardResourcesButton: React.FC<
  TaskCardResourcesButtonProps
> = ({ resourceCount, onClick }) => (
  <Button
    variant="light"
    color={resourceCount > 0 ? 'blue' : 'gray'}
    size="sm"
    // rightSection={
    //   <Badge
    //     size="sm"
    //     variant="filled"
    //     color={resourceCount > 0 ? 'blue' : 'gray'}
    //   >
    //     {resourceCount}
    //   </Badge>
    // }
    onClick={onClick}
    style={{ flexShrink: 0 }}
  >
    <Badge
      size="sm"
      variant="filled"
      color={resourceCount > 0 ? 'blue' : 'gray'}
      style={{ cursor: 'pointer' }}
    >
      {resourceCount}
    </Badge>
  </Button>
);
