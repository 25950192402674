import { StorageUploadRequest, StorageUsageEnum } from '@btrway/api-storage';
import { StepConfigFormConfig } from '@btrway/api-workflow';
import { useAuthContext } from '@btrway/auth-core';
import { useStorageUpload } from '@btrway/file-storage-components';
import { setupCustomComponents } from '@btrway/form-controls';
import { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { Model, settings, SurveyModel } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useAuthenticatedUser } from '@btrway/current-user';
import { registerDescriptionEditor } from '@btrway/form-builder';

interface SurveyJsWrapperProps {
  formConfig: StepConfigFormConfig;
  prefilledData?: any;
  onSurveyComplete?: (sender: Model) => void;
  hideCompleteButton?: boolean;
}

export interface SurveyJsWrapperHandle {
  completeSurvey: () => void;
  getSurveyModel: () => SurveyModel | null;
}

registerDescriptionEditor();

const SurveyJsWrapper = forwardRef<SurveyJsWrapperHandle, SurveyJsWrapperProps>(
  (
    { formConfig, prefilledData, onSurveyComplete, hideCompleteButton = false },
    ref
  ) => {
    const { authState } = useAuthContext();
    const { storageUpload } = useStorageUpload();
    const { currentUser, currentOrganization } = useAuthenticatedUser();
    const apiUrl = authState.apiUrl;

    setupCustomComponents(apiUrl, currentUser);

    const handleUploadFiles = useCallback(
      async (
        survey: SurveyModel,
        options: {
          files: File[];
          callback: (status: string, data?: any) => void;
        }
      ) => {
        const fileNames: string[] = [];
        const fileUploadData: any[] = [];

        options.files.forEach((file: File) => {
          fileNames.push(file.name);
          fileUploadData.push({
            file: file,
            contentType: file.type || 'application/octet-stream',
            fileName: file.name,
          });
        });

        if (currentUser?.user && currentOrganization) {
          const storageUploadRequest: StorageUploadRequest = {
            organizationId: currentOrganization.id,
            userId: currentUser.user.id,
            fileNames: fileNames,
            storageUsageEnum: StorageUsageEnum.form,
          };

          try {
            const res = await storageUpload(
              storageUploadRequest,
              fileUploadData
            );
            if (
              res.storageKeys &&
              res.storageKeys.length === options.files.length
            ) {
              console.log('Files successfully uploaded to S3');
              options.callback('success', res.storageKeys);
            } else {
              console.error('Error uploading files to S3');
              options.callback('error');
            }
          } catch (error) {
            console.error('Error uploading files: ', error);
            options.callback('error', error);
          }
        }
      },
      [currentUser, currentOrganization, storageUpload]
    );

    const surveyModel = useMemo(() => {
      const model = new Model(formConfig);

      if (hideCompleteButton) {
        model.showPageTitles = false;
        model.showCompletedPage = false;
        model.showNavigationButtons = false;
      }

      model.onAfterRenderQuestion.add((survey, options) => {
        const question = options.question;

        if (question.getType() === 'file') {
          question.storeDataAsText = false;
        }

        // if (options.question.name === 'teamSelect') {
        //   loadTeamOptions(options.question);
        // } else if (options.question.name === 'personSelect') {
        //   loadPersonOptions(options.question);
        // }

        // if (question.getType() === 'persondropdown') {
        //   loadPersonOptions(question).catch((err) =>
        //     console.error('Error loading person options:', err)
        //   );
        // }
      });

      settings.web.onBeforeRequestChoices = function (sender, options) {
        options.request.withCredentials = true;
      };

      model.onUploadFiles.add(handleUploadFiles);

      if (prefilledData) {
        model.data = prefilledData;
      }

      return model;
    }, [formConfig, prefilledData, handleUploadFiles, hideCompleteButton]);

    useImperativeHandle(ref, () => ({
      completeSurvey: () => surveyModel.doComplete(),
      getSurveyModel: () => surveyModel,
    }));

    return <Survey model={surveyModel} onComplete={onSurveyComplete} />;
  }
);

export default SurveyJsWrapper;
