/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetHelpResourcesByTaskParams,
  HelpResourceQueryRequest,
  HelpResourceRequest,
  HelpResourceResponse,
  HelpResourceUsageRequest,
  HelpResourceUsageResponse,
  HelpTagRequest,
  HelpTagResponse,
  TaskUsageConditions
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getHelpTags = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpTagResponse[]>(
      {url: `/api/help/help-tags`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHelpTagsQueryKey = () => {
    return [`/api/help/help-tags`] as const;
    }

    
export const getGetHelpTagsQueryOptions = <TData = Awaited<ReturnType<typeof getHelpTags>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpTags>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHelpTagsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHelpTags>>> = ({ signal }) => getHelpTags(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHelpTags>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHelpTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getHelpTags>>>
export type GetHelpTagsQueryError = unknown



export function useGetHelpTags<TData = Awaited<ReturnType<typeof getHelpTags>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpTags>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHelpTagsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveHelpTag = (
    helpTagRequest: HelpTagRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/help/help-tags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: helpTagRequest
    },
      options);
    }
  


export const getSaveHelpTagMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpTag>>, TError,{data: HelpTagRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveHelpTag>>, TError,{data: HelpTagRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveHelpTag>>, {data: HelpTagRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveHelpTag(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveHelpTagMutationResult = NonNullable<Awaited<ReturnType<typeof saveHelpTag>>>
    export type SaveHelpTagMutationBody = HelpTagRequest
    export type SaveHelpTagMutationError = unknown

    export const useSaveHelpTag = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpTag>>, TError,{data: HelpTagRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveHelpTag>>,
        TError,
        {data: HelpTagRequest},
        TContext
      > => {

      const mutationOptions = getSaveHelpTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveHelpResource = (
    helpResourceRequest: HelpResourceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/help/help-resources`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: helpResourceRequest
    },
      options);
    }
  


export const getSaveHelpResourceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpResource>>, TError,{data: HelpResourceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveHelpResource>>, TError,{data: HelpResourceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveHelpResource>>, {data: HelpResourceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveHelpResource(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveHelpResourceMutationResult = NonNullable<Awaited<ReturnType<typeof saveHelpResource>>>
    export type SaveHelpResourceMutationBody = HelpResourceRequest
    export type SaveHelpResourceMutationError = unknown

    export const useSaveHelpResource = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpResource>>, TError,{data: HelpResourceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveHelpResource>>,
        TError,
        {data: HelpResourceRequest},
        TContext
      > => {

      const mutationOptions = getSaveHelpResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getHelpResourcesByWorkflow = (
    workflowKey: string,
    getHelpResourcesByWorkflowBody: string[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/help/help-resources/workflow/${workflowKey}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getHelpResourcesByWorkflowBody
    },
      options);
    }
  


export const getGetHelpResourcesByWorkflowMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>, {workflowKey: string;data: string[]}> = (props) => {
          const {workflowKey,data} = props ?? {};

          return  getHelpResourcesByWorkflow(workflowKey,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetHelpResourcesByWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>>
    export type GetHelpResourcesByWorkflowMutationBody = string[]
    export type GetHelpResourcesByWorkflowMutationError = unknown

    export const useGetHelpResourcesByWorkflow = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getHelpResourcesByWorkflow>>,
        TError,
        {workflowKey: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getGetHelpResourcesByWorkflowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getHelpResourcesByTask = (
    params: GetHelpResourcesByTaskParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpResourceResponse[]>(
      {url: `/api/help/help-resources/task`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetHelpResourcesByTaskQueryKey = (params: GetHelpResourcesByTaskParams,) => {
    return [`/api/help/help-resources/task`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHelpResourcesByTaskQueryOptions = <TData = Awaited<ReturnType<typeof getHelpResourcesByTask>>, TError = unknown>(params: GetHelpResourcesByTaskParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByTask>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHelpResourcesByTaskQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHelpResourcesByTask>>> = ({ signal }) => getHelpResourcesByTask(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByTask>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHelpResourcesByTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getHelpResourcesByTask>>>
export type GetHelpResourcesByTaskQueryError = unknown



export function useGetHelpResourcesByTask<TData = Awaited<ReturnType<typeof getHelpResourcesByTask>>, TError = unknown>(
 params: GetHelpResourcesByTaskParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByTask>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHelpResourcesByTaskQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getHelpResourcesByTaskUsageConditions = (
    taskUsageConditions: TaskUsageConditions,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<HelpResourceResponse[]>(
      {url: `/api/help/help-resources/task`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskUsageConditions
    },
      options);
    }
  


export const getGetHelpResourcesByTaskUsageConditionsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>, TError,{data: TaskUsageConditions}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>, TError,{data: TaskUsageConditions}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>, {data: TaskUsageConditions}> = (props) => {
          const {data} = props ?? {};

          return  getHelpResourcesByTaskUsageConditions(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetHelpResourcesByTaskUsageConditionsMutationResult = NonNullable<Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>>
    export type GetHelpResourcesByTaskUsageConditionsMutationBody = TaskUsageConditions
    export type GetHelpResourcesByTaskUsageConditionsMutationError = unknown

    export const useGetHelpResourcesByTaskUsageConditions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>, TError,{data: TaskUsageConditions}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getHelpResourcesByTaskUsageConditions>>,
        TError,
        {data: TaskUsageConditions},
        TContext
      > => {

      const mutationOptions = getGetHelpResourcesByTaskUsageConditionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const queryHelpResources = (
    helpResourceQueryRequest: HelpResourceQueryRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<HelpResourceResponse[]>(
      {url: `/api/help/help-resources/query`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: helpResourceQueryRequest
    },
      options);
    }
  


export const getQueryHelpResourcesMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryHelpResources>>, TError,{data: HelpResourceQueryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof queryHelpResources>>, TError,{data: HelpResourceQueryRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof queryHelpResources>>, {data: HelpResourceQueryRequest}> = (props) => {
          const {data} = props ?? {};

          return  queryHelpResources(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type QueryHelpResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof queryHelpResources>>>
    export type QueryHelpResourcesMutationBody = HelpResourceQueryRequest
    export type QueryHelpResourcesMutationError = unknown

    export const useQueryHelpResources = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof queryHelpResources>>, TError,{data: HelpResourceQueryRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof queryHelpResources>>,
        TError,
        {data: HelpResourceQueryRequest},
        TContext
      > => {

      const mutationOptions = getQueryHelpResourcesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveHelpResourceUsage = (
    helpResourceUsageRequest: HelpResourceUsageRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/help/help-resource-usages`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: helpResourceUsageRequest
    },
      options);
    }
  


export const getSaveHelpResourceUsageMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpResourceUsage>>, TError,{data: HelpResourceUsageRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveHelpResourceUsage>>, TError,{data: HelpResourceUsageRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveHelpResourceUsage>>, {data: HelpResourceUsageRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveHelpResourceUsage(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveHelpResourceUsageMutationResult = NonNullable<Awaited<ReturnType<typeof saveHelpResourceUsage>>>
    export type SaveHelpResourceUsageMutationBody = HelpResourceUsageRequest
    export type SaveHelpResourceUsageMutationError = unknown

    export const useSaveHelpResourceUsage = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveHelpResourceUsage>>, TError,{data: HelpResourceUsageRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveHelpResourceUsage>>,
        TError,
        {data: HelpResourceUsageRequest},
        TContext
      > => {

      const mutationOptions = getSaveHelpResourceUsageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getHelpResourceUsagesByWorkflow = (
    workflowKey: string,
    getHelpResourceUsagesByWorkflowBody: string[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/help/help-resource-usages/workflow/${workflowKey}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getHelpResourceUsagesByWorkflowBody
    },
      options);
    }
  


export const getGetHelpResourceUsagesByWorkflowMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>, {workflowKey: string;data: string[]}> = (props) => {
          const {workflowKey,data} = props ?? {};

          return  getHelpResourceUsagesByWorkflow(workflowKey,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetHelpResourceUsagesByWorkflowMutationResult = NonNullable<Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>>
    export type GetHelpResourceUsagesByWorkflowMutationBody = string[]
    export type GetHelpResourceUsagesByWorkflowMutationError = unknown

    export const useGetHelpResourceUsagesByWorkflow = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>, TError,{workflowKey: string;data: string[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getHelpResourceUsagesByWorkflow>>,
        TError,
        {workflowKey: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getGetHelpResourceUsagesByWorkflowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getHelpResourceById = (
    helpResourceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpResourceResponse>(
      {url: `/api/help/help-resources/${helpResourceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHelpResourceByIdQueryKey = (helpResourceId: number,) => {
    return [`/api/help/help-resources/${helpResourceId}`] as const;
    }

    
export const getGetHelpResourceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getHelpResourceById>>, TError = unknown>(helpResourceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHelpResourceByIdQueryKey(helpResourceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHelpResourceById>>> = ({ signal }) => getHelpResourceById(helpResourceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(helpResourceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHelpResourceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getHelpResourceById>>>
export type GetHelpResourceByIdQueryError = unknown



export function useGetHelpResourceById<TData = Awaited<ReturnType<typeof getHelpResourceById>>, TError = unknown>(
 helpResourceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHelpResourceByIdQueryOptions(helpResourceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteHelpResource = (
    helpResourceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/help/help-resources/${helpResourceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteHelpResourceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResource>>, TError,{helpResourceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResource>>, TError,{helpResourceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHelpResource>>, {helpResourceId: number}> = (props) => {
          const {helpResourceId} = props ?? {};

          return  deleteHelpResource(helpResourceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteHelpResourceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHelpResource>>>
    
    export type DeleteHelpResourceMutationError = unknown

    export const useDeleteHelpResource = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResource>>, TError,{helpResourceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteHelpResource>>,
        TError,
        {helpResourceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteHelpResourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getHelpResourcesByRepository = (
    repository: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpResourceResponse[]>(
      {url: `/api/help/help-resources/repository/${repository}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHelpResourcesByRepositoryQueryKey = (repository: string,) => {
    return [`/api/help/help-resources/repository/${repository}`] as const;
    }

    
export const getGetHelpResourcesByRepositoryQueryOptions = <TData = Awaited<ReturnType<typeof getHelpResourcesByRepository>>, TError = unknown>(repository: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByRepository>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHelpResourcesByRepositoryQueryKey(repository);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHelpResourcesByRepository>>> = ({ signal }) => getHelpResourcesByRepository(repository, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(repository), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByRepository>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHelpResourcesByRepositoryQueryResult = NonNullable<Awaited<ReturnType<typeof getHelpResourcesByRepository>>>
export type GetHelpResourcesByRepositoryQueryError = unknown



export function useGetHelpResourcesByRepository<TData = Awaited<ReturnType<typeof getHelpResourcesByRepository>>, TError = unknown>(
 repository: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourcesByRepository>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHelpResourcesByRepositoryQueryOptions(repository,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAllActiveHelpResources = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpResourceResponse[]>(
      {url: `/api/help/help-resources/active`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllActiveHelpResourcesQueryKey = () => {
    return [`/api/help/help-resources/active`] as const;
    }

    
export const getGetAllActiveHelpResourcesQueryOptions = <TData = Awaited<ReturnType<typeof getAllActiveHelpResources>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllActiveHelpResources>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllActiveHelpResourcesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllActiveHelpResources>>> = ({ signal }) => getAllActiveHelpResources(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllActiveHelpResources>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllActiveHelpResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllActiveHelpResources>>>
export type GetAllActiveHelpResourcesQueryError = unknown



export function useGetAllActiveHelpResources<TData = Awaited<ReturnType<typeof getAllActiveHelpResources>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllActiveHelpResources>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllActiveHelpResourcesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getHelpResourceUsages = (
    helpResourceKey: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HelpResourceUsageResponse[]>(
      {url: `/api/help/help-resource-usages/usages/${helpResourceKey}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetHelpResourceUsagesQueryKey = (helpResourceKey: string,) => {
    return [`/api/help/help-resource-usages/usages/${helpResourceKey}`] as const;
    }

    
export const getGetHelpResourceUsagesQueryOptions = <TData = Awaited<ReturnType<typeof getHelpResourceUsages>>, TError = unknown>(helpResourceKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceUsages>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHelpResourceUsagesQueryKey(helpResourceKey);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHelpResourceUsages>>> = ({ signal }) => getHelpResourceUsages(helpResourceKey, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(helpResourceKey), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceUsages>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHelpResourceUsagesQueryResult = NonNullable<Awaited<ReturnType<typeof getHelpResourceUsages>>>
export type GetHelpResourceUsagesQueryError = unknown



export function useGetHelpResourceUsages<TData = Awaited<ReturnType<typeof getHelpResourceUsages>>, TError = unknown>(
 helpResourceKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getHelpResourceUsages>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHelpResourceUsagesQueryOptions(helpResourceKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteHelpTag = (
    helpTagId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/help/help-tags/${helpTagId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteHelpTagMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpTag>>, TError,{helpTagId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteHelpTag>>, TError,{helpTagId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHelpTag>>, {helpTagId: number}> = (props) => {
          const {helpTagId} = props ?? {};

          return  deleteHelpTag(helpTagId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteHelpTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHelpTag>>>
    
    export type DeleteHelpTagMutationError = unknown

    export const useDeleteHelpTag = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpTag>>, TError,{helpTagId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteHelpTag>>,
        TError,
        {helpTagId: number},
        TContext
      > => {

      const mutationOptions = getDeleteHelpTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteHelpResourceUsage = (
    helpResourceUsageId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/help/help-resource-usages/${helpResourceUsageId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteHelpResourceUsageMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResourceUsage>>, TError,{helpResourceUsageId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResourceUsage>>, TError,{helpResourceUsageId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteHelpResourceUsage>>, {helpResourceUsageId: number}> = (props) => {
          const {helpResourceUsageId} = props ?? {};

          return  deleteHelpResourceUsage(helpResourceUsageId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteHelpResourceUsageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteHelpResourceUsage>>>
    
    export type DeleteHelpResourceUsageMutationError = unknown

    export const useDeleteHelpResourceUsage = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteHelpResourceUsage>>, TError,{helpResourceUsageId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteHelpResourceUsage>>,
        TError,
        {helpResourceUsageId: number},
        TContext
      > => {

      const mutationOptions = getDeleteHelpResourceUsageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
