import {
  OutsideTaskDefinitionRequest,
  useDeleteOutsideTaskDefinition,
  useGetOutsideTaskDefinitionsByOrganization,
  useSaveOutsideTaskDefinition,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  ScrollableDrawer,
  useScrollableDrawer,
} from '@btrway/scrollable-drawer';
import { uuid } from '@btrway/utils';
import { Button } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { OutsideTaskEdit } from '../OutsideTaskEdit/OutsideTaskEdit';
import { OutsideTasksAdminView } from '../OutsideTasksAdminView/OutsideTasksAdminView';

interface OutsideTasksDrawerProps {
  opened: boolean;
  onClose: () => void;
  onTaskChange?: () => void;
}

export function OutsideTasksDrawer({
  opened,
  onClose,
  onTaskChange,
}: OutsideTasksDrawerProps) {
  const [editingTask, setEditingTask] =
    useState<OutsideTaskDefinitionRequest | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentOrganization } = useAuthenticatedUser();
  const { setDrawerHeader } = useScrollableDrawer();
  const { mutateAsync: saveOutsideTask } = useSaveOutsideTaskDefinition();
  const { mutateAsync: deleteOutsideTask } = useDeleteOutsideTaskDefinition();

  // Fetch tasks internally
  const { data: tasks, refetch: refetchTasks } =
    useGetOutsideTaskDefinitionsByOrganization(currentOrganization.id);

  const handleAddTask = useCallback(() => {
    const newTask: OutsideTaskDefinitionRequest = {
      name: '',
      organizationId: currentOrganization.id,
      outsideTaskKey: uuid(),
    };
    setEditingTask(newTask);
  }, [currentOrganization.id]);

  const handleEditTask = useCallback((task: OutsideTaskDefinitionRequest) => {
    setEditingTask(task);
  }, []);

  const handleSaveTask = useCallback(
    async (taskData: OutsideTaskDefinitionRequest) => {
      setIsLoading(true);
      try {
        console.log('handleSaveTask: taskData', taskData);
        await saveOutsideTask({ data: taskData });

        await refetchTasks();
        onTaskChange?.();
        setEditingTask(null);
      } catch (error) {
        console.error('Failed to save task:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [saveOutsideTask, refetchTasks, onTaskChange]
  );

  const handleDeleteTask = useCallback(
    async (taskId: number) => {
      setIsLoading(true);
      try {
        await deleteOutsideTask({ outsideTaskDefinitionId: taskId });
        await refetchTasks();
        onTaskChange?.();
        setEditingTask(null);
      } catch (error) {
        console.error('Failed to delete task:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [deleteOutsideTask, refetchTasks, onTaskChange]
  );

  const handleCancelEdit = useCallback(() => {
    setEditingTask(null);
  }, []);

  useEffect(() => {
    if (!editingTask) {
      setDrawerHeader(
        <Button
          leftSection={<IconPlus size={16} />}
          variant="light"
          onClick={handleAddTask}
        >
          Add Task
        </Button>
      );
    } else {
      setDrawerHeader(null);
    }

    return () => {
      setDrawerHeader(null);
    };
  }, [editingTask, handleAddTask, setDrawerHeader]);

  const renderContent = () => {
    if (editingTask) {
      return (
        <OutsideTaskEdit
          task={editingTask}
          isNew={!editingTask.id}
          onSave={handleSaveTask}
          onDelete={handleDeleteTask}
          onCancel={handleCancelEdit}
          isSaving={isLoading}
        />
      );
    }

    return (
      <OutsideTasksAdminView tasks={tasks ?? []} onEditTask={handleEditTask} />
    );
  };

  return (
    <ScrollableDrawer
      opened={opened}
      onClose={onClose}
      title={
        editingTask
          ? editingTask.id
            ? 'Edit Task'
            : 'Add Task'
          : 'Manage Outside Tasks'
      }
    >
      {renderContent()}
    </ScrollableDrawer>
  );
}
