import {
  CertificationConfig,
  CertificationTypeEnum,
  TaskOptionTaskProperties,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTypedCertificationProperties } from '../../../config/certificationPropertiesMap';
import { WorkflowSelectorField } from '../../../selector-components/WorkflowSelectorField/WorkflowSelectorField';
import { CertificationConfigProps } from '../../../types/editors';
import { getWorkflowSelection } from '../../../utils/workflowSelectionUtil';

export const CertificationOptionConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const certificationProperties = getTypedCertificationProperties(
    config,
    CertificationTypeEnum.completeTaskOption
  ) as TaskOptionTaskProperties | undefined;

  const [properties, setProperties] = useState<TaskOptionTaskProperties>({
    title: certificationProperties?.title || '',
    shortDescription: certificationProperties?.shortDescription || '',
    longDescription: certificationProperties?.longDescription || '',
    workflowSelection: certificationProperties?.workflowSelection || {
      workflowDefinitionKey: {
        value: '',
      },
    },
  });

  useEffect(() => {
    if (certificationProperties) {
      setProperties({
        title: certificationProperties.title || '',
        shortDescription: certificationProperties?.shortDescription || '',
        longDescription: certificationProperties?.longDescription || '',
        workflowSelection: certificationProperties.workflowSelection,
      });
    }
  }, [certificationProperties]);

  const handlePropertyChange = (
    field: keyof Omit<TaskOptionTaskProperties, 'workflowSelection'>,
    value: string
  ) => {
    const updatedProperties: TaskOptionTaskProperties = {
      ...properties,
      [field]: value,
    };

    setProperties(updatedProperties);

    onConfigChange({
      ...config,
      title: updatedProperties.title,
      taskProperties: updatedProperties,
    });
  };

  const handleWorkflowSelect = (
    newWorkflowSelection: WorkflowSelection,
    name: string
  ) => {
    const updatedProperties: TaskOptionTaskProperties = {
      ...properties,
      workflowSelection: newWorkflowSelection,
    };

    setProperties(updatedProperties);

    const updatedConfig: CertificationConfig = {
      ...config,
      title: name,
      taskProperties: updatedProperties,
    };

    onConfigChange(updatedConfig);
  };

  const workflowSelection = getWorkflowSelection(config);
  const workflowType = WorkflowTypeEnum.taskOption;

  return (
    <Stack gap="md">
      <Stack>
        <TextInput
          label="Title"
          placeholder="Enter certification title"
          value={properties.title}
          onChange={(event) =>
            handlePropertyChange('title', event.currentTarget.value)
          }
        />
        <Textarea
          label="Short Description"
          placeholder="Enter brief description"
          value={properties.shortDescription || ''}
          onChange={(event) =>
            handlePropertyChange('shortDescription', event.currentTarget.value)
          }
          rows={2}
        />
        <Textarea
          label="Long Description"
          placeholder="Enter detailed description"
          value={properties.longDescription || ''}
          onChange={(event) =>
            handlePropertyChange('longDescription', event.currentTarget.value)
          }
          rows={8}
        />
      </Stack>
      <WorkflowSelectorField
        workflowType={workflowType}
        workflowSelection={workflowSelection}
        onWorkflowSelect={handleWorkflowSelect}
      />
    </Stack>
  );
};

export default CertificationOptionConfiguration;
