import { useAuthImpersonation } from '@btrway/impersonation';
import { IconLogin2 } from '@tabler/icons-react';
import React from 'react';

interface UserAccessButtonProps {
  personId: number;
  organizationId: number;
  email: string;
  size?: number;
  style?: React.CSSProperties;
}

const UserAccessButton: React.FC<UserAccessButtonProps> = ({
  personId,
  organizationId,
  email,
  size = 20,
  style,
}) => {
  const { requestPersonAccessToken } = useAuthImpersonation();

  const handleAccessPersonClick = (event: React.MouseEvent<SVGSVGElement>) => {
    requestPersonAccessToken(personId, organizationId, email);
  };

  return (
    <IconLogin2
      size={size}
      color="darkgrey"
      style={{ cursor: 'pointer', ...style }}
      onClick={handleAccessPersonClick}
    />
  );
};

export default UserAccessButton;
