import { Box, CloseButton, Paper, Transition } from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface SlidingActionPanelProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const SlidingActionPanel: React.FC<SlidingActionPanelProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Paper
      shadow="md"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: isOpen ? '300px' : '0px',
        height: '100%',
        zIndex: 1000,
        overflowY: 'auto',
        transition: 'width 0.4s ease',
        backgroundColor: 'white',
        borderLeft: '1px solid #e9ecef', // gray.2 in Mantine
      }}
    >
      <Transition
        mounted={isOpen}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Box p="md" style={{ ...styles, position: 'relative' }} w="300px">
            <CloseButton
              onClick={onClose}
              style={{ position: 'absolute', top: 10, left: 10 }}
              aria-label="Close panel"
            />
            <Box pt={40} w="100%">
              {children}
            </Box>
          </Box>
        )}
      </Transition>
    </Paper>
  );
};

export default SlidingActionPanel;
