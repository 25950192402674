import { FacilityRequest, FacilityResponse } from '@btrway/api-calendar';
import { WorkgroupResponse } from '@btrway/api-core';
import { useScrollableDrawer } from '@btrway/scrollable-drawer';
import { Stack } from '@mantine/core';
import { useMemo } from 'react';
import { convertFacilityResponseToRequest } from '../../utils/facilityConversion';
import { FacilityCard } from '../FacilityCard/FacilityCard';

interface FacilitiesAdminViewProps {
  facilities: FacilityResponse[];
  workgroup: WorkgroupResponse;
  onEditFacility: (facility: FacilityRequest) => void;
  onAddFacility: () => void;
}

export const FacilitiesAdminView: React.FC<FacilitiesAdminViewProps> = ({
  facilities,
  workgroup,
  onEditFacility,
  onAddFacility,
}) => {
  const { setDrawerFooter } = useScrollableDrawer();

  const handleEditFacility = (facility: FacilityResponse) => {
    const convertedFacility = convertFacilityResponseToRequest(facility);
    onEditFacility(convertedFacility);
  };

  // Sort facilities by name
  const sortedFacilities = useMemo(() => {
    return facilities
      .slice()
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
  }, [facilities]);

  return (
    <Stack gap="md">
      {sortedFacilities.map((facility) => {
        const convertedFacility = convertFacilityResponseToRequest(facility);
        return (
          <FacilityCard
            key={facility.id}
            facility={convertedFacility}
            onEdit={() => handleEditFacility(facility)}
          />
        );
      })}
    </Stack>
  );
};
