import { Button, Text } from '@mantine/core';
import React from 'react';
import { useJSONGeneration } from '../../hooks/useJSONGeneration';

export const ReviewSubmitStep: React.FC = () => {
  const { generateFinalJSON } = useJSONGeneration();
  // const fileData = useAtomValue(fileDataAtom);
  // const fieldMapping = useAtomValue(fieldMappingAtom);
  // const workgroupMapping = useAtomValue(workgroupMappingAtom);

  const handleGenerateJSON = () => {
    const json = generateFinalJSON();
    if (json) {
      console.log('Generated JSON:', json);
    } else {
      console.error('Failed to generate JSON');
    }
  };

  return (
    <>
      <Text>Click the button below to generate and log the JSON:</Text>
      <Button onClick={handleGenerateJSON} mt="md">
        Generate JSON
      </Button>
    </>
  );
};
