import {
  HelpResourceUsageRequest,
  HelpResourceUsageResponse,
  useDeleteHelpResourceUsage,
  useGetHelpResourceUsagesByWorkflow,
  useSaveHelpResourceUsage,
} from '@btrway/api-help';
import { useCallback, useMemo } from 'react';
import { useHelpResourceUsagesStore } from './useHelpResourceUsagesStore';

export const useTaskHelpResources = (
  workflowKey: string,
  taskTypes?: string[]
) => {
  const { mutateAsync: saveHelpResourceUsage } = useSaveHelpResourceUsage();
  const { mutateAsync: deleteHelpResourceUsage } = useDeleteHelpResourceUsage();
  const { mutateAsync: getHelpResourceUsages } =
    useGetHelpResourceUsagesByWorkflow();

  const {
    helpResourceUsages,
    updateHelpResourceUsages,
    removeHelpResourceUsages,
    getTaskHelpUsages,
  } = useHelpResourceUsagesStore();

  const fetchHelpUsages = useCallback(async () => {
    if (!workflowKey || !taskTypes?.length) return;

    try {
      const response = (await getHelpResourceUsages({
        workflowKey,
        data: taskTypes,
      })) as HelpResourceUsageResponse[];
      updateHelpResourceUsages(response);
    } catch (error) {
      console.error('Error fetching help resources:', error);
    }
  }, [workflowKey, taskTypes, getHelpResourceUsages, updateHelpResourceUsages]);

  const updateHelpResourceAssociations = useCallback(
    async (
      taskKey: string,
      taskType: string | undefined,
      selectedResourceKeys: string[]
    ) => {
      const existingUsages = getTaskHelpUsages(workflowKey, taskKey, taskType);
      const existingMap = new Map(
        existingUsages.map((usage) => [usage.helpResourceKey, usage])
      );
      const existingKeys = new Set(existingMap.keys());
      const selectedKeysSet = new Set(selectedResourceKeys);

      // Handle new associations
      const addPromises = Array.from(selectedKeysSet)
        .filter((key) => !existingKeys.has(key))
        .map(async (resourceKey) => {
          const request: HelpResourceUsageRequest = {
            helpResourceKey: resourceKey,
            conditions: {
              workflowKey,
              taskKey,
              taskType,
            },
            usageType: 'task',
          };
          const newUsage = (await saveHelpResourceUsage({
            data: request,
          })) as HelpResourceUsageResponse;
          return newUsage;
        });

      // Handle removals
      const removePromises = Array.from(existingMap.entries())
        .filter(([key]) => !selectedKeysSet.has(key))
        .map(async ([_, usage]) => {
          await deleteHelpResourceUsage({ helpResourceUsageId: usage.id });
          return usage.id;
        });

      try {
        const [newUsages, removedIds] = await Promise.all([
          Promise.all(addPromises),
          Promise.all(removePromises),
        ]);

        // Update the atom
        updateHelpResourceUsages(newUsages);
        removeHelpResourceUsages(removedIds);
      } catch (error) {
        console.error('Error updating help resource associations:', error);
      }
    },
    [
      workflowKey,
      getTaskHelpUsages,
      saveHelpResourceUsage,
      deleteHelpResourceUsage,
      updateHelpResourceUsages,
      removeHelpResourceUsages,
    ]
  );

  return useMemo(
    () => ({
      helpResourceUsages,
      fetchHelpUsages,
      getTaskHelpUsages,
      updateHelpResourceAssociations,
    }),
    [
      helpResourceUsages,
      fetchHelpUsages,
      getTaskHelpUsages,
      updateHelpResourceAssociations,
    ]
  );
};
