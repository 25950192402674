import { RichTextEditor } from '@mantine/tiptap';
import { Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Question, Serializer, ElementFactory, QuestionFactory } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import React, { useEffect } from 'react';
import { editorLocalization } from "survey-creator-core";
import { ContentRenderer, FontControls } from '@btrway/content-editor';

const CUSTOM_TYPE = 'description-editor';

export class DescriptionQuestion extends Question {
    getType() {
      return CUSTOM_TYPE;
    }
  
    get editorContent() {
      const storedValue = this.getPropertyValue('editorContent');
      try {
        // If it's a string, try to parse it
        if (typeof storedValue === 'string') {
          return { json: JSON.parse(storedValue) };
        }
        return storedValue;
      } catch (e) {
        console.warn('Failed to parse editor content:', e);
        return null;
      }
    }
    
    set editorContent(val: any) {
      // Store the JSON structure as a string to avoid SurveyJS comparison issues
      if (val?.json) {
        this.setPropertyValue('editorContent', JSON.stringify(val.json));
      } else {
        this.setPropertyValue('editorContent', null);
      }
    }
  
    get showTitle(): boolean {
      return false;
    }
  }

const defaultContent = {
  type: 'doc',
  content: [{ type: 'paragraph' }]
};

const DescriptionEditor: React.FC<{
  content: any;
  isDesignMode: boolean;
  onChange?: (content: any) => void;
}> = ({ content, isDesignMode, onChange }) => {
  // Ensure we have a valid content object
  const initialContent = content?.type === 'doc' ? content : defaultContent;

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
    ],
    content: initialContent,
    editable: isDesignMode,
    onUpdate: onChange ? ({ editor }) => {
      const json = editor.getJSON();
      onChange({ json });
    } : undefined,
  }, [isDesignMode]); // Re-initialize when design mode changes

  useEffect(() => {
    if (editor) {
      editor.setEditable(isDesignMode);
    }
  }, [editor, isDesignMode]);

  useEffect(() => {
    if (editor && content?.type === 'doc') {
      // Only update content if it's different from current
      const currentContent = editor.getJSON();
      if (JSON.stringify(currentContent) !== JSON.stringify(content)) {
        editor.commands.setContent(content);
      }
    }
  }, [editor, content]);

  if (!editor) {
    return null;
  }

  return (
    <RichTextEditor editor={editor}>
      {isDesignMode && (
        <RichTextEditor.Toolbar sticky>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
            <RichTextEditor.Code />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Undo />
            <RichTextEditor.Redo />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

export class SurveyDescriptionEditor extends SurveyQuestionElementBase {
  get question(): DescriptionQuestion {
    return this.questionBase as DescriptionQuestion;
  }

  get editorValue() {
    try {
      const storedContent = this.question.editorContent;
      if (storedContent) {
        // If it's already a parsed object with json property
        if (storedContent.json) {
          return storedContent.json;
        }
        // If it's a string (from our modified storage), parse it
        return JSON.parse(storedContent);
      }
      
      // Try to get from question value
      if (this.question.value) {
        return typeof this.question.value === 'string' 
          ? JSON.parse(this.question.value) 
          : this.question.value;
      }
    } catch (e) {
      console.warn('Failed to parse content:', e);
    }
    
    return defaultContent;
  }

  handleValueChange = (content: any) => {
    // Store content as stringified JSON in both places
    this.question.editorContent = content;
    this.question.value = JSON.stringify(content.json);
  };

  get isDesignMode(): boolean {
    return this.question.isDesignMode && !this.question.isReadOnly;
  }

  // Override the base class method to properly handle the content
//   protected setNewValue(newValue: any) {
//     super.setNewValue(newValue);
//     try {
//       const parsedValue = typeof newValue === 'string' ? JSON.parse(newValue) : newValue;
//       this.question.editorContent = { json: parsedValue };
//     } catch (e) {
//       console.warn('Failed to parse new value:', e);
//     }
//   }

  renderElement() {
    return (
      <div style={{ 
        padding: '10px',
        backgroundColor: this.isDesignMode ? '#fff' : 'transparent',
        border: this.isDesignMode ? '1px solid #e0e0e0' : 'none',
        borderRadius: '4px',
        minHeight: '100px'
      }}>
        <DescriptionEditor
          content={this.editorValue}
          isDesignMode={this.isDesignMode}
          onChange={this.handleValueChange}
        />
      </div>
    );
  }
}

export function registerDescriptionEditor() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    const question = new DescriptionQuestion(name);
    question.iconName = 'icon-description';
    return question;
  });

  Serializer.addClass(
    CUSTOM_TYPE,
    [{ 
      name: 'editorContent',
      default: null,
      visible: false 
    }],
    function () {
      return new DescriptionQuestion('');
    },
    'question'
  );

  Serializer.getProperty(CUSTOM_TYPE, "hideNumber").defaultValue = true;
  Serializer.getProperty(CUSTOM_TYPE, "titleLocation").defaultValue = "hidden";

  ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return React.createElement(SurveyDescriptionEditor, props);
  });

  const locale = editorLocalization.getLocale("");
  locale.qt[CUSTOM_TYPE] = "Description";

  QuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (name) => {
    return new DescriptionQuestion(name);
  });
}