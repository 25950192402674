/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type DateDueTypeEnum = typeof DateDueTypeEnum[keyof typeof DateDueTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateDueTypeEnum = {
  relative: 'relative',
  fixed: 'fixed',
  taskSpecific: 'taskSpecific',
} as const;
