import { FacilityRequest, FacilityResponse } from '@btrway/api-calendar';
import { convertEventResponseToRequest } from '@btrway/calendar-event-editor';

export const convertFacilityResponseToRequest = (
  facility: FacilityResponse
): FacilityRequest => {
  // Create a new object to avoid mutation
  const convertedFacility: FacilityRequest = {
    id: facility.id,
    name: facility.name,
    attributes: facility.attributes || {},
    organizationId: facility.organizationId,
    workgroupId: facility.workgroupId,
    path: facility.path,
    // Ensure we're explicitly handling the availabilityEvents array
    availabilityEvents: facility.availabilityEvents
      ? facility.availabilityEvents.map(convertEventResponseToRequest)
      : [],
  };

  return convertedFacility;
};
