import {
  AssetResponse,
  ChapterProgressRequest,
  ChapterQuizResultEnum,
} from '@btrway/api-courseware';
import { useCourseManager } from '@btrway/courseware-course-manager';
import { useCoursewareProgressUpdate } from '@btrway/courseware-progress-manager';
import { QuizViewer } from '@btrway/courseware-quiz-viewer';
import {
  VideoTimelineLocation,
  VideoViewer,
} from '@btrway/courseware-video-viewer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './CourseViewer.module.css';

interface CourseViewerProps {
  course: AssetResponse;
  resume?: boolean;
  onBack: () => void;
  onCourseComplete?: () => void;
}

const CourseViewer: React.FC<CourseViewerProps> = ({
  course,
  resume = false,
  onBack,
  onCourseComplete,
}) => {
  const memoizedCourse = useMemo(() => course, [course.id]);

  const [videoCompleted, setVideoCompleted] = useState(false);
  const [videoMaxTimeSeconds, setVideoMaxTimeSeconds] = useState(0);
  const prevChapterRef = useRef<string | null>(null);
  const taskCompletedRef = useRef(false);
  const progressSaving = useRef(false);
  const { saveProgress } = useCoursewareProgressUpdate();

  const {
    currentChapter,
    isLoading: isChapterLoading,
    goToChapter,
    goToNextChapter,
    initialCurrentTime,
    initialMaxTime,
    isCourseComplete,
  } = useCourseManager(memoizedCourse, resume);

  useEffect(() => {
    if (isCourseComplete && !taskCompletedRef.current) {
      taskCompletedRef.current = true;
      onCourseComplete?.();
    }
  }, [isCourseComplete, onCourseComplete]);

  useEffect(() => {
    if (currentChapter && prevChapterRef.current !== currentChapter?.key) {
      setVideoCompleted(false);
      setVideoMaxTimeSeconds(0);
      prevChapterRef.current = currentChapter?.key;
    }
  }, [currentChapter?.key]);

  const handleVideoEnd = useCallback(() => {
    setVideoCompleted(true);
    setVideoMaxTimeSeconds(currentChapter?.duration || 0);

    if (!currentChapter?.questions || currentChapter?.questions.length === 0) {
      handleChapterComplete({
        currentTimeSeconds: currentChapter?.duration || 0,
        maxTimeSeconds: currentChapter?.duration || 0,
      });
    }
  }, [currentChapter]);

  const handleQuizComplete = useCallback(
    (correctCount: number, totalCount: number) => {
      const quizResult =
        correctCount === totalCount
          ? ChapterQuizResultEnum.passed
          : ChapterQuizResultEnum.failed;

      handleChapterComplete({
        currentTimeSeconds: videoMaxTimeSeconds,
        maxTimeSeconds: videoMaxTimeSeconds,
        chapterQuizResult: quizResult,
        correctQuestionCount: correctCount,
        totalQuestionCount: totalCount,
      });
    },
    [videoMaxTimeSeconds]
  );

  const handleChapterSelect = useCallback(
    (chapterKey: string) => {
      goToChapter(chapterKey);
    },
    [goToChapter]
  );

  const handleChapterComplete = useCallback(
    async (progressData: Partial<ChapterProgressRequest>) => {
      if (!currentChapter?.key || progressSaving.current) {
        return;
      }

      try {
        progressSaving.current = true;
        await saveProgress({
          ...progressData,
          chapterKey: currentChapter?.key,
          completed: true,
          completedAt: new Date().toISOString(),
        });

        goToNextChapter();
      } finally {
        progressSaving.current = false;
      }
    },
    [currentChapter?.key, saveProgress, goToNextChapter]
  );

  if (!currentChapter) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentContainer}>
        {!videoCompleted && currentChapter && (
          <VideoViewer
            initialChapter={currentChapter}
            course={memoizedCourse}
            onVideoEnd={handleVideoEnd}
            timelineLocation={VideoTimelineLocation.RIGHT}
            initialCurrentTime={initialCurrentTime}
            initialMaxTime={initialMaxTime}
            onBack={onBack}
            onChapterSelect={handleChapterSelect}
          />
        )}
        {videoCompleted &&
          currentChapter?.questions &&
          currentChapter?.questions.length > 0 && (
            <QuizViewer
              questions={currentChapter?.questions}
              onQuizComplete={handleQuizComplete}
            />
          )}
      </div>
    </div>
  );
};

export default CourseViewer;
