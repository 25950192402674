import { QuestionDTO } from '@btrway/api-courseware';
import { Box } from '@mantine/core';
import React, { useState } from 'react';
import QuizQuestion from '../QuizQuestion/QuizQuestion';

interface QuizViewerProps {
  questions: QuestionDTO[];
  onQuizComplete: (correctCount: number, totalCount: number) => void;
}

const QuizViewer: React.FC<QuizViewerProps> = ({
  questions,
  onQuizComplete,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState<Set<number>>(
    new Set()
  );

  const handleAnswer = (questionIndex: number, isCorrect: boolean) => {
    if (isCorrect) {
      setCorrectAnswers((prev) => prev + 1);
    }
    setAnsweredQuestions((prev) => new Set(prev).add(questionIndex));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      onQuizComplete(correctAnswers, questions.length);
    }
  };

  return (
    <Box
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {questions.map((question, index) => (
        <Box
          key={index}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(${(index - currentQuestionIndex) * 100}%)`,
          }}
        >
          <QuizQuestion
            question={question}
            onAnswer={(isCorrect) => handleAnswer(index, isCorrect)}
            onNextQuestion={handleNextQuestion}
            isCurrent={index === currentQuestionIndex}
            isAnswered={answeredQuestions.has(index)}
            isLastQuestion={index === questions.length - 1}
          />
        </Box>
      ))}
    </Box>
  );
};

export default QuizViewer;
