/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type ActivityTypeEnum = typeof ActivityTypeEnum[keyof typeof ActivityTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ActivityTypeEnum = {
  userCreated: 'userCreated',
  userUpdated: 'userUpdated',
  workgroupMemberCreated: 'workgroupMemberCreated',
  workgroupMemberUpdated: 'workgroupMemberUpdated',
  workgroupMemberDeleted: 'workgroupMemberDeleted',
  personImportCompleted: 'personImportCompleted',
  personImportReverted: 'personImportReverted',
  comment: 'comment',
  taskAssigned: 'taskAssigned',
  taskViewed: 'taskViewed',
  taskCompleted: 'taskCompleted',
  formSubmitted: 'formSubmitted',
  curriculumTaskAssigned: 'curriculumTaskAssigned',
  courseTaskCompleted: 'courseTaskCompleted',
  login: 'login',
  registrationSubmitted: 'registrationSubmitted',
  registrationProcessed: 'registrationProcessed',
  sentEmail: 'sentEmail',
  relationshipCreated: 'relationshipCreated',
  relationshipUpdated: 'relationshipUpdated',
  relationshipDeleted: 'relationshipDeleted',
  missing_enum_value: 'missing enum value',
} as const;
