import {
  CustomTaskProperties,
  TaskConfig,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTypedTaskProperties } from '../../../config/taskPropertiesMap';
import { TaskConfigProps } from '../../../types/editors';

export const ManualTaskConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const taskProperties = getTypedTaskProperties(
    config,
    TaskTypeEnum.completeTask
  );

  const [properties, setProperties] = useState<CustomTaskProperties>({
    title: taskProperties?.title || '',
    shortDescription: taskProperties?.shortDescription || '',
    longDescription: taskProperties?.longDescription || '',
  });

  useEffect(() => {
    if (taskProperties) {
      setProperties({
        title: taskProperties.title,
        shortDescription: taskProperties?.shortDescription || '',
        longDescription: taskProperties?.longDescription || '',
      });
    }
  }, [taskProperties]);

  const handlePropertyChange = (
    field: keyof CustomTaskProperties,
    value: string
  ) => {
    const updatedProperties = {
      ...properties,
      [field]: value,
    };
    setProperties(updatedProperties);

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        title: updatedProperties.title,
      },
      taskProperties: updatedProperties,
    };
    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <TextInput
        label="Title"
        placeholder="Enter task title"
        value={properties.title}
        onChange={(event) =>
          handlePropertyChange('title', event.currentTarget.value)
        }
      />
      <Textarea
        label="Short Description"
        placeholder="Enter detailed description"
        value={properties.shortDescription || ''}
        onChange={(event) =>
          handlePropertyChange('shortDescription', event.currentTarget.value)
        }
        rows={2}
      />
      <Textarea
        label="Long Description"
        placeholder="Enter detailed description"
        value={properties.longDescription || ''}
        onChange={(event) =>
          handlePropertyChange('longDescription', event.currentTarget.value)
        }
        rows={8}
      />
    </Stack>
  );
};
