/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AddressRequest,
  AddressResponse,
  CountryResponse,
  CreatePersonRequest,
  CurrentUserResponse,
  EntityGroupMemberRequest,
  EntityGroupMemberResponse,
  EntityGroupRequest,
  EntityGroupResponse,
  ExternalUserRequest,
  ExternalUserResponse,
  FormRequest,
  FormResponse,
  GetEncodedPasswordParams,
  GetPublicRegistrationFormParams,
  GlobalSearchRequest,
  OrganizationRequest,
  OrganizationResponse,
  OrganizationTypeResponse,
  PagedResponseWorkgroupMemberGridResponse,
  PersonResponse,
  PublicRegistrationFormResponse,
  PublicWorkgroupResponse,
  RegistrationFormOverrideRequest,
  RegistrationFormOverrideResponse,
  RegistrationFormRequest,
  RegistrationFormResponse,
  RegistrationInstanceRequest,
  RegistrationInstanceResponse,
  RelationshipRequest,
  RelationshipResponse,
  RelationshipTypeResponse,
  SearchGlobalUserRequest,
  StateProvinceResponse,
  UpdatePersonRequest,
  WorkgroupMemberGridRequest,
  WorkgroupMemberRequest,
  WorkgroupMemberResponse,
  WorkgroupRequest,
  WorkgroupResponse,
  WorkgroupSlugResponse,
  WorkgroupTypeRequest,
  WorkgroupTypeResponse,
  YearDefinitionRequest,
  YearDefinitionResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const savePublicRegistrationInstance = (
    registrationInstanceRequest: RegistrationInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/public/public-data/save-registration-instance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationInstanceRequest
    },
      options);
    }
  


export const getSavePublicRegistrationInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePublicRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof savePublicRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePublicRegistrationInstance>>, {data: RegistrationInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  savePublicRegistrationInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePublicRegistrationInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof savePublicRegistrationInstance>>>
    export type SavePublicRegistrationInstanceMutationBody = RegistrationInstanceRequest
    export type SavePublicRegistrationInstanceMutationError = unknown

    export const useSavePublicRegistrationInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePublicRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof savePublicRegistrationInstance>>,
        TError,
        {data: RegistrationInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSavePublicRegistrationInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveYearDefinition = (
    yearDefinitionRequest: YearDefinitionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/year-definitions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: yearDefinitionRequest
    },
      options);
    }
  


export const getSaveYearDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveYearDefinition>>, TError,{data: YearDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveYearDefinition>>, TError,{data: YearDefinitionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveYearDefinition>>, {data: YearDefinitionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveYearDefinition(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveYearDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof saveYearDefinition>>>
    export type SaveYearDefinitionMutationBody = YearDefinitionRequest
    export type SaveYearDefinitionMutationError = unknown

    export const useSaveYearDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveYearDefinition>>, TError,{data: YearDefinitionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveYearDefinition>>,
        TError,
        {data: YearDefinitionRequest},
        TContext
      > => {

      const mutationOptions = getSaveYearDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveWorkgroup = (
    workgroupRequest: WorkgroupRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/workgroups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workgroupRequest
    },
      options);
    }
  


export const getSaveWorkgroupMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroup>>, TError,{data: WorkgroupRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroup>>, TError,{data: WorkgroupRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkgroup>>, {data: WorkgroupRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkgroup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkgroupMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkgroup>>>
    export type SaveWorkgroupMutationBody = WorkgroupRequest
    export type SaveWorkgroupMutationError = unknown

    export const useSaveWorkgroup = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroup>>, TError,{data: WorkgroupRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkgroup>>,
        TError,
        {data: WorkgroupRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkgroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveWorkgroupType = (
    workgroupTypeRequest: WorkgroupTypeRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/workgroup-types`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workgroupTypeRequest
    },
      options);
    }
  


export const getSaveWorkgroupTypeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupType>>, TError,{data: WorkgroupTypeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupType>>, TError,{data: WorkgroupTypeRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkgroupType>>, {data: WorkgroupTypeRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkgroupType(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkgroupTypeMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkgroupType>>>
    export type SaveWorkgroupTypeMutationBody = WorkgroupTypeRequest
    export type SaveWorkgroupTypeMutationError = unknown

    export const useSaveWorkgroupType = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupType>>, TError,{data: WorkgroupTypeRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkgroupType>>,
        TError,
        {data: WorkgroupTypeRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkgroupTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupMembers = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupMemberResponse[]>(
      {url: `/api/core/workgroup-members`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupMembersQueryKey = () => {
    return [`/api/core/workgroup-members`] as const;
    }

    
export const getGetWorkgroupMembersQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupMembers>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembers>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupMembersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupMembers>>> = ({ signal }) => getWorkgroupMembers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupMembers>>>
export type GetWorkgroupMembersQueryError = unknown



export function useGetWorkgroupMembers<TData = Awaited<ReturnType<typeof getWorkgroupMembers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembers>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupMembersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveWorkgroupMember = (
    workgroupMemberRequest: WorkgroupMemberRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/workgroup-members`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workgroupMemberRequest
    },
      options);
    }
  


export const getSaveWorkgroupMemberMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupMember>>, TError,{data: WorkgroupMemberRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupMember>>, TError,{data: WorkgroupMemberRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveWorkgroupMember>>, {data: WorkgroupMemberRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveWorkgroupMember(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveWorkgroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof saveWorkgroupMember>>>
    export type SaveWorkgroupMemberMutationBody = WorkgroupMemberRequest
    export type SaveWorkgroupMemberMutationError = unknown

    export const useSaveWorkgroupMember = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveWorkgroupMember>>, TError,{data: WorkgroupMemberRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveWorkgroupMember>>,
        TError,
        {data: WorkgroupMemberRequest},
        TContext
      > => {

      const mutationOptions = getSaveWorkgroupMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupMemberGrid = (
    workgroupMemberGridRequest: WorkgroupMemberGridRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PagedResponseWorkgroupMemberGridResponse>(
      {url: `/api/core/workgroup-members/grid`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workgroupMemberGridRequest
    },
      options);
    }
  


export const getGetWorkgroupMemberGridMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getWorkgroupMemberGrid>>, TError,{data: WorkgroupMemberGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getWorkgroupMemberGrid>>, TError,{data: WorkgroupMemberGridRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getWorkgroupMemberGrid>>, {data: WorkgroupMemberGridRequest}> = (props) => {
          const {data} = props ?? {};

          return  getWorkgroupMemberGrid(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetWorkgroupMemberGridMutationResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupMemberGrid>>>
    export type GetWorkgroupMemberGridMutationBody = WorkgroupMemberGridRequest
    export type GetWorkgroupMemberGridMutationError = unknown

    export const useGetWorkgroupMemberGrid = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getWorkgroupMemberGrid>>, TError,{data: WorkgroupMemberGridRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getWorkgroupMemberGrid>>,
        TError,
        {data: WorkgroupMemberGridRequest},
        TContext
      > => {

      const mutationOptions = getGetWorkgroupMemberGridMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveRelationship = (
    relationshipRequest: RelationshipRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/relationships`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: relationshipRequest
    },
      options);
    }
  


export const getSaveRelationshipMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRelationship>>, TError,{data: RelationshipRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveRelationship>>, TError,{data: RelationshipRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveRelationship>>, {data: RelationshipRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveRelationship(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveRelationshipMutationResult = NonNullable<Awaited<ReturnType<typeof saveRelationship>>>
    export type SaveRelationshipMutationBody = RelationshipRequest
    export type SaveRelationshipMutationError = unknown

    export const useSaveRelationship = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRelationship>>, TError,{data: RelationshipRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveRelationship>>,
        TError,
        {data: RelationshipRequest},
        TContext
      > => {

      const mutationOptions = getSaveRelationshipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveRegistrationInstance = (
    registrationInstanceRequest: RegistrationInstanceRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/registration-instances`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationInstanceRequest
    },
      options);
    }
  


export const getSaveRegistrationInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveRegistrationInstance>>, {data: RegistrationInstanceRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveRegistrationInstance(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveRegistrationInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof saveRegistrationInstance>>>
    export type SaveRegistrationInstanceMutationBody = RegistrationInstanceRequest
    export type SaveRegistrationInstanceMutationError = unknown

    export const useSaveRegistrationInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationInstance>>, TError,{data: RegistrationInstanceRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveRegistrationInstance>>,
        TError,
        {data: RegistrationInstanceRequest},
        TContext
      > => {

      const mutationOptions = getSaveRegistrationInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveRegistrationForm = (
    registrationFormRequest: RegistrationFormRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/registration-forms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationFormRequest
    },
      options);
    }
  


export const getSaveRegistrationFormMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationForm>>, TError,{data: RegistrationFormRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationForm>>, TError,{data: RegistrationFormRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveRegistrationForm>>, {data: RegistrationFormRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveRegistrationForm(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveRegistrationFormMutationResult = NonNullable<Awaited<ReturnType<typeof saveRegistrationForm>>>
    export type SaveRegistrationFormMutationBody = RegistrationFormRequest
    export type SaveRegistrationFormMutationError = unknown

    export const useSaveRegistrationForm = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationForm>>, TError,{data: RegistrationFormRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveRegistrationForm>>,
        TError,
        {data: RegistrationFormRequest},
        TContext
      > => {

      const mutationOptions = getSaveRegistrationFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveRegistrationFormOverride = (
    registrationFormOverrideRequest: RegistrationFormOverrideRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<RegistrationFormOverrideResponse>(
      {url: `/api/core/registration-form-overrides`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: registrationFormOverrideRequest
    },
      options);
    }
  


export const getSaveRegistrationFormOverrideMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationFormOverride>>, TError,{data: RegistrationFormOverrideRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationFormOverride>>, TError,{data: RegistrationFormOverrideRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveRegistrationFormOverride>>, {data: RegistrationFormOverrideRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveRegistrationFormOverride(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveRegistrationFormOverrideMutationResult = NonNullable<Awaited<ReturnType<typeof saveRegistrationFormOverride>>>
    export type SaveRegistrationFormOverrideMutationBody = RegistrationFormOverrideRequest
    export type SaveRegistrationFormOverrideMutationError = unknown

    export const useSaveRegistrationFormOverride = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveRegistrationFormOverride>>, TError,{data: RegistrationFormOverrideRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveRegistrationFormOverride>>,
        TError,
        {data: RegistrationFormOverrideRequest},
        TContext
      > => {

      const mutationOptions = getSaveRegistrationFormOverrideMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const savePerson = (
    updatePersonRequest: UpdatePersonRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/persons`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updatePersonRequest
    },
      options);
    }
  


export const getSavePersonMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePerson>>, TError,{data: UpdatePersonRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof savePerson>>, TError,{data: UpdatePersonRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof savePerson>>, {data: UpdatePersonRequest}> = (props) => {
          const {data} = props ?? {};

          return  savePerson(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SavePersonMutationResult = NonNullable<Awaited<ReturnType<typeof savePerson>>>
    export type SavePersonMutationBody = UpdatePersonRequest
    export type SavePersonMutationError = unknown

    export const useSavePerson = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof savePerson>>, TError,{data: UpdatePersonRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof savePerson>>,
        TError,
        {data: UpdatePersonRequest},
        TContext
      > => {

      const mutationOptions = getSavePersonMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPersonsByOrganizationAndIds = (
    organizationId: number,
    getPersonsByOrganizationAndIdsBody: number[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PersonResponse[]>(
      {url: `/api/core/persons/organizations/${organizationId}/person-ids`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getPersonsByOrganizationAndIdsBody
    },
      options);
    }
  


export const getGetPersonsByOrganizationAndIdsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>, TError,{organizationId: number;data: number[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>, TError,{organizationId: number;data: number[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>, {organizationId: number;data: number[]}> = (props) => {
          const {organizationId,data} = props ?? {};

          return  getPersonsByOrganizationAndIds(organizationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetPersonsByOrganizationAndIdsMutationResult = NonNullable<Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>>
    export type GetPersonsByOrganizationAndIdsMutationBody = number[]
    export type GetPersonsByOrganizationAndIdsMutationError = unknown

    export const useGetPersonsByOrganizationAndIds = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>, TError,{organizationId: number;data: number[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getPersonsByOrganizationAndIds>>,
        TError,
        {organizationId: number;data: number[]},
        TContext
      > => {

      const mutationOptions = getGetPersonsByOrganizationAndIdsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const createPerson = (
    createPersonRequest: CreatePersonRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/persons/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createPersonRequest
    },
      options);
    }
  


export const getCreatePersonMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError,{data: CreatePersonRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError,{data: CreatePersonRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPerson>>, {data: CreatePersonRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPerson(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePersonMutationResult = NonNullable<Awaited<ReturnType<typeof createPerson>>>
    export type CreatePersonMutationBody = CreatePersonRequest
    export type CreatePersonMutationError = unknown

    export const useCreatePerson = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPerson>>, TError,{data: CreatePersonRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createPerson>>,
        TError,
        {data: CreatePersonRequest},
        TContext
      > => {

      const mutationOptions = getCreatePersonMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getOrganizations = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationResponse[]>(
      {url: `/api/core/organizations`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationsQueryKey = () => {
    return [`/api/core/organizations`] as const;
    }

    
export const getGetOrganizationsQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizations>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizations>>> = ({ signal }) => getOrganizations(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizations>>>
export type GetOrganizationsQueryError = unknown



export function useGetOrganizations<TData = Awaited<ReturnType<typeof getOrganizations>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizations>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOrganizationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveOrganization = (
    organizationRequest: OrganizationRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/organizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organizationRequest
    },
      options);
    }
  


export const getSaveOrganizationMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveOrganization>>, TError,{data: OrganizationRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveOrganization>>, TError,{data: OrganizationRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveOrganization>>, {data: OrganizationRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveOrganization(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof saveOrganization>>>
    export type SaveOrganizationMutationBody = OrganizationRequest
    export type SaveOrganizationMutationError = unknown

    export const useSaveOrganization = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveOrganization>>, TError,{data: OrganizationRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveOrganization>>,
        TError,
        {data: OrganizationRequest},
        TContext
      > => {

      const mutationOptions = getSaveOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const searchGlobal = (
    globalSearchRequest: GlobalSearchRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/global/search`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: globalSearchRequest
    },
      options);
    }
  


export const getSearchGlobalMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchGlobal>>, TError,{data: GlobalSearchRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof searchGlobal>>, TError,{data: GlobalSearchRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchGlobal>>, {data: GlobalSearchRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchGlobal(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchGlobalMutationResult = NonNullable<Awaited<ReturnType<typeof searchGlobal>>>
    export type SearchGlobalMutationBody = GlobalSearchRequest
    export type SearchGlobalMutationError = unknown

    export const useSearchGlobal = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchGlobal>>, TError,{data: GlobalSearchRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof searchGlobal>>,
        TError,
        {data: GlobalSearchRequest},
        TContext
      > => {

      const mutationOptions = getSearchGlobalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const searchGlobalUsers = (
    searchGlobalUserRequest: SearchGlobalUserRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/global-users/search`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: searchGlobalUserRequest
    },
      options);
    }
  


export const getSearchGlobalUsersMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchGlobalUsers>>, TError,{data: SearchGlobalUserRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof searchGlobalUsers>>, TError,{data: SearchGlobalUserRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchGlobalUsers>>, {data: SearchGlobalUserRequest}> = (props) => {
          const {data} = props ?? {};

          return  searchGlobalUsers(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SearchGlobalUsersMutationResult = NonNullable<Awaited<ReturnType<typeof searchGlobalUsers>>>
    export type SearchGlobalUsersMutationBody = SearchGlobalUserRequest
    export type SearchGlobalUsersMutationError = unknown

    export const useSearchGlobalUsers = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchGlobalUsers>>, TError,{data: SearchGlobalUserRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof searchGlobalUsers>>,
        TError,
        {data: SearchGlobalUserRequest},
        TContext
      > => {

      const mutationOptions = getSearchGlobalUsersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getForms = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FormResponse[]>(
      {url: `/api/core/forms`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFormsQueryKey = () => {
    return [`/api/core/forms`] as const;
    }

    
export const getGetFormsQueryOptions = <TData = Awaited<ReturnType<typeof getForms>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForms>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFormsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getForms>>> = ({ signal }) => getForms(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getForms>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFormsQueryResult = NonNullable<Awaited<ReturnType<typeof getForms>>>
export type GetFormsQueryError = unknown



export function useGetForms<TData = Awaited<ReturnType<typeof getForms>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForms>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFormsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveForm = (
    formRequest: FormRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/forms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: formRequest
    },
      options);
    }
  


export const getSaveFormMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveForm>>, TError,{data: FormRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveForm>>, TError,{data: FormRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveForm>>, {data: FormRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveForm(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveFormMutationResult = NonNullable<Awaited<ReturnType<typeof saveForm>>>
    export type SaveFormMutationBody = FormRequest
    export type SaveFormMutationError = unknown

    export const useSaveForm = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveForm>>, TError,{data: FormRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveForm>>,
        TError,
        {data: FormRequest},
        TContext
      > => {

      const mutationOptions = getSaveFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getExternalUsers = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalUserResponse[]>(
      {url: `/api/core/external-users`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalUsersQueryKey = () => {
    return [`/api/core/external-users`] as const;
    }

    
export const getGetExternalUsersQueryOptions = <TData = Awaited<ReturnType<typeof getExternalUsers>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUsers>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalUsersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalUsers>>> = ({ signal }) => getExternalUsers(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalUsers>>>
export type GetExternalUsersQueryError = unknown



export function useGetExternalUsers<TData = Awaited<ReturnType<typeof getExternalUsers>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUsers>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalUsersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveExternalUser = (
    externalUserRequest: ExternalUserRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/external-users`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: externalUserRequest
    },
      options);
    }
  


export const getSaveExternalUserMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalUser>>, TError,{data: ExternalUserRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveExternalUser>>, TError,{data: ExternalUserRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveExternalUser>>, {data: ExternalUserRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveExternalUser(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveExternalUserMutationResult = NonNullable<Awaited<ReturnType<typeof saveExternalUser>>>
    export type SaveExternalUserMutationBody = ExternalUserRequest
    export type SaveExternalUserMutationError = unknown

    export const useSaveExternalUser = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveExternalUser>>, TError,{data: ExternalUserRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveExternalUser>>,
        TError,
        {data: ExternalUserRequest},
        TContext
      > => {

      const mutationOptions = getSaveExternalUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEntityGroup = (
    entityGroupRequest: EntityGroupRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/entity-groups`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: entityGroupRequest
    },
      options);
    }
  


export const getSaveEntityGroupMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroup>>, TError,{data: EntityGroupRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroup>>, TError,{data: EntityGroupRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEntityGroup>>, {data: EntityGroupRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEntityGroup(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEntityGroupMutationResult = NonNullable<Awaited<ReturnType<typeof saveEntityGroup>>>
    export type SaveEntityGroupMutationBody = EntityGroupRequest
    export type SaveEntityGroupMutationError = unknown

    export const useSaveEntityGroup = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroup>>, TError,{data: EntityGroupRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEntityGroup>>,
        TError,
        {data: EntityGroupRequest},
        TContext
      > => {

      const mutationOptions = getSaveEntityGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveEntityGroupMember = (
    entityGroupMemberRequest: EntityGroupMemberRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/entity-group-members`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: entityGroupMemberRequest
    },
      options);
    }
  


export const getSaveEntityGroupMemberMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroupMember>>, TError,{data: EntityGroupMemberRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroupMember>>, TError,{data: EntityGroupMemberRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveEntityGroupMember>>, {data: EntityGroupMemberRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveEntityGroupMember(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveEntityGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof saveEntityGroupMember>>>
    export type SaveEntityGroupMemberMutationBody = EntityGroupMemberRequest
    export type SaveEntityGroupMemberMutationError = unknown

    export const useSaveEntityGroupMember = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveEntityGroupMember>>, TError,{data: EntityGroupMemberRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveEntityGroupMember>>,
        TError,
        {data: EntityGroupMemberRequest},
        TContext
      > => {

      const mutationOptions = getSaveEntityGroupMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAddresses = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AddressResponse[]>(
      {url: `/api/core/addresses`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAddressesQueryKey = () => {
    return [`/api/core/addresses`] as const;
    }

    
export const getGetAddressesQueryOptions = <TData = Awaited<ReturnType<typeof getAddresses>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAddressesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAddresses>>> = ({ signal }) => getAddresses(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAddressesQueryResult = NonNullable<Awaited<ReturnType<typeof getAddresses>>>
export type GetAddressesQueryError = unknown



export function useGetAddresses<TData = Awaited<ReturnType<typeof getAddresses>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAddresses>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAddressesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveAddress = (
    addressRequest: AddressRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/addresses`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addressRequest
    },
      options);
    }
  


export const getSaveAddressMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAddress>>, TError,{data: AddressRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveAddress>>, TError,{data: AddressRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveAddress>>, {data: AddressRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveAddress(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveAddressMutationResult = NonNullable<Awaited<ReturnType<typeof saveAddress>>>
    export type SaveAddressMutationBody = AddressRequest
    export type SaveAddressMutationError = unknown

    export const useSaveAddress = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAddress>>, TError,{data: AddressRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveAddress>>,
        TError,
        {data: AddressRequest},
        TContext
      > => {

      const mutationOptions = getSaveAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPublicWorkgroupById = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse>(
      {url: `/public/public-data/workgroups/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPublicWorkgroupByIdQueryKey = (workgroupId: number,) => {
    return [`/public/public-data/workgroups/${workgroupId}`] as const;
    }

    
export const getGetPublicWorkgroupByIdQueryOptions = <TData = Awaited<ReturnType<typeof getPublicWorkgroupById>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicWorkgroupByIdQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicWorkgroupById>>> = ({ signal }) => getPublicWorkgroupById(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroupById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPublicWorkgroupByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicWorkgroupById>>>
export type GetPublicWorkgroupByIdQueryError = unknown



export function useGetPublicWorkgroupById<TData = Awaited<ReturnType<typeof getPublicWorkgroupById>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPublicWorkgroupByIdQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPublicRelationshipTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RelationshipTypeResponse[]>(
      {url: `/public/public-data/relationship-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPublicRelationshipTypesQueryKey = () => {
    return [`/public/public-data/relationship-types`] as const;
    }

    
export const getGetPublicRelationshipTypesQueryOptions = <TData = Awaited<ReturnType<typeof getPublicRelationshipTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicRelationshipTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicRelationshipTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicRelationshipTypes>>> = ({ signal }) => getPublicRelationshipTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPublicRelationshipTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPublicRelationshipTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicRelationshipTypes>>>
export type GetPublicRelationshipTypesQueryError = unknown



export function useGetPublicRelationshipTypes<TData = Awaited<ReturnType<typeof getPublicRelationshipTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicRelationshipTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPublicRelationshipTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPublicOrganizations = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationResponse[]>(
      {url: `/public/public-data/organizations`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPublicOrganizationsQueryKey = () => {
    return [`/public/public-data/organizations`] as const;
    }

    
export const getGetPublicOrganizationsQueryOptions = <TData = Awaited<ReturnType<typeof getPublicOrganizations>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicOrganizations>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicOrganizationsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicOrganizations>>> = ({ signal }) => getPublicOrganizations(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPublicOrganizations>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPublicOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicOrganizations>>>
export type GetPublicOrganizationsQueryError = unknown



export function useGetPublicOrganizations<TData = Awaited<ReturnType<typeof getPublicOrganizations>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicOrganizations>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPublicOrganizationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPublicWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PublicWorkgroupResponse>(
      {url: `/public/core/public-workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPublicWorkgroupQueryKey = (workgroupId: number,) => {
    return [`/public/core/public-workgroup/${workgroupId}`] as const;
    }

    
export const getGetPublicWorkgroupQueryOptions = <TData = Awaited<ReturnType<typeof getPublicWorkgroup>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicWorkgroupQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicWorkgroup>>> = ({ signal }) => getPublicWorkgroup(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPublicWorkgroupQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicWorkgroup>>>
export type GetPublicWorkgroupQueryError = unknown



export function useGetPublicWorkgroup<TData = Awaited<ReturnType<typeof getPublicWorkgroup>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPublicWorkgroupQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const lookupWorkgroupBySlug = (
    slug: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupSlugResponse>(
      {url: `/public/core/public-workgroup/workgroup-lookup/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getLookupWorkgroupBySlugQueryKey = (slug: string,) => {
    return [`/public/core/public-workgroup/workgroup-lookup/${slug}`] as const;
    }

    
export const getLookupWorkgroupBySlugQueryOptions = <TData = Awaited<ReturnType<typeof lookupWorkgroupBySlug>>, TError = unknown>(slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof lookupWorkgroupBySlug>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLookupWorkgroupBySlugQueryKey(slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof lookupWorkgroupBySlug>>> = ({ signal }) => lookupWorkgroupBySlug(slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(slug), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof lookupWorkgroupBySlug>>, TError, TData> & { queryKey: QueryKey }
}

export type LookupWorkgroupBySlugQueryResult = NonNullable<Awaited<ReturnType<typeof lookupWorkgroupBySlug>>>
export type LookupWorkgroupBySlugQueryError = unknown



export function useLookupWorkgroupBySlug<TData = Awaited<ReturnType<typeof lookupWorkgroupBySlug>>, TError = unknown>(
 slug: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof lookupWorkgroupBySlug>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLookupWorkgroupBySlugQueryOptions(slug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPublicRegistrationForm = (
    workgroupId: number,
    registrationFormId: number,
    params?: GetPublicRegistrationFormParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PublicRegistrationFormResponse>(
      {url: `/public/core/public-registration-form/${workgroupId}/registration-form/${registrationFormId}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetPublicRegistrationFormQueryKey = (workgroupId: number,
    registrationFormId: number,
    params?: GetPublicRegistrationFormParams,) => {
    return [`/public/core/public-registration-form/${workgroupId}/registration-form/${registrationFormId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPublicRegistrationFormQueryOptions = <TData = Awaited<ReturnType<typeof getPublicRegistrationForm>>, TError = unknown>(workgroupId: number,
    registrationFormId: number,
    params?: GetPublicRegistrationFormParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicRegistrationForm>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicRegistrationFormQueryKey(workgroupId,registrationFormId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicRegistrationForm>>> = ({ signal }) => getPublicRegistrationForm(workgroupId,registrationFormId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId && registrationFormId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPublicRegistrationForm>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPublicRegistrationFormQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicRegistrationForm>>>
export type GetPublicRegistrationFormQueryError = unknown



export function useGetPublicRegistrationForm<TData = Awaited<ReturnType<typeof getPublicRegistrationForm>>, TError = unknown>(
 workgroupId: number,
    registrationFormId: number,
    params?: GetPublicRegistrationFormParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicRegistrationForm>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPublicRegistrationFormQueryOptions(workgroupId,registrationFormId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getYearDefinitionsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<YearDefinitionResponse[]>(
      {url: `/api/core/year-definitions/organization/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetYearDefinitionsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/core/year-definitions/organization/${organizationId}`] as const;
    }

    
export const getGetYearDefinitionsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetYearDefinitionsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>> = ({ signal }) => getYearDefinitionsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetYearDefinitionsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>>
export type GetYearDefinitionsByOrganizationQueryError = unknown



export function useGetYearDefinitionsByOrganization<TData = Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetYearDefinitionsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getYearDefinitionByOrganizationAndYear = (
    organizationId: number,
    year: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<YearDefinitionResponse>(
      {url: `/api/core/year-definitions/organization/${organizationId}/year/${year}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetYearDefinitionByOrganizationAndYearQueryKey = (organizationId: number,
    year: number,) => {
    return [`/api/core/year-definitions/organization/${organizationId}/year/${year}`] as const;
    }

    
export const getGetYearDefinitionByOrganizationAndYearQueryOptions = <TData = Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>, TError = unknown>(organizationId: number,
    year: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetYearDefinitionByOrganizationAndYearQueryKey(organizationId,year);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>> = ({ signal }) => getYearDefinitionByOrganizationAndYear(organizationId,year, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && year), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>, TError, TData> & { queryKey: QueryKey }
}

export type GetYearDefinitionByOrganizationAndYearQueryResult = NonNullable<Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>>
export type GetYearDefinitionByOrganizationAndYearQueryError = unknown



export function useGetYearDefinitionByOrganizationAndYear<TData = Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>, TError = unknown>(
 organizationId: number,
    year: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearDefinitionByOrganizationAndYear>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetYearDefinitionByOrganizationAndYearQueryOptions(organizationId,year,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getYearByDate = (
    organizationId: number,
    date: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<number>(
      {url: `/api/core/year-definitions/organization/${organizationId}/date/${date}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetYearByDateQueryKey = (organizationId: number,
    date: string,) => {
    return [`/api/core/year-definitions/organization/${organizationId}/date/${date}`] as const;
    }

    
export const getGetYearByDateQueryOptions = <TData = Awaited<ReturnType<typeof getYearByDate>>, TError = unknown>(organizationId: number,
    date: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearByDate>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetYearByDateQueryKey(organizationId,date);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getYearByDate>>> = ({ signal }) => getYearByDate(organizationId,date, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && date), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getYearByDate>>, TError, TData> & { queryKey: QueryKey }
}

export type GetYearByDateQueryResult = NonNullable<Awaited<ReturnType<typeof getYearByDate>>>
export type GetYearByDateQueryError = unknown



export function useGetYearByDate<TData = Awaited<ReturnType<typeof getYearByDate>>, TError = unknown>(
 organizationId: number,
    date: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getYearByDate>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetYearByDateQueryOptions(organizationId,date,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkgroupById = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse>(
      {url: `/api/core/workgroups/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupByIdQueryKey = (workgroupId: number,) => {
    return [`/api/core/workgroups/${workgroupId}`] as const;
    }

    
export const getGetWorkgroupByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupById>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupByIdQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupById>>> = ({ signal }) => getWorkgroupById(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupById>>>
export type GetWorkgroupByIdQueryError = unknown



export function useGetWorkgroupById<TData = Awaited<ReturnType<typeof getWorkgroupById>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupByIdQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/workgroups/${workgroupId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkgroupMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroup>>, TError,{workgroupId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroup>>, TError,{workgroupId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkgroup>>, {workgroupId: number}> = (props) => {
          const {workgroupId} = props ?? {};

          return  deleteWorkgroup(workgroupId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkgroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkgroup>>>
    
    export type DeleteWorkgroupMutationError = unknown

    export const useDeleteWorkgroup = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroup>>, TError,{workgroupId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkgroup>>,
        TError,
        {workgroupId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkgroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupsByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse[]>(
      {url: `/api/core/workgroups/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupsByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/core/workgroups/organizations/${organizationId}`] as const;
    }

    
export const getGetWorkgroupsByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupsByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>> = ({ signal }) => getWorkgroupsByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupsByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>>
export type GetWorkgroupsByOrganizationIdQueryError = unknown



export function useGetWorkgroupsByOrganizationId<TData = Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupsByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkgroupHierarchyByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse>(
      {url: `/api/core/workgroups/organizations/${organizationId}/hierarchy`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupHierarchyByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/core/workgroups/organizations/${organizationId}/hierarchy`] as const;
    }

    
export const getGetWorkgroupHierarchyByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupHierarchyByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>> = ({ signal }) => getWorkgroupHierarchyByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupHierarchyByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>>
export type GetWorkgroupHierarchyByOrganizationIdQueryError = unknown



export function useGetWorkgroupHierarchyByOrganizationId<TData = Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupHierarchyByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupHierarchyByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkgroupsWithChildren = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse[]>(
      {url: `/api/core/workgroups/organizations/${organizationId}/childless`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupsWithChildrenQueryKey = (organizationId: number,) => {
    return [`/api/core/workgroups/organizations/${organizationId}/childless`] as const;
    }

    
export const getGetWorkgroupsWithChildrenQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupsWithChildren>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsWithChildren>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupsWithChildrenQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupsWithChildren>>> = ({ signal }) => getWorkgroupsWithChildren(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsWithChildren>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupsWithChildrenQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupsWithChildren>>>
export type GetWorkgroupsWithChildrenQueryError = unknown



export function useGetWorkgroupsWithChildren<TData = Awaited<ReturnType<typeof getWorkgroupsWithChildren>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupsWithChildren>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupsWithChildrenQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAllWorkgroupChildren = (
    organizationId: number,
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupResponse[]>(
      {url: `/api/core/workgroups/organization/${organizationId}/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAllWorkgroupChildrenQueryKey = (organizationId: number,
    workgroupId: number,) => {
    return [`/api/core/workgroups/organization/${organizationId}/${workgroupId}`] as const;
    }

    
export const getGetAllWorkgroupChildrenQueryOptions = <TData = Awaited<ReturnType<typeof getAllWorkgroupChildren>>, TError = unknown>(organizationId: number,
    workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllWorkgroupChildren>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllWorkgroupChildrenQueryKey(organizationId,workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllWorkgroupChildren>>> = ({ signal }) => getAllWorkgroupChildren(organizationId,workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllWorkgroupChildren>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllWorkgroupChildrenQueryResult = NonNullable<Awaited<ReturnType<typeof getAllWorkgroupChildren>>>
export type GetAllWorkgroupChildrenQueryError = unknown



export function useGetAllWorkgroupChildren<TData = Awaited<ReturnType<typeof getAllWorkgroupChildren>>, TError = unknown>(
 organizationId: number,
    workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllWorkgroupChildren>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllWorkgroupChildrenQueryOptions(organizationId,workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkgroupTypeById = (
    workgroupTypeId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupTypeResponse>(
      {url: `/api/core/workgroup-types/${workgroupTypeId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupTypeByIdQueryKey = (workgroupTypeId: number,) => {
    return [`/api/core/workgroup-types/${workgroupTypeId}`] as const;
    }

    
export const getGetWorkgroupTypeByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupTypeById>>, TError = unknown>(workgroupTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupTypeByIdQueryKey(workgroupTypeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupTypeById>>> = ({ signal }) => getWorkgroupTypeById(workgroupTypeId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupTypeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypeById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupTypeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupTypeById>>>
export type GetWorkgroupTypeByIdQueryError = unknown



export function useGetWorkgroupTypeById<TData = Awaited<ReturnType<typeof getWorkgroupTypeById>>, TError = unknown>(
 workgroupTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypeById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupTypeByIdQueryOptions(workgroupTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkgroupType = (
    workgroupTypeId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/workgroup-types/${workgroupTypeId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkgroupTypeMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupType>>, TError,{workgroupTypeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupType>>, TError,{workgroupTypeId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkgroupType>>, {workgroupTypeId: number}> = (props) => {
          const {workgroupTypeId} = props ?? {};

          return  deleteWorkgroupType(workgroupTypeId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkgroupTypeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkgroupType>>>
    
    export type DeleteWorkgroupTypeMutationError = unknown

    export const useDeleteWorkgroupType = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupType>>, TError,{workgroupTypeId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkgroupType>>,
        TError,
        {workgroupTypeId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkgroupTypeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupTypesByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupTypeResponse[]>(
      {url: `/api/core/workgroup-types/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupTypesByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/core/workgroup-types/organizations/${organizationId}`] as const;
    }

    
export const getGetWorkgroupTypesByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupTypesByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>> = ({ signal }) => getWorkgroupTypesByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupTypesByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>>
export type GetWorkgroupTypesByOrganizationIdQueryError = unknown



export function useGetWorkgroupTypesByOrganizationId<TData = Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupTypesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupTypesByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getWorkgroupMemberById = (
    workgroupMemberId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupMemberResponse>(
      {url: `/api/core/workgroup-members/${workgroupMemberId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupMemberByIdQueryKey = (workgroupMemberId: number,) => {
    return [`/api/core/workgroup-members/${workgroupMemberId}`] as const;
    }

    
export const getGetWorkgroupMemberByIdQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupMemberById>>, TError = unknown>(workgroupMemberId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMemberById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupMemberByIdQueryKey(workgroupMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupMemberById>>> = ({ signal }) => getWorkgroupMemberById(workgroupMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupMemberId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMemberById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupMemberByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupMemberById>>>
export type GetWorkgroupMemberByIdQueryError = unknown



export function useGetWorkgroupMemberById<TData = Awaited<ReturnType<typeof getWorkgroupMemberById>>, TError = unknown>(
 workgroupMemberId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMemberById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupMemberByIdQueryOptions(workgroupMemberId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteWorkgroupMember = (
    workgroupMemberId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/workgroup-members/${workgroupMemberId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkgroupMemberMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupMember>>, TError,{workgroupMemberId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupMember>>, TError,{workgroupMemberId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkgroupMember>>, {workgroupMemberId: number}> = (props) => {
          const {workgroupMemberId} = props ?? {};

          return  deleteWorkgroupMember(workgroupMemberId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkgroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkgroupMember>>>
    
    export type DeleteWorkgroupMemberMutationError = unknown

    export const useDeleteWorkgroupMember = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkgroupMember>>, TError,{workgroupMemberId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteWorkgroupMember>>,
        TError,
        {workgroupMemberId: number},
        TContext
      > => {

      const mutationOptions = getDeleteWorkgroupMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getWorkgroupMembersByWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<WorkgroupMemberResponse[]>(
      {url: `/api/core/workgroup-members/workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkgroupMembersByWorkgroupQueryKey = (workgroupId: number,) => {
    return [`/api/core/workgroup-members/workgroup/${workgroupId}`] as const;
    }

    
export const getGetWorkgroupMembersByWorkgroupQueryOptions = <TData = Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetWorkgroupMembersByWorkgroupQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>> = ({ signal }) => getWorkgroupMembersByWorkgroup(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetWorkgroupMembersByWorkgroupQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>>
export type GetWorkgroupMembersByWorkgroupQueryError = unknown



export function useGetWorkgroupMembersByWorkgroup<TData = Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWorkgroupMembersByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetWorkgroupMembersByWorkgroupQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getDistinctWorkgroupMemberYears = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<number[]>(
      {url: `/api/core/workgroup-member-years/years`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDistinctWorkgroupMemberYearsQueryKey = () => {
    return [`/api/core/workgroup-member-years/years`] as const;
    }

    
export const getGetDistinctWorkgroupMemberYearsQueryOptions = <TData = Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDistinctWorkgroupMemberYearsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>> = ({ signal }) => getDistinctWorkgroupMemberYears(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDistinctWorkgroupMemberYearsQueryResult = NonNullable<Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>>
export type GetDistinctWorkgroupMemberYearsQueryError = unknown



export function useGetDistinctWorkgroupMemberYears<TData = Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDistinctWorkgroupMemberYears>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetDistinctWorkgroupMemberYearsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getStateProvinces = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<StateProvinceResponse[]>(
      {url: `/api/core/state-provinces`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStateProvincesQueryKey = () => {
    return [`/api/core/state-provinces`] as const;
    }

    
export const getGetStateProvincesQueryOptions = <TData = Awaited<ReturnType<typeof getStateProvinces>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStateProvincesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStateProvinces>>> = ({ signal }) => getStateProvinces(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStateProvincesQueryResult = NonNullable<Awaited<ReturnType<typeof getStateProvinces>>>
export type GetStateProvincesQueryError = unknown



export function useGetStateProvinces<TData = Awaited<ReturnType<typeof getStateProvinces>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStateProvincesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRelationshipById = (
    relationshipId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RelationshipResponse>(
      {url: `/api/core/relationships/${relationshipId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRelationshipByIdQueryKey = (relationshipId: number,) => {
    return [`/api/core/relationships/${relationshipId}`] as const;
    }

    
export const getGetRelationshipByIdQueryOptions = <TData = Awaited<ReturnType<typeof getRelationshipById>>, TError = unknown>(relationshipId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRelationshipByIdQueryKey(relationshipId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRelationshipById>>> = ({ signal }) => getRelationshipById(relationshipId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(relationshipId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRelationshipById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRelationshipByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRelationshipById>>>
export type GetRelationshipByIdQueryError = unknown



export function useGetRelationshipById<TData = Awaited<ReturnType<typeof getRelationshipById>>, TError = unknown>(
 relationshipId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRelationshipByIdQueryOptions(relationshipId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteRelationship = (
    relationshipId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/relationships/${relationshipId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteRelationshipMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRelationship>>, TError,{relationshipId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRelationship>>, TError,{relationshipId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRelationship>>, {relationshipId: number}> = (props) => {
          const {relationshipId} = props ?? {};

          return  deleteRelationship(relationshipId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRelationshipMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRelationship>>>
    
    export type DeleteRelationshipMutationError = unknown

    export const useDeleteRelationship = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRelationship>>, TError,{relationshipId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRelationship>>,
        TError,
        {relationshipId: number},
        TContext
      > => {

      const mutationOptions = getDeleteRelationshipMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getRelationshipsByPersonIdAndOrganizationId = (
    organizationId: number,
    personId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RelationshipResponse[]>(
      {url: `/api/core/relationships/org/${organizationId}/person/${personId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRelationshipsByPersonIdAndOrganizationIdQueryKey = (organizationId: number,
    personId: number,) => {
    return [`/api/core/relationships/org/${organizationId}/person/${personId}`] as const;
    }

    
export const getGetRelationshipsByPersonIdAndOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>, TError = unknown>(organizationId: number,
    personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRelationshipsByPersonIdAndOrganizationIdQueryKey(organizationId,personId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>> = ({ signal }) => getRelationshipsByPersonIdAndOrganizationId(organizationId,personId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && personId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRelationshipsByPersonIdAndOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>>
export type GetRelationshipsByPersonIdAndOrganizationIdQueryError = unknown



export function useGetRelationshipsByPersonIdAndOrganizationId<TData = Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>, TError = unknown>(
 organizationId: number,
    personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipsByPersonIdAndOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRelationshipsByPersonIdAndOrganizationIdQueryOptions(organizationId,personId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRelationshipTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RelationshipTypeResponse[]>(
      {url: `/api/core/relationship-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRelationshipTypesQueryKey = () => {
    return [`/api/core/relationship-types`] as const;
    }

    
export const getGetRelationshipTypesQueryOptions = <TData = Awaited<ReturnType<typeof getRelationshipTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRelationshipTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRelationshipTypes>>> = ({ signal }) => getRelationshipTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRelationshipTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRelationshipTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getRelationshipTypes>>>
export type GetRelationshipTypesQueryError = unknown



export function useGetRelationshipTypes<TData = Awaited<ReturnType<typeof getRelationshipTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelationshipTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRelationshipTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRegistrationInstanceById = (
    registrationInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationInstanceResponse>(
      {url: `/api/core/registration-instances/${registrationInstanceId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationInstanceByIdQueryKey = (registrationInstanceId: number,) => {
    return [`/api/core/registration-instances/${registrationInstanceId}`] as const;
    }

    
export const getGetRegistrationInstanceByIdQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationInstanceById>>, TError = unknown>(registrationInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationInstanceByIdQueryKey(registrationInstanceId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationInstanceById>>> = ({ signal }) => getRegistrationInstanceById(registrationInstanceId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(registrationInstanceId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstanceById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationInstanceByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationInstanceById>>>
export type GetRegistrationInstanceByIdQueryError = unknown



export function useGetRegistrationInstanceById<TData = Awaited<ReturnType<typeof getRegistrationInstanceById>>, TError = unknown>(
 registrationInstanceId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstanceById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationInstanceByIdQueryOptions(registrationInstanceId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteRegistrationInstance = (
    registrationInstanceId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/registration-instances/${registrationInstanceId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteRegistrationInstanceMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationInstance>>, TError,{registrationInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationInstance>>, TError,{registrationInstanceId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRegistrationInstance>>, {registrationInstanceId: number}> = (props) => {
          const {registrationInstanceId} = props ?? {};

          return  deleteRegistrationInstance(registrationInstanceId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRegistrationInstanceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRegistrationInstance>>>
    
    export type DeleteRegistrationInstanceMutationError = unknown

    export const useDeleteRegistrationInstance = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationInstance>>, TError,{registrationInstanceId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRegistrationInstance>>,
        TError,
        {registrationInstanceId: number},
        TContext
      > => {

      const mutationOptions = getDeleteRegistrationInstanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getRegistrationInstances = (
    workgroupId: number,
    registrationFormId: number,
    year: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationInstanceResponse[]>(
      {url: `/api/core/registration-instances/search/${workgroupId}/${registrationFormId}/${year}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationInstancesQueryKey = (workgroupId: number,
    registrationFormId: number,
    year: number,) => {
    return [`/api/core/registration-instances/search/${workgroupId}/${registrationFormId}/${year}`] as const;
    }

    
export const getGetRegistrationInstancesQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationInstances>>, TError = unknown>(workgroupId: number,
    registrationFormId: number,
    year: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstances>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationInstancesQueryKey(workgroupId,registrationFormId,year);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationInstances>>> = ({ signal }) => getRegistrationInstances(workgroupId,registrationFormId,year, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId && registrationFormId && year), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstances>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationInstancesQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationInstances>>>
export type GetRegistrationInstancesQueryError = unknown



export function useGetRegistrationInstances<TData = Awaited<ReturnType<typeof getRegistrationInstances>>, TError = unknown>(
 workgroupId: number,
    registrationFormId: number,
    year: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationInstances>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationInstancesQueryOptions(workgroupId,registrationFormId,year,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRegistrationFormById = (
    registrationFormId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationFormResponse>(
      {url: `/api/core/registration-forms/${registrationFormId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationFormByIdQueryKey = (registrationFormId: number,) => {
    return [`/api/core/registration-forms/${registrationFormId}`] as const;
    }

    
export const getGetRegistrationFormByIdQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationFormById>>, TError = unknown>(registrationFormId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationFormByIdQueryKey(registrationFormId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationFormById>>> = ({ signal }) => getRegistrationFormById(registrationFormId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(registrationFormId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationFormByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationFormById>>>
export type GetRegistrationFormByIdQueryError = unknown



export function useGetRegistrationFormById<TData = Awaited<ReturnType<typeof getRegistrationFormById>>, TError = unknown>(
 registrationFormId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationFormByIdQueryOptions(registrationFormId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteRegistrationForm = (
    registrationFormId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/registration-forms/${registrationFormId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteRegistrationFormMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationForm>>, TError,{registrationFormId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationForm>>, TError,{registrationFormId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRegistrationForm>>, {registrationFormId: number}> = (props) => {
          const {registrationFormId} = props ?? {};

          return  deleteRegistrationForm(registrationFormId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRegistrationFormMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRegistrationForm>>>
    
    export type DeleteRegistrationFormMutationError = unknown

    export const useDeleteRegistrationForm = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationForm>>, TError,{registrationFormId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRegistrationForm>>,
        TError,
        {registrationFormId: number},
        TContext
      > => {

      const mutationOptions = getDeleteRegistrationFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getRegistrationFormsByWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationFormResponse[]>(
      {url: `/api/core/registration-forms/workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationFormsByWorkgroupQueryKey = (workgroupId: number,) => {
    return [`/api/core/registration-forms/workgroup/${workgroupId}`] as const;
    }

    
export const getGetRegistrationFormsByWorkgroupQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationFormsByWorkgroupQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>> = ({ signal }) => getRegistrationFormsByWorkgroup(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationFormsByWorkgroupQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>>
export type GetRegistrationFormsByWorkgroupQueryError = unknown



export function useGetRegistrationFormsByWorkgroup<TData = Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormsByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationFormsByWorkgroupQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRegistrationFormOverridesByWorkgroupId = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationFormOverrideResponse[]>(
      {url: `/api/core/registration-form-overrides/by-workgroup/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationFormOverridesByWorkgroupIdQueryKey = (workgroupId: number,) => {
    return [`/api/core/registration-form-overrides/by-workgroup/${workgroupId}`] as const;
    }

    
export const getGetRegistrationFormOverridesByWorkgroupIdQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationFormOverridesByWorkgroupIdQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>> = ({ signal }) => getRegistrationFormOverridesByWorkgroupId(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationFormOverridesByWorkgroupIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>>
export type GetRegistrationFormOverridesByWorkgroupIdQueryError = unknown



export function useGetRegistrationFormOverridesByWorkgroupId<TData = Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByWorkgroupId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationFormOverridesByWorkgroupIdQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getRegistrationFormOverridesByRegistrationFormId = (
    registrationFormId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RegistrationFormOverrideResponse[]>(
      {url: `/api/core/registration-form-overrides/by-registration-form/${registrationFormId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRegistrationFormOverridesByRegistrationFormIdQueryKey = (registrationFormId: number,) => {
    return [`/api/core/registration-form-overrides/by-registration-form/${registrationFormId}`] as const;
    }

    
export const getGetRegistrationFormOverridesByRegistrationFormIdQueryOptions = <TData = Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>, TError = unknown>(registrationFormId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegistrationFormOverridesByRegistrationFormIdQueryKey(registrationFormId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>> = ({ signal }) => getRegistrationFormOverridesByRegistrationFormId(registrationFormId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(registrationFormId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRegistrationFormOverridesByRegistrationFormIdQueryResult = NonNullable<Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>>
export type GetRegistrationFormOverridesByRegistrationFormIdQueryError = unknown



export function useGetRegistrationFormOverridesByRegistrationFormId<TData = Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>, TError = unknown>(
 registrationFormId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegistrationFormOverridesByRegistrationFormId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRegistrationFormOverridesByRegistrationFormIdQueryOptions(registrationFormId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTest = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/public/test`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTestQueryKey = () => {
    return [`/api/core/public/test`] as const;
    }

    
export const getGetTestQueryOptions = <TData = Awaited<ReturnType<typeof getTest>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTestQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTest>>> = ({ signal }) => getTest(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTestQueryResult = NonNullable<Awaited<ReturnType<typeof getTest>>>
export type GetTestQueryError = unknown



export function useGetTest<TData = Awaited<ReturnType<typeof getTest>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTestQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getExternalUserById = (
    externalUserId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalUserResponse>(
      {url: `/api/core/public/test/user/${externalUserId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalUserByIdQueryKey = (externalUserId: number,) => {
    return [`/api/core/public/test/user/${externalUserId}`] as const;
    }

    
export const getGetExternalUserByIdQueryOptions = <TData = Awaited<ReturnType<typeof getExternalUserById>>, TError = unknown>(externalUserId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalUserByIdQueryKey(externalUserId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalUserById>>> = ({ signal }) => getExternalUserById(externalUserId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(externalUserId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalUserByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalUserById>>>
export type GetExternalUserByIdQueryError = unknown



export function useGetExternalUserById<TData = Awaited<ReturnType<typeof getExternalUserById>>, TError = unknown>(
 externalUserId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalUserByIdQueryOptions(externalUserId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getExternalUserByEmail = (
    email: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalUserResponse>(
      {url: `/api/core/public/test/user/email/${email}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalUserByEmailQueryKey = (email: string,) => {
    return [`/api/core/public/test/user/email/${email}`] as const;
    }

    
export const getGetExternalUserByEmailQueryOptions = <TData = Awaited<ReturnType<typeof getExternalUserByEmail>>, TError = unknown>(email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserByEmail>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalUserByEmailQueryKey(email);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalUserByEmail>>> = ({ signal }) => getExternalUserByEmail(email, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(email), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalUserByEmail>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalUserByEmailQueryResult = NonNullable<Awaited<ReturnType<typeof getExternalUserByEmail>>>
export type GetExternalUserByEmailQueryError = unknown



export function useGetExternalUserByEmail<TData = Awaited<ReturnType<typeof getExternalUserByEmail>>, TError = unknown>(
 email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserByEmail>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalUserByEmailQueryOptions(email,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getEncodedPassword = (
    params: GetEncodedPasswordParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/public/test/get-encode`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEncodedPasswordQueryKey = (params: GetEncodedPasswordParams,) => {
    return [`/api/core/public/test/get-encode`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEncodedPasswordQueryOptions = <TData = Awaited<ReturnType<typeof getEncodedPassword>>, TError = unknown>(params: GetEncodedPasswordParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEncodedPassword>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEncodedPasswordQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEncodedPassword>>> = ({ signal }) => getEncodedPassword(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEncodedPassword>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEncodedPasswordQueryResult = NonNullable<Awaited<ReturnType<typeof getEncodedPassword>>>
export type GetEncodedPasswordQueryError = unknown



export function useGetEncodedPassword<TData = Awaited<ReturnType<typeof getEncodedPassword>>, TError = unknown>(
 params: GetEncodedPasswordParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEncodedPassword>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEncodedPasswordQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPersonById = (
    personId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PersonResponse>(
      {url: `/api/core/persons/${personId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPersonByIdQueryKey = (personId: number,) => {
    return [`/api/core/persons/${personId}`] as const;
    }

    
export const getGetPersonByIdQueryOptions = <TData = Awaited<ReturnType<typeof getPersonById>>, TError = unknown>(personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonByIdQueryKey(personId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonById>>> = ({ signal }) => getPersonById(personId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(personId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersonById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonById>>>
export type GetPersonByIdQueryError = unknown



export function useGetPersonById<TData = Awaited<ReturnType<typeof getPersonById>>, TError = unknown>(
 personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPersonByIdQueryOptions(personId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deletePerson = (
    personId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/persons/${personId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePersonMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePerson>>, TError,{personId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePerson>>, TError,{personId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePerson>>, {personId: number}> = (props) => {
          const {personId} = props ?? {};

          return  deletePerson(personId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePersonMutationResult = NonNullable<Awaited<ReturnType<typeof deletePerson>>>
    
    export type DeletePersonMutationError = unknown

    export const useDeletePerson = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePerson>>, TError,{personId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePerson>>,
        TError,
        {personId: number},
        TContext
      > => {

      const mutationOptions = getDeletePersonMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPersonsByWorkgroup = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PersonResponse[]>(
      {url: `/api/core/persons/workgroups/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPersonsByWorkgroupQueryKey = (workgroupId: number,) => {
    return [`/api/core/persons/workgroups/${workgroupId}`] as const;
    }

    
export const getGetPersonsByWorkgroupQueryOptions = <TData = Awaited<ReturnType<typeof getPersonsByWorkgroup>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonsByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonsByWorkgroupQueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersonsByWorkgroup>>> = ({ signal }) => getPersonsByWorkgroup(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersonsByWorkgroup>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonsByWorkgroupQueryResult = NonNullable<Awaited<ReturnType<typeof getPersonsByWorkgroup>>>
export type GetPersonsByWorkgroupQueryError = unknown



export function useGetPersonsByWorkgroup<TData = Awaited<ReturnType<typeof getPersonsByWorkgroup>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersonsByWorkgroup>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPersonsByWorkgroupQueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPersons = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PersonResponse[]>(
      {url: `/api/core/persons/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPersonsQueryKey = (organizationId: number,) => {
    return [`/api/core/persons/organizations/${organizationId}`] as const;
    }

    
export const getGetPersonsQueryOptions = <TData = Awaited<ReturnType<typeof getPersons>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersons>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersonsQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersons>>> = ({ signal }) => getPersons(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersons>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersonsQueryResult = NonNullable<Awaited<ReturnType<typeof getPersons>>>
export type GetPersonsQueryError = unknown



export function useGetPersons<TData = Awaited<ReturnType<typeof getPersons>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersons>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPersonsQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getOrganizationById = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationResponse>(
      {url: `/api/core/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationByIdQueryKey = (organizationId: number,) => {
    return [`/api/core/organizations/${organizationId}`] as const;
    }

    
export const getGetOrganizationByIdQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationById>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationByIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationById>>> = ({ signal }) => getOrganizationById(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationById>>>
export type GetOrganizationByIdQueryError = unknown



export function useGetOrganizationById<TData = Awaited<ReturnType<typeof getOrganizationById>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOrganizationByIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/organizations/${organizationId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOrganizationMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{organizationId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{organizationId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganization>>, {organizationId: number}> = (props) => {
          const {organizationId} = props ?? {};

          return  deleteOrganization(organizationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganization>>>
    
    export type DeleteOrganizationMutationError = unknown

    export const useDeleteOrganization = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{organizationId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteOrganization>>,
        TError,
        {organizationId: number},
        TContext
      > => {

      const mutationOptions = getDeleteOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getOrganizationTemplates = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationResponse[]>(
      {url: `/api/core/organizations/templates`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationTemplatesQueryKey = () => {
    return [`/api/core/organizations/templates`] as const;
    }

    
export const getGetOrganizationTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationTemplates>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationTemplates>>> = ({ signal }) => getOrganizationTemplates(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationTemplates>>>
export type GetOrganizationTemplatesQueryError = unknown



export function useGetOrganizationTemplates<TData = Awaited<ReturnType<typeof getOrganizationTemplates>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplates>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOrganizationTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getOrganizationTemplatesByOrganizationType = (
    organizationTypeId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationResponse[]>(
      {url: `/api/core/organizations/templates/${organizationTypeId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationTemplatesByOrganizationTypeQueryKey = (organizationTypeId: number,) => {
    return [`/api/core/organizations/templates/${organizationTypeId}`] as const;
    }

    
export const getGetOrganizationTemplatesByOrganizationTypeQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>, TError = unknown>(organizationTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationTemplatesByOrganizationTypeQueryKey(organizationTypeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>> = ({ signal }) => getOrganizationTemplatesByOrganizationType(organizationTypeId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationTypeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationTemplatesByOrganizationTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>>
export type GetOrganizationTemplatesByOrganizationTypeQueryError = unknown



export function useGetOrganizationTemplatesByOrganizationType<TData = Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>, TError = unknown>(
 organizationTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTemplatesByOrganizationType>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOrganizationTemplatesByOrganizationTypeQueryOptions(organizationTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getOrganizationTypes = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<OrganizationTypeResponse[]>(
      {url: `/api/core/organization-types`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationTypesQueryKey = () => {
    return [`/api/core/organization-types`] as const;
    }

    
export const getGetOrganizationTypesQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationTypes>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationTypes>>> = ({ signal }) => getOrganizationTypes(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrganizationTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationTypes>>>
export type GetOrganizationTypesQueryError = unknown



export function useGetOrganizationTypes<TData = Awaited<ReturnType<typeof getOrganizationTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganizationTypes>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetOrganizationTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getFormById = (
    formId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FormResponse>(
      {url: `/api/core/forms/${formId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFormByIdQueryKey = (formId: number,) => {
    return [`/api/core/forms/${formId}`] as const;
    }

    
export const getGetFormByIdQueryOptions = <TData = Awaited<ReturnType<typeof getFormById>>, TError = unknown>(formId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFormById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFormByIdQueryKey(formId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFormById>>> = ({ signal }) => getFormById(formId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(formId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFormById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFormByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getFormById>>>
export type GetFormByIdQueryError = unknown



export function useGetFormById<TData = Awaited<ReturnType<typeof getFormById>>, TError = unknown>(
 formId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFormById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFormByIdQueryOptions(formId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteForm = (
    formId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/forms/${formId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFormMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteForm>>, TError,{formId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteForm>>, TError,{formId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteForm>>, {formId: number}> = (props) => {
          const {formId} = props ?? {};

          return  deleteForm(formId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteFormMutationResult = NonNullable<Awaited<ReturnType<typeof deleteForm>>>
    
    export type DeleteFormMutationError = unknown

    export const useDeleteForm = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteForm>>, TError,{formId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteForm>>,
        TError,
        {formId: number},
        TContext
      > => {

      const mutationOptions = getDeleteFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getFormsByOrganization = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<FormResponse[]>(
      {url: `/api/core/forms/org/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFormsByOrganizationQueryKey = (organizationId: number,) => {
    return [`/api/core/forms/org/${organizationId}`] as const;
    }

    
export const getGetFormsByOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getFormsByOrganization>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFormsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFormsByOrganizationQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFormsByOrganization>>> = ({ signal }) => getFormsByOrganization(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFormsByOrganization>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFormsByOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getFormsByOrganization>>>
export type GetFormsByOrganizationQueryError = unknown



export function useGetFormsByOrganization<TData = Awaited<ReturnType<typeof getFormsByOrganization>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFormsByOrganization>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetFormsByOrganizationQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getTeams = (
    organizationId: number,
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/form-choices/teams/${organizationId}/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTeamsQueryKey = (organizationId: number,
    workgroupId: number,) => {
    return [`/api/core/form-choices/teams/${organizationId}/${workgroupId}`] as const;
    }

    
export const getGetTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getTeams>>, TError = unknown>(organizationId: number,
    workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTeamsQueryKey(organizationId,workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({ signal }) => getTeams(organizationId,workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId && workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>
export type GetTeamsQueryError = unknown



export function useGetTeams<TData = Awaited<ReturnType<typeof getTeams>>, TError = unknown>(
 organizationId: number,
    workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTeamsQueryOptions(organizationId,workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getStateProvinces1 = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/form-choices/states`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStateProvinces1QueryKey = () => {
    return [`/api/core/form-choices/states`] as const;
    }

    
export const getGetStateProvinces1QueryOptions = <TData = Awaited<ReturnType<typeof getStateProvinces1>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStateProvinces1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStateProvinces1>>> = ({ signal }) => getStateProvinces1(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStateProvinces1QueryResult = NonNullable<Awaited<ReturnType<typeof getStateProvinces1>>>
export type GetStateProvinces1QueryError = unknown



export function useGetStateProvinces1<TData = Awaited<ReturnType<typeof getStateProvinces1>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getStateProvinces1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStateProvinces1QueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getPersons1 = (
    workgroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/form-choices/persons/${workgroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPersons1QueryKey = (workgroupId: number,) => {
    return [`/api/core/form-choices/persons/${workgroupId}`] as const;
    }

    
export const getGetPersons1QueryOptions = <TData = Awaited<ReturnType<typeof getPersons1>>, TError = unknown>(workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersons1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPersons1QueryKey(workgroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPersons1>>> = ({ signal }) => getPersons1(workgroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(workgroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPersons1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPersons1QueryResult = NonNullable<Awaited<ReturnType<typeof getPersons1>>>
export type GetPersons1QueryError = unknown



export function useGetPersons1<TData = Awaited<ReturnType<typeof getPersons1>>, TError = unknown>(
 workgroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPersons1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPersons1QueryOptions(workgroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCountries = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/core/form-choices/countries`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCountriesQueryKey = () => {
    return [`/api/core/form-choices/countries`] as const;
    }

    
export const getGetCountriesQueryOptions = <TData = Awaited<ReturnType<typeof getCountries>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountriesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountries>>> = ({ signal }) => getCountries(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getCountries>>>
export type GetCountriesQueryError = unknown



export function useGetCountries<TData = Awaited<ReturnType<typeof getCountries>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountries>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCountriesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getExternalUserById1 = (
    externalUserId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ExternalUserResponse>(
      {url: `/api/core/external-users/${externalUserId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetExternalUserById1QueryKey = (externalUserId: number,) => {
    return [`/api/core/external-users/${externalUserId}`] as const;
    }

    
export const getGetExternalUserById1QueryOptions = <TData = Awaited<ReturnType<typeof getExternalUserById1>>, TError = unknown>(externalUserId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExternalUserById1QueryKey(externalUserId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExternalUserById1>>> = ({ signal }) => getExternalUserById1(externalUserId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(externalUserId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExternalUserById1QueryResult = NonNullable<Awaited<ReturnType<typeof getExternalUserById1>>>
export type GetExternalUserById1QueryError = unknown



export function useGetExternalUserById1<TData = Awaited<ReturnType<typeof getExternalUserById1>>, TError = unknown>(
 externalUserId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExternalUserById1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetExternalUserById1QueryOptions(externalUserId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteExternalUser = (
    externalUserId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/external-users/${externalUserId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteExternalUserMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalUser>>, TError,{externalUserId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteExternalUser>>, TError,{externalUserId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExternalUser>>, {externalUserId: number}> = (props) => {
          const {externalUserId} = props ?? {};

          return  deleteExternalUser(externalUserId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteExternalUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExternalUser>>>
    
    export type DeleteExternalUserMutationError = unknown

    export const useDeleteExternalUser = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExternalUser>>, TError,{externalUserId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteExternalUser>>,
        TError,
        {externalUserId: number},
        TContext
      > => {

      const mutationOptions = getDeleteExternalUserMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEntityGroupById = (
    entityGroupId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EntityGroupResponse>(
      {url: `/api/core/entity-groups/${entityGroupId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEntityGroupByIdQueryKey = (entityGroupId: number,) => {
    return [`/api/core/entity-groups/${entityGroupId}`] as const;
    }

    
export const getGetEntityGroupByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEntityGroupById>>, TError = unknown>(entityGroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEntityGroupByIdQueryKey(entityGroupId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntityGroupById>>> = ({ signal }) => getEntityGroupById(entityGroupId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(entityGroupId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEntityGroupByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEntityGroupById>>>
export type GetEntityGroupByIdQueryError = unknown



export function useGetEntityGroupById<TData = Awaited<ReturnType<typeof getEntityGroupById>>, TError = unknown>(
 entityGroupId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEntityGroupByIdQueryOptions(entityGroupId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEntityGroup = (
    entityGroupId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/entity-groups/${entityGroupId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEntityGroupMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroup>>, TError,{entityGroupId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroup>>, TError,{entityGroupId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEntityGroup>>, {entityGroupId: number}> = (props) => {
          const {entityGroupId} = props ?? {};

          return  deleteEntityGroup(entityGroupId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEntityGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEntityGroup>>>
    
    export type DeleteEntityGroupMutationError = unknown

    export const useDeleteEntityGroup = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroup>>, TError,{entityGroupId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEntityGroup>>,
        TError,
        {entityGroupId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEntityGroupMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getEntityGroupMemberById = (
    entityGroupMemberId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<EntityGroupMemberResponse>(
      {url: `/api/core/entity-group-members/${entityGroupMemberId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetEntityGroupMemberByIdQueryKey = (entityGroupMemberId: number,) => {
    return [`/api/core/entity-group-members/${entityGroupMemberId}`] as const;
    }

    
export const getGetEntityGroupMemberByIdQueryOptions = <TData = Awaited<ReturnType<typeof getEntityGroupMemberById>>, TError = unknown>(entityGroupMemberId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupMemberById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEntityGroupMemberByIdQueryKey(entityGroupMemberId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntityGroupMemberById>>> = ({ signal }) => getEntityGroupMemberById(entityGroupMemberId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(entityGroupMemberId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupMemberById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEntityGroupMemberByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEntityGroupMemberById>>>
export type GetEntityGroupMemberByIdQueryError = unknown



export function useGetEntityGroupMemberById<TData = Awaited<ReturnType<typeof getEntityGroupMemberById>>, TError = unknown>(
 entityGroupMemberId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEntityGroupMemberById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetEntityGroupMemberByIdQueryOptions(entityGroupMemberId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteEntityGroupMember = (
    entityGroupMemberId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/entity-group-members/${entityGroupMemberId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteEntityGroupMemberMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroupMember>>, TError,{entityGroupMemberId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroupMember>>, TError,{entityGroupMemberId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEntityGroupMember>>, {entityGroupMemberId: number}> = (props) => {
          const {entityGroupMemberId} = props ?? {};

          return  deleteEntityGroupMember(entityGroupMemberId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteEntityGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEntityGroupMember>>>
    
    export type DeleteEntityGroupMemberMutationError = unknown

    export const useDeleteEntityGroupMember = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEntityGroupMember>>, TError,{entityGroupMemberId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteEntityGroupMember>>,
        TError,
        {entityGroupMemberId: number},
        TContext
      > => {

      const mutationOptions = getDeleteEntityGroupMemberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getCurrentUser = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CurrentUserResponse>(
      {url: `/api/core/current-user`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCurrentUserQueryKey = () => {
    return [`/api/core/current-user`] as const;
    }

    
export const getGetCurrentUserQueryOptions = <TData = Awaited<ReturnType<typeof getCurrentUser>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrentUserQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUser>>> = ({ signal }) => getCurrentUser(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrentUserQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentUser>>>
export type GetCurrentUserQueryError = unknown



export function useGetCurrentUser<TData = Awaited<ReturnType<typeof getCurrentUser>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentUser>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCurrentUserQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getCountries1 = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CountryResponse[]>(
      {url: `/api/core/countries`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCountries1QueryKey = () => {
    return [`/api/core/countries`] as const;
    }

    
export const getGetCountries1QueryOptions = <TData = Awaited<ReturnType<typeof getCountries1>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountries1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCountries1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCountries1>>> = ({ signal }) => getCountries1(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCountries1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCountries1QueryResult = NonNullable<Awaited<ReturnType<typeof getCountries1>>>
export type GetCountries1QueryError = unknown



export function useGetCountries1<TData = Awaited<ReturnType<typeof getCountries1>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCountries1>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCountries1QueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAddressById = (
    addressId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AddressResponse>(
      {url: `/api/core/addresses/${addressId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAddressByIdQueryKey = (addressId: number,) => {
    return [`/api/core/addresses/${addressId}`] as const;
    }

    
export const getGetAddressByIdQueryOptions = <TData = Awaited<ReturnType<typeof getAddressById>>, TError = unknown>(addressId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAddressById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAddressByIdQueryKey(addressId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAddressById>>> = ({ signal }) => getAddressById(addressId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(addressId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAddressById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAddressByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAddressById>>>
export type GetAddressByIdQueryError = unknown



export function useGetAddressById<TData = Awaited<ReturnType<typeof getAddressById>>, TError = unknown>(
 addressId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAddressById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAddressByIdQueryOptions(addressId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteAddress = (
    addressId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/addresses/${addressId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAddressMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAddress>>, {addressId: number}> = (props) => {
          const {addressId} = props ?? {};

          return  deleteAddress(addressId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAddressMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAddress>>>
    
    export type DeleteAddressMutationError = unknown

    export const useDeleteAddress = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAddress>>, TError,{addressId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAddress>>,
        TError,
        {addressId: number},
        TContext
      > => {

      const mutationOptions = getDeleteAddressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteYearDefinition = (
    yearDefinitionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/year-definitions/${yearDefinitionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteYearDefinitionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteYearDefinition>>, TError,{yearDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteYearDefinition>>, TError,{yearDefinitionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteYearDefinition>>, {yearDefinitionId: number}> = (props) => {
          const {yearDefinitionId} = props ?? {};

          return  deleteYearDefinition(yearDefinitionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteYearDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteYearDefinition>>>
    
    export type DeleteYearDefinitionMutationError = unknown

    export const useDeleteYearDefinition = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteYearDefinition>>, TError,{yearDefinitionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteYearDefinition>>,
        TError,
        {yearDefinitionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteYearDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const deleteRegistrationFormOverride = (
    registrationFormOverrideId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/core/registration-form-overrides/${registrationFormOverrideId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteRegistrationFormOverrideMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationFormOverride>>, TError,{registrationFormOverrideId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationFormOverride>>, TError,{registrationFormOverrideId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRegistrationFormOverride>>, {registrationFormOverrideId: number}> = (props) => {
          const {registrationFormOverrideId} = props ?? {};

          return  deleteRegistrationFormOverride(registrationFormOverrideId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteRegistrationFormOverrideMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRegistrationFormOverride>>>
    
    export type DeleteRegistrationFormOverrideMutationError = unknown

    export const useDeleteRegistrationFormOverride = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegistrationFormOverride>>, TError,{registrationFormOverrideId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteRegistrationFormOverride>>,
        TError,
        {registrationFormOverrideId: number},
        TContext
      > => {

      const mutationOptions = getDeleteRegistrationFormOverrideMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
