import {
  RegistrationFormOverrideRequest,
  RegistrationFormRequest,
  useSaveRegistrationForm,
  useSaveRegistrationFormOverride,
  WorkgroupResponse,
} from '@btrway/api-core';
import { ScrollableDrawer } from '@btrway/scrollable-drawer';
import { Button, Group, ScrollArea } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import { useRegistrationFormAdministration } from '../../hooks/useRegistrationFormAdministration';
import { RegistrationFormEdit } from '../RegistrationFormEdit/RegistrationFormEdit';
import { RegistrationFormsList } from '../RegistrationFormsList/RegistrationFormsList';

interface RegistrationFormAdministrationDrawerProps {
  opened: boolean;
  onClose: () => void;
  workgroup: WorkgroupResponse;
}

export function RegistrationFormAdministrationDrawer({
  opened,
  onClose,
  workgroup,
}: RegistrationFormAdministrationDrawerProps) {
  const [selectedFormId, setSelectedFormId] = useState<number | null>(null);
  const { forms, isLoading, refetchForms } = useRegistrationFormAdministration(
    workgroup.id
  );

  const { mutateAsync: saveRegistrationForm } = useSaveRegistrationForm();
  const { mutateAsync: saveRegistrationFormOverride } =
    useSaveRegistrationFormOverride();

  const handleFormSelect = (formId: number | null) => {
    setSelectedFormId(formId);
  };

  const handleAdd = () => {
    setSelectedFormId(-1);
  };

  const handleSave = async (
    updatedForm: RegistrationFormRequest | RegistrationFormOverrideRequest
  ) => {
    try {
      const selectedForm = forms.find((form) => form.id === selectedFormId);

      if (!selectedForm || selectedForm.workgroup.id === workgroup.id) {
        await saveRegistrationForm({
          data: updatedForm as RegistrationFormRequest,
        });
      } else {
        const overrideRequest: RegistrationFormOverrideRequest = {
          ...(updatedForm as RegistrationFormOverrideRequest),
          registrationFormId: selectedForm.id,
          workgroupId: workgroup.id,
        };
        await saveRegistrationFormOverride({ data: overrideRequest });
      }

      await refetchForms();
      setSelectedFormId(null);
    } catch (error) {
      console.error('Error saving registration form:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const title =
    selectedFormId === null
      ? 'Registration Forms'
      : selectedFormId === -1
      ? 'Add Registration Form'
      : 'Edit Registration Form';

  return (
    <ScrollableDrawer opened={opened} onClose={onClose} title={title}>
      {selectedFormId === null ? (
        <>
          <Group justify="flex-end" mb="md">
            <Button leftSection={<IconPlus size={16} />} onClick={handleAdd}>
              Add Registration Form
            </Button>
          </Group>
          <ScrollArea>
            <RegistrationFormsList
              forms={forms}
              isLoading={isLoading}
              onSelectForm={handleFormSelect}
              workgroup={workgroup}
            />
          </ScrollArea>
        </>
      ) : (
        <RegistrationFormEdit
          formId={selectedFormId}
          workgroup={workgroup}
          onSave={handleSave}
          onCancel={() => setSelectedFormId(null)}
        />
      )}
    </ScrollableDrawer>
  );
}
