import { useMemo } from 'react';

// Constants for APS range
const APS_MIN = 20;
const APS_MID = 50;
const APS_MAX = 100;

// Extended color scale with 17 variations
const extendedColorScale = [
  'red.9',
  'red.8',
  'orange.9',
  'orange.8',
  'orange.7',
  'orange.6',
  'orange.5',
  'yellow.7',
  'yellow.6',
  'yellow.5',
  'lime.5',
  'lime.6',
  'green.6',
  'green.7',
  'green.8',
  'green.9',
  'teal.9',
];

interface ColorThreshold {
  threshold: number;
  color: string;
}

// Function to generate color thresholds based on the number of steps
const generateColorThresholds = (colorSteps: number): ColorThreshold[] => {
  const actualSteps = Math.min(colorSteps, extendedColorScale.length);
  const lowerSteps = Math.floor(actualSteps / 2);
  const upperSteps = actualSteps - lowerSteps;

  const lowerThresholds = Array.from({ length: lowerSteps }, (_, i) => {
    const t = i / lowerSteps;
    return {
      threshold: APS_MIN + (APS_MID - APS_MIN) * t,
      color: extendedColorScale[i],
    };
  });

  const upperThresholds = Array.from({ length: upperSteps }, (_, i) => {
    const t = i / (upperSteps - 1);
    return {
      threshold: APS_MID + (APS_MAX - APS_MID) * t,
      color: extendedColorScale[i + lowerSteps],
    };
  });

  return [...lowerThresholds, ...upperThresholds];
};

export const usePerformanceColor = (colorSteps: number = 17) => {
  const colorThresholds = useMemo(
    () => generateColorThresholds(colorSteps),
    [colorSteps]
  );

  const getColorFromAPS = (aps: number): string => {
    for (const { threshold, color } of colorThresholds) {
      if (aps <= threshold) {
        return color;
      }
    }
    return colorThresholds[colorThresholds.length - 1].color;
  };

  return { getColorFromAPS };
};
