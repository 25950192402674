import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';
import ChildInfo from '../ChildInfo/ChildInfo';

const ChildrenInfo: React.FC = () => {
  const { registration, addChild, removePerson } = useRegistrationSubmission();

  const children = registration.persons.filter((person) => person.isDependent);

  const handleDeletePerson = (personKey: string) => {
    removePerson(personKey);
  };

  return (
    <Stack gap="md">
      <Card withBorder radius="sm" padding="sm" shadow="none" bg="yellow.1">
        <Text size="lg" fw={500}>
          Provide the names and contact information for your children or
          dependents.
        </Text>
      </Card>

      {children.map((child, index) => (
        <ChildInfo
          key={child.personKey}
          childKey={child.personKey}
          childIndex={index + 1}
          onDelete={handleDeletePerson}
        />
      ))}
      <Group justify="center">
        <Button variant="light" onClick={addChild}>
          + Add Another Child/Dependent
        </Button>
      </Group>
    </Stack>
  );
};

export default ChildrenInfo;
