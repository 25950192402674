import { CertificationListView } from '@btrway/task-list-builder';
import { WorkflowServiceProvider } from '@btrway/workflow-configuration-manager';
import { Card, ScrollArea } from '@mantine/core';
import React from 'react';

interface CertificationDetailProps {
  workflowKey: string;
}

export const CertificationDetail: React.FC<CertificationDetailProps> = ({
  workflowKey,
}) => {
  return (
    <Card mt="lg" bg="gray.3" withBorder p={0} radius="md">
      <ScrollArea h={300} offsetScrollbars type="auto" pl="xs" pt={0} pb={0}>
        <WorkflowServiceProvider workflowKey={workflowKey}>
          <CertificationListView
            workflowKey={workflowKey}
            onCertificationSelected={(certificationKey) => {}}
            onCertificationDeleted={(certificationKey) => {}}
          />
        </WorkflowServiceProvider>
      </ScrollArea>
    </Card>
  );
};
