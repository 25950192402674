// src/utils/saveHandler.ts

import {
  CertificationConfig,
  EventConfig,
  StepConfig,
  TaskConfig,
} from '@btrway/api-workflow';
import { WorkflowItemConfig } from '../types/state';

/**
 * Creates a type-safe save handler for different editor components.
 * Wraps the specific config type into a WorkflowItemConfig for unified state management.
 *
 * @param onSave - The unified save handler that accepts WorkflowItemConfig
 * @param type - The type of configuration being saved
 * @returns A properly typed save handler for the specific editor component
 */
export const createEditorSaveHandler = {
  task: (onSave: (itemConfig: WorkflowItemConfig) => Promise<void>) => {
    return async (config: TaskConfig, isNew?: boolean) => {
      const itemConfig: WorkflowItemConfig = {
        type: 'task',
        config,
      };
      await onSave(itemConfig);
    };
  },

  certification: (
    onSave: (itemConfig: WorkflowItemConfig) => Promise<void>
  ) => {
    return async (config: CertificationConfig, isNew?: boolean) => {
      const itemConfig: WorkflowItemConfig = {
        type: 'certification',
        config,
      };
      await onSave(itemConfig);
    };
  },

  step: (onSave: (itemConfig: WorkflowItemConfig) => Promise<void>) => {
    return async (config: StepConfig, isNew?: boolean) => {
      const itemConfig: WorkflowItemConfig = {
        type: 'step',
        config,
      };
      await onSave(itemConfig);
    };
  },

  event: (onSave: (itemConfig: WorkflowItemConfig) => Promise<void>) => {
    return async (config: EventConfig, isNew?: boolean) => {
      const itemConfig: WorkflowItemConfig = {
        type: 'event',
        config,
      };
      await onSave(itemConfig);
    };
  },
};
