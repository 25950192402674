import {
  useGetFilteredEvents,
  type EventRequest,
  type EventResponse,
} from '@btrway/api-calendar';
import { CalendarEventModal } from '@btrway/calendar-event-editor';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  ActionIcon,
  Box,
  Card,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
  IconClock,
  IconPlus,
} from '@tabler/icons-react';
import {
  addDays,
  addWeeks,
  endOfWeek,
  format,
  isToday,
  isTomorrow,
  startOfWeek,
  subWeeks,
} from 'date-fns';
import { useEffect, useState } from 'react';
import styles from './CalendarWidget.module.css';

interface CalendarWidgetAddButtonProps {
  selectedDate: Date;
  onClick: (date: Date) => void;
}

const CalendarWidgetAddButton = ({
  selectedDate,
  onClick,
}: CalendarWidgetAddButtonProps) => {
  return (
    <ActionIcon
      variant="light"
      //   bg="gray.5"
      onClick={() => onClick(selectedDate)}
      size="md"
    >
      <IconPlus size={18} stroke={2} />
    </ActionIcon>
  );
};

const WEEK_START_DAY = 1; // 0 = Sunday, 1 = Monday, etc.
const DAYS_OF_WEEK = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const CalendarWidget = () => {
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(today);
  const [weekStart, setWeekStart] = useState<Date>(() =>
    startOfWeek(today, { weekStartsOn: WEEK_START_DAY })
  );
  const [events, setEvents] = useState<EventResponse[]>([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedEventId, setSelectedEventId] = useState<number | undefined>();
  const [newEventConfig, setNewEventConfig] = useState<Partial<EventRequest>>();

  const { currentOrganization, currentPerson } = useAuthenticatedUser();
  const { mutate: getEvents, data: eventData } = useGetFilteredEvents();

  useEffect(() => {
    const start = startOfWeek(selectedDate, { weekStartsOn: WEEK_START_DAY });
    const end = endOfWeek(selectedDate, { weekStartsOn: WEEK_START_DAY });

    getEvents({
      data: {
        organizationId: currentOrganization.id,
        personId: currentPerson.id,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      },
    });
  }, [selectedDate, getEvents, currentOrganization.id, currentPerson.id]);

  useEffect(() => {
    if (eventData) {
      setEvents(eventData);
    }
  }, [eventData]);

  const getHeaderText = () => {
    if (isToday(selectedDate)) return 'Today';
    if (isTomorrow(selectedDate)) return 'Tomorrow';
    return format(selectedDate, 'MMMM do');
  };

  const getDayEvents = () => {
    const dayEvents = events
      .filter((event: EventResponse) => {
        const eventDate = new Date(event.startTime);
        return (
          format(eventDate, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd')
        );
      })
      .sort((a: EventResponse, b: EventResponse) => {
        if (a.allDayEvent && !b.allDayEvent) return -1;
        if (!a.allDayEvent && b.allDayEvent) return 1;
        return (
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
        );
      });

    return dayEvents;
  };

  const handlePrevWeek = () => {
    const newWeekStart = subWeeks(weekStart, 1);
    setWeekStart(newWeekStart);
    setSelectedDate(newWeekStart);
  };

  const handleNextWeek = () => {
    const newWeekStart = addWeeks(weekStart, 1);
    setWeekStart(newWeekStart);
    setSelectedDate(newWeekStart);
  };

  const handleAddClick = (date: Date) => {
    setSelectedEventId(undefined);
    const config: Partial<EventRequest> = {
      startTime: date.toISOString(),
      endTime: date.toISOString(),
      allDayEvent: true,
      organizationId: currentOrganization.id,
      workgroupId: undefined,
    };
    setNewEventConfig(config);
    open();
  };

  const handleEventClick = (eventId: number) => {
    setSelectedEventId(eventId);
    setNewEventConfig(undefined);
    open();
  };

  const handleCloseModal = () => {
    setSelectedEventId(undefined);
    setNewEventConfig(undefined);
    close();
  };

  const handleSaveEvent = () => {
    // Refresh events after save
    const start = startOfWeek(selectedDate, { weekStartsOn: WEEK_START_DAY });
    const end = endOfWeek(selectedDate, { weekStartsOn: WEEK_START_DAY });

    getEvents({
      data: {
        organizationId: currentOrganization.id,
        personId: currentPerson.id,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      },
    });

    handleCloseModal();
  };

  const getWeekDays = () => {
    return Array.from({ length: 7 }).map((_, index) => {
      return addDays(weekStart, index);
    });
  };

  return (
    <>
      <Card p="md" radius="lg" style={{ minWidth: 'fit-content' }}>
        <Stack gap="sm">
          <Group justify="space-between">
            <Text fz="lg" fw={600}>
              {getHeaderText()}
            </Text>
            <Group gap="xs">
              <ActionIcon variant="subtle" onClick={handlePrevWeek} size="md">
                <IconChevronLeft size={16} />
              </ActionIcon>
              <CalendarWidgetAddButton
                selectedDate={selectedDate}
                onClick={handleAddClick}
              />
              <ActionIcon variant="subtle" onClick={handleNextWeek} size="md">
                <IconChevronRight size={16} />
              </ActionIcon>
            </Group>
          </Group>

          <Group gap={0} wrap="nowrap">
            {DAYS_OF_WEEK.map((day, index) => {
              const date = getWeekDays()[index];
              const isSelected =
                format(date, 'yyyy-MM-dd') ===
                format(selectedDate, 'yyyy-MM-dd');

              return (
                <Stack key={day} gap={4} align="center" w={40} mb="md">
                  <Text c="dimmed" fz="sm">
                    {day}
                  </Text>
                  <div
                    className={`${styles.dayNumber} ${
                      isSelected ? styles.selected : ''
                    }`}
                    onClick={() => setSelectedDate(date)}
                  >
                    {format(date, 'd')}
                  </div>
                </Stack>
              );
            })}
          </Group>

          <Stack gap="sm" style={{ minHeight: 400 }}>
            {getDayEvents().length === 0 ? (
              <Text c="dimmed" ta="center" py="xl">
                No events scheduled for this day
              </Text>
            ) : (
              getDayEvents().map((event: EventResponse) => (
                <UnstyledButton
                  key={event.id}
                  onClick={() => handleEventClick(event.id)}
                >
                  <Card p="sm" radius="md" bg="blue.1">
                    <Group gap="sm" wrap="nowrap">
                      {event.allDayEvent ? (
                        <IconCalendar size={20} color="gray" />
                      ) : (
                        <IconClock size={20} color="gray" />
                      )}
                      <Box>
                        <Text size="sm" fw={500}>
                          {event.name}
                        </Text>
                        {!event.allDayEvent && (
                          <Text size="xs" c="dimmed">
                            {format(new Date(event.startTime), 'h:mma')} -{' '}
                            {format(new Date(event.endTime), 'h:mma')}
                          </Text>
                        )}
                      </Box>
                    </Group>
                  </Card>
                </UnstyledButton>
              ))
            )}
          </Stack>
        </Stack>
      </Card>

      <CalendarEventModal
        opened={opened}
        onClose={handleCloseModal}
        eventId={selectedEventId}
        newEventConfig={newEventConfig}
        onSave={handleSaveEvent}
        userTimezone={currentOrganization.timeZoneName || 'America/New_York'}
      />
    </>
  );
};

export default CalendarWidget;
