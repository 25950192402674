import { getWorkgroupCalendarFeed } from '@btrway/api-calendar';
import { WorkgroupResponse } from '@btrway/api-core';
import { Button, Tooltip } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';

interface CalendarFeedButtonProps {
  workgroup?: WorkgroupResponse;
  variant?: string;
  size?: string;
}

export function CalendarFeedButton({
  workgroup,
  variant = 'light',
  size = 'sm',
}: CalendarFeedButtonProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    if (!workgroup) return;

    setIsLoading(true);
    try {
      const requestConfig = {
        responseType: 'text' as const,
        headers: {
          Accept: 'text/calendar;charset=UTF-8',
        },
      };

      let calendarData: string;
      const result = await getWorkgroupCalendarFeed(
        workgroup.id,
        requestConfig
      );

      if (typeof result === 'string') {
        calendarData = result;
      } else if (result && typeof result === 'object' && 'data' in result) {
        calendarData = (result as { data: string }).data;
      } else {
        throw new Error('Unexpected response format');
      }

      if (!calendarData.includes('BEGIN:VCALENDAR')) {
        throw new Error('Invalid calendar data format');
      }

      try {
        const dataUrl = `data:text/calendar;charset=utf-8,${encodeURIComponent(
          calendarData
        )}`;
        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute(
          'download',
          `${workgroup.name
            .replace(/[^a-z0-9]/gi, '_')
            .toLowerCase()}-calendar.ics`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (downloadError) {
        // Fallback to blob method
        const blob = new Blob([calendarData], {
          type: 'text/calendar;charset=utf-8',
        });

        saveAs(
          blob,
          `${workgroup.name
            .replace(/[^a-z0-9]/gi, '_')
            .toLowerCase()}-calendar.ics`
        );
      }
    } catch (error) {
      // You might want to add user feedback here, like a toast notification
    } finally {
      setIsLoading(false);
    }
  }, [workgroup]);

  if (workgroup) {
    return (
      <Tooltip label="Download calendar">
        <Button
          variant={variant}
          size={size}
          onClick={handleDownload}
          loading={isLoading}
        >
          <IconDownload size={16} />
        </Button>
      </Tooltip>
    );
  }

  return null;
}
