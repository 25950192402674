import { Activities } from '@btrway/activity-components';
import {
  getGetPublicWorkgroupQueryKey,
  RegistrationDTO,
  RegistrationInstanceResponse,
  useGetPublicWorkgroup,
  useGetRegistrationInstances,
} from '@btrway/api-core';
import { RegistrationFormAdministrationButton } from '@btrway/registration-administration';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Group,
  LoadingOverlay,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupRegistrationsView: React.FC = () => {
  const { workgroup } = useWorkgroup();
  const [selectedFormId, setSelectedFormId] = useState<string | null>(null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [expandedCards, setExpandedCards] = useState<Set<number>>(new Set());

  console.log(
    'WorkgroupRegistrationsView rendering with workgroup: ',
    workgroup
  );

  // Fetch public workgroup data for registration forms
  const { data: publicWorkgroup, isLoading: isLoadingPublicWorkgroup } =
    useGetPublicWorkgroup(workgroup?.id || 0, {
      query: {
        enabled: !!workgroup?.id,
        queryKey: getGetPublicWorkgroupQueryKey(workgroup?.id || 0),
      },
    });

  // Fetch registration instances based on filters
  const { data: registrationInstances, isLoading: isLoadingInstances } =
    useGetRegistrationInstances(
      workgroup?.id || 0,
      selectedFormId ? parseInt(selectedFormId) : 0,
      selectedYear ? parseInt(selectedYear) : 0
    );

  // Prepare distinct form options using useMemo
  const formOptions = useMemo(() => {
    if (!publicWorkgroup?.registrationForms) return [];

    // Create a Map to store unique forms by their ID
    const uniqueFormsMap = new Map();

    publicWorkgroup.registrationForms.forEach((form) => {
      if (
        form.registrationFormConfiguration?.title &&
        !uniqueFormsMap.has(form.id)
      ) {
        uniqueFormsMap.set(form.id, {
          value: form.id.toString(),
          label: form.registrationFormConfiguration.title,
        });
      }
    });

    return Array.from(uniqueFormsMap.values());
  }, [publicWorkgroup?.registrationForms]);

  // Prepare year options using useMemo
  const yearOptions = useMemo(() => {
    if (!publicWorkgroup?.registrationForms) return [];

    const uniqueYears = new Set<number>();
    publicWorkgroup.registrationForms.forEach((form) => {
      if (form.year) {
        uniqueYears.add(form.year);
      }
    });

    return Array.from(uniqueYears)
      .sort((a, b) => b - a)
      .map((year) => ({
        value: year.toString(),
        label: year.toString(),
      }));
  }, [publicWorkgroup?.registrationForms]);

  const toggleCardExpansion = (id: number) => {
    setExpandedCards((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const renderRegistrationCard = (instance: RegistrationInstanceResponse) => {
    const registrationData = instance.registrationData as RegistrationDTO;
    const isExpanded = expandedCards.has(instance.id);

    if (!registrationData) return null;

    const registrant = registrationData.persons.find(
      (p) => p.personKey === registrationData.registrantPersonKey
    );
    const otherPersons = registrationData.persons.filter(
      (p) => p.personKey !== registrationData.registrantPersonKey
    );

    return (
      <Card key={instance.id} withBorder shadow="sm" p="md">
        <Stack gap="md">
          {/* Header with Registrant Info */}
          <Group justify="space-between">
            <Group gap="md">
              <Avatar size="md" radius="xl" />
              <Stack gap={2}>
                <Text fw={700} size="lg">
                  {registrant?.firstName} {registrant?.lastName}
                </Text>
                <Text size="sm" c="dimmed">
                  {registrant?.email}
                </Text>
              </Stack>
            </Group>
            <Group gap="sm">
              <Badge color={instance.completedAt ? 'green' : 'yellow'}>
                {instance.completedAt ? 'Complete' : 'In Progress'}
              </Badge>
              {otherPersons.length > 0 && (
                <Button
                  variant="subtle"
                  size="sm"
                  onClick={() => toggleCardExpansion(instance.id)}
                  rightSection={
                    isExpanded ? (
                      <IconChevronUp size={16} />
                    ) : (
                      <IconChevronDown size={16} />
                    )
                  }
                >
                  {otherPersons.length} Additional{' '}
                  {otherPersons.length === 1 ? 'Person' : 'People'}
                </Button>
              )}
            </Group>
          </Group>

          {/* Additional Persons (Expandable) */}
          {isExpanded && otherPersons.length > 0 && (
            <Card withBorder bg="gray.0">
              <Stack gap="xs">
                {otherPersons.map((person, index) => (
                  <Group key={person.personKey} gap="sm">
                    <Avatar size="sm" radius="xl" />
                    <Stack gap={0}>
                      <Text size="sm" fw={500}>
                        {person.firstName} {person.lastName}
                      </Text>
                      <Text size="xs" c="dimmed">
                        {person.email}
                        {person.mobilePhone && ` • ${person.mobilePhone}`}
                      </Text>
                    </Stack>
                  </Group>
                ))}
                <Activities entityType="registration" entityId={instance.id} />
              </Stack>
            </Card>
          )}
        </Stack>
      </Card>
    );
  };

  console.log('formOptions', formOptions);
  console.log('yearOptions', yearOptions);

  return (
    <Box p="md" h="100%">
      <Stack gap="lg">
        {/* Filters */}
        <Group justify="space-between" align="flex-end">
          <Group grow style={{ flex: 1 }}>
            <Select
              label="Registration Form"
              placeholder="Select a form"
              data={formOptions}
              value={selectedFormId}
              onChange={setSelectedFormId}
              clearable
              disabled={isLoadingPublicWorkgroup}
            />
            <Select
              label="Year"
              placeholder="Select a year"
              data={yearOptions}
              value={selectedYear}
              onChange={setSelectedYear}
              clearable
              disabled={isLoadingPublicWorkgroup}
            />
          </Group>
          <RegistrationFormAdministrationButton workgroup={workgroup} />
        </Group>

        {/* Registration Instances */}
        <Box pos="relative">
          <LoadingOverlay
            visible={isLoadingInstances || isLoadingPublicWorkgroup}
          />

          <Stack gap="md">
            {registrationInstances?.map(renderRegistrationCard)}

            {(!registrationInstances || registrationInstances.length === 0) && (
              <Card withBorder p="xl">
                <Text ta="center" c="dimmed">
                  No registration instances found for the selected filters
                </Text>
              </Card>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default WorkgroupRegistrationsView;
