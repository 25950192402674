import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { styledModalAtom } from '../atoms/styledModalAtom';

export const useStyledModal = () => {
  const [modalState, setModalState] = useAtom(styledModalAtom);
  const resetModalState = useResetAtom(styledModalAtom);

  const setModalTitle = useCallback(
    (title?: React.ReactNode) => {
      setModalState((prev) => ({
        ...prev,
        modalTitle: title,
      }));
    },
    [setModalState]
  );

  const resetModal = useCallback(() => {
    resetModalState();
  }, [resetModalState]);

  return {
    modalState,
    setModalTitle,
    resetModal,
  };
};
