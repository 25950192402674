import { useWorkgroup } from '@btrway/workgroup-components';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import { WorkgroupDashboardCards } from '../../components/WorkgroupDashboardCards/WorkgroupDashboardCards';
import { WorkgroupTaskStatsView } from '../../components/WorkgroupTaskStatsView/WorkgroupTaskStatsView';
import styles from './OversightPage.module.css';

const OversightPage: React.FC = () => {
  const { workgroup } = useWorkgroup();

  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        <WorkgroupDashboardCards />
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <WorkgroupTaskStatsView />
            </Stack>
          </Stack>
        </Box>
        <Box visibleFrom="md" style={{ flexShrink: 0 }}></Box>
      </Flex>
    </Stack>
  );
};

export default OversightPage;
