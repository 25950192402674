/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type HelpRepositoryEnum = typeof HelpRepositoryEnum[keyof typeof HelpRepositoryEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HelpRepositoryEnum = {
  clientHow: 'clientHow',
  clientWhy: 'clientWhy',
  clientFiles: 'clientFiles',
  internalKnowledgeBase: 'internalKnowledgeBase',
  none: 'none',
} as const;
