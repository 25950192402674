import { useAtom } from 'jotai';
import { footerAtom, headerAtom } from '../atoms/componentAtoms';

export const useScrollableDrawer = () => {
  const [footer, setFooter] = useAtom(footerAtom);
  const [header, setHeader] = useAtom(headerAtom);

  return {
    setDrawerFooter: setFooter,
    clearDrawerFooter: () => setFooter(null),
    setDrawerHeader: setHeader,
    clearDrawerHeader: () => setHeader(null),
  };
};
