import { WorkgroupResponse } from '@btrway/api-core';
import { TimezoneSelect } from '@btrway/shared-components';
import { CalendarApi } from '@fullcalendar/core';
import {
  Box,
  Button,
  Group,
  SegmentedControl,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from '@tabler/icons-react';
import { CalendarFeedButton } from '../CalendarFeedButton/CalendarFeedButton';

interface CalendarHeaderProps {
  calendarApi: CalendarApi | null;
  onViewChange: (view: string) => void;
  title: string;
  workgroup?: WorkgroupResponse;
  onNewEvent: () => void;
  selectedTimezone: string;
  onTimezoneChange: (timezone: string) => void;
  showNewEventButton?: boolean;
}

export default function CustomCalendarHeader({
  calendarApi,
  onViewChange,
  title,
  workgroup,
  onNewEvent,
  selectedTimezone,
  onTimezoneChange,
  showNewEventButton,
}: CalendarHeaderProps) {
  if (!calendarApi) return null;

  const handlePrevClick = () => {
    calendarApi.prev();
  };

  const handleNextClick = () => {
    calendarApi.next();
  };

  const handleTodayClick = () => {
    calendarApi.today();
  };

  return (
    <Box mb="md">
      <Group align="center" grow>
        <Group justify="flex-start" gap="md">
          <Button.Group>
            <Button variant="default" size="sm" onClick={handlePrevClick} p={8}>
              <IconChevronLeft size={16} stroke={1.5} />
            </Button>
            <Button variant="default" size="sm" onClick={handleTodayClick}>
              Today
            </Button>
            <Button variant="default" size="sm" onClick={handleNextClick} p={8}>
              <IconChevronRight size={16} stroke={1.5} />
            </Button>
          </Button.Group>
          <TimezoneSelect
            value={selectedTimezone}
            onChange={(value) => onTimezoneChange(value || 'America/New_York')}
          />
        </Group>

        <Group justify="center">
          <Title order={3}>{title}</Title>
        </Group>

        <Group justify="flex-end" gap="md">
          <SegmentedControl
            data={[
              { label: 'Month', value: 'dayGridMonth' },
              { label: 'Week', value: 'timeGridWeek' },
              { label: 'Day', value: 'timeGridDay' },
            ]}
            onChange={onViewChange}
            value={calendarApi.view.type}
          />
          {showNewEventButton && (
            <Tooltip label="Download calendar">
              <Button size="sm" onClick={onNewEvent}>
                <IconCalendar size={20} />
              </Button>
            </Tooltip>
          )}
          {workgroup && <CalendarFeedButton workgroup={workgroup} />}
        </Group>
      </Group>
    </Box>
  );
}
