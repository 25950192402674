import { CertificationInstanceGridResponse } from '@btrway/api-workflow';
import { formatDateTime } from '@btrway/utils';
import { CertificationTypeIcon } from '@btrway/workflow-components';
import { useCertificationTypes } from '@btrway/workflow-manager';
import { Badge, Group, Paper, Stack, Text } from '@mantine/core';
import { IconCircleCheck, IconClock } from '@tabler/icons-react';
import {
  getRequirementStatusColor,
  getRequirementStatusText,
} from '../../utils/certificationUtils';
import { DataDisplay } from '../DataDisplay/DataDisplay';

interface RequirementCardProps {
  requirement: CertificationInstanceGridResponse;
}

export const RequirementCard = ({ requirement }: RequirementCardProps) => {
  const { getCertificationType } = useCertificationTypes();

  const getStatusIcon = (completed: boolean) => {
    return completed ? <IconCircleCheck size={16} /> : <IconClock size={16} />;
  };

  const getRequirementTitle = () => {
    const certType =
      requirement.certificationType &&
      getCertificationType(requirement.certificationType);

    const title =
      requirement.certificationTitle ||
      (certType?.name ?? requirement.certificationType);

    if (requirement.requirementDescription) {
      return `${title}: ${requirement.requirementDescription}`;
    }
    return title;
  };

  return (
    <Paper
      key={requirement.certificationKey}
      p="sm"
      radius="sm"
      withBorder
      shadow="xs"
    >
      <Group justify="space-between" align="flex-start">
        <Stack gap="md">
          <Group gap="xs">
            {requirement.certificationType && (
              <CertificationTypeIcon
                iconName={requirement.certificationType}
                size={20}
                color="gray"
              />
            )}
            <Text fz="md" fw={600}>
              {getRequirementTitle()}
            </Text>
          </Group>
          <DataDisplay requirement={requirement} />
        </Stack>

        <Stack gap="xs" align="flex-end">
          <Badge
            color={getRequirementStatusColor(
              requirement.completed,
              requirement.requirementMet
            )}
            leftSection={getStatusIcon(requirement.completed)}
          >
            {getRequirementStatusText(
              requirement.completed,
              requirement.requirementMet
            )}
          </Badge>

          {requirement.completed && (
            <Stack gap={0} align="flex-end">
              {requirement.completedByName && (
                <Text size="sm">
                  Completed by: {requirement.completedByName}
                </Text>
              )}
              {requirement.completedAt && (
                <Text size="sm">{formatDateTime(requirement.completedAt)}</Text>
              )}
            </Stack>
          )}
        </Stack>
      </Group>
    </Paper>
  );
};
