import { useGetRelationshipTypes } from '@btrway/api-core';
import {
  Button,
  Card,
  Grid,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React from 'react';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';
import RelationshipChipSelector from '../RelationshipChipSelector/RelationshipChipSelector';

export interface OtherRelatedPersonInfoProps {
  personKey: string;
  personIndex: number;
  onDelete: (personKey: string) => void;
}

const OtherRelatedPersonInfo: React.FC<OtherRelatedPersonInfoProps> = ({
  personKey,
  personIndex,
  onDelete,
}) => {
  const {
    registration,
    updateOtherRelatedPerson,
    getRelationship,
    updateRelationship,
  } = useRegistrationSubmission();
  const { data: relationshipTypes } = useGetRelationshipTypes();

  const person = registration.persons.find((p) => p.personKey === personKey);
  const dependents = registration.persons.filter((p) => p.isDependent);

  if (!person) return null;

  return (
    <Card withBorder radius="sm" padding="lg" shadow="none">
      <Stack gap="md">
        <Group justify="flex-start" mb="lg">
          <Text size="lg" fw={600}>
            Other Related Person #{personIndex}
          </Text>
          <Button
            // color="red"
            size="compact-xs"
            color="gray"
            variant="outline"
            onClick={() => onDelete(personKey)}
          >
            Delete
          </Button>
        </Group>
        <Grid>
          <Grid.Col span={12}>
            <Group grow>
              <TextInput
                label="First Name"
                value={person.firstName}
                onChange={(event) =>
                  updateOtherRelatedPerson(
                    personKey,
                    'firstName',
                    event.currentTarget.value
                  )
                }
                required
                autoFocus
              />
              <TextInput
                label="Last Name"
                value={person.lastName}
                onChange={(event) =>
                  updateOtherRelatedPerson(
                    personKey,
                    'lastName',
                    event.currentTarget.value
                  )
                }
                required
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Group grow>
              <TextInput
                label="Email"
                type="email"
                value={person.email}
                onChange={(event) =>
                  updateOtherRelatedPerson(
                    personKey,
                    'email',
                    event.currentTarget.value
                  )
                }
                required
              />
              <TextInput
                label="Mobile Phone"
                value={person.mobilePhone}
                onChange={(event) =>
                  updateOtherRelatedPerson(
                    personKey,
                    'mobilePhone',
                    event.currentTarget.value
                  )
                }
              />
            </Group>
          </Grid.Col>
        </Grid>
        {dependents.map((dependent) => (
          <Stack key={dependent.personKey} gap="xs">
            <Text size="sm" fw={500}>
              Relationship to {dependent.firstName || 'this child'}
            </Text>
            <RelationshipChipSelector
              value={
                getRelationship(
                  personKey,
                  dependent.personKey
                )?.relationshipTypeId.toString() || ''
              }
              onChange={(value) =>
                updateRelationship(
                  personKey,
                  dependent.personKey,
                  Number(value)
                )
              }
              relationshipTypes={relationshipTypes || []}
            />
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default OtherRelatedPersonInfo;
