import { DataTypeEnum } from '@btrway/api-activities';
import { Loader, Text } from '@mantine/core';
import React from 'react';
import { ActivityList } from './components/ActivityList/ActivityList';
import { useActivities } from './hooks/useActivities';

interface ActivitiesProps {
  entityType: DataTypeEnum;
  entityId: number;
}

export const Activities: React.FC<ActivitiesProps> = ({
  entityType,
  entityId,
}) => {
  const { activities, isLoading, error } = useActivities(entityType, entityId);

  if (isLoading) {
    return <Loader size="sm" />;
  }

  if (error) {
    return (
      <Text c="red" size="sm">
        Error: {error.message}
      </Text>
    );
  }

  return <ActivityList activities={activities} />;
};
