import { Activities } from '@btrway/activity-components';
import { Box } from '@mantine/core';
import React from 'react';
import { usePerson } from '../../providers/PersonProvider';

export const PersonActivityView: React.FC = () => {
  const { person } = usePerson();

  return (
    <Box m="lg" h="100%">
      <Activities entityType="person" entityId={person.id} />
    </Box>
  );
};
