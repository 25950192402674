import { useCallback, useState } from 'react';

const MAX_RETRIES = 5;
const RETRY_DELAY = 3000;

export const useMuxUpload = () => {
  const [uploadId, setUploadId] = useState<string | null>(null);
  const [assetId, setAssetId] = useState<string | null>(null);

  const tokenId = import.meta.env.VITE_MUX_TOKEN_ID;
  const tokenSecret = import.meta.env.VITE_MUX_TOKEN_SECRET;

  const endpointFunction = useCallback(async () => {
    try {
      const response = await fetch('https://api.mux.com/video/v1/uploads', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
        },
        body: JSON.stringify({
          cors_origin: 'http://localhost/',
          new_asset_settings: {
            playback_policy: ['public'],
            encoding_tier: 'smart',
            max_resolution_tier: '2160p',
            input: [
              {
                generated_subtitles: [
                  { language_code: 'en', name: 'English CC' },
                  { language_code: 'es', name: 'Spanish' },
                  { language_code: 'it', name: 'Italian' },
                  { language_code: 'pt', name: 'Portuguese' },
                  { language_code: 'fr', name: 'French' },
                ],
              },
            ],
          },
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setUploadId(data.data.id);
        return data.data.url;
      } else {
        console.error('Error generating endpoint:', response.statusText);
        throw new Error('Failed to generate upload URL');
      }
    } catch (error) {
      console.error('Error generating endpoint:', error);
      throw error;
    }
  }, [tokenId, tokenSecret]);

  const fetchAssetId = useCallback(
    async (uploadId: string, retries: number = 0): Promise<string> => {
      try {
        const response = await fetch(
          `https://api.mux.com/video/v1/uploads/${uploadId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
            },
          }
        );

        if (response.ok) {
          const uploadData = await response.json();
          console.log('fetchAssetId: uploadData', uploadData);
          const assetId = uploadData.data.asset_id;

          if (assetId) {
            return assetId;
          } else {
            if (retries < MAX_RETRIES) {
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
              return fetchAssetId(uploadId, retries + 1);
            } else {
              throw new Error(
                `Asset ID not found after ${MAX_RETRIES} retries`
              );
            }
          }
        } else {
          if (retries < MAX_RETRIES) {
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return fetchAssetId(uploadId, retries + 1);
          } else {
            throw new Error(`Request failed after ${MAX_RETRIES} retries`);
          }
        }
      } catch (error) {
        if (retries < MAX_RETRIES) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          return fetchAssetId(uploadId, retries + 1);
        } else {
          throw error;
        }
      }
    },
    [tokenId, tokenSecret]
  );

  const fetchPlaybackId = useCallback(
    async (assetId: string, retries: number = 0): Promise<string> => {
      try {
        const response = await fetch(
          `https://api.mux.com/video/v1/assets/${assetId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
            },
          }
        );

        if (response.ok) {
          const assetData = await response.json();
          console.log('fetchPlaybackId: assetData', assetData);
          const playbackId = assetData.data.playback_ids[0].id;

          if (playbackId) {
            return playbackId;
          } else {
            if (retries < MAX_RETRIES) {
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
              return fetchPlaybackId(assetId, retries + 1);
            } else {
              throw new Error(
                `Playback ID not found after ${MAX_RETRIES} retries`
              );
            }
          }
        } else {
          if (retries < MAX_RETRIES) {
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return fetchPlaybackId(assetId, retries + 1);
          } else {
            throw new Error(`Request failed after ${MAX_RETRIES} retries`);
          }
        }
      } catch (error) {
        if (retries < MAX_RETRIES) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          return fetchPlaybackId(assetId, retries + 1);
        } else {
          throw error;
        }
      }
    },
    [tokenId, tokenSecret]
  );

  const handleUploadStart = useCallback(() => {
    setUploadId(null);
    setAssetId(null);
  }, []);

  const fetchAssetDetails = useCallback(
    async (
      assetId: string,
      retries: number = 0
    ): Promise<{ playbackId: string; duration: number }> => {
      try {
        const response = await fetch(
          `https://api.mux.com/video/v1/assets/${assetId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
            },
          }
        );

        if (response.ok) {
          const assetData = await response.json();
          console.log('fetchAssetDetails: assetData', assetData);
          const playbackId = assetData.data.playback_ids[0].id;
          const duration = assetData.data.duration;

          if (playbackId && duration) {
            return { playbackId, duration };
          } else {
            if (retries < MAX_RETRIES) {
              await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
              return fetchAssetDetails(assetId, retries + 1);
            } else {
              throw new Error(
                `Asset details not found after ${MAX_RETRIES} retries`
              );
            }
          }
        } else {
          if (retries < MAX_RETRIES) {
            await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
            return fetchAssetDetails(assetId, retries + 1);
          } else {
            throw new Error(`Request failed after ${MAX_RETRIES} retries`);
          }
        }
      } catch (error) {
        if (retries < MAX_RETRIES) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
          return fetchAssetDetails(assetId, retries + 1);
        } else {
          throw error;
        }
      }
    },
    [tokenId, tokenSecret]
  );

  const handleUploadSuccess = useCallback(async () => {
    if (uploadId) {
      try {
        const newAssetId = await fetchAssetId(uploadId);
        setAssetId(newAssetId);
        const { playbackId, duration } = await fetchAssetDetails(newAssetId);
        return { playbackId, duration };
      } catch (error) {
        console.error('Error retrieving asset details:', error);
        throw error;
      }
    }
  }, [uploadId, fetchAssetId, fetchAssetDetails]);

  return {
    endpointFunction,
    handleUploadStart,
    handleUploadSuccess,
  };
};
