import {
  DataTypeEnum,
  EventRequest,
  EventTypeResponse,
  getGetEventTypesByOrganizationIdQueryKey,
  SystemEventTypeEnum,
  useGetEventTypesByOrganizationId,
} from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  Box,
  Button,
  Chip,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { DateInput, TimeInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconArrowRight, IconCalendar, IconClock } from '@tabler/icons-react';
import { useCallback, useMemo, useState } from 'react';
import { getDefaultStartEndTimes } from '../../utils/newAvailabilityEvent';

interface FacilityAvailabilityEditProps {
  facilityId: number;
  workgroupId: number;
  initialEvent?: Partial<EventRequest>;
  onSave: (event: EventRequest) => void;
  onCancel: () => void;
  userTimezone: string;
}

const formatTimeString = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
};

const parseTimeString = (
  timeStr: string
): { hours: number; minutes: number } => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return { hours, minutes };
};

const generateWeeklyRecurrenceRule = (
  weekDays: string[],
  untilDate: Date | null
): string => {
  if (!weekDays.length) return '';
  const baseRule = `FREQ=WEEKLY;BYDAY=${weekDays.join(',')}`;
  if (untilDate) {
    const untilStr = untilDate.toISOString().split('T')[0].replace(/-/g, '');
    return `${baseRule};UNTIL=${untilStr}`;
  }
  return baseRule;
};

const getEndDateFromRecurrenceRule = (rule: string): Date | null => {
  if (!rule) return null;
  const untilMatch = rule.match(/UNTIL=(\d{8})/);
  if (!untilMatch) return null;

  const dateStr = untilMatch[1];
  const year = parseInt(dateStr.slice(0, 4));
  const month = parseInt(dateStr.slice(4, 6)) - 1;
  const day = parseInt(dateStr.slice(6, 8));
  return new Date(year, month, day);
};

export default function FacilityAvailabilityEdit({
  facilityId,
  workgroupId,
  initialEvent,
  onSave,
  onCancel,
  userTimezone,
}: FacilityAvailabilityEditProps) {
  console.log('FacilityAvailabilityEdit');
  const [hasInvalidTimes, setHasInvalidTimes] = useState(false);
  const { currentOrganization, currentPerson } = useAuthenticatedUser();

  const { initialWeekDays, initialEndDate } = useMemo(() => {
    if (!initialEvent?.recurrenceRule)
      return { initialWeekDays: [], initialEndDate: null };
    const weekDaysMatch = initialEvent.recurrenceRule.match(/BYDAY=([^;]+)/);
    const weekDays = weekDaysMatch ? weekDaysMatch[1].split(',') : [];
    const endDate = getEndDateFromRecurrenceRule(initialEvent.recurrenceRule);
    return { initialWeekDays: weekDays, initialEndDate: endDate };
  }, [initialEvent?.recurrenceRule]);

  const [selectedDays, setSelectedDays] = useState<string[]>(initialWeekDays);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<Date | null>(
    initialEndDate
  );

  const { data: eventTypes, isLoading } = useGetEventTypesByOrganizationId(
    currentOrganization.id,
    {
      query: {
        select: (types: EventTypeResponse[]) =>
          types.find(
            (type) => type.systemType === SystemEventTypeEnum.availability
          ),
        queryKey: getGetEventTypesByOrganizationIdQueryKey(
          currentOrganization.id
        ),
      },
    }
  );

  const form = useForm<Partial<EventRequest>>({
    initialValues: {
      startTime: initialEvent?.startTime || getDefaultStartEndTimes().startTime,
      endTime: initialEvent?.endTime || getDefaultStartEndTimes().endTime,
      timeZoneName: userTimezone,
      name: 'Available',
      organizationId: currentOrganization.id,
      workgroupId: workgroupId,
      active: true,
      dirty: true,
    },
  });

  const validateTimes = useCallback((startTime: string, endTime: string) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const isValid = endDate.getTime() > startDate.getTime();
    setHasInvalidTimes(!isValid);
    return isValid;
  }, []);

  const handleTimeChange = useCallback(
    (type: 'start' | 'end', timeStr: string) => {
      const { hours, minutes } = parseTimeString(timeStr);
      const startDate = new Date(form.values.startTime || new Date());
      const newDate = new Date(startDate);
      newDate.setHours(hours, minutes, 0, 0);

      if (type === 'start') {
        form.setFieldValue('startTime', newDate.toISOString());

        const endTime = new Date(form.values.endTime || new Date());
        const updatedEndTime = new Date(newDate);
        updatedEndTime.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
        form.setFieldValue('endTime', updatedEndTime.toISOString());
      } else {
        const updatedEndTime = new Date(startDate);
        updatedEndTime.setHours(hours, minutes, 0, 0);
        form.setFieldValue('endTime', updatedEndTime.toISOString());
      }

      validateTimes(form.values.startTime!, newDate.toISOString());
    },
    [form, validateTimes]
  );

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      if (!date) return;

      const currentStart = new Date(form.values.startTime || new Date());
      const currentEnd = new Date(form.values.endTime || new Date());

      const newStart = new Date(date);
      newStart.setHours(
        currentStart.getHours(),
        currentStart.getMinutes(),
        0,
        0
      );

      const newEnd = new Date(date);
      newEnd.setHours(currentEnd.getHours(), currentEnd.getMinutes(), 0, 0);

      form.setFieldValue('startTime', newStart.toISOString());
      form.setFieldValue('endTime', newEnd.toISOString());
    },
    [form]
  );

  const handleSubmit = useCallback(
    async (e?: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      if (!validateTimes(form.values.startTime!, form.values.endTime!)) {
        return;
      }

      if (!eventTypes || !selectedDays.length) {
        return;
      }

      try {
        const eventRequest: EventRequest = {
          ...form.values,
          id: initialEvent?.id,
          name: 'Available',
          eventTypeId: eventTypes.id,
          timeZoneName: userTimezone,
          recurrenceRule: generateWeeklyRecurrenceRule(
            selectedDays,
            recurrenceEndDate
          ),
          isRecurrenceParent: true,
          eventTargets: [
            {
              entityId: facilityId,
              entityType: DataTypeEnum.facility,
              organizationId: currentOrganization.id,
            },
          ],
          organizationId: currentOrganization.id,
          workgroupId: workgroupId,
          active: true,
          dirty: true,
          isPublic: true,
          allDayEvent: false,
          ownerPersonId: currentPerson.id,
        } as EventRequest;

        await Promise.resolve(onSave(eventRequest));
      } catch (error) {
        // Error handling could be added here if needed
      }
    },
    [
      validateTimes,
      form.values,
      eventTypes,
      selectedDays,
      recurrenceEndDate,
      facilityId,
      currentOrganization.id,
      workgroupId,
      userTimezone,
      onSave,
      initialEvent?.id,
      currentPerson.id,
    ]
  );

  if (isLoading || !eventTypes) {
    return <LoadingOverlay visible />;
  }

  const startDateTime = new Date(form.values.startTime || new Date());
  const endDateTime = new Date(form.values.endTime || new Date());

  const weekDays = [
    { value: 'MO', label: 'Mon' },
    { value: 'TU', label: 'Tue' },
    { value: 'WE', label: 'Wed' },
    { value: 'TH', label: 'Thu' },
    { value: 'FR', label: 'Fri' },
    { value: 'SA', label: 'Sat' },
    { value: 'SU', label: 'Sun' },
  ];

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Text fz="sm" fw={500}>
          Select Applicable Days of Week
        </Text>
        <Chip.Group multiple value={selectedDays} onChange={setSelectedDays}>
          <Group>
            {weekDays.map((day) => (
              <Chip key={day.value} value={day.value}>
                {day.label}
              </Chip>
            ))}
          </Group>
        </Chip.Group>
      </Stack>

      <Group align="flex-end">
        <TimeInput
          label="Start Time"
          leftSection={<IconClock size={16} />}
          value={formatTimeString(startDateTime)}
          onChange={(event) =>
            handleTimeChange('start', event.currentTarget.value)
          }
          error={hasInvalidTimes}
        />

        <Box
          style={{
            border: '1px solid #dee2e6',
            borderRadius: '4px',
            padding: '4px',
            marginBottom: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconArrowRight size={16} stroke={1.5} />
        </Box>

        <TimeInput
          label="End Time"
          leftSection={<IconClock size={16} />}
          value={formatTimeString(endDateTime)}
          onChange={(event) =>
            handleTimeChange('end', event.currentTarget.value)
          }
          error={hasInvalidTimes}
        />
      </Group>

      <Group align="flex-end">
        <DateInput
          label="Start Date"
          leftSection={<IconCalendar size={16} />}
          value={startDateTime}
          onChange={handleStartDateChange}
          clearable={false}
        />

        <DateInput
          label="End Date"
          leftSection={<IconCalendar size={16} />}
          value={recurrenceEndDate}
          onChange={(date) => setRecurrenceEndDate(date)}
          clearable={true}
          placeholder="No end date"
        />
      </Group>

      <Group justify="flex-end">
        <Button variant="subtle" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={hasInvalidTimes || selectedDays.length === 0}
        >
          Save
        </Button>
      </Group>
    </Stack>
  );
}
