import { useAuthenticatedUser } from '@btrway/current-user';
import { Text } from '@mantine/core';

interface WorkgroupNavLabelProps {
  fallbackLabel: string;
}

export const WorkgroupNavLabel: React.FC<WorkgroupNavLabelProps> = ({
  fallbackLabel,
}) => {
  const { rootWorkgroup } = useAuthenticatedUser();

  return (
    <Text fz="sm" lineClamp={1}>
      {rootWorkgroup?.shortDisplayName ?? fallbackLabel}
    </Text>
  );
};
