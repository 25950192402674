import { RuleOperatorTypeEnum } from '@btrway/api-workflow';
import { useMemo } from 'react';

interface RuleOperatorOption {
  value: RuleOperatorTypeEnum;
  label: string;
}

export const useRuleOperatorTypes = (): RuleOperatorOption[] => {
  const operatorOptions = useMemo(() => {
    const options: RuleOperatorOption[] = [
      { value: RuleOperatorTypeEnum.equal, label: 'Equals' },
      { value: RuleOperatorTypeEnum.notequal, label: 'Does not equal' },
      { value: RuleOperatorTypeEnum.contains, label: 'Contains' },
      { value: RuleOperatorTypeEnum.notcontains, label: 'Does not contain' },
      { value: RuleOperatorTypeEnum.startswith, label: 'Starts with' },
      { value: RuleOperatorTypeEnum.endswith, label: 'Ends with' },
      { value: RuleOperatorTypeEnum.greater, label: 'Greater than' },
      {
        value: RuleOperatorTypeEnum.greaterorequal,
        label: 'Greater than or equal to',
      },
      { value: RuleOperatorTypeEnum.less, label: 'Less than' },
      {
        value: RuleOperatorTypeEnum.lessorequal,
        label: 'Less than or equal to',
      },
      { value: RuleOperatorTypeEnum.includes, label: 'Includes' },
      { value: RuleOperatorTypeEnum.excludes, label: 'Excludes' },
      { value: RuleOperatorTypeEnum.hasvalue, label: 'Has value' },
      { value: RuleOperatorTypeEnum.novalue, label: 'Has no value' },
      { value: RuleOperatorTypeEnum.before, label: 'Before' },
      { value: RuleOperatorTypeEnum.after, label: 'After' },
      { value: RuleOperatorTypeEnum.onorbefore, label: 'On or before' },
      { value: RuleOperatorTypeEnum.onorafter, label: 'On or after' },
    ];

    return options;
  }, []);

  return operatorOptions;
};
