import { WorkgroupTaskStatisticsResponse } from '@btrway/api-workflow';
import { ActionIcon, Card, Collapse, Group, Text } from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import React from 'react';
import { TaskStatisticMetric } from '../TaskStatisticMetric/TaskStatisticMetric';
import { TaskStatisticsDetail } from '../TaskStatisticsDetail/TaskStatisticsDetail';

interface TaskStatisticsCardProps {
  statistics: WorkgroupTaskStatisticsResponse;
  isExpanded: boolean;
  onToggleExpand: () => void;
}

// In TaskStatisticsCard.tsx
export const TaskStatisticsCard: React.FC<TaskStatisticsCardProps> = ({
  statistics,
  isExpanded,
  onToggleExpand,
}) => (
  <Card shadow="sm" padding="lg" withBorder>
    <Group justify="space-between" align="center" wrap="nowrap">
      <Text fw={700} size="lg">
        {statistics.workgroupName}
      </Text>

      <Group gap="xl" justify="flex-end" wrap="nowrap">
        <Group gap="lg">
          <TaskStatisticMetric
            value={statistics.totalTaskLists ?? 0}
            label="Assignments"
          />
          <TaskStatisticMetric
            value={statistics.totalTasks ?? 0}
            label="Total Tasks"
          />
          <TaskStatisticMetric
            value={statistics.completedTasks ?? 0}
            label="Completed"
          />
          <TaskStatisticMetric
            value={`${statistics.completionPercentage?.toFixed(1) ?? '0.0'}%`}
            label="Completion"
          />
          <TaskStatisticMetric
            value={statistics.totalOverdueCompletedTasks ?? 0}
            label="Tasks Overdue"
          />
          <TaskStatisticMetric
            value={`${statistics.averageOverdueDays?.toFixed(1) ?? '0.0'}`}
            label="Avg Days Overdue"
          />
        </Group>

        <ActionIcon onClick={onToggleExpand} variant="subtle" size="xl">
          {isExpanded ? (
            <IconChevronUp size="1.75rem" stroke={1.5} />
          ) : (
            <IconChevronDown size="1.75rem" stroke={1.5} />
          )}
        </ActionIcon>
      </Group>
    </Group>

    <Collapse in={isExpanded}>
      <TaskStatisticsDetail statistics={statistics} />
    </Collapse>
  </Card>
);
