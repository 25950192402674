import { abwlogosymbol } from '@btrway/assets';
import { Image } from '@mantine/core';
import React from 'react';
import { PasswordResetForm } from '../../components/PasswordResetForm/PasswordResetForm';
import './PasswordReset.css';

const PasswordReset: React.FC = () => {
  // const { apiToken } = useAPIClient();

  return (
    <div className="login-page">
      <div className="right-section">
        <div className="centered-content">
          <div>
            <Image
              src={abwlogosymbol}
              alt="Your Company Logo"
              height={200}
              fit="contain"
            />
            <PasswordResetForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
//
