import { Card } from '@mantine/core';
import React from 'react';

interface RegistrationFormContentCardProps {
  children: React.ReactNode;
}

export const RegistrationFormContentCard: React.FC<
  RegistrationFormContentCardProps
> = ({ children }) => {
  return (
    <Card
      padding="xl"
      radius="md"
      withBorder
      style={{
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </Card>
  );
};
