import { TopNavigationLayout } from '@btrway/layout-components';
import { RouteConfig } from '@btrway/web-routing';
import {
  IconAlignBoxLeftTop,
  IconBulb,
  IconForms,
  IconHome,
} from '@tabler/icons-react';
import { lazy } from 'react';
import { myRoutes } from './myRoutes';
import { opsRoutes } from './opsRoutes';
import { oversightRoutes } from './oversightRoutes';
import { personRoutes } from './personRoutes';
import { settingsRoutes } from './settingsRoutes';

// Lazy loaded calendar components
const CalendarSettings = lazy(() =>
  import('@btrway/calendar-components').then((module) => ({
    default: module.CalendarSettings,
  }))
);
const MyCalendarPage = lazy(() =>
  import('@btrway/calendar-components').then((module) => ({
    default: module.MyCalendarPage,
  }))
);

// Lazy loaded courseware components
const CourseLandingPage = lazy(() =>
  import('@btrway/courseware-library-components').then((module) => ({
    default: module.CourseLandingPage,
  }))
);
const EducationPage = lazy(() =>
  import('@btrway/courseware-library-components').then((module) => ({
    default: module.EducationPage,
  }))
);

// Lazy loaded form components
const LaunchFormList = lazy(() =>
  import('@btrway/form-library').then((module) => ({
    default: module.LaunchFormList,
  }))
);
const SubmittedFormsView = lazy(() =>
  import('@btrway/form-submission').then((module) => ({
    default: module.SubmittedFormsView,
  }))
);

// Lazy loaded dashboard components
const ParentDashboardPage = lazy(() =>
  import('@btrway/parent-dashboard').then((module) => ({
    default: module.ParentDashboardPage,
  }))
);
const ScoreboardPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.ScoreboardPage,
  }))
);
const HomePage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.HomePage,
  }))
);
const CertificationsPage = lazy(() =>
  import('@btrway/scoreboard-components').then((module) => ({
    default: module.CertificationsPage,
  }))
);
const StudentDashboardPage = lazy(() =>
  import('@btrway/student-dashboard').then((module) => ({
    default: module.StudentDashboardPage,
  }))
);

// Lazy loaded task components
const GroupedTasksView = lazy(() =>
  import('@btrway/task-grids').then((module) => ({
    default: module.GroupedTasksView,
  }))
);
const TaskAssignmentsGrid = lazy(() =>
  import('@btrway/task-grids').then((module) => ({
    default: module.TaskAssignmentsGrid,
  }))
);

// const ReportingTestPage = lazy(() =>
//   import('@btrway/task-reporting-components').then((module) => ({
//     default: module.TaskAssignmentsGrid,
//   }))
// );

export const appShellRoutes: RouteConfig[] = [
  ...myRoutes,
  // {
  //   path: 'home',
  //   element: <HomePage />,
  //   label: 'Home',
  //   icon: IconHome,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['STAFF_DASHBOARD'],
  // },
  {
    path: 'parent',
    element: <ParentDashboardPage />,
    label: "Parent's Dashboard",
    icon: IconHome,
    navLevel: 'primary',
    navContainer: 'first',
    permissions: ['RELATED_CONTACT_DASHBOARD'],
  },
  {
    path: 'student',
    element: <StudentDashboardPage />,
    label: "Student's Dashboard",
    icon: IconHome,
    navLevel: 'primary',
    navContainer: 'first',
    permissions: ['STUDENT_DASHBOARD'],
  },
  // {
  //   path: 'certifications',
  //   element: <CertificationsPage />,
  //   label: 'Certifications',
  //   icon: IconAward,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['STAFF_DASHBOARD'],
  // },
  // {
  //   path: 'approvals',
  //   element: (
  //     <HeaderLayout>
  //       <TaskAssignmentsGrid />
  //     </HeaderLayout>
  //   ),
  //   label: 'Approvals',
  //   labelComponent: TasksNavLabel,
  //   icon: IconClockCheck,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['TODOS'],
  // },
  // {
  //   path: 'todos',
  //   element: (
  //     <HeaderLayout>
  //       <GroupedTasksView />
  //     </HeaderLayout>
  //   ),
  //   label: 'To-Dos',
  //   icon: IconListCheck,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['CHECKLISTS'],
  // },
  {
    path: 'forms',
    element: <TopNavigationLayout />,
    label: 'Forms',
    icon: IconAlignBoxLeftTop,
    navLevel: 'primary',
    navContainer: 'first',
    permissions: ['FORMS'],
    children: [
      {
        path: 'library',
        element: <LaunchFormList />,
        label: 'Form Library',
        icon: IconForms,
        navLevel: 'secondary',
        displayType: 'label',
      },
      {
        path: 'myforms',
        element: <SubmittedFormsView />,
        label: 'My Forms',
        icon: IconForms,
        navLevel: 'secondary',
        displayType: 'label',
      },
    ],
  },
  // {
  //   path: 'calendar',
  //   element: <MyCalendarPage />,
  //   label: 'Schedule',
  //   icon: IconCalendar,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  //   permissions: ['SCHEDULE'],
  // },
  {
    path: 'education',
    element: <EducationPage />,
    label: 'Learn',
    icon: IconBulb,
    navLevel: 'primary',
    navContainer: 'first',
    forceDarkMode: true,
    permissions: ['LEARN'],
    children: [
      {
        path: 'curriculum/:curriculumId/course/:courseUid',
        element: <CourseLandingPage />,
        label: 'Course',
        navLevel: 'secondary',
      },
    ],
  },
  // {
  //   path: 'reporting',
  //   element: <ReportingTestPage />,
  //   label: 'Reporting Test',
  //   icon: IconReportAnalytics,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  // },
  // {
  //   path: 'tasklist-reporting',
  //   element: <WorkflowReportingPage />,
  //   label: 'Workflow Reporting',
  //   icon: IconReportAnalytics,
  //   navLevel: 'primary',
  //   navContainer: 'first',
  // },
  {
    path: 'education/course/:courseUid',
    element: <CourseLandingPage />,
  },
  ...opsRoutes,
  ...oversightRoutes,
  ...settingsRoutes,
  ...personRoutes,
];
