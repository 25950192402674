/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskAssignmentTypeEnum = typeof TaskAssignmentTypeEnum[keyof typeof TaskAssignmentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskAssignmentTypeEnum = {
  userRole: 'userRole',
  person: 'person',
  primaryWorkflowPerson: 'primaryWorkflowPerson',
} as const;
