import {
  EventTargetRequest,
  useGetFacilitiesByWorkgroup,
} from '@btrway/api-calendar';
import { DataTypeEnum } from '@btrway/api-workflow';
import { Select, Skeleton, Stack, Text } from '@mantine/core';
import { useMemo } from 'react';
import { EventTargetCard } from '../EventTargetCard/EventTargetCard';

interface EventFacilitySelectorProps {
  workgroupId: number;
  targets: EventTargetRequest[];
  onAddTarget: (target: EventTargetRequest) => void;
  onRemoveTarget: (targetId: number) => void;
  organizationId: number;
}

const EventFacilitySelector = ({
  workgroupId,
  targets,
  onAddTarget,
  onRemoveTarget,
  organizationId,
}: EventFacilitySelectorProps) => {
  const { data: facilities, isLoading } =
    useGetFacilitiesByWorkgroup(workgroupId);

  // Filter for facility targets
  const facilityTargets = useMemo(
    () =>
      targets.filter((target) => target.entityType === DataTypeEnum.facility),
    [targets]
  );

  const existingFacilityIds = useMemo(
    () => facilityTargets.map((target) => target.entityId),
    [facilityTargets]
  );

  const handleSelect = (facilityId: string | null) => {
    if (facilityId) {
      const newTarget: EventTargetRequest = {
        entityId: parseInt(facilityId, 10),
        entityType: DataTypeEnum.facility,
        organizationId,
      };
      onAddTarget(newTarget);
    }
  };

  if (isLoading) {
    return <Skeleton height={36} />;
  }

  // Filter out facilities that are already selected as targets
  const availableFacilities =
    facilities?.filter(
      (facility) => !existingFacilityIds.includes(facility.id)
    ) || [];

  return (
    <Stack gap="md">
      <Select
        label="Add Facility"
        placeholder="Select a facility"
        data={availableFacilities.map((facility) => ({
          value: facility.id.toString(),
          label: facility.name,
        }))}
        onChange={handleSelect}
        searchable
        clearable
        nothingFoundMessage="No facilities available"
      />
      <Stack gap="xs">
        <Text size="sm" fw={500} c="dimmed">
          Selected Facilities ({facilityTargets.length})
        </Text>
        {facilityTargets.map((target) => (
          <EventTargetCard
            key={`${target.entityType}-${target.entityId}`}
            target={target}
            onRemove={onRemoveTarget}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default EventFacilitySelector;
