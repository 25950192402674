import { CertificationValidTypeEnum } from '@btrway/api-workflow';
import { Chip, Group, NumberInput, Stack, Text, Title } from '@mantine/core';
import { CertificationConfigProps } from '../../../types/editors';

export const CertificationValidConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const handleValidTypeChange = (value: string) => {
    onConfigChange({
      ...config,
      certificationValidConfig: {
        validType: value as CertificationValidTypeEnum,
        withinDays: config.certificationValidConfig?.withinDays || 0,
      },
    });
  };

  const handleWithinDaysChange = (value: string | number) => {
    const numValue = typeof value === 'string' ? parseInt(value) : value;

    onConfigChange({
      ...config,
      certificationValidConfig: {
        validType:
          config.certificationValidConfig?.validType ||
          CertificationValidTypeEnum.withinDays,
        withinDays: numValue || 0,
      },
    });
  };

  return (
    <Stack gap="md">
      <Stack gap="xs">
        <Title order={4}>Validity Timeframe</Title>
        <Text size="sm" c="dimmed">
          Specify whether previous task completions can be used to satisfy this
          requirement.
        </Text>
        <Chip.Group
          multiple={false}
          value={config.certificationValidConfig?.validType || ''}
          onChange={handleValidTypeChange}
        >
          <Group gap="xs">
            <Chip value={CertificationValidTypeEnum.thisYear}>This Year</Chip>
            <Chip value={CertificationValidTypeEnum.ever}>Any Time</Chip>
            <Chip value={CertificationValidTypeEnum.withinDays}>
              Limited to...
            </Chip>
          </Group>
        </Chip.Group>
      </Stack>

      {config.certificationValidConfig?.validType ===
        CertificationValidTypeEnum.withinDays && (
        <NumberInput
          label="Valid if within this many days"
          value={config.certificationValidConfig?.withinDays || 0}
          onChange={handleWithinDaysChange}
          min={0}
        />
      )}
    </Stack>
  );
};
