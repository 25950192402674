import { QuestionDTO } from '@btrway/api-courseware';
import { Box, Button, Flex, Text, Transition } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import QuizAnswer from '../QuizAnswer/QuizAnswer';

interface QuizQuestionProps {
  question: QuestionDTO;
  onAnswer: (isCorrect: boolean) => void;
  onNextQuestion: () => void;
  isCurrent: boolean;
  isAnswered: boolean;
  isLastQuestion: boolean;
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({
  question,
  onAnswer,
  onNextQuestion,
  isCurrent,
  isAnswered,
  isLastQuestion,
}) => {
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number | null>(
    null
  );
  const [showResult, setShowResult] = useState(false);
  const [transitionDirection, setTransitionDirection] = useState<
    'slide-left' | 'slide-right'
  >('slide-left');

  useEffect(() => {
    if (isCurrent) {
      setTransitionDirection('slide-left');
      const timer = setTimeout(() => {
        setTransitionDirection('slide-right');
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isCurrent]);

  const handleAnswerClick = (index: number) => {
    if (showResult) return;
    setSelectedAnswerIndex(index);
    setShowResult(true);
    const isCorrect = question.answers?.[index]?.correct || false;
    onAnswer(isCorrect);
  };

  const allAnswersCorrect =
    question.answers?.every((answer) => answer.correct) || false;

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Transition
        mounted={isCurrent}
        transition={transitionDirection}
        duration={300}
        timingFunction="ease"
      >
        {(styles) => (
          <Flex
            direction="column"
            align="center"
            p="xl"
            style={{ ...styles, height: '100%' }}
          >
            <Text
              size="xl"
              fw="bold"
              mb="xl"
              c="white"
              style={{ fontSize: '24px', textAlign: 'center' }}
            >
              {question.questionValue}
            </Text>
            <Flex
              direction="column"
              gap="md"
              align="center"
              style={{ width: '100%', maxWidth: '600px' }}
            >
              {question.answers?.map((answer, index) => (
                <QuizAnswer
                  key={index}
                  answer={answer.answerValue}
                  isSelected={selectedAnswerIndex === index}
                  isCorrect={answer.correct}
                  onClick={() => handleAnswerClick(index)}
                  showResult={showResult}
                  allAnswersCorrect={allAnswersCorrect}
                />
              ))}
            </Flex>
            {showResult && (
              <Button onClick={onNextQuestion} mt="xl">
                {isLastQuestion ? 'Finish Quiz' : 'Next Question'}
              </Button>
            )}
          </Flex>
        )}
      </Transition>
    </Box>
  );
};

export default QuizQuestion;
