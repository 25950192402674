import { BaseDTO, ToolboxItem } from '../types/itemType';

const sortItems = <T extends BaseDTO>(items: T[]): T[] => {
  return [...items].sort((a, b) => {
    if (a.displayOrder !== b.displayOrder) {
      return a.displayOrder - b.displayOrder;
    }
    return a.name.localeCompare(b.name);
  });
};

export const sortToolboxItems = (items: ToolboxItem[]): ToolboxItem[] => {
  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.type]) {
      acc[item.type] = [];
    }
    acc[item.type].push(item);
    return acc;
  }, {} as Record<ToolboxItem['type'], ToolboxItem[]>);

  return Object.entries(groupedItems).flatMap(([type, items]) => {
    const sorted = sortItems(items.map((item) => item.data));
    return sorted.map((data, index) => ({
      type: type as ToolboxItem['type'],
      data: data,
    })) as ToolboxItem[];
  });
};
