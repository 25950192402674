import { useTaskManager } from '@btrway/task-manager';
import { Card, Text } from '@mantine/core';
import { useAtomValue } from 'jotai';
import React from 'react';
import TaskListGrid from '../TaskListGrid/TaskListGrid';

interface PersonTaskListGridViewProps {
  personId: number;
}

const PersonTaskListGridView: React.FC<PersonTaskListGridViewProps> = ({
  personId,
}) => {
  const { getPersonTasksAtom } = useTaskManager();
  const personTasksAtom = getPersonTasksAtom(personId);
  const personTasks = useAtomValue(personTasksAtom);

  if (!personTasks.length) {
    return (
      <Card withBorder p="md">
        <Text c="dimmed">No tasks found</Text>
      </Card>
    );
  }

  return <TaskListGrid tasks={personTasks} />;
};

export default PersonTaskListGridView;
