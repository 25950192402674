import { Group, Title } from '@mantine/core';
import React from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import OrgAccessButton from '../OrgAccessButton/OrgAccessButton';

export const OrganizationHeader: React.FC = () => {
  const { organization } = useOrganization();

  if (!organization) {
    return null;
  }

  return (
    <Group gap="xl" wrap="nowrap">
      <Title order={3}>{organization.name}</Title>
      <OrgAccessButton organizationId={organization.id} />
    </Group>
  );
};
