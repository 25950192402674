import {
  CommonWorkflow,
  WorkflowService,
} from '@btrway/workflow-configuration-manager';
import React, { createContext, useReducer } from 'react';
import { DisplayLayout } from '../components/DisplayLayout/DisplayLayout';
import { LAYOUT } from '../constants/layout';
import { DisplayConfig } from '../types/display';
import { Screen } from '../types/screen';
import { Action, State, WorkflowBuilderContextValue } from '../types/state';
import { getDefaultDisplayConfig } from '../utils/screenUtils';

const defaultDisplayConfig: DisplayConfig = {
  mode: 'panel',
  position: 'left',
  width: LAYOUT.PANEL.DEFAULT_WIDTH,
};

const initialState: State = {
  currentScreen: null,
  displayState: {
    isOpen: false,
    content: null,
    config: defaultDisplayConfig,
  },
  currentTaskConfig: null,
  currentCertificationConfig: null,
  currentStepConfig: null,
  currentEventConfig: null,
  parentWorkflow: null,
  nestedWorkflow: null,
  nestedWorkflowService: null,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'OPEN_SCREEN':
      return {
        ...state,
        currentScreen: action.screen,
        displayState: {
          isOpen: true,
          content: null,
          config: action.config || getDefaultDisplayConfig(action.screen),
        },
      };

    case 'CLOSE_SCREEN':
      return {
        ...state,
        currentScreen: null,
        displayState: {
          ...state.displayState,
          isOpen: false,
        },
      };

    case 'UPDATE_TASK_CONFIG':
      return {
        ...state,
        currentTaskConfig: action.config,
      };

    case 'UPDATE_CERTIFICATION_CONFIG':
      return {
        ...state,
        currentCertificationConfig: action.config,
      };

    case 'UPDATE_STEP_CONFIG':
      return {
        ...state,
        currentStepConfig: action.config,
      };

    case 'UPDATE_EVENT_CONFIG':
      return {
        ...state,
        currentEventConfig: action.config,
      };

    case 'SET_PARENT_WORKFLOW':
      return {
        ...state,
        parentWorkflow: action.workflow,
      };

    case 'RESET':
      return {
        ...initialState,
        parentWorkflow: state.parentWorkflow,
        nestedWorkflow: state.nestedWorkflow,
        nestedWorkflowService: state.nestedWorkflowService,
      };

    case 'SET_NESTED_WORKFLOW':
      return {
        ...state,
        nestedWorkflow: action.workflow,
      };

    case 'SET_NESTED_WORKFLOW_SERVICE':
      return {
        ...state,
        nestedWorkflowService: action.service,
      };

    default:
      return state;
  }
}

export const WorkflowBuilderContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
} | null>(null);

interface WorkflowBuilderStateProviderProps {
  children: React.ReactNode;
  parentWorkflow: CommonWorkflow | null;
}

export const WorkflowBuilderStateProvider: React.FC<
  WorkflowBuilderStateProviderProps
> = ({ children, parentWorkflow }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    parentWorkflow: parentWorkflow ?? null,
  });

  const contextValue: WorkflowBuilderContextValue = {
    state,
    openScreen: (screen: Screen, config?: DisplayConfig) => {
      dispatch({
        type: 'OPEN_SCREEN',
        screen,
        config: config || getDefaultDisplayConfig(screen),
      });
    },
    openInitialScreen: (
      screen: Screen,
      config: DisplayConfig = { mode: 'panel', position: 'left' },
      nestedWorkflowService?: WorkflowService | null,
      nestedWorkflow?: CommonWorkflow | null
    ) => {
      if (nestedWorkflowService) {
        dispatch({
          type: 'SET_NESTED_WORKFLOW_SERVICE',
          service: nestedWorkflowService,
        });
        if (nestedWorkflow) {
          dispatch({
            type: 'SET_NESTED_WORKFLOW',
            workflow: nestedWorkflow,
          });
        }
      }
      dispatch({
        type: 'OPEN_SCREEN',
        screen,
        config: config || getDefaultDisplayConfig(screen),
      });
    },
    closeScreen: () => {
      dispatch({ type: 'CLOSE_SCREEN' });
    },
    updateTaskConfig: (config) => {
      dispatch({ type: 'UPDATE_TASK_CONFIG', config });
    },
    updateCertificationConfig: (config) => {
      dispatch({ type: 'UPDATE_CERTIFICATION_CONFIG', config });
    },
    updateStepConfig: (config) => {
      dispatch({ type: 'UPDATE_STEP_CONFIG', config });
    },
    updateEventConfig: (config) => {
      dispatch({ type: 'UPDATE_EVENT_CONFIG', config });
    },
    reset: () => {
      dispatch({ type: 'RESET' });
    },
    setParentWorkflow: (workflow) => {
      dispatch({ type: 'SET_PARENT_WORKFLOW', workflow });
    },
    setNestedWorkflow: (workflow) => {
      dispatch({ type: 'SET_NESTED_WORKFLOW', workflow });
    },
    setNestedWorkflowService: (service: WorkflowService | null) => {
      dispatch({ type: 'SET_NESTED_WORKFLOW_SERVICE', service });
    },
  };

  return (
    <WorkflowBuilderContext.Provider value={{ state, dispatch }}>
      <DisplayLayout>{children}</DisplayLayout>
    </WorkflowBuilderContext.Provider>
  );
};

export function useWorkflowBuilderContext() {
  const context = React.useContext(WorkflowBuilderContext);
  if (!context) {
    throw new Error(
      'useWorkflowBuilderContext must be used within a WorkflowBuilderStateProvider'
    );
  }
  return context;
}
