import {
  DateAssignedTypeEnum,
  DateDueTypeEnum,
  TaskListDateMethodEnum,
  TaskListSettings,
} from '@btrway/api-workflow';
import { MonthDaySelector } from '@btrway/shared-components';
import { SETTINGS_PANEL_LAYOUT } from '@btrway/workflow-builder-settings-panel';
import { useWorkflowUpdater } from '@btrway/workflow-configuration-manager';
import {
  Box,
  Button,
  Group,
  NumberInput,
  Radio,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface TaskListDateConfigurationProps {
  workflowKey: string;
  initialConfig?: TaskListSettings;
  onClose: () => void;
}

export const TaskListDateConfiguration: React.FC<
  TaskListDateConfigurationProps
> = ({ workflowKey, initialConfig, onClose }) => {
  const { updateConfig } = useWorkflowUpdater();
  const [config, setConfig] = useState<TaskListSettings>(() => {
    const baseConfig = initialConfig || {};
    if (
      baseConfig.monthValue === undefined &&
      baseConfig.dayValue === undefined
    ) {
      return {
        ...baseConfig,
        monthValue: 6,
        dayValue: 1,
      };
    }
    return baseConfig;
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (initialConfig) {
      if (
        initialConfig.monthValue === undefined &&
        initialConfig.dayValue === undefined
      ) {
        setConfig({
          ...initialConfig,
          monthValue: 6,
          dayValue: 1,
        });
      } else {
        setConfig(initialConfig);
      }
    }
  }, [initialConfig]);

  const handleSave = async () => {
    try {
      // If not using taskSpecific for assignment, ensure due date is set to relative
      const finalConfig = {
        ...config,
        dueDateType:
          config.assignedDateType === DateAssignedTypeEnum.taskSpecific
            ? DateDueTypeEnum.taskSpecific
            : DateDueTypeEnum.relative,
      };

      await updateConfig({
        workflowConfig: {
          settings: {
            taskListSettings: finalConfig,
          },
        },
      });
      setHasUnsavedChanges(false);
      onClose();
    } catch (error) {
      console.error('Error saving task list config:', error);
    }
  };

  const updateTaskListConfig = (updates: Partial<TaskListSettings>) => {
    setConfig((prev) => ({ ...prev, ...updates }));
    setHasUnsavedChanges(true);
  };

  const handleDateMethodChange = (value: TaskListDateMethodEnum) => {
    const updates: Partial<TaskListSettings> = {
      taskListDateMethod: value,
    };

    if (value === TaskListDateMethodEnum.byDueDate) {
      updates.dueDateType = DateDueTypeEnum.fixed;
      // updates.assignedDateType = undefined;
      // updates.assignedDateValue = undefined;
    }

    updateTaskListConfig(updates);
  };

  const handleMonthDayChange = (
    month: number | undefined,
    day: number | undefined
  ) => {
    updateTaskListConfig({
      monthValue: month,
      dayValue: day,
    });
  };

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        style={{
          flex: 1,
          overflow: 'auto',
          paddingBottom: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT + 16}px`,
        }}
      >
        <Box p="1rem">
          <Stack gap="xl">
            {/* Task List Date Section */}
            <Stack gap="md">
              <Title order={4}>Task List Date</Title>
              <Text size="sm">
                Configure whether task lists are based on start or due dates:
              </Text>
              <Box
                p="md"
                style={{ border: '1px solid var(--mantine-color-gray-4)' }}
              >
                <Radio.Group
                  value={config.taskListDateMethod || 'byStartDate'}
                  onChange={(value) =>
                    handleDateMethodChange(value as TaskListDateMethodEnum)
                  }
                >
                  <Stack gap="xs">
                    <Radio value="byStartDate" label="Start Date" />
                    <Radio value="byDueDate" label="Due Date" />
                  </Stack>
                </Radio.Group>
              </Box>
              <Text size="sm">Choose the annual month and day:</Text>
              <Box style={{ border: '1px solid var(--mantine-color-gray-4)' }}>
                <MonthDaySelector
                  month={config.monthValue}
                  day={config.dayValue}
                  onChange={handleMonthDayChange}
                />
              </Box>
            </Stack>

            {/* Assignment Date Section - Only show for byStartDate */}
            {config.taskListDateMethod ===
              TaskListDateMethodEnum.byStartDate && (
              <Stack gap="md">
                <Title order={4}>Individual Task Assignment</Title>
                <Text size="sm">
                  Configure when individual tasks should be assigned
                </Text>
                <Radio.Group
                  value={config.assignedDateType || 'immediate'}
                  onChange={(value) =>
                    updateTaskListConfig({
                      assignedDateType: value as DateAssignedTypeEnum,
                    })
                  }
                >
                  <Stack gap="xs">
                    <Radio
                      value="taskSpecific"
                      label="Specify assign and due dates by task"
                    />
                    <Radio
                      value="immediate"
                      label="Assign all tasks immediately"
                    />
                    <Radio
                      value="incremental"
                      label="Incrementally assign tasks"
                    />
                  </Stack>
                </Radio.Group>

                {config.assignedDateType ===
                  DateAssignedTypeEnum.incremental && (
                  <NumberInput
                    label="Days between assignments"
                    value={config.assignedDateValue || 0}
                    onChange={(value) =>
                      updateTaskListConfig({
                        assignedDateValue: value as number,
                      })
                    }
                    min={0}
                  />
                )}

                {/* Due Date Information */}
                <Box>
                  {config.assignedDateType ===
                  DateAssignedTypeEnum.taskSpecific ? (
                    <></>
                  ) : (
                    <>
                      <NumberInput
                        label="Days after assignment that they are due"
                        value={config.dueDateValue || 0}
                        onChange={(value) =>
                          updateTaskListConfig({
                            dueDateValue: value as number,
                          })
                        }
                        min={0}
                      />
                    </>
                  )}
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>

      {/* Fixed Footer */}
      <Group
        justify="flex-end"
        p="md"
        style={{
          borderTop: '1px solid var(--mantine-color-gray-3)',
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'var(--mantine-color-gray-1)',
          height: `${SETTINGS_PANEL_LAYOUT.FOOTER_HEIGHT}px`,
        }}
      >
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!hasUnsavedChanges}>
          Save
        </Button>
      </Group>
    </Box>
  );
};
