import { SearchUserResponse } from '@btrway/api-core';
import {
  GlobalUserSearchInput,
  GlobalUsersGrid,
} from '@btrway/global-users-components';
import { Box, Center, Flex } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import WorkgroupsGridView from '../WorkgroupsGridView/WorkgroupsGridView';

const OrganizationEntitiesView: React.FC = () => {
  const { organization } = useOrganization();
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState<SearchUserResponse | null>(
    null
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleRowSelected = useCallback((params: any) => {
    if (params.node.isSelected()) {
      //   setSelectedUser(params.data as SearchUserResponse);
    }
  }, []);

  useEffect(() => {
    setSelectedUser(null);
  }, [searchValue]);

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box mb="md">
        <Flex justify="space-between" align="center">
          <Center w={1500}>
            <GlobalUserSearchInput onSearch={handleSearch} />
          </Center>
        </Flex>
      </Box>

      <Box
        style={{
          display: 'flex',
          flex: '1',
          marginTop: '20px',
          height: 'calc(100% - 60px)', // Adjust based on your header height
          minHeight: '500px', // Ensure minimum height for the grid
        }}
      >
        <Box style={{ flex: '1', marginRight: '20px', height: '100%' }}>
          {searchValue.length >= 2 ? (
            <GlobalUsersGrid
              searchValue={searchValue}
              onRowSelected={handleRowSelected}
              organizationId={organization.id}
            />
          ) : (
            <WorkgroupsGridView />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationEntitiesView;
