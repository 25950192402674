import { useAPIClient } from '@btrway/api-client-provider';
import { useAuth } from '@btrway/auth-core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { queryClient } = useAPIClient();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        logout();
        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Error during logout:', error);
        // Still try to navigate to login even if there's an error
        navigate('/login', { replace: true });
      }
    };

    handleLogout();
  }, [logout, navigate, queryClient]);

  return null;
};

export default Logout;
