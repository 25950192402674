import { PropertyReference, StepConfig } from '@btrway/api-workflow';
import {
  usePreviousStepProperties,
  useSelectedItem,
  workflowStepAtomFamily,
} from '@btrway/workflow-configuration-manager';
import { Button, Card, Modal, ScrollArea, Stack, Text } from '@mantine/core';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback, useState } from 'react';
import { Action, PageModel, Question, SurveyModel } from 'survey-core';
import { SurveyCreator } from 'survey-creator-react';
import './DataBindingAdorner.css';

// Function to create the Data Binding Action
export const createDataBindingAction = (
  question: Question,
  openModal: () => void
) => {
  const isBound = question.dataBinding;
  return new Action({
    id: 'dataBindingAdorner',
    title: isBound ? 'Data Bound' : 'Data Binding',
    css: isBound ? 'data-binding-adorner' : '',
    iconName: 'icon-fast-entry',
    action: () => {
      console.log('Opening data binding adorner for question:', question.name);
      openModal();
    },
    visibleIndex: 9,
  });
};

// Modal/Dialog to handle data binding
export const DataBindingDialog: React.FC<{
  question: Question;
  onClose: () => void;
  creator: SurveyCreator;
}> = ({ question, onClose, creator }) => {
  const { selectedItem } = useSelectedItem();
  const selectedStepKey =
    selectedItem?.type === 'step' ? selectedItem.key : null;

  const getSelectedStep = useAtomCallback(
    useCallback(
      (get) => {
        if (!selectedStepKey) return null;
        return get(workflowStepAtomFamily(selectedStepKey));
      },
      [selectedStepKey]
    )
  );

  const setStep = useAtomCallback(
    useCallback(
      (get, set, update: Partial<StepConfig>) => {
        if (selectedStepKey) {
          set(workflowStepAtomFamily(selectedStepKey), update);
        }
      },
      [selectedStepKey]
    )
  );

  const previousStepProperties = usePreviousStepProperties(
    selectedStepKey || ''
  );
  const [selectedPreviousProp, setSelectedPreviousProp] =
    useState<PropertyReference | null>(null);

  const isBound = question.dataBinding;
  const currentBinding = question.dataBinding;

  // Function to generate a unique key for a property
  const getUniqueKey = (prop: PropertyReference): string => {
    return `${prop.stepKey}-${prop.propertyKey}-${prop.entityProperty}`;
  };

  const handlePreviousPropClick = (prop: PropertyReference) => {
    setSelectedPreviousProp(prop);
  };

  // Update or change the binding
  const handleBind = () => {
    if (!selectedPreviousProp || !selectedStepKey) return;

    try {
      const selectedStep = getSelectedStep();
      if (!selectedStep?.formConfig) return;

      // Create SurveyJS model from the form config
      const survey = new SurveyModel(selectedStep.formConfig);

      // Find and update the targeted question
      survey.pages.forEach((page: PageModel) => {
        page.questions.forEach((element: Question) => {
          if (element.name === question.name) {
            // Remove previous binding if it exists
            if (element.getPropertyValue('dataBinding')) {
              element.setPropertyValue('dataBinding', null);
            }

            // Set the new dataBinding property
            element.setPropertyValue('dataBinding', {
              stepKey: selectedPreviousProp.stepKey,
              propertyKey: selectedPreviousProp.propertyKey,
              label: selectedPreviousProp.label,
              dataType: selectedPreviousProp.dataType,
              entityProperty: selectedPreviousProp.entityProperty,
            });
          }
        });
      });

      // Get the updated configuration
      const newFormConfig = survey.toJSON();

      // Update the step with new config
      setStep({ formConfig: newFormConfig });

      // Update the survey creator if it exists
      if (creator) {
        creator.JSON = newFormConfig;
      }

      onClose();
    } catch (error) {
      console.error('Error updating form configuration:', error);
    }
  };

  const handleDeleteBind = () => {
    if (!selectedStepKey) return;

    try {
      const selectedStep = getSelectedStep();
      if (!selectedStep?.formConfig) return;

      // Create SurveyJS model from the form config
      const survey = new SurveyModel(selectedStep.formConfig);

      // Find and remove the data binding from the targeted question
      survey.pages.forEach((page: PageModel) => {
        page.questions.forEach((element: Question) => {
          if (element.name === question.name) {
            // Remove the dataBinding property if it exists
            if (element.getPropertyValue('dataBinding')) {
              element.setPropertyValue('dataBinding', null);
            }
          }
        });
      });

      // Get the updated configuration
      const newFormConfig = survey.toJSON();

      // Update the step with new config
      setStep({ formConfig: newFormConfig });

      // Update the survey creator if it exists
      if (creator) {
        creator.JSON = newFormConfig;
      }

      onClose();
    } catch (error) {
      console.error('Error deleting data binding:', error);
    }
  };

  const renderProperties = (
    properties: PropertyReference[],
    title: string,
    handleClick: (prop: PropertyReference) => void,
    selectedProp: PropertyReference | null
  ) => (
    <Stack>
      <Text fz="sm" fw={600}>
        {title}
      </Text>
      <ScrollArea style={{ height: 400 }}>
        <Stack>
          {properties.map((prop) => {
            const isCurrentlyBound =
              currentBinding &&
              currentBinding.stepKey === prop.stepKey &&
              currentBinding.propertyKey === prop.propertyKey &&
              currentBinding.entityProperty === prop.entityProperty;

            return (
              <Card
                key={getUniqueKey(prop)}
                shadow="sm"
                padding="sm"
                radius="md"
                withBorder
                onClick={() => handlePreviousPropClick(prop)}
                style={{
                  backgroundColor: isCurrentlyBound
                    ? '#4caf50'
                    : selectedProp &&
                      getUniqueKey(selectedProp) === getUniqueKey(prop)
                    ? '#f0f0f0'
                    : '',
                  cursor: 'pointer',
                }}
              >
                <Text fz="xs" mb="xs">
                  {prop.label}
                </Text>
              </Card>
            );
          })}
        </Stack>
      </ScrollArea>
    </Stack>
  );

  function forceQuestionRerender(question: Question) {
    question.registerPropertyChangedHandlers(['dataBinding'], () => {
      question.afterRerender();
    });
  }

  return (
    <Modal
      opened={true}
      onClose={onClose}
      title={`Data Binding - ${question.title}`}
      centered
    >
      <Stack>
        {renderProperties(
          previousStepProperties,
          'Previous Step Properties',
          handlePreviousPropClick,
          selectedPreviousProp
        )}
        <Button onClick={handleBind} disabled={!selectedPreviousProp}>
          {isBound ? 'Change Binding' : 'Bind Property'}
        </Button>
        {isBound && <Button onClick={handleDeleteBind}>Delete Binding</Button>}
      </Stack>
    </Modal>
  );
};
