import { DataTypeEnum, RuleOperatorTypeEnum } from '@btrway/api-workflow';
import { Checkbox, NumberInput, TextInput } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import React from 'react';

interface DataTypeValueSelectorProps {
  dataType: DataTypeEnum;
  operator?: RuleOperatorTypeEnum;
  values: any[];
  onChange: (values: any[]) => void;
}

const DataTypeValueSelector: React.FC<DataTypeValueSelectorProps> = ({
  dataType,
  operator,
  values,
  onChange,
}) => {
  const handleChange = (value: any) => {
    onChange([value]);
  };

  const renderInput = () => {
    switch (dataType) {
      case DataTypeEnum.text:
      case DataTypeEnum.email:
      case DataTypeEnum.phone:
        return (
          <TextInput
            autoFocus
            value={values[0] || ''}
            onChange={(event) => handleChange(event.currentTarget.value)}
          />
        );
      case DataTypeEnum.number:
      case DataTypeEnum.integer:
        return (
          <NumberInput
            autoFocus
            value={values[0] || ''}
            onChange={(value) => handleChange(value)}
          />
        );
      case DataTypeEnum.boolean:
        return (
          <Checkbox
            checked={values[0] || false}
            onChange={(event) => handleChange(event.currentTarget.checked)}
          />
        );
      case DataTypeEnum.date:
        return (
          <DatePicker
            value={values[0] ? new Date(values[0]) : null}
            onChange={(date) => handleChange(date)}
          />
        );
      case DataTypeEnum.time:
        return (
          <TimeInput
            autoFocus
            value={values[0] || ''}
            onChange={(event) => handleChange(event.currentTarget.value)}
          />
        );
      // Add more cases for other data types as needed
      default:
        return (
          <TextInput
            autoFocus
            value={values[0] || ''}
            onChange={(event) => handleChange(event.currentTarget.value)}
          />
        );
    }
  };

  return renderInput();
};

export default DataTypeValueSelector;
