import {
  CourseTaskProperties,
  DataTypeEnum,
  EntityTaskResponse,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import {
  CourseStatus,
  useCoursewareProgress,
} from '@btrway/courseware-progress-manager';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import {
  personTasksAtomFamily,
  taskInstancesAtom,
} from '../atoms/taskInstancesAtoms';
import { reconcileTaskInstances } from '../utils/reconcileTaskInstances';

export const useTaskManager = () => {
  const [tasks, setTasks] = useAtom(taskInstancesAtom);
  const { currentPerson } = useAuthenticatedUser();
  const { getAllCourseProgress } = useCoursewareProgress();

  // Filtered task lists
  const myTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.assignedEntityType === DataTypeEnum.userRole ||
          (task.assignedEntityType === DataTypeEnum.person &&
            task.assignedEntityId === currentPerson.id)
      ),
    [tasks, currentPerson.id]
  );

  const myAssignedTasks = useMemo(
    () => myTasks.filter((task) => !task.completed && !task.rollupWorkflowKey),
    [myTasks]
  );

  const completedTasks = useMemo(
    () => tasks.filter((task) => task.completed),
    [tasks]
  );

  // Get incomplete course tasks that have been started
  const incompleteCourseTasks = useMemo(() => {
    const allProgress = getAllCourseProgress();

    return myTasks.filter((task) => {
      if (
        task.completed ||
        task.taskType !== TaskTypeEnum.completeCourse ||
        !task.taskProperties
      ) {
        return false;
      }

      const properties = task.taskProperties as CourseTaskProperties;
      const courseId = properties.courseSelection.courseUid?.value;

      if (courseId === undefined) {
        return false;
      }

      const progress = allProgress[courseId];
      return progress && progress.status === CourseStatus.Started;
    });
  }, [myTasks, getAllCourseProgress]);

  // Get assigned course tasks that haven't been started
  const assignedUnstartedCourseTasks = useMemo(() => {
    const allProgress = getAllCourseProgress();

    return myTasks.filter((task) => {
      if (
        task.completed ||
        task.taskType !== TaskTypeEnum.completeCourse ||
        !task.taskProperties
      ) {
        return false;
      }

      const properties = task.taskProperties as CourseTaskProperties;
      const courseId = properties.courseSelection.courseUid?.value;

      if (courseId === undefined) {
        return false;
      }

      const progress = allProgress[courseId];
      return !progress || progress.status === CourseStatus.NotStarted;
    });
  }, [myTasks, getAllCourseProgress]);

  const currentMonthTasks = useMemo(() => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    return myTasks.filter((task) => {
      if (!task.assignedAt) return false;
      const assignedDate = new Date(task.assignedAt);
      return (
        !task.completed &&
        task.rollupWorkflowKey && // Check for task list association
        assignedDate.getMonth() === currentMonth &&
        assignedDate.getFullYear() === currentYear
      );
    });
  }, [myTasks]);

  // Task grouping by person
  const tasksByPerson = useMemo(() => {
    const taskMap = new Map<number, EntityTaskResponse[]>();
    tasks.forEach((task) => {
      if (
        task.rollupWorkflowKey && // Check for task list association
        task.assignedEntityType === DataTypeEnum.person &&
        task.assignedEntityId
      ) {
        if (!taskMap.has(task.assignedEntityId)) {
          taskMap.set(task.assignedEntityId, []);
        }
        taskMap.get(task.assignedEntityId)?.push(task);
      }
    });
    return taskMap;
  }, [tasks]);

  const getTaskListTasksByPerson = useCallback(
    (personId: number) => tasksByPerson.get(personId) || [],
    [tasksByPerson]
  );

  const taskListTasks = useMemo(
    () => (currentPerson.id ? getTaskListTasksByPerson(currentPerson.id) : []),
    [currentPerson.id, getTaskListTasksByPerson]
  );

  const updateTask = useCallback(
    (task: EntityTaskResponse) => {
      setTasks((prevTasks) => reconcileTaskInstances(prevTasks, [task]));
    },
    [setTasks]
  );

  const getPersonTasksAtom = useCallback(
    (personId: number) => personTasksAtomFamily(personId),
    []
  );

  return {
    tasks,
    myTasks,
    assignedTasks: myAssignedTasks,
    completedTasks,
    taskListTasks,
    currentMonthTasks,
    incompleteCourseTasks,
    assignedUnstartedCourseTasks,
    getTaskListTasksByPerson,
    updateTask,
    getPersonTasksAtom,
  };
};
