import { Card, Grid, Group, Stack, Text, TextInput } from '@mantine/core';
import React from 'react';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';

const RegistrantInfo: React.FC = () => {
  const { registration, updateRegistrant } = useRegistrationSubmission();

  const registrant = registration.persons.find(
    (person) => person.personKey === registration.registrantPersonKey
  );

  if (!registrant) return null;

  return (
    <Stack>
      <Card withBorder radius="sm" padding="sm" shadow="none" bg="yellow.1">
        <Text size="lg" fw={500}>
          Please verfiy that this information is correct, if not please correct
          it below
        </Text>
      </Card>

      <Card withBorder radius="sm" padding="lg" shadow="none">
        <Grid gutter="xl">
          <Grid.Col span={12}>
            <Group grow gap="xl">
              <TextInput
                label="Your First Name"
                value={registrant.firstName}
                onChange={(event) =>
                  updateRegistrant('firstName', event.currentTarget.value)
                }
                autoFocus
                required
              />
              <TextInput
                label="Your Last Name"
                value={registrant.lastName}
                onChange={(event) =>
                  updateRegistrant('lastName', event.currentTarget.value)
                }
                required
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Group grow gap="xl">
              <TextInput
                label="Your Email Address"
                type="email"
                value={registrant.email}
                onChange={(event) =>
                  updateRegistrant('email', event.currentTarget.value)
                }
                required
              />
              <TextInput
                label="Your Mobile Phone Number"
                value={registrant.mobilePhone}
                onChange={(event) =>
                  updateRegistrant('mobilePhone', event.currentTarget.value)
                }
              />
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
    </Stack>
  );
};

export default RegistrantInfo;
