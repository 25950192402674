import { AnswerDTO } from '@btrway/api-courseware';
import { ActionIcon, Card, Group, Stack, TextInput } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useChapter } from '../../hooks/useChapter';
import QuestionAnswers from '../QuestionAnswers/QuestionAnswers';

interface QuestionCardProps {
  chapterKey: string;
  questionIndex: number;
  onDeleteQuestion: () => void;
}

export interface QuestionCardRef {
  focus: () => void;
}

const QuestionCard = forwardRef<QuestionCardRef, QuestionCardProps>(
  ({ chapterKey, questionIndex, onDeleteQuestion }, ref) => {
    const { chapter, setChapter } = useChapter(chapterKey);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
    }));

    if (!chapter) return null;

    const question = chapter.questions?.[questionIndex];

    if (!question) return null;

    const handleQuestionChange = (value: string) => {
      const updatedQuestions = [...(chapter.questions || [])];
      updatedQuestions[questionIndex] = { ...question, questionValue: value };
      setChapter({ ...chapter, questions: updatedQuestions });
    };

    const handleAddAnswer = (correct: boolean) => {
      const newAnswer: AnswerDTO = {
        answerValue: '',
        correct: correct,
      };
      const updatedQuestions = [...(chapter.questions || [])];
      const newAnswers = [...(question.answers || []), newAnswer];
      updatedQuestions[questionIndex] = {
        ...question,
        answers: newAnswers,
      };
      setChapter({ ...chapter, questions: updatedQuestions });
    };

    const handleUpdateAnswer = (
      answerIndex: number,
      updatedAnswer: AnswerDTO
    ) => {
      const updatedQuestions = [...(chapter.questions || [])];
      const updatedAnswers = [...(question.answers || [])];
      updatedAnswers[answerIndex] = updatedAnswer;
      updatedQuestions[questionIndex] = {
        ...question,
        answers: updatedAnswers,
      };
      setChapter({ ...chapter, questions: updatedQuestions });
    };

    const handleDeleteAnswer = (answerIndex: number) => {
      const updatedQuestions = [...(chapter.questions || [])];
      const updatedAnswers = (question.answers || []).filter(
        (_, i) => i !== answerIndex
      );
      updatedQuestions[questionIndex] = {
        ...question,
        answers: updatedAnswers,
      };
      setChapter({ ...chapter, questions: updatedQuestions });
    };

    const handleReorderAnswers = (newAnswers: AnswerDTO[]) => {
      const updatedQuestions = [...(chapter.questions || [])];
      updatedQuestions[questionIndex] = { ...question, answers: newAnswers };
      setChapter({ ...chapter, questions: updatedQuestions });
    };

    return (
      <Card withBorder>
        <Stack gap="xs">
          <Group justify="apart">
            <TextInput
              ref={inputRef}
              value={question.questionValue}
              onChange={(event) =>
                handleQuestionChange(event.currentTarget.value)
              }
              placeholder="Enter question"
              style={{ flex: 1 }}
            />
            <ActionIcon
              onClick={onDeleteQuestion}
              color="gray"
              variant="subtle"
            >
              <IconTrash size={20} />
            </ActionIcon>
          </Group>
          <QuestionAnswers
            answers={question.answers || []}
            onAddAnswer={handleAddAnswer}
            onUpdateAnswer={handleUpdateAnswer}
            onDeleteAnswer={handleDeleteAnswer}
            onReorderAnswers={handleReorderAnswers}
          />
        </Stack>
      </Card>
    );
  }
);

export default QuestionCard;
