import { ActivityResponse } from '@btrway/api-activities';
import { Stack, Text } from '@mantine/core';
import React from 'react';
import { getFriendlyDescription } from '../../utils/getFriendlyDescription';

interface ActivityItemProps {
  activity: ActivityResponse;
}

export const ActivityItem: React.FC<ActivityItemProps> = ({ activity }) => {
  return (
    <Stack gap="xs" ta="left">
      <Text size="sm">{getFriendlyDescription(activity.activityType)}</Text>
      <Text size="xs" c="dimmed">
        {new Date(activity.activityDate).toLocaleString()}
      </Text>
    </Stack>
  );
};
