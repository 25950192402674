import { AssetResponse } from '@btrway/api-courseware';
import {
  CourseTaskProperties,
  EntityTaskResponse,
  TaskRetrievalRequestCompletionStatus,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CourseCarouselItem } from '@btrway/courseware-library-components';
import { useCourses } from '@btrway/courseware-manager';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  useTaskFetcher,
  useTaskManager,
  useTaskModal,
} from '@btrway/task-manager';
import { Card, Group, Text } from '@mantine/core';
import React, { useEffect } from 'react';

interface CurriculumsViewProps {
  personId?: number;
}

const CurriculumsView: React.FC<CurriculumsViewProps> = ({ personId }) => {
  const { openTask } = useTaskModal();
  const { fetchTasksWithCriteria } = useTaskFetcher();
  const { currentPerson } = useAuthenticatedUser();
  const { tasks } = useTaskManager();
  const { getCourse } = useCourses();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);

  const targetPersonId = personId || currentPerson.id;

  useEffect(() => {
    if (!targetPersonId) return;

    setIsLoading(true);
    fetchTasksWithCriteria(
      [targetPersonId],
      {
        workflowType: WorkflowTypeEnum.curriculum,
        completionStatus: TaskRetrievalRequestCompletionStatus.ALL,
      },
      {
        onSuccess: () => {
          setIsLoading(false);
        },
        onError: (err) => {
          setError(err as Error);
          setIsLoading(false);
        },
      }
    );
  }, [targetPersonId, fetchTasksWithCriteria]);

  const courseTasks = React.useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.rollupWorkflowType === WorkflowTypeEnum.curriculum &&
          task.taskType === TaskTypeEnum.completeCourse
      ),
    [tasks]
  );

  const handleTaskClick = (task: EntityTaskResponse) => {
    openTask({
      taskProperties: task.taskProperties || {},
      taskInstanceId: task.taskInstanceId,
      assignedEntityType: task.assignedEntityType,
      assignedEntityId: task.assignedEntityId,
      workgroupId: task.workgroupId,
      derivedWorkflowKey: task.parentWorkflowKey,
      taskKey: task.taskKey,
      taskType: task.taskType,
    });
  };

  const getCourseFromTask = (
    task: EntityTaskResponse
  ): AssetResponse | undefined => {
    const properties = task.taskProperties as CourseTaskProperties;
    if (!properties?.courseSelection?.courseUid?.value) return undefined;
    return getCourse(properties.courseSelection.courseUid.value);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Text>Loading...</Text>;
    }

    if (error) {
      return <Text c="red">Error loading tasks: {error.message}</Text>;
    }

    if (!courseTasks.length) {
      return (
        <Text c="dimmed" ta="center">
          No pending certifications
        </Text>
      );
    }

    return (
      <Group gap="md" wrap="wrap" style={{ width: '100%' }}>
        {courseTasks.map((task, index) => {
          const course = getCourseFromTask(task);
          if (!course) return null;

          return (
            <div
              key={task.taskInstanceId || task.taskKey}
              style={{ width: '300px' }}
            >
              <CourseCarouselItem
                course={course}
                onPlay={() => handleTaskClick(task)}
                isFirst={index === 0}
                isLast={index === courseTasks.length - 1}
                growthFactor={1.1}
                itemHeight={180}
              />
            </div>
          );
        })}
      </Group>
    );
  };

  return (
    <Card p="md" radius="lg" mih={800}>
      <Text fz="lg" fw={600} mb="sm">
        Courses
      </Text>
      {renderContent()}
    </Card>
  );
};

export default CurriculumsView;
