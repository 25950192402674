import {
  TaskStatisticsRequest,
  WorkflowTaskStatisticsResponse,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { LoadingSpinner } from '@btrway/shared-components';
import { useWorkgroup } from '@btrway/workgroup-components';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useFetchWorkgroupStats } from '../hooks/useFetchWorkgroupStats';
import { EnrichedWorkflowStats, EnrichedWorkgroupStats } from '../types/stats';

interface WorkgroupStatsContextValue {
  stats: EnrichedWorkgroupStats[] | undefined;
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
  getStatsByWorkflowType: (
    workflowType?: WorkflowTypeEnum
  ) => EnrichedWorkgroupStats[] | undefined;
}

const WorkgroupStatsContext = createContext<
  WorkgroupStatsContextValue | undefined
>(undefined);

export const useWorkgroupStats = () => {
  const context = useContext(WorkgroupStatsContext);
  if (!context) {
    throw new Error(
      'useWorkgroupStats must be used within a WorkgroupStatsProvider'
    );
  }
  return context;
};

interface WorkgroupStatsProviderProps {
  children: React.ReactNode;
  showLoading?: boolean;
}

export const WorkgroupStatsProvider: React.FC<WorkgroupStatsProviderProps> = ({
  children,
  showLoading = true,
}) => {
  const { workgroup } = useWorkgroup();

  const request = useMemo<TaskStatisticsRequest>(
    () => ({
      organizationId: workgroup.organizationId,
      workgroupId: workgroup.id,
    }),
    [workgroup.organizationId, workgroup.id]
  );

  const { data, isLoading, error, refetch } = useFetchWorkgroupStats(request);

  const getStatsByWorkflowType = useCallback(
    (workflowType?: WorkflowTypeEnum) => {
      if (!data || !workflowType) return data;

      return data.map((workgroupStats) => ({
        ...workgroupStats,
        workflowStatistics:
          workgroupStats.workflowStatistics?.filter(
            (stat) => stat.workflowType === workflowType
          ) ?? [],
        workflows:
          workgroupStats.workflows?.filter(
            (workflow) => workflow.workflowType === workflowType
          ) ?? [],
        workflowTypeStats:
          workgroupStats.workflowTypeStats?.filter(
            (stats) => stats.workflowType === workflowType
          ) ?? [],
        // Recalculate totals based on filtered workflows
        totalTaskLists:
          workgroupStats.workflowStatistics
            ?.filter((stat) => stat.workflowType === workflowType)
            .reduce((sum, stat) => sum + (stat.totalTaskLists ?? 0), 0) ?? 0,
        completedTaskLists:
          workgroupStats.workflowStatistics
            ?.filter((stat) => stat.workflowType === workflowType)
            .reduce((sum, stat) => sum + (stat.completedTaskLists ?? 0), 0) ??
          0,
        totalTasks:
          workgroupStats.workflowStatistics
            ?.filter((stat) => stat.workflowType === workflowType)
            .reduce((sum, stat) => sum + (stat.totalTasks ?? 0), 0) ?? 0,
        completedTasks:
          workgroupStats.workflowStatistics
            ?.filter((stat) => stat.workflowType === workflowType)
            .reduce((sum, stat) => sum + (stat.completedTasks ?? 0), 0) ?? 0,
        totalRemainingTasks:
          workgroupStats.workflows
            ?.filter((workflow) => workflow.workflowType === workflowType)
            .reduce(
              (sum, workflow) => sum + (workflow.remainingTasks ?? 0),
              0
            ) ?? 0,
        totalRemainingTaskLists:
          workgroupStats.workflows
            ?.filter((workflow) => workflow.workflowType === workflowType)
            .reduce(
              (sum, workflow) => sum + (workflow.remainingTaskLists ?? 0),
              0
            ) ?? 0,
        averageTaskProgress: calculateAverageTaskProgress(
          workgroupStats.workflows?.filter(
            (workflow) => workflow.workflowType === workflowType
          ) ?? []
        ),
        averageTaskListProgress: calculateAverageTaskListProgress(
          workgroupStats.workflows?.filter(
            (workflow) => workflow.workflowType === workflowType
          ) ?? []
        ),
        taskCompletionPercentage: calculateTaskCompletionPercentage(
          workgroupStats.workflowStatistics?.filter(
            (stat) => stat.workflowType === workflowType
          ) ?? []
        ),
        taskListCompletionPercentage: calculateTaskListCompletionPercentage(
          workgroupStats.workflowStatistics?.filter(
            (stat) => stat.workflowType === workflowType
          ) ?? []
        ),
        overdueWorkflowsCount:
          workgroupStats.workflows?.filter(
            (workflow) =>
              workflow.workflowType === workflowType && workflow.isOverdue
          ).length ?? 0,
      }));
    },
    [data]
  );

  if (isLoading && showLoading) {
    return <LoadingSpinner />;
  }

  return (
    <WorkgroupStatsContext.Provider
      value={{
        stats: data,
        isLoading,
        error,
        refetch,
        getStatsByWorkflowType,
      }}
    >
      {children}
    </WorkgroupStatsContext.Provider>
  );
};

// Helper functions with proper type handling
const calculateAverageTaskProgress = (
  workflows: EnrichedWorkflowStats[]
): number => {
  if (workflows.length === 0) return 0;
  const totalProgress = workflows.reduce(
    (sum, workflow) => sum + (workflow.progressPercentage ?? 0),
    0
  );
  return totalProgress / workflows.length;
};

const calculateAverageTaskListProgress = (
  workflows: EnrichedWorkflowStats[]
): number => {
  if (workflows.length === 0) return 0;
  const totalProgress = workflows.reduce(
    (sum, workflow) => sum + (workflow.taskListProgressPercentage ?? 0),
    0
  );
  return totalProgress / workflows.length;
};

const calculateTaskCompletionPercentage = (
  stats: WorkflowTaskStatisticsResponse[]
): number => {
  const totalTasks = stats.reduce(
    (sum, stat) => sum + (stat.totalTasks ?? 0),
    0
  );
  const completedTasks = stats.reduce(
    (sum, stat) => sum + (stat.completedTasks ?? 0),
    0
  );
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};

const calculateTaskListCompletionPercentage = (
  stats: WorkflowTaskStatisticsResponse[]
): number => {
  const totalTaskLists = stats.reduce(
    (sum, stat) => sum + (stat.totalTaskLists ?? 0),
    0
  );
  const completedTaskLists = stats.reduce(
    (sum, stat) => sum + (stat.completedTaskLists ?? 0),
    0
  );
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

// export const WorkgroupStatsProvider: React.FC<WorkgroupStatsProviderProps> = ({
//   children,
//   showLoading = true,
// }) => {
//   const { workgroup } = useWorkgroup();

//   const request = useMemo<TaskStatisticsRequest>(
//     () => ({
//       organizationId: workgroup.organizationId,
//       workgroupId: workgroup.id,
//     }),
//     [workgroup.organizationId, workgroup.id]
//   );

//   const { data, isLoading, error, refetch } = useFetchWorkgroupStats(request);

//   const getStatsByWorkflowType = useCallback(
//     (workflowType?: WorkflowTypeEnum) => {
//       if (!data || !workflowType) return data;

//       return data.map((workgroupStats) => ({
//         ...workgroupStats,
//         workflowStatistics:
//           workgroupStats.workflowStatistics?.filter(
//             (stat) => stat.workflowType === workflowType
//           ) ?? [],
//         workflows:
//           workgroupStats.workflows?.filter(
//             (workflow) => workflow.workflowType === workflowType
//           ) ?? [],
//         workflowTypeStats:
//           workgroupStats.workflowTypeStats?.filter(
//             (stats) => stats.workflowType === workflowType
//           ) ?? [],
//         // Recalculate totals based on filtered workflows
//         totalTaskLists:
//           workgroupStats.workflowStatistics
//             ?.filter((stat) => stat.workflowType === workflowType)
//             .reduce((sum, stat) => sum + (stat.totalTaskLists ?? 0), 0) ?? 0,
//         totalTasks:
//           workgroupStats.workflowStatistics
//             ?.filter((stat) => stat.workflowType === workflowType)
//             .reduce((sum, stat) => sum + (stat.totalTasks ?? 0), 0) ?? 0,
//         completedTasks:
//           workgroupStats.workflowStatistics
//             ?.filter((stat) => stat.workflowType === workflowType)
//             .reduce((sum, stat) => sum + (stat.completedTasks ?? 0), 0) ?? 0,
//         totalRemainingTasks:
//           workgroupStats.workflows
//             ?.filter((workflow) => workflow.workflowType === workflowType)
//             .reduce(
//               (sum, workflow) => sum + (workflow.remainingTasks ?? 0),
//               0
//             ) ?? 0,
//         averageProgress: calculateAverageProgress(
//           workgroupStats.workflows?.filter(
//             (workflow) => workflow.workflowType === workflowType
//           ) ?? []
//         ),
//         overdueWorkflowsCount:
//           workgroupStats.workflows?.filter(
//             (workflow) =>
//               workflow.workflowType === workflowType && workflow.isOverdue
//           ).length ?? 0,
//       }));
//     },
//     [data]
//   );

//   if (isLoading && showLoading) {
//     return <LoadingSpinner />;
//   }

//   return (
//     <WorkgroupStatsContext.Provider
//       value={{
//         stats: data,
//         isLoading,
//         error,
//         refetch,
//         getStatsByWorkflowType,
//       }}
//     >
//       {children}
//     </WorkgroupStatsContext.Provider>
//   );
// };

// // Helper function with proper type handling
// const calculateAverageProgress = (
//   workflows: EnrichedWorkflowStats[]
// ): number => {
//   if (workflows.length === 0) return 0;
//   const totalProgress = workflows.reduce(
//     (sum, workflow) => sum + (workflow.progressPercentage ?? 0),
//     0
//   );
//   return totalProgress / workflows.length;
// };
