import { ColDef, GetRowIdFunc } from '@ag-grid-community/core';
import {
  BadgeGridCell,
  BadgeGridCellParams,
  LinkGridCell,
  ResponsiveGrid,
} from '@btrway/grid-components';
import {
  CombinedWorkgroupResponse,
  usePerformanceColor,
} from '@btrway/workgroup-manager';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupSubgroupsGrid: React.FC = () => {
  const { workgroup } = useWorkgroup();

  const { getColorFromAPS } = usePerformanceColor();
  const navigate = useNavigate();

  const rowData = useMemo(() => {
    if (!workgroup?.children) return [];

    return workgroup.children;
    // return workgroupType
    //   ? workgroup.children.filter(
    //       (child) => child.workgroupType.id === workgroupType.id
    //     )
    //   : workgroup.children;
  }, [workgroup?.children]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        sort: 'asc',
        cellRenderer: LinkGridCell,
        cellRendererParams: (params: any) => {
          if (!params || !params.data) {
            return { value: { to: '#', displayText: 'N/A' } };
          }
          return {
            value: {
              to: `/app/workgroup/${params.data.id}`,
              displayText: params.data.name || 'Unnamed',
            },
          };
        },
        comparator: (valueA, valueB) =>
          (valueA || '').localeCompare(valueB || ''),
        flex: 2,
      },
      {
        field: 'assignedTasks',
        headerName: 'Assigned',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) => params.data.taskAggregation?.assignedTasks,
      },
      {
        field: 'overdueTasks',
        headerName: 'Overdue',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) => params.data.taskAggregation?.overdueTasks,
      },
      {
        field: 'totalCompletedTasks',
        headerName: 'Completed',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) =>
          params.data.taskAggregation?.totalCompletedTasks,
      },
      {
        field: 'completedOverdueTasks',
        headerName: 'Completed Late',
        sortable: true,
        type: 'numericColumn',
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) =>
          params.data.taskAggregation?.completedOverdueTasks,
      },
      {
        field: 'ontimePercent',
        headerName: 'Ontime %',
        sortable: true,
        type: 'numericColumn',
        cellRenderer: BadgeGridCell,
        cellRendererParams: (params: BadgeGridCellParams) => ({
          color: getColorFromAPS(
            params.data.taskAggregation?.adjustedPerformanceScore
          ),
        }),
        cellStyle: { textAlign: 'right' },
        sortingOrder: ['desc', 'asc', null],
        valueGetter: (params) => params.data.taskAggregation?.ontimePercent,
      },
    ],
    [getColorFromAPS]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const getRowId: GetRowIdFunc<CombinedWorkgroupResponse> = (params) => {
    return params.data?.id?.toString() || '';
  };

  const handleCardClick = (data: CombinedWorkgroupResponse) => {
    navigate(`/app/workgroup/${data.id}`);
  };

  return (
    <ResponsiveGrid<CombinedWorkgroupResponse>
      columnDefs={columnDefs}
      rowData={rowData}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      getRowId={getRowId}
      rowModelType="clientSide"
      primaryNavigationField="name"
      responsiveRowSpacing={10}
      onRowClick={handleCardClick}
    />
  );
};

export default WorkgroupSubgroupsGrid;
