/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type EventTypeEnum = typeof EventTypeEnum[keyof typeof EventTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventTypeEnum = {
  formSubmitted: 'formSubmitted',
  registrationSubmitted: 'registrationSubmitted',
  registrationProcessed: 'registrationProcessed',
  personCreated: 'personCreated',
  courseCompleted: 'courseCompleted',
  personUpdated: 'personUpdated',
  workgroupMemberCreated: 'workgroupMemberCreated',
  relationshipCreated: 'relationshipCreated',
  calendarEventCreated: 'calendarEventCreated',
  calendarEventUpdated: 'calendarEventUpdated',
  facilityRequested: 'facilityRequested',
  gameRescheduled: 'gameRescheduled',
  messageSent: 'messageSent',
  taskCompleted: 'taskCompleted',
  taskListEvaluationNeeded: 'taskListEvaluationNeeded',
  taskListCompleted: 'taskListCompleted',
  certificationAssigned: 'certificationAssigned',
  certificationEvaluationRequested: 'certificationEvaluationRequested',
} as const;
