import {
  StepConfig,
  TransitionConfig,
  useUpdateWorkflowDefinitionConfig,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { selectedItemAtom } from '../atoms/selectedItemAtom';
import { workflowConfigurationAtom } from '../atoms/workflowConfigurationAtom';

export const useWorkflowSteps = (workflowKey: string) => {
  const [workflowConfiguration, setWorkflowConfiguration] = useAtom(
    workflowConfigurationAtom
  );
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const { mutateAsync: updateWorkflowConfig } =
    useUpdateWorkflowDefinitionConfig();
  const { currentOrganization } = useAuthenticatedUser();

  useEffect(() => {
    if (!selectedItem?.key && workflowConfiguration?.steps?.length) {
      const firstStep =
        workflowConfiguration.steps.length > 1
          ? workflowConfiguration.steps[1]
          : workflowConfiguration.steps[0];
      setSelectedItem({ type: 'step', key: firstStep.stepKey });
    }
  }, [workflowConfiguration, selectedItem, setSelectedItem]);

  const getOutgoingTransitions = useMemo(() => {
    return (step: StepConfig): TransitionConfig[] => {
      if (!workflowConfiguration) return [];
      return (
        workflowConfiguration.transitions?.filter(
          (transition) => transition.sourceStepKey === step.stepKey
        ) || []
      );
    };
  }, [workflowConfiguration]);

  const getOrderedSteps = useMemo(() => {
    return (): StepConfig[] => {
      if (!workflowConfiguration?.steps || !workflowConfiguration.transitions) {
        return [];
      }

      const steps = [...workflowConfiguration.steps];
      const transitions = [...workflowConfiguration.transitions];

      // Find the starting step (no incoming transitions)
      const startStep = steps.find(
        (step) =>
          !transitions.some(
            (transition) => transition.targetStepKey === step.stepKey
          )
      );

      if (!startStep) return [];

      const orderedSteps: StepConfig[] = [startStep];
      let currentStep = startStep;

      while (orderedSteps.length < steps.length) {
        const outgoingTransition = transitions.find(
          (transition) => transition.sourceStepKey === currentStep.stepKey
        );

        if (!outgoingTransition) break;

        const nextStep = steps.find(
          (step) => step.stepKey === outgoingTransition.targetStepKey
        );

        if (nextStep) {
          orderedSteps.push(nextStep);
          currentStep = nextStep;
        } else {
          break;
        }
      }

      return orderedSteps;
    };
  }, [workflowConfiguration]);

  const getNextStep = (currentStepKey: string): StepConfig | null => {
    const orderedSteps = getOrderedSteps();
    const currentIndex = orderedSteps.findIndex(
      (step) => step.stepKey === currentStepKey
    );

    if (currentIndex === -1 || currentIndex === orderedSteps.length - 1) {
      return null; // Current step not found or it's the last step
    }

    return orderedSteps[currentIndex + 1];
  };

  const getPreviousStep = (currentStepKey: string): StepConfig | null => {
    const orderedSteps = getOrderedSteps();
    const currentIndex = orderedSteps.findIndex(
      (step) => step.stepKey === currentStepKey
    );

    if (currentIndex <= 0) {
      return null; // Current step not found or it's the first step
    }

    return orderedSteps[currentIndex - 1];
  };

  return {
    workflowConfiguration,
    getOrderedSteps,
    getOutgoingTransitions,
    getNextStep,
    getPreviousStep,
  };
};
