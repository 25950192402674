import {
  PersonResponse,
  useDeleteRelationship,
  useGetRelationshipsByPersonIdAndOrganizationId,
} from '@btrway/api-core';
import { ActionIcon, Anchor, Card, Group, Stack, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddRelationshipModal from '../AddRelationshipModal/AddRelationshipModal';

interface PersonRelationshipsViewProps {
  person: PersonResponse;
}

const PersonRelationshipsView: React.FC<PersonRelationshipsViewProps> = ({
  person,
}) => {
  const navigate = useNavigate();
  const {
    data: relationshipsResponse,
    isLoading,
    refetch,
  } = useGetRelationshipsByPersonIdAndOrganizationId(
    person.organizationId,
    person.id
  );

  const { mutateAsync: deleteRelationship } = useDeleteRelationship();

  if (isLoading) {
    return null;
  }

  const handleDeleteRelationship = async (relationshipId: number) => {
    try {
      await deleteRelationship({ relationshipId });
      refetch();
    } catch (error) {
      console.error('Failed to delete relationship:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const renderRelationshipDescription = (relationship: any) => {
    if (relationship.fromPerson.id === person.id) {
      return (
        <>
          <Text span>{relationship.relationshipType.name} of </Text>
          <Anchor
            onClick={() => navigate(`/app/person/${relationship.toPerson.id}`)}
          >
            {relationship.toPerson.firstName} {relationship.toPerson.lastName}
          </Anchor>
        </>
      );
    } else {
      return (
        <>
          <Text span>{relationship.relationshipType.name} is </Text>
          <Anchor
            onClick={() =>
              navigate(`/app/person/${relationship.fromPerson.id}`)
            }
          >
            {relationship.fromPerson.firstName}{' '}
            {relationship.fromPerson.lastName}
          </Anchor>
        </>
      );
    }
  };

  return (
    <Stack gap="md">
      <Group justify="space-between">
        <Text size="xl" fw={700}>
          Relationships
        </Text>
        <AddRelationshipModal person={person} onRelationshipAdded={refetch} />
      </Group>

      {relationshipsResponse && relationshipsResponse.length > 0 ? (
        relationshipsResponse.map((relationship) => (
          <Card
            key={relationship.id}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
          >
            <Group justify="space-between">
              <Text>{renderRelationshipDescription(relationship)}</Text>
              <ActionIcon
                color="red"
                onClick={() => handleDeleteRelationship(relationship.id)}
                variant="subtle"
              >
                <IconTrash size="1rem" />
              </ActionIcon>
            </Group>
          </Card>
        ))
      ) : (
        <Text>No relationships found for this person.</Text>
      )}
    </Stack>
  );
};

export default PersonRelationshipsView;
