import { useGetStateProvinces } from '@btrway/api-core';
import { TagsInput } from '@mantine/core';
import React, { useMemo, useState } from 'react';

interface StateProvinceTagsInputProps {
  onChange: (value: string | undefined) => void;
  required?: boolean;
  defaultValue?: string;
}

const StateProvinceTagsInput: React.FC<StateProvinceTagsInputProps> = ({
  onChange,
  required = false,
  defaultValue,
}) => {
  const [value, setValue] = useState<string[]>(
    defaultValue ? [defaultValue] : []
  );
  const { data: states } = useGetStateProvinces();

  const stateOptions = useMemo(() => {
    if (!states) return [];
    return states.map((state) => `${state.name} (${state.code})`);
  }, [states]);

  const handleChange = (newValue: string[]) => {
    const selectedValue =
      newValue.length > 0 ? newValue[newValue.length - 1] : undefined;
    setValue(selectedValue ? [selectedValue] : []);

    const stateCode = selectedValue
      ? selectedValue.match(/\(([^)]+)\)/)?.[1]
      : undefined;
    onChange(stateCode);
  };

  if (!states) {
    return null;
  }

  return (
    <TagsInput
      label="State/Province"
      required={required}
      data={value.length === 0 ? stateOptions : []}
      value={value}
      onChange={handleChange}
      maxTags={1}
      clearable={false}
      placeholder=""
      maxDropdownHeight={200}
    />
  );
};

export default StateProvinceTagsInput;
