// import { ImportedPerson } from '../types';
// import { ImportConfiguration } from '../types/ImportConfiguration';

// export const generateJSON = (
//   fileData: { headers: string[]; rows: string[][] },
//   importConfiguration: ImportConfiguration
// ): ImportedPerson[] => {
//   return fileData.rows.map((row, rowIndex) => {
//     console.log(`Processing row ${rowIndex}`);
//     const person: Partial<Record<keyof ImportedPerson, string | number>> = {};

//     importConfiguration.columnMappings.forEach((columnMapping) => {
//       const { columnHeader, targetField, valueMappings } = columnMapping;
//       console.log(
//         `Processing field: ${targetField}, mappedHeader: ${columnHeader}`
//       );

//       const index = fileData.headers.findIndex(
//         (header) => header.toLowerCase() === columnHeader.toLowerCase()
//       );
//       console.log(`Header index: ${index}`);

//       if (index !== -1 && row[index] !== undefined) {
//         const sourceValue = row[index];
//         console.log(`Source value: ${sourceValue}`);

//         // Check if valueMappings exist for this field
//         const mappedValue = valueMappings.find(
//           (mapping) => mapping.sourceValue === sourceValue
//         )?.targetValue;
//         console.log(`Mapped value: ${mappedValue}`);

//         if (mappedValue !== null && mappedValue !== undefined) {
//           person[targetField] = mappedValue;
//         } else {
//           person[targetField] = sourceValue;
//         }
//       }
//     });

//     console.log('Final person object:', person);
//     return person as ImportedPerson;
//   });
// };

import { ImportedPerson } from '../types';
import { ImportConfiguration } from '../types/ImportConfiguration';

export const generateJSON = (
  fileData: { headers: string[]; rows: string[][] },
  importConfiguration: ImportConfiguration
): ImportedPerson[] => {
  return fileData.rows.map((row) => {
    const person: ImportedPerson = {
      firstName: '',
      lastName: '',
      email: '',
      rootWorkgroup: { name: '' },
      userRole: { name: '' },
    };

    importConfiguration.columnMappings.forEach((columnMapping) => {
      const { columnHeader, targetField, valueMappings } = columnMapping;
      const index = fileData.headers.findIndex(
        (header) => header.toLowerCase() === columnHeader.toLowerCase()
      );

      if (index !== -1 && row[index] !== undefined) {
        const sourceValue = row[index];

        if (targetField === 'rootWorkgroup' || targetField === 'userRole') {
          const matchedMapping = valueMappings.find(
            (mapping) => mapping.sourceValue === sourceValue
          );
          person[targetField] = {
            name: sourceValue,
            id: matchedMapping?.targetValue
              ? Number(matchedMapping.targetValue)
              : undefined,
          };
        } else if (targetField in person) {
          // Use type assertion to tell TypeScript that targetField is a valid key of ImportedPerson
          (person as any)[targetField] = sourceValue;
        }
      }
    });

    return person;
  });
};
