import {
  TaskListStartTypeEnum,
  WorkflowTemplateResponse,
} from '@btrway/api-workflow';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { Badge, Card, Group, Stack, Text } from '@mantine/core';
import { IconList, IconTrash } from '@tabler/icons-react';
import React from 'react';

interface TaskListSummaryCardProps {
  data: WorkflowTemplateResponse;
  className?: string;
  onClick?: () => void;
}

const TaskListSummaryCard: React.FC<TaskListSummaryCardProps> = ({
  data: workflowTemplate,
  className,
  onClick,
}) => {
  const { deleteWorkflowTemplate } = useWorkflowTemplates();

  const getStartTypeDisplay = (template: WorkflowTemplateResponse) => {
    const startType = template.templateSettings?.taskListStartType;
    const month = template.templateSettings?.taskListStartMonth;
    const day = template.templateSettings?.taskListStartDay;

    switch (startType) {
      case TaskListStartTypeEnum.configuredDate:
        return 'Configured Date';
      case TaskListStartTypeEnum.taskAssignment:
        return 'Task Assignment';
      case TaskListStartTypeEnum.specificDay:
        return `Annually: ${month?.toString().padStart(2, '0')}/${day
          ?.toString()
          .padStart(2, '0')}`;
      default:
        return 'N/A';
    }
  };

  const handleDeleteClick = (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation();
    deleteWorkflowTemplate(workflowTemplate.id);
  };

  return (
    <Card
      shadow="sm"
      padding="sm"
      radius="md"
      withBorder
      w={240}
      mih={200}
      className={className}
      onClick={onClick}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <IconTrash
        size={16}
        color="darkgrey"
        style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }}
        onClick={handleDeleteClick}
      />
      <Stack gap="xs">
        <Text size="sm" fw={700} style={{ paddingRight: '20px' }}>
          {workflowTemplate.name}
        </Text>
        <Text c="dimmed" fz="xs" lineClamp={2}>
          {workflowTemplate.description || 'No description available'}
        </Text>
        <Group justify="space-between" mt="auto">
          <Badge
            size="sm"
            leftSection={<IconList size={14} />}
            color="blue"
            variant="light"
          >
            {workflowTemplate.workflowConfiguration?.steps?.length || 0} Tasks
          </Badge>
          <Text fz="xs">{getStartTypeDisplay(workflowTemplate)}</Text>
        </Group>
        <Group justify="space-between">
          <Badge
            size="sm"
            variant="filled"
            color={workflowTemplate.published ? 'green' : 'gray'}
          >
            {workflowTemplate.published ? 'Live' : 'Draft'}
          </Badge>
        </Group>
      </Stack>
    </Card>
  );
};

export default TaskListSummaryCard;
