import { AssetResponse } from '@btrway/api-courseware';
import { TaskConfig, TaskTypeEnum } from '@btrway/api-workflow';
import { useCourses } from '@btrway/courseware-manager';
import { uuid } from '@btrway/utils';
import { Anchor, Group, Stack, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React, { useMemo, useState } from 'react';
import { getTypedTaskProperties } from '../../config/taskPropertiesMap';
import { EditorProps } from '../../types/editor';
import { EditorSearchInput } from '../EditorSearchInput/EditorSearchInput';
import { EditorSwitchCard } from '../EditorSwitchCard/EditorSwitchCard';
import { EditorWrapper } from '../EditorWrapper/EditorWrapper';

interface GroupedAssets {
  folderId: number;
  folderTitle: string;
  items: AssetResponse[];
}

export const CurriculumEditor: React.FC<EditorProps> = ({
  initialSelectedTasks,
  onSave,
  onClose,
  workflowKey,
  workflowName,
}) => {
  console.log('CurriculumEditor');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const { courseFolders } = useCourses();

  const groupedAssets = useMemo((): GroupedAssets[] => {
    if (!courseFolders) return [];

    return courseFolders
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((folder) => ({
        folderId: folder.id,
        folderTitle: folder.title,
        items: folder.assets || [],
      }))
      .filter((group) => group.items.length > 0);
  }, [courseFolders]);

  const createTaskConfig = (asset: AssetResponse): TaskConfig => ({
    taskKey: uuid(),
    taskType: TaskTypeEnum.completeCourse,
    metadata: {
      title: asset.title,
    },
    taskProperties: {
      courseSelection: {
        courseUid: { value: asset.uid },
      },
    },
  });

  const filteredGroups = useMemo(() => {
    const searchTermLower = debouncedSearchTerm.toLowerCase();
    return groupedAssets
      .map((group) => ({
        ...group,
        items: group.items.filter((item) =>
          item.title.toLowerCase().includes(searchTermLower)
        ),
      }))
      .filter((group) => group.items.length > 0);
  }, [groupedAssets, debouncedSearchTerm]);

  const handleSelectAll = (
    selectedTasks: TaskConfig[],
    groupAssets: AssetResponse[],
    onToggle: (task: TaskConfig) => void,
    onToggleBatch: (taskConfigs: TaskConfig[]) => void
  ) => {
    // If all items in group are selected, deselect all
    const allSelected = groupAssets.every((asset) =>
      selectedTasks.some((task) => {
        const props = getTypedTaskProperties(task, TaskTypeEnum.completeCourse);
        return props?.courseSelection.courseUid.value === asset.uid;
      })
    );

    if (allSelected) {
      // Collect all tasks to deselect
      const tasksToToggle = groupAssets
        .map((asset) =>
          selectedTasks.find((task) => {
            const props = getTypedTaskProperties(
              task,
              TaskTypeEnum.completeCourse
            );
            return props?.courseSelection.courseUid.value === asset.uid;
          })
        )
        .filter((task): task is TaskConfig => task !== undefined);

      onToggleBatch(tasksToToggle);
    } else {
      // Collect all new tasks to select
      const tasksToToggle = groupAssets
        .filter(
          (asset) =>
            !selectedTasks.some((task) => {
              const props = getTypedTaskProperties(
                task,
                TaskTypeEnum.completeCourse
              );
              return props?.courseSelection.courseUid.value === asset.uid;
            })
        )
        .map(createTaskConfig);

      onToggleBatch(tasksToToggle);
    }
  };

  return (
    <EditorWrapper
      initialSelectedTasks={initialSelectedTasks}
      onSave={onSave}
      onClose={onClose}
    >
      {({ selectedTasks, onToggle, onToggleBatch }) => (
        <Stack p="md">
          <Text fz="lg" fw={600}>
            Select Courses
          </Text>
          <EditorSearchInput
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <Stack gap="xs">
            {filteredGroups.map((group) => (
              <Stack key={group.folderId} gap="xs">
                <Group justify="space-between" align="center">
                  <Text fw={500} fz="sm" mt="sm">
                    {group.folderTitle}
                  </Text>
                  <Anchor
                    fz="xs"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectAll(
                        selectedTasks,
                        group.items,
                        onToggle,
                        onToggleBatch
                      );
                    }}
                  >
                    Select all
                  </Anchor>
                </Group>
                {group.items.map((asset) => {
                  const isSelected = selectedTasks.some((task) => {
                    const selectedTaskProperties = getTypedTaskProperties(
                      task,
                      TaskTypeEnum.completeCourse
                    );
                    return (
                      selectedTaskProperties?.courseSelection.courseUid
                        .value === asset.uid
                    );
                  });

                  // Find existing task config if it exists
                  const existingTask = selectedTasks.find((task) => {
                    const props = getTypedTaskProperties(
                      task,
                      TaskTypeEnum.completeCourse
                    );
                    return props?.courseSelection.courseUid.value === asset.uid;
                  });

                  return (
                    <EditorSwitchCard
                      key={asset.uid}
                      item={{ id: asset.uid.toString(), name: asset.title }}
                      isSelected={isSelected}
                      onToggle={() =>
                        onToggle(existingTask || createTaskConfig(asset))
                      }
                    />
                  );
                })}
              </Stack>
            ))}
          </Stack>
        </Stack>
      )}
    </EditorWrapper>
  );
};
