/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type StepTypeEnum = typeof StepTypeEnum[keyof typeof StepTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepTypeEnum = {
  assignTask: 'assignTask',
  actionCreateCertificate: 'actionCreateCertificate',
  actionCreateEvent: 'actionCreateEvent',
  actionCreateWorkgroupMember: 'actionCreateWorkgroupMember',
  actionDeleteWorkgroupMember: 'actionDeleteWorkgroupMember',
  actionPushNotification: 'actionPushNotification',
  actionSendEmail: 'actionSendEmail',
  actionSendUserInvite: 'actionSendUserInvite',
  actionSms: 'actionSms',
  actionUpdatePersonData: 'actionUpdatePersonData',
  eventChapterCompleted: 'eventChapterCompleted',
  eventCourseCompleted: 'eventCourseCompleted',
  eventCurriculumCompleted: 'eventCurriculumCompleted',
  eventFormSubmitted: 'eventFormSubmitted',
  eventReviewCompleted: 'eventReviewCompleted',
  eventUserCreated: 'eventUserCreated',
  flowCondition: 'flowCondition',
  flowStart: 'flowStart',
  flowWait: 'flowWait',
  systemWorkflowActionCompleted: 'systemWorkflowActionCompleted',
  systemWorkflowAssigned: 'systemWorkflowAssigned',
  systemWorkflowInstanceCreated: 'systemWorkflowInstanceCreated',
  systemWorkflowTaskCompleted: 'systemWorkflowTaskCompleted',
  systemCreateWorkflowInstance: 'systemCreateWorkflowInstance',
  systemMarkWorkflowStepInstanceCompleted: 'systemMarkWorkflowStepInstanceCompleted',
  formSubmission: 'formSubmission',
  fileUpload: 'fileUpload',
  missing_enum_value: 'missing enum value',
} as const;
