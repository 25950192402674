import { StepTypeEnum, TaskTypeEnum } from '@btrway/api-workflow';
import { StepTypeIcon, TaskTypeIcon } from '@btrway/workflow-components';
import { useWorkflowStep } from '@btrway/workflow-configuration-manager';
import React from 'react';

interface StepConfigCardIconProps {
  stepKey: string;
}

export const StepConfigCardIcon: React.FC<StepConfigCardIconProps> = ({
  stepKey,
}) => {
  const { step } = useWorkflowStep(stepKey);

  if (
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType
  ) {
    return (
      <TaskTypeIcon
        iconName={step.taskConfig.taskType as TaskTypeEnum}
        size={24}
      />
    );
  }

  return (
    <StepTypeIcon iconName={step?.stepTypeCode as StepTypeEnum} size={24} />
  );
};
