import { CertificationInstanceGridResponse } from '@btrway/api-workflow';
import { useCertificationTypes } from '@btrway/workflow-manager';
import { Badge, Box, Card, Collapse, Group, Stack, Text } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronUp,
  IconCircleCheck,
  IconClock,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { GroupedCertification } from '../../types/certificationTypes';
import {
  getProgressColor,
  getRequirementCounts,
} from '../../utils/certificationUtils';
import { RequirementCard } from '../RequirementCard/RequirementCard';

interface CertificationCardProps {
  certification: GroupedCertification;
  expanded: boolean;
  onToggleExpand: () => void;
}

export const CertificationCard = ({
  certification,
  expanded,
  onToggleExpand,
}: CertificationCardProps) => {
  const { getCertificationType } = useCertificationTypes();
  const { count, completedCount, totalCount } = getRequirementCounts(
    certification.requirements
  );

  const getStatusIcon = (completed: boolean) => {
    return completed ? <IconCircleCheck size={16} /> : <IconClock size={16} />;
  };

  const sortedRequirements = useMemo(() => {
    return [...certification.requirements].sort((a, b) => {
      const getTitleForSort = (
        req: CertificationInstanceGridResponse
      ): string => {
        const certType =
          req.certificationType && getCertificationType(req.certificationType);
        return (
          req.certificationTitle ||
          certType?.name ||
          req.certificationType ||
          'Untitled'
        ).toLowerCase();
      };

      return getTitleForSort(a).localeCompare(getTitleForSort(b));
    });
  }, [certification.requirements, getCertificationType]);

  return (
    <Card shadow="sm" radius="md" withBorder p={0}>
      <Box
        p="lg"
        style={{
          borderBottom: expanded ? '1px solid #dee2e6' : 'none',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
          },
        }}
        onClick={onToggleExpand}
      >
        <Group justify="space-between">
          <Group gap="xs">
            <Group gap="xs">
              <Text fz="lg" fw={700}>
                {certification.assignedEntityName}
              </Text>
            </Group>
            <Badge
              size="lg"
              color={getProgressColor(completedCount, totalCount)}
              leftSection={getStatusIcon(certification.completed)}
            >
              {count}
            </Badge>
          </Group>
          <Group gap="xs">
            <Text fz="md" fw={500}>
              {certification.certificationName}
            </Text>
            {expanded ? (
              <IconChevronUp size={16} />
            ) : (
              <IconChevronDown size={16} />
            )}
          </Group>
        </Group>
      </Box>

      <Collapse in={expanded}>
        <Box bg="gray.2" p="lg">
          <Stack gap="md">
            {sortedRequirements.map((req) => (
              <RequirementCard key={req.certificationKey} requirement={req} />
            ))}
          </Stack>
        </Box>
      </Collapse>
    </Card>
  );
};
