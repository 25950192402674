export const formatTimeString = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const parseTimeString = (
  timeStr: string
): { hours: number; minutes: number } => {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return { hours, minutes };
};

export const getDateFromISOString = (isoString: string) => {
  return new Date(isoString);
};
