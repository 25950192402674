import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useWorkflowTemplate } from '@btrway/workflow-manager';
import { Space, Stack, TagsInput, TextInput, Textarea } from '@mantine/core';
import React from 'react';
import TaskListStartSelector from '../TaskListStartSelector/TaskListStartSelector';

interface TaskListSettingsProps {
  // workflowTemplate: WorkflowTemplateResponse;
  // updateWorkflowTemplate: (update: Partial<WorkflowTemplateRequest>) => void;
}

const TaskListSettings: React.FC<TaskListSettingsProps> = (
  {
    // workflowTemplate,
    // updateWorkflowTemplate,
  }
) => {
  const { workflowTemplate, updateWorkflowTemplate } = useWorkflowTemplate();

  const handleDefaultUserRolesChange = (values: string[]) => {
    updateWorkflowTemplate({
      templateSettings: {
        ...workflowTemplate?.templateSettings,
        defaultUserRoles: values,
      },
    });
  };

  const isTaskList =
    workflowTemplate?.workflowType === WorkflowTypeEnum.taskList;

  return (
    <Stack gap={5} align="flex-start" w={300}>
      <TextInput
        label="Template Name"
        value={workflowTemplate?.name || ''}
        onChange={(event) =>
          updateWorkflowTemplate({ name: event.currentTarget.value })
        }
        w="100%"
      />
      <Textarea
        label="Description"
        value={workflowTemplate?.description || ''}
        onChange={(event) =>
          updateWorkflowTemplate({
            description: event.currentTarget.value,
          })
        }
        minRows={3}
        w="100%"
      />
      {isTaskList && (
        <TagsInput
          label="Default User Roles"
          value={workflowTemplate.templateSettings?.defaultUserRoles || []}
          onChange={handleDefaultUserRolesChange}
          data={['System AD', 'School AD', 'Coach']}
          w="100%"
        />
      )}

      <TaskListStartSelector />

      <Space h={40} />
      {/*<Text fz={16} fw={600} c="gray.8">
        Task List Summary
      </Text>
       <TaskListChart /> */}
    </Stack>
  );
};

export default TaskListSettings;
