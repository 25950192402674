// import {
//   TaskStatisticsRequest,
//   useGetTaskStatistics,
//   WorkflowTaskStatisticsResponse,
//   WorkflowTypeEnum,
//   WorkgroupTaskStatisticsResponse,
// } from '@btrway/api-workflow';
// import { useEffect, useState } from 'react';
// import {
//   EnrichedWorkflowStats,
//   EnrichedWorkgroupStats,
//   WorkflowTypeTaskStatisticsResponse,
// } from '../types/stats';

// interface UseFetchWorkgroupStatsResult {
//   data: EnrichedWorkgroupStats[] | undefined;
//   isLoading: boolean;
//   isError: boolean;
//   error: Error | null;
//   refetch: () => Promise<void>;
// }

// const calculateWorkflowTypeStats = (
//   workflows: WorkflowTaskStatisticsResponse[]
// ): WorkflowTypeTaskStatisticsResponse[] => {
//   const typeMap = new Map<
//     WorkflowTypeEnum,
//     WorkflowTypeTaskStatisticsResponse
//   >();

//   workflows.forEach((workflow) => {
//     if (!workflow.workflowType) return;

//     const existing = typeMap.get(workflow.workflowType) || {
//       completedTasks: 0,
//       completedTaskLists: 0,
//       overdueCompletedTasks: 0,
//       totalTaskLists: 0,
//       totalTasks: 0,
//       workflowType: workflow.workflowType,
//     };

//     typeMap.set(workflow.workflowType, {
//       completedTasks: existing.completedTasks + (workflow.completedTasks || 0),
//       completedTaskLists:
//         existing.completedTaskLists + (workflow.completedTaskLists || 0),
//       overdueCompletedTasks:
//         existing.overdueCompletedTasks + (workflow.overdueCompletedTasks || 0),
//       totalTaskLists: existing.totalTaskLists + (workflow.totalTaskLists || 0),
//       totalTasks: existing.totalTasks + (workflow.totalTasks || 0),
//       workflowType: workflow.workflowType,
//     });
//   });

//   return Array.from(typeMap.values());
// };

// export const useFetchWorkgroupStats = (
//   request: TaskStatisticsRequest
// ): UseFetchWorkgroupStatsResult => {
//   const [data, setData] = useState<EnrichedWorkgroupStats[] | undefined>(
//     undefined
//   );

//   const {
//     mutateAsync: getTaskStatistics,
//     isPending: isLoading,
//     isError,
//     error: queryError,
//   } = useGetTaskStatistics();

//   const enrichStats = (
//     statistics: WorkgroupTaskStatisticsResponse[]
//   ): EnrichedWorkgroupStats[] => {
//     return statistics.map((workgroup): EnrichedWorkgroupStats => {
//       const workflowStats = workgroup.workflowStatistics || [];

//       const enrichedWorkflows = workflowStats.map(
//         (workflow): EnrichedWorkflowStats => {
//           const completed = workflow.completedTasks || 0;
//           const total = workflow.totalTasks || 0;

//           return {
//             ...workflow,
//             progressPercentage: total > 0 ? (completed / total) * 100 : 0,
//             remainingTasks: total - completed,
//             isOverdue: (workflow.averageOverdueDays || 0) > 0,
//           };
//         }
//       );

//       const totalRemaining = enrichedWorkflows.reduce(
//         (sum, workflow) => sum + workflow.remainingTasks,
//         0
//       );

//       const totalProgress = enrichedWorkflows.reduce(
//         (sum, workflow) => sum + workflow.progressPercentage,
//         0
//       );

//       const overdueCount = enrichedWorkflows.filter(
//         (workflow) => workflow.isOverdue
//       ).length;

//       return {
//         ...workgroup,
//         workflows: enrichedWorkflows,
//         workflowTypeStats: calculateWorkflowTypeStats(workflowStats),
//         totalRemainingTasks: totalRemaining,
//         averageProgress:
//           enrichedWorkflows.length > 0
//             ? totalProgress / enrichedWorkflows.length
//             : 0,
//         overdueWorkflowsCount: overdueCount,
//       };
//     });
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await getTaskStatistics({ data: request });
//         const statistics = Array.isArray(response) ? response : [response];
//         setData(enrichStats(statistics));
//       } catch (err) {
//         console.error('Error fetching workgroup stats:', err);
//         setData(undefined);
//       }
//     };

//     fetchData();
//   }, [getTaskStatistics, request]);

//   const refetch = async () => {
//     await getTaskStatistics({ data: request });
//   };

//   return {
//     data,
//     isLoading,
//     isError,
//     error: queryError instanceof Error ? queryError : null,
//     refetch,
//   };
// };

import {
  TaskStatisticsRequest,
  useGetTaskStatistics,
  WorkflowTaskStatisticsResponse,
  WorkflowTypeEnum,
  WorkgroupTaskStatisticsResponse,
} from '@btrway/api-workflow';
import { useEffect, useState } from 'react';
import {
  EnrichedWorkflowStats,
  EnrichedWorkgroupStats,
  WorkflowTypeTaskStatisticsResponse,
} from '../types/stats';

interface UseFetchWorkgroupStatsResult {
  data: EnrichedWorkgroupStats[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const calculateWorkflowTypeStats = (
  workflows: WorkflowTaskStatisticsResponse[]
): WorkflowTypeTaskStatisticsResponse[] => {
  const typeMap = new Map<
    WorkflowTypeEnum,
    WorkflowTypeTaskStatisticsResponse
  >();

  workflows.forEach((workflow) => {
    if (!workflow.workflowType) return;

    const existing = typeMap.get(workflow.workflowType) || {
      completedTasks: 0,
      completedTaskLists: 0,
      overdueCompletedTasks: 0,
      totalTaskLists: 0,
      totalTasks: 0,
      workflowType: workflow.workflowType,
    };

    typeMap.set(workflow.workflowType, {
      completedTasks: existing.completedTasks + (workflow.completedTasks || 0),
      completedTaskLists:
        existing.completedTaskLists + (workflow.completedTaskLists || 0),
      overdueCompletedTasks:
        existing.overdueCompletedTasks + (workflow.overdueCompletedTasks || 0),
      totalTaskLists: existing.totalTaskLists + (workflow.totalTaskLists || 0),
      totalTasks: existing.totalTasks + (workflow.totalTasks || 0),
      workflowType: workflow.workflowType,
    });
  });

  return Array.from(typeMap.values());
};

const calculateTaskListProgress = (
  completedTaskLists: number = 0,
  totalTaskLists: number = 0
): number => {
  return totalTaskLists > 0 ? (completedTaskLists / totalTaskLists) * 100 : 0;
};

const calculateTaskProgress = (
  completedTasks: number = 0,
  totalTasks: number = 0
): number => {
  return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
};

export const useFetchWorkgroupStats = (
  request: TaskStatisticsRequest
): UseFetchWorkgroupStatsResult => {
  const [data, setData] = useState<EnrichedWorkgroupStats[] | undefined>(
    undefined
  );

  const {
    mutateAsync: getTaskStatistics,
    isPending: isLoading,
    isError,
    error: queryError,
  } = useGetTaskStatistics();

  const enrichStats = (
    statistics: WorkgroupTaskStatisticsResponse[]
  ): EnrichedWorkgroupStats[] => {
    return statistics.map((workgroup): EnrichedWorkgroupStats => {
      const workflowStats = workgroup.workflowStatistics || [];

      const enrichedWorkflows = workflowStats.map(
        (workflow): EnrichedWorkflowStats => {
          const completedTasks = workflow.completedTasks || 0;
          const totalTasks = workflow.totalTasks || 0;
          const completedTaskLists = workflow.completedTaskLists || 0;
          const totalTaskLists = workflow.totalTaskLists || 0;

          return {
            ...workflow,
            progressPercentage: calculateTaskProgress(
              completedTasks,
              totalTasks
            ),
            taskListProgressPercentage: calculateTaskListProgress(
              completedTaskLists,
              totalTaskLists
            ),
            remainingTasks: totalTasks - completedTasks,
            remainingTaskLists: totalTaskLists - completedTaskLists,
            isOverdue: (workflow.averageOverdueDays || 0) > 0,
          };
        }
      );

      const totalRemaining = enrichedWorkflows.reduce(
        (sum, workflow) => sum + workflow.remainingTasks,
        0
      );

      const totalRemainingTaskLists = enrichedWorkflows.reduce(
        (sum, workflow) => sum + workflow.remainingTaskLists,
        0
      );

      const totalTaskProgress = enrichedWorkflows.reduce(
        (sum, workflow) => sum + workflow.progressPercentage,
        0
      );

      const totalTaskListProgress = enrichedWorkflows.reduce(
        (sum, workflow) => sum + workflow.taskListProgressPercentage,
        0
      );

      const overdueCount = enrichedWorkflows.filter(
        (workflow) => workflow.isOverdue
      ).length;

      return {
        ...workgroup,
        workflows: enrichedWorkflows,
        workflowTypeStats: calculateWorkflowTypeStats(workflowStats),
        totalRemainingTasks: totalRemaining,
        totalRemainingTaskLists: totalRemainingTaskLists,
        averageTaskProgress:
          enrichedWorkflows.length > 0
            ? totalTaskProgress / enrichedWorkflows.length
            : 0,
        averageTaskListProgress:
          enrichedWorkflows.length > 0
            ? totalTaskListProgress / enrichedWorkflows.length
            : 0,
        overdueWorkflowsCount: overdueCount,
      };
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTaskStatistics({ data: request });
        const statistics = Array.isArray(response) ? response : [response];
        setData(enrichStats(statistics));
      } catch (err) {
        console.error('Error fetching workgroup stats:', err);
        setData(undefined);
      }
    };

    fetchData();
  }, [getTaskStatistics, request]);

  const refetch = async () => {
    await getTaskStatistics({ data: request });
  };

  return {
    data,
    isLoading,
    isError,
    error: queryError instanceof Error ? queryError : null,
    refetch,
  };
};
