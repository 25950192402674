import { DataTypeEnum, EntityTaskResponse } from '@btrway/api-workflow';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

export const taskInstancesAtom = atom<EntityTaskResponse[]>([]);

export const personTasksAtomFamily = atomFamily((personId: number) =>
  atom((get) => {
    const allTasks = get(taskInstancesAtom);
    return allTasks.filter(
      (task) =>
        task.assignedEntityType === DataTypeEnum.person &&
        task.assignedEntityId === personId
    );
  })
);

export const personCompletedTasksAtomFamily = atomFamily((personId: number) =>
  atom((get) => {
    const personTasks = get(personTasksAtomFamily(personId));
    return personTasks.filter((task) => task.completed);
  })
);
