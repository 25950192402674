import { InnerContentWrapper } from '@btrway/layout-components';
import React from 'react';

const OrganizationSettingsTab: React.FC = () => {
  return (
    <InnerContentWrapper>
      Misc organization settings configurable by client administrator TBD
    </InnerContentWrapper>
  );
};

export default OrganizationSettingsTab;
