import { formatDateTimeSeconds } from '@btrway/utils';
import { useWorkflowTemplate } from '@btrway/workflow-manager';
import { Text } from '@mantine/core';
import React from 'react';

const TemplateLastUpdated: React.FC = () => {
  const { workflowTemplate } = useWorkflowTemplate();

  return (
    <Text fz="xs" c="dimmed">
      Last Updated:{' '}
      {workflowTemplate?.base.updatedAt
        ? formatDateTimeSeconds(workflowTemplate?.base.updatedAt)
        : ''}{' '}
    </Text>
  );
};

export default TemplateLastUpdated;
