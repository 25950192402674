import {
  getGetPublicWorkgroupQueryKey,
  getLookupWorkgroupBySlugQueryKey,
  useGetPublicWorkgroup,
  useLookupWorkgroupBySlug,
} from '@btrway/api-core';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WorkgroupLandingDetail from '../../components/WorkgroupLandingDetail/WorkgroupLandingDetail';
import { useRegistrationAuth } from '@btrway/registration-forms';

const WorkgroupLandingPage: React.FC = () => {
  const { workgroupIdentifier } = useParams<{ workgroupIdentifier: string }>();
  const navigate = useNavigate();
  const {getTempToken} = useRegistrationAuth();
  let mounted = false;

  useEffect(() => {
    if(!mounted){
      getTempToken();
      mounted = true;
    }
  });

  const isNumeric = /^\d+$/.test(workgroupIdentifier || '');

  const {
    data: workgroupBySlug,
    isLoading: isLoadingBySlug,
    error: slugError,
  } = useLookupWorkgroupBySlug(workgroupIdentifier || '', {
    query: {
      enabled: !isNumeric,
      queryKey: getLookupWorkgroupBySlugQueryKey(workgroupIdentifier || ''),
    },
  });

  const workgroupId = isNumeric
    ? Number(workgroupIdentifier)
    : workgroupBySlug?.workgroupId;

  const {
    data: workgroup,
    isLoading: isLoadingWorkgroup,
    error: workgroupError,
  } = useGetPublicWorkgroup(workgroupId || 0, {
    query: {
      enabled: !!workgroupId,
      queryKey: getGetPublicWorkgroupQueryKey(workgroupId || 0),
    },
  });

  if (isLoadingBySlug || isLoadingWorkgroup) {
    return null;
  }

  if (slugError || workgroupError) {
    return <div>Error: {(slugError || workgroupError)?.toString()}</div>;
  }

  if (!workgroup) {
    return <div>No workgroup found.</div>;
  }

  // If the workgroup has a slug and it's different from the current URL, redirect
  if (
    workgroup.workgroupSlug &&
    workgroup.workgroupSlug !== workgroupIdentifier
  ) {
    navigate(`/register/${workgroup.workgroupSlug}`, { replace: true });
    return null;
  }

  return <WorkgroupLandingDetail workgroup={workgroup} />;
};

export default WorkgroupLandingPage;
