import {
  getGetOrganizationTypesQueryKey,
  useGetOrganizationTypes,
} from '@btrway/api-core';
import { useCallback } from 'react';

export const useOrganizationTypes = () => {
  const {
    data: organizationTypes,
    isLoading,
    error,
    refetch,
  } = useGetOrganizationTypes({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetOrganizationTypesQueryKey(),
    },
  });

  const getOrganizationTypeById = useCallback(
    (id: number) => {
      return organizationTypes?.find((type) => type.id === id);
    },
    [organizationTypes]
  );

  return {
    organizationTypes: organizationTypes ?? [],
    isLoading,
    error,
    getOrganizationTypeById,
    refetchOrganizationTypes: refetch,
  };
};
