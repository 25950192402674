import { UserRoleResponse } from '@btrway/api-security';
import { Box, Button, Group, Stack, Tabs, TextInput } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import UserRoleAccessPermissions from '../UserRoleAccessPermissions/UserRoleAccessPermissions';
import UserRoleSettings from '../UserRoleSettings/UserRoleSettings';

interface UserRoleDetailViewProps {
  userRole: UserRoleResponse;
}

const UserRoleDetailView: React.FC<UserRoleDetailViewProps> = ({
  userRole,
}) => {
  console.log('UserRoleDetailViewProps', userRole);
  const [activeTab, setActiveTab] = useState<string | null>('settings');
  const [roleName, setRoleName] = useState(userRole.name);

  useEffect(() => {
    setRoleName(userRole.name);
  }, [userRole.name]);

  const handleSave = () => {
    // Placeholder save handler
    console.log('Saving role with name:', roleName);
    // Here you would typically call an API to update the role
  };

  return (
    <Stack gap="md" style={{ height: '100%' }}>
      <Group justify="space-between" align="flex-end">
        <TextInput
          label="Role Name"
          value={roleName}
          onChange={(event) => setRoleName(event.currentTarget.value)}
          w={500}
        />
        <Button onClick={handleSave}>Save</Button>
      </Group>

      <Tabs value={activeTab} onChange={setActiveTab} style={{ flexGrow: 1 }}>
        <Tabs.List>
          <Tabs.Tab value="settings">Settings</Tabs.Tab>
          <Tabs.Tab value="permissions">Access Permissions</Tabs.Tab>
        </Tabs.List>

        <Box style={{ height: 'calc(100% - 36px)', backgroundColor: 'white' }}>
          <Tabs.Panel value="settings" p="md" style={{ height: '100%' }}>
            <UserRoleSettings userRole={{ ...userRole, name: roleName }} />
          </Tabs.Panel>
          <Tabs.Panel value="permissions" p="md" style={{ height: '100%' }}>
            <UserRoleAccessPermissions
              userRole={{ ...userRole, name: roleName }}
            />
          </Tabs.Panel>
        </Box>
      </Tabs>
    </Stack>
  );
};

export default UserRoleDetailView;
