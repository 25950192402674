import { Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface Event {
  date: string;
  title: string;
  time: string;
}

const mockEvents: Event[] = [
  { date: '2024-09-16', title: 'Football Practice', time: '3:30 PM' },
  { date: '2024-09-17', title: 'Basketball Team Meeting', time: '4:00 PM' },
  {
    date: '2024-09-18',
    title: 'Soccer Game vs. Riverside High',
    time: '5:00 PM',
  },
  { date: '2024-09-19', title: 'Track and Field Training', time: '3:00 PM' },
  { date: '2024-09-20', title: 'Volleyball Tournament', time: '9:00 AM' },
];

const CalendarAgenda: React.FC = () => {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder h="100%">
      <Text size="xl" fw={700} mb="md">
        Upcoming Events
      </Text>
      <Stack gap="sm">
        {mockEvents.map((event, index) => (
          <Group key={index} justify="space-between">
            <Stack gap={0}>
              <Text fw={500}>{event.title}</Text>
              <Text size="sm" color="dimmed">
                {new Date(event.date).toLocaleDateString()}
              </Text>
            </Stack>
            <Text>{event.time}</Text>
          </Group>
        ))}
      </Stack>
    </Card>
  );
};

export default CalendarAgenda;
