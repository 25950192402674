import { abwicon, abwlogo } from '@btrway/assets';
import { useAuthContext } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useImpersonation } from '@btrway/impersonation';
import { RouteConfig, useRouteContext } from '@btrway/web-routing';
import { Box, Divider, Image } from '@mantine/core';
import { ReactNode } from 'react';
import { NavbarItem } from '../NavbarItem/NavbarItem';
import ToggleNavbarButton from '../ToggleNavbarButton/ToggleNavbarButton';
import UserProfileNavbarButton from '../UserProfileNavbarButton/UserProfileNavbarButton';
import classes from './PrimaryNavigation.module.css';

interface PrimaryNavigationProps {
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
  onNavItemClick?: () => void;
}

export const PrimaryNavigation: React.FC<PrimaryNavigationProps> = ({
  minimized,
  setMinimized,
  onNavItemClick,
}) => {
  const { childRoutes, currentRoute } = useRouteContext();

  const { authState } = useAuthContext();
  const { currentUser, currentOrganization, currentPerson, rootWorkgroup } =
    useAuthenticatedUser();
  const {
    stopOrgImpersonation,
    stopPersonImpersonation,
    isImpersonatingOrg,
    isImpersonatingPerson,
  } = useImpersonation();

  const handleExitOrganization = () => {
    stopOrgImpersonation();
    onNavItemClick?.();
  };

  const handleExitPerson = () => {
    console.log('Exiting person impersonation');
    stopPersonImpersonation();
    onNavItemClick?.();
  };

  const filterRoutesByPermission = (routes: RouteConfig[]) =>
    routes.filter((route) => route.navLevel === 'primary');

  const renderNavItem = (route: RouteConfig) => {
    let label: ReactNode;

    if (route.labelComponent) {
      const LabelComponent = route.labelComponent;
      label = <LabelComponent fallbackLabel={route.label as string} />;
    } else {
      label = route.label;
    }

    return (
      <NavbarItem
        key={route.path}
        to={route.path}
        label={label}
        icon={route.icon}
        minimized={minimized}
        onItemClick={onNavItemClick}
      />
    );
  };

  const firstRoutes = filterRoutesByPermission(
    childRoutes.filter((r) => r.navContainer === 'first')
  );
  const secondRoutes = filterRoutesByPermission(
    childRoutes.filter((r) => r.navContainer === 'second')
  );
  const thirdRoutes = filterRoutesByPermission(
    childRoutes.filter((r) => r.navContainer === 'third')
  );
  const bottomRoutes = filterRoutesByPermission(
    childRoutes.filter((r) => r.navContainer === 'bottom')
  );

  const hasFirstRoutes = firstRoutes.length > 0;
  const hasSecondRoutes = secondRoutes.length > 0;
  const hasThirdRoutes = thirdRoutes.length > 0;
  const hasBottomRoutes = bottomRoutes.length > 0;

  return (
    <nav className={classes.navbar}>
      <div className={classes.navbarMain}>
        <Box visibleFrom="sm">
          <Box className={classes.logoContainer} mb={20}>
            <Image
              src={abwlogo}
              alt="ABW Logo"
              height={30}
              fit="contain"
              visibleFrom="sm"
              className={`${classes.logo} ${minimized ? classes.hideLogo : ''}`}
            />
            <Image
              src={abwicon}
              alt="ABW Icon"
              height={30}
              fit="contain"
              visibleFrom="sm"
              className={`${classes.icon} ${minimized ? classes.showIcon : ''}`}
            />
          </Box>
          <ToggleNavbarButton
            minimized={minimized}
            onClick={() => setMinimized(!minimized)}
          />
        </Box>
        {hasFirstRoutes && firstRoutes.map(renderNavItem)}

        {hasFirstRoutes && hasSecondRoutes && (
          <Divider mt={10} mb={10} size="lg" />
        )}

        {hasSecondRoutes && secondRoutes.map(renderNavItem)}

        {hasSecondRoutes && hasThirdRoutes && (
          <Divider mt={10} mb={10} size="lg" />
        )}

        {hasThirdRoutes && thirdRoutes.map(renderNavItem)}
      </div>

      {/* {isImpersonatingOrg && !isImpersonatingPerson && (
        <Card padding="md" bg="#A71021" style={{ marginTop: 'auto' }}>
          <Text fz={14} fw={600} c="white">
            {currentOrganization.name}
          </Text>
          <Text fz={12} fw={500} c="gray.4">
            {currentOrganization.physicalAddress?.city},{' '}
            {currentOrganization.physicalAddress?.stateProvinceCode}
          </Text>
          <Space h={10} />
          <Button
            variant="outline"
            size="xs"
            bg="red.7"
            color="white"
            onClick={handleExitOrganization}
            rightSection={<IconLogout size="1rem" stroke={1.5} />}
          >
            Exit Organization
          </Button>
        </Card>
      )}

      {(isImpersonatingPerson ||
        (isImpersonatingPerson && isImpersonatingOrg)) && (
        <Card padding="md" bg="#A71021" style={{ marginTop: 'auto' }}>
          <Text fz={14} fw={600} c="white">
            {currentPerson.firstName} {currentPerson.lastName}
          </Text>
          <Text fz={12} fw={500} c="gray.4">
            Member of {currentOrganization.name}
          </Text>
          <Space h={10} />
          <Button
            variant="outline"
            size="xs"
            bg="red.7"
            color="white"
            onClick={handleExitPerson}
            rightSection={<IconLogout size="1rem" stroke={1.5} />}
          >
            Stop Impersonating
          </Button>
        </Card>
      )} */}

      {hasBottomRoutes && <>{bottomRoutes.map(renderNavItem)}</>}

      <UserProfileNavbarButton minimized={minimized} />
    </nav>
  );
};
