/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  PermissionResponse,
  UserRolePermissionRequest,
  UserRolePermissionResponse,
  UserRoleRequest,
  UserRoleResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const saveUserRole = (
    userRoleRequest: UserRoleRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/security/user-roles`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userRoleRequest
    },
      options);
    }
  


export const getSaveUserRoleMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUserRole>>, {data: UserRoleRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveUserRole(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof saveUserRole>>>
    export type SaveUserRoleMutationBody = UserRoleRequest
    export type SaveUserRoleMutationError = unknown

    export const useSaveUserRole = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRole>>, TError,{data: UserRoleRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveUserRole>>,
        TError,
        {data: UserRoleRequest},
        TContext
      > => {

      const mutationOptions = getSaveUserRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getUserRolePermissions = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRolePermissionResponse[]>(
      {url: `/api/security/user-role-permissions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRolePermissionsQueryKey = () => {
    return [`/api/security/user-role-permissions`] as const;
    }

    
export const getGetUserRolePermissionsQueryOptions = <TData = Awaited<ReturnType<typeof getUserRolePermissions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRolePermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRolePermissions>>> = ({ signal }) => getUserRolePermissions(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRolePermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRolePermissions>>>
export type GetUserRolePermissionsQueryError = unknown



export function useGetUserRolePermissions<TData = Awaited<ReturnType<typeof getUserRolePermissions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRolePermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveUserRolePermission = (
    userRolePermissionRequest: UserRolePermissionRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/security/user-role-permissions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userRolePermissionRequest
    },
      options);
    }
  


export const getSaveUserRolePermissionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRolePermission>>, TError,{data: UserRolePermissionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveUserRolePermission>>, TError,{data: UserRolePermissionRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUserRolePermission>>, {data: UserRolePermissionRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveUserRolePermission(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveUserRolePermissionMutationResult = NonNullable<Awaited<ReturnType<typeof saveUserRolePermission>>>
    export type SaveUserRolePermissionMutationBody = UserRolePermissionRequest
    export type SaveUserRolePermissionMutationError = unknown

    export const useSaveUserRolePermission = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserRolePermission>>, TError,{data: UserRolePermissionRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveUserRolePermission>>,
        TError,
        {data: UserRolePermissionRequest},
        TContext
      > => {

      const mutationOptions = getSaveUserRolePermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getUserRoleById = (
    userRoleId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRoleResponse>(
      {url: `/api/security/user-roles/${userRoleId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRoleByIdQueryKey = (userRoleId: number,) => {
    return [`/api/security/user-roles/${userRoleId}`] as const;
    }

    
export const getGetUserRoleByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserRoleById>>, TError = unknown>(userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRoleByIdQueryKey(userRoleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRoleById>>> = ({ signal }) => getUserRoleById(userRoleId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userRoleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRoleByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRoleById>>>
export type GetUserRoleByIdQueryError = unknown



export function useGetUserRoleById<TData = Awaited<ReturnType<typeof getUserRoleById>>, TError = unknown>(
 userRoleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRoleById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRoleByIdQueryOptions(userRoleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteUserRole = (
    userRoleId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/security/user-roles/${userRoleId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteUserRoleMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserRole>>, {userRoleId: number}> = (props) => {
          const {userRoleId} = props ?? {};

          return  deleteUserRole(userRoleId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserRole>>>
    
    export type DeleteUserRoleMutationError = unknown

    export const useDeleteUserRole = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRole>>, TError,{userRoleId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserRole>>,
        TError,
        {userRoleId: number},
        TContext
      > => {

      const mutationOptions = getDeleteUserRoleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getUserRolesByOrganizationId = (
    organizationId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRoleResponse[]>(
      {url: `/api/security/user-roles/organizations/${organizationId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRolesByOrganizationIdQueryKey = (organizationId: number,) => {
    return [`/api/security/user-roles/organizations/${organizationId}`] as const;
    }

    
export const getGetUserRolesByOrganizationIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError = unknown>(organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRolesByOrganizationIdQueryKey(organizationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>> = ({ signal }) => getUserRolesByOrganizationId(organizationId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(organizationId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRolesByOrganizationIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>>
export type GetUserRolesByOrganizationIdQueryError = unknown



export function useGetUserRolesByOrganizationId<TData = Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError = unknown>(
 organizationId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolesByOrganizationId>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRolesByOrganizationIdQueryOptions(organizationId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getUserRolePermissionById = (
    userRolePermissionId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<UserRolePermissionResponse>(
      {url: `/api/security/user-role-permissions/${userRolePermissionId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetUserRolePermissionByIdQueryKey = (userRolePermissionId: number,) => {
    return [`/api/security/user-role-permissions/${userRolePermissionId}`] as const;
    }

    
export const getGetUserRolePermissionByIdQueryOptions = <TData = Awaited<ReturnType<typeof getUserRolePermissionById>>, TError = unknown>(userRolePermissionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserRolePermissionByIdQueryKey(userRolePermissionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserRolePermissionById>>> = ({ signal }) => getUserRolePermissionById(userRolePermissionId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(userRolePermissionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissionById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUserRolePermissionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getUserRolePermissionById>>>
export type GetUserRolePermissionByIdQueryError = unknown



export function useGetUserRolePermissionById<TData = Awaited<ReturnType<typeof getUserRolePermissionById>>, TError = unknown>(
 userRolePermissionId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserRolePermissionById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetUserRolePermissionByIdQueryOptions(userRolePermissionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteUserRolePermission = (
    userRolePermissionId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/security/user-role-permissions/${userRolePermissionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteUserRolePermissionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRolePermission>>, TError,{userRolePermissionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserRolePermission>>, TError,{userRolePermissionId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserRolePermission>>, {userRolePermissionId: number}> = (props) => {
          const {userRolePermissionId} = props ?? {};

          return  deleteUserRolePermission(userRolePermissionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteUserRolePermissionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserRolePermission>>>
    
    export type DeleteUserRolePermissionMutationError = unknown

    export const useDeleteUserRolePermission = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserRolePermission>>, TError,{userRolePermissionId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteUserRolePermission>>,
        TError,
        {userRolePermissionId: number},
        TContext
      > => {

      const mutationOptions = getDeleteUserRolePermissionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getPermissions = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PermissionResponse[]>(
      {url: `/api/security/permissions`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPermissionsQueryKey = () => {
    return [`/api/security/permissions`] as const;
    }

    
export const getGetPermissionsQueryOptions = <TData = Awaited<ReturnType<typeof getPermissions>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({ signal }) => getPermissions(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPermissionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPermissions>>>
export type GetPermissionsQueryError = unknown



export function useGetPermissions<TData = Awaited<ReturnType<typeof getPermissions>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPermissions>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPermissionsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




