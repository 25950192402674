import {
  CourseTaskProperties,
  CurriculumTaskProperties,
  CustomTaskProperties,
  FileUploadTaskProperties,
  FormPacketTaskProperties,
  FormTaskProperties,
  SendEmailTaskProperties,
  TaskConfig,
  TaskListTaskProperties,
  TaskOptionTaskProperties,
  TaskTypeEnum,
  ThirdPartyTaskProperties,
  WorkflowStepTaskProperties,
} from '@btrway/api-workflow';

type TaskPropertiesMap = {
  [TaskTypeEnum.completeCourse]: CourseTaskProperties;
  [TaskTypeEnum.submitForm]: FormTaskProperties;
  [TaskTypeEnum.completeCurriculum]: CurriculumTaskProperties;
  [TaskTypeEnum.completeTaskList]: TaskListTaskProperties;
  [TaskTypeEnum.completeTaskOption]: TaskOptionTaskProperties;
  [TaskTypeEnum.uploadFile]: FileUploadTaskProperties;
  [TaskTypeEnum.completeWorkflowStep]: WorkflowStepTaskProperties;
  [TaskTypeEnum.sendEmail]: SendEmailTaskProperties;
  [TaskTypeEnum.thirdParty]: ThirdPartyTaskProperties;
  [TaskTypeEnum.completeFormPacket]: FormPacketTaskProperties;
  [TaskTypeEnum.completeTask]: CustomTaskProperties;
  [TaskTypeEnum.addtoWorkgroup]: never;
  [TaskTypeEnum.createCalendarEvent]: never;
};

export function getTypedTaskProperties<T extends keyof TaskPropertiesMap>(
  config: TaskConfig | undefined,
  expectedTaskType: T
): TaskPropertiesMap[T] | undefined {
  if (config?.taskType === expectedTaskType) {
    return config.taskProperties as TaskPropertiesMap[T];
  }
  return undefined;
}
