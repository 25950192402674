import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
  HelpRepositoryEnum,
  useDeleteHelpResource,
  useGetHelpResourcesByRepository,
} from '@btrway/api-help';
import { Button, Group, Stack, TextInput } from '@mantine/core';
import { IconPlus, IconSearch } from '@tabler/icons-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useHelpResourceEditor } from '../../hooks/useHelpResourceEditor';
import HelpResourceEditorModal from '../HelpResourceEditorModal/HelpResourceEditorModal';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface HelpResourcesViewProps {
  repository: HelpRepositoryEnum;
}

const HelpResourcesView: React.FC<HelpResourcesViewProps> = ({
  repository,
}) => {
  const { data: helpResources, refetch } =
    useGetHelpResourcesByRepository(repository);
  const { mutateAsync: deleteHelpResource } = useDeleteHelpResource();
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openHelpResourceEditor } = useHelpResourceEditor();

  const onDeleteClick = useCallback(
    async (id: number) => {
      try {
        await deleteHelpResource({ helpResourceId: id });
        refetch();
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    },
    [deleteHelpResource, refetch]
  );

  const actionCellRenderer = useCallback(
    (params: any) => (
      <Button
        size="compact-sm"
        variant="light"
        color="red"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick(params.data.id);
        }}
      >
        Delete
      </Button>
    ),
    [onDeleteClick]
  );

  const onRowClick = useCallback(
    (event: any) => {
      const id = event.data.id;
      openHelpResourceEditor(id, repository, refetch);
    },
    [openHelpResourceEditor, repository, refetch]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Title',
        field: 'metadata.title',
        sort: 'asc',
        flex: 2,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Published Date',
        field: 'publishedDate',
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: actionCellRenderer,
        width: 150,
        suppressHeaderMenuButton: true,
      },
    ],
    [actionCellRenderer]
  );

  const filteredHelpResources = useMemo(() => {
    if (!searchTerm) return helpResources;
    return helpResources?.filter((helpResource) =>
      helpResource.metadata?.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [helpResources, searchTerm]);

  return (
    <>
      <Stack gap="md" h="100%">
        <Group justify="space-between">
          <TextInput
            placeholder="Search articles..."
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.currentTarget.value)}
            leftSection={<IconSearch size={16} />}
            style={{ flex: 1 }}
          />
          <Button
            leftSection={<IconPlus size={16} />}
            onClick={() => openHelpResourceEditor(null, repository, refetch)}
          >
            Add Article
          </Button>
        </Group>
        <div
          className="ag-theme-quartz"
          style={{ height: 'calc(100% - 40px)', width: '100%' }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={filteredHelpResources}
            onGridReady={(params) => params.api.sizeColumnsToFit()}
            onRowClicked={onRowClick}
          />
        </div>
      </Stack>

      <HelpResourceEditorModal title="Help Resource Editor" />
    </>
  );
};

export default HelpResourcesView;
