// editorStyles.ts
export const EDITOR_COLORS = {
  selected: {
    background: 'rgb(180, 215, 255)', // Light blue background when selected
  },
  default: {
    background: 'var(--mantine-color-gray-0)', // Default gray background
  },
  icon: {
    color: 'var(--mantine-color-blue-6)',
  },
};
