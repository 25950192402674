import { ColDef, GetRowIdFunc } from '@ag-grid-community/core';
import { Box, Flex, Group, SegmentedControl } from '@mantine/core';
import { IconColumns, IconLayoutGrid } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ResponsiveGrid from '../ResponsiveGrid/ResponsiveGrid';

interface GridCardViewProps<T> {
  data: T[];
  gridCols: ColDef[];
  cardComponent: React.ComponentType<{
    data: T;
    className?: string;
    onClick?: () => void;
  }>;
  defaultView?: 'grid' | 'card';
  getNavigationPath?: (data: T) => string;
}

function GridCardView<T>({
  data,
  gridCols,
  cardComponent: CardComponent,
  defaultView = 'grid',
  getNavigationPath,
}: GridCardViewProps<T>) {
  const [view, setView] = useState<'grid' | 'card'>(defaultView);
  const navigate = useNavigate();

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const getRowId: GetRowIdFunc = (params) => {
    return params.data?.id?.toString() || '';
  };

  const handleItemClick = (item: T) => {
    if (getNavigationPath) {
      const path = getNavigationPath(item);
      navigate(path);
    }
  };

  return (
    <Flex direction="column" style={{ height: '100%' }}>
      <Group justify="flex-end" mb={4}>
        <SegmentedControl
          bg="transparent"
          value={view}
          onChange={(value) => setView(value as 'grid' | 'card')}
          data={[
            {
              label: (
                <Flex align="center" gap="xs">
                  <IconColumns size={16} />
                </Flex>
              ),
              value: 'grid',
            },
            {
              label: (
                <Flex align="center" gap="xs">
                  <IconLayoutGrid size={16} />
                </Flex>
              ),
              value: 'card',
            },
          ]}
        />
      </Group>
      <Box style={{ flex: 1, minHeight: 0 }}>
        {view === 'grid' ? (
          <ResponsiveGrid
            columnDefs={gridCols}
            rowData={data}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            getRowId={getRowId}
            rowModelType="clientSide"
            primaryNavigationField="name"
            responsiveRowComponent={CardComponent}
            responsiveRowSpacing={10}
            onRowClick={handleItemClick}
          />
        ) : (
          <Flex
            wrap="wrap"
            gap="md"
            justify="flex-start"
            align="stretch"
            style={{ height: '100%', overflowY: 'auto' }}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                style={{
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <CardComponent
                  data={item}
                  onClick={() => handleItemClick(item)}
                />
              </Box>
            ))}
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

export default GridCardView;
