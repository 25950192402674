import { AssetResponse } from '@btrway/api-courseware';
import React, { useCallback, useRef, useState } from 'react';
import styles from './CustomTimeline.module.css';

interface ChapterTimelineProps {
  course: AssetResponse;
  currentChapter: string;
  onChapterSelect: (chapterKey: string) => void;
}
// chapters: ChapterDTO[];
// currentTime: number;
// totalDuration: number;
// onSeek: (time: number) => void;

const ChapterTimeline: React.FC<ChapterTimelineProps> = ({
  course,
  // chapters,
  // currentTime,
  // totalDuration,
  // onSeek,
}) => {
  const timelineRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const chapters = course.chapters || [];
  const totalDuration = 0;
  const currentTime = 0;
  const handleTimelineClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // if (timelineRef.current) {
      //   const rect = timelineRef.current.getBoundingClientRect();
      //   const clickPosition = e.clientX - rect.left;
      //   const percentage = clickPosition / rect.width;
      //   const seekTime = percentage * totalDuration;
      //   onSeek(seekTime);
      // }
    },
    [] //[totalDuration, onSeek]
  );

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      // if (isDragging && timelineRef.current) {
      //   const rect = timelineRef.current.getBoundingClientRect();
      //   const movePosition = e.clientX - rect.left;
      //   const percentage = Math.max(0, Math.min(1, movePosition / rect.width));
      //   const seekTime = percentage * totalDuration;
      //   onSeek(seekTime);
      // }
    },
    [] //[isDragging, totalDuration, onSeek]
  );

  const getChapterProgress = (
    chapterStart: number,
    chapterDuration: number
  ) => {
    // const chapterEnd = chapterStart + chapterDuration;
    // if (currentTime <= chapterStart) return 0;
    // if (currentTime >= chapterEnd) return 100;
    // return ((currentTime - chapterStart) / chapterDuration) * 100;
    return 0;
  };

  return (
    <div
      className={styles.chapterTimeline}
      ref={timelineRef}
      onClick={handleTimelineClick}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div className={styles.timelineChapters}>
        {chapters.map((chapter, index) => {
          const chapterStart = chapters
            .slice(0, index)
            .reduce((sum, c) => sum + (c.duration || 0), 0);
          const chapterWidth = ((chapter.duration || 0) / totalDuration) * 100;
          const chapterProgress = getChapterProgress(
            chapterStart,
            chapter.duration || 0
          );

          return (
            <div
              key={chapter.key}
              className={styles.timelineChapter}
              style={{ width: `${chapterWidth}%` }}
            >
              <div
                className={styles.chapterProgress}
                style={{ width: `${chapterProgress}%` }}
              />
              <div className={styles.tooltip}>
                {chapter.title} ({formatTime(chapter.duration || 0)})
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={styles.seekHandle}
        style={{ left: `${(currentTime / totalDuration) * 100}%` }}
        onMouseDown={handleMouseDown}
      />
    </div>
  );
};

export default ChapterTimeline;
