import { CourseSelection } from '@btrway/api-workflow';
import { useCourses } from '@btrway/courseware-manager';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import { Card, Group, Text, UnstyledButton } from '@mantine/core';
import {
  IconAlertTriangleFilled,
  IconBook,
  IconEdit,
} from '@tabler/icons-react';
import React from 'react';
import { CourseSelector } from '../CourseSelector/CourseSelector';

interface CourseSelectorFieldProps {
  courseSelection?: CourseSelection;
  onCourseSelect: (courseSelection: CourseSelection, name: string) => void;
  title?: string;
}

export const CourseSelectorField: React.FC<CourseSelectorFieldProps> = ({
  courseSelection,
  onCourseSelect,
  title,
}) => {
  const { courses, isLoading } = useCourses();
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  const selectedCourse = courses.find(
    (course) =>
      course.uid.toString() === courseSelection?.courseUid.value?.toString()
  );

  const openCourseSelector = () => {
    setRightSectionContent(
      <CourseSelector
        courses={courses}
        selectedCourseUid={courseSelection?.courseUid.value?.toString() || null}
        onCourseSelect={(courseUid, name) => {
          const newCourseSelection: CourseSelection = {
            courseUid: { value: parseInt(courseUid, 10) },
          };
          onCourseSelect(newCourseSelection, name);
          clearRightSectionContent();
        }}
        onClose={clearRightSectionContent}
      />
    );
  };

  if (isLoading) {
    return <Text>Loading courses...</Text>;
  }

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      onClick={openCourseSelector}
      style={{ cursor: 'pointer' }}
    >
      <Group justify="space-between" wrap="nowrap">
        <Group justify="flex-start" gap="xs" wrap="nowrap" align="flex-start">
          <IconBook style={{ width: '20px', height: '20px' }} />
          <Text fz="md" fw={600}>
            {selectedCourse ? selectedCourse.title : title || 'Select a Course'}
          </Text>
        </Group>
        <UnstyledButton>
          {selectedCourse ? (
            <IconEdit size={20} color="blue" />
          ) : (
            <IconAlertTriangleFilled size={20} color="orange" />
          )}
        </UnstyledButton>
      </Group>
    </Card>
  );
};
