import {
  getGetStateProvincesQueryKey,
  useGetStateProvinces,
} from '@btrway/api-core';
import { useCallback } from 'react';

export const useStateProvinces = () => {
  const {
    data: stateProvinces,
    isLoading,
    error,
    refetch,
  } = useGetStateProvinces({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetStateProvincesQueryKey(),
    },
  });

  const getStateProvinceByCode = useCallback(
    (code: string) => {
      return stateProvinces?.find((state) => state.code === code);
    },
    [stateProvinces]
  );

  return {
    stateProvinces: stateProvinces ?? [],
    isLoading,
    error,
    getStateProvinceByCode,
    refetchStateProvinces: refetch,
  };
};
