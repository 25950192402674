import {
  WorkflowTemplateResponse,
  useGetWorkflowTemplateById,
} from '@btrway/api-workflow';
import { LoadingSpinner } from '@btrway/shared-components';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

interface WorkflowTemplateContextValue {
  workflowTemplate: WorkflowTemplateResponse;
  isLoading: boolean;
}

const WorkflowTemplateContext = createContext<
  WorkflowTemplateContextValue | undefined
>(undefined);

export const useWorkflowTemplate = () => {
  const context = useContext(WorkflowTemplateContext);
  if (!context) {
    throw new Error(
      'useWorkflowTemplate must be used within a WorkflowTemplateProvider'
    );
  }
  return context;
};

interface WorkflowTemplateProviderProps {
  children: React.ReactNode;
}

export const WorkflowTemplateProvider: React.FC<
  WorkflowTemplateProviderProps
> = ({ children }) => {
  const { workflowTemplateId } = useParams();
  const workflowTemplateIdNumber = Number(workflowTemplateId);

  const {
    data: workflowTemplate,
    isLoading,
    error,
  } = useGetWorkflowTemplateById(workflowTemplateIdNumber);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !workflowTemplate) {
    return <div>Error loading workflow template</div>;
  }

  return (
    <WorkflowTemplateContext.Provider
      value={{
        workflowTemplate,
        isLoading: false,
      }}
    >
      {children}
    </WorkflowTemplateContext.Provider>
  );
};
