import { DataTypeEnum } from '@btrway/api-workflow';
import { Question } from 'survey-core';

export const mapQuestionTypeToDataType = (question: Question): DataTypeEnum => {
  const type = question.getType();
  const inputType = (question as any).inputType; // Using 'any' as inputType might not be in the type definition

  switch (type) {
    case 'text':
      switch (inputType) {
        case 'email':
          return DataTypeEnum.email;
        case 'tel':
          return DataTypeEnum.phone;
        case 'date':
          return DataTypeEnum.date;
        case 'datetime-local':
          return DataTypeEnum.dateTime;
        case 'time':
          return DataTypeEnum.time;
        default:
          return DataTypeEnum.text;
      }
    case 'boolean':
      return DataTypeEnum.boolean;
    case 'radiogroup':
    case 'dropdown':
      return DataTypeEnum.text; // Assuming these are represented as text
    case 'rating':
    case 'number':
      return DataTypeEnum.number;
    case 'comment':
      return DataTypeEnum.text;
    case 'person':
      return DataTypeEnum.person;
    case 'persondropdown':
      return DataTypeEnum.person;
    case 'personSearch':
      return DataTypeEnum.person;
    default:
      return DataTypeEnum.text; // Default to text for unknown types
  }
};
