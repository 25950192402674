/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type AssetTypeEnum = typeof AssetTypeEnum[keyof typeof AssetTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetTypeEnum = {
  course: 'course',
  file: 'file',
} as const;
