import {
  useGetWorkgroupTypesByOrganizationId,
  WorkgroupResponse,
} from '@btrway/api-core';
import {
  TaskStatisticsRequest,
  useGetTaskStatistics,
  WorkgroupTaskStatisticsResponse,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import {
  CloseButton,
  Container,
  Group,
  SegmentedControl,
  Skeleton,
  Stack,
  TextInput,
  Transition,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { TaskStatisticsCard } from '../TaskStatisticsCard/TaskStatisticsCard';
import { TaskStatisticsSkeleton } from '../TaskStatisticsSkeleton/TaskStatisticsSkeleton';

interface TaskStatisticsViewProps {
  workgroup: WorkgroupResponse;
}

export const TaskStatisticsView: React.FC<TaskStatisticsViewProps> = ({
  workgroup,
}) => {
  const [selectedWorkgroupTypeId, setSelectedWorkgroupTypeId] =
    useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch] = useDebouncedValue(searchTerm, 300);
  const [statistics, setStatistics] = useState<
    WorkgroupTaskStatisticsResponse[]
  >([]);
  const [expandedWorkgroups, setExpandedWorkgroups] = useState<Set<number>>(
    new Set()
  );

  const { currentOrganization } = useAuthenticatedUser();

  const { data: workgroupTypes, isLoading: isLoadingTypes } =
    useGetWorkgroupTypesByOrganizationId(currentOrganization.id);

  const { mutateAsync: getTaskStatistics, isPending: isLoadingStats } =
    useGetTaskStatistics();

  useEffect(() => {
    const fetchStatistics = async () => {
      if (!currentOrganization?.id || !workgroup?.id) return;

      const request: TaskStatisticsRequest = {
        organizationId: currentOrganization.id,
        workgroupId: workgroup.id,
        workgroupTypeId: selectedWorkgroupTypeId
          ? Number(selectedWorkgroupTypeId)
          : undefined,
        workgroupName: debouncedSearch || undefined,
      };

      const response = await getTaskStatistics({ data: request });
      setStatistics(Array.isArray(response) ? response : [response]);
    };

    fetchStatistics();
  }, [
    selectedWorkgroupTypeId,
    debouncedSearch,
    currentOrganization?.id,
    workgroup?.id,
  ]);

  const toggleExpand = (workgroupId: number) => {
    setExpandedWorkgroups((prev) => {
      const next = new Set(prev);
      if (next.has(workgroupId)) {
        next.delete(workgroupId);
      } else {
        next.add(workgroupId);
      }
      return next;
    });
  };

  if (isLoadingTypes) {
    return (
      <Container size="xl" p={0}>
        <Stack>
          <Skeleton height={36} radius="sm" />
          <TaskStatisticsSkeleton />
        </Stack>
      </Container>
    );
  }

  const segmentedControlData = [
    { label: 'All Types', value: '' },
    ...(workgroupTypes?.map((type) => ({
      label: type.name,
      value: type.id.toString(),
    })) || []),
  ];

  const customTransition = {
    in: { opacity: 1, transform: 'translateY(0)' },
    out: { opacity: 0, transform: 'translateY(-20px)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  return (
    <Container size="xl" p={0}>
      <Stack gap="md">
        <Group align="flex-start">
          <Stack gap="xs" style={{ flex: 1 }}>
            <SegmentedControl
              fullWidth
              value={selectedWorkgroupTypeId}
              onChange={setSelectedWorkgroupTypeId}
              data={segmentedControlData}
            />
            <TextInput
              placeholder="Search workgroups..."
              leftSection={<IconSearch size="1rem" />}
              rightSection={
                searchTerm ? (
                  <CloseButton
                    size="sm"
                    onClick={() => setSearchTerm('')}
                    aria-label="Clear search"
                  />
                ) : null
              }
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.currentTarget.value)}
            />
          </Stack>
        </Group>

        {/* {isLoadingStats ? (
          <Transition
            mounted={true}
            transition={customTransition}
            duration={400}
            timingFunction="ease"
          >
            {(styles) => (
              <div style={styles}>
                <TaskStatisticsSkeleton />
              </div>
            )}
          </Transition>
        ) : ( */}
        <Transition
          mounted={true}
          transition={customTransition}
          duration={400}
          timingFunction="ease"
        >
          {(styles) => (
            <Stack gap="md" style={styles}>
              {statistics.map((stat) => (
                <TaskStatisticsCard
                  key={stat.workgroupId}
                  statistics={stat}
                  isExpanded={expandedWorkgroups.has(stat.workgroupId!)}
                  onToggleExpand={() =>
                    stat.workgroupId && toggleExpand(stat.workgroupId)
                  }
                />
              ))}
            </Stack>
          )}
        </Transition>
        {/* )} */}
      </Stack>
    </Container>
  );
};
