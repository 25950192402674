import {
  Box,
  Button,
  Group,
  Progress,
  Stack,
  Text,
  Textarea,
  Title,
  Transition,
} from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import OpenAI from 'openai';
import { ChatCompletionTool } from 'openai/resources';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';

import { TaskConfig, TaskTypeEnum } from '@btrway/api-workflow';
import { useTaskCategories } from '@btrway/workflow-manager';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

interface EmptyTaskListProps {
  onBuild: (tasks: TaskConfig[]) => void;
  onSkip: () => void;
  style?: React.CSSProperties;
}

interface TaskAssistantResponse {
  tasks: Array<{
    title: string;
    subtitle?: string;
    taskCategory?: string;
    startDateOffset?: number;
    endDateOffset?: number;
    taskType: 'completeTask' | 'createCalendarEvent' | 'sendEmail';
  }>;
}

const convertToTaskConfigs = (
  taskAssistant: TaskAssistantResponse,
  getTaskCategoryByName: (name: string | undefined) => any
): TaskConfig[] => {
  return taskAssistant.tasks.map(
    (task): TaskConfig => ({
      taskKey: crypto.randomUUID(),
      taskType: TaskTypeEnum[task.taskType],
      metadata: {
        title: task.title,
        subtitle: task.subtitle,
        taskCategoryKey: task.taskCategory
          ? getTaskCategoryByName(task.taskCategory)?.key
          : undefined,
      },
      taskListConfig: {
        startDate:
          task.startDateOffset !== undefined
            ? { offsetDays: task.startDateOffset }
            : undefined,
        endDate:
          task.endDateOffset !== undefined
            ? { offsetDays: task.endDateOffset }
            : undefined,
      },
    })
  );
};

const ProgressUpdate: React.FC<{ message: string }> = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => setVisible(false), 2000);
    return () => clearTimeout(timer);
  }, [message]);

  return (
    <Transition
      mounted={visible}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Text style={{ ...styles, position: 'absolute', bottom: 20, left: 20 }}>
          {message}
        </Text>
      )}
    </Transition>
  );
};

// Constants for chunking and processing
const MAX_CHUNK_SIZE = 4000; // Characters per chunk
const MAX_TASKS_PER_REQUEST = 20; // Maximum number of tasks to process in a single API call

interface ChunkProcessingStatus {
  processed: number;
  total: number;
}

const EmptyTaskList: React.FC<EmptyTaskListProps> = ({
  onBuild,
  onSkip,
  style,
}) => {
  const [taskDescription, setTaskDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [progressUpdate, setProgressUpdate] = useState('');
  const [processingStatus, setProcessingStatus] =
    useState<ChunkProcessingStatus | null>(null);
  const { taskCategories, getTaskCategoryByName } = useTaskCategories();

  // Split text into processable chunks
  const splitIntoChunks = (text: string): string[] => {
    const chunks: string[] = [];
    let currentChunk = '';
    const lines = text.split('\n');

    for (const line of lines) {
      if ((currentChunk + line).length > MAX_CHUNK_SIZE) {
        if (currentChunk) {
          chunks.push(currentChunk.trim());
          currentChunk = '';
        }
        if (line.length > MAX_CHUNK_SIZE) {
          // Split very long lines if necessary
          const lineChunks =
            line.match(new RegExp(`.{1,${MAX_CHUNK_SIZE}}`, 'g')) || [];
          chunks.push(...lineChunks);
        } else {
          currentChunk = line;
        }
      } else {
        currentChunk += (currentChunk ? '\n' : '') + line;
      }
    }
    if (currentChunk) {
      chunks.push(currentChunk.trim());
    }
    return chunks;
  };

  const formatTaskListFunction: ChatCompletionTool = {
    type: 'function',
    function: {
      name: 'formatTaskList',
      description:
        'Format a task list structure based on the provided tasks data',
      parameters: {
        type: 'object',
        properties: {
          tasks: {
            type: 'array',
            description: 'Array of tasks',
            items: {
              type: 'object',
              properties: {
                title: {
                  type: 'string',
                  description: 'Title of the task',
                },
                subtitle: {
                  type: 'string',
                  description:
                    'Subtitle or description of the task. Not required but should be used if available.',
                },
                taskCategory: {
                  type: 'string',
                  description:
                    'Task category name (will be matched against available categories)',
                  enum: taskCategories.map((cat) => cat.name),
                },
                startDateOffset: {
                  type: 'number',
                  description: 'Number of days from the workflow start date',
                },
                endDateOffset: {
                  type: 'number',
                  description:
                    'Number of days from the workflow start date for task completion',
                },
                taskType: {
                  type: 'string',
                  enum: ['completeTask', 'createCalendarEvent', 'sendEmail'],
                  description: 'Type of task to create',
                },
              },
              required: ['title', 'taskType'],
            },
          },
        },
        required: ['tasks'],
      },
    },
  };

  const processChunk = async (
    chunk: string,
    openai: OpenAI
  ): Promise<TaskConfig[]> => {
    const response = await openai.chat.completions.create({
      model: 'gpt-4o-mini',
      messages: [
        {
          role: 'system',
          content: `You are an AI assistant that helps users create task lists.
            Convert the provided task data into a structured list of tasks.
            Available task categories are: ${taskCategories
              .map((cat) => cat.name)
              .join(', ')}.
            Each task must have a title and task type. Other fields are optional.
            Limit response to ${MAX_TASKS_PER_REQUEST} tasks maximum per request.
            Task types available are: completeTask, createCalendarEvent, and sendEmail.`,
        },
        { role: 'user', content: chunk },
      ],
      tools: [formatTaskListFunction],
      tool_choice: {
        type: 'function',
        function: { name: 'formatTaskList' },
      },
      temperature: 0.7,
    });

    const functionCall = response.choices[0].message.tool_calls?.[0];
    if (functionCall && functionCall.function.name === 'formatTaskList') {
      const taskStructure = JSON.parse(functionCall.function.arguments);
      return convertToTaskConfigs(taskStructure, getTaskCategoryByName);
    }
    return [];
  };

  const handleBuildClick = async () => {
    if (!taskDescription && !file) {
      console.error('No input provided');
      return;
    }

    setLoading(true);
    const openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    try {
      let content: string;
      if (file) {
        if (file.type === 'application/pdf') {
          content = await extractTextFromPDF(file);
        } else {
          content = await file.text();
        }
      } else {
        content = taskDescription;
      }

      const chunks = splitIntoChunks(content);
      setProcessingStatus({ processed: 0, total: chunks.length });

      const allTasks: TaskConfig[] = [];
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i];
        setProgressUpdate(`Processing chunk ${i + 1} of ${chunks.length}...`);
        const chunkTasks = await processChunk(chunk, openai);
        allTasks.push(...chunkTasks);
        setProcessingStatus({ processed: i + 1, total: chunks.length });
      }

      onBuild(allTasks);
    } catch (error) {
      console.error('Error processing input or calling OpenAI API:', error);
    } finally {
      setLoading(false);
      setProcessingStatus(null);
    }
  };
  const handleDrop = (files: File[]) => {
    setFile(files[0]);
  };
  const extractTextFromPDF = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
    let text = '';
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map((item: any) => item.str).join(' ') + '\n';
    }
    return text;
  };

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <Stack
        align="center"
        gap="xl"
        style={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          flex: 1,
          overflow: 'auto',
          padding: '20px',
        }}
      >
        <Title order={1} ta="center">
          Let's Create Your Task List!
        </Title>

        <Text fz="lg" fw={500} ta="center">
          Describe the tasks you'd like to create, or upload an existing
          document.
        </Text>

        <Textarea
          placeholder="Describe your tasks here..."
          rows={10}
          style={{ width: '100%' }}
          value={taskDescription}
          onChange={(event) => setTaskDescription(event.currentTarget.value)}
        />

        <Dropzone
          onDrop={(files) => setFile(files[0])}
          maxSize={5 * 1024 ** 2}
          accept={['text/plain', 'application/pdf', 'text/csv']}
          multiple={false}
        >
          <Group
            justify="center"
            gap="xl"
            style={{ minHeight: 80, pointerEvents: 'none' }}
          >
            <Text ta="center">Or Upload an Existing File</Text>
          </Group>
        </Dropzone>

        {file && (
          <Text size="sm" ta="center">
            Selected file: {file.name}
          </Text>
        )}

        {processingStatus && (
          <Stack w="100%">
            <Progress
              value={
                (processingStatus.processed / processingStatus.total) * 100
              }
              size="sm"
            />
            <Text size="sm" ta="center">
              Processing chunk {processingStatus.processed} of{' '}
              {processingStatus.total}
            </Text>
          </Stack>
        )}
      </Stack>

      <Group justify="center" gap="md" style={{ padding: '20px' }}>
        <Button onClick={handleBuildClick} size="lg" loading={loading}>
          Create Tasks!
        </Button>
        <Button onClick={onSkip} variant="outline" size="lg">
          Start From Scratch
        </Button>
      </Group>
    </Box>
  );
};

export default EmptyTaskList;
