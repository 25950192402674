import { CertificationTypeEnum } from '@btrway/api-workflow';
import {
  IconAlignBoxLeftTop,
  IconAward,
  IconCheckbox,
  IconCircleDashedCheck,
  IconClipboardCheck,
  IconDeviceTv,
  IconDirections,
  IconExternalLink,
  IconFileStack,
  IconListCheck,
  IconMoodSmile,
  IconProps,
  IconUser,
} from '@tabler/icons-react';
import React from 'react';

const iconMap: Record<CertificationTypeEnum, React.ComponentType<IconProps>> = {
  [CertificationTypeEnum.personData]: IconUser,
  [CertificationTypeEnum.taskData]: IconCircleDashedCheck,
  [CertificationTypeEnum.submitForm]: IconAlignBoxLeftTop,
  [CertificationTypeEnum.completeTaskList]: IconListCheck,
  [CertificationTypeEnum.completeCurriculum]: IconClipboardCheck,
  [CertificationTypeEnum.completeCourse]: IconDeviceTv,
  [CertificationTypeEnum.thirdParty]: IconExternalLink,
  [CertificationTypeEnum.certification]: IconAward,
  [CertificationTypeEnum.completeFormPacket]: IconFileStack,
  [CertificationTypeEnum.completeTaskOption]: IconDirections,
  [CertificationTypeEnum.completeTask]: IconCheckbox,
};

interface CertificationTypeIconProps {
  iconName: CertificationTypeEnum;
  color?: string;
  size?: string | number;
}

export const CertificationTypeIcon: React.FC<CertificationTypeIconProps> = ({
  iconName,
  color = 'currentColor',
  size = 24,
}) => {
  const IconComponent = iconMap[iconName] || IconMoodSmile;
  return <IconComponent color={color} size={size} />;
};
