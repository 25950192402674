import { Box, Button, Image, Text } from '@mantine/core';
import MuxUploader from '@mux/mux-uploader-react';
import { useCallback, useEffect, useState } from 'react';
import { useMuxUpload } from '../../hooks/useMuxUpload';

interface MuxSingleVideoUploaderProps {
  onUploadComplete: (
    assetId: string,
    playbackId: string,
    thumbnail: string
  ) => void;
  existingPlaybackId?: string;
}

const MuxSingleVideoUploader: React.FC<MuxSingleVideoUploaderProps> = ({
  onUploadComplete,
  existingPlaybackId,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string | null>(
    existingPlaybackId
      ? `https://image.mux.com/${existingPlaybackId}/thumbnail.jpg?time=0`
      : null
  );
  console.log('existingPlaybackId', existingPlaybackId);
  console.log('thumbnail', thumbnail);

  const { endpointFunction, handleUploadStart, handleUploadSuccess } =
    useMuxUpload();

  useEffect(() => {
    if (existingPlaybackId) {
      setThumbnail(
        `https://image.mux.com/${existingPlaybackId}/thumbnail.jpg?time=0`
      );
    }
  });

  const handleSuccess = useCallback(async () => {
    console.log('Upload successful');
    setIsUploading(false);

    const result = await handleUploadSuccess();
    if (result) {
      const { playbackId } = result;
      const thumbnailUrl = `https://image.mux.com/${playbackId}/thumbnail.jpg?time=0`;
      setThumbnail(thumbnailUrl);
      onUploadComplete(playbackId, playbackId, thumbnailUrl);
    }
  }, [handleUploadSuccess, onUploadComplete]);

  const handleStart = useCallback(() => {
    console.log('Upload started');
    setIsUploading(true);
    setThumbnail(null);
  }, []);

  return (
    <Box>
      {!thumbnail && (
        <MuxUploader
          endpoint={endpointFunction}
          onSuccess={handleSuccess}
          onUploadStart={handleStart}
        />
      )}

      {thumbnail && (
        <Box style={{ position: 'relative', maxHeight: 100 }}>
          <Image
            src={thumbnail}
            alt="Video thumbnail"
            style={{
              height: 100,
              width: '100%',
              objectFit: 'cover',
            }}
          />
          <Button
            size="sm"
            variant="light"
            color="red"
            style={{ position: 'absolute', top: 8, right: 8 }}
            onClick={() => {
              setThumbnail(null);
              onUploadComplete('', '', '');
            }}
          >
            Remove
          </Button>
        </Box>
      )}

      {isUploading && <Text mt="md">Upload in progress...</Text>}
    </Box>
  );
};

export default MuxSingleVideoUploader;
