import { Stack, Text, Title } from '@mantine/core';
import React from 'react';

const RegistrationConfirmation: React.FC = () => {
  return (
    <Stack align="center" justify="center" style={{ height: '100%' }}>
      <Title order={2}>Registration Submitted</Title>
      <Text>
        Thank you for your registration. We have received your information.
      </Text>
      <Text>You will receive a confirmation email shortly.</Text>
      <Text>You will be rerouted to the app soon</Text>
    </Stack>
  );
};

export default RegistrationConfirmation;
