import { Stack, Text } from '@mantine/core';
import React from 'react';
import { CertificationConfigProps } from '../../../types/editors';
import { DataPropertiesTriggerBuilder } from '../DataPropertiesTriggerBuilder/DataPropertiesTriggerBuilder';

export const CertificationDataConfiguration: React.FC<
  CertificationConfigProps
> = ({
  config,
  onConfigChange,
  workflowType,
  workflow,
  pendingWorkflowUpdates,
  onPendingWorkflowUpdates,
}) => {
  return (
    <Stack gap="xl">
      <Text size="sm" c="dimmed">
        Configure conditions based on{' '}
        {config.certificationType === 'personData'
          ? 'person information'
          : 'task completion data'}
      </Text>

      <DataPropertiesTriggerBuilder
        config={config}
        onConfigChange={onConfigChange}
      />
    </Stack>
  );
};
