import { WorkgroupTypeRequest, WorkgroupTypeResponse } from '@btrway/api-core';
import { useWorkgroupTypes } from '@btrway/workgroup-manager';
import {
  Button,
  Group,
  Modal,
  MultiSelect,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';

interface AddWorkgroupTypeModalProps {
  organizationId: number;
}

const AddWorkgroupTypeModal: React.FC<AddWorkgroupTypeModalProps> = ({
  organizationId,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { workgroupTypes, addWorkgroupType } =
    useWorkgroupTypes(organizationId);

  const [workgroupTypeName, setWorkgroupTypeName] = useState('');
  const [selectedChildTypes, setSelectedChildTypes] = useState<string[]>([]);

  const handleSubmit = async () => {
    if (workgroupTypeName) {
      const workgroupTypeRequest: WorkgroupTypeRequest = {
        organizationId: organizationId,
        name: workgroupTypeName,
        index: 0,
        childWorkgroupTypeIds: selectedChildTypes.map(Number),
      };

      try {
        await addWorkgroupType(workgroupTypeRequest);
        closeModal();
      } catch (error) {
        console.error('Error adding workgroup type:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  const closeModal = () => {
    close();
    resetForm();
  };

  const resetForm = () => {
    setWorkgroupTypeName('');
    setSelectedChildTypes([]);
  };

  return (
    <>
      <Tooltip label="Add Workgroup Type" withArrow>
        <Button
          variant="subtle"
          leftSection={<IconPlus size={16} />}
          onClick={open}
        >
          Create New Workgroup Type
        </Button>
      </Tooltip>
      <Modal
        opened={opened}
        onClose={closeModal}
        title={
          <Text fz={20} fw={500}>
            Add New Type of Workgroup
          </Text>
        }
        centered
      >
        <Stack gap={0} p={0} justify="space-between">
          <Stack gap={10} p={10}>
            <TextInput
              label="Name"
              value={workgroupTypeName}
              onChange={(event) => setWorkgroupTypeName(event.target.value)}
              autoFocus
              data-autofocus
            />
            <MultiSelect
              label="Child Workgroup Types"
              data={
                workgroupTypes?.map((type: WorkgroupTypeResponse) => ({
                  value: type.id.toString(),
                  label: type.name,
                })) || []
              }
              value={selectedChildTypes}
              onChange={setSelectedChildTypes}
              placeholder="Select child workgroup types"
            />
          </Stack>
          <Group justify="flex-end">
            <Button variant="default" onClick={closeModal} mr={10}>
              Cancel
            </Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default AddWorkgroupTypeModal;
