import { Combobox, ScrollArea, TextInput, useCombobox } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface TreeNode {
  value: string;
  label: string;
  children?: TreeNode[];
}

interface MantineTreeSelectProps {
  value: string | null | undefined;
  options: TreeNode[];
  onChange: (selectedOption: { value: string }) => void;
  filter?: boolean;
  placeholder?: string;
}

const MantineTreeSelect: React.FC<MantineTreeSelectProps> = ({
  value,
  options,
  onChange,
  filter = false,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<TreeNode[]>(options);
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());
  const [selectedNodePath, setSelectedNodePath] = useState<string | null>(null);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  useEffect(() => {
    if (filter) {
      const { filtered, expandedSet } = filterNodesAndExpandParents(
        options,
        searchValue
      );
      setFilteredOptions(filtered);
      setExpandedNodes(expandedSet);
    } else {
      setFilteredOptions(options);
    }
  }, [searchValue, options, filter]);

  useEffect(() => {
    if (value) {
      const path = findNodePath(options, value);
      if (path) {
        setSelectedNodePath(path.map((node) => node.label).join(' > '));
      }
    }
  }, [value, options]);

  const filterNodesAndExpandParents = (
    nodes: TreeNode[],
    query: string
  ): { filtered: TreeNode[]; expandedSet: Set<string> } => {
    const expandedSet = new Set<string>();

    const filterNode = (node: TreeNode): TreeNode | null => {
      if (node.label.toLowerCase().includes(query.toLowerCase())) {
        return node;
      }
      if (node.children) {
        const filteredChildren = node.children
          .map(filterNode)
          .filter((n): n is TreeNode => n !== null);
        if (filteredChildren.length > 0) {
          expandedSet.add(node.value);
          return { ...node, children: filteredChildren };
        }
      }
      return null;
    };

    const filtered = nodes
      .map(filterNode)
      .filter((n): n is TreeNode => n !== null);
    return { filtered, expandedSet };
  };

  const findNodePath = (
    nodes: TreeNode[],
    searchValue: string,
    path: TreeNode[] = []
  ): TreeNode[] | null => {
    for (const node of nodes) {
      const newPath = [...path, node];
      if (node.value === searchValue) {
        return newPath;
      }
      if (node.children) {
        const found = findNodePath(node.children, searchValue, newPath);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const toggleNodeExpansion = (nodeValue: string) => {
    setExpandedNodes((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(nodeValue)) {
        newSet.delete(nodeValue);
      } else {
        newSet.add(nodeValue);
      }
      return newSet;
    });
  };

  const handleNodeSelect = (node: TreeNode) => {
    const path = findNodePath(options, node.value);
    if (path) {
      setSelectedNodePath(path.map((n) => n.label).join(' > '));
      onChange({ value: node.value });
      combobox.closeDropdown();
    }
  };

  const renderTreeNode = (node: TreeNode, level: number = 0): JSX.Element => {
    const isExpanded = expandedNodes.has(node.value);
    const hasChildren = node.children && node.children.length > 0;

    return (
      <Combobox.Option value={node.value} key={node.value}>
        <div
          style={{
            paddingLeft: `${level * 20}px`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {hasChildren && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                toggleNodeExpansion(node.value);
              }}
              style={{ cursor: 'pointer', marginRight: '5px' }}
            >
              {isExpanded ? (
                <IconChevronDown size={16} />
              ) : (
                <IconChevronRight size={16} />
              )}
            </span>
          )}
          <span
            onClick={() => handleNodeSelect(node)}
            style={{ cursor: 'pointer', flex: 1 }}
          >
            {node.label}
          </span>
        </div>
        {isExpanded && hasChildren && (
          <div>
            {node.children?.map((child) => renderTreeNode(child, level + 1))}
          </div>
        )}
      </Combobox.Option>
    );
  };

  return (
    <Combobox store={combobox} withinPortal={false}>
      <Combobox.Target>
        <TextInput
          placeholder={placeholder}
          value={selectedNodePath || searchValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(event.currentTarget.value);
            setSelectedNodePath(null);
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            if (!selectedNodePath) {
              setSearchValue('');
            }
            combobox.closeDropdown();
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="scroll">
            {filteredOptions.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              filteredOptions.map((node) => renderTreeNode(node))
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default MantineTreeSelect;
