import { RouteConfig } from '@btrway/web-routing';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavItem } from '../types/navItem';

export function useLayoutNavigation(
  childRoutes: RouteConfig[],
  currentRoute: RouteConfig
): {
  navItems: NavItem[];
  currentValue: string;
  handleNavigation: (newPath: string) => void;
} {
  const location = useLocation();
  const navigate = useNavigate();

  // Filter routes that should appear in secondary navigation
  const navItems = React.useMemo(() => {
    return childRoutes
      .filter((route) => route.navLevel === 'secondary')
      .map((route) => ({
        value: route.path,
        label: typeof route.label === 'string' ? route.label : route.path,
        labelComponent: route.labelComponent,
        icon: route.icon,
        displayType: route.displayType || (route.icon ? 'both' : 'label'),
      }));
  }, [childRoutes]);

  // Calculate base path and active segment
  const { basePath, activeSegment } = React.useMemo(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const hasParameters = currentRoute.path.includes('/:');
    const isTopLevel = currentRoute.children?.some(
      (child) => child.navLevel === 'secondary'
    );

    // For parameterized routes (e.g., ops/:workgroupId)
    if (hasParameters) {
      const pathBeforeParam = currentRoute.path.split('/:')[0];
      const baseSegmentIndex = pathSegments.findIndex((segment) =>
        pathBeforeParam.split('/').includes(segment)
      );

      if (baseSegmentIndex === -1) return { basePath: '', activeSegment: '' };

      return {
        basePath: `/${pathSegments.slice(0, baseSegmentIndex + 2).join('/')}`,
        activeSegment: pathSegments[pathSegments.length - 1],
      };
    }

    // For top-level routes with nested navigation
    if (isTopLevel) {
      const routeSegment = currentRoute.path;
      const baseSegmentIndex = pathSegments.findIndex(
        (segment) => segment === routeSegment
      );

      if (baseSegmentIndex === -1) return { basePath: '', activeSegment: '' };

      // For top-level navigation, we want the next segment as the active one
      return {
        basePath: `/${pathSegments.slice(0, baseSegmentIndex + 1).join('/')}`,
        activeSegment: pathSegments[baseSegmentIndex + 1] || navItems[0]?.value,
      };
    }

    // For regular nested routes
    const routeFirstSegment = currentRoute.path.split('/')[0];
    const baseSegmentIndex = pathSegments.findIndex(
      (segment) => segment === routeFirstSegment
    );

    if (baseSegmentIndex === -1) return { basePath: '', activeSegment: '' };

    return {
      basePath: `/${pathSegments.slice(0, baseSegmentIndex + 1).join('/')}`,
      activeSegment: pathSegments[pathSegments.length - 1],
    };
  }, [location.pathname, currentRoute, navItems]);

  // Determine current value based on active segment
  const currentValue = React.useMemo(() => {
    // For top-level navigation, prioritize matching the active segment
    const isTopLevel = currentRoute.children?.some(
      (child) => child.navLevel === 'secondary'
    );

    if (isTopLevel) {
      // Use exact matching for top-level navigation
      return activeSegment || navItems[0]?.value;
    }

    // For other navigation levels, find matching nav item
    const matchingNavItem = navItems.find(
      (item) => item.value === activeSegment
    );
    return matchingNavItem?.value || navItems[0]?.value;
  }, [activeSegment, navItems, currentRoute]);

  const handleNavigation = React.useCallback(
    (newPath: string) => {
      if (!basePath) return;
      navigate(`${basePath}/${newPath}`);
    },
    [basePath, navigate]
  );

  return { navItems, currentValue, handleNavigation };
}
