import { atom } from 'jotai';
import { RegistrationState } from '../types/RegistrationState';

export const registrationFlowAtom = atom<RegistrationState>({
  currentStep: 0,
  completedSteps: [],
  reachedSteps: [0],
  isSummaryReached: false,
  isSubmitted: false,
});
