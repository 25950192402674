import { AnswerDTO } from '@btrway/api-courseware';
import { Box, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import React, { useEffect, useRef } from 'react';

interface QuestionAnswerChipProps {
  answer: AnswerDTO;
  onUpdate: (updatedAnswer: AnswerDTO) => void;
  onDelete: () => void;
  isNew?: boolean;
}

const QuestionAnswerChip: React.FC<QuestionAnswerChipProps> = ({
  answer,
  onUpdate,
  onDelete,
  isNew = false,
}) => {
  const contentEditableRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (isNew && contentEditableRef.current) {
      contentEditableRef.current.focus();
      const range = document.createRange();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [isNew]);

  const handleValueChange = () => {
    const newValue = contentEditableRef.current?.textContent || '';
    onUpdate({ ...answer, answerValue: newValue });
  };

  const handleToggleCorrect = (e: React.MouseEvent) => {
    e.stopPropagation();
    onUpdate({ ...answer, correct: !answer.correct });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      contentEditableRef.current?.blur();
    }
  };

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: answer.correct
          ? 'rgba(0, 128, 0, 0.1)'
          : 'rgba(255, 0, 0, 0.1)',
        borderRadius: '16px',
        padding: '3px',
        cursor: 'default',
        width: '100%',
      }}
    >
      <Box
        onClick={handleToggleCorrect}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '20px',
          height: '20px',
          minWidth: '20px',
          borderRadius: '50%',
          backgroundColor: answer.correct
            ? 'var(--mantine-color-green-9)'
            : 'var(--mantine-color-red-9)',
          color: 'white',
          cursor: 'pointer',
          marginRight: '6px',
        }}
      >
        {answer.correct ? <IconCheck size={16} /> : <IconX size={16} />}
      </Box>
      <Box
        style={{
          position: 'relative',
          flex: 1,
          marginRight: '6px',
        }}
      >
        <Text
          component="span"
          ref={contentEditableRef}
          contentEditable={true}
          onBlur={handleValueChange}
          onKeyDown={handleKeyDown}
          suppressContentEditableWarning={true}
          style={{
            padding: '2px 8px',
            borderRadius: '16px',
            cursor: 'text',
            fontSize: '14px',
            display: 'block',
            width: '100%',
            outline: 'none',
          }}
        >
          {answer.answerValue}
        </Text>
      </Box>
      <Box
        onClick={onDelete}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '24px',
          height: '24px',
          minWidth: '24px',
          cursor: 'pointer',
        }}
      >
        <IconX size={16} />
      </Box>
    </Box>
  );
};

export default QuestionAnswerChip;
