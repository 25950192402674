import { Box, Center, Text } from '@mantine/core';
import React from 'react';
import { useMostRecentCourse } from '../../hooks/useMostRecentCourse';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import CourseWidget from '../CourseWidget/CourseWidget';

export const MostRecentCourseWidget: React.FC = () => {
  const { course, progress } = useMostRecentCourse();

  if (!course || !progress) {
    return (
      <CardWrapper>
        <Center h="100%">
          <Text c="dimmed">No courses in progress</Text>
        </Center>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper p={0} style={{ overflow: 'hidden', position: 'relative' }}>
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          padding: 'var(--mantine-spacing-md)',
        }}
      >
        <Text fz="lg" fw={700} c="white">
          Continue Watching
        </Text>
      </Box>
      <CourseWidget
        course={course}
        completionPercent={progress.completionPercent}
      />
    </CardWrapper>
  );
};
