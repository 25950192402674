import { Box, Button, Flex, Group } from '@mantine/core';
import React from 'react';

import type { MessageRequest } from '@btrway/api-messaging';
import {
  MessageStatusEnum,
  useGetMessagesByWorkgroupId,
  useSaveMessage,
} from '@btrway/api-messaging';
import { MessageComposer } from '@btrway/message-composer';

import { MessagesGrid } from '@btrway/messaging-components';
import { StyledModal } from '@btrway/styled-modal';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

const WorkgroupCommunicationsView: React.FC = () => {
  const { workgroup } = useWorkgroup();
  const [opened, { open, close }] = useDisclosure(false);
  const { mutateAsync: saveMessage, isPending } = useSaveMessage();
  const { refetch } = useGetMessagesByWorkgroupId(workgroup.id);

  const handleMessageChange = (message: MessageRequest) => {};

  const handleSend = async (message: MessageRequest) => {
    try {
      await saveMessage({ data: message });
      await refetch(); // Refetch messages after successful save

      // notifications.show({
      //   title: 'Success',
      //   message: 'Message sent successfully',
      //   color: 'green',
      // });
      close();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to send message. Please try again.',
        color: 'red',
      });
    }
  };

  return (
    <Box p="md" h="100%">
      <Flex direction="column" style={{ height: '100%', position: 'relative' }}>
        <Group justify="space-between" mb="md">
          <h3>Communications View</h3>
          <Button color="blue" onClick={open}>
            New Communication
          </Button>
        </Group>

        <Box style={{ height: 'calc(100% - 60px)' }}>
          <MessagesGrid workgroup={workgroup} />
        </Box>
      </Flex>
      <StyledModal
        opened={opened}
        onClose={close}
        title="New Communication"
        variant="xl"
        closeOnClickOutside={false}
      >
        <Box p="md" style={{ height: '100%' }}>
          <MessageComposer
            workgroup={workgroup}
            initialMessage={{
              workgroupId: workgroup.id,
              status: MessageStatusEnum.draft,
            }}
            onChange={handleMessageChange}
            onCancel={close}
            onSend={handleSend}
            isLoading={isPending}
          />
        </Box>
      </StyledModal>
    </Box>
  );
};

export default WorkgroupCommunicationsView;
