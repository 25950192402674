import { WorkgroupResponse } from '@btrway/api-core';
import { Button, Card, Group, Text } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React from 'react';

interface WorkgroupDetailViewProps {
  workgroup: WorkgroupResponse;
  onClose: () => void;
}

const WorkgroupDetailView: React.FC<WorkgroupDetailViewProps> = ({
  workgroup,
  onClose,
}) => {
  return (
    <Card withBorder p="lg" h="100%" w="100%" pos="relative">
      <Group justify="apart" mb="md">
        <Text size="xl" fw={500}>
          {workgroup.name}
        </Text>
        <Button
          variant="subtle"
          color="gray"
          onClick={onClose}
          style={{ position: 'absolute', top: 10, right: 10 }}
        >
          <IconX size={18} />
        </Button>
      </Group>
      {/* Add more workgroup details here */}
      <Text>Workgroup Type: {workgroup.workgroupType.name}</Text>
      {workgroup.physicalAddress && (
        <Text>
          Address: {workgroup.physicalAddress.street1},{' '}
          {workgroup.physicalAddress.city},{' '}
          {workgroup.physicalAddress.stateProvinceCode}{' '}
          {workgroup.physicalAddress.postalCode}
        </Text>
      )}
    </Card>
  );
};

export default WorkgroupDetailView;
