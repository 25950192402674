import { HelpResourceUsageResponse } from '@btrway/api-help';
import { useAtom } from 'jotai';
import { useCallback, useMemo } from 'react';
import { helpResourceUsagesAtom } from '../atoms/helpResourceUsageAtom';

export const useHelpResourceUsagesStore = () => {
  const [helpResourceUsages, setHelpResourceUsages] = useAtom(
    helpResourceUsagesAtom
  );

  const updateHelpResourceUsages = useCallback(
    (newUsages: HelpResourceUsageResponse[]) => {
      setHelpResourceUsages((currentUsages) => {
        const usagesMap = new Map(
          currentUsages.map((usage) => [usage.id, usage])
        );

        // Update or add new usages
        newUsages.forEach((usage) => {
          usagesMap.set(usage.id, usage);
        });

        return Array.from(usagesMap.values());
      });
    },
    [setHelpResourceUsages]
  );

  const removeHelpResourceUsages = useCallback(
    (usageIds: number[]) => {
      setHelpResourceUsages((currentUsages) =>
        currentUsages.filter((usage) => !usageIds.includes(usage.id))
      );
    },
    [setHelpResourceUsages]
  );

  const getTaskHelpUsages = useCallback(
    (workflowKey: string, taskKey: string, taskType?: string) => {
      // Case 1: Direct task matches
      const directMatches = helpResourceUsages.filter(
        (usage) => usage.conditions?.taskKey === taskKey
      );

      // Case 2: Workflow-level help with no task specifics
      const workflowMatches = helpResourceUsages.filter(
        (usage) =>
          usage.conditions?.workflowKey === workflowKey &&
          !usage.conditions?.taskKey &&
          !usage.conditions?.taskType
      );

      // Case 3: Task type match without conflicting task key
      const taskTypeMatches = helpResourceUsages.filter(
        (usage) =>
          usage.conditions?.taskType === taskType && !usage.conditions?.taskKey
        //   (!usage.conditions?.taskKey || usage.conditions.taskKey !== taskKey)
      );

      return [...directMatches, ...workflowMatches, ...taskTypeMatches];
    },
    [helpResourceUsages]
  );

  return useMemo(
    () => ({
      helpResourceUsages,
      updateHelpResourceUsages,
      removeHelpResourceUsages,
      getTaskHelpUsages,
    }),
    [
      helpResourceUsages,
      updateHelpResourceUsages,
      removeHelpResourceUsages,
      getTaskHelpUsages,
    ]
  );
};
