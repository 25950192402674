import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { ImportedPerson } from '../types/ImportedPerson';
import { ValueMapping } from '../types/ValueMapping';
import { importConfigurationAtom } from './importConfigurationAtom';

export const fieldValueMappingsAtomFamily = atomFamily(
  (key: keyof ImportedPerson) =>
    atom(
      (get) => {
        const importConfig = get(importConfigurationAtom);
        const columnMapping = importConfig.columnMappings.find(
          (mapping) => mapping.targetField === key
        );
        return columnMapping
          ? { targetField: key, valueMappings: columnMapping.valueMappings }
          : undefined;
      },
      (
        get,
        set,
        update: Partial<{
          targetField: keyof ImportedPerson;
          valueMappings: ValueMapping[];
        }>
      ) => {
        set(importConfigurationAtom, (prev) => {
          const updatedColumnMappings = prev.columnMappings.map((mapping) =>
            mapping.targetField === key
              ? {
                  ...mapping,
                  valueMappings: update.valueMappings || mapping.valueMappings,
                }
              : mapping
          );
          return { ...prev, columnMappings: updatedColumnMappings };
        });
      }
    )
);
