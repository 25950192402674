import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { useProgressCalculation } from '@btrway/courseware-progress-manager';
import { Group, RingProgress, Text } from '@mantine/core';
import React from 'react';

interface CompletionRingProps {
  curriculum?: WorkflowDefinitionResponse;
  course?: AssetResponse;
  chapter?: ChapterDTO;
}

const CompletionRing: React.FC<CompletionRingProps> = ({
  curriculum,
  course,
  chapter,
}) => {
  const { completionPercent } = useProgressCalculation(
    curriculum,
    course,
    chapter
  );

  return (
    <Group gap={5}>
      <Text fz="xs" c="dimmed">
        {completionPercent.toFixed(0)}% completed
      </Text>
      <RingProgress
        size={18}
        thickness={2}
        sections={[
          {
            value: completionPercent,
            color: 'blue',
          },
        ]}
      />
    </Group>
  );
};

export default CompletionRing;
