import {
  getGetTaskTypesQueryKey,
  TaskTypeEnum,
  useGetTaskTypes,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export const useTaskTypes = () => {
  const {
    data: taskTypes,
    isLoading,
    error,
    refetch,
  } = useGetTaskTypes({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetTaskTypesQueryKey(),
    },
  });

  const getTaskType = useCallback(
    (taskTypeCode: TaskTypeEnum) => {
      return taskTypes?.find((type) => type.code === taskTypeCode);
    },
    [taskTypes]
  );

  return {
    taskTypes: taskTypes ?? [],
    isLoading,
    error,
    getTaskType,
    refetchTaskTypes: refetch,
  };
};
