import {
  getGetUserRolesByOrganizationIdQueryKey,
  useGetUserRolesByOrganizationId,
} from '@btrway/api-security';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback, useMemo } from 'react';

export const useUserRoles = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const organizationId = currentOrganization.id;

  const {
    data: userRoles,
    isLoading,
    error,
    refetch,
  } = useGetUserRolesByOrganizationId(organizationId, {
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetUserRolesByOrganizationIdQueryKey(organizationId),
    },
  });

  const getUserRoleById = useCallback(
    (roleId: number) => {
      return userRoles?.find((role) => role.id === roleId);
    },
    [userRoles]
  );

  const getUserRoleBySlug = useCallback(
    (roleSlug: string) => {
      return userRoles?.find((role) => role.slug === roleSlug);
    },
    [userRoles]
  );

  const sortedRoles = useMemo(() => {
    return [...(userRoles || [])].sort((a, b) => a.name.localeCompare(b.name));
  }, [userRoles]);

  return {
    userRoles: sortedRoles,
    isLoading,
    error,
    getUserRoleById,
    getUserRoleBySlug,
    refetchUserRoles: refetch,
  };
};
