import {
  StepConfig,
  StepTypeEnum,
  TaskInstanceResponse,
  WorkflowStepInstanceResponse,
} from '@btrway/api-workflow';
import { Box, Card, Flex, ScrollArea, Stack } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import WorkflowInstanceSidebar from '../WorkflowInstanceSidebar/WorkflowInstanceSidebar';
import WorkflowStepInstanceDetail from '../WorkflowStepInstanceDetail/WorkflowStepInstanceDetail';

interface WorkflowInstanceViewProps {
  taskInstance: TaskInstanceResponse;
}

const WorkflowInstanceView: React.FC<WorkflowInstanceViewProps> = ({
  taskInstance,
}) => {
  const [selectedStepInstance, setSelectedStepInstance] =
    useState<WorkflowStepInstanceResponse | null>(null);
  const [isManualScrolling, setIsManualScrolling] = useState(false);
  const detailRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const workflowInstance = taskInstance.workflowInstance;

  const workflowConfiguration =
    workflowInstance?.workflowDefinition?.workflowConfiguration;

  const getStepConfig = (
    stepKey: string | undefined
  ): StepConfig | undefined => {
    return workflowConfiguration?.steps?.find(
      (step) => step.stepKey === stepKey
    );
  };

  const handleStepClick = useCallback(
    (stepInstance: WorkflowStepInstanceResponse) => {
      setSelectedStepInstance(stepInstance);
      setIsManualScrolling(false);
      const element = detailRefs.current[stepInstance.id.toString()];
      if (element && scrollAreaRef.current) {
        const scrollTop = element.offsetTop - 16;
        scrollAreaRef.current.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    },
    []
  );

  const updateSelectedStepInstance = useCallback(() => {
    if (!scrollAreaRef.current || !workflowInstance?.steps) return;

    const scrollPosition = scrollAreaRef.current.scrollTop;
    const viewportHeight = scrollAreaRef.current.clientHeight;
    const scrollCenter = scrollPosition + viewportHeight / 2;

    let closestStepInstance = workflowInstance.steps[0];
    let closestDistance = Infinity;

    workflowInstance.steps.forEach((stepInstance) => {
      const element = detailRefs.current[stepInstance.id.toString()];
      if (element) {
        const elementCenter = element.offsetTop + element.clientHeight / 2;
        const distance = Math.abs(elementCenter - scrollCenter);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestStepInstance = stepInstance;
        }
      }
    });

    setSelectedStepInstance(closestStepInstance);
  }, [workflowInstance?.steps]);

  const handleScroll = useCallback(() => {
    setIsManualScrolling(true);
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      updateSelectedStepInstance();
      setIsManualScrolling(false);
    }, 100);
  }, [updateSelectedStepInstance]);

  useEffect(() => {
    const scrollArea = scrollAreaRef.current;
    if (scrollArea) {
      scrollArea.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollArea) {
        scrollArea.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, [handleScroll]);

  console.log(
    'workflowInstance.steps.keys',
    workflowInstance?.steps?.map((step) => step.stepKey)
  );
  console.log('taskInstance.stepKey', taskInstance.stepKey);

  const filteredSteps =
    workflowInstance?.steps?.filter((step) => {
      const workflowStep = getStepConfig(step.stepKey);
      return (
        step.stepKey !== taskInstance.stepKey &&
        workflowStep?.stepTypeCode !== StepTypeEnum.flowStart
      );
    }) || [];

  if (!workflowInstance) {
    return null; // Or return an error message
  }

  const hasOneStep = filteredSteps.length === 1;
  return (
    <Box
      style={{
        height: '100%',
        border: '1px solid var(--mantine-color-dark-4)',
      }}
    >
      <Flex style={{ height: '100%' }}>
        {!hasOneStep && (
          <WorkflowInstanceSidebar
            onStepClick={handleStepClick}
            selectedStepInstance={selectedStepInstance}
            workflowStepInstances={filteredSteps}
            workflowConfiguration={workflowConfiguration}
          />
        )}
        <ScrollArea
          style={{ flex: 1 }}
          viewportRef={scrollAreaRef}
          styles={{
            root: { backgroundColor: 'var(--mantine-color-dark-4)' },
            viewport: { padding: 'var(--mantine-spacing-md)', paddingTop: 0 },
            corner: { backgroundColor: 'var(--mantine-color-dark-4)' },
          }}
        >
          <Box pt="md">
            <Stack gap="md">
              {filteredSteps.map((stepInstance) => (
                <Card
                  key={stepInstance.id}
                  shadow="sm"
                  padding="lg"
                  radius="md"
                  withBorder
                  ref={(el) => {
                    detailRefs.current[stepInstance.id.toString()] = el;
                  }}
                >
                  <WorkflowStepInstanceDetail
                    workflowStepInstance={stepInstance}
                    workflowStep={getStepConfig(stepInstance.stepKey)}
                    isOnlyStep={hasOneStep}
                  />
                </Card>
              ))}
            </Stack>
          </Box>
        </ScrollArea>
      </Flex>
    </Box>
  );
};

export default WorkflowInstanceView;
