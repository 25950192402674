import { useGetOrganizations } from '@btrway/api-core';
import {
  StepConfig,
  StepTypeEnum,
  TransitionConfig,
  useDeleteWorkflowTemplate,
  useGetWorkflowTemplates,
  useSaveWorkflowTemplate,
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { workflowTemplatesAtom } from '../atoms/workflowTemplateAtoms';

export const useWorkflowTemplates = () => {
  const [workflowTemplates, setWorkflowTemplates] = useAtom(
    workflowTemplatesAtom
  );
  const {
    data: fetchedTemplates,
    refetch,
    isLoading,
    error,
  } = useGetWorkflowTemplates();
  const { mutateAsync: saveTemplate } = useSaveWorkflowTemplate();
  const { mutateAsync: deleteTemplateMutation } = useDeleteWorkflowTemplate();

  const { data: organizations } = useGetOrganizations();

  const hasFetchedRef = useRef(false);

  const fetchWorkflowTemplates = useCallback(async () => {
    try {
      const result = await refetch();
      if (result.data) {
        setWorkflowTemplates(result.data);
        hasFetchedRef.current = true;
      }
    } catch (err) {
      console.error('Failed to fetch workflow templates:', err);
    }
  }, [refetch, setWorkflowTemplates]);

  useEffect(() => {
    if (!hasFetchedRef.current && !isLoading) {
      fetchWorkflowTemplates();
    }
  }, [isLoading, fetchWorkflowTemplates]);

  const addWorkflowTemplate = useCallback(
    async (
      partialTemplate: Partial<WorkflowTemplateRequest>
    ): Promise<WorkflowTemplateResponse | null> => {
      try {
        const newTemplate: WorkflowTemplateRequest = {
          name: partialTemplate.name || 'New Template',
          description: partialTemplate.description || '',
          templateKey: partialTemplate.templateKey || uuid(),
          workflowType: partialTemplate.workflowType!,
          published: partialTemplate.published || false,
          workflowConfiguration: partialTemplate.workflowConfiguration || {
            steps: [],
            transitions: [],
          },
          ...partialTemplate,
        };

        const savedTemplate = (await saveTemplate({
          data: newTemplate,
        })) as WorkflowTemplateResponse;

        if (savedTemplate) {
          setWorkflowTemplates((prev) => [...prev, savedTemplate]);

          // const nonInternalOrganizationIds =
          //   organizations
          //     ?.filter((org) => !org.internal)
          //     .map((org) => org.id) || [];

          // if (nonInternalOrganizationIds.length > 0) {
          //   try {
          //     await createWorkflowDefinitions({
          //       workflowTemplateId: savedTemplate.id,
          //       data: nonInternalOrganizationIds,
          //     });
          //   } catch (defError) {
          //     console.error('Error creating workflow definitions:', defError);
          //   }
          // }
        }

        return savedTemplate;
      } catch (err) {
        console.error('Failed to add workflow template:', err);
        return null;
      }
    },
    [saveTemplate, setWorkflowTemplates, organizations]
  );

  const addFormWorkflowTemplate = useCallback(
    async (partialTemplate: Partial<WorkflowTemplateRequest>) => {
      const startStep: StepConfig = {
        stepKey: uuid(),
        title: 'Start',
        stepTypeCode: StepTypeEnum.flowStart,
      };

      const formStep: StepConfig = {
        stepKey: uuid(),
        title: 'Form Submission',
        stepTypeCode: StepTypeEnum.formSubmission,
      };

      const transition: TransitionConfig = {
        transitionKey: uuid(),
        sourceStepKey: startStep.stepKey,
        targetStepKey: formStep.stepKey,
        index: 0,
      };

      const updatedPartialTemplate: Partial<WorkflowTemplateRequest> = {
        ...partialTemplate,
        workflowConfiguration: {
          ...partialTemplate.workflowConfiguration,
          steps: [
            ...(partialTemplate.workflowConfiguration?.steps || []),
            startStep,
            formStep,
          ],
          transitions: [
            ...(partialTemplate.workflowConfiguration?.transitions || []),
            transition,
          ],
        },
      };

      return addWorkflowTemplate(updatedPartialTemplate);
    },
    [addWorkflowTemplate]
  );

  const deleteWorkflowTemplate = useCallback(
    async (templateId: number) => {
      try {
        await deleteTemplateMutation({ workflowTemplateId: templateId });
        setWorkflowTemplates((prev) =>
          prev.filter((template) => template.id !== templateId)
        );
      } catch (err) {
        console.error('Failed to delete workflow template:', err);
      }
    },
    [deleteTemplateMutation, setWorkflowTemplates]
  );

  return {
    workflowTemplates,
    isLoading,
    error,
    fetchWorkflowTemplates,
    addWorkflowTemplate,
    addFormWorkflowTemplate,
    deleteWorkflowTemplate,
  };
};
