import {
  EventRequest,
  FacilityRequest,
  getGetFacilityByIdQueryKey,
  useDeleteFacility,
  useGetFacilityById,
  useSaveFacility,
} from '@btrway/api-calendar';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useScrollableDrawer } from '@btrway/scrollable-drawer';
import {
  Box,
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Stack,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { convertFacilityResponseToRequest } from '../../utils/facilityConversion';
import { FacilityAvailabilityList } from '../FacilityAvailabilityList/FacilityAvailabilityList';

interface FacilityEditProps {
  facility: FacilityRequest;
  isNew?: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export const FacilityEdit = ({
  facility,
  isNew = false,
  onSave,
  onCancel,
}: FacilityEditProps) => {
  const { data: facilityResponse, isLoading: isLoadingFacility } =
    useGetFacilityById(facility.id || 0, {
      query: {
        enabled: !isNew && !!facility.id,
        queryKey: getGetFacilityByIdQueryKey(facility.id || 0),
      },
    });

  const availabilityEventsRef = useRef<EventRequest[]>([]);

  const [formData, setFormData] = useState<FacilityRequest>({
    ...facility,
    attributes: facility.attributes || {},
    availabilityEvents: facility.availabilityEvents || [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const { currentOrganization } = useAuthenticatedUser();
  const { mutateAsync: saveFacility } = useSaveFacility();
  const { mutateAsync: deleteFacility } = useDeleteFacility();
  const { setDrawerFooter } = useScrollableDrawer();

  useEffect(() => {
    if (facilityResponse && !isNew) {
      const convertedFacility =
        convertFacilityResponseToRequest(facilityResponse);
      setFormData(convertedFacility);
      availabilityEventsRef.current =
        convertedFacility.availabilityEvents || [];
    }
  }, [facilityResponse, isNew]);

  const handleFormChange = useCallback((field: string, value: any) => {
    setFormData((prev) => {
      if (field === 'name') {
        return { ...prev, name: value };
      }
      return {
        ...prev,
        attributes: {
          ...prev.attributes,
          [field]: value,
        },
      };
    });
  }, []);

  const handleAddAvailability = useCallback((event: EventRequest) => {
    console.log('handleAddAvailability');
    const currentEvents = availabilityEventsRef.current;
    let updatedEvents: EventRequest[];

    if (event.id) {
      updatedEvents = currentEvents.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...event, id: existingEvent.id }
          : existingEvent
      );
    } else {
      updatedEvents = [...currentEvents, event];
    }

    availabilityEventsRef.current = updatedEvents;

    setFormData((prev) => ({
      ...prev,
      availabilityEvents: updatedEvents,
    }));
  }, []);

  const handleDeleteAvailability = useCallback((deletedEvent: EventRequest) => {
    const updatedEvents = availabilityEventsRef.current.filter(
      (event) => event.id !== deletedEvent.id
    );
    availabilityEventsRef.current = updatedEvents;

    setFormData((prev) => ({
      ...prev,
      availabilityEvents: updatedEvents,
    }));
  }, []);

  const handleSaveFacility = async () => {
    if (!formData.name.trim()) return;

    setIsLoading(true);
    try {
      const facilityToSave: FacilityRequest = {
        ...formData,
        organizationId: currentOrganization.id,
        availabilityEvents: availabilityEventsRef.current,
        attributes: formData.attributes || {},
      };

      await saveFacility({ data: facilityToSave });
      onSave();
    } catch (error) {
      // Error handling could be added here if needed
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFacility = async () => {
    if (!facility.id) return;

    setIsLoading(true);
    try {
      await deleteFacility({ facilityId: facility.id });
      onSave();
    } catch (error) {
      // Error handling could be added here if needed
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setDrawerFooter(
      <Group justify="space-between" gap="md">
        {!isNew && (
          <Button
            variant="light"
            color="red"
            onClick={handleDeleteFacility}
            loading={isLoading}
          >
            Delete
          </Button>
        )}
        <Group ml="auto">
          <Button variant="light" onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleSaveFacility}
            loading={isLoading}
            disabled={!formData.name.trim()}
          >
            Save Changes
          </Button>
        </Group>
      </Group>
    );

    return () => setDrawerFooter(null);
  }, [isNew, isLoading, formData.name]);

  return (
    <Stack gap="md">
      <LoadingOverlay visible={isLoading || isLoadingFacility} />
      <TextInput
        label="Facility Name"
        value={formData.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
        placeholder="Enter facility name"
        required
      />
      <Textarea
        label="Description"
        value={formData.attributes?.description || ''}
        onChange={(e) => handleFormChange('description', e.target.value)}
        placeholder="Enter facility description"
      />
      <NumberInput
        label="Capacity"
        value={formData.attributes?.capacity || 0}
        onChange={(value) => handleFormChange('capacity', Number(value))}
        placeholder="Enter facility capacity"
        min={0}
      />

      {!isNew && formData.id && (
        <Box mt="xl">
          <FacilityAvailabilityList
            facilityId={formData.id}
            workgroupId={formData.workgroupId}
            userTimezone={currentOrganization.timeZoneName}
            availabilityEvents={formData.availabilityEvents || []}
            onAdd={handleAddAvailability}
            onEdit={handleAddAvailability}
            onDelete={handleDeleteAvailability}
          />
        </Box>
      )}
    </Stack>
  );
};
