import { useMantineTheme } from '@mantine/core';
import { useAtom } from 'jotai';
import { colorSchemeAtom, forcedDarkModeAtom } from '../atoms/themeAtoms';
import { useAuthenticatedUser } from '@btrway/current-user';

export const useThemeScheme = () => {
  const theme = useMantineTheme();
  const [userColorScheme, setUserColorScheme] = useAtom(colorSchemeAtom);
  const [forcedDarkMode, setForcedDarkMode] = useAtom(forcedDarkModeAtom);
  const { currentUser } = useAuthenticatedUser();
  const isInternal = currentUser?.user?.internal || false;

  const getColorValue = <T extends keyof typeof theme.other>(
    key: T
  ): string => {
    const value = theme.other[key];
    if (typeof value === 'object' && value !== null) {
      const mode = isInternal ? 'internal' : 'client';
      const effectiveScheme = forcedDarkMode ? 'dark' : userColorScheme;
      return value[mode][effectiveScheme];
    }
    return typeof value === 'string' ? value : '';
  };

  return {
    colorScheme: forcedDarkMode ? 'dark' : userColorScheme,
    setColorScheme: setUserColorScheme,
    isInternal,
    getColorValue,
    forcedDarkMode,
    setForcedDarkMode,
  };
};
