import { TriggerConfig } from '@btrway/api-workflow';
import { useUserRoles } from '@btrway/security-manager';
import { Box, Card, Group, Text } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import React from 'react';
import styles from './TriggerCard.module.css';

interface TriggerCardProps {
  trigger: TriggerConfig;
  onDelete: (triggerKey: string) => void;
  onEdit: (trigger: TriggerConfig) => void;
  isFirstTrigger: boolean;
}

const TriggerCard: React.FC<TriggerCardProps> = ({
  trigger,
  onDelete,
  onEdit,
  isFirstTrigger,
}) => {
  const { getUserRoleById } = useUserRoles();

  const handleClick = () => {
    onEdit(trigger);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(trigger.triggerKey);
  };

  const getRuleValuesDisplay = () => {
    if (trigger.property?.dataType === 'userRole') {
      return trigger.ruleValues
        ?.map((item) => getUserRoleById(Number(item.value))?.name)
        .filter(Boolean)
        .join(', ');
    }
    return trigger.ruleValues?.map((item) => item.value).join(', ');
  };

  const labelPrefix = isFirstTrigger ? '' : 'AND ';

  return (
    <Card
      padding={4}
      radius="xs"
      onClick={handleClick}
      className={styles.card}
      shadow="none"
    >
      <Group justify="space-between" wrap="nowrap" align="flex-start">
        <Text fz="md" c="gray.7" style={{ flex: 1 }}>
          <span style={{ fontWeight: 700 }}>
            {labelPrefix}
            {trigger.property?.label}
          </span>{' '}
          {trigger.ruleOperator?.toLowerCase()} "{getRuleValuesDisplay()}"
        </Text>
        <Box className={styles.iconWrapper}>
          <IconTrash
            size={16}
            color="red"
            className={styles.trashIcon}
            style={{ cursor: 'pointer' }}
            onClick={handleDelete}
          />
        </Box>
      </Group>
    </Card>
  );
};

export default TriggerCard;
