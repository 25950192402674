import { useAuth } from '@btrway/auth-core';
import { Box, Button, PasswordInput, Space, Text } from '@mantine/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

export function PasswordResetForm() {
  const { setPassword } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [message, setMessage] = useState<string | null>(null);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { password: '', confirmPassword: '' },
  });

  const password = watch('password');

  const onSubmit = async (data: {
    password: string;
    confirmPassword: string;
  }) => {
    if (data.password !== data.confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }
    if (!token) {
      return;
    }
    const pass = data.password;

    const setPasswordBody = {
      token,
      password: pass,
    };
    try {
      const response = await setPassword(setPasswordBody);
      if (response.success) {
        setMessage(
          'Password has been reset successfully. Redirecting to login...'
        );
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setMessage(
          'This link is expired please send another password reset request to get a new one'
        );
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      style={{
        maxWidth: 740,
        backgroundColor: 'white',
        padding: '40px',
        margin: 'auto',
      }}
    >
      <Text size="xl" fw={700} ta="center">
        Reset Password
      </Text>
      <Space h="xl" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          control={control}
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <PasswordInput
              label="New Password"
              placeholder="Enter new password"
              {...field}
              error={errors.password?.message}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            required: 'Please confirm your password',
            validate: (value) => value === password || 'Passwords do not match',
          }}
          render={({ field }) => (
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm new password"
              {...field}
              error={errors.confirmPassword?.message}
              mt="md"
            />
          )}
        />
        {message && (
          <Text color={message.includes('error') ? 'red' : 'green'}>
            {message}
          </Text>
        )}
        <Button type="submit" mt="lg" fullWidth color="#2F667F">
          Reset Password
        </Button>
      </form>
    </Box>
  );
}
