import { OrganizationResponse, WorkgroupResponse } from '@btrway/api-core';
import React, { useMemo } from 'react';

import { useWorkgroups } from '@btrway/workgroup-manager';
import MantineTreeSelect from '../MantineTreeSelect/MantineTreeSelect';

interface WorkgroupSelectorProps {
  organization: OrganizationResponse;
  workgroup?: WorkgroupResponse;
  suggestedWorkgroup?: WorkgroupResponse;
  onSelect: (workgroup: WorkgroupResponse) => void;
}

const WorkgroupSelector: React.FC<WorkgroupSelectorProps> = ({
  organization,
  workgroup,
  suggestedWorkgroup,
  onSelect,
}) => {
  const { hierarchicalWorkgroups } = useWorkgroups(organization.id);

  const transformWorkgroupsToTreeData = (
    workgroups: WorkgroupResponse[]
  ): any[] => {
    return workgroups.map((wg) => ({
      value: wg.id.toString(),
      label: wg.name,
      children: wg.children
        ? transformWorkgroupsToTreeData(wg.children)
        : undefined,
    }));
  };

  const treeData = useMemo(
    () => transformWorkgroupsToTreeData(hierarchicalWorkgroups),
    [hierarchicalWorkgroups]
  );

  const handleSelect = ({ value }: { value: string }) => {
    const selectedWorkgroup = findWorkgroupById(
      hierarchicalWorkgroups,
      parseInt(value, 10)
    );
    if (selectedWorkgroup) {
      onSelect(selectedWorkgroup);
    }
  };

  const findWorkgroupById = (
    workgroups: WorkgroupResponse[],
    id: number
  ): WorkgroupResponse | undefined => {
    for (const wg of workgroups) {
      if (wg.id === id) {
        return wg;
      }
      if (wg.children) {
        const found = findWorkgroupById(wg.children, id);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  };

  // Use suggestedWorkgroup as the initial value if available and not overridden by a selection
  const initialValue =
    workgroup?.id.toString() || suggestedWorkgroup?.id.toString();

  return (
    <MantineTreeSelect
      value={initialValue}
      options={treeData}
      onChange={handleSelect}
      filter={true}
      placeholder="Select a Workgroup"
    />
  );
};

export default WorkgroupSelector;
