import { EventResponse, SystemEventTypeEnum } from '@btrway/api-calendar';
import { EventInput } from '@fullcalendar/core';

export const convertToFullCalendarEvents = (
  apiEvents: EventResponse[]
): EventInput[] => {
  return apiEvents.map((event) => {
    const isAvailabilityEvent =
      event.eventType?.systemType === SystemEventTypeEnum.availability;

    return {
      id: event.id.toString(),
      title: isAvailabilityEvent ? '' : event.name,
      start: new Date(event.startTime).toISOString(),
      end: new Date(event.endTime).toISOString(),
      allDay: event.allDayEvent,
      display: isAvailabilityEvent ? 'background' : 'auto',
      extendedProps: event, // Include the full event object
      backgroundColor: isAvailabilityEvent
        ? 'var(--mantine-color-green-2)' // Mantine green.2
        : event.eventType?.primaryColor || '#228BE6',
      borderColor: isAvailabilityEvent
        ? 'var(--mantine-color-green-3)' // Slightly darker green for border
        : event.eventType?.primaryColor || '#228BE6',
      textColor: isAvailabilityEvent ? 'transparent' : 'white',
      editable: !isAvailabilityEvent,
      interactive: !isAvailabilityEvent,
    };
  });
};
