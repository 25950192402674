import {
  DefaultMantineColor,
  MantineColorScheme,
  MantineColorsTuple,
  MantineThemeOverride,
} from '@mantine/core';

// Define custom types
export type ThemeMode = 'client' | 'internal';
export type CustomColors =
  | 'client-light'
  | 'client-dark'
  | 'internal-light'
  | 'internal-dark';

// Define color palettes
const colorPalettes: Record<CustomColors, MantineColorsTuple> = {
  'internal-light': [
    '#FFEBE9',
    '#FFCCC7',
    '#FFA39E',
    '#FF7875',
    '#FF4D4F',
    '#F5222D',
    '#CF1322',
    '#A8071A',
    '#820014',
    '#5C0011',
  ] as MantineColorsTuple,
  'internal-dark': [
    '#2A1215',
    '#431418',
    '#58181C',
    '#791A1F',
    '#A61D24',
    '#D32029',
    '#E84749',
    '#F37370',
    '#F89F9A',
    '#FCC8C5',
  ] as MantineColorsTuple,
  'client-light': [
    '#E6F7FF',
    '#BAE7FF',
    '#91D5FF',
    '#69C0FF',
    '#40A9FF',
    '#1890FF',
    '#096DD9',
    '#0050B3',
    '#003A8C',
    '#002766',
  ] as MantineColorsTuple,
  'client-dark': [
    '#111D2C',
    '#112A45',
    '#15395B',
    '#164C7E',
    '#1D5CA3',
    '#177DDC',
    '#3C9AE8',
    '#65B7F3',
    '#8DCFF8',
    '#B7E3FA',
  ] as MantineColorsTuple,
};

// Function to select the appropriate color based on mode and color scheme
export const selectPrimaryColor = (
  mode: ThemeMode,
  colorScheme: MantineColorScheme
): CustomColors => {
  if (mode === 'client') {
    return colorScheme === 'dark' ? 'client-dark' : 'client-light';
  } else {
    return colorScheme === 'dark' ? 'internal-dark' : 'internal-light';
  }
};

// Function to get color variable
export const getColorVariable = (
  color: CustomColors,
  shade: number
): string => {
  return `var(--mantine-color-${color}-${shade})`;
};

// Create the app theme
export const createAppTheme = (
  mode: ThemeMode,
  colorScheme: MantineColorScheme = 'light'
): MantineThemeOverride => {
  const primaryColor = selectPrimaryColor(mode, colorScheme);

  return {
    colors: colorPalettes as Record<DefaultMantineColor, MantineColorsTuple>,
    primaryColor,
    primaryShade: { light: 6, dark: 8 },
    other: {
      primaryColor: {
        client: {
          light: getColorVariable('client-light', 6),
          dark: getColorVariable('client-dark', 8),
        },
        internal: {
          light: getColorVariable('internal-light', 6),
          dark: getColorVariable('internal-dark', 8),
        },
      },
      appShellBackground: {
        client: {
          light: '#fffffe',
          dark: '#121212',
        },
        internal: {
          light: '#fffffe',
          dark: '#121212',
        },
      },
      mainContentBackground: {
        client: {
          light: '#f8f9fa',
          dark: '#1e1e1e',
        },
        internal: {
          light: '#f8f9fa',
          dark: '#1e1e1e',
          // light: '#a71021',
          // dark: '#320509',
        },
      },
    },
  };
};
