import {
  CertificationInstanceRequest,
  DataTypeEnum,
  TaskInstanceRequest,
  TaskListTaskProperties,
  TaskTypeEnum,
  useGetWorkflowDefinitionsByOrganizationAndType,
  useSaveCertificationInstance,
  useSaveTaskInstance,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Button, Card, Flex, Group, Select, Stack, Text } from '@mantine/core';
import {
  IconAward,
  IconBook,
  IconBulb,
  IconCalendarEvent,
  IconClipboardList,
  IconEdit,
  IconFileExport,
  IconMail,
  IconTrash,
  IconUsers,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import AddToGroupModal from '../AddtoGroupModal/AddToGroupModal';
import { SelectedPeopleState } from '../WorkgroupPeopleGrid/WorkgroupPeopleGrid';

interface ActionItemProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  expanded?: boolean;
  children?: React.ReactNode;
}

const ActionItem: React.FC<ActionItemProps> = ({
  title,
  description,
  icon,
  onClick,
  expanded,
  children,
}) => (
  <Card
    withBorder
    shadow="none"
    p="md"
    style={{ cursor: 'pointer' }}
    onClick={onClick}
  >
    <Flex direction="column" gap="sm">
      <Flex align="flex-start">
        <div style={{ marginRight: '1rem' }}>{icon}</div>
        <div>
          <Text fw={700} size="md">
            {title}
          </Text>
          <Text c="dimmed" size="xs">
            {description}
          </Text>
        </div>
      </Flex>
      {expanded && <div onClick={(e) => e.stopPropagation()}>{children}</div>}
    </Flex>
  </Card>
);

interface ActionGroupProps {
  title: string;
  children: React.ReactNode;
}

const ActionGroup: React.FC<ActionGroupProps> = ({ title, children }) => (
  <Stack>
    <Text fw={700} size="lg" c="dimmed">
      {title}
    </Text>
    {children}
  </Stack>
);

interface WorkgroupPeopleActionListProps {
  selectionState: SelectedPeopleState;
}

const WorkgroupPeopleActionList: React.FC<WorkgroupPeopleActionListProps> = ({
  selectionState,
}) => {
  const [addToGroupOpened, setAddToGroupOpened] = useState(false);
  const [messageComposerOpened, setMessageComposerOpened] = useState(false);
  const [certificationExpanded, setCertificationExpanded] = useState(false);
  const [taskListExpanded, setTaskListExpanded] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState<
    string | null
  >(null);
  const [selectedTaskList, setSelectedTaskList] = useState<string | null>(null);

  const { currentOrganization, currentPerson } = useAuthenticatedUser();
  const { data: certifications, isLoading } =
    useGetWorkflowDefinitionsByOrganizationAndType(
      currentOrganization.id,
      WorkflowTypeEnum.certification
    );
  const { data: taskLists, isLoading: taskListsLoading } =
    useGetWorkflowDefinitionsByOrganizationAndType(
      currentOrganization.id,
      WorkflowTypeEnum.taskList
    );

  const { mutate: saveCertificationInstance } = useSaveCertificationInstance();
  const { mutate: saveTaskInstance } = useSaveTaskInstance();

  const handleAddToGroup = () => {
    setAddToGroupOpened(true);
  };

  const handleSendEmail = () => {
    setMessageComposerOpened(true);
  };

  const handleCertificationClick = (e: React.MouseEvent) => {
    // Only toggle if clicking the card itself, not the select or button
    if (
      e.target === e.currentTarget ||
      e.currentTarget.contains(e.target as Node)
    ) {
      setCertificationExpanded(!certificationExpanded);
      if (!certificationExpanded) {
        setSelectedCertification(null);
      }
    }
  };

  const handleTaskListClick = (e: React.MouseEvent) => {
    if (
      e.target === e.currentTarget ||
      e.currentTarget.contains(e.target as Node)
    ) {
      setTaskListExpanded(!taskListExpanded);
      if (!taskListExpanded) {
        setSelectedTaskList(null);
      }
    }
  };

  const handleAssignTaskList = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!selectedTaskList || !currentPerson || !currentOrganization) return;

    const selectedWorkflow = taskLists?.find(
      (t) => t.id.toString() === selectedTaskList
    );
    if (!selectedWorkflow) return;

    const taskConfigProperties: TaskListTaskProperties = {
      workflowSelection: {
        workflowDefinitionKey: { value: selectedWorkflow.workflowKey },
      },
    };

    const selectedPeople = Array.from(selectionState.selectedPeople.values());

    selectedPeople.forEach((person) => {
      const taskInstanceRequest: TaskInstanceRequest = {
        assignedAt: new Date().toISOString(),
        completed: false,
        organizationId: currentOrganization.id,
        workflowDefinitionId: selectedWorkflow.id,
        requestedBy: currentPerson.id,
        assignedEntityType: DataTypeEnum.person,
        assignedEntityId: person.personId,
        viewed: false,
        workgroupId: person.workgroupId,
        taskType: TaskTypeEnum.completeTaskList,
        taskProperties: taskConfigProperties,
      };

      saveTaskInstance(
        { data: taskInstanceRequest },
        {
          onSuccess: () => {
            setTaskListExpanded(false);
            setSelectedTaskList(null);
          },
          onError: (error) => {
            console.error('Error saving task instance:', error);
          },
        }
      );
    });
  };

  const handleAssignCertification = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!selectedCertification || !currentPerson || !currentOrganization)
      return;

    const selectedWorkflow = certifications?.find(
      (c) => c.id.toString() === selectedCertification
    );
    if (!selectedWorkflow) return;

    const taskConfigProperties = {
      workflowDefinitionKey: { value: selectedWorkflow.workflowKey },
    };

    const selectedPeople = Array.from(selectionState.selectedPeople.values());

    selectedPeople.forEach((person) => {
      const certificationInstanceRequest: CertificationInstanceRequest = {
        completed: false,
        organizationId: currentOrganization.id,
        workgroupId: person.workgroupId,
        workflowKey: selectedWorkflow.workflowKey,
        assignedEntityType: DataTypeEnum.person,
        assignedEntityId: person.personId,
      };

      saveCertificationInstance(
        { data: certificationInstanceRequest },
        {
          onSuccess: () => {
            setCertificationExpanded(false);
            setSelectedCertification(null);
          },
          onError: (error) => {
            console.error('Error saving task instance:', error);
          },
        }
      );
    });
  };

  const certificationOptions =
    certifications?.map((c) => ({
      value: c.id.toString(),
      label: c.name || 'Unnamed Certification',
    })) || [];

  const taskListOptions =
    taskLists?.map((t) => ({
      value: t.id.toString(),
      label: t.name || 'Unnamed Task List',
    })) || [];

  return (
    <>
      <Stack w="100%">
        <ActionGroup title="Organize">
          <ActionItem
            title="Add to List"
            description="Assign selected people to a specific list for better organization and management."
            icon={<IconUsers size={24} />}
            onClick={handleAddToGroup}
          />
          <ActionItem
            title="Schedule Event"
            description="Create and schedule an event for the selected individuals."
            icon={<IconCalendarEvent size={24} />}
          />
        </ActionGroup>

        <ActionGroup title="Communicate">
          <ActionItem
            title="Send Email"
            description="Compose and send an email to all selected people."
            icon={<IconMail size={24} />}
            onClick={handleSendEmail}
          />
          <ActionItem
            title="Send Message Blast"
            description="Send a quick message or announcement to all selected individuals."
            icon={<IconBulb size={24} />}
          />
        </ActionGroup>

        <ActionGroup title="Assign">
          <ActionItem
            title="Form"
            description="Assign a form for selected people to fill out."
            icon={<IconClipboardList size={24} />}
          />
          <ActionItem
            title="Task List"
            description="Create and assign a list of tasks to selected individuals."
            icon={<IconClipboardList size={24} />}
            onClick={handleTaskListClick}
            expanded={taskListExpanded}
          >
            <Stack gap="xs" mt="xs">
              <Select
                data={taskListOptions}
                value={selectedTaskList}
                onChange={(value) => setSelectedTaskList(value)}
                placeholder="Select a task list"
                // disabled={taskListsLoading}
              />
              <Group justify="flex-end">
                <Button
                  size="sm"
                  onClick={handleAssignTaskList}
                  disabled={!selectedTaskList}
                >
                  Assign Task List
                </Button>
              </Group>
            </Stack>
          </ActionItem>
          <ActionItem
            title="Curriculum"
            description="Assign a specific curriculum or learning path to selected people."
            icon={<IconBook size={24} />}
          />
          <ActionItem
            title="Certification"
            description="Assign a certification to be completed."
            icon={<IconAward size={24} />}
            onClick={handleCertificationClick}
            expanded={certificationExpanded}
          >
            <Stack gap="xs" mt="xs">
              <Select
                data={certificationOptions}
                value={selectedCertification}
                onChange={(value) => {
                  setSelectedCertification(value);
                }}
                placeholder="Select a certification"
                disabled={isLoading}
              />
              <Group justify="flex-end">
                <Button
                  size="sm"
                  onClick={handleAssignCertification}
                  disabled={!selectedCertification}
                >
                  Assign Certification
                </Button>
              </Group>
            </Stack>
          </ActionItem>
        </ActionGroup>

        <ActionGroup title="Manage">
          <ActionItem
            title="Export"
            description="Export data for selected individuals in various formats."
            icon={<IconFileExport size={24} />}
          />
          <ActionItem
            title="Bulk Update"
            description="Make changes to multiple records at once for selected people."
            icon={<IconEdit size={24} />}
          />
          <ActionItem
            title="Delete"
            description="Remove selected individuals from the current workgroup."
            icon={<IconTrash size={24} />}
          />
        </ActionGroup>
      </Stack>
      <AddToGroupModal
        opened={addToGroupOpened}
        onClose={() => setAddToGroupOpened(false)}
        selectionState={selectionState}
      />
    </>
  );
};

export default WorkgroupPeopleActionList;
