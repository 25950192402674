import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { useProgressCalculation } from '@btrway/courseware-progress-manager';
import { Progress, Stack, Text } from '@mantine/core';
import React from 'react';

interface CompletionProgressBarProps {
  curriculum?: WorkflowDefinitionResponse;
  course?: AssetResponse;
  chapter?: ChapterDTO;
  showZeroPercent?: boolean;
}

const CompletionProgressBar: React.FC<CompletionProgressBarProps> = ({
  curriculum,
  course,
  chapter,
  showZeroPercent = false,
}) => {
  console.log('CompletionProgressBar, course', course);
  const { completionPercent } = useProgressCalculation(
    curriculum,
    course,
    chapter
  );

  if (completionPercent === 0 && !showZeroPercent) {
    return null;
  }

  return (
    <Stack gap={5}>
      <Text fz="xs" c="dimmed">
        {completionPercent.toFixed(0)}% completed
      </Text>
      <Progress.Root w={100}>
        <Progress.Section value={completionPercent} color="blue" />
      </Progress.Root>
    </Stack>
  );
};

export default CompletionProgressBar;
