import {
  EventConfig,
  StepConfig,
  StepTypeEnum,
  WorkflowConfig,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { DeploymentFilterView } from '@btrway/workflow-template-deployment';
import { Center, ScrollArea, Space, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { EventsPanel } from '../EventsPanel/EventsPanel';
import FileUploadCard from '../FileUploadCard/FileUploadCard';
import FormCard from '../FormCard/FormCard';
import StepsPanel from '../StepsPanel/StepsPanel';

interface WorkflowConfigurationViewProps {
  workflow: CommonWorkflow;
  updateWorkflow: (workflow: Partial<CommonWorkflow>) => void;
  workflowConfiguration: WorkflowConfig;
  workflowKey: string;
}

export const WorkflowConfigurationView: React.FC<
  WorkflowConfigurationViewProps
> = ({ workflow, updateWorkflow, workflowConfiguration, workflowKey }) => {
  const { steps, events } = workflowConfiguration;
  const [localEvents, setLocalEvents] = useState<EventConfig[]>(
    events as EventConfig[]
  );
  const [localSteps, setLocalSteps] = useState<StepConfig[]>(
    steps as StepConfig[]
  );

  const stepConfigs = steps as StepConfig[];
  const startStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.flowStart
  );
  const formStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.formSubmission
  );
  const fileUploadStep = stepConfigs.find(
    (step) => step.stepTypeCode === StepTypeEnum.fileUpload
  );
  const formStepConfigs = stepConfigs.filter(
    (step) =>
      step.stepTypeCode !== StepTypeEnum.flowStart &&
      step.stepTypeCode !== StepTypeEnum.formSubmission &&
      step.stepTypeCode !== StepTypeEnum.fileUpload
  );

  const isFormWorkflow = workflow?.workflowType === WorkflowTypeEnum.form;
  const isFileWorkflow = workflow?.workflowType === WorkflowTypeEnum.file;

  const handleEventDelete = (eventKey: string) => {
    setLocalEvents((prev) =>
      prev.filter((event) => event.eventKey !== eventKey)
    );
  };

  const handleStepDelete = (stepKey: string) => {
    setLocalSteps((prev) => prev.filter((step) => step.stepKey !== stepKey));
  };

  return (
    <ScrollArea style={{ height: '100%', width: '100%' }}>
      <Center>
        <Stack pt="lg" px="md" gap="xs" align="center" w="100%" maw={800}>
          <DeploymentFilterView workflow={workflow} />

          {isFormWorkflow ? (
            startStep &&
            formStep && (
              <FormCard
                startStep={startStep}
                formStep={formStep}
                workflow={workflow}
              />
            )
          ) : isFileWorkflow ? (
            fileUploadStep && (
              <FileUploadCard
                fileUploadStep={fileUploadStep}
                onClick={() => {}}
                workflow={workflow}
              />
            )
          ) : (
            <EventsPanel
              workflowKey={workflowKey}
              workflow={workflow}
              workflowType={workflow.workflowType}
              entityType={workflow.entityType}
              events={localEvents}
              onDelete={handleEventDelete}
            />
          )}

          <StepsPanel
            workflow={workflow}
            stepConfigs={
              isFormWorkflow || isFileWorkflow ? formStepConfigs : localSteps
            }
            panelTitle={
              isFormWorkflow ? 'After Form is Submitted...' : 'Do this...'
            }
            onDelete={handleStepDelete}
          />

          <Space h="xl" />
        </Stack>
      </Center>
    </ScrollArea>
  );
};
