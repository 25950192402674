import { useGetWorkgroupById } from '@btrway/api-core';
import { Box, Chip, Group } from '@mantine/core';
import { useAtomValue } from 'jotai';
import React from 'react';
import { workgroupIdAtom } from '../../atoms/registrationAtoms';

interface WorkgroupChipSelectorProps {
  value: number[];
  onChange: (workgroupIds: number[]) => void;
}

const WorkgroupChipSelector: React.FC<WorkgroupChipSelectorProps> = ({
  value,
  onChange,
}) => {
  const parentWorkgroupId = useAtomValue(workgroupIdAtom);
  const { data: workgroupResponse, isLoading } = useGetWorkgroupById(
    parentWorkgroupId ?? 0
  );
  const workgroups = workgroupResponse?.children ?? [];

  const sortedWorkgroups = workgroups.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (isLoading || !workgroups.length) return null;

  // Convert numbers to strings for Mantine Chip.Group
  const selectedValues = value.map((id) => id.toString());

  const handleChange = (selected: string[]) => {
    // Convert strings back to numbers for the DTO
    onChange(selected.map(Number));
  };

  return (
    <Box pt="sm">
      <Chip.Group multiple value={selectedValues} onChange={handleChange}>
        <Group gap="md">
          {sortedWorkgroups.map((workgroup) => (
            <Chip key={workgroup.id} value={workgroup.id.toString()}>
              {workgroup.name}
            </Chip>
          ))}
        </Group>
      </Chip.Group>
    </Box>
  );
};

export default WorkgroupChipSelector;
