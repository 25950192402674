import {
  PropertyReference,
  TriggerConfig,
  TriggerSetConfig,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { Card, Divider, Stack, Text } from '@mantine/core';
import React from 'react';
import TriggerSetCard from '../TriggerSetCard/TriggerSetCard';

interface TriggerSetsViewProps {
  triggerSets: TriggerSetConfig[];
  properties: PropertyReference[];
  onTriggerSetsChange: (updatedTriggerSets: TriggerSetConfig[]) => void;
  onTriggerSelect: (
    triggerSetKey: string | null,
    trigger: TriggerConfig | null
  ) => void;
}

const TriggerSetsView: React.FC<TriggerSetsViewProps> = ({
  triggerSets,
  properties,
  onTriggerSetsChange,
  onTriggerSelect,
}) => {
  const handleAddTrigger = (triggerSetKey: string) => {
    onTriggerSelect(triggerSetKey, null);
  };

  const handleEditTrigger = (triggerSetKey: string, trigger: TriggerConfig) => {
    onTriggerSelect(triggerSetKey, trigger);
  };

  const handleCopyTriggerSet = (triggerSetKey: string) => {
    const triggerSetToCopy = triggerSets.find(
      (ts) => ts.triggerSetKey === triggerSetKey
    );
    if (triggerSetToCopy && triggerSetToCopy.triggers) {
      const newTriggerSet: TriggerSetConfig = {
        ...triggerSetToCopy,
        triggerSetKey: uuid(),
        triggers: triggerSetToCopy.triggers.map((trigger) => ({
          ...trigger,
          triggerKey: uuid(),
        })),
      };
      onTriggerSetsChange([...triggerSets, newTriggerSet]);
    }
  };

  const handleDeleteTriggerSet = (triggerSetKey: string) => {
    const updatedTriggerSets = triggerSets.filter(
      (ts) => ts.triggerSetKey !== triggerSetKey
    );
    onTriggerSetsChange(updatedTriggerSets);
  };

  const handleDeleteTrigger = (triggerSetKey: string, triggerKey: string) => {
    const updatedTriggerSets = triggerSets.map((ts) => {
      if (ts.triggerSetKey === triggerSetKey && ts.triggers) {
        return {
          ...ts,
          triggers: ts.triggers.filter((t) => t.triggerKey !== triggerKey),
        };
      }
      return ts;
    });
    onTriggerSetsChange(
      updatedTriggerSets.filter((ts) => ts.triggers && ts.triggers.length > 0)
    );
  };

  const handleFirstTriggerAdd = () => {
    onTriggerSelect(null, null);
  };

  const renderTriggerSets = () => {
    return triggerSets.map((triggerSet, index) => (
      <React.Fragment key={triggerSet.triggerSetKey}>
        <TriggerSetCard
          triggerSet={triggerSet}
          onAddTrigger={handleAddTrigger}
          onCopyTriggerSet={handleCopyTriggerSet}
          onDeleteTriggerSet={handleDeleteTriggerSet}
          onDeleteTrigger={handleDeleteTrigger}
          onEditTrigger={handleEditTrigger}
        />
        {index < triggerSets.length && (
          <Divider
            labelPosition="center"
            label={
              <Text ta="center" size="lg" fw={700} c="gray.7">
                OR
              </Text>
            }
            fz="lg"
            fw={700}
            c="gray.7"
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <Stack gap="xs">
      {renderTriggerSets()}
      <Card
        shadow="sm"
        padding="sm"
        radius="md"
        withBorder
        onClick={handleFirstTriggerAdd}
        style={{ cursor: 'pointer' }}
        ta="center"
      >
        <Text fz="md" fw={600}>
          {triggerSets.length === 0 ? 'Add Condition' : 'Add Another Condition'}
        </Text>
      </Card>
    </Stack>
  );
};

export default TriggerSetsView;
