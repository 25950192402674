import { useGetRelationshipTypes } from '@btrway/api-core';
import {
  Button,
  Card,
  Grid,
  Group,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React from 'react';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';
import RelationshipChipSelector from '../RelationshipChipSelector/RelationshipChipSelector';
import WorkgroupChipSelector from '../WorkgroupChipSelector/WorkgroupChipSelector';

export interface ChildInfoProps {
  childKey: string;
  childIndex: number;
  onDelete: (personKey: string) => void;
}

const ChildInfo: React.FC<ChildInfoProps> = ({
  childKey,
  childIndex,
  onDelete,
}) => {
  const {
    registration,
    updateChild,
    updateRelationship,
    updatePersonWorkgroups,
  } = useRegistrationSubmission();
  const { data: relationshipTypes } = useGetRelationshipTypes();

  const child = registration.persons.find(
    (person) => person.personKey === childKey
  );

  if (!child) return null;

  const currentRelationship = registration.relationships.find(
    (rel) =>
      rel.toPersonKey === childKey &&
      rel.fromPersonKey === registration.registrantPersonKey
  );

  return (
    <Stack gap="md">
      <Card withBorder radius="sm" padding="lg" shadow="none">
        <Group justify="flex-start" mb="lg">
          <Text size="lg" fw={600}>
            Child/Dependent #{childIndex}
          </Text>
          <Button
            size="compact-xs"
            color="gray"
            variant="outline"
            onClick={() => onDelete(childKey)}
          >
            Delete
          </Button>
        </Group>
        <Grid gutter="xl">
          <Grid.Col span={12}>
            <Group grow gap="xl">
              <TextInput
                label="Child's First Name"
                value={child.firstName}
                onChange={(event) =>
                  updateChild(childKey, 'firstName', event.currentTarget.value)
                }
                required
                autoFocus
              />
              <TextInput
                label="Child's Last Name"
                value={child.lastName}
                onChange={(event) =>
                  updateChild(childKey, 'lastName', event.currentTarget.value)
                }
                required
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Group grow gap="xl">
              <TextInput
                label="Child's Email Address"
                type="email"
                description="Recommended to use your child's school email"
                value={child.email}
                onChange={(event) =>
                  updateChild(childKey, 'email', event.currentTarget.value)
                }
                required
              />
              <TextInput
                label="Child's Mobile Phone Number"
                value={child.mobilePhone}
                onChange={(event) =>
                  updateChild(
                    childKey,
                    'mobilePhone',
                    event.currentTarget.value
                  )
                }
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="sm" fw={500}>
              Your Relationship to {child.firstName || 'this child'}
            </Text>
            <RelationshipChipSelector
              value={currentRelationship?.relationshipTypeId.toString() || ''}
              onChange={(value) =>
                updateRelationship(
                  registration.registrantPersonKey,
                  childKey,
                  Number(value)
                )
              }
              relationshipTypes={relationshipTypes || []}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Text size="sm" fw={500}>
              {child.firstName ? `${child.firstName} is` : 'They are'}{' '}
              interested in participating in:
            </Text>
            <WorkgroupChipSelector
              value={child.workgroupIds}
              onChange={(workgroupIds) =>
                updatePersonWorkgroups(childKey, workgroupIds)
              }
            />
          </Grid.Col>
        </Grid>
      </Card>
    </Stack>
  );
};

export default ChildInfo;
