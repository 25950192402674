import React, { createContext, ReactNode, useContext, useState } from 'react';

interface RightSectionContent {
  content: ReactNode;
  width?: string | number;
}

interface RightSectionContextType {
  rightSectionContent: RightSectionContent | null;
  setRightSectionContent: (content: ReactNode, width?: string | number) => void;
  clearRightSectionContent: () => void;
}

const RightSectionContext = createContext<RightSectionContextType | undefined>(
  undefined
);

export const RightSectionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [rightSectionContent, setInternalRightSectionContent] =
    useState<RightSectionContent | null>(null);

  const setRightSectionContent = (
    content: ReactNode,
    width?: string | number
  ) => {
    setInternalRightSectionContent({ content, width });
  };

  const clearRightSectionContent = () => {
    setInternalRightSectionContent(null);
  };

  return (
    <RightSectionContext.Provider
      value={{
        rightSectionContent,
        setRightSectionContent,
        clearRightSectionContent,
      }}
    >
      {children}
    </RightSectionContext.Provider>
  );
};

export const useRightSection = () => {
  const context = useContext(RightSectionContext);
  if (context === undefined) {
    throw new Error(
      'useRightSection must be used within a RightSectionProvider'
    );
  }
  return context;
};
