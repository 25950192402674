import { CurrentUserResponse } from '@btrway/api-core';
import { useAuth, useAuthContext } from '@btrway/auth-core';
import { useNavigate } from 'react-router-dom';

export const useAuthWeb = () => {
  const {
    loginWithToken,
    logout,
    // requestOrgAccessToken,
    // requestPersonAccessToken,
    // exitOrgAccess,
    // exitPersonAccess,
  } = useAuth();
  const { authState } = useAuthContext();
  // const currentUser = authState.currentUser;

  // const [apiToken, setApiToken, removeApiToken] = useSessionStorage({
  //   key: "apiToken",
  // });
  // const [orgAccessToken, setOrgAccessToken, removeOrgAccessToken] =
  //   useSessionStorage({
  //     key: "orgAccessToken",
  //   });
  // const { currentUser } = useAuthenticatedUser();

  const navigate = useNavigate();

  // const handleRefresh = async (token: string) => {
  //   // await loginWithToken(token)
  //   //   .then(({ success }) => {
  //   //     if (!success) {
  //   //       handleLogout();
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("An error occurred during refresh", error);
  //   //   });
  // };

  // const requestInternalOrgAccess = async (organizationId: number) => {
  //   requestOrgAccessToken(organizationId)
  //     .then(({ success, error }) => {
  //       if (
  //         success
  //         //  &&
  //         // currentUser?.user &&
  //         // currentUser.user.internal
  //       ) {
  //         // console.log(currentUser);
  //         if (organizationId) {
  //           navigate('/app', { replace: true });
  //         } else {
  //           navigate('/app-internal/organizations', { replace: true });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(
  //         'An error occurred during internal org access request:',
  //         error
  //       );
  //     });
  // };

  // const requestInternalPersonAccess = async (
  //   personId: number,
  //   organizationId: number,
  //   email: string
  // ) => {
  //   requestPersonAccessToken(personId, organizationId, email)
  //     .then(({ success, error }) => {
  //       if (
  //         success
  //         //  &&
  //         // currentUser?.user &&
  //         // currentUser.user.internal
  //       ) {
  //         if (personId && organizationId) {
  //           navigate('/app', { replace: true });
  //         } else {
  //           navigate('/app-internal/users', { replace: true });
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(
  //         'An error occured during internal person access request:',
  //         error
  //       );
  //     });
  // };

  //TODO: Exit Internal Org Access
  // const exitInternalOrgAccess = async () => {
  //   exitOrgAccess();
  //   navigate('/app-internal/organizations', { replace: true });
  // };

  // const exitInternalPersonAcesss = async () => {
  //   exitPersonAccess();
  //   if (isAccessingOrg) {
  //     navigate('/app/playbook', { replace: true });
  //   } else {
  //     navigate('/app-internal/users', { replace: true });
  //   }
  // };

  const handleLoginSuccess = async (
    requireMfa?: boolean,
    email?: string,
    currentUser?: CurrentUserResponse
  ) => {
    try {
      if (requireMfa) {
        navigate('/multi-factor-auth', { state: { email } });
      } else if (
        currentUser?.user?.internal
        // !isAccessingOrg &&
        // !isAccessingPerson
      ) {
        navigate('/app-internal', { replace: true });
      } else {
        navigate('/app', { replace: true });
      }
    } catch (error) {
      console.error('Error handling login success:', error);
    }
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Error removing token:', error);
    }
  };

  return {
    handleLoginSuccess,
    handleLogout,
    // requestInternalOrgAccess,
    // requestInternalPersonAccess,
    // exitInternalOrgAccess,
    // exitInternalPersonAcesss,
  };
};
