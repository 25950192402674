import { AsideBar } from '@btrway/aside-bar';
import { useAuthContext } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useImpersonation } from '@btrway/impersonation';
import { NavHeader, PrimaryNavigation } from '@btrway/primary-nav-bar';
import { useThemeScheme } from '@btrway/theme-scheme-provider';
import {
  courseViewerTransitionTargetAtom,
  endCourseViewerTransitionAtom,
  isCourseViewerTransitioningAtom,
} from '@btrway/web-routing';
import { AppShell, Box, LoadingOverlay, useMantineTheme } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ImpersonationBanner } from '../ImpersonationBanner/ImpersonationBanner';
import styles from './AppShellLayout.module.css';

interface AppShellLayoutProps {
  children?: React.ReactNode;
}

export const AppShellLayout: React.FC<AppShellLayoutProps> = ({
  children = null,
}) => {
  const [navOpened, { toggle: toggleNav, close: closeNav }] =
    useDisclosure(false);
  const [asideOpened, { toggle: toggleAside, close: closeAside }] =
    useDisclosure(false);

  const [navbarMinimized, setNavbarMinimized] = useState(false);
  const [asideMinimized, setAsideMinimized] = useState(true);
  const previousNavbarState = useRef(navbarMinimized);
  const { getColorValue } = useThemeScheme();
  const { authState } = useAuthContext();
  const { currentUser, isLoading } = useAuthenticatedUser();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  // Course viewer transition handling
  const isTransitioning = useAtomValue(isCourseViewerTransitioningAtom);
  const transitionTarget = useAtomValue(courseViewerTransitionTargetAtom);
  const endTransition = useSetAtom(endCourseViewerTransitionAtom);
  const navigate = useNavigate();

  const { isImpersonatingOrg, isImpersonatingPerson } = useImpersonation();

  useEffect(() => {
    if (isTransitioning && transitionTarget) {
      const timer = setTimeout(() => {
        navigate(`/app/education/course-viewer/${transitionTarget.courseUid}`, {
          state: { resume: transitionTarget.resume },
        });
        endTransition();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning, transitionTarget, navigate, endTransition]);

  const fullNavWidth = 240;
  const collapsedNavWidth = 72;
  const appShellBackground = getColorValue('appShellBackground');
  const mainContentBackground = getColorValue('mainContentBackground');
  const headerHeight =
    (isMobile ? 60 : 20) +
    (isImpersonatingOrg || isImpersonatingPerson ? 10 : 0);

  const handleAsideToggle = (minimized: boolean) => {
    setAsideMinimized(minimized);
    if (!minimized) {
      previousNavbarState.current = navbarMinimized;
      setNavbarMinimized(true);
    } else if (previousNavbarState.current === false) {
      setNavbarMinimized(false);
    }
  };

  const handleAsideContent = (content: 'search') => {
    toggleAside();
    if (asideMinimized) {
      setAsideMinimized(false);
    } else {
      setAsideMinimized(true);
    }
  };

  const handleNavItemClick = () => {
    if (isMobile) {
      closeNav();
    }
  };

  if (isLoading || authState.status === 'loading') {
    return <LoadingOverlay visible={true} overlayProps={{ blur: 2 }} />;
  }

  return (
    <div
      className={`${styles.appWrapper} ${
        isTransitioning ? styles.transitioning : ''
      }`}
    >
      <AppShell
        header={{ height: headerHeight }}
        navbar={{
          width: navbarMinimized ? collapsedNavWidth : fullNavWidth,
          breakpoint: 'sm',
          collapsed: { mobile: !navOpened, desktop: false },
        }}
        aside={{
          width: { sm: asideMinimized ? 40 : 400 },
          breakpoint: 'sm',
          collapsed: { mobile: !asideOpened, desktop: false },
        }}
        p="0 0 0 0"
        withBorder={false}
        styles={{
          root: { backgroundColor: appShellBackground },
          header: { backgroundColor: appShellBackground },
          navbar: { backgroundColor: appShellBackground },
        }}
        className={`${styles.appShell} ${
          isTransitioning ? styles.transitioning : ''
        }`}
      >
        <AppShell.Header>
          <ImpersonationBanner />
          <NavHeader
            openedNav={navOpened}
            toggleNav={toggleNav}
            toggleAside={handleAsideContent}
          />
        </AppShell.Header>

        <AppShell.Navbar p="md" className={styles.navbar}>
          <PrimaryNavigation
            minimized={navbarMinimized}
            setMinimized={setNavbarMinimized}
            onNavItemClick={handleNavItemClick}
          />
        </AppShell.Navbar>

        <AppShell.Main className={styles.main}>
          <Box
            className={styles.content}
            style={{ backgroundColor: mainContentBackground }}
          >
            {children ? children : <Outlet />}
          </Box>
        </AppShell.Main>

        <AppShell.Aside>
          <AsideBar
            minimized={asideMinimized}
            setMinimized={handleAsideToggle}
          />
        </AppShell.Aside>
      </AppShell>
    </div>
  );
};
