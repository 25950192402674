import { Group, Text } from '@mantine/core';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { NodeSelection } from 'prosemirror-state';
import React, { useEffect, useState } from 'react';
import { DYNAMIC_CONTENT_OPTIONS } from '../../config/dynamicContentOptions';
import { DynamicContentAttributes } from '../../types/editorContent';
import classes from './DynamicContentComponent.module.css';

export const DynamicContentComponent: React.FC<NodeViewProps> = ({
  node,
  selected,
  getPos,
  editor,
}) => {
  const attrs = node.attrs as DynamicContentAttributes;
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!editor || typeof getPos !== 'function') return;

    const { state, view } = editor;
    const pos = getPos();

    const nodeSelection = NodeSelection.create(state.doc, pos);
    const tr = state.tr.setSelection(nodeSelection);
    view.dispatch(tr);
    view.focus();
  };

  const option = DYNAMIC_CONTENT_OPTIONS.find(
    (opt) => opt.type === attrs.contentType
  );
  const Icon = option?.icon;

  return (
    <NodeViewWrapper className={classes.wrapper}>
      <div
        className={`${classes.dynamicContent} ${
          isSelected ? classes.selected : ''
        }`}
        onClick={handleClick}
        contentEditable={false}
      >
        <div className={classes.contentContainer}>
          <Group gap="xs" className={classes.header}>
            {Icon && <Icon size={20} className={classes.icon} />}
            <Text size="sm" fw={500}>
              {option?.name || attrs.contentType}
            </Text>
          </Group>

          <Text mt="xs" c="gray.7" className={classes.placeholder}>
            {attrs.placeholder}
          </Text>
        </div>
      </div>
    </NodeViewWrapper>
  );
};
