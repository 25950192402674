import {
  DataTypeEnum,
  EventRequest,
  getGetEventByIdQueryKey,
  useGetEventById,
} from '@btrway/api-calendar';
import { WorkgroupResponse } from '@btrway/api-core';
import { StyledModal } from '@btrway/styled-modal';
import { Box, Center, Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { convertEventResponseToRequest } from '../../utils/eventConversion';
import { CalendarEventEdit } from '../CalendarEventEdit/CalendarEventEdit';

interface CalendarEventModalProps {
  opened: boolean;
  onClose: () => void;
  eventId?: number;
  newEventConfig?: Partial<EventRequest>;
  workgroup?: WorkgroupResponse;
  facilityId?: number;
  onSave: () => void;
  onDelete?: () => void;
  userTimezone: string;
}

export const CalendarEventModal: React.FC<CalendarEventModalProps> = ({
  opened,
  onClose,
  eventId,
  newEventConfig,
  workgroup,
  facilityId,
  onSave,
  onDelete,
  userTimezone,
}) => {
  const { data: eventResponse, isLoading } = useGetEventById(eventId || 0, {
    query: {
      enabled: !!eventId,
      queryKey: getGetEventByIdQueryKey(eventId || 0),
    },
  });

  const [event, setEvent] = useState<Partial<EventRequest>>({});

  // Handle existing event data
  useEffect(() => {
    if (eventResponse) {
      setEvent(convertEventResponseToRequest(eventResponse));
    }
  }, [eventResponse]);

  // Handle new event configuration
  useEffect(() => {
    if (newEventConfig) {
      const configWithFacility = {
        ...newEventConfig,
        // Add facilityId to the event targets if facilityId is provided
        eventTargets: [
          ...(newEventConfig.eventTargets || []),
          ...(facilityId
            ? [
                {
                  entityId: facilityId,
                  entityType: DataTypeEnum.facility,
                  organizationId: workgroup?.organizationId || 0,
                },
              ]
            : []),
        ],
      };
      setEvent(configWithFacility);
    }
  }, [newEventConfig, facilityId, workgroup]);

  // Cleanup effect
  useEffect(() => {
    if (!opened) {
      setEvent({});
    }
  }, [opened]);

  const renderContent = () => {
    if (eventId && isLoading) {
      return (
        <Center h={200}>
          <Loader size="lg" />
        </Center>
      );
    }

    return (
      <CalendarEventEdit
        workgroup={workgroup}
        initialEvent={event}
        onCancel={onClose}
        onSave={onSave}
        onDelete={onDelete}
        userTimezone={userTimezone}
      />
    );
  };

  return (
    <StyledModal
      opened={opened}
      onClose={onClose}
      title={eventId ? 'Edit Event' : 'New Event'}
      variant="xl"
      closeOnClickOutside={false}
    >
      <Box p="md" style={{ height: '100%' }}>
        {renderContent()}
      </Box>
    </StyledModal>
  );
};
