import { NavLink, Tooltip } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface ToggleNavbarButtonProps {
  minimized: boolean;
  onClick: () => void;
}

const ToggleNavbarButton: React.FC<ToggleNavbarButtonProps> = ({
  minimized,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const Icon = minimized ? IconChevronRight : IconChevronLeft;

  useEffect(() => {
    if (minimized) {
      const timer = setTimeout(() => setShowTooltip(true), 1000);
      return () => clearTimeout(timer);
    } else {
      setShowTooltip(false);
    }
  }, [minimized]);

  const button = (
    <NavLink
      onClick={() => {
        setShowTooltip(false);
        onClick();
      }}
      label=""
      leftSection={
        <Icon
          size="1rem"
          stroke={1.5}
          style={{
            width: '25px',
            height: '25px',
          }}
        />
      }
      styles={(theme) => ({
        root: {
          width: 40,
          height: 40,
          padding: 0,
          borderRadius: theme.radius.sm,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        body: {
          display: 'none',
        },
        section: {
          margin: 0,
        },
      })}
    />
  );

  return showTooltip ? (
    <Tooltip label="Expand sidebar" position="right" withArrow>
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default ToggleNavbarButton;
