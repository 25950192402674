import {
  Box,
  Flex,
  Stack,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useChapter } from '../../hooks/useChapter';
import QuestionList from '../QuestionList/QuestionList';
import VideoSelector from '../VideoSelector/VideoSelector';

interface ChapterDetailProps {
  chapterKey: string;
}

const ChapterDetail: React.FC<ChapterDetailProps> = ({ chapterKey }) => {
  const { chapter, setChapter } = useChapter(chapterKey);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  if (!chapter) return null;

  const handleTitleChange = (value: string) => {
    setChapter({
      ...chapter,
      title: value,
    });
  };

  const handleDescriptionChange = (value: string) => {
    setChapter({
      ...chapter,
      description: value,
    });
  };

  return (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      gap="md"
      align={isMobile ? 'stretch' : 'flex-start'}
    >
      <Stack style={{ flex: 1 }}>
        <TextInput
          label="Chapter Title"
          value={chapter.title}
          onChange={(e) => handleTitleChange(e.currentTarget.value)}
          placeholder="Enter chapter title"
          required
        />
        <Textarea
          label="Chapter Description"
          value={chapter.description || ''}
          onChange={(e) => handleDescriptionChange(e.currentTarget.value)}
          placeholder="Enter chapter description (optional)"
          rows={3}
        />
      </Stack>
      <Box w={250} miw={250}>
        <VideoSelector chapterKey={chapter.key} />
      </Box>
      <Box style={{ flex: 1, minWidth: 250 }}>
        <QuestionList chapterKey={chapter.key} />
      </Box>
    </Flex>
  );
};

export default ChapterDetail;
