import { Chip, Group } from '@mantine/core';
import React, { useState } from 'react';

interface CategorySelectorProps {
  categories: string[];
  onCategoryChange: (category: string | null) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = ({
  categories,
  onCategoryChange,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleCategorySelect = (category: string | null) => {
    setSelectedCategory(category);
    onCategoryChange(category);
  };

  return (
    <Group justify="center" gap="xs">
      <Chip
        checked={selectedCategory === null}
        onChange={() => handleCategorySelect(null)}
      >
        All
      </Chip>
      {categories.sort().map((category) => (
        <Chip
          key={category}
          checked={selectedCategory === category}
          onChange={() => handleCategorySelect(category)}
        >
          {category}
        </Chip>
      ))}
    </Group>
  );
};

export default CategorySelector;
