import { StorageUsageEnum } from '@btrway/api-storage';
import { Button, Group, Modal } from '@mantine/core';
import React, { useState } from 'react';
import ImageUploadCrop from '../ImageUploadCrop/ImageUploadCrop';

interface EditableImageModalProps {
  opened: boolean;
  onClose: () => void;
  onImageUpload: (file: Blob) => void;
  existingImageUrl?: string;
  storageUsage: StorageUsageEnum;
  title: string;
  aspectRatio?: { width: number; height: number };
}

const EditableImageModal: React.FC<EditableImageModalProps> = ({
  opened,
  onClose,
  onImageUpload,
  existingImageUrl,
  storageUsage,
  title,
  aspectRatio,
}) => {
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

  const handleCroppedImage = (url: string) => {
    setCroppedImageUrl(url);
  };

  const handleUpload = async () => {
    if (croppedImageUrl) {
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      onImageUpload(blob);
      onClose();
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title={title} size="lg">
      <ImageUploadCrop
        onCroppedImage={handleCroppedImage}
        currentImageUrl={existingImageUrl || ''}
        isEditingCurrent={!!existingImageUrl}
        aspectRatio={aspectRatio}
      />
      <Group justify="flex-end" mt="md">
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button onClick={handleUpload} disabled={!croppedImageUrl}>
          Upload
        </Button>
      </Group>
    </Modal>
  );
};

export default EditableImageModal;
