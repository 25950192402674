import {
  CertificationConfig,
  CertificationTaskProperties,
  CertificationTypeEnum,
  CourseTaskProperties,
  CurriculumTaskProperties,
  CustomTaskProperties,
  FormPacketTaskProperties,
  FormTaskProperties,
  PersonDataProperties,
  TaskDataProperties,
  TaskListTaskProperties,
  TaskOptionTaskProperties,
  ThirdPartyTaskProperties,
} from '@btrway/api-workflow';

type CertificationPropertiesMap = {
  [CertificationTypeEnum.completeCourse]: CourseTaskProperties;
  [CertificationTypeEnum.submitForm]: FormTaskProperties;
  [CertificationTypeEnum.completeCurriculum]: CurriculumTaskProperties;
  [CertificationTypeEnum.completeTaskList]: TaskListTaskProperties;
  [CertificationTypeEnum.taskData]: TaskDataProperties;
  [CertificationTypeEnum.personData]: PersonDataProperties;
  [CertificationTypeEnum.thirdParty]: ThirdPartyTaskProperties;
  [CertificationTypeEnum.certification]: CertificationTaskProperties;
  [CertificationTypeEnum.completeFormPacket]: FormPacketTaskProperties;
  [CertificationTypeEnum.completeTask]: CustomTaskProperties;
  [CertificationTypeEnum.completeTaskOption]: TaskOptionTaskProperties;
};

export function getTypedCertificationProperties<
  T extends keyof CertificationPropertiesMap
>(
  config: CertificationConfig | undefined,
  expectedCertificationType: T
): CertificationPropertiesMap[T] | undefined {
  if (config?.certificationType === expectedCertificationType) {
    return config.taskProperties as CertificationPropertiesMap[T];
  }
  return undefined;
}
