import {
  getGetWorkgroupTypesByOrganizationIdQueryKey,
  useGetWorkgroupTypesByOrganizationId,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Text } from '@mantine/core';
import pluralize from 'pluralize';
import { useWorkgroup } from '../../providers/WorkgroupProvider';

interface ChildWorkgroupTypeLabelProps {
  fallbackLabel: string;
  hideIfNoChildren?: boolean;
}

export const ChildWorkgroupTypeLabel: React.FC<
  ChildWorkgroupTypeLabelProps
> = ({ fallbackLabel, hideIfNoChildren = true }) => {
  // Get the current organization ID
  const { currentOrganization } = useAuthenticatedUser();
  const organizationId = currentOrganization.id;

  // Fetch workgroup types for the organization
  const { data: workgroupTypes, isLoading } =
    useGetWorkgroupTypesByOrganizationId(organizationId, {
      query: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        queryKey: getGetWorkgroupTypesByOrganizationIdQueryKey(organizationId),
      },
    });

  // Get the current workgroup
  const { workgroup } = useWorkgroup();

  // Check if we should hide this label/route
  if (
    hideIfNoChildren &&
    (!workgroup?.children || workgroup.children.length === 0)
  ) {
    return null;
  }

  if (isLoading || !workgroup || !workgroupTypes) {
    return (
      <Text fz="sm" lineClamp={1}>
        {fallbackLabel}
      </Text>
    );
  }

  // Find the current workgroup type and its first child type
  const workgroupType = workgroupTypes.find(
    (type) => type.id === workgroup.workgroupType.id
  );
  const firstChildWorkgroupType = workgroupType?.childWorkgroupTypes?.[0];

  // Generate the label - pluralize the child workgroup type name or use fallback
  const label = firstChildWorkgroupType
    ? pluralize(firstChildWorkgroupType.name)
    : fallbackLabel;

  return (
    <Text fz="sm" lineClamp={1}>
      {label}
    </Text>
  );
};
