import {
  EventConfig,
  EventTypeEnum,
  FormSubmittedEventProperties,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { WorkflowSelectorField } from '../../../selector-components/WorkflowSelectorField/WorkflowSelectorField';
import { EventConfigProps } from '../../../types/editors';

export const EventFormConfiguration: React.FC<EventConfigProps> = ({
  config,
  onConfigChange,
}) => {
  // Cast the basePropertyConfig to FormSubmittedEventProperties
  const formProperties =
    config.basePropertyConfig as FormSubmittedEventProperties;

  // Create a WorkflowSelection object from the existing workflowDefinitionKey
  const workflowSelection: WorkflowSelection | undefined =
    formProperties?.workflowDefinitionKey
      ? {
          workflowDefinitionKey: {
            value: formProperties.workflowDefinitionKey,
          },
        }
      : undefined;

  const handleWorkflowSelect = (
    newWorkflowSelection: WorkflowSelection,
    name: string
  ) => {
    const updatedConfig: EventConfig = {
      ...config,
      basePropertyConfig: {
        ...formProperties,
        workflowDefinitionKey: newWorkflowSelection.workflowDefinitionKey.value,
      },
      eventTypeCode: EventTypeEnum.formSubmitted,
    };
    onConfigChange(updatedConfig);
  };

  return (
    <WorkflowSelectorField
      workflowType={WorkflowTypeEnum.form}
      workflowSelection={workflowSelection}
      onWorkflowSelect={handleWorkflowSelect}
    />
  );
};
