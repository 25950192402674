import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import ApprovalsWidget from '../../components/ApprovalsWidget/ApprovalsWidget';
import styles from './ApprovalsPage.module.css';

const ApprovalsPage: React.FC = () => {
  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        {/* <CertificationsTopSection /> */}
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <ApprovalsWidget />
            </Stack>
          </Stack>
        </Box>
        {/* <Box visibleFrom="md" style={{ flexShrink: 0 }}>
          <CalendarWidget />
        </Box> */}
      </Flex>
    </Stack>
  );
};

export default ApprovalsPage;
