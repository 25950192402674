import { EventConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import { ToolboxItem, ToolboxPanel } from '@btrway/workflow-builder-toolbox';
import { useWorkflowUpdater } from '@btrway/workflow-configuration-manager';
import { useEventTypes } from '@btrway/workflow-manager';
import { useCallback, useMemo } from 'react';

interface UseEventEditProps {
  workflowKey: string;
  workflowType: WorkflowTypeEnum;
  entityType?: string;
  onEventUpdated?: (updatedEvent: EventConfig, isNew: boolean) => void;
  onEventDeleted?: (eventKey: string) => void;
}

export const useEventEdit = ({
  workflowKey,
  workflowType,
  entityType,
  onEventUpdated,
  onEventDeleted,
}: UseEventEditProps) => {
  const { openDisplay, closeDisplay } = useSettingsDisplay();
  const { addEvent, removeEvent } = useWorkflowUpdater();
  const { eventTypes } = useEventTypes();

  const handleSaveEventConfig = async (
    config: EventConfig,
    isNew: boolean = false
  ) => {
    await addEvent(config);
    onEventUpdated?.(config, isNew);
  };

  const handleDeleteEvent = async (eventKey: string) => {
    await removeEvent(eventKey);
    onEventDeleted?.(eventKey);
  };

  const eventItems = useMemo(
    () =>
      eventTypes.map((eventType) => ({
        type: 'eventType' as const,
        data: eventType,
      })),
    [eventTypes]
  );

  const handleEventCardSelect = useCallback(
    (item: ToolboxItem) => {
      // if (item.type === 'eventType' && workflowType) {
      //   const newEvent = {
      //     eventKey: uuid(),
      //     eventTypeCode: item.data.code,
      //     title: item.data.name,
      //   };
      //   openDisplay(
      //     <EventConfigEditor
      //       initialEventConfig={newEvent}
      //       workflowType={workflowType}
      //       isNew={true}
      //       onClose={closeDisplay}
      //       onSave={async (updatedConfig) => {
      //         await handleSaveEventConfig(updatedConfig, true);
      //         closeDisplay();
      //       }}
      //     />,
      //     { mode: 'panel', modalProps: { title: 'Configure Event' } }
      //   );
      // }
    },
    [workflowType, handleSaveEventConfig, openDisplay, closeDisplay]
  );

  const showEventEditor = useCallback(
    (eventConfig: EventConfig, isNew: boolean = false) => {
      // openDisplay(
      //   <EventConfigEditor
      //     initialEventConfig={eventConfig}
      //     workflowType={workflowType}
      //     isNew={isNew}
      //     onClose={closeDisplay}
      //     onDelete={
      //       !isNew
      //         ? () => {
      //             handleDeleteEvent(eventConfig.eventKey);
      //             closeDisplay();
      //           }
      //         : undefined
      //     }
      //     onSave={async (updatedConfig) => {
      //       await handleSaveEventConfig(updatedConfig, isNew);
      //       closeDisplay();
      //     }}
      //   />,
      //   {
      //     mode: 'panel',
      //     modalProps: { title: isNew ? 'Add Event' : 'Configure Event' },
      //   }
      // );
    },
    [
      workflowType,
      openDisplay,
      closeDisplay,
      handleSaveEventConfig,
      handleDeleteEvent,
    ]
  );

  const showEventsToolbox = useCallback(() => {
    openDisplay(
      <ToolboxPanel
        title="Events"
        items={eventItems}
        onCardSelect={handleEventCardSelect}
        droppableId="events-toolbox-droppable"
        variant="full"
        filter={(item) => {
          if (item.type === 'eventType' && 'entityType' in item.data) {
            return !entityType || item.data.entityType === entityType;
          }
          return true;
        }}
      />,
      { mode: 'panel', modalProps: { title: 'Add Event' } }
    );
  }, [entityType, eventItems, handleEventCardSelect, openDisplay]);

  return {
    openDisplay,
    showEventEditor,
    showEventsToolbox,
  };
};
