import {
  getGetWorkflowTemplateByKeyQueryKey,
  getGetWorkflowTemplatesQueryKey,
  useGetWorkflowTemplateByKey,
  useGetWorkflowTemplates,
  useSaveWorkflowTemplate,
  useUpdateWorkflowTemplateConfig,
  WorkflowTemplateConfigUpdate,
  WorkflowTemplateRequest,
} from '@btrway/api-workflow';
import { useWorkflowCommon } from '../providers/WorkflowCommonProvider';
import { CommonWorkflow } from '../types/commonWorkflow';
import { WorkflowResult, WorkflowsResult } from '../types/service';
import {
  convertCommonToWorkflowRequest,
  convertResponseToCommon,
  validateWorkflowBeforeConversion,
} from '../utils/commonWorkflowConversions';

export function useWorkflowTemplate(workflowKey?: string) {
  const { sourceType } = useWorkflowCommon();
  const isEnabled = sourceType === 'template';

  const {
    data,
    isLoading,
    error,
    refetch: refetchTemplate,
  } = useGetWorkflowTemplateByKey(
    workflowKey || '',
    { publishedOnly: false },
    {
      query: {
        enabled: isEnabled && !!workflowKey,
        queryKey: getGetWorkflowTemplateByKeyQueryKey(workflowKey || ''),
      },
    }
  );

  const {
    data: templatesData,
    isLoading: templatesLoading,
    error: templatesError,
    refetch: refetchTemplates,
  } = useGetWorkflowTemplates({
    query: {
      enabled: isEnabled,
      queryKey: getGetWorkflowTemplatesQueryKey(),
    },
  });

  const { mutateAsync: updateConfig } = useUpdateWorkflowTemplateConfig();
  const { mutateAsync: saveWorkflowTemplate } = useSaveWorkflowTemplate();

  const result: WorkflowResult = {
    data: data ? convertResponseToCommon(data) : null,
    isLoading,
    error: error as Error | null,
    refetch: refetchTemplate,
  };

  const templatesResult: WorkflowsResult = {
    workflows: (templatesData || []).map(convertResponseToCommon),
    isLoading: templatesLoading,
    error: templatesError as Error | undefined,
    refetch: refetchTemplates,
  };

  return {
    workflowResult: result,
    workflowsResult: templatesResult,
    updateConfig: async (config: WorkflowTemplateConfigUpdate) => {
      await updateConfig({ data: config });
    },
    updateWorkflow: async (workflow: CommonWorkflow) => {
      if (workflow.sourceType !== 'template') {
        throw new Error('Cannot save definition workflow in template context');
      }
      const validationErrors = validateWorkflowBeforeConversion(workflow);
      if (validationErrors.length > 0) {
        throw new Error(`Invalid workflow: ${validationErrors.join(', ')}`);
      }
      const request = convertCommonToWorkflowRequest(
        workflow
      ) as WorkflowTemplateRequest;
      return await saveWorkflowTemplate({ data: request });
    },
  };
}
