import {
  HelpRepositoryEnum,
  HelpResourceResponse,
  useQueryHelpResources,
} from '@btrway/api-help';
import { ContentRenderer } from '@btrway/content-editor';
import { HelpResourceList } from '@btrway/help-components';
import { useHelpTags } from '@btrway/help-tag-manager';
import {
  Badge,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  Transition,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconChevronLeft, IconSearch, IconX } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';
import HelpArticleRenderer from '../HelpArticleRenderer/HelpArticleRenderer';

const HelpSidebar: React.FC = () => {
  const [activeView, setActiveView] = useState<'list' | 'helpresource'>('list');
  const [selectedHelpResource, setSelectedHelpResource] =
    useState<HelpResourceResponse | null>(null);
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText] = useDebouncedValue(searchText, 300);
  const tags = useHelpTags();

  const {
    mutate: queryHelpResources,
    data: helpResources,
    isPending: isLoading,
    error,
  } = useQueryHelpResources<Error>();

  // Separated query functions for search and tags
  const fetchByTags = useCallback(() => {
    if (tags.length > 0) {
      queryHelpResources({
        data: {
          repository: HelpRepositoryEnum.clientWhy,
          tags,
        },
      });
    }
  }, [queryHelpResources, tags]);

  const fetchBySearch = useCallback(
    (search: string) => {
      if (search) {
        queryHelpResources({
          data: {
            repository: HelpRepositoryEnum.clientWhy,
            searchValue: search,
          },
        });
      }
    },
    [queryHelpResources]
  );

  // Effect for tag-based fetching
  useEffect(() => {
    if (!debouncedSearchText) {
      fetchByTags();
    }
  }, [fetchByTags, debouncedSearchText]);

  // Effect for search-based fetching
  useEffect(() => {
    if (debouncedSearchText) {
      fetchBySearch(debouncedSearchText);
    }
  }, [debouncedSearchText, fetchBySearch]);

  const handleHelpResourceClick = (helpresource: HelpResourceResponse) => {
    setSelectedHelpResource(helpresource);
    setActiveView('helpresource');
  };

  const handleBackToList = () => {
    setActiveView('list');
    setSelectedHelpResource(null);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  return (
    <Stack gap="md">
      <Stack
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: '100%',
        }}
      >
        <Transition
          mounted={activeView === 'list'}
          transition="slide-right"
          duration={300}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={{ ...styles, height: '100%' }}>
              <Stack>
                <TextInput
                  placeholder="Search help resources..."
                  value={searchText}
                  onChange={(event) => setSearchText(event.currentTarget.value)}
                  leftSection={<IconSearch size={16} />}
                  rightSection={
                    isLoading ? (
                      <Loader size="xs" />
                    ) : searchText ? (
                      <IconX
                        size={16}
                        style={{ cursor: 'pointer' }}
                        onClick={handleClearSearch}
                      />
                    ) : null
                  }
                />
                {tags.length > 0 && (
                  <Group gap="xs">
                    <Text size="sm" c="dimmed">
                      Current tags:
                    </Text>
                    {tags.map((tag) => (
                      <Badge key={tag} variant="light" size="sm">
                        {tag}
                      </Badge>
                    ))}
                  </Group>
                )}
                {error ? (
                  <Text c="red">
                    Error loading help resources: {error.message}
                  </Text>
                ) : helpResources && helpResources.length > 0 ? (
                  <>
                    <Text fw={700} mb="xs">
                      {searchText ? 'Search Results' : 'Suggested Topics'}
                    </Text>
                    <HelpResourceList
                      helpResources={helpResources}
                      onHelpResourceClick={handleHelpResourceClick}
                    />
                  </>
                ) : searchText || tags.length > 0 ? (
                  <Text>No help resources found</Text>
                ) : null}
              </Stack>
            </div>
          )}
        </Transition>
        <Transition
          mounted={activeView === 'helpresource'}
          transition="slide-left"
          duration={300}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={{ ...styles, height: '100%' }}>
              <Stack
                h="100%"
                justify="flex-start"
                // style={{ border: '1px solid red' }}
              >
                <Button
                  leftSection={<IconChevronLeft size={16} />}
                  variant="subtle"
                  onClick={handleBackToList}
                >
                  Back to List
                </Button>
                {selectedHelpResource && (
                  <>
                    <h2>{selectedHelpResource.metadata?.title}</h2>
                    <HelpArticleRenderer helpResource={selectedHelpResource} />
                  </>
                )}
              </Stack>
            </div>
          )}
        </Transition>
      </Stack>
    </Stack>
  );
};

export default HelpSidebar;
