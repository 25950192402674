import {
  Card,
  Group,
  MantineStyleProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';

interface InfoCardProps extends Omit<MantineStyleProps, 'bg'> {
  text: string;
  bg?: MantineStyleProps['bg'];
}

export const InfoCard: React.FC<InfoCardProps> = ({ text, bg, ...others }) => {
  const theme = useMantineTheme();

  // Logic to handle the default background color
  const backgroundColor = bg || theme.colors.gray[1];

  return (
    <Card
      padding="md"
      withBorder={false}
      radius="md"
      bg={backgroundColor}
      {...others}
    >
      <Group justify="flex-start" align="flex-start" wrap="nowrap">
        <IconInfoCircle size={20} />
        <Text fz={14} fw={500}>
          {text}
        </Text>
      </Group>
    </Card>
  );
};
