/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type MessageStatusEnum =
  (typeof MessageStatusEnum)[keyof typeof MessageStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageStatusEnum = {
  draft: 'draft',
  pending: 'pending',
  sent: 'sent',
  failed: 'failed',
} as const;
