import { WorkflowTypeEnum } from '@btrway/api-workflow';
import {
  CommonWorkflow,
  useWorkflowService,
  WorkflowCommonProvider,
  WorkflowServiceProvider,
} from '@btrway/workflow-configuration-manager';
import { DeploymentButton } from '@btrway/workflow-template-deployment';
import { Box, Group, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { lazy, Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowDefinitionMetadataEdit } from '../../components/WorkflowDefinitionMetadataEdit/WorkflowDefinitionMetadataEdit';
import { WorkflowDefinitionMetadataView } from '../../components/WorkflowDefinitionMetadataView/WorkflowDefinitionMetadataView';

const ProcessBuilder = lazy(
  () => import('../../components/ProcessBuilder/ProcessBuilder')
);
const TaskListBuilder = lazy(
  () => import('../../components/TaskListBuilder/TaskListBuilder')
);
const CertificationBuilder = lazy(
  () => import('../../components/CertificationBuilder/CertificationBuilder')
);

export type WorkflowSourceType = 'definition' | 'template';

interface WorkflowBuilderPageProps {
  sourceType: WorkflowSourceType;
  workflowKey: string;
}

const getBackLinkText = (
  workflowType: WorkflowTypeEnum | undefined
): string => {
  switch (workflowType) {
    case WorkflowTypeEnum.form:
      return 'Back to Forms';
    case WorkflowTypeEnum.automation:
      return 'Back to Automations';
    case WorkflowTypeEnum.curriculum:
      return 'Back to Curriculums';
    case WorkflowTypeEnum.certification:
      return 'Back to Certifications';
    default:
      return 'Back';
  }
};

interface WorkflowBuilderContentProps {
  workflowKey: string;
}

const WorkflowBuilderContent: React.FC<WorkflowBuilderContentProps> = ({
  workflowKey,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const workflowService = useWorkflowService();
  const { data: workflow, isLoading } = workflowService.getByKey();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const handleSave = useCallback(async () => {
    try {
      console.log('To Do: Publish');
    } catch (error) {
      console.error('Failed to save workflows:', error);
    }
  }, []);

  const handleMetadataSave = useCallback(
    async (updatedWorkflow: CommonWorkflow) => {
      if (!workflow) return;

      await workflowService.updateWorkflow(updatedWorkflow);

      close();
    },
    [workflow] //, updateWorkflow]
  );

  const renderWorkflowBuilder = () => {
    if (!workflowKey || !workflow?.workflowType) {
      return null;
    }

    const builderProps = { workflowKey };

    switch (workflow.workflowType) {
      case WorkflowTypeEnum.formPacket:
      case WorkflowTypeEnum.taskList:
      case WorkflowTypeEnum.curriculum:
        return <TaskListBuilder {...builderProps} />;
      case WorkflowTypeEnum.certification:
        return <CertificationBuilder {...builderProps} />;
      default:
        return <ProcessBuilder {...builderProps} />;
    }
  };

  if (isLoading) {
    return <div>Loading workflow...</div>;
  }

  // if (error) {
  //   return <div>Error loading workflow: {error.message}</div>;
  // }

  if (!workflow) {
    return <div>No workflow found</div>;
  }

  const backLinkText = getBackLinkText(workflow.workflowType);
  const mainContentBackground = '#f0f0f0';

  return (
    <Stack h="100vh" gap={0}>
      <Group
        align="center"
        bg="dark.6"
        h={60}
        px="sm"
        style={{ position: 'relative' }}
      >
        <Box style={{ position: 'absolute', left: 16 }}>
          <div
            onClick={() => navigate(-1)}
            style={{ textDecoration: 'none', cursor: 'pointer' }}
          >
            <Group wrap="nowrap">
              <IconChevronLeft size={24} color="white" />
              <Text size="md" c="white" fw={600}>
                {backLinkText}
              </Text>
            </Group>
          </div>
        </Box>

        <Box style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          {workflowKey && (
            <WorkflowDefinitionMetadataView
              workflow={workflow}
              onEditClick={open}
            />
          )}
        </Box>

        <Box style={{ position: 'absolute', right: 16 }}>
          {/* <SaveButton variant="outline" bg="white" onSave={handleSave} disabled>
            Publish
          </SaveButton> */}
          <DeploymentButton workflow={workflow} />
        </Box>
      </Group>

      <Box
        style={{
          backgroundColor: mainContentBackground,
          display: 'flex',
          height: 'calc(100vh - 60px)',
          overflow: 'hidden',
        }}
      >
        <Box
          style={{
            flex: '1 1 100%',
            overflow: 'auto',
            transition: 'flex 0.3s ease',
          }}
        >
          <Suspense fallback={<div>Loading builder...</div>}>
            {renderWorkflowBuilder()}
          </Suspense>
        </Box>
      </Box>
      <WorkflowDefinitionMetadataEdit
        isOpen={opened}
        onClose={close}
        onSave={handleMetadataSave}
        initialData={workflow}
      />
    </Stack>
  );
};

// Main component that provides the service context
const WorkflowBuilderPage: React.FC<WorkflowBuilderPageProps> = ({
  sourceType,
  workflowKey,
}) => {
  return (
    <WorkflowCommonProvider sourceType={sourceType}>
      <WorkflowServiceProvider workflowKey={workflowKey}>
        <WorkflowBuilderContent workflowKey={workflowKey} />
      </WorkflowServiceProvider>
    </WorkflowCommonProvider>
  );
};

export default WorkflowBuilderPage;
