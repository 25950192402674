import { EventConfig, EventTypeEnum } from '@btrway/api-workflow';
import { EventTypeIcon } from '@btrway/workflow-components';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { Card, Group } from '@mantine/core';
import React from 'react';
import { EventConfigContent } from '../EventConfigContent/EventConfigContent';

interface EventConfigCardProps {
  event: EventConfig;
  workflow: CommonWorkflow;
  onClick: () => void;
  isSelected?: boolean;
}

export const EventConfigCard: React.FC<EventConfigCardProps> = ({
  event,
  workflow,
  onClick,
  isSelected,
}) => {
  return (
    <Card
      p="md"
      w="100%"
      radius="md"
      withBorder
      shadow="xs"
      onClick={onClick}
      style={{
        marginBottom: 0,
        cursor: 'pointer',
        borderColor: isSelected ? 'blue' : undefined,
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      <Group justify="space-between" gap="xs" wrap="nowrap">
        <Group gap="xs" wrap="nowrap" justify="flex-start" align="flex-start">
          <EventTypeIcon
            iconName={event.eventTypeCode as EventTypeEnum}
            size={24}
          />
          <EventConfigContent event={event} workflow={workflow} />
        </Group>
      </Group>
    </Card>
  );
};
