import { CertificationListView } from '@btrway/task-list-builder';
import { RightSectionProvider } from '@btrway/workflow-builder-rightsection';
import { SettingsDisplayProvider } from '@btrway/workflow-builder-settings-panel';
import { WorkflowBuilderStateProvider } from '@btrway/workflow-builder-state';
import { useWorkflowService } from '@btrway/workflow-configuration-manager';
import { Box, Container, ScrollArea } from '@mantine/core';
import React from 'react';

interface CertificationBuilderProps {
  workflowKey: string;
}

const CertificationBuilder: React.FC<CertificationBuilderProps> = ({
  workflowKey,
}) => {
  const workflowService = useWorkflowService();
  const { data: workflow } = workflowService.getByKey();

  return (
    <Box style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <RightSectionProvider>
        <SettingsDisplayProvider>
          <WorkflowBuilderStateProvider parentWorkflow={workflow}>
            <ScrollArea style={{ height: '100%', width: '100%' }}>
              <Container size="md" px="md" style={{ maxWidth: 800 }}>
                <CertificationListView
                  workflowKey={workflowKey}
                  onCertificationSelected={(certificationKey) => {
                    // Any additional certification selection handling if needed
                  }}
                  onCertificationDeleted={(certificationKey) => {
                    // Any additional certification deletion handling if needed
                  }}
                />
              </Container>
            </ScrollArea>
          </WorkflowBuilderStateProvider>
        </SettingsDisplayProvider>
      </RightSectionProvider>
    </Box>
  );
};

export default CertificationBuilder;
