import { WorkflowBuilderPage } from '@btrway/workflow-builder';
import React from 'react';
import { useParams } from 'react-router-dom';

const WorkflowDefinitionPage: React.FC = () => {
  const { workflowKey } = useParams<{
    workflowKey: string;
  }>();

  if (!workflowKey) {
    return null;
  }

  return (
    <WorkflowBuilderPage sourceType="definition" workflowKey={workflowKey} />
  );
};

export default WorkflowDefinitionPage;
