import {
  DateAssignedTypeEnum,
  DateDueTypeEnum,
  TaskConfig,
  TaskListDateMethodEnum,
  TaskListSettings,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useTaskHelpResources } from '@btrway/help-manager';
import { useWorkflowBuilderState } from '@btrway/workflow-builder-state';
import {
  CommonWorkflow,
  useSelectedItem,
  useWorkflowService,
  useWorkflowUpdater,
} from '@btrway/workflow-configuration-manager';
import { DeploymentFilterView } from '@btrway/workflow-template-deployment';
import {
  ActionIcon,
  Button,
  Center,
  Group,
  Stack,
  Tooltip,
} from '@mantine/core';
import { IconCalendar, IconEdit, IconPlus } from '@tabler/icons-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskSortType, useTaskGrouping } from '../../hooks/useTaskGrouping';
import EmptyTaskList from '../EmptyTaskList/EmptyTaskList';
import TaskGroup from '../TaskGroup/TaskGroup';

interface TaskListViewInnerProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  onTaskSelected: (taskKey: string) => void;
  onTaskDeleted: (taskKey: string) => void;
  nestedTaskList: boolean;
}

const TaskListViewInner: React.FC<TaskListViewInnerProps> = ({
  workflowKey,
  workflow,
  onTaskSelected,
  onTaskDeleted,
  nestedTaskList,
}) => {
  const { removeTask, updateConfig } = useWorkflowUpdater();
  const { setSelectedItem } = useSelectedItem();
  const [hasStartedFromScratch, setHasStartedFromScratch] = useState(false);
  const [localTasks, setLocalTasks] = useState<TaskConfig[]>([]);
  const [currentSort, setCurrentSort] = useState<TaskSortType>('date');
  const workflowService = useWorkflowService();
  const { openInitialScreen, openToolbox } = useWorkflowBuilderState();

  // Get unique task types for fetching help resources
  const uniqueTaskTypes = useMemo(
    () => Array.from(new Set(localTasks.map((task) => task.taskType))),
    [localTasks]
  );

  const { fetchHelpUsages } = useTaskHelpResources(
    workflowKey,
    uniqueTaskTypes
  );

  // Fetch help resources on mount and when tasks change
  useEffect(() => {
    fetchHelpUsages();
  }, [uniqueTaskTypes]);

  useEffect(() => {
    if (!workflow) return;

    // Set local tasks
    const workflowTasks = workflow.workflowConfiguration?.tasks ?? [];
    setLocalTasks(workflowTasks);
  }, [workflow]);

  const handleDelete = async (taskKey: string) => {
    try {
      await removeTask(taskKey);
      setLocalTasks((prev) => prev.filter((task) => task.taskKey !== taskKey));
      onTaskDeleted(taskKey);
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow?.workflowType) return;

    openToolbox(workflow, 'task', nestedTaskList ? workflowService : undefined);
  };

  const handleTaskClick = (task: TaskConfig) => {
    if (!workflow) return;

    setSelectedItem({ type: 'task', key: task.taskKey });

    openInitialScreen(
      {
        type: 'TASK_CONFIG',
        data: {
          config: task,
          workflow,
          isNew: false,
        },
      },
      undefined,
      nestedTaskList ? workflowService : null,
      nestedTaskList ? workflow : null
    );
    onTaskSelected(task.taskKey);
  };

  const handleConfigureDates = (e: React.MouseEvent) => {
    e.stopPropagation();

    // Create default settings if none exist
    const defaultSettings: TaskListSettings = {
      taskListDateMethod: TaskListDateMethodEnum.byStartDate,
      assignedDateType: DateAssignedTypeEnum.taskSpecific,
      dueDateType: DateDueTypeEnum.taskSpecific,
      assignedDateValue: 0,
      dueDateValue: 7,
      dayValue: 1,
      monthValue: 1,
    };

    // Get existing settings or use defaults
    const currentSettings =
      workflow?.workflowConfiguration?.settings?.taskListSettings ||
      defaultSettings;

    openInitialScreen(
      {
        type: 'TASK_LIST_DATE_CONFIG',
        data: {
          workflowKey,
          initialConfig: currentSettings,
        },
      },
      undefined,
      nestedTaskList ? workflowService : null,
      nestedTaskList ? workflow : null
    );
  };

  const handleEditWorkflow = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!workflow) return;

    const isFormPacket = workflow.workflowType === WorkflowTypeEnum.formPacket;

    openInitialScreen(
      {
        type: isFormPacket ? 'FORM_PACKET_EDITOR' : 'CURRICULUM_EDITOR',
        data: {
          initialSelectedTasks: workflow.workflowConfiguration?.tasks || [],
          workflowKey,
          workflowName: workflow.name || (isFormPacket ? 'Form Packet' : ''),
          showTaskConfigAfterSave: false,
        },
      },
      undefined,
      nestedTaskList ? workflowService : null,
      nestedTaskList ? workflow : null
    );
  };

  const handleNewTasks = async (tasks: TaskConfig[]) => {
    try {
      await updateConfig({
        workflowConfig: {
          tasks,
        },
      });
      setLocalTasks(tasks);
      setHasStartedFromScratch(true); // Set flag when user starts from scratch
    } catch (error) {
      console.error('Failed to update tasks:', error);
    }
  };

  const handleSkip = () => {
    setHasStartedFromScratch(true); // Set flag when user skips
    handleAddClick({ stopPropagation: () => {} } as React.MouseEvent);
  };

  const workflowType = workflow?.workflowType;
  const isFormPacket = workflowType === WorkflowTypeEnum.formPacket;
  const isCurriculum = workflowType === WorkflowTypeEnum.curriculum;
  const isTaskList = workflowType === WorkflowTypeEnum.taskList;
  const isTaskOption = workflowType === WorkflowTypeEnum.taskOption;
  const showDateSettings = workflowType === WorkflowTypeEnum.taskList;
  const shouldUseGrouping = isTaskList;

  const { groupedTasks } = useTaskGrouping(
    localTasks,
    shouldUseGrouping ? (isCurriculum ? 'date' : currentSort) : 'none'
  );

  const handleSort = (sortType: TaskSortType) => {
    setCurrentSort(currentSort === sortType ? 'none' : sortType);
  };

  if (!workflow) {
    return <Center>No workflow found</Center>;
  }

  // Show EmptyTaskList when there are no tasks and this is a task list
  if (groupedTasks.length === 0 && isTaskList) {
    return (
      <EmptyTaskList
        onBuild={handleNewTasks}
        onSkip={handleSkip}
        style={{ padding: '20px' }}
      />
    );
  }

  return (
    <Center>
      <Stack gap="xs" align="stretch" p="md" w="100%">
        {!nestedTaskList && <DeploymentFilterView workflow={workflow} />}
        <Group justify="space-between" w="100%">
          <Group gap="xs" justify="flex-start">
            {isTaskList && (
              <>
                <Button.Group>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'date' ? 'filled' : 'outline'}
                    onClick={() => handleSort('date')}
                  >
                    Date
                  </Button>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'type' ? 'filled' : 'outline'}
                    onClick={() => handleSort('type')}
                  >
                    Type
                  </Button>
                  <Button
                    size="xs"
                    color="blue"
                    variant={currentSort === 'category' ? 'filled' : 'outline'}
                    onClick={() => handleSort('category')}
                  >
                    Category
                  </Button>
                </Button.Group>
                <Button
                  size="xs"
                  variant="light"
                  color="blue"
                  leftSection={<IconCalendar size={16} />}
                  onClick={handleConfigureDates}
                >
                  Configure Assignment Dates
                </Button>
              </>
            )}
          </Group>

          <Group justify="flex-end" gap="xs">
            {isFormPacket || isCurriculum ? (
              <Button
                size="xs"
                variant="light"
                color="blue"
                leftSection={<IconEdit size={16} />}
                onClick={handleEditWorkflow}
              >
                Select {isFormPacket ? 'Forms' : 'Courses'}
              </Button>
            ) : (
              <Group gap="xs">
                <Tooltip label={`Add New ${isTaskOption ? 'Option' : 'Task'}`}>
                  <ActionIcon
                    variant="light"
                    color="blue"
                    size="md"
                    radius="xl"
                    onClick={handleAddClick}
                  >
                    <IconPlus size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            )}
          </Group>
        </Group>

        <Stack gap={0}>
          {localTasks.length === 0 && isTaskList && !hasStartedFromScratch ? (
            <EmptyTaskList
              onBuild={handleNewTasks}
              onSkip={handleSkip}
              style={{ padding: '20px' }}
            />
          ) : (
            groupedTasks.map(
              (group) =>
                workflowType && (
                  <TaskGroup
                    key={group.label}
                    label={group.label}
                    tasks={group.tasks}
                    workflow={workflow}
                    onTaskSelected={(taskKey) => {
                      const task = localTasks.find(
                        (t) => t.taskKey === taskKey
                      );
                      if (task) handleTaskClick(task);
                    }}
                    onTaskDeleted={handleDelete}
                  />
                )
            )
          )}
        </Stack>
      </Stack>
    </Center>
  );
};

// Outer wrapper component that provides context
interface TaskListViewProps {
  workflowKey: string;
  workflow?: CommonWorkflow | null;
  onTaskSelected: (taskKey: string) => void;
  onTaskDeleted: (taskKey: string) => void;
  nestedTaskList?: boolean;
}

const TaskListView: React.FC<TaskListViewProps> = ({
  workflowKey,
  workflow,
  onTaskSelected,
  onTaskDeleted,
  nestedTaskList = false,
}) => {
  const workflowService = useWorkflowService();
  const { data: fetchedWorkflow, isLoading } = workflow
    ? { data: workflow, isLoading: false }
    : workflowService.getByKey();

  if (!fetchedWorkflow || isLoading) {
    return null;
  }
  return (
    <TaskListViewInner
      workflowKey={workflowKey}
      workflow={fetchedWorkflow}
      onTaskSelected={onTaskSelected}
      onTaskDeleted={onTaskDeleted}
      nestedTaskList={nestedTaskList}
    />
  );
};

export default TaskListView;
