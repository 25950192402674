import { StepConfig, WorkflowStepInstanceResponse } from '@btrway/api-workflow';
import { EntityTag } from '@btrway/entity-tags';
import { formatDateTime } from '@btrway/utils';
import { Box, Divider, Grid, Group, Stack, Text } from '@mantine/core';
import React, { useMemo } from 'react';
import { Model, Question, QuestionMatrixDropdownModel } from 'survey-core';

interface WorkflowStepInstanceDetailProps {
  workflowStepInstance: WorkflowStepInstanceResponse;
  workflowStep?: StepConfig;
  isOnlyStep: boolean;
}

const WorkflowStepInstanceDetail: React.FC<WorkflowStepInstanceDetailProps> = ({
  workflowStepInstance,
  workflowStep,
  isOnlyStep,
}) => {
  const formData = useMemo(() => {
    return workflowStepInstance.formData;
  }, [workflowStepInstance.formData]);

  const surveyModel = useMemo(() => {
    if (workflowStep?.formConfig) {
      try {
        return new Model(workflowStep.formConfig);
      } catch (error) {
        console.error('Error creating survey model:', error);
        return null;
      }
    }
    return null;
  }, [workflowStep?.formConfig]);

  const renderFormData = () => {
    if (!formData || !surveyModel) return null;

    return surveyModel.getAllQuestions().map((question: Question) => {
      const value = formData[question.name];
      if (value === undefined) return null;

      return (
        <Grid key={question.name}>
          <Grid.Col span={6}>
            <Text fw={500}>{question.title || question.name}</Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text>{renderValue(question, value)}</Text>
          </Grid.Col>
        </Grid>
      );
    });
  };

  const renderValue = (question: Question, value: any) => {
    if (question.getType() === 'matrix') {
      return renderMatrixValue(question as QuestionMatrixDropdownModel, value);
    }
    if (Array.isArray(value)) {
      return value.join(', ');
    } else if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value);
    } else {
      return String(value);
    }
  };

  const renderMatrixValue = (
    question: QuestionMatrixDropdownModel,
    value: any
  ) => {
    if (!value) return '';
    return Object.entries(value).map(([row, rowValue]) => (
      <div key={row}>
        <strong>{row}:</strong> {JSON.stringify(rowValue)}
      </div>
    ));
  };

  const getLatestCompletedTask = () => {
    return workflowStepInstance.taskInstances
      .filter((task) => task.completed)
      .sort((a, b) => {
        const dateA = a.completedAt ? new Date(a.completedAt) : new Date(0);
        const dateB = b.completedAt ? new Date(b.completedAt) : new Date(0);
        return dateB.getTime() - dateA.getTime();
      })[0];
  };

  const latestCompletedTask = getLatestCompletedTask();

  return (
    <Box
      mih={500}
      style={{
        height: isOnlyStep ? '100%' : 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack justify="space-between" style={{ flex: 1 }}>
        <Stack gap="md">
          <Text size="xl" fw={700}>
            {workflowStep?.title}
          </Text>
          <Divider />
          {formData && <Stack gap="xs">{renderFormData()}</Stack>}
        </Stack>
        <Stack gap="md" mt="auto">
          <Divider />
          <Group justify="flex-end">
            <Text>
              Submitted at:{' '}
              {workflowStepInstance.endedAt
                ? formatDateTime(workflowStepInstance.endedAt)
                : 'Not submitted'}
              {workflowStepInstance.endedAt &&
                latestCompletedTask?.completedBy && (
                  <>
                    {' by '}
                    <EntityTag
                      dataType="person"
                      id={latestCompletedTask.completedBy}
                      allowClick={false}
                    />
                  </>
                )}
            </Text>
          </Group>
        </Stack>
      </Stack>
    </Box>
  );
};

export default WorkflowStepInstanceDetail;
