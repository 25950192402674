import { OutsideTaskDefinitionResponse } from '@btrway/api-workflow';
import { ActionIcon, Card, Group, Stack, Text } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';

interface OutsideTaskCardProps {
  task: OutsideTaskDefinitionResponse;
  onEdit: () => void;
}

export const OutsideTaskCard: React.FC<OutsideTaskCardProps> = ({
  task,
  onEdit,
}) => {
  return (
    <Card shadow="xs" padding="sm" radius="md">
      <Group>
        <Stack style={{ flex: 1 }}>
          <Text size="md" fw={500}>
            {task.name}
          </Text>
          {task.description && (
            <Text size="sm" c="dimmed" lineClamp={1}>
              {task.description}
            </Text>
          )}
          {task.url && (
            <Text size="sm" c="dimmed" lineClamp={1}>
              {task.url}
            </Text>
          )}
        </Stack>
        <ActionIcon size="xs" variant="subtle" onClick={onEdit}>
          <IconEdit />
        </ActionIcon>
      </Group>
    </Card>
  );
};
