import { ComponentCollection, SurveyModel } from 'survey-core';

// Define the structure of the Person entity
export interface PersonEntity {
  firstName?: string;
  lastName?: string;
  email: string;
  mobilePhone?: string;
  address?: {
    street?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
  birthDate?: string;
  homePhone?: string;
  workPhone?: string;
  organizationId: number;
}

ComponentCollection.Instance.add(
  {
    name: 'person',
    title: 'Person',
    elementsJSON: [
      {
        type: 'panel',
        name: 'person',
        // title: "Personal Info",
        elements: [
          {
            type: 'text',
            name: 'firstName',
            title: 'First Name',
            placeholder: 'John',
            isRequired: true,
          },
          {
            type: 'text',
            name: 'lastName',
            startWithNewLine: false,
            title: 'Last Name',
            placeholder: 'Smith',
            isRequired: true,
          },
          {
            type: 'text',
            inputType: 'email',
            name: 'email',
            title: 'Email',
            placeholder: 'johnsmith@example.com',
            isRequired: true,
          },
          {
            type: 'text',
            inputType: 'tel',
            name: 'mobilePhone',
            startWithNewLine: false,
            title: 'Mobile Phone Number',
            placeholder: '(555)-555-5555',
          },
          {
            type: 'text',
            inputType: 'tel',
            name: 'homePhone',
            title: 'Home Phone Number',
            placeholder: '(555)-555-5555',
          },
          {
            type: 'text',
            inputType: 'tel',
            name: 'workPhone',
            startWithNewLine: false,
            title: 'Work Phone Number',
            placeholder: '(555)-555-5555',
          },
          {
            type: 'text',
            inputType: 'date',
            name: 'birthDate',
            title: 'Date of Birth',
          },
          {
            type: 'address',
            name: 'address',
            title: 'Address',
          },
        ],
      },
    ],
    defaultQuestionTitle: 'Personal Info',
  }
  // onInit() {
  //     const question = this.valueFromQuestion as any;
  //     question.name = "person";
  // }
);

// Function to map survey data to PersonEntity
export function mapSurveyDataToPerson(surveyData: any): PersonEntity {
  return {
    firstName: surveyData.person.firstName,
    lastName: surveyData.person.lastName,
    email: surveyData.person.email,
    mobilePhone: surveyData.person.mobilePhone,
    homePhone: surveyData.person.homePhone,
    workPhone: surveyData.person.workPhone,
    birthDate: surveyData.person.birthDate,
    address: surveyData.person.address,
    organizationId: Number(surveyData.person.organizationId),
  };
}
