import { EventRequest } from '@btrway/api-calendar';
import { Button, Card, Group, Paper, Stack, Text } from '@mantine/core';
import { IconCalendar } from '@tabler/icons-react';
import { useCallback, useMemo, useState } from 'react';
import { getDefaultStartEndTimes } from '../../utils/newAvailabilityEvent';
import FacilityAvailabilityEdit from '../FacilityAvailabilityEdit/FacilityAvailabilityEdit';
import { FacilityAvailabilityItem } from '../FacilityAvailabilityItem/FacilityAvailabilityItem';

interface FacilityAvailabilityListProps {
  facilityId: number;
  workgroupId: number;
  userTimezone: string;
  availabilityEvents: EventRequest[];
  onAdd: (event: EventRequest) => void;
  onEdit: (event: EventRequest) => void;
  onDelete: (event: EventRequest) => void;
}

export function FacilityAvailabilityList({
  facilityId,
  workgroupId,
  userTimezone,
  availabilityEvents,
  onAdd,
  onEdit,
  onDelete,
}: FacilityAvailabilityListProps) {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [pendingEvent, setPendingEvent] = useState<EventRequest | null>(null);

  const activeEvents = useMemo(() => {
    const filtered = availabilityEvents.filter(
      (event) => event.active !== false
    );
    return filtered;
  }, [availabilityEvents]);

  const handleAddSave = useCallback(
    (event: EventRequest) => {
      onAdd(event);
      setPendingEvent(null);
      setIsAddingNew(false);
    },
    [onAdd]
  );

  const handleAddCancel = useCallback(() => {
    setPendingEvent(null);
    setIsAddingNew(false);
  }, []);

  const handleAddClick = useCallback(() => {
    const { startTime, endTime } = getDefaultStartEndTimes();
    const newEvent: EventRequest = {
      name: 'Available',
      startTime,
      endTime,
      allDayEvent: false,
      isPublic: true,
    } as EventRequest;
    setPendingEvent(newEvent);
    setIsAddingNew(true);
    console.log('handleAddClick');
  }, []);

  const handleEdit = useCallback(
    (event: EventRequest) => {
      onEdit(event);
    },
    [onEdit]
  );

  const handleDelete = useCallback(
    (event: EventRequest) => {
      onDelete(event);
    },
    [onDelete]
  );

  return (
    <Stack gap="md">
      <Group justify="space-between">
        <Text size="sm" fw={500}>
          Standard Availability Hours
        </Text>
        <Button
          variant="light"
          leftSection={<IconCalendar size={16} />}
          onClick={handleAddClick}
        >
          Add Hours
        </Button>
      </Group>

      {isAddingNew && pendingEvent && (
        <Paper withBorder p="sm">
          <FacilityAvailabilityEdit
            facilityId={facilityId}
            workgroupId={workgroupId}
            initialEvent={pendingEvent}
            onSave={handleAddSave}
            onCancel={handleAddCancel}
            userTimezone={userTimezone}
          />
        </Paper>
      )}

      {!isAddingNew && activeEvents.length === 0 ? (
        <Card withBorder padding="lg">
          <Text c="dimmed" ta="center" fz="sm">
            No Standard Availability Hours defined for this facility
          </Text>
        </Card>
      ) : (
        activeEvents.map((event) => (
          <FacilityAvailabilityItem
            key={event.id || Math.random()}
            event={event}
            facilityId={facilityId}
            workgroupId={workgroupId}
            userTimezone={userTimezone}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        ))
      )}
    </Stack>
  );
}
