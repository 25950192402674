import { EventResponse } from '@btrway/api-calendar';
import { EventContentArg } from '@fullcalendar/core';
import { Group, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import classes from './EventContent.module.css';

interface CustomEventContentProps {
  timeText: string;
  event: EventContentArg['event'] & {
    extendedProps: EventResponse;
  };
  view: EventContentArg['view'];
  backgroundColor: string;
  textColor: string;
  isInMonthView: boolean;
}

const ColorDot = ({ color }: { color: string }) => (
  <div
    style={{
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: color,
      flexShrink: 0,
    }}
  />
);

const formatTime = (date: Date): string => {
  const minutes = date.getMinutes();
  const format = minutes === 0 ? 'ha' : 'h:mma';
  return dayjs(date).format(format).toLowerCase();
};

const formatTimeRangeForWeekView = (start: Date, end: Date): string => {
  const startMinutes = start.getMinutes();
  const endMinutes = end.getMinutes();
  const startFormat = startMinutes === 0 ? 'h' : 'h:mm';
  const endFormat = endMinutes === 0 ? 'h' : 'h:mm';

  const startTime = dayjs(start).format(startFormat);
  const endTime = dayjs(end).format(endFormat);
  const endPeriod = dayjs(end).format('a').toLowerCase();

  if (dayjs(start).format('a') === endPeriod) {
    return `${startTime} - ${endTime}${endPeriod}`;
  }

  const startPeriod = dayjs(start).format('a').toLowerCase();
  return `${startTime}${startPeriod} - ${endTime}${endPeriod}`;
};

const EventContent: React.FC<CustomEventContentProps> = ({
  event,
  timeText,
  isInMonthView,
  backgroundColor,
}) => {
  const startDate = event.start;
  const endDate = event.end;
  const isAvailabilityEvent =
    event.extendedProps.eventType?.systemType === 'availability';

  // Return empty content for background/availability events
  if (isAvailabilityEvent) {
    return null;
  }

  if (isInMonthView) {
    return (
      <div className={classes.monthViewEvent}>
        <Group
          gap={4}
          className={classes.monthViewEventGroup}
          wrap="nowrap"
          align="center"
        >
          <ColorDot color={backgroundColor} />
          <Text fz="sm" c="dark.4" className={classes.monthViewEventTime}>
            {startDate && formatTime(startDate)}
          </Text>
          <Text
            fz="sm"
            fw={500}
            c="dark.9"
            className={classes.monthViewEventTitle}
            lineClamp={1}
          >
            {event.title}
          </Text>
        </Group>
      </div>
    );
  }

  // Week/Day view rendering
  return (
    <Stack
      gap={0}
      className="event-content week-view"
      style={{
        backgroundColor,
        color: 'white',
        height: '100%',
        padding: '2px 6px',
        borderRadius: '4px',
      }}
    >
      <Text
        fz="sm"
        fw={600}
        className="event-title"
        style={{
          color: 'inherit',
        }}
      >
        {event.title}
      </Text>
      {startDate && endDate && (
        <Text
          size="sm"
          className="event-time"
          style={{
            opacity: 0.9,
            color: 'inherit',
          }}
        >
          {formatTimeRangeForWeekView(startDate, endDate)}
        </Text>
      )}
    </Stack>
  );
};

export const renderEventContent = (eventInfo: EventContentArg) => {
  const isInMonthView = eventInfo.view.type === 'dayGridMonth';
  const event = eventInfo.event as EventContentArg['event'] & {
    extendedProps: EventResponse;
  };

  return (
    <EventContent
      event={event}
      timeText={eventInfo.timeText}
      view={eventInfo.view}
      backgroundColor={eventInfo.backgroundColor}
      textColor={eventInfo.textColor}
      isInMonthView={isInMonthView}
    />
  );
};
