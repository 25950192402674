import { useGetRegistrationFormsByWorkgroup } from '@btrway/api-core';

export function useRegistrationFormAdministration(workgroupId: number) {
  const {
    data: forms,
    isLoading,
    refetch: refetchForms,
  } = useGetRegistrationFormsByWorkgroup(workgroupId);

  return {
    forms: forms || [],
    isLoading,
    refetchForms,
  };
}
