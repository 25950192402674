import { getClient } from '@btrway/api-client-provider';
import { NewUserRequest, useAuthContext } from '@btrway/auth-core';
import { useCurrentUser } from '@btrway/current-user';
import { useMemo } from 'react';

export const useRegistrationAuth = () => {
  const { authState, login, revokeToken } = useAuthContext();
  const { currentUser } = useCurrentUser();
  const apiUrl = authState.apiUrl;
  const axios = getClient();

  const registrationLoginWithCredentials = async (credentials: {
    email: string;
    password: string;
  }): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const response = await axios.post('/auth/login', credentials);
      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, error: 'Error logging into API' };
      }
    } catch (error) {
      console.error('Login error: ', error);
      return { success: false, error: 'Login error: ' + error };
    }
  };

  const registrationLoginWithOAuth = async (
    idpHint?: string
  ): Promise<void> => {
    const clientId = 'btrway-client';
    const redirectUri = encodeURIComponent(
      `${window.location.origin}/register/callback`
    );
    console.log(redirectUri);
    sessionStorage.setItem('redirect_uri', redirectUri);

    // Store the current path so we can redirect back after login
    const returnTo = window.location.pathname;
    sessionStorage.setItem('returnTo', returnTo);

    // Add state parameter for security
    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('oauth_state', state);

    // Build authorization URL
    let authUrl =
      `http://localhost:8080/realms/btrwayapp/protocol/openid-connect/auth?` +
      `client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent('openid email profile')}` +
      `&state=${state}`;

    if (idpHint) {
      authUrl += `&kc_idp_hint=${idpHint}`;
    }

    window.location.href = authUrl;
  };

  const registrationCreateNewUser = async (
    newUserRequest: NewUserRequest
  ): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const response = await axios.post(
        `${apiUrl}/auth/new-user`,
        newUserRequest
      );

      if (response.status === 200) {
        return { success: true };
      } else {
        return { success: false, error: 'Error creating new ' };
      }
    } catch (error) {
      console.error('Error creating new user: ', error);
      return { success: false, error: 'Error creating new user: ' + error };
    }
  };

  const getTempToken = async (): Promise<{
    success: boolean;
    error?: string;
  }> => {
    try {
      const tempTokenUrl = `${apiUrl}/auth/get-temp-token`;
      const response = await fetch(tempTokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      console.log(response);
      if (response.ok) {
        return { success: true };
      } else {
        return { success: false, error: 'Error fetching temp token' };
      }
    } catch (error) {
      console.error(error);
      return { success: false, error: 'Error fetching temp token: ' + error };
    }
  };

  const clearTempToken = async () => {
    revokeToken('temp_token');
  };

  return useMemo(
    () => ({
      registrationLoginWithCredentials,
      registrationLoginWithOAuth,
      registrationCreateNewUser,
      getTempToken,
      clearTempToken,
    }),
    []
  );
};
