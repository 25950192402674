import { getPresignedUrl, StorageUploadRequest, StorageUsageEnum, useGetPresignedUrl } from "@btrway/api-storage";
import { useAuthenticatedUser } from "@btrway/current-user";
import { useStorageUpload } from "@btrway/file-storage-components";
import { ActionIcon, Box, Button, FileButton, Group, Paper, Stack, Text } from "@mantine/core";
import { IconPaperclip, IconTrash } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

interface CalendarEventFileUploadProps {
    initialStorageKeys: string[];
    onStorageKeysChange: (storagekeys: string[]) => void;
}

export const CalendarEventFileUpload: React.FC<CalendarEventFileUploadProps> = ({
    initialStorageKeys, 
    onStorageKeysChange,
}) => {
    const [uploading, setUploading] = useState(false);
    const [keysAndUrls, setKeysAndUrls] = useState<{[key: string]: string}>({});
    const [storageKeys, setStorageKeys] = useState<string[]>([]);
    const [presignedUrls, setPresignedUrls] = useState<string[]>([]);
    const {storageUpload} = useStorageUpload();
    const { currentUser, currentOrganization } = useAuthenticatedUser();

    useEffect(() => {
        if(initialStorageKeys) {
            setStorageKeys(initialStorageKeys);
            getPresignedUrls(initialStorageKeys);
        }
    }, [initialStorageKeys]);
   

    const handleFileUpload = async(files: File[]) => {
        setUploading(true);
        try {
            const storageUploadRequest: StorageUploadRequest = {
                organizationId: currentOrganization.id,
                userId: currentUser.user.id,
                fileNames: files.map(file => file.name),
                storageUsageEnum: StorageUsageEnum.event,
            };
     
            const res = await storageUpload(storageUploadRequest, files.map(file => ({
                file: file,
                contentType: file.type,
                fileName: file.name
            })));
     
            const updatedKeys = [...storageKeys, ...res.storageKeys];
            setStorageKeys(updatedKeys);
            getPresignedUrls(updatedKeys);
            onStorageKeysChange(updatedKeys);
        } finally {
            setUploading(false);
        }
    }

    const handleRemoveFile = (storageKeyToRemove: string) => {
        const updatedKeys = storageKeys.filter(key => key !== storageKeyToRemove);
        const updatedUrls = {...keysAndUrls};
        delete updatedUrls[storageKeyToRemove];
        
        setStorageKeys(updatedKeys);
        setKeysAndUrls(updatedUrls);
        onStorageKeysChange(updatedKeys);
    };

    const getPresignedUrls = async (keys: string[]) => {
        const urlMap: {[key: string]: string} = {};
        for(const key of keys) {
            const presignedUrl = await getPresignedUrl(StorageUsageEnum.event, {
                storageKey: key,
            }) as string;
            urlMap[key] = presignedUrl;
        }
        setKeysAndUrls(urlMap);
    }

    return (
        <Box>
          <Stack>
            <Group p="left">
              <FileButton onChange={handleFileUpload} accept="*/*" multiple>
                {(props) => (
                  <Button {...props} leftSection={<IconPaperclip size={16} />} loading={uploading}>
                    Attach Files
                  </Button>
                )}
              </FileButton>
            </Group>
    
            {storageKeys.length > 0 && (
                <Paper p="xs" withBorder>
                    <Stack>
                        {storageKeys.map((key) => (
                            <Group key={key} p="apart">
                                <a 
                                    href={keysAndUrls[key]} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Text size="sm" c="blue">
                                        {key.split('/').pop()}
                                    </Text>
                                </a>
                                <ActionIcon
                                    color="red"
                                    onClick={() => handleRemoveFile(key)}
                                >
                                    <IconTrash size={16} />
                                </ActionIcon>
                            </Group>
                        ))}
                    </Stack>
                </Paper>
                )}
          </Stack>
        </Box>
    );
};

export default CalendarEventFileUpload;