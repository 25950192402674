import { useAPIClient } from '@btrway/api-client-provider';
import { getGetCurrentUserQueryKey, useGetCurrentUser } from '@btrway/api-core';
import { useAuthContext } from '@btrway/auth-core';
import { useMemo } from 'react';

export function useCurrentUserQuery() {
  const { authState } = useAuthContext();
  const { queryClient } = useAPIClient();

  const query = useGetCurrentUser({
    query: {
      enabled: authState.status === 'authenticated',
      queryKey: getGetCurrentUserQueryKey(),
      select: (data) => {
        if (!data) {
          throw new Error('Current user data is undefined');
        }
        return data;
      },
      staleTime: 5 * 60 * 1000,
      retry: (failureCount, error) => {
        if (
          error instanceof Error &&
          'status' in error &&
          (error.status === 401 || error.status === 403)
        ) {
          return false;
        }
        return failureCount < 3;
      },
    },
  });

  const rootWorkgroup = useMemo(
    () =>
      query.data?.workgroupMembers.find(
        (member) => member.workgroup.id === query.data?.rootWorkgroupId
      )?.workgroup,
    [query.data]
  );

  const allPermissions = useMemo(
    () =>
      query.data
        ? new Set<string>(
            query.data.workgroupMembers.flatMap(
              (member) => member.permissionCodes
            )
          )
        : new Set<string>(),
    [query.data]
  );

  return {
    ...query,
    rootWorkgroup,
    allPermissions,
  };
}
