import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useWorkgroup } from '@btrway/workgroup-components';
import { Group } from '@mantine/core';
import {
  IconAward,
  IconBulb,
  IconListCheck,
  IconWand,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router';
import { useWorkgroupStats } from '../../providers/WorkgroupStatsProvider';
import { TaskStatusCard } from '../TaskStatusCard/TaskStatusCard';

export const WorkgroupDashboardCards: React.FC = () => {
  const navigate = useNavigate();
  const { workgroup } = useWorkgroup();
  const { stats, getStatsByWorkflowType } = useWorkgroupStats();

  if (!stats?.[0]) {
    return null;
  }

  const getTypeStats = (type: WorkflowTypeEnum) => {
    const filteredStats = getStatsByWorkflowType(type);
    if (!filteredStats?.length) {
      return {
        completedTasks: 0,
        completedTaskLists: 0,
        totalTasks: 0,
        totalTaskLists: 0,
        progress: 0,
      };
    }

    // Sum up totals across all workgroups
    const totalStats = filteredStats.reduce(
      (acc, workgroup) => ({
        completedTasks: acc.completedTasks + (workgroup.completedTasks ?? 0),
        completedTaskLists:
          acc.completedTaskLists + (workgroup.completedTaskLists ?? 0),
        totalTasks: acc.totalTasks + (workgroup.totalTasks ?? 0),
        totalTaskLists: acc.totalTaskLists + (workgroup.totalTaskLists ?? 0),
      }),
      {
        completedTasks: 0,
        completedTaskLists: 0,
        totalTasks: 0,
        totalTaskLists: 0,
      }
    );

    // Calculate overall progress
    const progress =
      totalStats.totalTasks > 0
        ? (totalStats.completedTasks / totalStats.totalTasks) * 100
        : 0;

    return {
      ...totalStats,
      progress,
    };
  };

  const certificationStats = getTypeStats(WorkflowTypeEnum.certification);
  const todoStats = getTypeStats(WorkflowTypeEnum.taskList);
  const curriculumStats = getTypeStats(WorkflowTypeEnum.curriculum);

  console.log('WorkgroupDashboardCards: todoStats', JSON.stringify(todoStats));

  return (
    <Group gap="md">
      <TaskStatusCard
        title="Certifications"
        backgroundColor="#b5e5cf"
        icon={IconAward}
        details={[
          `${certificationStats.completedTaskLists} of ${certificationStats.totalTaskLists} Lists Complete`,
          `${certificationStats.completedTasks} of ${certificationStats.totalTasks} Tasks Complete`,
          `${
            certificationStats.totalTasks - certificationStats.completedTasks
          } Tasks Left`,
        ]}
        progress={certificationStats.progress}
        onClick={() =>
          navigate(`/app/oversight/${workgroup.id}/certifications`)
        }
      />
      <TaskStatusCard
        title="To-Dos"
        backgroundColor="#c3e0e5"
        icon={IconListCheck}
        details={[
          `${todoStats.completedTaskLists} of ${todoStats.totalTaskLists} Lists Complete`,
          `${todoStats.completedTasks} of ${todoStats.totalTasks} Tasks Complete`,
          `${todoStats.totalTasks - todoStats.completedTasks} Tasks Left`,
        ]}
        progress={todoStats.progress}
        onClick={() => navigate(`/app/oversight/${workgroup.id}/todos`)}
      />
      <TaskStatusCard
        title="Curriculums"
        backgroundColor="#ece3f0"
        icon={IconBulb}
        details={[
          `${curriculumStats.completedTaskLists} of ${curriculumStats.totalTaskLists} Courses Complete`,
          `${curriculumStats.completedTasks} of ${curriculumStats.totalTasks} Tasks Complete`,
          `${
            curriculumStats.totalTasks - curriculumStats.completedTasks
          } Tasks Left`,
        ]}
        progress={curriculumStats.progress}
        onClick={() => navigate(`/app/oversight/${workgroup.id}/curriculums`)}
      />
      <TaskStatusCard
        title="Approvals"
        backgroundColor="red.2"
        icon={IconWand}
        details={[
          `${curriculumStats.completedTaskLists} of ${curriculumStats.totalTaskLists} Forms Submitted`,
          `${curriculumStats.completedTasks} of ${curriculumStats.totalTasks} Approvals Completed`,
        ]}
        progress={curriculumStats.progress}
        onClick={() => navigate(`/app/oversight/${workgroup.id}/curriculums`)}
      />
    </Group>
  );
};
