/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type MessageDeliveryTypeEnum =
  (typeof MessageDeliveryTypeEnum)[keyof typeof MessageDeliveryTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MessageDeliveryTypeEnum = {
  email: 'email',
  sms: 'sms',
  push: 'push',
} as const;
