import { WorkgroupResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { WorkgroupSelector } from '@btrway/shared-components';
import { Badge, Grid, Group, Text } from '@mantine/core';
import React from 'react';
import { useFuzzyWorkgroupMapping } from '../../hooks/useFuzzyWorkgroupMapping';
import { useImportConfiguration } from '../../hooks/useImportConfiguration';

const scoreToPercentage = (score: number): number => {
  return Math.round(score * 100);
};

export const WorkgroupMappingStep: React.FC = () => {
  const { currentOrganization } = useAuthenticatedUser();
  const { importConfiguration } = useImportConfiguration();
  const { updateWorkgroupMapping, workgroups, fuse } = useFuzzyWorkgroupMapping(
    currentOrganization.id
  );

  const workgroupMapping = importConfiguration.columnMappings.find(
    (mapping) => mapping.targetField === 'rootWorkgroup'
  );

  const handleWorkgroupSelect = (
    originalWorkgroup: string,
    selectedWorkgroup: WorkgroupResponse
  ) => {
    updateWorkgroupMapping(originalWorkgroup, selectedWorkgroup);
  };

  if (!currentOrganization) {
    return <Text>Error: Current organization not found.</Text>;
  }

  if (!workgroupMapping) {
    return <Text>Error: Workgroup mapping not found.</Text>;
  }

  return (
    <Grid>
      {workgroupMapping.valueMappings.map((mapping) => (
        <React.Fragment key={mapping.sourceValue}>
          <Grid.Col span={6}>
            <Group justify="flex-start" mb="xs">
              <Text>{mapping.sourceValue}</Text>
              {mapping.autoMatched && (
                <Group gap="xs">
                  <Badge
                    color={
                      (mapping.autoMatchedValue || 0) > 0.8 ? 'green' : 'yellow'
                    }
                  >
                    Auto Match:{' '}
                    {scoreToPercentage(mapping.autoMatchedValue || 0)}%
                  </Badge>
                </Group>
              )}
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <WorkgroupSelector
              organization={currentOrganization}
              workgroup={workgroups.find(
                (w) => w.id.toString() === mapping.targetValue
              )}
              suggestedWorkgroup={fuse.search(mapping.sourceValue)[0]?.item}
              onSelect={(selected) =>
                handleWorkgroupSelect(mapping.sourceValue, selected)
              }
            />
          </Grid.Col>
        </React.Fragment>
      ))}
    </Grid>
  );
};
