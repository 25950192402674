import {
  TaskProperties,
  WorkflowConfig,
  useGetWorkflowDefinitionById,
} from '@btrway/api-workflow';
import { Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import FormSubmissionView from '../FormSubmissionView/FormSubmissionView';

interface FormSubmissionProps {
  workflowDefinitionId: number;
  parentTaskListId?: number;
  taskProperties?: TaskProperties;
  onClose: () => void;
  onSubmitSuccess?: () => Promise<void>;
}

const FormSubmission: React.FC<FormSubmissionProps> = ({
  workflowDefinitionId,
  parentTaskListId,
  taskProperties,
  onClose,
  onSubmitSuccess,
}) => {
  const { data: fetchedWorkflowDefinition, isLoading } =
    useGetWorkflowDefinitionById(workflowDefinitionId);
  const [workflowConfiguration, setWorkflowConfiguration] =
    useState<WorkflowConfig | null>(null);

  useEffect(() => {
    if (fetchedWorkflowDefinition?.workflowConfiguration && !isLoading) {
      setWorkflowConfiguration(fetchedWorkflowDefinition.workflowConfiguration);
    }
  }, [fetchedWorkflowDefinition, isLoading]);

  if (!fetchedWorkflowDefinition) {
    return null;
  }

  if (isLoading || !workflowConfiguration) {
    return <Loader style={{ margin: 'auto' }} />;
  }

  return (
    <FormSubmissionView
      workflowConfiguration={workflowConfiguration}
      workflowDefinitionId={workflowDefinitionId}
      workflowDefinition={fetchedWorkflowDefinition}
      parentTaskListId={parentTaskListId}
      taskProperties={taskProperties}
      onClose={onClose}
      onSubmitSuccess={onSubmitSuccess}
    />
  );
};

export default FormSubmission;
