import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  GetRowIdFunc,
  ModuleRegistry,
  ValueGetterParams,
} from '@ag-grid-community/core';

import { useGetOrganizations } from '@btrway/api-core';
import {
  useGetWorkflowDefinitionsByTemplateId,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { ResponsiveGrid } from '@btrway/grid-components';
import { formatDateTime } from '@btrway/utils';
import React, { useMemo } from 'react';
import { useWorkflowTemplate } from '../../providers/WorkflowTemplateProvider';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const TemplateOrganizationsGrid: React.FC = () => {
  const { workflowTemplate } = useWorkflowTemplate();

  const { data: workflowDefinitions } = useGetWorkflowDefinitionsByTemplateId(
    workflowTemplate.id
  );
  const { data: organizations } = useGetOrganizations();

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Organization',
        field: 'organizationId',
        valueGetter: (params: ValueGetterParams) => {
          const org = organizations?.find(
            (o) => o.id === params.data.organizationId
          );
          return org ? org.name : params.data.organizationId;
        },
        sort: 'asc',
      },
      {
        headerName: 'Workflow Definition',
        field: 'name',
      },
      {
        headerName: 'Definition ID',
        field: 'id',
      },
      {
        headerName: 'Created At',
        field: 'base.createdAt',
        valueFormatter: (params) => formatDateTime(params.value),
      },
    ],
    [organizations]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      resizable: true,
      sortable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const onGridReady = (params: { api: any }) => {
    params.api.sizeColumnsToFit();
  };

  const getRowId: GetRowIdFunc<WorkflowDefinitionResponse> = (params) => {
    return params.data.id.toString();
  };

  return (
    <ResponsiveGrid
      columnDefs={columnDefs}
      rowData={workflowDefinitions}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      getRowId={getRowId}
      rowModelType="clientSide"
      enableRangeSelection={true}
      primaryNavigationField="organizationId"
      responsiveRowSpacing={10}
    />
  );
};

export default TemplateOrganizationsGrid;
