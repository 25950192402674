/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type DataTypeEnum = typeof DataTypeEnum[keyof typeof DataTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataTypeEnum = {
  text: 'text',
  number: 'number',
  integer: 'integer',
  email: 'email',
  phone: 'phone',
  boolean: 'boolean',
  date: 'date',
  dateTime: 'dateTime',
  time: 'time',
  location: 'location',
  stateProvince: 'stateProvince',
  country: 'country',
  postalcode: 'postalcode',
  systemId: 'systemId',
  systemDateTime: 'systemDateTime',
  organization: 'organization',
  workgroup: 'workgroup',
  workgroupMember: 'workgroupMember',
  person: 'person',
  calendarEvent: 'calendarEvent',
  document: 'document',
  curriculum: 'curriculum',
  course: 'course',
  chapter: 'chapter',
  taskInstance: 'taskInstance',
  workflowDefinition: 'workflowDefinition',
  workflowInstance: 'workflowInstance',
  userRole: 'userRole',
  sentEmail: 'sentEmail',
  importJob: 'importJob',
  registration: 'registration',
  entityGroup: 'entityGroup',
  facility: 'facility',
  missing_enum_value: 'missing enum value',
} as const;
