import { StyledModal } from '@btrway/styled-modal';
import { Box } from '@mantine/core';
import React, { useCallback } from 'react';
import { useHelpResourceEditor } from '../../hooks/useHelpResourceEditor';
import { HelpResourceEditor } from '../HelpResourceEditor/HelpResourceEditor';

interface HelpResourceEditorModalProps {
  title: string;
}

const HelpResourceEditorModal: React.FC<HelpResourceEditorModalProps> = ({
  title,
}) => {
  const {
    isOpen,
    helpResourceId,
    repository,
    onAfterSave,
    closeHelpResourceEditor,
  } = useHelpResourceEditor();

  const handleSave = useCallback(() => {
    console.log('HelpResourceEditModal handleSave');
    if (onAfterSave) {
      console.log('calling onAfterSave');
      onAfterSave();
    }
    closeHelpResourceEditor();
  }, [onAfterSave, closeHelpResourceEditor]);

  return (
    <StyledModal
      opened={isOpen}
      onClose={closeHelpResourceEditor}
      title={title}
      variant="xl"
    >
      <Box p="lg">
        {repository && (
          <HelpResourceEditor
            helpResourceId={helpResourceId}
            repository={repository}
            onSave={handleSave}
            onCancel={closeHelpResourceEditor}
          />
        )}
      </Box>
    </StyledModal>
  );
};

export default HelpResourceEditorModal;
