/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskTypeEnum = typeof TaskTypeEnum[keyof typeof TaskTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskTypeEnum = {
  addtoWorkgroup: 'addtoWorkgroup',
  certification: 'certification',
  completeCourse: 'completeCourse',
  completeCurriculum: 'completeCurriculum',
  completeFormPacket: 'completeFormPacket',
  completeTask: 'completeTask',
  completeTaskList: 'completeTaskList',
  completeTaskOption: 'completeTaskOption',
  completeWorkflowStep: 'completeWorkflowStep',
  createCalendarEvent: 'createCalendarEvent',
  sendEmail: 'sendEmail',
  submitForm: 'submitForm',
  uploadFile: 'uploadFile',
  thirdParty: 'thirdParty',
  chooseTask: 'chooseTask',
} as const;
