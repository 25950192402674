/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type DateAssignedTypeEnum = typeof DateAssignedTypeEnum[keyof typeof DateAssignedTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateAssignedTypeEnum = {
  immediate: 'immediate',
  incremental: 'incremental',
  taskSpecific: 'taskSpecific',
} as const;
