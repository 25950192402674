// hooks/useEmailTemplates.ts
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { EmailTemplateRequest, EmailTemplateResponse } from '@btrway/api-messaging';
import { generateTemplateHtml, TEMPLATE_REGISTRY } from '../components/EmailTemplateRegistry/EmailTemplateRegistry';
import { useAuthContext } from '@btrway/auth-core';

export const useEmailTemplates = () => {
  const queryClient = useQueryClient();
  const {authState} = useAuthContext();
  const apiUrl = authState.apiUrl;

  // Query to fetch all templates
  const { data: templates = [], isLoading } = useQuery<EmailTemplateResponse[]>({
    queryKey: ['emailTemplates'],
    queryFn: async () => {
      const response = await fetch(`${apiUrl}/api/messaging/email-template`, {
        credentials: 'include'
      });
      return response.json();
    },
  });

  // Mutation to create template
  const { mutateAsync: createTemplate } = useMutation({
    mutationFn: async ({ 
      templateName, 
      templateHtml, 
      published = false 
    }: { 
      templateName: string; 
      templateHtml: string; 
      published?: boolean;
    }) => {
      const request: EmailTemplateRequest = {
        templateHtml: templateHtml,
        templateName: templateName,
        published: published
      }

      const response = await fetch(`${apiUrl}/api/messaging/email-template/save`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
        credentials: 'include'
      });
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emailTemplates'] });
    },
  });

  // Function to initialize all templates from registry
  const initializeTemplates = async () => {
    const existingTemplateNames = templates.map(t => t.templateName);

    for (const [key, config] of Object.entries(TEMPLATE_REGISTRY)) {
      // Generate template name (you can modify this naming convention)
      const templateName = `${key.charAt(0).toUpperCase()}${key.slice(1)} Template`;

      // Skip if template already exists
      if (existingTemplateNames.includes(templateName)) {
        continue;
      }

      // Generate HTML for the template
      const templateHtml = await generateTemplateHtml(key);

      // Create the template
      await createTemplate({
        templateName,
        templateHtml,
        published: false,
      });
    }
  };

  // Mutation to toggle published status
  const { mutateAsync: togglePublished } = useMutation({
    mutationFn: async (templateId: number) => {
      const template = templates.find(t => t.id === templateId);
      if (!template) throw new Error('Template not found');

      const request: EmailTemplateRequest = {
        id: template.id,
        templateHtml: template.templateHtml,
        templateName: template.templateName,
        published: !template.published
      }

      const response = await fetch(`${apiUrl}/api/messaging/email-template/save`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
        credentials: 'include'
      });
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['emailTemplates'] });
    },
  });

  return {
    templates,
    isLoading,
    initializeTemplates,
    togglePublished,
  };
};