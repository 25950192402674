import { StepTypeEnum } from '@btrway/api-workflow';
import {
  IconAlignBoxLeftTop,
  IconBellRinging,
  IconBook,
  IconCalendarEvent,
  IconCertificate,
  IconCheckbox,
  IconClipboardCheck,
  IconClock,
  IconEye,
  IconFileCheck,
  IconListCheck,
  IconMail,
  IconMessage,
  IconMoodSmile,
  IconPlayerPlay,
  IconProps,
  IconRoute,
  IconSchool,
  IconSquareRoundedPlus,
  IconUpload,
  IconUserCheck,
  IconUserEdit,
  IconUserMinus,
  IconUserPlus,
  IconUsersPlus,
} from '@tabler/icons-react';
import React from 'react';

const iconMap: Record<StepTypeEnum, React.ComponentType<IconProps>> = {
  [StepTypeEnum.actionCreateCertificate]: IconCertificate,
  [StepTypeEnum.actionCreateEvent]: IconCalendarEvent,
  [StepTypeEnum.actionCreateWorkgroupMember]: IconUserPlus,
  [StepTypeEnum.actionDeleteWorkgroupMember]: IconUserMinus,
  [StepTypeEnum.actionPushNotification]: IconBellRinging,
  [StepTypeEnum.actionSendEmail]: IconMail,
  [StepTypeEnum.actionSendUserInvite]: IconUserCheck,
  [StepTypeEnum.actionSms]: IconMessage,
  [StepTypeEnum.actionUpdatePersonData]: IconUserEdit,
  [StepTypeEnum.eventChapterCompleted]: IconBook,
  [StepTypeEnum.eventCourseCompleted]: IconSchool,
  [StepTypeEnum.eventCurriculumCompleted]: IconClipboardCheck,
  [StepTypeEnum.eventFormSubmitted]: IconAlignBoxLeftTop,
  [StepTypeEnum.eventUserCreated]: IconUsersPlus,
  [StepTypeEnum.eventReviewCompleted]: IconEye,
  [StepTypeEnum.flowCondition]: IconRoute,
  [StepTypeEnum.flowStart]: IconPlayerPlay,
  [StepTypeEnum.flowWait]: IconClock,
  [StepTypeEnum.systemWorkflowActionCompleted]: IconCheckbox,
  [StepTypeEnum.systemWorkflowAssigned]: IconListCheck,
  [StepTypeEnum.systemWorkflowInstanceCreated]: IconFileCheck,
  [StepTypeEnum.systemWorkflowTaskCompleted]: IconCheckbox,
  [StepTypeEnum.systemCreateWorkflowInstance]: IconFileCheck,
  [StepTypeEnum.systemMarkWorkflowStepInstanceCompleted]: IconCheckbox,
  [StepTypeEnum.assignTask]: IconSquareRoundedPlus,
  [StepTypeEnum.missing_enum_value]: IconMoodSmile,
  [StepTypeEnum.formSubmission]: IconAlignBoxLeftTop,
  [StepTypeEnum.fileUpload]: IconUpload,
};

interface StepTypeIconProps {
  iconName: StepTypeEnum;
  color?: string;
  size?: string | number;
}

export const StepTypeIcon: React.FC<StepTypeIconProps> = ({
  iconName,
  color = 'currentColor',
  size = 24,
}) => {
  const IconComponent = iconMap[iconName] || IconMoodSmile;
  return <IconComponent color={color} size={size} />;
};
