import {
  AssetFolderRequest,
  useGetAssetFolders,
  useSaveAssetFolder,
} from '@btrway/api-courseware';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';
import React, { useState } from 'react';
import CourseFolderView from '../../components/CourseFolderView/CourseFolderView';

const CoursesPage: React.FC = () => {
  useAddHelpTags(['course']);

  const {
    data: courseFolders,
    error,
    isLoading,
    refetch,
  } = useGetAssetFolders();
  const { mutate: saveCourseFolder } = useSaveAssetFolder();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderTitle, setNewFolderTitle] = useState('');

  if (isLoading) return null;
  if (error) return <div>Error loading course folders</div>;

  const handleAddCourseFolder = () => {
    const newFolder: AssetFolderRequest = {
      title: newFolderTitle,
    };

    saveCourseFolder(
      { data: newFolder },
      {
        onSuccess: () => {
          refetch();
          setIsModalOpen(false);
          setNewFolderTitle('');
        },
      }
    );
  };

  return (
    <>
      <Stack gap="xs">
        <Group justify="flex-end">
          <Button onClick={() => setIsModalOpen(true)}>
            Add Course Folder
          </Button>
        </Group>
        {courseFolders?.map((folder) => (
          <CourseFolderView key={folder.id} folder={folder} />
        ))}
      </Stack>
      <Modal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add New Course Folder"
        autoFocus
      >
        <TextInput
          value={newFolderTitle}
          onChange={(event) => setNewFolderTitle(event.currentTarget.value)}
          placeholder="Enter folder title"
          mb="md"
          autoFocus
          data-autofocus
        />
        <Group justify="right">
          <Button variant="outline" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleAddCourseFolder}>Add Folder</Button>
        </Group>
      </Modal>
    </>
  );
};

export default CoursesPage;
