/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type TaskListDateMethodEnum = typeof TaskListDateMethodEnum[keyof typeof TaskListDateMethodEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaskListDateMethodEnum = {
  byStartDate: 'byStartDate',
  byDueDate: 'byDueDate',
} as const;
