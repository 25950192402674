import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { HelpResourceEditor } from '../../components/HelpResourceEditor/HelpResourceEditor';

const HelpResourcePage: React.FC = () => {
  const { helpResourceId } = useParams<{ helpResourceId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const isNewHelpResource = !helpResourceId || helpResourceId === 'new';
  const parsedHelpResourceId = isNewHelpResource
    ? null
    : parseInt(helpResourceId, 10);

  const handleSave = () => {
    // Get the current path segments and remove the last one
    const pathSegments = location.pathname.split('/');
    pathSegments.pop();
    navigate(pathSegments.join('/'));
  };

  return (
    <HelpResourceEditor
      helpResourceId={parsedHelpResourceId}
      onSave={handleSave}
    />
  );
};

export default HelpResourcePage;
