import { CurrentUserResponse } from '@btrway/api-core';
import { useAuthContext } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import React, { createContext, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

interface ImpersonationContextValue {
  startOrgImpersonation: (organizationId: number) => Promise<void>;
  stopOrgImpersonation: () => Promise<void>;
  startPersonImpersonation: (
    personId: number,
    organizationId: number,
    email: string
  ) => Promise<void>;
  stopPersonImpersonation: () => Promise<void>;
  isImpersonatingOrg: boolean;
  isImpersonatingPerson: boolean;
}

const ImpersonationContext = createContext<ImpersonationContextValue | null>(
  null
);

export const ImpersonationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authState, updateAuthState, revokeToken } = useAuthContext();
  const apiUrl = authState.apiUrl;
  const { refetchCurrentUser, currentUser } = useAuthenticatedUser();
  const navigate = useNavigate();

  const startOrgImpersonation = useCallback(
    async (organizationId: number) => {
      try {
        // Make the API request to get org access token
        const response = await fetch(`${apiUrl}/auth/internal-org-access`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ organizationId }),
        });

        if (!response.ok) {
          throw new Error('Failed to start org impersonation');
        }

        // Update auth state
        updateAuthState({
          isAccessingOrg: true,
        });

        // Force refetch of current user to get updated data
        const currentUserResponse =
          (await refetchCurrentUser()) as CurrentUserResponse;

        // Navigate to appropriate route
        navigate('/app');
      } catch (error) {
        console.error('Error starting org impersonation:', error);
        throw error;
      }
    },
    [updateAuthState, refetchCurrentUser, navigate]
  );

  const stopOrgImpersonation = useCallback(async () => {
    try {
      // Make the API request to clear org access
      await revokeToken('org_token');

      // Update auth state
      updateAuthState({
        isAccessingOrg: false,
      });

      // Force refetch of current user to get updated data
      const currentUserResponse =
        (await refetchCurrentUser()) as CurrentUserResponse;
      console.log('Current user response: ', currentUserResponse);

      // Navigate to appropriate route
      navigate('/app-internal/organizations/orgs', { replace: true });
    } catch (error) {
      console.error('Error stopping org impersonation:', error);
      throw error;
    }
  }, [updateAuthState, refetchCurrentUser, navigate]);

  const startPersonImpersonation = useCallback(
    async (personId: number, organizationId: number, email: string) => {
      try {
        // Make the API request to get person access token
        const response = await fetch(`${apiUrl}/auth/internal-person-access`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({ personId, organizationId, email }),
        });

        if (!response.ok) {
          throw new Error('Failed to start person impersonation');
        }

        // Update auth state
        updateAuthState({
          isAccessingPerson: true,
        });

        // Force refetch of current user to get updated data
        const currentUserResponse =
          (await refetchCurrentUser()) as CurrentUserResponse;
        console.log(
          'Impersonated Person Current user response: ',
          currentUserResponse
        );

        // Navigate to appropriate route
        navigate('/app');
      } catch (error) {
        console.error('Error starting person impersonation:', error);
        throw error;
      }
    },
    [updateAuthState, refetchCurrentUser, navigate]
  );

  const stopPersonImpersonation = useCallback(async () => {
    try {
      await revokeToken('person_token');
      updateAuthState({ isAccessingPerson: false });

      const currentUserResponse =
        (await refetchCurrentUser()) as CurrentUserResponse;
      console.log('Current user response: ', currentUserResponse);

      navigate(`app-internal/dashboard`, { replace: true });

      // If navigation fails for any reason
    } catch (error) {
      console.error('Error stopping person impersonation:', error);

      // Fallback navigation
    }
  }, [updateAuthState, refetchCurrentUser, navigate]);

  const value = {
    startOrgImpersonation,
    stopOrgImpersonation,
    startPersonImpersonation,
    stopPersonImpersonation,
    isImpersonatingOrg: authState.isAccessingOrg,
    isImpersonatingPerson: authState.isAccessingPerson,
  };

  return (
    <ImpersonationContext.Provider value={value}>
      {children}
    </ImpersonationContext.Provider>
  );
};

export const useImpersonation = () => {
  const context = useContext(ImpersonationContext);
  if (!context) {
    throw new Error(
      'useImpersonation must be used within an ImpersonationProvider'
    );
  }
  return context;
};
