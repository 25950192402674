import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { Badge, Box, Card, MantineColor, Text } from '@mantine/core';
import React from 'react';
import { IconPickerItem } from 'react-fa-icon-picker';
import { FaFileAlt } from 'react-icons/fa';

interface LaunchFormItemProps {
  workflowDefinition: WorkflowDefinitionResponse;
  getCategoryColor: (category: string) => MantineColor;
  onLaunch: (workflowDefinitionId: number) => void;
  isMobile: boolean;
}

const LaunchFormItem: React.FC<LaunchFormItemProps> = ({
  workflowDefinition,
  getCategoryColor,
  onLaunch,
  isMobile,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onLaunch(workflowDefinition.id);
  };

  const metadata = workflowDefinition.workflowMetadata || {};
  const category =
    metadata.categories && metadata.categories.length > 0
      ? metadata.categories[0]
      : null;
  const categoryColor = category ? getCategoryColor(category) : null;

  const fallbackIcons = ['FaRegFileAlt'];
  const iconName =
    metadata.iconName ||
    fallbackIcons[Math.floor(Math.random() * fallbackIcons.length)];

  const renderIcon = () => {
    try {
      return (
        <IconPickerItem
          icon={iconName}
          size={isMobile ? 60 : 120}
          color="#adb5bd"
        />
      );
    } catch (error) {
      console.error(`Error rendering icon: ${iconName}`, error);
      return <FaFileAlt size={isMobile ? 60 : 120} color="#adb5bd" />;
    }
  };

  return (
    <Card
      padding={0}
      radius="md"
      withBorder
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        width: isMobile ? '100%' : '250px',
        height: isMobile ? '120px' : '250px',
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        overflow: 'hidden',
      }}
    >
      <Box
        bg="gray.4"
        style={{
          height: isMobile ? '120px' : '100px',
          width: isMobile ? '120px' : '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          overflow: 'hidden',
          position: 'relative',
          flexShrink: 0,
        }}
      >
        {category && categoryColor && (
          <Badge
            size="xs"
            radius={0}
            variant="filled"
            color={categoryColor}
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              zIndex: 1,
            }}
          >
            {category}
          </Badge>
        )}
        <div
          style={{
            transform: isMobile ? 'scale(1.2)' : 'scale(1.2) translateY(10px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {renderIcon()}
        </div>
      </Box>
      <Box
        p="md"
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Text size="md" fw={500} mb={5}>
          {workflowDefinition.name}
        </Text>
        <Text size="xs" c="dimmed" lineClamp={isMobile ? 2 : 3}>
          {metadata.shortDescription}
        </Text>
      </Box>
    </Card>
  );
};

export default LaunchFormItem;
