import { useTaskManager } from '@btrway/task-manager';
import { Button } from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import TaskCompletionWrapper from '../TaskCompletionWrapper/TaskCompletionWrapper';

interface TaskAssignmentStartButtonProps {
  onTaskCompletion: () => void;
  initialTaskCount: number;
}

const TaskAssignmentStartButton: React.FC<TaskAssignmentStartButtonProps> = ({
  onTaskCompletion,
  initialTaskCount,
}) => {
  const { assignedTasks } = useTaskManager();
  const [isContinuousTaskMode, setIsContinuousTaskMode] = useState(false);

  useEffect(() => {
    if (assignedTasks.length === 0 && initialTaskCount > 0) {
      onTaskCompletion();
    }
  }, [assignedTasks, initialTaskCount, onTaskCompletion]);

  const handleStartClick = () => {
    setIsContinuousTaskMode(true);
  };

  const handleTaskCompletionWrapper = () => {
    setIsContinuousTaskMode(false);
    if (assignedTasks.length === 0) {
      onTaskCompletion();
    }
  };

  if (assignedTasks.length === 0) {
    console.log('No tasks, returning null');
    return null;
  }

  return (
    <>
      <Button
        leftSection={<IconPlayerPlayFilled size={18} />}
        onClick={handleStartClick}
      >
        Start
      </Button>
      {isContinuousTaskMode && (
        <TaskCompletionWrapper onClose={handleTaskCompletionWrapper} />
      )}
    </>
  );
};

export default TaskAssignmentStartButton;
