import {
  StorageUploadRequest,
  StorageUploadResponse,
  useUploadFile,
} from '@btrway/api-storage';

interface FileUploadData {
  file: File | Blob;
  contentType: string;
  fileName: string;
}

export const useStorageUpload = () => {
  const { mutateAsync: uploadFile } = useUploadFile();

  const storageUpload = async (
    storageUploadRequest: StorageUploadRequest,
    fileUploadData: FileUploadData[]
  ): Promise<StorageUploadResponse> => {
    try {
      const response = await uploadFile({ data: storageUploadRequest });
      const res = response as StorageUploadResponse;

      const presignedUrls = res.presignedUrls;
      const fileNames = Object.keys(presignedUrls);

      if (fileNames.length !== fileUploadData.length) {
        throw new Error('Mismatch between presigned URLs and file upload data');
      }

      const uploadPromises = fileNames.map((fullPath) => {
        const fileName = fullPath.split('/').pop(); // Get the actual file name
        const url = presignedUrls[fullPath];
        const fileData = fileUploadData.find(
          (data) => data.fileName === fileName
        );

        if (!fileData) {
          throw new Error(`No upload data found for file: ${fileName}`);
        }

        return uploadToS3(url, fileData.file, fileData.contentType);
      });

      await Promise.all(uploadPromises);

      return res;
    } catch (error) {
      console.error('Error in storageUpload:', error);
      throw error;
    }
  };

  const uploadToS3 = async (
    uploadUrl: string,
    file: File | Blob,
    contentType: string
  ): Promise<void> => {
    try {
      const response = await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error(`Error uploading file: ${response.statusText}`);
      }
    } catch (error) {
      console.error(`Error in uploadToS3: ${error}`);
      throw error;
    }
  };

  return {
    storageUpload,
  };
};
