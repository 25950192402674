// @btrway/courseware-progress-manager/src/hooks/useCoursewareProgress.ts
import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { useCourses } from '@btrway/courseware-manager';
import { useAtomValue } from 'jotai';
import { chapterProgressAtom } from '../atoms/chapterProgressAtom';
import {
  ChapterProgress,
  ChapterWithProgress,
  CourseProgress,
  CourseStatus,
  CurriculumProgress,
} from '../types/progressTypes';

export const useCoursewareProgress = () => {
  const progress = useAtomValue(chapterProgressAtom);
  const { courses } = useCourses();

  const getChapterProgress = (chapter: ChapterDTO): ChapterProgress => {
    const chapterProgress = progress.find((p) => p.chapterKey === chapter.key);

    return {
      progress: chapterProgress,
      isComplete: Boolean(chapterProgress?.completed),
      currentTimeSeconds: chapterProgress?.currentTimeSeconds || 0,
      maxTimeSeconds: chapterProgress?.maxTimeSeconds || 0,
    };
  };

  const getFirstIncompleteChapter = (
    course: AssetResponse
  ): ChapterWithProgress | null => {
    const chapters = course.chapters || [];

    const firstIncompleteChapter = chapters.find(
      (chapter) =>
        !progress.some((p) => p.chapterKey === chapter.key && p.completed)
    );

    const targetChapter = firstIncompleteChapter || chapters[0];

    if (!targetChapter) {
      return null;
    }

    return {
      chapter: targetChapter,
      progress: progress.find((p) => p.chapterKey === targetChapter.key),
    };
  };

  const getCourseProgress = (course: AssetResponse): CourseProgress => {
    const courseChapters = course.chapters || [];
    const chapterProgress = courseChapters.map((chapter) =>
      progress.find((p) => p.chapterKey === chapter.key)
    );

    const totalDuration = courseChapters.reduce(
      (sum, chapter) => sum + (chapter.duration || 0),
      0
    );

    const completedDuration = chapterProgress.reduce((sum, progress, index) => {
      const chapterDuration = courseChapters[index].duration || 0;

      // If the chapter is completed, count its full duration
      if (progress?.completed) {
        return sum + chapterDuration;
      }

      // If there's progress but not complete, use the minimum of current time and max time
      if (progress) {
        const progressTime = Math.min(
          chapterDuration,
          Math.max(progress.currentTimeSeconds, progress.maxTimeSeconds)
        );
        return sum + progressTime;
      }

      // No progress at all
      return sum;
    }, 0);

    const status = (() => {
      if (chapterProgress.every((p) => p?.completed)) {
        return CourseStatus.Complete;
      }
      if (chapterProgress.some((p) => p)) {
        return CourseStatus.Started;
      }
      return CourseStatus.NotStarted;
    })();

    const resumePoint = (() => {
      let duration = 0;
      for (let i = 0; i < courseChapters.length; i++) {
        const chapter = courseChapters[i];
        const progress = chapterProgress[i];

        if (!progress?.completed) {
          return duration + (progress?.currentTimeSeconds || 0);
        }

        duration += chapter.duration || 0;
      }
      return duration;
    })();

    return {
      status,
      resumePoint,
      completionPercent: totalDuration
        ? (completedDuration / totalDuration) * 100
        : 0,
      totalDuration,
      completedDuration,
    };
  };

  const getCurriculumProgress = (
    curriculum: WorkflowDefinitionResponse
  ): CurriculumProgress => {
    // TODO: Implement real curriculum progress calculation
    return {
      completionPercent: 0,
      completedCourses: 0,
      totalCourses: 0,
      isComplete: false,
    };
  };

  const getAllCourseProgress = (): Record<number, CourseProgress> => {
    return courses.reduce((acc, course) => {
      acc[course.uid] = getCourseProgress(course);
      return acc;
    }, {} as Record<number, CourseProgress>);
  };

  return {
    getChapterProgress,
    getCourseProgress,
    getCurriculumProgress,
    getFirstIncompleteChapter,
    getAllCourseProgress,
  };
};
