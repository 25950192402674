import {
  ActionIcon,
  Box,
  Button,
  Card,
  Grid,
  Group,
  NumberInput,
  Popover,
  Stack,
  Tabs,
  Text,
  Transition,
  rem,
} from '@mantine/core';
import { IconCalendar, IconClock } from '@tabler/icons-react';
import React, { useCallback, useEffect, useState } from 'react';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

interface DayValuePickerProps {
  initialMonth?: number;
  initialDay?: number;
  initialOffsetDays?: number;
  onSave: (
    month: number | undefined,
    day: number | undefined,
    offsetDays: number | undefined
  ) => void;
  children: (
    open: (event: React.MouseEvent<HTMLButtonElement>) => void
  ) => React.ReactNode;
}

const DayValuePicker: React.FC<DayValuePickerProps> = ({
  initialMonth = 7,
  initialDay = 1,
  initialOffsetDays,
  onSave,
  children,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);
  const [selectedDay, setSelectedDay] = useState(initialDay);
  const [offsetDays, setOffsetDays] = useState(initialOffsetDays || 0);
  const [opened, setOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<'monthDay' | 'daysLater'>(
    initialOffsetDays ? 'daysLater' : 'monthDay'
  );

  useEffect(() => {
    if (opened) {
      setSelectedMonth(initialMonth);
      setSelectedDay(initialDay);
      setOffsetDays(initialOffsetDays || 0);
      setActiveTab(initialOffsetDays ? 'daysLater' : 'monthDay');
    }
  }, [initialMonth, initialDay, initialOffsetDays, opened]);

  const handleMonthClick = useCallback(
    (event: React.MouseEvent, month: number) => {
      event.stopPropagation();
      setSelectedMonth(month);
    },
    []
  );

  const handleDayClick = useCallback((event: React.MouseEvent, day: number) => {
    event.stopPropagation();
    setSelectedDay(day);
  }, []);

  const handleSave = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (activeTab === 'monthDay') {
      onSave(selectedMonth, selectedDay, undefined);
    } else {
      onSave(undefined, undefined, offsetDays);
    }
    setOpened(false);
  };

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    onSave(undefined, undefined, undefined);
    setOpened(false);
  };

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpened(!opened);
  };

  const handleTabsClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const renderDayButtons = () => {
    const rows = [];
    const numberOfDays = daysInMonth[selectedMonth - 1];

    for (let i = 0; i < numberOfDays; i += 7) {
      const week = [];
      for (let j = i; j < i + 7 && j < numberOfDays; j++) {
        const day = j + 1;
        week.push(
          <Grid.Col span={1} key={day}>
            <ActionIcon
              variant={selectedDay === day ? 'filled' : 'white'}
              color={selectedDay === day ? 'blue' : 'black'}
              radius="xs"
              size="md"
              aria-label={`Select day ${day}`}
              onClick={(event) => handleDayClick(event, day)}
            >
              <Text fz="xs">{day}</Text>
            </ActionIcon>
          </Grid.Col>
        );
      }
      rows.push(
        <Grid columns={7} key={i}>
          {week}
        </Grid>
      );
    }

    return <>{rows}</>;
  };

  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Popover
      width={420}
      position="bottom"
      opened={opened}
      onChange={setOpened}
      radius="md"
      shadow="xl"
      closeOnEscape
      trapFocus
    >
      <Popover.Target>{children(handleToggle)}</Popover.Target>
      <Popover.Dropdown p={0}>
        <Card p={0} style={{ position: 'relative' }}>
          <div onClick={handleTabsClick}>
            {' '}
            {/* Add this wrapper div */}
            <Tabs
              variant="pills"
              radius="xl"
              value={activeTab}
              onChange={(value) =>
                setActiveTab(value as 'monthDay' | 'daysLater')
              }
            >
              <Group justify="center" p="xs" bg="gray.4">
                <Tabs.List style={{ padding: '0 1rem' }}>
                  <Tabs.Tab
                    value="monthDay"
                    leftSection={<IconCalendar style={iconStyle} />}
                    style={{ fontSize: rem(12), padding: '0.3rem 0.5rem' }}
                    fw={500}
                  >
                    Month/Day
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="daysLater"
                    leftSection={<IconClock style={iconStyle} />}
                    style={{ fontSize: rem(12), padding: '0.3rem 0.5rem' }}
                    fw={500}
                  >
                    Days Later
                  </Tabs.Tab>
                </Tabs.List>
              </Group>
              <Box
                style={{
                  height: '220px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <Transition
                  mounted={activeTab === 'monthDay'}
                  transition={{
                    in: { transform: 'translateX(0)', opacity: 1 },
                    out: { transform: 'translateX(-100%)', opacity: 0 },
                    common: {
                      transition: 'transform 200ms ease, opacity 200ms ease',
                    },
                    transitionProperty: 'transform, opacity',
                  }}
                >
                  {(styles) => (
                    <div
                      style={{
                        ...styles,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Tabs.Panel value="monthDay">
                        <Grid gutter={0}>
                          <Grid.Col span={4}>
                            <Stack p="sm" bg="gray.1" h="100%" justify="center">
                              <Grid
                                columns={2}
                                p="xs"
                                style={{ height: '100%' }}
                              >
                                {months.map((month, index) => (
                                  <Grid.Col key={month} span={1} p={5}>
                                    <Button
                                      fullWidth
                                      size="compact-xs"
                                      variant={
                                        selectedMonth === index + 1
                                          ? 'filled'
                                          : 'subtle'
                                      }
                                      color={
                                        selectedMonth === index + 1
                                          ? 'blue'
                                          : 'gray'
                                      }
                                      onClick={(event) =>
                                        handleMonthClick(event, index + 1)
                                      }
                                    >
                                      {month.slice(0, 3).toUpperCase()}
                                    </Button>
                                  </Grid.Col>
                                ))}
                              </Grid>
                            </Stack>
                          </Grid.Col>
                          <Grid.Col span={8} p="md">
                            {renderDayButtons()}
                          </Grid.Col>
                        </Grid>
                      </Tabs.Panel>
                    </div>
                  )}
                </Transition>

                <Transition
                  mounted={activeTab === 'daysLater'}
                  transition={{
                    in: { transform: 'translateX(0)', opacity: 1 },
                    out: { transform: 'translateX(100%)', opacity: 0 },
                    common: {
                      transition: 'transform 200ms ease, opacity 200ms ease',
                    },
                    transitionProperty: 'transform, opacity',
                  }}
                >
                  {(styles) => (
                    <div
                      style={{
                        ...styles,
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <Tabs.Panel
                        value="daysLater"
                        p="md"
                        style={{ height: '100%' }}
                      >
                        <NumberInput
                          label="Days Later"
                          description="Enter the number of days to offset"
                          value={offsetDays}
                          onChange={(value) => setOffsetDays(Number(value))}
                          min={0}
                        />
                      </Tabs.Panel>
                    </div>
                  )}
                </Transition>
              </Box>
            </Tabs>
          </div>
          <Group
            justify="flex-end"
            gap="xs"
            p="xs"
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              background: 'transparent',
            }}
          >
            <Button
              size="compact-xs"
              variant="subtle"
              color="gray"
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              size="compact-xs"
              variant="light"
              color="blue"
              onClick={handleSave}
            >
              Save
            </Button>
          </Group>
        </Card>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DayValuePicker;
