/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type CertificationRequiredTypeEnum = typeof CertificationRequiredTypeEnum[keyof typeof CertificationRequiredTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CertificationRequiredTypeEnum = {
  yes: 'yes',
  withinTimeframe: 'withinTimeframe',
} as const;
