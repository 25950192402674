import {
  CertificationTaskProperties,
  StepConfig,
  StepTypeEnum,
  StepTypeTypeEnum,
  TaskListTaskProperties,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { useFormBuilder } from '@btrway/form-builder';
import { useSelectedItem } from '@btrway/workflow-configuration-manager';
import { useStepTypes } from '@btrway/workflow-manager';
import { Draggable } from '@hello-pangea/dnd';
import { Box, Button, Card, Group } from '@mantine/core';
import {
  IconChevronDown,
  IconChevronRight,
  IconWand,
} from '@tabler/icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { CertificationDetail } from '../CertificationDetail/CertificationDetail';
import { StepConfigCardIcon } from '../StepConfigCardIcon/StepConfigCardIcon';
import { StepConfigConnector } from '../StepConfigConnector/StepConfigConnector';
import { StepConfigContent } from '../StepConfigContent/StepConfigContent';
import { TaskListDetail } from '../TaskListDetail/TaskListDetail';

interface StepConfigCardProps {
  workflowKey: string;
  step: StepConfig;
  index: number;
  isLast: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

const StepConfigCard: React.FC<StepConfigCardProps> = ({
  workflowKey,
  step,
  index,
  isLast,
  isSelected,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [nestedWorkflowKey, setNestedWorkflowKey] = useState('');
  const { openFormBuilder } = useFormBuilder();
  const { getStepType } = useStepTypes();
  const { selectedItem, selectedTitle, setSelectedItem } = useSelectedItem();

  const stepType = getStepType(step?.stepTypeCode as StepTypeEnum);
  const isAutomatedStep = stepType?.type === StepTypeTypeEnum.action;

  const showTaskListEditor =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.completeTaskList;
  const showCertificationEditor =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.certification;

  useEffect(() => {
    if (showTaskListEditor && step?.taskConfig?.taskProperties) {
      const taskListProperties = step.taskConfig
        .taskProperties as TaskListTaskProperties;
      setNestedWorkflowKey(
        taskListProperties.workflowSelection.workflowDefinitionKey?.value || ''
      );
    } else if (showCertificationEditor && step?.taskConfig?.taskProperties) {
      const certificationProperties = step.taskConfig
        .taskProperties as CertificationTaskProperties;
      setNestedWorkflowKey(
        certificationProperties.workflowSelection.workflowDefinitionKey
          ?.value || ''
      );
    }
  }, [step, showTaskListEditor, showCertificationEditor]);

  const showFormFieldsButton =
    step?.stepTypeCode === StepTypeEnum.assignTask &&
    step.taskConfig?.taskType === TaskTypeEnum.completeWorkflowStep;
  const hasNestedContent = showTaskListEditor || showCertificationEditor;

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleShowFieldsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedItem({ type: 'step', key: step.stepKey });
    openFormBuilder(step.stepKey, true);
  };

  const renderNestedContent = () => {
    if (!isExpanded) return null;

    return (
      <>
        {showTaskListEditor && (
          <TaskListDetail workflowKey={nestedWorkflowKey} />
        )}
        {showCertificationEditor && (
          <CertificationDetail workflowKey={nestedWorkflowKey} />
        )}
      </>
    );
  };
  return (
    <Draggable draggableId={step.stepKey} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            width: '100%',
            ...provided.draggableProps.style,
          }}
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
        >
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <Card
              p="md"
              radius="md"
              withBorder
              shadow="xs"
              style={{
                width: '100%',
                marginBottom: 0,
                opacity: snapshot.isDragging ? 0.5 : 1,
                borderColor: isSelected ? 'blue' : undefined,
                borderWidth: isSelected ? 2 : 1,
                backgroundColor: isAutomatedStep ? '#F2E9FE' : undefined,
                cursor: 'pointer',
              }}
            >
              <Group justify="space-between" gap="xs" wrap="nowrap">
                <Group
                  gap="xs"
                  wrap="nowrap"
                  justify="flex-start"
                  align="flex-start"
                >
                  <StepConfigCardIcon stepKey={step.stepKey} />
                  <StepConfigContent
                    step={step}
                    workflowKey={workflowKey}
                    isAutomatedStep={isAutomatedStep}
                    stepType={stepType}
                  />
                  {hasNestedContent && (
                    <div
                      onClick={handleExpandClick}
                      style={{ cursor: 'pointer', display: 'flex' }}
                    >
                      {isExpanded ? (
                        <IconChevronDown size={20} />
                      ) : (
                        <IconChevronRight size={20} />
                      )}
                    </div>
                  )}
                </Group>
                {isAutomatedStep && <IconWand size={24} color="#8C65D3" />}
              </Group>

              {renderNestedContent()}

              <Box
                style={{ position: 'relative', height: 36, marginTop: 'xs' }}
              >
                <AnimatePresence>
                  {showFormFieldsButton && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '100%',
                      }}
                    >
                      <Group justify="flex-end" p={0}>
                        <Button
                          variant="light"
                          size="xs"
                          onClick={handleShowFieldsClick}
                        >
                          Form Layout
                        </Button>
                      </Group>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>
            </Card>
            {!isLast && !snapshot.isDragging && <StepConfigConnector />}
          </motion.div>
        </div>
      )}
    </Draggable>
  );
};

export default StepConfigCard;
