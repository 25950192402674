import { useGetWorkgroupById, WorkgroupResponse } from '@btrway/api-core';
import { LoadingSpinner } from '@btrway/shared-components';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router';

interface WorkgroupContextValue {
  workgroup: WorkgroupResponse;
  isLoading: boolean;
}

const WorkgroupContext = createContext<WorkgroupContextValue | undefined>(
  undefined
);

export const useWorkgroup = () => {
  const context = useContext(WorkgroupContext);
  if (!context) {
    throw new Error('useWorkgroup must be used within a WorkgroupProvider');
  }
  return context;
};

interface WorkgroupProviderProps {
  children: React.ReactNode;
}

export const WorkgroupProvider: React.FC<WorkgroupProviderProps> = ({
  children,
}) => {
  const { workgroupId } = useParams();

  const {
    data: workgroup,
    isLoading,
    error,
  } = useGetWorkgroupById(workgroupId ? Number(workgroupId) : 0);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || !workgroup) {
    return <div>Error loading workgroup</div>;
  }

  return (
    <WorkgroupContext.Provider
      value={{
        workgroup,
        isLoading: false,
      }}
    >
      {children}
    </WorkgroupContext.Provider>
  );
};
