import { useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { tagsAtom } from '../atoms/tagsAtom';

export const useAddHelpTags = (tags: string[] = []): void => {
  const location = useLocation();
  const setTags = useSetAtom(tagsAtom);
  const processedRef = useRef(false);

  useEffect(() => {
    if (tags.length > 0 && !processedRef.current) {
      setTags((prev) => {
        const existingLocationIndex = prev.findIndex(
          (lt) => lt.pathname === location.pathname
        );

        if (existingLocationIndex >= 0) {
          // Update existing location's tags
          return prev.map((lt, index) =>
            index === existingLocationIndex
              ? {
                  ...lt,
                  tags: Array.from(new Set([...lt.tags, ...tags])),
                }
              : lt
          );
        }

        // Add new location tags
        return [...prev, { pathname: location.pathname, tags }];
      });
      processedRef.current = true;
    }
  }, [tags, location.pathname, setTags]);
};
