import {
  Column,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { Box, Card, Flex, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface ResponsiveGridRowProps {
  data: any;
  columnDefs: any[];
  isMobile: boolean;
  api: any;
  context: any;
  variant?: 'stack' | 'flex';
  onCardClick?: (data: any) => void;
}

const ResponsiveGridRow: React.FC<ResponsiveGridRowProps> = ({
  data,
  columnDefs,
  isMobile,
  api,
  context,
  variant = 'flex',
  onCardClick,
}) => {
  if (!isMobile || !data) {
    return null;
  }

  const getValue = (col: any) => {
    if (col.valueGetter) {
      const params: ValueGetterParams = {
        data,
        node: api.getRowNode(data.id),
        colDef: col,
        api,
        column: api.getColumnDef(col.field),
        context,
        getValue: (field: string) => data[field],
      };
      return col.valueGetter(params);
    }
    return data[col.field];
  };

  const renderCellContent = (col: any, value: any, column: Column) => {
    if (col.cellRenderer) {
      const CellRenderer = col.cellRenderer;
      const cellRendererParams: ICellRendererParams = {
        value,
        valueFormatted: undefined,
        fullWidth: false,
        pinned: null,
        data,
        node: api.getRowNode(data.id),
        colDef: col,
        column,
        eGridCell: document.createElement('div'),
        eParentOfValue: document.createElement('div'),
        api,
        context,
        getValue: () => value,
        setValue: (newValue: any) => {
          data[col.field] = newValue;
        },
        formatValue: (value: any) => value?.toString() ?? '',
        refreshCell: () => {},
        registerRowDragger: () => {},
        setTooltip: () => {},
      };

      if (col.cellRendererParams) {
        Object.assign(
          cellRendererParams,
          col.cellRendererParams(cellRendererParams)
        );
      }

      return <CellRenderer {...cellRendererParams} />;
    }

    // Apply valueFormatter if it exists
    if (col.valueFormatter) {
      const formattedValue = col.valueFormatter({
        value,
        data,
        node: api.getRowNode(data.id),
        colDef: col,
        column,
        api,
        context,
        getValue: () => value,
      } as ValueFormatterParams);
      return formattedValue ?? 'N/A';
    }

    return value ?? 'N/A';
  };

  const visibleColumns = columnDefs.filter((col) => !col.hide);

  const renderStackVariant = () => (
    <Stack gap="xs">
      {visibleColumns.map((col, index) => {
        const value = getValue(col);
        const label = col.headerName;
        const column = api.getColumnDef(col.field);

        if (index === 0) {
          return (
            <Box key={col.field}>
              <Text size="lg" fw={500} mb="xs" component="div">
                {renderCellContent(col, value, column)}
              </Text>
            </Box>
          );
        }

        return (
          <Group key={col.field} justify="space-between" wrap="nowrap">
            <Text size="sm" c="dimmed" component="div">
              {label}:
            </Text>
            <Box style={col.cellStyle}>
              {renderCellContent(col, value, column)}
            </Box>
          </Group>
        );
      })}
    </Stack>
  );

  const renderFlexVariant = () => (
    <Flex gap="sm" wrap="wrap">
      {visibleColumns.map((col, index) => {
        const value = getValue(col);
        const label = col.headerName;
        const column = api.getColumnDef(col.field);

        if (index === 0) {
          return (
            <Box key={col.field} style={{ width: '100%' }}>
              <Text size="lg" fw={500} mb="xs" component="div">
                {renderCellContent(col, value, column)}
              </Text>
            </Box>
          );
        }

        return (
          <Box
            key={col.field}
            style={{
              minWidth: '120px',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Text size="xs" c="dimmed" component="div" mb={4}>
              {label}
            </Text>
            <Box style={{ ...col.cellStyle, textAlign: 'center' }}>
              {renderCellContent(col, value, column)}
            </Box>
          </Box>
        );
      })}
    </Flex>
  );

  const handleClick = () => {
    if (onCardClick) {
      onCardClick(data);
    }
  };

  return (
    <Box style={{ width: '100%' }}>
      <Card
        shadow="sm"
        padding="md"
        radius="md"
        withBorder
        onClick={handleClick}
        style={{ cursor: onCardClick ? 'pointer' : 'default' }}
      >
        {variant === 'stack' ? renderStackVariant() : renderFlexVariant()}
      </Card>
    </Box>
  );
};

export default ResponsiveGridRow;
