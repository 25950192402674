// MessageComposerCore.tsx
import {
  MessageRequest,
  MessageRequestMessageContent,
  MessageTargetRequest,
} from '@btrway/api-messaging';
import { ContentEditor, DynamicContentPanel } from '@btrway/content-editor';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Box, Stack, TextInput } from '@mantine/core';
import { Editor } from '@tiptap/react';
import React, { useState } from 'react';
import { MessageTargetList } from '../MessageTargetList/MessageTargetList';
import { MessageTemplateSelector } from '../MessageTemplateSelector/MessageTemplateSelector';
import { RightPanel } from '../RightPanel/RightPanel';
import MessageAttachmentPanel from '../MessageAttachmentPanel/MessageAttachmentPanel';

interface TemplateSelection {
  id: number;
  templateHtml: string;
}

export interface MessageComposerCoreProps {
  initialMessage?: Partial<MessageRequest>;
  onChange?: (
    message: Pick<
      MessageRequest,
      | 'messageContent'
      | 'messageSubject'
      | 'messageTargets'
      | 'templateId'
      | 'templateHtml'
    >
  ) => void;
  showTargetSelector?: boolean;
}

export const MessageComposerCore: React.FC<MessageComposerCoreProps> = ({
  initialMessage,
  onChange,
  showTargetSelector = true,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [message, setMessage] = React.useState<Partial<MessageRequest>>(() => ({
    messageSubject: initialMessage?.messageSubject || '',
    messageContent: initialMessage?.messageContent,
    messageTargets: initialMessage?.messageTargets || [],
    templateId: initialMessage?.templateId,
    templateHtml: initialMessage?.templateHtml,
    storageKeys: initialMessage?.storageKeys || [], // Add this line
  }));

  const handleAddTarget = (target: MessageTargetRequest) => {
    const newTargets = [...(message.messageTargets || []), target];
    const updatedMessage = { ...message, messageTargets: newTargets };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const handleRemoveTarget = (targetId: number) => {
    const newTargets = message.messageTargets?.filter(
      (target) => target.entityId !== targetId
    );
    const updatedMessage = { ...message, messageTargets: newTargets };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const handleContentChange = (
    json: MessageRequestMessageContent,
    html: string
  ) => {
    const updatedMessage = {
      ...message,
      messageContent: json,
      messageHtml: html,
    };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const handleTemplateChange = (selection: TemplateSelection | null) => {
    const updatedMessage = {
      ...message,
      templateId: selection?.id,
      templateHtml: selection?.templateHtml,
    };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const handleStorageKeysChange = (storageKeys: string[]) => {
    const updatedMessage = {
      ...message,
      storageKeys,
    };
    setMessage(updatedMessage);
    onChange?.(updatedMessage);
  };

  const togglePanel = () => {
    setIsPanelVisible((prev) => !prev);
  };

  return (
    <Box style={{ display: 'flex', gap: '16px', height: '100%', minHeight: 0 }}>
      {/* Column 1: Message Target List */}
      {showTargetSelector && (
        <Box w="20%" miw={300}>
          <MessageAttachmentPanel
            storageKeys={message.storageKeys || []}
            onStorageKeysChange={handleStorageKeysChange}
          />
          <br />          
          <MessageTemplateSelector onChange={handleTemplateChange} />
          <br />
          <MessageTargetList
            targets={message.messageTargets || []}
            onAddTarget={handleAddTarget}
            onRemoveTarget={handleRemoveTarget}
          />
        </Box>
      )}

      {/* Column 2: Subject and Editor */}
      <Stack gap="md" style={{ flex: 1, minHeight: 0 }}>
        <TextInput
          placeholder="Enter Subject..."
          value={message.messageSubject}
          onChange={(e) => {
            const updatedMessage = {
              ...message,
              messageSubject: e.target.value,
            };
            setMessage(updatedMessage);
            onChange?.(updatedMessage);
          }}
        />
        <Box style={{ flex: 1, minHeight: 0 }}>
          <ContentEditor
            initialContent={message.messageContent}
            onChange={handleContentChange}
            onEditorReady={setEditor}
          />
        </Box>
      </Stack>

      {/* Column 3: Right Panel with DynamicContentPanel */}
      {editor && (
        <RightPanel
          title="Dynamic Content"
          isVisible={isPanelVisible}
          onToggle={togglePanel}
        >
          <DynamicContentPanel editor={editor} />
        </RightPanel>
      )}
    </Box>
  );
};
