import {
  CertificationInstanceGridResponse,
  useGetCertificationInstanceGrid,
} from '@btrway/api-workflow';
import { useEffect, useMemo, useState } from 'react';
import { GroupedCertification } from '../types/certificationTypes';

export const useCertificationInstances = (
  workgroupId: number,
  organizationId: number
) => {
  const { mutate: getCertificationInstances } =
    useGetCertificationInstanceGrid();
  const [certifications, setCertifications] = useState<
    CertificationInstanceGridResponse[]
  >([]);
  const [loading, setLoading] = useState(true);

  const fetchCertifications = async () => {
    if (!workgroupId) return;

    setLoading(true);
    try {
      const request = {
        organizationId,
        filterByWorkgroupIds: [workgroupId],
        startRow: 0,
        endRow: 1000,
        sortFields: ['certificationInstanceId'],
        sortOrders: ['desc'],
      };

      getCertificationInstances(
        { data: request },
        {
          onSuccess: (response) => {
            setCertifications(response.content);
            setLoading(false);
          },
          onError: (error) => {
            console.error('Error fetching certifications:', error);
            setLoading(false);
          },
        }
      );
    } catch (error) {
      console.error('Error fetching certifications:', error);
      setLoading(false);
    }
  };

  const groupedCertifications = useMemo(() => {
    const groupedMap = certifications.reduce((acc, curr) => {
      if (!acc.has(curr.certificationInstanceId)) {
        acc.set(curr.certificationInstanceId, {
          certificationInstanceId: curr.certificationInstanceId,
          organizationId: curr.organizationId,
          workgroupId: curr.workgroupId,
          workgroupName: curr.workgroupName || 'Workgroup Name Missing',
          certificationName:
            curr.certificationName || 'Certification Name Missing',
          assignedEntityType: curr.assignedEntityType,
          assignedEntityId: curr.assignedEntityId,
          assignedEntityName:
            curr.assignedEntityName || 'Assigned Entity Name Missing',
          completed: true,
          requirements: [],
        });
      }

      const group = acc.get(curr.certificationInstanceId)!;
      group.requirements.push(curr);
      group.completed = group.completed && curr.completed;

      return acc;
    }, new Map<number, GroupedCertification>());

    // Convert to array and sort by assignedEntityName
    return Array.from(groupedMap.values()).sort((a, b) =>
      (a.assignedEntityName || '').localeCompare(b.assignedEntityName || '')
    );
  }, [certifications]);

  useEffect(() => {
    fetchCertifications();
  }, [workgroupId, organizationId]);

  return {
    groupedCertifications,
    loading,
    refresh: fetchCertifications,
  };
};
