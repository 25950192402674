import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useTaskManager } from '@btrway/task-manager';
import { Box, Card, Flex, Stack } from '@mantine/core';
import React from 'react';
import { CertificationsTopSection } from '../../components/CertificationsTopSection/CertificationsTopSection';
import CertificationsView from '../../components/CertificationsView/CertificationsView';
import styles from './CertificationsPage.module.css';

const CertificationsPage: React.FC = () => {
  const { currentPerson } = useAuthenticatedUser();
  const { tasks } = useTaskManager();

  const certificationTasks = tasks.filter(
    (task) => task.rollupWorkflowType === WorkflowTypeEnum.certification
  );

  // const { fetchTasksWithCriteria } = useTaskFetcher();

  // useEffect(() => {
  //   if (currentPerson.id) {
  //     fetchTasksWithCriteria([currentPerson.id], {
  //       includeRoleBasedTasks: true,
  //       workflowType: undefined, // Fetch all workflow types
  //     });
  //   }
  // }, [currentPerson.id, fetchTasksWithCriteria]);

  return (
    <Stack p={0} gap="md" bg="gray.3">
      <Card className={styles.topSection}>
        <CertificationsTopSection />
      </Card>
      <Flex p="lg" gap="md">
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Stack gap="md">
              <CertificationsView />
            </Stack>
          </Stack>
        </Box>
        {/* <Box visibleFrom="md" style={{ flexShrink: 0 }}>
          <CalendarWidget />
        </Box> */}
      </Flex>
    </Stack>
  );
};

export default CertificationsPage;
