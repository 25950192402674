import {
  WorkflowTemplateRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useWorkflowTemplates } from '@btrway/workflow-manager';
import { Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import React from 'react';
import AddTaskTemplateModal from '../AddTaskTemplateModal/AddTaskTemplateModal';
import TaskListImportModal from '../TaskListImportModal/TaskListImportModal';

const workflowType = WorkflowTypeEnum.taskList;

const AddTaskTemplateButtons: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { addWorkflowTemplate } = useWorkflowTemplates();

  const handleCreateTaskTemplate = async (
    partialWorkflowTemplate: Partial<WorkflowTemplateRequest>
  ) => {
    addWorkflowTemplate(partialWorkflowTemplate);
  };

  return (
    <Group justify="flex-end" mb="md">
      <Button onClick={open} leftSection={<IconPlus size={16} />}>
        Add New Template
      </Button>
      <AddTaskTemplateModal
        workflowType={workflowType}
        opened={opened}
        onClose={close}
        onCreateRoutine={handleCreateTaskTemplate}
      />
      {workflowType === WorkflowTypeEnum.taskList && <TaskListImportModal />}
    </Group>
  );
};

export default AddTaskTemplateButtons;
