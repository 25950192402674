import { WorkgroupTaskStatisticsResponse } from '@btrway/api-workflow';
import { Card, Group, Progress, Stack, Text } from '@mantine/core';
import React from 'react';

interface TaskStatisticsDetailProps {
  statistics: WorkgroupTaskStatisticsResponse;
}

export const TaskStatisticsDetail: React.FC<TaskStatisticsDetailProps> = ({
  statistics,
}) => (
  <Stack mt="lg" gap="xs">
    {statistics.workflowStatistics?.map((workflow, index) => (
      <Card key={index} padding="sm" radius="sm" withBorder>
        <Stack gap="xs">
          <Group justify="space-between">
            <Text size="sm" fw={500}>
              {workflow.workflowName}
            </Text>
            <Group gap="lg">
              <Text size="sm" fw={500} color="dimmed">
                {workflow.completedTaskLists}/{workflow.totalTaskLists} Task
                Lists
              </Text>
              <Text size="sm" fw={500} color="dimmed">
                {workflow.completedTasks}/{workflow.totalTasks} Tasks
              </Text>
              <Text size="sm" fw={500} color="dimmed">
                {workflow.overdueCompletedTasks} overdue
              </Text>
              <Text size="sm" fw={500} color="dimmed">
                {workflow.averageOverdueDays?.toFixed(1)} days avg
              </Text>
            </Group>
          </Group>
          <Progress
            value={workflow.taskCompletionPercentage || 0}
            size="xs"
            radius="sm"
          />
        </Stack>
      </Card>
    ))}
  </Stack>
);
