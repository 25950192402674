import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { useInitialCoursewareProgress } from '@btrway/courseware-progress-manager';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { currentChapterAtom } from '../atoms/currentChapterAtom';

interface CourseManagerResult {
  currentChapter: ChapterDTO | null;
  isLoading: boolean;
  goToNextChapter: () => void;
  goToChapter: (chapterKey: string) => void;
  initialCurrentTime: number;
  initialMaxTime: number;
  isCourseComplete: boolean;
}

export const useCourseManager = (
  course: AssetResponse | null,
  resume?: boolean
): CourseManagerResult => {
  const [currentChapter, setCurrentChapter] = useAtom(currentChapterAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [initialCurrentTime, setInitialCurrentTime] = useState(0);
  const [initialMaxTime, setInitialMaxTime] = useState(0);
  const [isCourseComplete, setIsCourseComplete] = useState(false);
  const currentChapterIndexRef = useRef<number>(0);

  const { getInitialFirstIncompleteChapter, getInitialChapterProgress } =
    useInitialCoursewareProgress();

  const chapters = course?.chapters || [];

  const checkCourseCompletion = useCallback(() => {
    const totalChapters = chapters.length;
    const isLastChapter = currentChapterIndexRef.current === totalChapters - 1;

    if (isLastChapter) {
      // If we're on the last chapter and it's complete, the course is complete
      const lastChapterProgress = getInitialChapterProgress(
        chapters[currentChapterIndexRef.current]
      );
      if (lastChapterProgress.isComplete) {
        setIsCourseComplete(true);
      }
    }
  }, [chapters, getInitialChapterProgress]);

  const initializeCurrentChapter = useCallback(() => {
    if (chapters.length > 0) {
      if (resume && course) {
        const result = getInitialFirstIncompleteChapter(course);

        if (result) {
          setCurrentChapter(result.chapter);
          currentChapterIndexRef.current = chapters.findIndex(
            (c) => c.key === result.chapter.key
          );
          if (result.progress) {
            setInitialCurrentTime(result.progress.currentTimeSeconds);
            setInitialMaxTime(result.progress.maxTimeSeconds);
          } else {
            setInitialCurrentTime(0);
            setInitialMaxTime(0);
          }
        }
      } else {
        const firstChapter = chapters[0];
        setCurrentChapter(firstChapter);
        currentChapterIndexRef.current = 0;
        const progress = getInitialChapterProgress(firstChapter);
        setInitialCurrentTime(0);
        setInitialMaxTime(progress.maxTimeSeconds);
      }
    } else {
      setCurrentChapter(null);
      currentChapterIndexRef.current = -1;
      setInitialCurrentTime(0);
      setInitialMaxTime(0);
    }
  }, [
    chapters,
    resume,
    course,
    getInitialFirstIncompleteChapter,
    getInitialChapterProgress,
  ]);

  useEffect(() => {
    setIsLoading(true);
    if (!course || chapters.length === 0) {
      setCurrentChapter(null);
      currentChapterIndexRef.current = -1;
      setInitialCurrentTime(0);
      setInitialMaxTime(0);
      setIsLoading(false);
      return;
    }

    initializeCurrentChapter();
    setIsLoading(false);
  }, [course, chapters, initializeCurrentChapter]);

  const goToNextChapter = useCallback(() => {
    if (!currentChapter) {
      return;
    }

    const currentIndex = chapters.findIndex(
      (cc) => cc.key === currentChapter.key
    );

    if (currentIndex !== -1 && currentIndex < chapters.length - 1) {
      const nextChapter = chapters[currentIndex + 1];
      currentChapterIndexRef.current = currentIndex + 1;
      setCurrentChapter(nextChapter);
      const progress = getInitialChapterProgress(nextChapter);
      setInitialCurrentTime(0);
      setInitialMaxTime(progress.maxTimeSeconds);
    } else {
      // We're at the last chapter, check for course completion
      checkCourseCompletion();
    }
  }, [
    currentChapter,
    chapters,
    getInitialChapterProgress,
    checkCourseCompletion,
  ]);

  const goToChapter = useCallback(
    (chapterKey: string) => {
      const newIndex = chapters.findIndex((cc) => cc.key === chapterKey);
      if (newIndex !== -1) {
        const chapter = chapters[newIndex];
        currentChapterIndexRef.current = newIndex;
        setCurrentChapter(chapter);
        const progress = getInitialChapterProgress(chapter);
        setInitialCurrentTime(0);
        setInitialMaxTime(progress.maxTimeSeconds);
      }
    },
    [chapters, getInitialChapterProgress]
  );

  return {
    currentChapter,
    isLoading,
    goToNextChapter,
    goToChapter,
    initialCurrentTime,
    initialMaxTime,
    isCourseComplete,
  };
};
