import {
  getGetAllActiveHelpResourcesQueryKey,
  getGetHelpResourcesByRepositoryQueryKey,
  HelpRepositoryEnum,
  HelpResourceRequest,
  HelpResourceResponse,
  useDeleteHelpResource,
  useGetAllActiveHelpResources,
  useGetHelpResourcesByRepository,
  useSaveHelpResource,
} from '@btrway/api-help';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useHelpResources = (repository?: HelpRepositoryEnum) => {
  const queryClient = useQueryClient();

  // Get all active help resources with caching
  const {
    data: allActiveResources,
    isLoading: isLoadingActive,
    error: activeError,
    refetch: refetchActive,
  } = useGetAllActiveHelpResources({
    query: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      queryKey: getGetAllActiveHelpResourcesQueryKey(),
    },
  });

  // Get help resources by repository with caching
  const {
    data: repositoryResources,
    isLoading: isLoadingRepository,
    error: repositoryError,
    refetch: refetchRepository,
  } = useGetHelpResourcesByRepository(repository ?? '', {
    query: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      queryKey: getGetHelpResourcesByRepositoryQueryKey(repository ?? ''),
      enabled: !!repository, // Only fetch if repository is provided
    },
  });

  // Save help resource with cache invalidation
  const { mutateAsync: saveHelpResourceMutation } = useSaveHelpResource();
  const saveHelpResource = useCallback(
    async (helpResource: HelpResourceRequest) => {
      await saveHelpResourceMutation({ data: helpResource });
      // Invalidate both queries to ensure fresh data
      await queryClient.invalidateQueries({
        queryKey: getGetAllActiveHelpResourcesQueryKey(),
      });
      if (repository) {
        await queryClient.invalidateQueries({
          queryKey: getGetHelpResourcesByRepositoryQueryKey(repository),
        });
      }
    },
    [saveHelpResourceMutation, queryClient, repository]
  );

  // Delete help resource with cache invalidation
  const { mutateAsync: deleteHelpResourceMutation } = useDeleteHelpResource();
  const deleteHelpResource = useCallback(
    async (helpResourceId: number) => {
      await deleteHelpResourceMutation({ helpResourceId });
      // Invalidate both queries to ensure fresh data
      await queryClient.invalidateQueries({
        queryKey: getGetAllActiveHelpResourcesQueryKey(),
      });
      if (repository) {
        await queryClient.invalidateQueries({
          queryKey: getGetHelpResourcesByRepositoryQueryKey(repository),
        });
      }
    },
    [deleteHelpResourceMutation, queryClient, repository]
  );

  // Helper function to get a help resource by key
  const getHelpResourceByKey = useCallback(
    (key: string): HelpResourceResponse | undefined => {
      const resources = repository ? repositoryResources : allActiveResources;
      return resources?.find((resource) => resource.helpResourceKey === key);
    },
    [repository, repositoryResources, allActiveResources]
  );

  return {
    // All active resources
    allHelpResources: allActiveResources ?? [],
    isLoadingActive,
    activeError,
    refetchAllResources: refetchActive,

    // Repository-specific resources
    repositoryHelpResources: repositoryResources ?? [],
    isLoadingRepository,
    repositoryError,
    refetchRepositoryResources: refetchRepository,

    // Mutations
    saveHelpResource,
    deleteHelpResource,

    // Helpers
    getHelpResourceByKey,
  };
};
