import {
  getGetStepTypesQueryKey,
  StepTypeEnum,
  useGetStepTypes,
} from '@btrway/api-workflow';
import { useCallback } from 'react';

export const useStepTypes = () => {
  const {
    data: stepTypes,
    isLoading,
    error,
    refetch,
  } = useGetStepTypes({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetStepTypesQueryKey(),
    },
  });

  const getStepType = useCallback(
    (stepTypeCode: StepTypeEnum) => {
      return stepTypes?.find((type) => type.code === stepTypeCode);
    },
    [stepTypes]
  );

  return {
    stepTypes: stepTypes ?? [],
    isLoading,
    error,
    getStepType,
    refetchStepTypes: refetch,
  };
};
