import { WorkflowTemplateSettings } from '@btrway/api-workflow';
import { useOrganizationTypes } from '@btrway/reference-data-manager';
import { useUserRoles } from '@btrway/security-manager';
import { useSettingsDisplay } from '@btrway/workflow-builder-settings-panel';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-configuration-manager';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { DeploymentFilterEdit } from '../DeploymentFilterEdit/DeploymentFilterEdit';

interface DeploymentFilterViewProps {
  workflow?: CommonWorkflow | null;
}

export const DeploymentFilterView: React.FC<DeploymentFilterViewProps> = ({
  workflow,
}) => {
  const { openDisplay, closeDisplay } = useSettingsDisplay();
  const workflowService = useWorkflowService();
  const { getOrganizationTypeById } = useOrganizationTypes();
  const { getUserRoleBySlug } = useUserRoles();
  const [templateSettings, setTemplateSettings] =
    useState<WorkflowTemplateSettings | null>(
      workflow?.templateSettings || null
    );

  if (!workflow || workflow.sourceType !== 'template') {
    return null;
  }

  const handleSave = async (newTemplateSettings: WorkflowTemplateSettings) => {
    console.log('newTemplateSettings', newTemplateSettings);
    const updatedWorkflow = {
      ...workflow,
      templateSettings: newTemplateSettings,
    };

    setTemplateSettings(newTemplateSettings || {});
    workflowService.updateWorkflow(updatedWorkflow);
  };

  const handleButtonClick = () => {
    openDisplay(
      <DeploymentFilterEdit
        templateSettings={templateSettings}
        onSave={handleSave}
      />,
      {
        mode: 'panel',
        position: 'right',
        modalProps: { title: 'Configure Deployment Filters' },
      }
    );
  };

  const renderFilterSummary = () => {
    const states = templateSettings?.organizationFilter?.stateCodes || [];
    const orgTypeIds =
      templateSettings?.organizationFilter?.organizationTypeIds || [];

    if (!states.length && !orgTypeIds.length) {
      return <Text fz="md">All Organizations</Text>;
    }

    return (
      <Stack p={0} gap={0}>
        {orgTypeIds.length > 0 && (
          <Text fz="md">
            Org Types:{' '}
            {orgTypeIds
              .map((id) => getOrganizationTypeById(id)?.name)
              .filter(Boolean)
              .sort()
              .join(', ')}
          </Text>
        )}
        {states.length > 0 && (
          <Text fz="md">States: {[...states].sort().join(', ')}</Text>
        )}
      </Stack>
    );
  };

  const renderRoleSummary = () => {
    const roles = templateSettings?.defaultUserRoles || [];

    if (!roles.length) {
      return <Text fz="md">None</Text>;
    }

    const roleNames = roles
      .map((slug) => {
        const role = getUserRoleBySlug(slug);
        return role?.name;
      })
      .filter(Boolean)
      .sort()
      .join(', ');

    return <Text fz="md">{roleNames}</Text>;
  };

  return (
    <Card
      padding="xs"
      mb="sm"
      w="100%"
      radius="md"
      bg="red.1"
      withBorder
      style={{ border: '1px solid var(--mantine-color-gray-5)' }}
    >
      <Group justify="space-between" align="flex-start" wrap="nowrap">
        <Stack gap="xs">
          <Group justify="space-between" align="flex-start" wrap="nowrap">
            <Group
              align="flex-start"
              wrap="nowrap"
              style={{ flex: 1, gap: '8px' }}
            >
              <Text fz="md" fw={600} style={{ whiteSpace: 'nowrap' }}>
                Deployable to:
              </Text>
              {renderFilterSummary()}
            </Group>
          </Group>
          <Group align="flex-start" wrap="nowrap" style={{ gap: '8px' }}>
            <Text fz="md" fw={600} style={{ whiteSpace: 'nowrap' }}>
              Default Roles:
            </Text>
            {renderRoleSummary()}
          </Group>
        </Stack>
        <Group justify="flex-end">
          <Button size="compact-sm" onClick={handleButtonClick}>
            Edit
          </Button>
        </Group>
      </Group>
    </Card>
  );
};
