import { StepConfig, TaskTypeEnum } from '@btrway/api-workflow';
import { useFormBuilder } from '@btrway/form-builder';
import { TaskTypeIcon } from '@btrway/workflow-components';
import {
  CommonWorkflow,
  useSelectedItem,
} from '@btrway/workflow-configuration-manager';
import { Button, Card, Group, Stack, Text } from '@mantine/core';
import React from 'react';

interface FormCardProps {
  startStep: StepConfig;
  formStep: StepConfig;
  workflow: CommonWorkflow;
}

const FormCard: React.FC<FormCardProps> = ({
  startStep,
  formStep,
  workflow,
}) => {
  const { selectedItem, setSelectedItem } = useSelectedItem();
  const { openFormBuilder } = useFormBuilder();

  const formStepKey = formStep.stepKey;
  const startStepKey = startStep.stepKey;

  const handleShowFormStepFieldsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedItem({ type: 'step', key: formStepKey });
    openFormBuilder(formStepKey, true); // Enable assistant for form fields
  };

  const handleShowStartStepFieldsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedItem({ type: 'step', key: startStepKey });
    openFormBuilder(startStepKey, false); // Disable assistant for start step
  };

  const isSelected =
    selectedItem?.type === 'step' &&
    (selectedItem.key === formStepKey || selectedItem.key === startStepKey);

  return (
    <Card
      p="md"
      w="100%"
      mih={200}
      radius="md"
      withBorder
      shadow="xs"
      style={{
        marginBottom: 0,
        borderColor: isSelected ? 'blue' : undefined,
        borderWidth: isSelected ? 2 : 1,
      }}
    >
      <Stack gap="sm" justify="space-between" h={160}>
        <Stack gap="sm" justify="flex-start">
          <Group justify="space-between" gap="xs" wrap="nowrap">
            <Group
              gap="xs"
              wrap="nowrap"
              justify="flex-start"
              align="flex-start"
            >
              <TaskTypeIcon iconName={TaskTypeEnum.submitForm} size={24} />
              <Text fz="md" fw={600}>
                {workflow.name}
              </Text>
            </Group>
          </Group>
          <Text fz="sm" fw={500}>
            High level information and settings about the form could be
            displayed here.
          </Text>
        </Stack>
        <Group justify="space-between" gap="xs" wrap="nowrap">
          <Button
            variant="outline"
            size="xs"
            onClick={handleShowStartStepFieldsClick}
          >
            Form is about...
          </Button>
          <Button
            variant="light"
            size="xs"
            onClick={handleShowFormStepFieldsClick}
          >
            Configure Form Fields
          </Button>
        </Group>
      </Stack>
    </Card>
  );
};

export default FormCard;
