import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { forcedDarkModeAtom } from '../atoms/themeAtoms';

export const ThemeEffectWrapper: React.FC<{
  children: React.ReactNode;
  forceDarkMode?: boolean;
}> = ({ children, forceDarkMode }) => {
  const [, setForcedDarkMode] = useAtom(forcedDarkModeAtom);
  const transitionTimeoutRef = useRef<number>();

  useEffect(() => {
    if (forceDarkMode) {
      // Add transition class before changing mode
      document.documentElement.classList.add('theme-transition');
      setForcedDarkMode(true);

      // Remove transition class after transition completes
      transitionTimeoutRef.current = window.setTimeout(() => {
        document.documentElement.classList.remove('theme-transition');
      }, 200) as unknown as number;

      // Cleanup function
      return () => {
        // Add transition class before cleanup
        document.documentElement.classList.add('theme-transition');
        setForcedDarkMode(false);

        if (transitionTimeoutRef.current) {
          window.clearTimeout(transitionTimeoutRef.current);
        }

        // Schedule removal of transition class
        const cleanupTimeout = window.setTimeout(() => {
          document.documentElement.classList.remove('theme-transition');
        }, 200);

        // Clear the cleanup timeout if component unmounts during transition
        window.setTimeout(() => {
          window.clearTimeout(cleanupTimeout);
        }, 200);
      };
    }
  }, [forceDarkMode, setForcedDarkMode]);

  return <>{children}</>;
};
