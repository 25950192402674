// hooks/useSelectionHandlers.ts
import {
  CertificationConfig,
  CertificationTypeEnum,
  TaskConfig,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { useCallback } from 'react';
import { Screen } from '../types/screen';
import { State } from '../types/state';

interface UseSelectionHandlersProps {
  state: State;
  openScreen: (screen: Screen) => void;
  openTaskConfigScreen: (config: TaskConfig, isNew: boolean) => void;
  openStepConfigScreen: (config: any, isNew: boolean, width?: number) => void;
  updateTaskConfig: (config: TaskConfig) => void;
  updateCertificationConfig: (config: CertificationConfig) => void;
  createWorkflowTaskConfig: (
    workflowKey: string,
    name: string,
    taskType: TaskTypeEnum
  ) => TaskConfig;
  createWorkflowCertificationConfig: (
    workflowKey: string,
    name: string,
    certType: CertificationTypeEnum
  ) => CertificationConfig;
  createCourseCertificationConfig: (
    courseUid: string,
    courseName: string
  ) => CertificationConfig;
  createCourseTaskConfig: (courseUid: string, courseName: string) => TaskConfig;
  createStepConfig: (type: 'step' | 'task', taskType: TaskTypeEnum) => any;
  parentWorkflow: CommonWorkflow | null;
}

export const useSelectionHandlers = ({
  state,
  parentWorkflow,
  openScreen,
  openTaskConfigScreen,
  openStepConfigScreen,
  updateTaskConfig,
  updateCertificationConfig,
  createWorkflowTaskConfig,
  createWorkflowCertificationConfig,
  createCourseCertificationConfig,
  createCourseTaskConfig,
  createStepConfig,
}: UseSelectionHandlersProps) => {
  const handleWorkflowSelect = useCallback(
    (workflowKey: string, name: string, isNew: boolean) => {
      const currentScreen = state.currentScreen;

      if (!currentScreen || currentScreen.type !== 'WORKFLOW_SELECTOR') return;

      const { taskType } = currentScreen.data;

      // Handle special cases for curriculum and form packet
      if (isNew && taskType === TaskTypeEnum.completeCurriculum) {
        openScreen({
          type: 'CURRICULUM_EDITOR',
          data: {
            initialSelectedTasks: [],
            workflowKey,
            workflowName: name,
            showTaskConfigAfterSave: true,
          },
        });
        return;
      }

      if (isNew && taskType === TaskTypeEnum.completeFormPacket) {
        openScreen({
          type: 'FORM_PACKET_EDITOR',
          data: {
            initialSelectedTasks: [],
            workflowKey,
            workflowName: name,
            showTaskConfigAfterSave: true,
          },
        });
        return;
      }

      const isCertificationWorkflow =
        parentWorkflow?.workflowType === WorkflowTypeEnum.certification;
      const isAutomationOrFormWorkflow =
        parentWorkflow?.workflowType === WorkflowTypeEnum.automation ||
        parentWorkflow?.workflowType === WorkflowTypeEnum.form;

      if (isCertificationWorkflow) {
        const certType = taskType as unknown as CertificationTypeEnum;
        const newConfig = state.currentCertificationConfig
          ? {
              ...state.currentCertificationConfig,
              workflowKey,
              title: name,
            }
          : createWorkflowCertificationConfig(workflowKey, name, certType);

        updateCertificationConfig(newConfig);
        openScreen({
          type: 'CERTIFICATION_CONFIG',
          data: {
            config: newConfig,
            workflow: parentWorkflow!,
            isNew: !state.currentCertificationConfig,
          },
        });
      } else if (isAutomationOrFormWorkflow) {
        const taskConfig = state.currentTaskConfig
          ? {
              ...state.currentTaskConfig,
              metadata: { ...state.currentTaskConfig.metadata, title: name },
            }
          : createWorkflowTaskConfig(workflowKey, name, taskType);

        const newStep = createStepConfig('task', taskType);
        if (newStep.taskConfig) {
          newStep.taskConfig = {
            ...newStep.taskConfig,
            taskKey: taskConfig.taskKey,
            taskType: taskConfig.taskType,
            taskProperties: taskConfig.taskProperties,
            metadata: taskConfig.metadata,
          };
        }

        updateTaskConfig(taskConfig);
        openStepConfigScreen(newStep, !state.currentTaskConfig);
      } else {
        const newConfig = state.currentTaskConfig
          ? {
              ...state.currentTaskConfig,
              metadata: { ...state.currentTaskConfig.metadata, title: name },
            }
          : createWorkflowTaskConfig(workflowKey, name, taskType);

        updateTaskConfig(newConfig);
        openTaskConfigScreen(newConfig, !state.currentTaskConfig);
      }
    },
    [
      state.currentScreen,
      state.currentTaskConfig,
      state.currentCertificationConfig,
      parentWorkflow,
      openScreen,
      openTaskConfigScreen,
      openStepConfigScreen,
      updateCertificationConfig,
      updateTaskConfig,
      createWorkflowTaskConfig,
      createWorkflowCertificationConfig,
      createStepConfig,
    ]
  );

  const handleCourseSelect = useCallback(
    (courseUid: string, courseName: string) => {
      const currentScreen = state.currentScreen;

      if (!currentScreen || currentScreen.type !== 'COURSE_SELECTOR') return;

      const { taskType } = currentScreen.data;
      const isCertificationWorkflow =
        parentWorkflow?.workflowType === WorkflowTypeEnum.certification;
      const isAutomationOrFormWorkflow =
        parentWorkflow?.workflowType === WorkflowTypeEnum.automation ||
        parentWorkflow?.workflowType === WorkflowTypeEnum.form;

      if (isCertificationWorkflow) {
        const newConfig = state.currentCertificationConfig
          ? {
              ...state.currentCertificationConfig,
              title: courseName,
              taskProperties: {
                courseSelection: {
                  courseUid: { value: courseUid },
                },
              },
            }
          : createCourseCertificationConfig(courseUid, courseName);

        updateCertificationConfig(newConfig);
        openScreen({
          type: 'CERTIFICATION_CONFIG',
          data: {
            config: newConfig,
            workflow: parentWorkflow!,
            isNew: !state.currentCertificationConfig,
          },
        });
      } else if (isAutomationOrFormWorkflow) {
        const taskConfig = state.currentTaskConfig
          ? {
              ...state.currentTaskConfig,
              metadata: {
                ...state.currentTaskConfig.metadata,
                title: courseName,
              },
              taskProperties: {
                courseSelection: {
                  courseUid: { value: courseUid },
                },
              },
            }
          : createCourseTaskConfig(courseUid, courseName);

        const newStep = createStepConfig('task', taskConfig.taskType);
        if (newStep.taskConfig) {
          newStep.taskConfig = {
            ...newStep.taskConfig,
            taskKey: taskConfig.taskKey,
            taskType: taskConfig.taskType,
            taskProperties: taskConfig.taskProperties,
            metadata: taskConfig.metadata,
          };
        }

        updateTaskConfig(taskConfig);
        openStepConfigScreen(newStep, !state.currentTaskConfig);
      } else {
        const newConfig = state.currentTaskConfig
          ? {
              ...state.currentTaskConfig,
              metadata: {
                ...state.currentTaskConfig.metadata,
                title: courseName,
              },
              taskProperties: {
                courseSelection: {
                  courseUid: { value: courseUid },
                },
              },
            }
          : createCourseTaskConfig(courseUid, courseName);

        updateTaskConfig(newConfig);
        openTaskConfigScreen(newConfig, !state.currentTaskConfig);
      }
    },
    [
      state.currentScreen,
      state.currentTaskConfig,
      state.currentCertificationConfig,
      parentWorkflow,
      openScreen,
      openTaskConfigScreen,
      openStepConfigScreen,
      updateCertificationConfig,
      updateTaskConfig,
      createCourseCertificationConfig,
      createCourseTaskConfig,
      createStepConfig,
    ]
  );

  return {
    handleWorkflowSelect,
    handleCourseSelect,
  };
};
