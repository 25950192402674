import { CustomTaskProperties } from '@btrway/api-workflow';
import { Box, Button, Group, Stack, Text, Textarea } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import React, { useState } from 'react';
import { useTaskActionContext } from '../../context/TaskActionContext/TaskActionContext';
import { TaskComponentProps } from '../../types/TaskComponentProps';

const CustomTask: React.FC<TaskComponentProps> = ({
  taskProperties,
  taskInstanceId,
  onClose,
}) => {
  const [notes, setNotes] = useState('');
  const [effectiveDate, setEffectiveDate] = useState<Date | null>(null);
  const { completeTask } = useTaskActionContext();

  const customTaskProps = taskProperties as CustomTaskProperties;
  const { title, shortDescription, longDescription } = customTaskProps;

  const handleSubmit = async () => {
    try {
      if (!effectiveDate) {
        // You might want to add proper error handling/validation here
        return;
      }

      const taskData = {
        customData: {
          notes: notes,
          effectiveDate: effectiveDate.toISOString(),
        },
      };

      const result = await completeTask(
        `Custom task completed with notes and effective date: ${effectiveDate.toLocaleDateString()}`,
        taskData
      );

      if (result.success) {
        onClose();
      }
    } catch (error) {
      console.error('Failed to complete custom task:', error);
    }
  };

  return (
    <Stack style={{ height: '100%' }} gap={0}>
      <Box
        style={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 20px',
          borderBottom: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Text size="xl" fw={700}>
          {title}
        </Text>
      </Box>

      <Box style={{ flex: 1, overflow: 'auto', padding: '20px' }}>
        <Stack gap="md">
          {shortDescription && (
            <Text size="sm" c="dimmed">
              {shortDescription}
            </Text>
          )}

          {longDescription && <Text>{longDescription}</Text>}

          <DatePickerInput
            label="Effective Date"
            placeholder="Pick date"
            value={effectiveDate}
            onChange={setEffectiveDate}
          />

          <Textarea
            label="Notes"
            placeholder="Enter your notes here"
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            rows={8}
          />
        </Stack>
      </Box>

      <Group
        justify="center"
        gap="md"
        style={{
          padding: '20px',
          borderTop: '1px solid var(--mantine-color-gray-2)',
        }}
      >
        <Button onClick={onClose} variant="outline" size="md">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          size="md"
          disabled={!effectiveDate || !notes.trim()}
        >
          Submit
        </Button>
      </Group>
    </Stack>
  );
};

export default CustomTask;
