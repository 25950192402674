import { WorkflowTypeEnum } from '@btrway/api-workflow';
import { Container, Stack, Transition } from '@mantine/core';
import React from 'react';
import { useWorkgroupStats } from '../../providers/WorkgroupStatsProvider';
import { TaskStatisticsCard } from '../TaskStatisticsCard/TaskStatisticsCard';
import { TaskStatisticsSkeleton } from '../TaskStatisticsSkeleton/TaskStatisticsSkeleton';

interface WorkgroupTaskStatsViewProps {
  workflowType?: WorkflowTypeEnum;
}

export const WorkgroupTaskStatsView: React.FC<WorkgroupTaskStatsViewProps> = ({
  workflowType,
}) => {
  const { getStatsByWorkflowType, isLoading } = useWorkgroupStats();
  const [expandedWorkgroups, setExpandedWorkgroups] = React.useState<
    Set<number>
  >(new Set());

  // Use the context's filtering function instead of local filtering
  const filteredStats =
    getStatsByWorkflowType(workflowType)?.filter(
      (workgroup) => workgroup.workflows.length > 0
    ) ?? [];

  console.log(
    'WorkgroupTaskStatsView: filteredStats',
    JSON.stringify(filteredStats)
  );

  const toggleExpand = (workgroupId: number) => {
    setExpandedWorkgroups((prev) => {
      const next = new Set(prev);
      if (next.has(workgroupId)) {
        next.delete(workgroupId);
      } else {
        next.add(workgroupId);
      }
      return next;
    });
  };

  const customTransition = {
    in: { opacity: 1, transform: 'translateY(0)' },
    out: { opacity: 0, transform: 'translateY(-20px)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
  };

  if (isLoading) {
    return (
      <Container size="xl" p={0}>
        <Stack>
          <TaskStatisticsSkeleton />
        </Stack>
      </Container>
    );
  }

  return (
    <Container size="xl" p={0}>
      <Transition
        mounted={true}
        transition={customTransition}
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Stack gap="md" style={styles}>
            {filteredStats.map((workgroup) => (
              <TaskStatisticsCard
                key={workgroup.workgroupId}
                statistics={workgroup}
                isExpanded={expandedWorkgroups.has(workgroup.workgroupId!)}
                onToggleExpand={() =>
                  workgroup.workgroupId && toggleExpand(workgroup.workgroupId)
                }
              />
            ))}
          </Stack>
        )}
      </Transition>
    </Container>
  );
};
