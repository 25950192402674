import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { importConfigurationAtom } from '../atoms/importConfigurationAtom';
import { ImportConfiguration } from '../types/ImportConfiguration';
import { ImportedPerson } from '../types/ImportedPerson';

export const useImportConfiguration = () => {
  const [importConfiguration, setImportConfiguration] = useAtom(
    importConfigurationAtom
  );

  const updateImportConfiguration = useCallback(
    (update: Partial<ImportConfiguration>) => {
      setImportConfiguration((prev) => ({
        ...prev,
        ...update,
      }));
    },
    [setImportConfiguration]
  );

  const updateColumnMapping = useCallback(
    (key: keyof ImportedPerson, value: string) => {
      updateImportConfiguration({
        columnMappings: importConfiguration.columnMappings.some(
          (mapping) => mapping.targetField === key
        )
          ? importConfiguration.columnMappings.map((mapping) =>
              mapping.targetField === key
                ? { ...mapping, columnHeader: value }
                : mapping
            )
          : [
              ...importConfiguration.columnMappings,
              {
                columnIndex: -1, // You might want to set this to a proper value
                columnHeader: value,
                targetField: key,
                valueMappings: [],
              },
            ],
      });
    },
    [importConfiguration.columnMappings, updateImportConfiguration]
  );

  return {
    importConfiguration,
    updateImportConfiguration,
    updateColumnMapping,
  };
};
