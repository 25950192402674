import { AssetResponse, ChapterDTO } from '@btrway/api-courseware';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { useCurriculum } from '@btrway/courseware-manager';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { chapterProgressAtom } from '../atoms/chapterProgressAtom';
import { CoursewareProgress } from '../types/progressTypes';

export const useProgressCalculation = (
  curriculum?: WorkflowDefinitionResponse,
  course?: AssetResponse,
  chapter?: ChapterDTO
): CoursewareProgress => {
  const chapterProgress = useAtomValue(chapterProgressAtom);
  const { courses: curriculumCourses, isLoading: isCurriculumLoading } =
    useCurriculum(curriculum?.id || 0);

  const applicableChapters = useMemo(() => {
    if (chapter) {
      return [chapter];
    }
    if (course) {
      return course.chapters || [];
    }
    if (curriculum && !isCurriculumLoading) {
      return curriculumCourses.flatMap((course) => course.chapters || []);
    }
    return [];
  }, [curriculum, course, chapter, curriculumCourses, isCurriculumLoading]);

  return useMemo(() => {
    const totalTimeSeconds = applicableChapters.reduce(
      (total, chapter) => total + (chapter.duration || 0),
      0
    );

    const completedTimeSeconds = applicableChapters.reduce((total, chapter) => {
      const progress = chapterProgress.find(
        (p) => p.chapterKey === chapter.key
      );
      return total + (progress?.maxTimeSeconds || 0);
    }, 0);

    const completionPercent =
      totalTimeSeconds > 0
        ? (completedTimeSeconds / totalTimeSeconds) * 100
        : 0;

    console.log('useProgressCalculation, completionPercent', completionPercent);
    console.log('useProgressCalculation, totalTimeSeconds', totalTimeSeconds);
    console.log(
      'useProgressCalculation, completedTimeSeconds',
      completedTimeSeconds
    );
    return {
      completedTimeSeconds,
      completionPercent,
      totalTimeSeconds,
    };
  }, [applicableChapters, chapterProgress]);
};
