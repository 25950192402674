import { CourseViewer } from '@btrway/courseware-course-viewer';
import { useAsset } from '@btrway/courseware-manager';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './CourseExecutionPage.module.css';

const CourseExecutionPage: React.FC = () => {
  const navigate = useNavigate();
  const { courseUid: courseUidParam } = useParams<{ courseUid: string }>();
  const courseUid = parseInt(courseUidParam ?? '0', 10);
  const { asset: course, isLoading: isAssetLoading } = useAsset(courseUid);

  const location = useLocation();
  const resume: boolean = location.state?.resume;

  const [isVisible, setIsVisible] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Slight delay to ensure the component is mounted before fading in
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  if (isAssetLoading || !course) return <div>Loading...</div>;

  return (
    <div className={styles.pageWrapper}>
      <div className={`${styles.content} ${isVisible ? styles.visible : ''}`}>
        <CourseViewer course={course} resume={resume} onBack={handleBack} />
      </div>
    </div>
  );
};

export default CourseExecutionPage;
