import * as XLSX from 'xlsx';

export const getSheetNames = async (file: File | null): Promise<string[]> => {
  if (!file) {
    throw new Error('No file provided');
  }

  if (!(file instanceof Blob)) {
    throw new Error('Invalid file object');
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        resolve(workbook.SheetNames);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
};
