import { useGetChapterProgressByPerson } from '@btrway/api-courseware';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { chapterProgressAtom } from '../atoms/chapterProgressAtom';

export const useInitializeProgress = () => {
  const { currentPerson } = useAuthenticatedUser();
  const setChapterProgress = useSetAtom(chapterProgressAtom);

  const { data, isLoading, error } = useGetChapterProgressByPerson(
    currentPerson.id
  );

  useEffect(() => {
    if (data) {
      setChapterProgress(data);
    }
  }, [data, setChapterProgress]);

  return {
    isLoading,
    error,
  };
};
