import { useAtomValue, useSetAtom } from 'jotai';
import { formBuilderActionsAtom } from '../atoms/formBuilderActionsAtom';
import { formBuilderStateAtom } from '../atoms/formBuilderStateAtom';

export const useFormBuilder = () => {
  const state = useAtomValue(formBuilderStateAtom);
  const dispatch = useSetAtom(formBuilderActionsAtom);

  return {
    // State
    ...state,

    // Actions
    openFormBuilder: (
      stepKey: string,
      enableFormBuilderAssistant: boolean = false
    ) =>
      dispatch({
        type: 'open',
        stepKey,
        enableFormBuilderAssistant,
      }),
    closeFormBuilder: () => dispatch({ type: 'close' }),
  };
};
