// import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
// import { CloseButton, Combobox, TextInput, useCombobox } from '@mantine/core';
// import { IconSearch } from '@tabler/icons-react';
// import { atom, useAtom } from 'jotai';
// import React, { useCallback, useState } from 'react';
// import { useGlobalSearch } from '../../hooks/useGlobalSearch';
// import { SearchResultOption } from '../SearchResultOption/SearchResultOption';

// // Define the Jotai atom to store selected items
// const selectedItemsAtom = atom<GlobalSearchResponse[]>([]);

// interface GlobalSearchControlProps {
//   onSelect: (entityType: DataTypeEnum, entityId: number) => void;
//   visibleFrom?: 'sm' | 'md' | 'lg' | 'xl';
//   hiddenFrom?: 'sm' | 'md' | 'lg' | 'xl';
//   w?: number;
//   pr?: number;
//   entityTypes?: DataTypeEnum[];
//   showSelectedInInput?: boolean;
//   clearOnSelect?: boolean;
// }

// const GlobalSearchControl: React.FC<GlobalSearchControlProps> = ({
//   onSelect,
//   visibleFrom,
//   hiddenFrom,
//   w,
//   pr,
//   entityTypes,
//   showSelectedInInput = false,
//   clearOnSelect = true,
// }) => {
//   const [selectedItems, setSelectedItems] = useAtom(selectedItemsAtom);
//   const [selectedItem, setSelectedItem] = useState<GlobalSearchResponse | null>(
//     null
//   );
//   const combobox = useCombobox({
//     onDropdownClose: () => combobox.resetSelectedOption(),
//   });

//   const { searchValue, setSearchValue, searchResults } = useGlobalSearch({
//     entityTypes,
//     initialResults: selectedItems,
//     showInitialResultsOnEmpty: true,
//   });

//   const handleOptionSubmit = useCallback(
//     (optionValue: string) => {
//       const [entityType, entityId] = optionValue.split(':');
//       const selected = searchResults.find(
//         (item) =>
//           item.entityType === entityType &&
//           item.entityId === parseInt(entityId, 10)
//       );

//       if (selected) {
//         setSelectedItem(selected);
//         setSelectedItems((prev) => {
//           if (
//             !prev.some(
//               (item) =>
//                 item.entityType === selected.entityType &&
//                 item.entityId === selected.entityId
//             )
//           ) {
//             return [...prev, selected];
//           }
//           return prev;
//         });
//         onSelect(selected.entityType as DataTypeEnum, selected.entityId);

//         if (clearOnSelect) {
//           setSearchValue('');
//         }
//       }

//       combobox.closeDropdown();
//     },
//     [
//       searchResults,
//       setSelectedItems,
//       onSelect,
//       clearOnSelect,
//       setSearchValue,
//       combobox,
//     ]
//   );

//   const handleClearSearch = useCallback(() => {
//     setSearchValue('');
//     setSelectedItem(null);
//   }, [setSearchValue]);

//   const displayValue =
//     showSelectedInInput && selectedItem
//       ? selectedItem.displayName
//       : searchValue;

//   return (
//     <Combobox
//       onOptionSubmit={handleOptionSubmit}
//       withinPortal={false}
//       store={combobox}
//     >
//       <Combobox.Target>
//         <TextInput
//           placeholder="Search..."
//           visibleFrom={visibleFrom}
//           hiddenFrom={hiddenFrom}
//           w={w}
//           pr={pr}
//           value={displayValue}
//           onChange={(event) => {
//             setSearchValue(event.currentTarget.value);
//             if (selectedItem) setSelectedItem(null);
//             combobox.resetSelectedOption();
//           }}
//           onClick={() => combobox.openDropdown()}
//           onBlur={() => combobox.closeDropdown()}
//           leftSection={<IconSearch size={18} stroke={1.5} />}
//           rightSection={
//             (searchValue || selectedItem) && (
//               <CloseButton
//                 onClick={handleClearSearch}
//                 size="sm"
//                 style={{ marginRight: '8px' }}
//               />
//             )
//           }
//         />
//       </Combobox.Target>
//       <Combobox.Dropdown hidden={searchResults.length === 0}>
//         <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
//           {searchResults.map((result) => (
//             <Combobox.Option
//               value={`${result.entityType}:${result.entityId}`}
//               key={`${result.entityType}:${result.entityId}`}
//             >
//               <SearchResultOption result={result} showSimilarity={false} />
//             </Combobox.Option>
//           ))}
//         </Combobox.Options>
//       </Combobox.Dropdown>
//     </Combobox>
//   );
// };

// export default GlobalSearchControl;

import { DataTypeEnum, GlobalSearchResponse } from '@btrway/api-core';
import { CloseButton, Combobox, TextInput, useCombobox } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { atom, useAtom } from 'jotai';
import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalSearch } from '../../hooks/useGlobalSearch';
import { SearchResultOption } from '../SearchResultOption/SearchResultOption';

// Define the Jotai atom to store selected items
const selectedItemsAtom = atom<GlobalSearchResponse[]>([]);

interface GlobalSearchControlProps {
  onSelect: (entityType: DataTypeEnum, entityId: number) => void;
  visibleFrom?: 'sm' | 'md' | 'lg' | 'xl';
  hiddenFrom?: 'sm' | 'md' | 'lg' | 'xl';
  w?: number;
  pr?: number;
  entityTypes?: DataTypeEnum[];
  showSelectedInInput?: boolean;
  clearOnSelect?: boolean;
}

const GlobalSearchControl: React.FC<GlobalSearchControlProps> = ({
  onSelect,
  visibleFrom,
  hiddenFrom,
  w,
  pr,
  entityTypes,
  showSelectedInInput = false,
  clearOnSelect = true,
}) => {
  const [selectedItems, setSelectedItems] = useAtom(selectedItemsAtom);
  const [selectedItem, setSelectedItem] = useState<GlobalSearchResponse | null>(
    null
  );
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const { searchValue, setSearchValue, searchResults } = useGlobalSearch({
    entityTypes,
    initialResults: selectedItems,
    showInitialResultsOnEmpty: true,
  });

  // Effect to open dropdown when there are search results
  useEffect(() => {
    if (searchResults.length > 0 && searchValue) {
      combobox.openDropdown();
    } else {
      combobox.closeDropdown();
    }
  }, [searchResults.length, searchValue, combobox]);

  const handleOptionSubmit = useCallback(
    (optionValue: string) => {
      const [entityType, entityId] = optionValue.split(':');
      const selected = searchResults.find(
        (item) =>
          item.entityType === entityType &&
          item.entityId === parseInt(entityId, 10)
      );

      if (selected) {
        setSelectedItem(selected);
        setSelectedItems((prev) => {
          if (
            !prev.some(
              (item) =>
                item.entityType === selected.entityType &&
                item.entityId === selected.entityId
            )
          ) {
            return [...prev, selected];
          }
          return prev;
        });
        onSelect(selected.entityType as DataTypeEnum, selected.entityId);

        if (clearOnSelect) {
          setSearchValue('');
        }
      }

      // Don't close the dropdown here - let the useEffect handle it based on search results
    },
    [searchResults, setSelectedItems, onSelect, clearOnSelect, setSearchValue]
  );

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
    setSelectedItem(null);
    combobox.closeDropdown();
  }, [setSearchValue, combobox]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setSearchValue(newValue);
    if (selectedItem) setSelectedItem(null);
    combobox.resetSelectedOption();
  };

  const displayValue =
    showSelectedInInput && selectedItem
      ? selectedItem.displayName
      : searchValue;

  return (
    <Combobox
      onOptionSubmit={handleOptionSubmit}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          placeholder="Search..."
          visibleFrom={visibleFrom}
          hiddenFrom={hiddenFrom}
          w={w}
          pr={pr}
          value={displayValue}
          onChange={handleInputChange}
          leftSection={<IconSearch size={18} stroke={1.5} />}
          rightSection={
            (searchValue || selectedItem) && (
              <CloseButton
                onClick={handleClearSearch}
                size="sm"
                style={{ marginRight: '8px' }}
              />
            )
          }
        />
      </Combobox.Target>
      <Combobox.Dropdown hidden={searchResults.length === 0}>
        <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
          {searchResults.map((result) => (
            <Combobox.Option
              value={`${result.entityType}:${result.entityId}`}
              key={`${result.entityType}:${result.entityId}`}
            >
              <SearchResultOption result={result} showSimilarity={false} />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default GlobalSearchControl;
