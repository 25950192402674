import { UserRoleResponse } from '@btrway/api-security';
import { Text } from '@mantine/core';
import React from 'react';

interface UserRoleAccessPermissionsProps {
  userRole: UserRoleResponse;
}

const UserRoleAccessPermissions: React.FC<UserRoleAccessPermissionsProps> = ({
  userRole,
}) => {
  return <Text>List of all the permissions and ability to toggle on/off</Text>;
};

export default UserRoleAccessPermissions;
