import { Box } from '@mantine/core';
import React from 'react';

const WorkgroupEquipmentView: React.FC = () => {
  return (
    <Box m="lg">
      <h3>Equipment View</h3>
    </Box>
  );
};

export default WorkgroupEquipmentView;
