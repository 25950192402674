import { RecurrenceState } from '../types/recurrenceState';

interface ParsedRule {
  [key: string]: string;
}

const parseRuleToMap = (rule?: string): ParsedRule => {
  if (!rule) return {};

  return rule.split(';').reduce((acc: ParsedRule, part) => {
    const [key, value] = part.split('=');
    acc[key] = value;
    return acc;
  }, {});
};

// Define day order mapping for sorting
const dayOrderMap: Record<string, number> = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6,
};

const dayMap: Record<string, string> = {
  MO: 'Monday',
  TU: 'Tuesday',
  WE: 'Wednesday',
  TH: 'Thursday',
  FR: 'Friday',
  SA: 'Saturday',
  SU: 'Sunday',
};

const parseUntilDate = (untilStr: string): Date => {
  const year = parseInt(untilStr.slice(0, 4));
  const month = parseInt(untilStr.slice(4, 6)) - 1;
  const day = parseInt(untilStr.slice(6, 8));
  return new Date(year, month, day, 23, 59, 59);
};

const formatUntilDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
};

export const parseRecurrenceRule = (rule?: string): RecurrenceState => {
  const defaultState: RecurrenceState = {
    frequency: 'DAILY',
    interval: 1,
    weekDays: [],
    endType: 'never',
    endDate: null,
  };

  if (!rule) return defaultState;

  const ruleMap = parseRuleToMap(rule);
  const endDate = ruleMap.UNTIL ? parseUntilDate(ruleMap.UNTIL) : null;

  // Sort weekdays when parsing
  let weekDays = ruleMap.BYDAY
    ? ruleMap.BYDAY.split(',')
    : defaultState.weekDays;
  weekDays = weekDays.sort((a, b) => dayOrderMap[a] - dayOrderMap[b]);

  return {
    frequency: ruleMap.FREQ || defaultState.frequency,
    interval: ruleMap.INTERVAL
      ? parseInt(ruleMap.INTERVAL)
      : defaultState.interval,
    weekDays,
    endType: endDate ? 'on' : 'never',
    endDate,
  };
};

export const generateRule = (state: RecurrenceState): string => {
  const components: string[] = [`FREQ=${state.frequency}`];

  if (state.interval > 1) {
    components.push(`INTERVAL=${state.interval}`);
  }

  if (state.endType === 'on' && state.endDate) {
    components.push(`UNTIL=${formatUntilDate(state.endDate)}`);
  }

  if (state.weekDays.length > 0 && state.frequency === 'WEEKLY') {
    const sortedWeekDays = [...state.weekDays].sort(
      (a, b) => dayOrderMap[a] - dayOrderMap[b]
    );
    components.push(`BYDAY=${sortedWeekDays.join(',')}`);
  }

  return components.join(';');
};

export const formatRecurrenceRule = (rule?: string): string => {
  if (!rule) return 'Does not repeat';

  const ruleMap = parseRuleToMap(rule);
  const freq = ruleMap.FREQ.toLowerCase();
  const interval = ruleMap.INTERVAL ? parseInt(ruleMap.INTERVAL) : 1;
  const until = ruleMap.UNTIL ? parseUntilDate(ruleMap.UNTIL) : null;

  let text = `Repeats ${interval > 1 ? `every ${interval} ` : ''}`;

  switch (freq) {
    case 'daily':
      text += interval > 1 ? 'days' : 'daily';
      break;
    case 'weekly':
      text += interval > 1 ? 'weeks' : 'weekly';
      if (ruleMap.BYDAY) {
        // Sort days before formatting them
        const days = ruleMap.BYDAY.split(',')
          .sort((a, b) => dayOrderMap[a] - dayOrderMap[b])
          .map((day) => dayMap[day])
          .join(', ');
        text += ` on ${days}`;
      }
      break;
    case 'monthly':
      text += interval > 1 ? 'months' : 'monthly';
      break;
    case 'yearly':
      text += interval > 1 ? 'years' : 'yearly';
      break;
  }

  if (until) {
    text += ` until ${until.toLocaleDateString()}`;
  }

  return text;
};
