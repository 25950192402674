import { useDebouncedValue } from '@mantine/hooks';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { workflowTemplateAtom } from '../atoms/workflowTemplateAtoms';
import { useWorkflowTemplate } from './useWorkflowTemplate';

// Constants for autosave timing (in milliseconds)
const DEBOUNCE_DELAY = 2000;
const PERIODIC_SAVE_INTERVAL = 60000;

export const useWorkflowTemplateAutosave = (
  options = {
    debounceDelay: DEBOUNCE_DELAY,
    periodicSaveInterval: PERIODIC_SAVE_INTERVAL,
  }
) => {
  const [workflowTemplate] = useAtom(workflowTemplateAtom);
  const { saveWorkflowTemplate } = useWorkflowTemplate();

  // Use Mantine's useDebouncedValue for debounced saves
  const [debouncedTemplate] = useDebouncedValue(
    workflowTemplate,
    options.debounceDelay
  );

  // Ref to store the interval ID for periodic saves
  const periodicSaveIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Ref to store the previous configuration
  const previousConfigurationRef = useRef(workflowTemplate);

  // Function to save the current workflow template
  const saveTemplate = useCallback(async () => {
    if (debouncedTemplate) {
      try {
        const savedTemplate = await saveWorkflowTemplate(debouncedTemplate);
        // Update the previous configuration after successful save
        previousConfigurationRef.current = debouncedTemplate;
      } catch (error) {
        console.error('Error during autosave:', error);
      }
    }
  }, [debouncedTemplate, saveWorkflowTemplate]);

  // Effect for debounced saves
  useEffect(() => {
    // Check if the configuration has actually changed
    if (
      debouncedTemplate &&
      debouncedTemplate !== previousConfigurationRef.current
    ) {
      saveTemplate();
    }
  }, [debouncedTemplate, saveTemplate]);

  // Effect for periodic saves
  useEffect(() => {
    periodicSaveIntervalRef.current = setInterval(() => {
      // Only save periodically if there are unsaved changes
      if (debouncedTemplate !== previousConfigurationRef.current) {
        saveTemplate();
      }
    }, options.periodicSaveInterval);

    return () => {
      if (periodicSaveIntervalRef.current) {
        clearInterval(periodicSaveIntervalRef.current);
      }
    };
  }, [options.periodicSaveInterval, debouncedTemplate, saveTemplate]);
};
