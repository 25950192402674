import { WorkgroupTypeResponse } from '@btrway/api-core';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { workgroupTypesAtom } from './workgroupTypesAtom';

export const workgroupTypesAtomFamily = atomFamily((id: number) =>
  atom(
    (get) =>
      get(workgroupTypesAtom).find((workgroupType) => workgroupType.id === id),
    (get, set, update: Partial<WorkgroupTypeResponse>) => {
      const prev = get(workgroupTypesAtom);
      const updatedWorkgroupTypes = prev.map((workgroupType) => {
        if (workgroupType.id === id) {
          return { ...workgroupType, ...update };
        }
        return workgroupType;
      });
      set(workgroupTypesAtom, updatedWorkgroupTypes);
    }
  )
);
