import {
  AssetFolderResponse,
  AssetResponse,
  AssetTypeEnum,
  useGetAssetFolder,
  useSaveAsset,
} from '@btrway/api-courseware';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Modal,
  Text,
  Textarea,
} from '@mantine/core';
import { IconFolderFilled, IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';
import CourseCard from '../CourseCard/CourseCard';

interface CourseFolderViewProps {
  folder: AssetFolderResponse;
}

const CourseFolderView: React.FC<CourseFolderViewProps> = ({ folder }) => {
  useAddHelpTags(['course-folders']);

  const [isMultiModalOpen, setIsMultiModalOpen] = useState(false);
  const [multiCourseInput, setMultiCourseInput] = useState('');
  const { mutate: saveCourse } = useSaveAsset();
  const { data: assetFolderResponse, refetch } = useGetAssetFolder(folder.id);

  const handleAddMultipleCourses = () => {
    const courseNames = multiCourseInput
      .split('\n')
      .filter((name) => name.trim() !== '');
    courseNames.forEach((name) => addCourse(name.trim()));
    setIsMultiModalOpen(false);
    setMultiCourseInput('');
  };

  const addCourse = (courseName: string) => {
    saveCourse(
      {
        data: {
          title: courseName,
          published: false,
          assetFolder: { id: folder.id, title: folder.title },
          chapters: [],
          assetType: AssetTypeEnum.course,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
        onError: (error) => {
          console.error('Error saving course:', error);
        },
      }
    );
  };

  // Use the fetched data if available, otherwise fall back to the prop
  const displayFolder = assetFolderResponse || folder;

  // Sort courses alphabetically by title
  const sortedCourses = [...(displayFolder.assets || [])].sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  return (
    <Box mb="xl">
      <Divider
        labelPosition="left"
        label={
          <Group justify="flex-start" gap="sm">
            <IconFolderFilled size={24} />
            <Text fz={18} fw={600} c="dark">
              {displayFolder.title}
            </Text>
            <ActionIcon
              variant="filled"
              radius="xl"
              aria-label="Add Multiple Courses"
              color="gray.5"
              size="sm"
              onClick={() => setIsMultiModalOpen(true)}
            >
              <IconPlus style={{ width: '70%', height: '70%' }} stroke={1.5} />
            </ActionIcon>
          </Group>
        }
      />
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="wrap"
        mt={20}
      >
        {sortedCourses.map((course: AssetResponse) => (
          <CourseCard key={course.id} course={course} />
        ))}
      </Flex>
      <Modal
        opened={isMultiModalOpen}
        onClose={() => setIsMultiModalOpen(false)}
        title="Add Multiple Courses"
      >
        <Textarea
          label="Course Names"
          value={multiCourseInput}
          onChange={(event) => setMultiCourseInput(event.currentTarget.value)}
          placeholder="Enter course names, one per line"
          autosize
          minRows={5}
          maxRows={10}
          autoFocus
          data-autofocus
        />
        <Button onClick={handleAddMultipleCourses} mt="md">
          Add Courses
        </Button>
      </Modal>
    </Box>
  );
};

export default CourseFolderView;
