import { StepConfig, StepTypeEnum } from '@btrway/api-workflow';
import { useAuthContext } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { setupCustomComponents } from '@btrway/form-controls';
import { uuid } from '@btrway/utils';
import {
  useSelectedItem,
  workflowStepAtomFamily,
} from '@btrway/workflow-configuration-manager';
import { Box } from '@mantine/core';
import { useAtomCallback } from 'jotai/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Question, settings, SurveyModel } from 'survey-core';
import 'survey-core/defaultV2.min.css';
import 'survey-core/survey.i18n.js';
import { QuestionAddedEvent, SurveyCreatorModel } from 'survey-creator-core';
import 'survey-creator-core/survey-creator-core.i18n.js';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { setupCustomProperties } from '../../utils/setupCustomProperties';
import { setupLabels } from '../../utils/setupLabels';
import { setupPropertyGrid } from '../../utils/setupPropertyGrid';
import { setupToolbox } from '../../utils/setupToolbox';
import { DataBindingDialog } from '../DataBindingAdorner/DataBindingAdorner';
import styles from './SurveyJsWrapper.module.css';
import { registerDescriptionEditor } from '../DescriptionQuestion/DescriptionQuestion';

interface SurveyJsWrapperProps {
  workflowKey: string;
}

registerDescriptionEditor();

const SurveyJsWrapper: React.FC<SurveyJsWrapperProps> = ({ workflowKey }) => {
  const { authState } = useAuthContext();
  const [creator, setCreator] = useState<SurveyCreator | null>(null);
  const [modalQuestion, setModalQuestion] = useState<Question | null>(null);

  const { selectedItem } = useSelectedItem();
  const selectedStepKey = selectedItem?.key;
  const apiUrl = authState.apiUrl;
  const { currentUser } = useAuthenticatedUser();

  const getSelectedStep = useAtomCallback(
    useCallback(
      (get) => {
        if (!selectedStepKey) return null;
        return get(workflowStepAtomFamily(selectedStepKey));
      },
      [workflowKey, selectedStepKey]
    )
  );

  const setStep = useAtomCallback(
    useCallback(
      (get, set, update: Partial<StepConfig>) => {
        if (selectedStepKey) {
          set(workflowStepAtomFamily(selectedStepKey), update);
        }
      },
      [selectedStepKey]
    )
  );

  //This isn't being called when the first question is added to an empty form, plus need to decide if we even use pages or how Headings as a control type could factor into this.
  const updateFirstPageTitle = useCallback(
    (formConfig: any, stepTitle: string | undefined) => {
      if (formConfig.pages && formConfig.pages.length > 0) {
        formConfig.pages[0].title = stepTitle || 'Page 1';
      }
      return formConfig;
    },
    []
  );

  const openModal = useCallback((question: Question) => {
    setModalQuestion(question);
  }, []);

  const closeModal = useCallback(() => {
    setModalQuestion(null);
  }, []);

  const getCreatorOptions = useCallback((stepTypeCode?: StepTypeEnum) => {
    const baseOptions = {
      showLogicTab: false,
      showJSONEditorTab: false,
      isAutoSave: true,
      showObjectTitles: true,
      showTitlesInExpressions: true,
      showSurveyTitle: false,
      showSidebar: false,
    };

    if (stepTypeCode === StepTypeEnum.flowStart) {
      return {
        ...baseOptions,
        pageEditMode: 'single' as const,
        allowModifyPages: false,
        // Set the default question type for flowStart mode
        questionTypes: ['personSearch'], // This restricts available question types
      };
    }

    return {
      ...baseOptions,
      pageEditMode: 'standard' as const,
      allowModifyPages: true,
    };
  }, []);

  const initializeCreator = useCallback(() => {
    const selectedStep = getSelectedStep();
    const stepTypeCode = selectedStep?.stepTypeCode;

    setupLabels(stepTypeCode);

    const creatorOptions = getCreatorOptions(stepTypeCode);
    const newCreator = new SurveyCreator(creatorOptions);

    if (stepTypeCode === StepTypeEnum.flowStart) {
      // Set the default question type to personSearch
      newCreator.currentAddQuestionType = 'personSearch';
    }

    if (selectedStep) {
      let formConfig = selectedStep.formConfig || {};

      // For flowStart, ensure we're working with a single page
      if (stepTypeCode === StepTypeEnum.flowStart) {
        // Create a temporary survey to properly handle the conversion
        const tempSurvey = new SurveyModel(formConfig);

        // Get all questions from all pages
        const allQuestions = tempSurvey.getAllQuestions();

        // Create a new single-page form config
        formConfig = {
          pages: [
            {
              name: 'page1',
              elements: allQuestions.map((q) => q.toJSON()),
            },
          ],
        };
      } else {
        formConfig = updateFirstPageTitle(formConfig, selectedStep.title);
      }

      newCreator.JSON = formConfig;
    }

    setupCustomComponents(apiUrl, currentUser);
    setupToolbox(newCreator);
    setupCustomProperties(newCreator, openModal);
    setupPropertyGrid(newCreator);
    

    // Handle question addition with correct typing
    newCreator.onQuestionAdded.add(
      (sender: SurveyCreatorModel, options: QuestionAddedEvent) => {
        if (stepTypeCode === StepTypeEnum.flowStart) {
          // Ensure any added question is personSearch type
          options.question.type = 'personSearch';

          // Keep the currentAddQuestionType set to personSearch
          if (sender instanceof SurveyCreator) {
            sender.currentAddQuestionType = 'personSearch';
          }

          // Apply any additional properties from the toolbox item
          const personSearchItem = sender.toolbox.items.find(
            (item) => item.name === 'personSearch'
          );
          if (personSearchItem) {
            Object.assign(options.question, personSearchItem.json);
          }
        }

        options.question.name = uuid();
        options.question.title = 'Enter Question Title';
      }
    );

    newCreator.saveSurveyFunc = (
      saveNo: number,
      callback: (no: number, success: boolean) => void
    ) => {
      const updatedConfig = newCreator.JSON;
      setStep({ formConfig: updatedConfig });
      callback(saveNo, true);
    };

    settings.web.onBeforeRequestChoices = function (sender, options) {
      options.request.withCredentials = true;
    };

    setCreator(newCreator);
  }, [setStep, openModal, apiUrl]);

  useEffect(() => {
    initializeCreator();
    return () => {
      if (creator) {
        creator.dispose();
      }
    };
  }, [initializeCreator]);

  useEffect(() => {
    if (creator && selectedStepKey) {
      const selectedStep = getSelectedStep();
      if (selectedStep) {
        creator.JSON = selectedStep.formConfig || {};
      }
    }
  }, [creator, selectedStepKey]);

  if (!creator) {
    return null;
  }

  return (
    <Box
      className={styles.SurveyJsWrapper}
      style={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <SurveyCreatorComponent
        creator={creator}
        style={{ flex: 1, minHeight: 0 }}
      />
      {modalQuestion && (
        <DataBindingDialog
          question={modalQuestion}
          onClose={closeModal}
          creator={creator}
        />
      )}
    </Box>
  );
};

export default React.memo(SurveyJsWrapper);
