/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  AssetFolderRequest,
  AssetFolderResponse,
  AssetRequest,
  AssetResponse,
  ChapterProgressRequest,
  ChapterProgressResponse,
  VideoRequest,
  VideoResponse
} from './models'
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const getVideos = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<VideoResponse[]>(
      {url: `/api/education/videos`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVideosQueryKey = () => {
    return [`/api/education/videos`] as const;
    }

    
export const getGetVideosQueryOptions = <TData = Awaited<ReturnType<typeof getVideos>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideos>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideosQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideos>>> = ({ signal }) => getVideos(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVideos>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideosQueryResult = NonNullable<Awaited<ReturnType<typeof getVideos>>>
export type GetVideosQueryError = unknown

export const useGetVideos = <TData = Awaited<ReturnType<typeof getVideos>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideos>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideosQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveVideo = (
    videoRequest: VideoRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/education/videos`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: videoRequest
    },
      options);
    }
  


export const getSaveVideoMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveVideo>>, TError,{data: VideoRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveVideo>>, TError,{data: VideoRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveVideo>>, {data: VideoRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveVideo(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveVideoMutationResult = NonNullable<Awaited<ReturnType<typeof saveVideo>>>
    export type SaveVideoMutationBody = VideoRequest
    export type SaveVideoMutationError = unknown

    export const useSaveVideo = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveVideo>>, TError,{data: VideoRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveVideo>>,
        TError,
        {data: VideoRequest},
        TContext
      > => {

      const mutationOptions = getSaveVideoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const saveChapterProgress = (
    chapterProgressRequest: ChapterProgressRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/education/person-chapter-progress`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: chapterProgressRequest
    },
      options);
    }
  


export const getSaveChapterProgressMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveChapterProgress>>, TError,{data: ChapterProgressRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveChapterProgress>>, TError,{data: ChapterProgressRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveChapterProgress>>, {data: ChapterProgressRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveChapterProgress(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveChapterProgressMutationResult = NonNullable<Awaited<ReturnType<typeof saveChapterProgress>>>
    export type SaveChapterProgressMutationBody = ChapterProgressRequest
    export type SaveChapterProgressMutationError = unknown

    export const useSaveChapterProgress = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveChapterProgress>>, TError,{data: ChapterProgressRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveChapterProgress>>,
        TError,
        {data: ChapterProgressRequest},
        TContext
      > => {

      const mutationOptions = getSaveChapterProgressMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssets = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetResponse[]>(
      {url: `/api/education/assets`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetsQueryKey = () => {
    return [`/api/education/assets`] as const;
    }

    
export const getGetAssetsQueryOptions = <TData = Awaited<ReturnType<typeof getAssets>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssets>>> = ({ signal }) => getAssets(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssets>>>
export type GetAssetsQueryError = unknown

export const useGetAssets = <TData = Awaited<ReturnType<typeof getAssets>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssets>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveAsset = (
    assetRequest: AssetRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/education/assets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetRequest
    },
      options);
    }
  


export const getSaveAssetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAsset>>, TError,{data: AssetRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveAsset>>, TError,{data: AssetRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveAsset>>, {data: AssetRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveAsset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveAssetMutationResult = NonNullable<Awaited<ReturnType<typeof saveAsset>>>
    export type SaveAssetMutationBody = AssetRequest
    export type SaveAssetMutationError = unknown

    export const useSaveAsset = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAsset>>, TError,{data: AssetRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveAsset>>,
        TError,
        {data: AssetRequest},
        TContext
      > => {

      const mutationOptions = getSaveAssetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const publishAsset = (
    assetId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<AssetResponse>(
      {url: `/api/education/assets/${assetId}/publish`, method: 'POST'
    },
      options);
    }
  


export const getPublishAssetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publishAsset>>, TError,{assetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof publishAsset>>, TError,{assetId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof publishAsset>>, {assetId: number}> = (props) => {
          const {assetId} = props ?? {};

          return  publishAsset(assetId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PublishAssetMutationResult = NonNullable<Awaited<ReturnType<typeof publishAsset>>>
    
    export type PublishAssetMutationError = unknown

    export const usePublishAsset = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof publishAsset>>, TError,{assetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof publishAsset>>,
        TError,
        {assetId: number},
        TContext
      > => {

      const mutationOptions = getPublishAssetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssetsByUids = (
    getAssetsByUidsBody: number[],
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<AssetResponse[]>(
      {url: `/api/education/assets/by-uids`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: getAssetsByUidsBody
    },
      options);
    }
  


export const getGetAssetsByUidsMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssetsByUids>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getAssetsByUids>>, TError,{data: number[]}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getAssetsByUids>>, {data: number[]}> = (props) => {
          const {data} = props ?? {};

          return  getAssetsByUids(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetAssetsByUidsMutationResult = NonNullable<Awaited<ReturnType<typeof getAssetsByUids>>>
    export type GetAssetsByUidsMutationBody = number[]
    export type GetAssetsByUidsMutationError = unknown

    export const useGetAssetsByUids = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAssetsByUids>>, TError,{data: number[]}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof getAssetsByUids>>,
        TError,
        {data: number[]},
        TContext
      > => {

      const mutationOptions = getGetAssetsByUidsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssetFolders = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetFolderResponse[]>(
      {url: `/api/education/asset-folders`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetFoldersQueryKey = () => {
    return [`/api/education/asset-folders`] as const;
    }

    
export const getGetAssetFoldersQueryOptions = <TData = Awaited<ReturnType<typeof getAssetFolders>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetFolders>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetFoldersQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetFolders>>> = ({ signal }) => getAssetFolders(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetFolders>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetFoldersQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetFolders>>>
export type GetAssetFoldersQueryError = unknown

export const useGetAssetFolders = <TData = Awaited<ReturnType<typeof getAssetFolders>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetFolders>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetFoldersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const saveAssetFolder = (
    assetFolderRequest: AssetFolderRequest,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/api/education/asset-folders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assetFolderRequest
    },
      options);
    }
  


export const getSaveAssetFolderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAssetFolder>>, TError,{data: AssetFolderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof saveAssetFolder>>, TError,{data: AssetFolderRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveAssetFolder>>, {data: AssetFolderRequest}> = (props) => {
          const {data} = props ?? {};

          return  saveAssetFolder(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SaveAssetFolderMutationResult = NonNullable<Awaited<ReturnType<typeof saveAssetFolder>>>
    export type SaveAssetFolderMutationBody = AssetFolderRequest
    export type SaveAssetFolderMutationError = unknown

    export const useSaveAssetFolder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveAssetFolder>>, TError,{data: AssetFolderRequest}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof saveAssetFolder>>,
        TError,
        {data: AssetFolderRequest},
        TContext
      > => {

      const mutationOptions = getSaveAssetFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getVideoById = (
    videoId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<VideoResponse>(
      {url: `/api/education/videos/${videoId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVideoByIdQueryKey = (videoId: number,) => {
    return [`/api/education/videos/${videoId}`] as const;
    }

    
export const getGetVideoByIdQueryOptions = <TData = Awaited<ReturnType<typeof getVideoById>>, TError = unknown>(videoId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideoById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetVideoByIdQueryKey(videoId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVideoById>>> = ({ signal }) => getVideoById(videoId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(videoId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVideoById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetVideoByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getVideoById>>>
export type GetVideoByIdQueryError = unknown

export const useGetVideoById = <TData = Awaited<ReturnType<typeof getVideoById>>, TError = unknown>(
 videoId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getVideoById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVideoByIdQueryOptions(videoId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteVideo = (
    videoId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/education/videos/${videoId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteVideoMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVideo>>, TError,{videoId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVideo>>, TError,{videoId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVideo>>, {videoId: number}> = (props) => {
          const {videoId} = props ?? {};

          return  deleteVideo(videoId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVideoMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVideo>>>
    
    export type DeleteVideoMutationError = unknown

    export const useDeleteVideo = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVideo>>, TError,{videoId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteVideo>>,
        TError,
        {videoId: number},
        TContext
      > => {

      const mutationOptions = getDeleteVideoMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getChapterProgressByPerson = (
    personId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<ChapterProgressResponse[]>(
      {url: `/api/education/person-chapter-progress/persons/${personId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChapterProgressByPersonQueryKey = (personId: number,) => {
    return [`/api/education/person-chapter-progress/persons/${personId}`] as const;
    }

    
export const getGetChapterProgressByPersonQueryOptions = <TData = Awaited<ReturnType<typeof getChapterProgressByPerson>>, TError = unknown>(personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getChapterProgressByPerson>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChapterProgressByPersonQueryKey(personId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChapterProgressByPerson>>> = ({ signal }) => getChapterProgressByPerson(personId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(personId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChapterProgressByPerson>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChapterProgressByPersonQueryResult = NonNullable<Awaited<ReturnType<typeof getChapterProgressByPerson>>>
export type GetChapterProgressByPersonQueryError = unknown

export const useGetChapterProgressByPerson = <TData = Awaited<ReturnType<typeof getChapterProgressByPerson>>, TError = unknown>(
 personId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getChapterProgressByPerson>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChapterProgressByPersonQueryOptions(personId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getIncompleteAssets = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetResponse[]>(
      {url: `/api/education/education/asset-progress/incomplete`, method: 'GET', signal
    },
      options);
    }
  

export const getGetIncompleteAssetsQueryKey = () => {
    return [`/api/education/education/asset-progress/incomplete`] as const;
    }

    
export const getGetIncompleteAssetsQueryOptions = <TData = Awaited<ReturnType<typeof getIncompleteAssets>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncompleteAssets>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncompleteAssetsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncompleteAssets>>> = ({ signal }) => getIncompleteAssets(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIncompleteAssets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIncompleteAssetsQueryResult = NonNullable<Awaited<ReturnType<typeof getIncompleteAssets>>>
export type GetIncompleteAssetsQueryError = unknown

export const useGetIncompleteAssets = <TData = Awaited<ReturnType<typeof getIncompleteAssets>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncompleteAssets>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncompleteAssetsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAssetById = (
    assetId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetResponse>(
      {url: `/api/education/assets/${assetId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetByIdQueryKey = (assetId: number,) => {
    return [`/api/education/assets/${assetId}`] as const;
    }

    
export const getGetAssetByIdQueryOptions = <TData = Awaited<ReturnType<typeof getAssetById>>, TError = unknown>(assetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetByIdQueryKey(assetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetById>>> = ({ signal }) => getAssetById(assetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(assetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetById>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetById>>>
export type GetAssetByIdQueryError = unknown

export const useGetAssetById = <TData = Awaited<ReturnType<typeof getAssetById>>, TError = unknown>(
 assetId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetById>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetByIdQueryOptions(assetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteAsset = (
    assetId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/education/assets/${assetId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssetMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAsset>>, {assetId: number}> = (props) => {
          const {assetId} = props ?? {};

          return  deleteAsset(assetId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAsset>>>
    
    export type DeleteAssetMutationError = unknown

    export const useDeleteAsset = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAsset>>, TError,{assetId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAsset>>,
        TError,
        {assetId: number},
        TContext
      > => {

      const mutationOptions = getDeleteAssetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
export const getAssetByUid = (
    assetUid: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetResponse>(
      {url: `/api/education/assets/uid/${assetUid}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetByUidQueryKey = (assetUid: number,) => {
    return [`/api/education/assets/uid/${assetUid}`] as const;
    }

    
export const getGetAssetByUidQueryOptions = <TData = Awaited<ReturnType<typeof getAssetByUid>>, TError = unknown>(assetUid: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetByUid>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetByUidQueryKey(assetUid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetByUid>>> = ({ signal }) => getAssetByUid(assetUid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(assetUid), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetByUid>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetByUidQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetByUid>>>
export type GetAssetByUidQueryError = unknown

export const useGetAssetByUid = <TData = Awaited<ReturnType<typeof getAssetByUid>>, TError = unknown>(
 assetUid: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetByUid>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetByUidQueryOptions(assetUid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAssetsByAssetFolder = (
    assetFolderId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetResponse[]>(
      {url: `/api/education/assets/asset-folders/${assetFolderId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetsByAssetFolderQueryKey = (assetFolderId: number,) => {
    return [`/api/education/assets/asset-folders/${assetFolderId}`] as const;
    }

    
export const getGetAssetsByAssetFolderQueryOptions = <TData = Awaited<ReturnType<typeof getAssetsByAssetFolder>>, TError = unknown>(assetFolderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetsByAssetFolder>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsByAssetFolderQueryKey(assetFolderId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsByAssetFolder>>> = ({ signal }) => getAssetsByAssetFolder(assetFolderId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(assetFolderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetsByAssetFolder>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetsByAssetFolderQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsByAssetFolder>>>
export type GetAssetsByAssetFolderQueryError = unknown

export const useGetAssetsByAssetFolder = <TData = Awaited<ReturnType<typeof getAssetsByAssetFolder>>, TError = unknown>(
 assetFolderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetsByAssetFolder>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsByAssetFolderQueryOptions(assetFolderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const getAssetFolder = (
    assetFolderId: number,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<AssetFolderResponse>(
      {url: `/api/education/asset-folders/${assetFolderId}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssetFolderQueryKey = (assetFolderId: number,) => {
    return [`/api/education/asset-folders/${assetFolderId}`] as const;
    }

    
export const getGetAssetFolderQueryOptions = <TData = Awaited<ReturnType<typeof getAssetFolder>>, TError = unknown>(assetFolderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetFolder>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetFolderQueryKey(assetFolderId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetFolder>>> = ({ signal }) => getAssetFolder(assetFolderId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(assetFolderId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetFolder>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetFolderQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetFolder>>>
export type GetAssetFolderQueryError = unknown

export const useGetAssetFolder = <TData = Awaited<ReturnType<typeof getAssetFolder>>, TError = unknown>(
 assetFolderId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssetFolder>>, TError, TData>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetFolderQueryOptions(assetFolderId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




export const deleteAssetFolder = (
    assetFolderId: number,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/api/education/asset-folders/${assetFolderId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssetFolderMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssetFolder>>, TError,{assetFolderId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssetFolder>>, TError,{assetFolderId: number}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssetFolder>>, {assetFolderId: number}> = (props) => {
          const {assetFolderId} = props ?? {};

          return  deleteAssetFolder(assetFolderId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssetFolderMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssetFolder>>>
    
    export type DeleteAssetFolderMutationError = unknown

    export const useDeleteAssetFolder = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssetFolder>>, TError,{assetFolderId: number}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteAssetFolder>>,
        TError,
        {assetFolderId: number},
        TContext
      > => {

      const mutationOptions = getDeleteAssetFolderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
