/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  EmailTemplateRequest,
  EmailTemplateResponse,
  MessageRecipientRequest,
  MessageRecipientResponse,
  MessageRequest,
  MessageResponse,
  MessageTargetRequest,
  MessageTargetResponse,
} from './models';
import { axiosInstance } from '../../../../api-client-provider/src/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const saveMessage = (
  messageRequest: MessageRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/messaging/messages`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: messageRequest,
    },
    options
  );
};

export const getSaveMessageMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessage>>,
    TError,
    { data: MessageRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveMessage>>,
  TError,
  { data: MessageRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveMessage>>,
    { data: MessageRequest }
  > = (props) => {
    const { data } = props ?? {};

    return saveMessage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveMessage>>
>;
export type SaveMessageMutationBody = MessageRequest;
export type SaveMessageMutationError = unknown;

export const useSaveMessage = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessage>>,
    TError,
    { data: MessageRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveMessage>>,
  TError,
  { data: MessageRequest },
  TContext
> => {
  const mutationOptions = getSaveMessageMutationOptions(options);

  return useMutation(mutationOptions);
};

export const saveMessageTarget = (
  messageTargetRequest: MessageTargetRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/messaging/message-targets`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: messageTargetRequest,
    },
    options
  );
};

export const getSaveMessageTargetMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessageTarget>>,
    TError,
    { data: MessageTargetRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveMessageTarget>>,
  TError,
  { data: MessageTargetRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveMessageTarget>>,
    { data: MessageTargetRequest }
  > = (props) => {
    const { data } = props ?? {};

    return saveMessageTarget(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveMessageTargetMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveMessageTarget>>
>;
export type SaveMessageTargetMutationBody = MessageTargetRequest;
export type SaveMessageTargetMutationError = unknown;

export const useSaveMessageTarget = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessageTarget>>,
    TError,
    { data: MessageTargetRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveMessageTarget>>,
  TError,
  { data: MessageTargetRequest },
  TContext
> => {
  const mutationOptions = getSaveMessageTargetMutationOptions(options);

  return useMutation(mutationOptions);
};

export const saveMessageRecipient = (
  messageRecipientRequest: MessageRecipientRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/messaging/message-recipients`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: messageRecipientRequest,
    },
    options
  );
};

export const getSaveMessageRecipientMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessageRecipient>>,
    TError,
    { data: MessageRecipientRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveMessageRecipient>>,
  TError,
  { data: MessageRecipientRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveMessageRecipient>>,
    { data: MessageRecipientRequest }
  > = (props) => {
    const { data } = props ?? {};

    return saveMessageRecipient(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveMessageRecipientMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveMessageRecipient>>
>;
export type SaveMessageRecipientMutationBody = MessageRecipientRequest;
export type SaveMessageRecipientMutationError = unknown;

export const useSaveMessageRecipient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveMessageRecipient>>,
    TError,
    { data: MessageRecipientRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveMessageRecipient>>,
  TError,
  { data: MessageRecipientRequest },
  TContext
> => {
  const mutationOptions = getSaveMessageRecipientMutationOptions(options);

  return useMutation(mutationOptions);
};

export const saveEmailTemplate = (
  emailTemplateRequest: EmailTemplateRequest,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<unknown>(
    {
      url: `/api/messaging/email-template/save`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailTemplateRequest,
    },
    options
  );
};

export const getSaveEmailTemplateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveEmailTemplate>>,
    TError,
    { data: EmailTemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof saveEmailTemplate>>,
  TError,
  { data: EmailTemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveEmailTemplate>>,
    { data: EmailTemplateRequest }
  > = (props) => {
    const { data } = props ?? {};

    return saveEmailTemplate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SaveEmailTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveEmailTemplate>>
>;
export type SaveEmailTemplateMutationBody = EmailTemplateRequest;
export type SaveEmailTemplateMutationError = unknown;

export const useSaveEmailTemplate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveEmailTemplate>>,
    TError,
    { data: EmailTemplateRequest },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof saveEmailTemplate>>,
  TError,
  { data: EmailTemplateRequest },
  TContext
> => {
  const mutationOptions = getSaveEmailTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getMessageById = (
  messageId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MessageResponse>(
    { url: `/api/messaging/messages/${messageId}`, method: 'GET', signal },
    options
  );
};

export const getGetMessageByIdQueryKey = (messageId: number) => {
  return [`/api/messaging/messages/${messageId}`] as const;
};

export const getGetMessageByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessageById>>,
  TError = unknown
>(
  messageId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMessageByIdQueryKey(messageId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessageById>>> = ({
    signal,
  }) => getMessageById(messageId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!messageId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessageById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMessageByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessageById>>
>;
export type GetMessageByIdQueryError = unknown;

export const useGetMessageById = <
  TData = Awaited<ReturnType<typeof getMessageById>>,
  TError = unknown
>(
  messageId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessageByIdQueryOptions(messageId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteMessage = (
  messageId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/messaging/messages/${messageId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteMessageMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessage>>,
    TError,
    { messageId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMessage>>,
  TError,
  { messageId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMessage>>,
    { messageId: number }
  > = (props) => {
    const { messageId } = props ?? {};

    return deleteMessage(messageId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMessage>>
>;

export type DeleteMessageMutationError = unknown;

export const useDeleteMessage = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessage>>,
    TError,
    { messageId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteMessage>>,
  TError,
  { messageId: number },
  TContext
> => {
  const mutationOptions = getDeleteMessageMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getMessagesByWorkgroupId = (
  workgroupId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MessageResponse[]>(
    {
      url: `/api/messaging/messages/by-workgroup/${workgroupId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMessagesByWorkgroupIdQueryKey = (workgroupId: number) => {
  return [`/api/messaging/messages/by-workgroup/${workgroupId}`] as const;
};

export const getGetMessagesByWorkgroupIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessagesByWorkgroupId>>,
  TError = unknown
>(
  workgroupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessagesByWorkgroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMessagesByWorkgroupIdQueryKey(workgroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMessagesByWorkgroupId>>
  > = ({ signal }) =>
    getMessagesByWorkgroupId(workgroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!workgroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessagesByWorkgroupId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMessagesByWorkgroupIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessagesByWorkgroupId>>
>;
export type GetMessagesByWorkgroupIdQueryError = unknown;

export const useGetMessagesByWorkgroupId = <
  TData = Awaited<ReturnType<typeof getMessagesByWorkgroupId>>,
  TError = unknown
>(
  workgroupId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessagesByWorkgroupId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessagesByWorkgroupIdQueryOptions(
    workgroupId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMessageTargetById = (
  messageTargetId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MessageTargetResponse>(
    {
      url: `/api/messaging/message-targets/${messageTargetId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMessageTargetByIdQueryKey = (messageTargetId: number) => {
  return [`/api/messaging/message-targets/${messageTargetId}`] as const;
};

export const getGetMessageTargetByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessageTargetById>>,
  TError = unknown
>(
  messageTargetId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageTargetById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMessageTargetByIdQueryKey(messageTargetId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMessageTargetById>>
  > = ({ signal }) =>
    getMessageTargetById(messageTargetId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!messageTargetId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessageTargetById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMessageTargetByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessageTargetById>>
>;
export type GetMessageTargetByIdQueryError = unknown;

export const useGetMessageTargetById = <
  TData = Awaited<ReturnType<typeof getMessageTargetById>>,
  TError = unknown
>(
  messageTargetId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageTargetById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessageTargetByIdQueryOptions(
    messageTargetId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteMessageTarget = (
  messageTargetId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/messaging/message-targets/${messageTargetId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteMessageTargetMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessageTarget>>,
    TError,
    { messageTargetId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMessageTarget>>,
  TError,
  { messageTargetId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMessageTarget>>,
    { messageTargetId: number }
  > = (props) => {
    const { messageTargetId } = props ?? {};

    return deleteMessageTarget(messageTargetId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMessageTargetMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMessageTarget>>
>;

export type DeleteMessageTargetMutationError = unknown;

export const useDeleteMessageTarget = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessageTarget>>,
    TError,
    { messageTargetId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteMessageTarget>>,
  TError,
  { messageTargetId: number },
  TContext
> => {
  const mutationOptions = getDeleteMessageTargetMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getMessageRecipientById = (
  messageRecipientId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MessageRecipientResponse>(
    {
      url: `/api/messaging/message-recipients/${messageRecipientId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMessageRecipientByIdQueryKey = (
  messageRecipientId: number
) => {
  return [`/api/messaging/message-recipients/${messageRecipientId}`] as const;
};

export const getGetMessageRecipientByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessageRecipientById>>,
  TError = unknown
>(
  messageRecipientId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageRecipientById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMessageRecipientByIdQueryKey(messageRecipientId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMessageRecipientById>>
  > = ({ signal }) =>
    getMessageRecipientById(messageRecipientId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!messageRecipientId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessageRecipientById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMessageRecipientByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessageRecipientById>>
>;
export type GetMessageRecipientByIdQueryError = unknown;

export const useGetMessageRecipientById = <
  TData = Awaited<ReturnType<typeof getMessageRecipientById>>,
  TError = unknown
>(
  messageRecipientId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageRecipientById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessageRecipientByIdQueryOptions(
    messageRecipientId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteMessageRecipient = (
  messageRecipientId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    {
      url: `/api/messaging/message-recipients/${messageRecipientId}`,
      method: 'DELETE',
    },
    options
  );
};

export const getDeleteMessageRecipientMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessageRecipient>>,
    TError,
    { messageRecipientId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMessageRecipient>>,
  TError,
  { messageRecipientId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMessageRecipient>>,
    { messageRecipientId: number }
  > = (props) => {
    const { messageRecipientId } = props ?? {};

    return deleteMessageRecipient(messageRecipientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMessageRecipientMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMessageRecipient>>
>;

export type DeleteMessageRecipientMutationError = unknown;

export const useDeleteMessageRecipient = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessageRecipient>>,
    TError,
    { messageRecipientId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteMessageRecipient>>,
  TError,
  { messageRecipientId: number },
  TContext
> => {
  const mutationOptions = getDeleteMessageRecipientMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getMessageRecipientsByMessageId = (
  messageId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<MessageRecipientResponse[]>(
    {
      url: `/api/messaging/message-recipients/by-message/${messageId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetMessageRecipientsByMessageIdQueryKey = (
  messageId: number
) => {
  return [`/api/messaging/message-recipients/by-message/${messageId}`] as const;
};

export const getGetMessageRecipientsByMessageIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>,
  TError = unknown
>(
  messageId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetMessageRecipientsByMessageIdQueryKey(messageId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>
  > = ({ signal }) =>
    getMessageRecipientsByMessageId(messageId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!messageId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMessageRecipientsByMessageIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>
>;
export type GetMessageRecipientsByMessageIdQueryError = unknown;

export const useGetMessageRecipientsByMessageId = <
  TData = Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>,
  TError = unknown
>(
  messageId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessageRecipientsByMessageId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessageRecipientsByMessageIdQueryOptions(
    messageId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAllEmailTemplates = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<EmailTemplateResponse[]>(
    { url: `/api/messaging/email-template`, method: 'GET', signal },
    options
  );
};

export const getGetAllEmailTemplatesQueryKey = () => {
  return [`/api/messaging/email-template`] as const;
};

export const getGetAllEmailTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllEmailTemplates>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAllEmailTemplates>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllEmailTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllEmailTemplates>>
  > = ({ signal }) => getAllEmailTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllEmailTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAllEmailTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllEmailTemplates>>
>;
export type GetAllEmailTemplatesQueryError = unknown;

export const useGetAllEmailTemplates = <
  TData = Awaited<ReturnType<typeof getAllEmailTemplates>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAllEmailTemplates>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllEmailTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEmailTemplateById = (
  templateId: number,
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<EmailTemplateResponse>(
    {
      url: `/api/messaging/email-template/${templateId}`,
      method: 'GET',
      signal,
    },
    options
  );
};

export const getGetEmailTemplateByIdQueryKey = (templateId: number) => {
  return [`/api/messaging/email-template/${templateId}`] as const;
};

export const getGetEmailTemplateByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmailTemplateById>>,
  TError = unknown
>(
  templateId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEmailTemplateById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEmailTemplateByIdQueryKey(templateId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getEmailTemplateById>>
  > = ({ signal }) => getEmailTemplateById(templateId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!templateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmailTemplateById>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEmailTemplateByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmailTemplateById>>
>;
export type GetEmailTemplateByIdQueryError = unknown;

export const useGetEmailTemplateById = <
  TData = Awaited<ReturnType<typeof getEmailTemplateById>>,
  TError = unknown
>(
  templateId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getEmailTemplateById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof axiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEmailTemplateByIdQueryOptions(templateId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteEmailTemplate = (
  templateId: number,
  options?: SecondParameter<typeof axiosInstance>
) => {
  return axiosInstance<void>(
    { url: `/api/messaging/email-template/${templateId}`, method: 'DELETE' },
    options
  );
};

export const getDeleteEmailTemplateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEmailTemplate>>,
    TError,
    { templateId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEmailTemplate>>,
  TError,
  { templateId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEmailTemplate>>,
    { templateId: number }
  > = (props) => {
    const { templateId } = props ?? {};

    return deleteEmailTemplate(templateId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEmailTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEmailTemplate>>
>;

export type DeleteEmailTemplateMutationError = unknown;

export const useDeleteEmailTemplate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEmailTemplate>>,
    TError,
    { templateId: number },
    TContext
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEmailTemplate>>,
  TError,
  { templateId: number },
  TContext
> => {
  const mutationOptions = getDeleteEmailTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getActiveEmailTemplates = (
  options?: SecondParameter<typeof axiosInstance>,
  signal?: AbortSignal
) => {
  return axiosInstance<EmailTemplateResponse[]>(
    { url: `/api/messaging/email-template/active`, method: 'GET', signal },
    options
  );
};

export const getGetActiveEmailTemplatesQueryKey = () => {
  return [`/api/messaging/email-template/active`] as const;
};

export const getGetActiveEmailTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveEmailTemplates>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmailTemplates>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetActiveEmailTemplatesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getActiveEmailTemplates>>
  > = ({ signal }) => getActiveEmailTemplates(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmailTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveEmailTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveEmailTemplates>>
>;
export type GetActiveEmailTemplatesQueryError = unknown;

export const useGetActiveEmailTemplates = <
  TData = Awaited<ReturnType<typeof getActiveEmailTemplates>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmailTemplates>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof axiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveEmailTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
