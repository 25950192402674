import {
  StorageUploadRequest,
  StorageUsageEnum,
  getPresignedUrl,
} from '@btrway/api-storage';
import { useAuthenticatedUser } from '@btrway/current-user';
import { uuid } from '@btrway/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useStorageUpload } from './useStorageUpload';

// Create a cache object outside of the hook to persist across re-renders
const urlCache = new Map<string, string>();

interface UseImageUploadProps {
  storageUsage: StorageUsageEnum;
  initialImageKey?: string;
  onImageUpload?: (imageKey: string) => void;
}

export const useImageUpload = ({
  storageUsage,
  initialImageKey,
  onImageUpload,
}: UseImageUploadProps) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const { currentUser, currentOrganization } = useAuthenticatedUser();
  const { storageUpload } = useStorageUpload();

  // Memoize the fetchAndCacheUrl function
  const fetchAndCacheUrl = useMemo(() => {
    return async (key: string) => {
      if (urlCache.has(key)) {
        return urlCache.get(key);
      }

      try {
        const presignedUrl = (await getPresignedUrl(storageUsage, {
          storageKey: key,
        })) as string;
        urlCache.set(key, presignedUrl);
        return presignedUrl;
      } catch (error) {
        console.error('Error fetching presigned URL:', error);
        return null;
      }
    };
  }, [storageUsage]);

  useEffect(() => {
    if (initialImageKey) {
      fetchAndCacheUrl(initialImageKey).then((url) => {
        if (url) setImageUrl(url);
      });
    } else {
      setImageUrl('');
    }
  }, [initialImageKey, fetchAndCacheUrl]);

  const handleImageUpload = useCallback(
    async (file: Blob) => {
      if (!currentUser?.user || !currentOrganization) return;

      const fileName = `${uuid()}.png`;
      const storageUploadRequest: StorageUploadRequest = {
        organizationId: currentOrganization.id,
        fileNames: [fileName],
        userId: currentUser.user.id,
        storageUsageEnum: storageUsage,
      };

      try {
        const storageUploadResponse = await storageUpload(
          storageUploadRequest,
          [
            {
              file: file,
              contentType: 'image/png',
              fileName: fileName,
            },
          ]
        );

        if (
          storageUploadResponse.storageKeys &&
          storageUploadResponse.storageKeys.length > 0
        ) {
          const imageKey = storageUploadResponse.storageKeys[0];
          const newImageUrl = await fetchAndCacheUrl(imageKey);
          if (newImageUrl) {
            setImageUrl(newImageUrl);
            onImageUpload?.(imageKey);
          }
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    },
    [
      currentUser,
      currentOrganization,
      storageUsage,
      storageUpload,
      onImageUpload,
      fetchAndCacheUrl,
    ]
  );

  return {
    imageUrl,
    handleImageUpload,
  };
};
