import {
  TaskCategoryResponse,
  TaskListStartTypeEnum,
  TaskTypeDTO,
} from '@btrway/api-workflow';
import { Field, Fields } from 'react-spreadsheet-import/types/types';
import { ImportedTask } from '../types/ImportedTask';

export const createTaskListImportFields = (
  taskTypes: TaskTypeDTO[],
  taskCategories: TaskCategoryResponse[]
): Fields<keyof ImportedTask> => {
  const taskListStartTypeOptions = [
    { label: 'Specific Day', value: TaskListStartTypeEnum.specificDay },
    { label: 'Configured Date', value: TaskListStartTypeEnum.configuredDate },
    { label: 'Task Assignment', value: TaskListStartTypeEnum.taskAssignment },
  ];

  const fields: Fields<keyof ImportedTask> = [
    {
      label: 'Task List Name',
      key: 'templateTitle',
      alternateMatches: [
        'Template',
        'template',
        'Task List',
        'task list',
        'Task List Name',
        'task list name',
      ],
      fieldType: { type: 'input' },
      example: 'Name of template',
    },
    {
      label: 'Default User Roles',
      key: 'userRoleString',
      alternateMatches: [
        'Roles',
        'roles',
        'User Roles',
        'user roles',
        'Default Roles',
        'default roles',
      ],
      fieldType: { type: 'input' },
      example: 'Admin, User',
    },
    {
      label: 'Task List Start Type',
      key: 'taskListStartType',
      alternateMatches: [
        'Task List Start Type',
        'task list start type',
        'Start Type',
        'start type',
      ],
      fieldType: {
        type: 'select',
        options: taskListStartTypeOptions,
      },
      example: 'Specific Day',
    },
    {
      label: 'Task List Start Month',
      key: 'taskListStartMonth',
      alternateMatches: [
        'Start Month',
        'start month',
        'Assign Month',
        'assign month',
        'Task List Start Month',
        'task list start month',
      ],
      fieldType: { type: 'input' },
      example: '1',
    },
    {
      label: 'Task List Start Day',
      key: 'taskListStartDay',
      alternateMatches: [
        'Start Day',
        'start day',
        'Assign Day',
        'assign day',
        'Task List Start Day',
        'task list start day',
      ],
      fieldType: { type: 'input' },
      example: '15',
    },
    {
      label: 'Assigned Offset Days',
      key: 'startOffsetDays',
      alternateMatches: [
        'Start Offset',
        'start offset',
        'Assigned Offset',
        'assigned offset',
        'Assigned Date',
        'assigned date',
        'Assigned Day',
        'assigned day',
        'Start Day',
        'start day',
      ],
      fieldType: { type: 'input' },
      example: '2',
    },
    {
      label: 'Due Offset Days',
      key: 'endOffsetDays',
      alternateMatches: [
        'End Offset',
        'end offset',
        'Due Offset',
        'due offset',
        'Due Date',
        'due date',
        'Due Day',
        'due day',
        'End Day',
        'end day',
      ],
      fieldType: { type: 'input' },
      example: '15',
    },
    {
      label: 'Task Category',
      key: 'taskCategory',
      alternateMatches: [
        'Category',
        'category',
        'Task Category',
        'task category',
        'task type',
        'Task Type',
      ],
      fieldType: {
        type: 'select',
        options: taskCategories.map((taskCategory) => ({
          label: taskCategory.name,
          value: taskCategory.key,
        })),
      },
      example: 'Facilities',
    },
    {
      label: 'Tool',
      key: 'taskTypeCode',
      alternateMatches: ['Tool', 'tool'],
      fieldType: {
        type: 'select',
        options: taskTypes.map((taskType) => ({
          label: taskType.name,
          value: taskType.code.toString(),
        })),
      },
      example: taskTypes[0]?.name || 'Submit Form',
    },
    {
      label: 'Task Title',
      key: 'taskTitle',
      alternateMatches: ['Task', 'task', 'Task Title', 'task title'],
      fieldType: { type: 'input' },
      example: 'Name of task',
    },
    {
      label: 'Task Subtitle',
      key: 'taskSubtitle',
      alternateMatches: [
        'Task Subtitle',
        'task subtitle',
        'Subtitle',
        'subtitle',
        'Task Action',
        'task action',
      ],
      fieldType: { type: 'input' },
      example: 'Brief description of task',
    },
    {
      label: 'Task Content',
      key: 'taskContent',
      alternateMatches: [
        'Description',
        'description',
        'Detail',
        'detail',
        'Content',
        'content',
        'Task Content',
        'task content',
        'Task Description',
        'task description',
      ],
      fieldType: { type: 'input' },
      example: 'Detailed description for the task',
    },
  ] as Fields<keyof ImportedTask>;

  // Sort the fields alphabetically by label
  return fields.sort(
    (a: Field<keyof ImportedTask>, b: Field<keyof ImportedTask>) =>
      a.label.localeCompare(b.label)
  );
};
