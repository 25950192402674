/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type StepTypeTypeEnum = typeof StepTypeTypeEnum[keyof typeof StepTypeTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StepTypeTypeEnum = {
  action: 'action',
  task: 'task',
  flow: 'flow',
  event: 'event',
  system: 'system',
  step: 'step',
  unknown: 'unknown',
} as const;
