import {
  CertificationTypeEnum,
  EventTypeEnum,
  StepTypeEnum,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import {
  CertificationTypeIcon,
  EventTypeIcon,
  StepTypeIcon,
  TaskTypeIcon,
} from '@btrway/workflow-components';
import React from 'react';
import { ItemType } from '../../types/itemType';

// Icon Component
interface ToolboxIconProps {
  type: ItemType;
  code: string;
  size?: number;
}

export const ToolboxIcon: React.FC<ToolboxIconProps> = ({
  type,
  code,
  size = 32,
}) => {
  switch (type) {
    case 'stepType':
      return <StepTypeIcon iconName={code as StepTypeEnum} size={size} />;
    case 'taskType':
      return <TaskTypeIcon iconName={code as TaskTypeEnum} size={size} />;
    case 'eventType':
      return <EventTypeIcon iconName={code as EventTypeEnum} size={size} />;
    case 'certificationType':
      return (
        <CertificationTypeIcon
          iconName={code as CertificationTypeEnum}
          size={size}
        />
      );
    default:
      return null;
  }
};
