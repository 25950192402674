import {
  WorkflowDefinitionResponse,
  WorkflowTypeEnum,
  useGetDistinctWorkflowCategories,
} from '@btrway/api-workflow';
import { useWorkflowDefinitions } from '@btrway/workflow-manager';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from '@btrway/current-user';

interface UseFormLibraryReturn {
  filteredWorkflows: WorkflowDefinitionResponse[];
  suggestedCategories: string[];
  selectedCategory: string | null;
  searchTerm: string;
  isReady: boolean;
  isLoading: boolean;
  error: unknown;
  setSelectedCategory: (category: string | null) => void;
  setSearchTerm: (term: string) => void;
}

export const useFormLibrary = (): UseFormLibraryReturn => {
  const { currentOrganization } = useAuthenticatedUser();
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [isReady, setIsReady] = useState(false);

  const {
    workflows,
    isLoading: isWorkflowsLoading,
    error: workflowsError,
  } = useWorkflowDefinitions();

  const { data: categoriesResponse, isLoading: isCategoriesLoading } =
    useGetDistinctWorkflowCategories(currentOrganization.id);

  const suggestedCategories = useMemo(
    () => categoriesResponse || [],
    [categoriesResponse]
  );

  const filteredWorkflows = useMemo(() => {
    if (!workflows) return [];

    let filtered = workflows.filter(
      (workflow) => workflow.workflowType === WorkflowTypeEnum.form
    );

    if (selectedCategory) {
      filtered = filtered.filter((workflow) =>
        workflow.workflowMetadata?.categories?.includes(selectedCategory)
      );
    }

    if (debouncedSearchTerm) {
      const lowerSearchTerm = debouncedSearchTerm.toLowerCase();
      filtered = filtered.filter(
        (workflow) =>
          workflow.name?.toLowerCase().includes(lowerSearchTerm) ||
          workflow.workflowMetadata?.shortDescription
            ?.toLowerCase()
            .includes(lowerSearchTerm) ||
          workflow.workflowMetadata?.longDescription
            ?.toLowerCase()
            .includes(lowerSearchTerm)
      );
    }

    return filtered.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
  }, [workflows, selectedCategory, debouncedSearchTerm]);

  useEffect(() => {
    if (
      !isWorkflowsLoading &&
      !isCategoriesLoading &&
      workflows &&
      categoriesResponse
    ) {
      setIsReady(true);
    }
  }, [isWorkflowsLoading, isCategoriesLoading, workflows, categoriesResponse]);

  return {
    filteredWorkflows,
    suggestedCategories,
    selectedCategory,
    searchTerm,
    isReady,
    isLoading: isWorkflowsLoading || isCategoriesLoading,
    error: workflowsError,
    setSelectedCategory,
    setSearchTerm,
  };
};
