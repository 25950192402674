import { CertificationConfig, CourseSelection } from '@btrway/api-workflow';
import React from 'react';
import { CourseSelectorField } from '../../../selector-components/CourseSelectorField/CourseSelectorField';
import { CertificationConfigProps } from '../../../types/editors';
import { getCourseSelection } from '../../../utils/courseSelectionUtil';

export const CertificationCourseConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const courseSelection = getCourseSelection(config);

  const handleCourseSelect = (
    newCourseSelection: CourseSelection,
    name: string
  ) => {
    const updatedConfig: CertificationConfig = {
      ...config,
      title: name,
      taskProperties: {
        courseSelection: newCourseSelection,
      },
    };
    onConfigChange(updatedConfig);
  };

  return (
    <CourseSelectorField
      courseSelection={courseSelection}
      onCourseSelect={handleCourseSelect}
    />
  );
};
