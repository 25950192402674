import { RegistrationFormResponse, WorkgroupResponse } from '@btrway/api-core';
import { Stack, Text } from '@mantine/core';
import { RegistrationFormCard } from '../RegistrationFormCard/RegistrationFormCard';

interface RegistrationFormsListProps {
  forms: RegistrationFormResponse[];
  isLoading: boolean;
  onSelectForm: (formId: number) => void;
  workgroup: WorkgroupResponse;
}

export function RegistrationFormsList({
  forms,
  isLoading,
  onSelectForm,
  workgroup,
}: RegistrationFormsListProps) {
  if (isLoading) {
    return <Text>Loading registration forms...</Text>;
  }

  if (!forms.length) {
    return <Text>No registration forms found</Text>;
  }

  return (
    <Stack gap="md">
      {forms.map((form) => (
        <RegistrationFormCard
          key={form.id}
          form={form}
          onClick={() => onSelectForm(form.id)}
          workgroup={workgroup}
        />
      ))}
    </Stack>
  );
}
