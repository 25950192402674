import { TaskListStartTypeEnum } from '@btrway/api-workflow';
import { DatePickerButton } from '@btrway/shared-components';
import { useWorkflowTemplate } from '@btrway/workflow-manager';
import {
  Combobox,
  Group,
  Input,
  InputBase,
  Radio,
  Stack,
  useCombobox,
} from '@mantine/core';
import React, { useState } from 'react';

const TaskListStartSelector: React.FC = () => {
  const { workflowTemplate, updateWorkflowTemplate } = useWorkflowTemplate();
  const [configurableDateValue, setConfigurableDateValue] = useState('');
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleTaskListStartTypeChange = (value: string) => {
    // Convert the string value to TaskListStartTypeEnum
    const enumValue = value as TaskListStartTypeEnum;
    updateWorkflowTemplate({
      templateSettings: {
        ...workflowTemplate?.templateSettings,
        taskListStartType: enumValue,
      },
    });
  };

  const handleDatePickerChange = (
    day: number | undefined,
    month: number | undefined
  ) => {
    updateWorkflowTemplate({
      templateSettings: {
        ...workflowTemplate?.templateSettings,
        taskListStartDay: day,
        taskListStartMonth: month,
      },
    });
  };

  if (!workflowTemplate) {
    return null;
  }

  return (
    <Radio.Group
      label="Task List Start"
      value={workflowTemplate.templateSettings?.taskListStartType}
      onChange={handleTaskListStartTypeChange}
    >
      <Stack gap="sm" mt="sm">
        <Radio
          value={TaskListStartTypeEnum.taskAssignment}
          label="At time of assignment"
        />
        <Group align="center">
          <Radio
            value={TaskListStartTypeEnum.specificDay}
            label="Annually on"
          />
          {workflowTemplate.templateSettings?.taskListStartType ===
            TaskListStartTypeEnum.specificDay && (
            <DatePickerButton
              day={workflowTemplate.templateSettings?.taskListStartDay}
              month={workflowTemplate.templateSettings?.taskListStartMonth}
              onChange={handleDatePickerChange}
            />
          )}
        </Group>
        <Group align="center">
          <Radio
            value={TaskListStartTypeEnum.configuredDate}
            label="Configurable Date"
          />
          {workflowTemplate.templateSettings?.taskListStartType ===
            TaskListStartTypeEnum.configuredDate && (
            <Combobox
              store={combobox}
              onOptionSubmit={(val) => {
                setConfigurableDateValue(val);
                combobox.closeDropdown();
              }}
            >
              <Combobox.Target>
                <InputBase
                  component="button"
                  type="button"
                  pointer
                  rightSection={<Combobox.Chevron />}
                  onClick={() => combobox.toggleDropdown()}
                  rightSectionPointerEvents="none"
                  multiline
                >
                  {configurableDateValue || (
                    <Input.Placeholder>
                      Pick configurable date
                    </Input.Placeholder>
                  )}
                </InputBase>
              </Combobox.Target>

              <Combobox.Dropdown>
                <Combobox.Options>
                  <Combobox.Option value="option1">Option 1</Combobox.Option>
                  <Combobox.Option value="option2">Option 2</Combobox.Option>
                  <Combobox.Option value="option3">Option 3</Combobox.Option>
                </Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
          )}
        </Group>
      </Stack>
    </Radio.Group>
  );
};

export default TaskListStartSelector;
