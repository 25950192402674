import { TaskListView } from '@btrway/task-list-builder';
import { WorkflowServiceProvider } from '@btrway/workflow-configuration-manager';
import { Card, ScrollArea } from '@mantine/core';
import React from 'react';

interface TaskListDetailProps {
  workflowKey: string;
}

export const TaskListDetail: React.FC<TaskListDetailProps> = ({
  workflowKey,
}) => {
  return (
    <Card mt="lg" bg="gray.3" withBorder p={0} radius="md">
      <ScrollArea h={300} offsetScrollbars type="auto" pl="xs" pt={0} pb={0}>
        <WorkflowServiceProvider workflowKey={workflowKey}>
          <TaskListView
            workflowKey={workflowKey}
            onTaskSelected={(taskKey) => {}}
            onTaskDeleted={(taskKey) => {}}
          />
        </WorkflowServiceProvider>
      </ScrollArea>
    </Card>
  );
};
