import { Button, Group, Popover, TextInput } from '@mantine/core';
import React, { useRef, useState } from 'react';

interface AddItemPopoverProps {
  buttonText: string;
  placeholder: string;
  onSave: (value: string) => void;
}

const AddItemPopover: React.FC<AddItemPopoverProps> = ({
  buttonText,
  placeholder,
  onSave,
}) => {
  const [opened, setOpened] = useState(false);
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    if (value.trim()) {
      onSave(value.trim());
      setValue('');
      setOpened(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-end"
      withinPortal
    >
      <Popover.Target>
        <Button onClick={() => setOpened(true)}>{buttonText}</Button>
      </Popover.Target>
      <Popover.Dropdown bg="blue">
        <Group>
          <TextInput
            placeholder={placeholder}
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
            ref={inputRef}
            autoFocus
          />
          <Button variant="white" onClick={handleSave}>
            Save
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default AddItemPopover;
