import { Card, Stack, Text } from '@mantine/core';
import { Editor } from '@tiptap/react';
import { DYNAMIC_CONTENT_OPTIONS } from '../../config/dynamicContentOptions';
import { DynamicContentOption } from '../../types/dynamicContentOption';
import classes from './DynamicContentPanel.module.css';

interface DynamicContentPanelProps {
  editor: Editor;
}

export function DynamicContentPanel({ editor }: DynamicContentPanelProps) {
  const handleContentSelect = (option: DynamicContentOption) => {
    editor
      .chain()
      .focus()
      .setDynamicContent({
        id: `${option.id}-${Date.now()}`,
        type: option.type,
        placeholder: option.placeholder,
      })
      .run();
  };

  return (
    <Card className={classes.panel}>
      <div className={classes.panelContent}>
        <Stack gap="sm">
          {DYNAMIC_CONTENT_OPTIONS.map((option) => (
            <Card
              key={option.id}
              shadow="none"
              p="sm"
              withBorder
              className={classes.panelItem}
              onClick={() => handleContentSelect(option)}
            >
              <Stack gap="xs">
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <option.icon size={20} className={classes.panelItemIcon} />
                  <Text size="sm" fw={500}>
                    {option.name}
                  </Text>
                </div>
                <Text size="xs" c="dimmed" style={{ lineHeight: 1.4 }}>
                  {option.description}
                </Text>
              </Stack>
            </Card>
          ))}
        </Stack>
      </div>
    </Card>
  );
}
