import { motion } from 'framer-motion';
import React from 'react';

export const StepConfigConnector: React.FC = () => (
  <motion.div
    initial={{ opacity: 0, height: 40 }}
    animate={{ opacity: 1, height: 40 }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        width: '2px',
        flexGrow: 1,
        backgroundColor: '#000',
      }}
    />
    <div
      style={{
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '10px solid #000',
      }}
    />
  </motion.div>
);
