import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import {
  useGetWorkgroupMemberGrid,
  WorkgroupMemberGridRequest,
} from '@btrway/api-core';
import { LinkGridCell, ResponsiveInfiniteGrid } from '@btrway/grid-components';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export interface SelectedPeopleState {
  selectedPeople: Array<{
    personId: number;
    workgroupMemberId: number;
    workgroupId: number;
  }>;
  selectAll: boolean;
}

interface WorkgroupPeopleGridProps {
  searchValue: string;
  selectedRoles: number[];
  workgroupIds: number[];
  organizationId: number;
  selectedYears: number[];
  onSelectionChanged: (
    selectedCount: number,
    isAllSelected: boolean,
    totalRows: number,
    selectedPeople: Array<{
      personId: number;
      workgroupMemberId: number;
      workgroupId: number;
    }>
  ) => void;
}

export interface WorkgroupPeopleGridRef {
  clearSelection: () => void;
}

const WorkgroupPeopleGrid = forwardRef<
  WorkgroupPeopleGridRef,
  WorkgroupPeopleGridProps
>(
  (
    {
      searchValue,
      selectedRoles,
      workgroupIds,
      organizationId,
      selectedYears,
      onSelectionChanged,
    },
    ref
  ) => {
    const { mutate: getWorkgroupMembers } = useGetWorkgroupMemberGrid();
    const gridApiRef = useRef<any>(null);
    const [totalRows, setTotalRows] = useState<number>(0);

    useImperativeHandle(ref, () => ({
      clearSelection: () => {
        if (gridApiRef.current) {
          gridApiRef.current.deselectAll();
        }
      },
    }));

    const columnDefs: ColDef[] = useMemo(
      () => [
        {
          headerName: 'Name',
          field: 'name',
          cellRenderer: LinkGridCell,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: false,
          cellRendererParams: (params: any) => ({
            value: {
              to: `/app/person/${params.data.personId}`,
              displayText: `${params.data.lastName}, ${params.data.firstName}`,
            },
          }),
          comparator: (valueA, valueB) => valueA.localeCompare(valueB),
          sort: 'asc',
          flex: 2,
        },
        {
          headerName: 'Level',
          field: 'workgroupName',
          flex: 1,
        },
        {
          headerName: 'Level Type',
          field: 'workgroupTypeName',
          flex: 1,
        },
        {
          headerName: 'Role',
          field: 'roleName',
          flex: 1,
        },
        {
          headerName: 'Year',
          field: 'year',
        },
        {
          headerName: 'Email',
          field: 'email',
          flex: 1,
        },
        {
          headerName: 'Phone',
          field: 'mobilePhone',
          flex: 1,
        },
      ],
      []
    );

    const defaultColDef = useMemo<ColDef>(
      () => ({
        sortable: true,
        resizable: true,
        suppressHeaderMenuButton: true,
      }),
      []
    );

    const getRowId = useCallback((params: any) => {
      return params.data.workgroupMemberId.toString();
    }, []);

    const createDatasource = useCallback((): IServerSideDatasource => {
      return {
        getRows: (params: IServerSideGetRowsParams) => {
          const { startRow = 0, endRow = 100, sortModel = [] } = params.request;
          // console.log(`Requesting rows from ${startRow} to ${endRow}`);

          const sortFields: string[] = [];
          const sortOrders: string[] = [];

          sortModel.forEach((model) => {
            if (model.colId === 'name') {
              sortFields.push('lastName', 'firstName');
              sortOrders.push(model.sort, model.sort);
            } else {
              sortFields.push(model.colId);
              sortOrders.push(model.sort);
            }
          });

          const request: WorkgroupMemberGridRequest = {
            organizationId,
            workgroupIds,
            nameSearch: searchValue,
            userRoleIds: selectedRoles,
            years: selectedYears.length > 0 ? selectedYears : undefined,
            startRow,
            endRow,
            sortFields,
            sortOrders,
          };

          getWorkgroupMembers(
            { data: request },
            {
              onSuccess: (data) => {
                const rowsThisBlock = data.content;
                const lastRow =
                  data.totalElements <= endRow ? data.totalElements : -1;
                setTotalRows(data.totalElements);
                params.success({ rowData: rowsThisBlock, rowCount: lastRow });
              },
              onError: () => {
                params.fail();
              },
            }
          );
        },
      };
    }, [
      getWorkgroupMembers,
      organizationId,
      workgroupIds,
      searchValue,
      selectedRoles,
      selectedYears,
    ]);

    const onGridReady = useCallback(
      (params: GridReadyEvent) => {
        gridApiRef.current = params.api;
        const dataSource = createDatasource();
        params.api.setGridOption('serverSideDatasource', dataSource);
      },
      [createDatasource]
    );

    const handleSelectionChanged = useCallback(
      (event: SelectionChangedEvent) => {
        const api = event.api;
        const selectionState =
          api.getServerSideSelectionState() as IServerSideSelectionState;

        const isAllSelected = selectionState.selectAll;
        const selectedCount = selectionState.selectAll
          ? totalRows - (selectionState.toggledNodes?.length || 0)
          : selectionState.toggledNodes?.length || 0;

        // Get the selected rows data
        const selectedNodes = api.getSelectedNodes();
        const selectedPeople = selectedNodes.map((node) => ({
          personId: node.data.personId,
          workgroupMemberId: node.data.workgroupMemberId,
          workgroupId: node.data.workgroupId,
        }));

        onSelectionChanged(
          selectedCount,
          isAllSelected,
          totalRows,
          selectedPeople
        );
      },
      [onSelectionChanged, totalRows]
    );

    useEffect(() => {
      if (gridApiRef.current) {
        const dataSource = createDatasource();
        gridApiRef.current.setGridOption('serverSideDatasource', dataSource);
      }
    }, [searchValue, selectedRoles, createDatasource]);

    return (
      <ResponsiveInfiniteGrid
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        cacheBlockSize={100}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={1000}
        maxBlocksInCache={10}
        enableRangeSelection={true}
        rowSelection="multiple"
        onGridReady={onGridReady}
        onSelectionChanged={handleSelectionChanged}
      />
    );
  }
);

export default WorkgroupPeopleGrid;
