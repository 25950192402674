import { useAuthenticatedUser } from '@btrway/current-user';
import { HeaderLayout } from '@btrway/layout-components';
import { DailySummaryColumn } from '@btrway/mockup-components';
import { TaskAssignmentsBanner } from '@btrway/task-components';
import { PersonTaskView } from '@btrway/workflow-reporting';
import {
  Box,
  Button,
  Drawer,
  Flex,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React from 'react';

const ScoreboardPage: React.FC = () => {
  const [drawerOpened, { open: openDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { currentPerson } = useAuthenticatedUser();

  return (
    <HeaderLayout
      header={<Title order={3}>Hi, {currentPerson.firstName}</Title>}
      headerBg="transparent"
      subtitleComponent={
        <Text fz={14} c="gray">
          Let's Get Started Getting Things Done!
        </Text>
      }
    >
      <Space h={20} />
      <Flex>
        <Box style={{ flex: 1, minWidth: 0 }}>
          <Stack>
            <Button
              onClick={openDrawer}
              size="md"
              fullWidth
              hiddenFrom="md"
              mb="md"
            >
              View Additional
            </Button>
            <Stack gap="md">
              <TaskAssignmentsBanner />
              <PersonTaskView personId={currentPerson.id} />
              {/* <CurrentMonthTaskList />
              <MetricCard2Grid /> */}
            </Stack>
          </Stack>
        </Box>
        <Box visibleFrom="md" ml={40} style={{ width: 300, flexShrink: 0 }}>
          <DailySummaryColumn />
        </Box>
      </Flex>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        title="Progress and Leaderboard"
        padding="xl"
        size="xl"
      >
        <DailySummaryColumn />
      </Drawer>

      <Space h={20} />
    </HeaderLayout>
  );
};

export default ScoreboardPage;
