import {
  CertificationTypeEnum,
  EventTypeEnum,
  StepTypeEnum,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { CertificationCourseConfiguration } from '../config-components/certification/CertificationCourseConfiguration/CertificationCourseConfiguration';
import { CertificationDataConfiguration } from '../config-components/certification/CertificationDataConfiguration/CertificationDataConfiguration';
import { CertificationManualConfiguration } from '../config-components/certification/CertificationManualConfiguration/CertificationManualConfiguration';
import CertificationOptionConfiguration from '../config-components/certification/CertificationOptionConfiguration/CertificationOptionConfiguration';
import { CertificationThirdPartyConfiguration } from '../config-components/certification/CertificationThirdPartyConfiguration/CertificationThirdPartyConfiguration';
import { CertificationWorkflowConfiguration } from '../config-components/certification/CertificationWorkflowConfiguration/CertificationWorkflowConfiguration';
import { EventConfiguration } from '../config-components/event/EventConfiguration/EventConfiguration';
import AssignTaskConfiguration from '../config-components/step/AssignTaskConfiguration/AssignTaskConfiguration';
import { SendEmailConfiguration } from '../config-components/step/SendEmailConfiguration/SendEmailConfiguration';
import { CreateCalendarEventConfiguration } from '../config-components/task/CreateCalendarEventConfiguration/CreateCalendarEventConfiguration';
import { ManualTaskConfiguration } from '../config-components/task/ManualTaskConfiguration/ManualTaskConfiguration';
import { SendEmailTaskConfiguration } from '../config-components/task/SendEmailTaskConfiguration/SendEmailTaskConfiguration';
import { TaskCourseConfiguration } from '../config-components/task/TaskCourseConfiguration/TaskCourseConfiguration';
import TaskOptionConfiguration from '../config-components/task/TaskOptionConfiguration/TaskOptionConfiguration';
import { TaskWorkflowConfiguration } from '../config-components/task/TaskWorkflowConfiguration/TaskWorkflowConfiguration';
import { ThirdPartyTaskConfiguration } from '../config-components/task/ThirdPartyTaskConfiguration/ThirdPartyTaskConfiguration';
import {
  CertificationConfigProps,
  EventConfigProps,
  StepConfigProps,
  TaskConfigProps,
} from '../types/editors';
import { RegistryEntry } from '../types/registry';

export const eventComponentRegistry: Partial<
  Record<EventTypeEnum, RegistryEntry<EventConfigProps>>
> = {
  [EventTypeEnum.formSubmitted]: {
    component: EventConfiguration,
  },
  [EventTypeEnum.personCreated]: {
    component: EventConfiguration,
  },
  [EventTypeEnum.workgroupMemberCreated]: {
    component: EventConfiguration,
  },
};

export const certificationComponentRegistry: Partial<
  Record<CertificationTypeEnum, RegistryEntry<CertificationConfigProps>>
> = {
  [CertificationTypeEnum.completeTask]: {
    component: CertificationManualConfiguration,
  },
  [CertificationTypeEnum.completeTaskList]: {
    component: CertificationWorkflowConfiguration,
  },
  [CertificationTypeEnum.completeTaskOption]: {
    component: CertificationOptionConfiguration,
  },
  [CertificationTypeEnum.completeFormPacket]: {
    component: CertificationWorkflowConfiguration,
  },
  [CertificationTypeEnum.completeCurriculum]: {
    component: CertificationWorkflowConfiguration,
  },
  [CertificationTypeEnum.submitForm]: {
    component: CertificationWorkflowConfiguration,
  },
  [CertificationTypeEnum.certification]: {
    component: CertificationWorkflowConfiguration,
  },
  [CertificationTypeEnum.completeCourse]: {
    component: CertificationCourseConfiguration,
  },
  [CertificationTypeEnum.personData]: {
    component: CertificationDataConfiguration,
  },
  [CertificationTypeEnum.taskData]: {
    component: CertificationDataConfiguration,
  },
  [CertificationTypeEnum.thirdParty]: {
    component: CertificationThirdPartyConfiguration,
  },
};

export const stepComponentRegistry: Partial<
  Record<StepTypeEnum, RegistryEntry<StepConfigProps>>
> = {
  [StepTypeEnum.assignTask]: {
    component: AssignTaskConfiguration,
  },
  [StepTypeEnum.actionSendEmail]: {
    component: SendEmailConfiguration,
  },
};

export const taskComponentRegistry: Partial<
  Record<TaskTypeEnum, RegistryEntry<TaskConfigProps>>
> = {
  [TaskTypeEnum.completeTask]: {
    component: ManualTaskConfiguration,
  },
  [TaskTypeEnum.submitForm]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.uploadFile]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.completeCurriculum]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.completeFormPacket]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.completeTaskList]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.completeTaskOption]: {
    component: TaskOptionConfiguration,
  },
  [TaskTypeEnum.certification]: {
    component: TaskWorkflowConfiguration,
  },
  [TaskTypeEnum.completeCourse]: {
    component: TaskCourseConfiguration,
  },
  [TaskTypeEnum.sendEmail]: {
    component: SendEmailTaskConfiguration,
  },
  // [TaskTypeEnum.addtoWorkgroup]: {
  //   component: ManualTaskConfiguration,
  // },
  [TaskTypeEnum.createCalendarEvent]: {
    component: CreateCalendarEventConfiguration,
  },
  [TaskTypeEnum.thirdParty]: {
    component: ThirdPartyTaskConfiguration,
  },
};
