import {
  getGetHelpResourceByIdQueryKey,
  HelpRepositoryEnum,
  HelpResourceRequest,
  HelpResourceResponse,
  useGetHelpResourceById,
  useSaveHelpResource,
} from '@btrway/api-help';
import { ContentEditor } from '@btrway/content-editor';
import { S3FileUploader } from '@btrway/file-storage-components';
import { SaveButton } from '@btrway/shared-components';
import { uuid } from '@btrway/utils';
import { MuxSingleVideoUploader } from '@btrway/video-components';
import {
  Box,
  Button,
  Group,
  Stack,
  TagsInput,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';

interface HelpResourceEditorProps {
  helpResourceId?: number | null;
  repository?: HelpRepositoryEnum;
  onSave?: () => void;
  onCancel?: () => void;
}

export const HelpResourceEditor: React.FC<HelpResourceEditorProps> = ({
  helpResourceId,
  repository,
  onSave,
  onCancel,
}) => {
  const [helpResource, setHelpResource] = useState<
    Partial<HelpResourceResponse>
  >({});

  const { mutateAsync: saveHelpResource } = useSaveHelpResource();

  const {
    data: helpResourceResponse,
    isLoading,
    error,
  } = useGetHelpResourceById(helpResourceId ?? 0, {
    query: {
      enabled: helpResourceId !== null && helpResourceId !== undefined,
      queryKey: getGetHelpResourceByIdQueryKey(helpResourceId ?? 0),
    },
  });

  useEffect(() => {
    if (helpResourceResponse && helpResourceId) {
      // Ensure the content is properly structured when setting initial state
      setHelpResource({
        ...helpResourceResponse,
        content: {
          ...helpResourceResponse.content,
          // Make sure content is a valid JSON string
          content: helpResourceResponse.content?.content,
        },
      });
    }
  }, [helpResourceResponse, helpResourceId]);

  const handleContentChange = (json: Record<string, unknown>, html: string) => {
    setHelpResource((prev) => ({
      ...prev,
      content: { content: json },
    }));
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleTitleChange', event.currentTarget.value);
    setHelpResource((prev) => ({
      ...prev,
      metadata: {
        ...prev.metadata,
        title: event.currentTarget.value,
      },
    }));
  };

  const handleTagsChange = (newTags: string[]) => {
    setHelpResource((prev) => ({ ...prev, tags: newTags }));
  };

  const handleVideoUpload = (
    assetId: string,
    playbackId: string,
    thumbnail: string
  ) => {
    console.log('handleVideoUpload', assetId, playbackId, thumbnail);
    setHelpResource((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        assetIdentifier: assetId || undefined,
      },
    }));
  };

  const handleFileUpload = (fileKey: string, fileName: string) => {
    console.log('handleFileUpload', fileKey, fileName);
    setHelpResource((prev) => ({
      ...prev,
      content: {
        ...prev.content,
        fileKey: fileKey || undefined,
      },
    }));
  };

  const handleSave = async () => {
    try {
      let helpResourceRequest: HelpResourceRequest;

      console.log('helpResourceId', helpResourceId);
      console.log('repository', repository);

      if (helpResourceId) {
        helpResourceRequest = helpResource as HelpResourceRequest;
      } else {
        if (!repository) {
          throw new Error('Repository is required for new help resources');
        }

        helpResourceRequest = {
          ...helpResource,
          helpResourceKey: uuid(),
          enabled: true,
          published: true,
          repository,
        } as HelpResourceRequest;
      }

      console.log('helpResourceRequest', helpResourceRequest);

      await saveHelpResource({
        data: helpResourceRequest,
      });
      onSave?.();
    } catch (error) {
      console.error('Error saving article:', error);
    }
  };

  if (isLoading && helpResourceId) return <Text>Loading...</Text>;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        marginBottom: 'var(--mantine-spacing-md)',
      }}
    >
      <Group justify="space-between" align="flex-start" mb="md">
        <Stack gap="xs" style={{ flex: 1 }}>
          <TextInput
            label="Title"
            value={helpResource.metadata?.title || ''}
            onChange={handleTitleChange}
            placeholder="Enter title"
            autoFocus
            data-autofocus
          />
          <TagsInput
            label="Tags"
            value={helpResource.tags || []}
            onChange={handleTagsChange}
            placeholder="Enter tags"
          />
          {repository === 'clientWhy' && (
            <Box style={{ maxWidth: 178 }}>
              <MuxSingleVideoUploader
                onUploadComplete={handleVideoUpload}
                existingPlaybackId={helpResource.content?.assetIdentifier}
              />
            </Box>
          )}
          {repository === 'clientFiles' && (
            <S3FileUploader
              onUploadComplete={handleFileUpload}
              existingFileKey={helpResource.content?.fileKey}
              existingFileName={helpResource.content?.fileKey?.split('/').pop()}
            />
          )}
        </Stack>
        <Group>
          {onCancel && (
            <Button variant="subtle" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <SaveButton variant="outline" bg="white" onSave={handleSave} />
        </Group>
      </Group>

      <Box style={{ height: 450 }}>
        <ContentEditor
          initialContent={helpResource.content?.content}
          onChange={handleContentChange}
        />
      </Box>
    </div>
  );
};
