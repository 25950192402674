/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type FileTypeGroupingEnum = typeof FileTypeGroupingEnum[keyof typeof FileTypeGroupingEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileTypeGroupingEnum = {
  images: 'images',
  pdf: 'pdf',
  documents: 'documents',
  audioVideo: 'audioVideo',
  videos: 'videos',
  spreadsheets: 'spreadsheets',
} as const;
