import {
  TaskInstanceRequest,
  TaskInstanceRequestTaskData,
  TaskInstanceResponse,
  useSaveTaskInstance,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useCallback } from 'react';
import { TaskCompletionContext } from '../types/TaskCompletionContext';
import { TaskCompletionResult } from '../types/TaskCompletionResult';
import { convertToEntityTaskResponse } from '../utils/convertToEntityTaskResponse';
import { convertToTaskInstanceRequest } from '../utils/convertToTaskInstanceRequest';
import { getStableTaskIdentifier } from '../utils/getStableTaskIdentifier';
import { useTaskManager } from './useTaskManager';
import { useTaskOptionCompletion } from './useTaskOptionCompletion';

interface UseTaskCompletionOptions extends TaskCompletionContext {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const useTaskCompletion = ({
  taskInstanceId,
  assignedEntityType,
  assignedEntityId,
  workgroupId,
  derivedWorkflowKey,
  taskKey,
  taskProperties,
  taskType,
  onSuccess,
  onError,
}: UseTaskCompletionOptions) => {
  const { currentPerson, currentOrganization } = useAuthenticatedUser();
  const { tasks, updateTask } = useTaskManager();
  const { updateParentTaskOptionAfterCompletion } = useTaskOptionCompletion();
  const { mutateAsync: saveTaskInstance } = useSaveTaskInstance();

  // Helper function to find existing task using stable identifier
  const findExistingTask = useCallback(
    (taskResponse?: TaskInstanceResponse) => {
      const searchCriteria = taskResponse || {
        id: taskInstanceId,
        assignedEntityType,
        assignedEntityId,
        workgroupId,
        derivedWorkflowKey,
        taskKey,
        taskProperties,
      };

      const existingTasks = tasks.find(
        (task) =>
          getStableTaskIdentifier(task) ===
          getStableTaskIdentifier(searchCriteria)
      );

      return existingTasks;
    },
    [
      tasks,
      taskInstanceId,
      assignedEntityType,
      assignedEntityId,
      derivedWorkflowKey,
      workgroupId,
      taskKey,
      taskProperties,
    ]
  );

  const completeTask = useCallback(
    async (
      comment?: string,
      taskData?: TaskInstanceRequestTaskData
    ): Promise<TaskCompletionResult> => {
      try {
        const existingTask = findExistingTask();

        console.log('completeTask, workgroupId', workgroupId);

        // Prepare task request
        const taskRequest = convertToTaskInstanceRequest(
          {
            ...existingTask,
            taskInstanceId,
            organizationId: currentOrganization.id,
            taskKey,
            assignedEntityType,
            assignedEntityId,
            workgroupId,
            // derivedWorkflowKey,
            taskType,
            taskProperties,
            completed: true,
            completedAt: new Date().toISOString(),
            completedBy: currentPerson.id,
          },
          comment
        );

        console.log('taskRequest', taskRequest);

        const taskRequestWithProperties: TaskInstanceRequest = {
          ...taskRequest,
          taskProperties,
          taskData, // Add the taskData to the request
        };

        // Save task to backend
        const response = (await saveTaskInstance({
          data: taskRequestWithProperties,
        })) as TaskInstanceResponse;

        // Convert response to grid format and update local state
        const entityResponse = convertToEntityTaskResponse(
          {
            ...response,
          },
          existingTask
        );

        updateTask(entityResponse);
        updateParentTaskOptionAfterCompletion(entityResponse);

        onSuccess?.();

        return {
          success: true,
          taskInstanceId: response.id,
        };
      } catch (error) {
        const err =
          error instanceof Error ? error : new Error('Unknown error occurred');
        onError?.(err);
        return { success: false, error: err };
      }
    },
    [
      currentPerson.id,
      currentOrganization.id,
      taskInstanceId,
      assignedEntityType,
      assignedEntityId,
      workgroupId,
      derivedWorkflowKey,
      taskKey,
      taskProperties,
      saveTaskInstance,
      findExistingTask,
      onSuccess,
      onError,
    ]
  );

  const markTaskViewed = useCallback(async () => {
    if (!currentOrganization.id) return;

    // try {
    //   const existingTask = findExistingTask();

    //   if (existingTask && !existingTask.viewed) {
    //     const taskRequest = convertToTaskInstanceRequest({
    //       ...existingTask,
    //       viewed: true,
    //       lastViewedAt: new Date().toISOString(),
    //     });

    //     const response = (await saveTaskInstance({
    //       data: taskRequest,
    //     })) as TaskInstanceResponse;

    //     // Convert response to grid format and update local state
    //     const gridResponse = convertToEntityTaskResponse(
    //       {
    //         ...response,
    //       },
    //       existingTask
    //     );
    //     updateTask(gridResponse);
    //   }
    // } catch (error) {
    //   console.error('Failed to mark task as viewed:', error);
    // }
  }, [currentOrganization.id, findExistingTask, saveTaskInstance]);

  return {
    completeTask,
    markTaskViewed,
  };
};
