import { ColorSwatch, Menu, rem } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconPalette, IconTypography } from '@tabler/icons-react';

const fontSizes = [
  { name: 'Small', value: '14px' },
  { name: 'Default', value: '16px' },
  { name: 'Large', value: '20px' },
  { name: 'Extra Large', value: '24px' },
  { name: 'Huge', value: '32px' },
];

const colors = [
  // Row 1 - Neutrals and Reds
  { name: 'Black', value: '#000000' },
  { name: 'Dark Gray', value: '#343A40' },
  { name: 'Gray', value: '#868E96' },
  { name: 'Light Gray', value: '#CED4DA' },
  { name: 'Dark Red', value: '#C92A2A' },
  { name: 'Red', value: '#E03131' },
  { name: 'Light Red', value: '#FA5252' },

  // Row 2 - Oranges to Greens
  { name: 'Dark Orange', value: '#D9480F' },
  { name: 'Orange', value: '#F76707' },
  { name: 'Gold', value: '#F59F00' },
  { name: 'Yellow', value: '#FAB005' },
  { name: 'Lime', value: '#82C91E' },
  { name: 'Green', value: '#37B24D' },
  { name: 'Dark Green', value: '#2B8A3E' },

  // Row 3 - Blues and Purples
  { name: 'Teal', value: '#0C8599' },
  { name: 'Light Blue', value: '#339AF0' },
  { name: 'Blue', value: '#1C7ED6' },
  { name: 'Dark Blue', value: '#1864AB' },
  { name: 'Purple', value: '#7048E8' },
  { name: 'Violet', value: '#9C36B5' },
  { name: 'Pink', value: '#D6336C' },
];

export function FontControls() {
  const { editor } = useRichTextEditorContext();

  if (!editor) {
    return null;
  }

  return (
    <RichTextEditor.ControlsGroup>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <RichTextEditor.Control aria-label="Set font size" title="Font size">
            <IconTypography size={16} />
          </RichTextEditor.Control>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Font Size</Menu.Label>
          {fontSizes.map((size) => (
            <Menu.Item
              key={size.value}
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .setMark('textStyle', { fontSize: size.value })
                  .run()
              }
              style={{ fontSize: size.value }}
            >
              {size.name}
            </Menu.Item>
          ))}
          <Menu.Divider />
          <Menu.Item
            onClick={() => editor.chain().focus().unsetMark('textStyle').run()}
          >
            Reset size
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Menu shadow="md">
        <Menu.Target>
          <RichTextEditor.Control
            aria-label="Set text color"
            title="Text color"
          >
            <IconPalette size={16} />
          </RichTextEditor.Control>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Text Color</Menu.Label>
          <div
            style={{
              padding: rem(5),
              display: 'flex',
              flexWrap: 'wrap',
              gap: rem(5),
              maxWidth: '200px',
            }}
          >
            {colors.map((color) => (
              <ColorSwatch
                key={color.value}
                component="button"
                color={color.value}
                title={color.name}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .setMark('textStyle', { color: color.value })
                    .run()
                }
                style={{ cursor: 'pointer' }}
                size={22}
              />
            ))}
          </div>
          <Menu.Divider />
          <Menu.Item
            onClick={() => editor.chain().focus().unsetMark('textStyle').run()}
          >
            Reset color
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </RichTextEditor.ControlsGroup>
  );
}
