/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * BetterWay API
 * OpenAPI spec version: v1.0
 */

export type RegistrationFormDateTypeEnum = typeof RegistrationFormDateTypeEnum[keyof typeof RegistrationFormDateTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegistrationFormDateTypeEnum = {
  annualDate: 'annualDate',
  yearStartOffset: 'yearStartOffset',
  yearEndOffset: 'yearEndOffset',
} as const;
