import { useGetFilteredEvents } from '@btrway/api-calendar';
import { DatesSetArg } from '@fullcalendar/core';
import { useMemo } from 'react';
import { convertToFullCalendarEvents } from '../utils/fullCalendarConversion';

interface UseCalendarEventsProps {
  organizationId: number;
  workgroupId?: number;
  personId?: number;
  facilityId?: number;
  timezone: string;
}

interface FetchEventsArgs {
  startDate: string;
  endDate: string;
}

export const useCalendarEvents = ({
  organizationId,
  workgroupId,
  personId,
  facilityId,
  timezone,
}: UseCalendarEventsProps) => {
  const { mutate: getEvents, data: eventData = [] } = useGetFilteredEvents();

  const calendarEvents = useMemo(
    () => convertToFullCalendarEvents(eventData),
    [eventData]
  );

  const fetchEvents = (arg: DatesSetArg | FetchEventsArgs) => {
    // If it's a DatesSetArg (from FullCalendar)
    if ('startStr' in arg) {
      getEvents({
        data: {
          organizationId,
          workgroupId,
          personId,
          facilityId,
          startDate: arg.startStr,
          endDate: arg.endStr,
        },
      });
    } else {
      // If it's our custom FetchEventsArgs
      getEvents({
        data: {
          organizationId,
          workgroupId,
          personId,
          facilityId,
          startDate: arg.startDate,
          endDate: arg.endDate,
        },
      });
    }
  };

  return {
    calendarEvents,
    fetchEvents,
  };
};
