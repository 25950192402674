import {
  CertificationTypeEnum,
  StepTypeTypeEnum,
  TaskTypeEnum,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import {
  useCertificationTypes,
  useEventTypes,
  useStepTypes,
  useTaskTypes,
} from '@btrway/workflow-manager';
import React from 'react';
import { ToolboxItem } from '../../types/itemType';
import { ToolboxPanel } from '../ToolboxPanel/ToolboxPanel';

export type ToolboxMode = 'task' | 'certification' | 'step' | 'event';

interface ToolboxManagerProps {
  mode: ToolboxMode;
  workflowType?: WorkflowTypeEnum;
  entityType?: string;
  onSelect: (item: ToolboxItem) => void;
}

const CERTIFICATION_SECTIONS = {
  GROUPS: 'Groups of Tasks',
  INDIVIDUAL: 'Individual Tasks',
  DATA: 'Data-based Rules',
} as const;

const STEP_SECTIONS = {
  ASSIGNABLE: 'Assignable Tasks',
  AUTOMATED: 'Automated Actions',
} as const;

const CERTIFICATION_TYPE_SECTIONS: Record<string, CertificationTypeEnum[]> = {
  [CERTIFICATION_SECTIONS.GROUPS]: [
    CertificationTypeEnum.completeCurriculum,
    CertificationTypeEnum.completeTaskList,
    CertificationTypeEnum.completeFormPacket,
    CertificationTypeEnum.certification,
  ],
  [CERTIFICATION_SECTIONS.INDIVIDUAL]: [
    CertificationTypeEnum.completeTask,
    CertificationTypeEnum.completeCourse,
    CertificationTypeEnum.completeTaskOption,
    CertificationTypeEnum.submitForm,
    CertificationTypeEnum.thirdParty,
  ],
  [CERTIFICATION_SECTIONS.DATA]: [
    CertificationTypeEnum.taskData,
    CertificationTypeEnum.personData,
  ],
};

const getAllowedTaskTypes = (
  workflowType?: WorkflowTypeEnum
): TaskTypeEnum[] => {
  switch (workflowType) {
    case WorkflowTypeEnum.taskList:
      return [
        TaskTypeEnum.completeTask,
        TaskTypeEnum.completeCourse,
        TaskTypeEnum.completeCurriculum,
        TaskTypeEnum.createCalendarEvent,
        TaskTypeEnum.sendEmail,
        TaskTypeEnum.submitForm,
        TaskTypeEnum.completeFormPacket,
        TaskTypeEnum.uploadFile,
        TaskTypeEnum.thirdParty,
        TaskTypeEnum.completeTaskList,
        TaskTypeEnum.completeTaskOption,
      ];
    case WorkflowTypeEnum.taskOption:
      return [
        TaskTypeEnum.completeTask,
        TaskTypeEnum.completeCourse,
        // TaskTypeEnum.createCalendarEvent,
        // TaskTypeEnum.sendEmail,
        TaskTypeEnum.certification,
        TaskTypeEnum.submitForm,
        TaskTypeEnum.uploadFile,
        TaskTypeEnum.thirdParty,
      ];
    case WorkflowTypeEnum.form:
      return [TaskTypeEnum.completeWorkflowStep];
    case WorkflowTypeEnum.automation:
      return Object.values(TaskTypeEnum);
    default:
      return [];
  }
};

export const ToolboxManager: React.FC<ToolboxManagerProps> = ({
  mode,
  workflowType,
  entityType,
  onSelect,
}) => {
  const { taskTypes } = useTaskTypes();
  const { certificationTypes } = useCertificationTypes();
  const { stepTypes } = useStepTypes();
  const { eventTypes } = useEventTypes();

  // Get sections or items based on mode
  const { sections, items, title } = React.useMemo(() => {
    switch (mode) {
      case 'task': {
        const allowedTaskTypes = getAllowedTaskTypes(workflowType);
        const taskItems =
          allowedTaskTypes.length > 0
            ? taskTypes
                .filter((taskType) => allowedTaskTypes.includes(taskType.code))
                .map((taskType) => ({
                  type: 'taskType' as const,
                  data: taskType,
                }))
            : [];
        return {
          items: taskItems,
          sections: undefined,
          title:
            workflowType === WorkflowTypeEnum.certification
              ? 'Certification Items'
              : 'Tasks',
        };
      }

      case 'certification': {
        const certSections = Object.entries(CERTIFICATION_TYPE_SECTIONS).map(
          ([title, typesList]) => ({
            title,
            items: certificationTypes
              .filter((certType) =>
                typesList.includes(certType.code as CertificationTypeEnum)
              )
              .map((certType) => ({
                type: 'certificationType' as const,
                data: certType,
              })),
          })
        );
        return {
          sections: certSections,
          items: undefined,
          title: 'Certification Items',
        };
      }

      case 'step': {
        const allowedTaskTypes = getAllowedTaskTypes(workflowType);
        const taskItems =
          allowedTaskTypes.length > 0
            ? taskTypes
                .filter((taskType) => allowedTaskTypes.includes(taskType.code))
                .map((taskType) => ({
                  type: 'taskType' as const,
                  data: taskType,
                }))
            : [];

        const stepItems = stepTypes
          .filter((stepType) => stepType.type === StepTypeTypeEnum.action)
          .map((stepType) => ({
            type: 'stepType' as const,
            data: stepType,
          }));

        const sections = [
          {
            title: STEP_SECTIONS.ASSIGNABLE,
            items: taskItems,
          },
          {
            title: STEP_SECTIONS.AUTOMATED,
            items: stepItems,
          },
        ];
        return {
          sections,
          items: undefined,
          title: 'Select a task or action',
        };
      }

      case 'event': {
        const eventItems = eventTypes
          .filter(
            (eventType) => !entityType || eventType.entityType === entityType
          )
          .map((eventType) => ({
            type: 'eventType' as const,
            data: eventType,
          }));
        return {
          items: eventItems,
          sections: undefined,
          title: 'Events',
        };
      }

      default:
        return { sections: undefined, items: undefined, title: '' };
    }
  }, [
    mode,
    workflowType,
    taskTypes,
    certificationTypes,
    stepTypes,
    eventTypes,
    entityType,
  ]);

  return (
    <ToolboxPanel
      title={title}
      sections={sections}
      items={items}
      onCardSelect={onSelect}
      droppableId={`${mode}-toolbox-droppable`}
      variant={mode === 'event' ? 'full' : 'compact'}
    />
  );
};
