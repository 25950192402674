import {
  TaskConfig,
  TaskTypeEnum,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import {
  CommonWorkflow,
  useWorkflowService,
} from '@btrway/workflow-configuration-manager';
import React, { useEffect, useState } from 'react';
import { WorkflowSelectorField } from '../../../selector-components/WorkflowSelectorField/WorkflowSelectorField';
import { TaskConfigProps } from '../../../types/editors';
import { getWorkflowSelection } from '../../../utils/workflowSelectionUtil';

export const TaskWorkflowConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const workflowType =
    config.taskType === TaskTypeEnum.submitForm
      ? WorkflowTypeEnum.form
      : config.taskType === TaskTypeEnum.uploadFile
      ? WorkflowTypeEnum.file
      : config.taskType === TaskTypeEnum.completeCurriculum
      ? WorkflowTypeEnum.curriculum
      : config.taskType === TaskTypeEnum.completeTaskList
      ? WorkflowTypeEnum.taskList
      : config.taskType === TaskTypeEnum.completeTaskOption
      ? WorkflowTypeEnum.taskOption
      : config.taskType === TaskTypeEnum.certification
      ? WorkflowTypeEnum.certification
      : config.taskType === TaskTypeEnum.completeFormPacket
      ? WorkflowTypeEnum.formPacket
      : null;

  if (!workflowType) {
    throw new Error(
      'Workflow type could not be determined for TaskWorkflowConfiguration component'
    );
  }

  //TODO: We need to not be fetching workflows again, should work from cache but needs to be a cache that supports CommonWorkflow
  const workflowService = useWorkflowService();
  const [workflows, setWorkflows] = useState<CommonWorkflow[]>([]);
  const workflowSelection = getWorkflowSelection(config);

  useEffect(() => {
    const fetchWorkflows = async () => {
      const result = await workflowService.getWorkflows();
      setWorkflows(result.workflows);
    };

    fetchWorkflows();
  }, [workflowService]);

  const selectedWorkflow = workflows.find((workflow) => {
    const key =
      workflow.sourceType === 'definition'
        ? workflow.workflowKey
        : workflow.templateKey;
    return key === workflowSelection?.workflowDefinitionKey.value;
  });

  const handleWorkflowSelect = (
    newWorkflowSelection: WorkflowSelection,
    name: string
  ) => {
    // Get the current workflow name from the existing selection
    const currentWorkflowName = selectedWorkflow?.name;

    // Get the current title from metadata
    const currentTitle = config.metadata?.title || '';

    // Determine if we should update the title:
    // 1. If the current title is empty
    // 2. If the current title exactly matches the previous workflow name
    const shouldUpdateTitle =
      !currentTitle || currentTitle === currentWorkflowName;

    const updatedConfig: TaskConfig = {
      ...config,
      metadata: {
        ...config.metadata,
        // Only update title if conditions are met
        title: shouldUpdateTitle ? name : config.metadata?.title,
      },
      taskProperties: {
        ...config.taskProperties,
        workflowSelection: newWorkflowSelection,
      },
    };

    onConfigChange(updatedConfig);
  };

  return (
    <WorkflowSelectorField
      workflowType={workflowType}
      workflowSelection={workflowSelection}
      onWorkflowSelect={handleWorkflowSelect}
    />
  );
};
