import {
  CertificationConfig,
  CertificationTypeEnum,
  CustomTaskProperties,
} from '@btrway/api-workflow';
import { Stack, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTypedCertificationProperties } from '../../../config/certificationPropertiesMap';
import { CertificationConfigProps } from '../../../types/editors';

export const CertificationManualConfiguration: React.FC<
  CertificationConfigProps
> = ({ config, onConfigChange }) => {
  const certificationProperties = getTypedCertificationProperties(
    config,
    CertificationTypeEnum.completeTask
  );

  const [properties, setProperties] = useState<CustomTaskProperties>({
    title: certificationProperties?.title || '',
    shortDescription: certificationProperties?.shortDescription || '',
    longDescription: certificationProperties?.longDescription || '',
  });

  useEffect(() => {
    if (certificationProperties) {
      setProperties({
        title: certificationProperties.title,
        shortDescription: certificationProperties?.shortDescription || '',
        longDescription: certificationProperties?.longDescription || '',
      });
    }
  }, [certificationProperties]);

  const handlePropertyChange = (
    field: keyof CustomTaskProperties,
    value: string
  ) => {
    const updatedProperties = {
      ...properties,
      [field]: value,
    };
    setProperties(updatedProperties);

    const updatedConfig: CertificationConfig = {
      ...config,
      title: updatedProperties.title,
      taskProperties: updatedProperties,
    };
    onConfigChange(updatedConfig);
  };

  return (
    <Stack>
      <TextInput
        label="Title"
        placeholder="Enter certification title"
        value={properties.title}
        onChange={(event) =>
          handlePropertyChange('title', event.currentTarget.value)
        }
      />
      <TextInput
        label="Short Description"
        placeholder="Enter a brief description"
        value={properties.shortDescription || ''}
        onChange={(event) =>
          handlePropertyChange('shortDescription', event.currentTarget.value)
        }
      />
      <Textarea
        label="Long Description"
        placeholder="Enter detailed description"
        value={properties.longDescription || ''}
        onChange={(event) =>
          handlePropertyChange('longDescription', event.currentTarget.value)
        }
        rows={4}
      />
    </Stack>
  );
};
