import {
  TaskAssignmentFrequencyEnum,
  TaskAssignmentRequest,
  TaskAssignmentResponse,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useUserRoles } from '@btrway/security-manager';
import { useWorkflowDefinitions } from '@btrway/workflow-manager';
import { Button, Card, Group, Select, Text } from '@mantine/core';
import React, { useMemo, useState } from 'react';

interface TaskAssignmentCardProps {
  taskAssignment?: TaskAssignmentResponse;
  onSave: (request: { data: TaskAssignmentRequest }) => Promise<void>;
  onDelete?: (id: number) => Promise<void>;
  isNew?: boolean;
}

const TaskAssignmentCard: React.FC<TaskAssignmentCardProps> = ({
  taskAssignment,
  onSave,
  onDelete,
  isNew = false,
}) => {
  const [isEditing, setIsEditing] = useState(isNew);
  const [selectedRoleId, setSelectedRoleId] = useState<number>(
    taskAssignment?.userRoleId || 0
  );
  const [selectedWorkflowKey, setSelectedWorkflowKey] = useState<string>(
    taskAssignment?.workflowKey || ''
  );

  const { currentOrganization, rootWorkgroupId } = useAuthenticatedUser();
  const { userRoles, getUserRoleById } = useUserRoles();
  const { workflows, getWorkflowDefinitionByKey } = useWorkflowDefinitions();

  const groupedWorkflowData = useMemo(() => {
    const assignableWorkflows =
      workflows?.filter(
        (workflow) =>
          workflow.workflowType === WorkflowTypeEnum.taskList ||
          workflow.workflowType === WorkflowTypeEnum.certification ||
          workflow.workflowType === WorkflowTypeEnum.curriculum ||
          workflow.workflowType === WorkflowTypeEnum.formPacket
      ) || [];

    const groups = [
      {
        type: WorkflowTypeEnum.certification,
        label: 'Certifications',
      },
      {
        type: WorkflowTypeEnum.taskList,
        label: 'To-Do Lists',
      },
      {
        type: WorkflowTypeEnum.formPacket,
        label: 'Form Packets',
      },
      {
        type: WorkflowTypeEnum.curriculum,
        label: 'Curriculums',
      },
    ];

    return groups
      .map(({ type, label }) => {
        const items = assignableWorkflows
          .filter((workflow) => workflow.workflowType === type)
          .map((workflow) => ({
            value: workflow.workflowKey,
            label: workflow.name || 'Unnamed Workflow',
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        return items.length > 0 ? { group: label, items } : null;
      })
      .filter(
        (
          group
        ): group is {
          group: string;
          items: { value: string; label: string }[];
        } => group !== null
      );
  }, [workflows]);

  const roleSelectData =
    userRoles
      ?.map((role) => ({
        value: role.id.toString(),
        label: role.name || 'Unnamed Role',
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const userRole = getUserRoleById(taskAssignment?.userRoleId || 0);
  const workflow = getWorkflowDefinitionByKey(
    taskAssignment?.workflowKey || ''
  );

  const handleSave = async () => {
    const request: TaskAssignmentRequest = {
      id: taskAssignment?.id,
      organizationId: currentOrganization.id,
      userRoleId: selectedRoleId,
      workflowKey: selectedWorkflowKey,
      workgroupId: rootWorkgroupId,
      frequency: TaskAssignmentFrequencyEnum.yearly,
    };

    await onSave({ data: request });
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <Card shadow="sm" p="lg" radius="md" withBorder style={{ width: '100%' }}>
        <Select
          label="User Role"
          placeholder="Select user role"
          data={roleSelectData}
          value={selectedRoleId.toString()}
          onChange={(value) => setSelectedRoleId(Number(value))}
          required
        />

        <Select
          label="Workflow"
          placeholder="Select workflow"
          data={groupedWorkflowData}
          value={selectedWorkflowKey}
          onChange={(value) => setSelectedWorkflowKey(value || '')}
          required
          mt="md"
        />

        <Group justify="flex-end" mt="lg">
          <Button variant="subtle" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Group>
      </Card>
    );
  }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder style={{ width: '100%' }}>
      <Group justify="space-between">
        <div>
          <Text fw={500}>{userRole?.name || 'Unknown Role'}</Text>
          <Text size="sm" color="dimmed">
            {workflow?.name || 'Unknown Workflow'}
          </Text>
        </div>
        <Group>
          <Button variant="subtle" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
          {onDelete && taskAssignment?.id && (
            <Button
              variant="subtle"
              color="red"
              onClick={() => onDelete(taskAssignment.id)}
            >
              Delete
            </Button>
          )}
        </Group>
      </Group>
    </Card>
  );
};

export default TaskAssignmentCard;
