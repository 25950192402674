import {
  getGetAssetFoldersQueryKey,
  getGetAssetsQueryKey,
  useGetAssetFolders,
  useGetAssets,
} from '@btrway/api-courseware';
import { useCallback } from 'react';

export const useCourses = () => {
  const {
    data: assets,
    isLoading: isCoursesLoading,
    error,
    refetch: refetchCourses,
  } = useGetAssets({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetAssetsQueryKey(),
    },
  });

  const {
    data: assetFolders,
    isLoading: isAssetsLoading,
    refetch: refetchFolders,
  } = useGetAssetFolders({
    query: {
      staleTime: Infinity, // Set staleTime to infinity to always use cached data
      refetchOnWindowFocus: false, // Disable automatic refetching
      queryKey: getGetAssetFoldersQueryKey(),
    },
  });

  const getCourse = useCallback(
    (courseUid: number) => {
      return assets?.find((asset) => asset.uid === courseUid);
    },
    [assets]
  );

  return {
    courses: assets ?? [],
    courseFolders: assetFolders ?? [],
    isLoading: isCoursesLoading || isAssetsLoading,
    error,
    getCourse,
    refetchCourses,
    refetchFolders,
  };
};
