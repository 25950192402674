import { AssetResponse } from '@btrway/api-courseware';
import { StorageUsageEnum } from '@btrway/api-storage';
import { CompletionProgressBar } from '@btrway/courseware-progress-components';
import { useImageUpload } from '@btrway/file-storage-components';
import { getFormattedDuration } from '@btrway/utils';
import { Card, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import classes from './CourseCard.module.css';

interface CourseCardProps {
  course: AssetResponse;
  onClick?: (courseId: number) => void;
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onClick }) => {
  const { title, metadata } = course;

  const imageKey = course.metadata?.imageKey;

  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.course,
    initialImageKey: imageKey,
  });

  const handleClick = () => {
    if (onClick) {
      onClick(course.id);
    }
  };

  const courseDuration = course.chapters?.reduce(
    (acc, chapter) => acc + (chapter.duration || 0),
    0
  );

  return (
    <>
      <div onClick={handleClick} style={{ cursor: 'pointer' }}>
        <Card
          shadow="sm"
          padding="xl"
          bg="transparent"
          // withBorder={true}
          style={{
            display: 'flex',
            marginBottom: 20,
          }}
        >
          <Group align="start" wrap="nowrap">
            <Image
              src={imageUrl}
              height={240}
              alt={course.title}
              fallbackSrc="https://placehold.co/600x400?text=No+Course+Image"
            />
            <Stack w="100%" px={0} justify="flex-start">
              <div className={classes.cardTitle}>
                <Text fw={500} fz={24} c="white">
                  {title}
                </Text>
              </div>
              <Text size="sm" c="white" fz="lg" style={{ lineHeight: 1.5 }}>
                {metadata?.description}
              </Text>
              <Text size="sm" c="dimmed" fz="lg" style={{ lineHeight: 1.5 }}>
                {getFormattedDuration(courseDuration || 0)}
              </Text>
              <div className={classes.completionPercent}>
                <Stack gap={5}>
                  <CompletionProgressBar course={course} />
                </Stack>
              </div>
            </Stack>
          </Group>
        </Card>
      </div>
    </>
  );
};

export default CourseCard;
