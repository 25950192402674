import {
  DataTypeEnum,
  EntityGroupTypeEnum,
  useSaveEntityGroup,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { Button, Modal, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import React, { useState } from 'react';
import { useWorkgroup } from '../../providers/WorkgroupProvider';
import { SelectedPeopleState } from '../WorkgroupPeopleGrid/WorkgroupPeopleGrid';

interface AddToGroupModalProps {
  opened: boolean;
  onClose: () => void;
  selectionState: SelectedPeopleState;
}

const AddToGroupModal: React.FC<AddToGroupModalProps> = ({
  opened,
  onClose,
  selectionState,
}) => {
  const [loading, setLoading] = useState(false);
  const { workgroup } = useWorkgroup();
  const { currentOrganization } = useAuthenticatedUser();
  const { mutateAsync: saveEntityGroup } = useSaveEntityGroup();

  const form = useForm({
    initialValues: {
      name: '',
    },
    validate: {
      name: (value) => (value.trim().length === 0 ? 'Name is required' : null),
    },
  });

  const handleSubmit = async (values: { name: string }) => {
    if (!workgroup || !currentOrganization) return;

    setLoading(true);
    try {
      const entityGroupRequest = {
        name: values.name,
        organizationId: currentOrganization.id,
        workgroupId: workgroup.id,
        entityType: DataTypeEnum.person,
        groupType: EntityGroupTypeEnum.manual,
        members: selectionState.selectedPeople.map((person) => ({
          entityId: person.personId,
        })),
      };

      await saveEntityGroup({ data: entityGroupRequest });
      notifications.show({
        title: 'Success',
        message: 'Group created successfully',
        color: 'green',
      });
      form.reset();
      onClose();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to create group',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Create New Group" size="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <Text size="sm" c="dimmed">
            Create a new group with {selectionState.selectedPeople.length}{' '}
            selected people
          </Text>
          <TextInput
            label="Group Name"
            placeholder="Enter group name"
            required
            {...form.getInputProps('name')}
          />
          <Button type="submit" loading={loading} fullWidth>
            Create Group
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default AddToGroupModal;
