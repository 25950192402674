import { ChapterProgressResponse } from '@btrway/api-courseware';
import { useSetAtom } from 'jotai';
import { chapterProgressAtomFamily } from '../atoms/chapterProgressAtomFamily';

export const useUpdateChapterProgress = (chapterKey: string) => {
  const setChapterProgress = useSetAtom(chapterProgressAtomFamily(chapterKey));

  const updateChapterProgress = (updates: Partial<ChapterProgressResponse>) => {
    setChapterProgress(updates);
  };

  return { updateChapterProgress };
};
