import {
  StepConfig,
  StepInputProperties,
  StepTypeEnum,
  TaskConfig,
  TaskListTaskProperties,
  TaskTypeEnum,
  WorkflowSelection,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';
import { useStepTypes, useTaskTypes } from '@btrway/workflow-manager';

export const useStepConfigFactory = () => {
  const { getTaskType } = useTaskTypes();
  const { getStepType } = useStepTypes();

  const createStepConfig = (
    type: 'step' | 'task',
    code: StepTypeEnum | TaskTypeEnum
  ): StepConfig => {
    const stepKey = uuid();
    let title: string;
    let stepTypeCode: StepTypeEnum;
    let inputProperties: StepInputProperties | undefined;

    console.log('Creating Step Config for type ' + type + ' and code: ', code);
    if (type === 'step') {
      const stepType = getStepType(code as StepTypeEnum);
      title = stepType ? stepType.name : 'New Step';
      stepTypeCode = code as StepTypeEnum;
    } else {
      const taskType = getTaskType(code as TaskTypeEnum);
      title = taskType ? taskType.name : 'New Task';
      stepTypeCode = StepTypeEnum.assignTask;
    }

    const baseConfig: StepConfig = {
      stepKey,
      stepTypeCode,
      title,
    };

    if (type === 'task') {
      return createStepTaskConfig(baseConfig, code as TaskTypeEnum);
    }

    return baseConfig;
  };

  const createStepTaskConfig = (
    baseConfig: StepConfig,
    taskType: TaskTypeEnum
  ): StepConfig => {
    const taskTypeDTO = getTaskType(taskType);
    let taskConfig: TaskConfig = {
      taskKey: uuid(),
      taskType: taskType,
      metadata: { title: taskTypeDTO ? taskTypeDTO.name : 'New Task' },
    };

    switch (taskType) {
      case TaskTypeEnum.completeTaskList:
        return createCompleteTaskListConfig(baseConfig, taskConfig);

      //   return { ...baseConfig, taskConfig };
      default:
        return { ...baseConfig, taskConfig };
    }
  };

  const createTaskConfig = (
    taskType: TaskTypeEnum,
    title?: string,
    workflowType?: WorkflowTypeEnum
  ): TaskConfig => {
    const taskTypeDTO = getTaskType(taskType);
    let taskConfig: TaskConfig = {
      taskKey: uuid(),
      taskType: taskType,
      metadata: {
        title: title ? title : taskTypeDTO ? taskTypeDTO.name : 'New Task',
      },
    };

    return taskConfig;
  };

  const createCompleteTaskListConfig = (
    baseConfig: StepConfig,
    taskConfig: TaskConfig
  ): StepConfig => {
    const newKey = uuid();

    // const newWorkflow: CommonWorkflow = {
    //   workflowKey: newKey,
    //   name: 'New Task List',
    //   workflowType: WorkflowTypeEnum.taskList,
    //   published: false,
    //   enabled: true,
    //   organizationId: currentOrganization.id,
    //   sourceType: workflowSourceType || 'definition',
    // };
    // const newWorkflowConfig: WorkflowConfig = {
    //   tasks: [],
    // };

    const workflowSelection: WorkflowSelection = {
      workflowDefinitionKey: { value: newKey },
    };
    const taskListProperties: TaskListTaskProperties = {
      workflowSelection,
    };

    // const assignTaskInputPropertyConfig: AssignTaskInputProperties = {
    //   taskAssignmentConfig: {
    //     assignmentType: TaskAssignmentTypeEnum.primaryWorkflowPerson,
    //   },
    // };

    // Add the new WorkflowDefinitionRequest to the workflowDefinitionsAtom
    // setWorkflows((prev) => ({
    //   ...prev,
    //   [newKey]: newWorkflow,
    // }));

    // // Add the new WorkflowConfig to the workflowConfigurationsAtom
    // setWorkflowConfigurations((prev) => ({
    //   ...prev,
    //   [newKey]: newWorkflowConfig,
    // }));

    return {
      ...baseConfig,
      taskConfig: {
        ...taskConfig,
        taskProperties: taskListProperties,
      },
      // basePropertyConfig: assignTaskInputPropertyConfig,
    };
  };

  return {
    createStepConfig,
    createTaskConfig,
  };
};
