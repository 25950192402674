// hooks/useChapterBuilder.ts
import { useStore } from 'jotai';
import { chapterAtomFamily } from '../atoms/chapterAtomFamily';
import { chaptersAtom } from '../atoms/chaptersAtom';

export const useChapterBuilder = () => {
  const store = useStore();

  const getChapter = (key: string) => {
    return store.get(chapterAtomFamily(key));
  };

  const getChapters = () => {
    return store.get(chaptersAtom);
  };

  return {
    getChapters,
    getChapter,
  };
};
