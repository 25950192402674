import { StepConfig, StepTypeDTO, StepTypeEnum } from '@btrway/api-workflow';

export const updateStepsWithStepTypes = (
  steps: StepConfig[],
  getStepType: (stepTypeCode: StepTypeEnum) => StepTypeDTO | undefined
): StepConfig[] => {
  return steps.map((step) => ({
    ...step,
    stepType: getStepType(step.stepTypeCode),
  }));
};

export const removeStepTypeFromSteps = (steps: StepConfig[]): StepConfig[] => {
  return steps;
  // return tasks.map(({ stepType, ...rest }) => ({
  //   ...rest,
  // }));
};
