import { EntityTaskResponse } from '@btrway/api-workflow';
import { useTaskFetcher, useTaskManager } from '@btrway/task-manager';
import { TaskTypeIcon } from '@btrway/workflow-components';
import { Badge, Card, Group, Loader, Stack, Text } from '@mantine/core';
import React, { useEffect } from 'react';

interface PersonTaskListCardViewProps {
  personId: number;
}

const PersonTaskListCardView: React.FC<PersonTaskListCardViewProps> = ({
  personId,
}) => {
  const { fetchTaskListTasks } = useTaskFetcher();
  const { getTaskListTasksByPerson } = useTaskManager();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    fetchTaskListTasks(personId, {
      onSuccess: () => setIsLoading(false),
      onError: (err) => {
        setError(err as Error);
        setIsLoading(false);
      },
    });
  }, [personId, fetchTaskListTasks]);

  if (isLoading) {
    return (
      <Group justify="center" p="xl">
        <Loader size="lg" />
      </Group>
    );
  }

  if (error) {
    return (
      <Card withBorder p="md">
        <Text c="red">Error loading tasks: {error.toString()}</Text>
      </Card>
    );
  }

  const tasks = getTaskListTasksByPerson(personId);

  const getStatusBadge = (task: EntityTaskResponse) => {
    if (task.completed) {
      return <Badge color="green">Completed</Badge>;
    }

    // Check if task is overdue based on due date
    if (task.dueAt) {
      const dueDate = new Date(task.dueAt);
      const now = new Date();
      if (dueDate < now) {
        const daysOverdue = Math.ceil(
          (now.getTime() - dueDate.getTime()) / (1000 * 60 * 60 * 24)
        );
        return <Badge color="red">Overdue: {daysOverdue} days</Badge>;
      }
    }

    return <Badge color="blue">In Progress</Badge>;
  };

  return (
    <Stack gap="xs">
      {tasks.map((task: EntityTaskResponse) => (
        <Card
          key={
            task.taskInstanceId || `${task.taskKey}-${task.assignedEntityId}`
          }
          withBorder
          shadow="sm"
          p="md"
        >
          <Stack gap="xs">
            {/* Task Title, Type Icon, and Status */}
            <Group justify="space-between" wrap="nowrap">
              <Group gap="sm">
                {task.taskType && <TaskTypeIcon iconName={task.taskType} />}
                <Text size="lg" fw={500}>
                  {task.taskTitle}
                </Text>
              </Group>
              <Group gap="xs">
                {getStatusBadge(task)}
                {/* Removed 'New' badge since viewed status isn't in EntityTaskResponse */}
              </Group>
            </Group>

            {/* Task Details */}
            <Group gap="xs">
              {task.dueAt && (
                <Text size="sm">
                  Due: {new Date(task.dueAt).toLocaleDateString()}
                </Text>
              )}
              {task.rollupWorkflowName && (
                <Text size="sm">List: {task.rollupWorkflowName}</Text>
              )}
              {task.workgroupId && (
                <Text size="sm">Workgroup ID: {task.workgroupId}</Text>
              )}
              {task.rollupWorkflowKey && (
                <Text size="sm">Workflow: {task.rollupWorkflowKey}</Text>
              )}
            </Group>

            {/* Additional task details specific to EntityTaskResponse */}
            <Group gap="xs">
              {task.assignedAt && (
                <Text size="sm">
                  Assigned: {new Date(task.assignedAt).toLocaleDateString()}
                </Text>
              )}
              {task.taskListConfig?.startDate?.offsetDays !== undefined && (
                <Badge variant="dot" color="blue">
                  Start Offset: {task.taskListConfig.startDate.offsetDays} days
                </Badge>
              )}
              {task.taskListConfig?.endDate?.offsetDays !== undefined && (
                <Badge variant="dot" color="grape">
                  Due Offset: {task.taskListConfig.endDate.offsetDays} days
                </Badge>
              )}
            </Group>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default PersonTaskListCardView;
