import {
  CertificationConfig,
  DataTypeEnum,
  PersonDataProperties,
  PropertyReference,
  TaskDataProperties,
  TriggerConfig,
  TriggerSetConfig,
} from '@btrway/api-workflow';
import { TriggerDetail, TriggerSetsView } from '@btrway/trigger-builder';
import { uuid } from '@btrway/utils';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import { Stack } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';

interface DataPropertiesTriggerBuilderProps {
  config: CertificationConfig;
  onConfigChange: (config: CertificationConfig) => void;
}

// Type info for the properties we want to expose
const PERSON_DATA_PROPERTIES: Record<keyof PersonDataProperties, DataTypeEnum> =
  {
    email: DataTypeEnum.email,
    firstName: DataTypeEnum.text,
    lastName: DataTypeEnum.text,
    mobilePhone: DataTypeEnum.phone,
    middleName: DataTypeEnum.text,
    age: DataTypeEnum.number,
  };

const TASK_DATA_PROPERTIES: Record<keyof TaskDataProperties, DataTypeEnum> = {
  tasksCompleted: DataTypeEnum.number,
};

// Helper to create property references from property definitions
const createPropertyReferences = (
  propertyDefinitions: Record<string, DataTypeEnum>
): PropertyReference[] => {
  return Object.entries(propertyDefinitions).map(([key, dataType]) => {
    // Format the label from the property key
    const label = key
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase());

    return {
      dataType,
      label,
      propertyKey: key,
    };
  });
};

export const DataPropertiesTriggerBuilder: React.FC<
  DataPropertiesTriggerBuilderProps
> = ({ config, onConfigChange }) => {
  console.log('DataPropertiesTriggerBuilder render with config:', config);

  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  // Get available properties based on certification type
  const availableProperties = useMemo(() => {
    console.log(
      'Computing availableProperties for type:',
      config.certificationType
    );

    // Use the appropriate property definitions based on certification type
    const propertyDefinitions =
      config.certificationType === 'personData'
        ? PERSON_DATA_PROPERTIES
        : TASK_DATA_PROPERTIES;

    const properties = createPropertyReferences(propertyDefinitions);
    console.log('Available properties computed:', properties);
    return properties;
  }, [config.certificationType]);

  const [triggerSets, setTriggerSets] = useState<TriggerSetConfig[]>(
    config.triggerSets || []
  );

  useEffect(() => {
    console.log('TriggerSets changed in config:', config.triggerSets);
    setTriggerSets(config.triggerSets || []);
  }, [config.triggerSets]);

  const handleTriggerSetsChange = (updatedTriggerSets: TriggerSetConfig[]) => {
    console.log('Trigger sets changed:', updatedTriggerSets);
    setTriggerSets(updatedTriggerSets);
    onConfigChange({
      ...config,
      triggerSets: updatedTriggerSets,
    });
  };

  const handleTriggerSelect = (
    triggerSetKey: string | null,
    trigger: TriggerConfig | null
  ) => {
    console.log('Trigger selected:', {
      triggerSetKey,
      trigger,
      availableProperties,
    });

    setRightSectionContent(
      <TriggerDetail
        properties={availableProperties}
        initialConfig={trigger || undefined}
        triggerSetKey={triggerSetKey}
        onSave={handleSaveTrigger}
        onCancel={clearRightSectionContent}
      />
    );
  };

  const handleSaveTrigger = (
    triggerSetKey: string | null,
    newTrigger: TriggerConfig
  ) => {
    console.log('Saving trigger:', { triggerSetKey, newTrigger });

    let updatedTriggerSets: TriggerSetConfig[];

    if (triggerSetKey) {
      updatedTriggerSets = triggerSets.map((ts) => {
        if (ts.triggerSetKey === triggerSetKey) {
          const updatedTriggers = ts.triggers ? [...ts.triggers] : [];
          const existingTriggerIndex = updatedTriggers.findIndex(
            (t) => t.triggerKey === newTrigger.triggerKey
          );

          if (existingTriggerIndex !== -1) {
            updatedTriggers[existingTriggerIndex] = newTrigger;
          } else {
            updatedTriggers.push(newTrigger);
          }

          return {
            ...ts,
            triggers: updatedTriggers,
          };
        }
        return ts;
      });
    } else {
      const newTriggerSet: TriggerSetConfig = {
        triggerSetKey: uuid(),
        triggers: [newTrigger],
      };
      updatedTriggerSets = [...triggerSets, newTriggerSet];
    }

    console.log('Updated trigger sets:', updatedTriggerSets);

    setTriggerSets(updatedTriggerSets);
    onConfigChange({
      ...config,
      triggerSets: updatedTriggerSets,
    });
    clearRightSectionContent();
  };

  console.log('Rendering TriggerSetsView with:', {
    triggerSets,
    availableProperties,
  });

  return (
    <Stack>
      <TriggerSetsView
        triggerSets={triggerSets}
        properties={availableProperties}
        onTriggerSetsChange={handleTriggerSetsChange}
        onTriggerSelect={handleTriggerSelect}
      />
    </Stack>
  );
};
