import {
  CertificationConfig,
  EventConfig,
  StepConfig,
  TaskConfig,
} from '@btrway/api-workflow';
import { ToolboxMode } from '@btrway/workflow-builder-toolbox';
import {
  CommonWorkflow,
  WorkflowService,
} from '@btrway/workflow-configuration-manager';
import { useContext } from 'react';
import { WorkflowBuilderContext } from '../providers/WorkflowBuilderStateProvider';
import { DisplayConfig } from '../types/display';
import { Screen } from '../types/screen';

export const useWorkflowBuilderState = () => {
  const context = useContext(WorkflowBuilderContext);

  if (!context) {
    throw new Error(
      'useWorkflowBuilderState must be used within WorkflowBuilderStateProvider'
    );
  }

  const { state, dispatch } = context;

  const openInitialScreen = (
    screen: Screen,
    config: DisplayConfig = { mode: 'panel', position: 'left' },
    nestedWorkflowService?: WorkflowService | null,
    nestedWorkflow?: CommonWorkflow | null
  ) => {
    dispatch({
      type: 'SET_NESTED_WORKFLOW',
      workflow: nestedWorkflowService ? nestedWorkflow || null : null,
    });
    dispatch({
      type: 'SET_NESTED_WORKFLOW_SERVICE',
      service: nestedWorkflowService || null,
    });
    dispatch({
      type: 'OPEN_SCREEN',
      screen,
      config,
    });
  };

  const openScreen = (
    screen: Screen,
    config: DisplayConfig = { mode: 'panel', position: 'left' }
  ) => {
    dispatch({
      type: 'OPEN_SCREEN',
      screen,
      config,
    });
  };

  const openToolbox = (
    targetWorkflow: CommonWorkflow,
    mode: ToolboxMode,
    nestedWorkflowService?: WorkflowService
  ) => {
    dispatch({
      type: 'SET_NESTED_WORKFLOW',
      workflow: nestedWorkflowService ? targetWorkflow : null,
    });
    dispatch({
      type: 'SET_NESTED_WORKFLOW_SERVICE',
      service: nestedWorkflowService || null,
    });
    dispatch({
      type: 'OPEN_SCREEN',
      screen: {
        type: 'TOOLBOX',
        data: {
          mode,
          workflowType: targetWorkflow?.workflowType,
        },
      },
      config: {
        mode: 'panel',
        position: 'left',
        width: 400,
        modalProps: {
          title: `Add ${mode.charAt(0).toUpperCase() + mode.slice(1)}`,
        },
      },
    });
  };

  const closeScreen = () => {
    dispatch({ type: 'CLOSE_SCREEN' });
  };

  const updateTaskConfig = (config: TaskConfig) => {
    dispatch({ type: 'UPDATE_TASK_CONFIG', config });
  };

  const updateCertificationConfig = (config: CertificationConfig) => {
    dispatch({ type: 'UPDATE_CERTIFICATION_CONFIG', config });
  };

  const updateStepConfig = (config: StepConfig) => {
    dispatch({ type: 'UPDATE_STEP_CONFIG', config });
  };

  const updateEventConfig = (config: EventConfig) => {
    dispatch({ type: 'UPDATE_EVENT_CONFIG', config });
  };

  const setNestedWorkflow = (workflow: CommonWorkflow | null) => {
    dispatch({ type: 'SET_NESTED_WORKFLOW', workflow });
  };

  const setNestedWorkflowService = (service: WorkflowService | null) => {
    dispatch({ type: 'SET_NESTED_WORKFLOW_SERVICE', service });
  };

  const reset = () => {
    dispatch({ type: 'RESET' });
  };

  return {
    state,
    openScreen,
    openInitialScreen,
    openToolbox,
    closeScreen,
    updateTaskConfig,
    updateCertificationConfig,
    updateStepConfig,
    updateEventConfig,
    setNestedWorkflow,
    setNestedWorkflowService,
    reset,
  };
};
