import { Badge, Card, Group, Progress, Text } from '@mantine/core';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { useDashboardStats } from '../../hooks/useDashboardStats';
import styles from './CertificationsTopSection.module.css';

const RequirementsCard: React.FC<{ count: number }> = ({ count }) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {count}
      </Badge>
      <Text fz="lg" fw={600} c="white">
        Requirements Left
      </Text>
    </Group>
  </Card>
);

const ProgressCard: React.FC<{ progress: number }> = ({ progress }) => (
  <Card className={styles.card} p="md" style={{ flex: '2' }} radius="lg">
    <Group justify="center" align="center" wrap="nowrap" w="100%">
      <Progress
        value={progress}
        color="blue"
        size="xl"
        radius="xl"
        style={{ flex: '1 1 auto' }}
      />
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {progress.toFixed(1)}%
      </Badge>
    </Group>
  </Card>
);

const DueDateCard: React.FC<{ dueDate: string | undefined }> = ({
  dueDate,
}) => (
  <Card className={styles.card} p="md" style={{ flex: '1' }} radius="lg">
    <Group gap="xs" wrap="nowrap" justify="center">
      <Text fz="lg" fw={600} c="white">
        Due in
      </Text>
      <Badge size="xl" variant="outline" color="#25274D" bg="white">
        {dueDate ? formatDistanceToNow(new Date(dueDate)) : 'No due date'}
      </Badge>
    </Group>
  </Card>
);

export const CertificationsTopSection: React.FC = () => {
  const { certificationStats } = useDashboardStats();

  return (
    <Group gap="md" style={{ width: '100%' }}>
      <RequirementsCard count={certificationStats.remainingCount} />
      <ProgressCard progress={certificationStats.progress} />
      <DueDateCard dueDate={certificationStats.nextDueDate} />
    </Group>
  );
};

export default CertificationsTopSection;
