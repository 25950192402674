import { EventConfig, WorkflowTypeEnum } from '@btrway/api-workflow';
import { CommonWorkflow } from '@btrway/workflow-configuration-manager';
import { ActionIcon, Box, Card, Center, Stack, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useEventEdit } from '../../hooks/useEventEdit';
import { EventConfigCard } from '../EventConfigCard/EventConfigCard';

interface EventsPanelProps {
  workflowKey: string;
  workflow: CommonWorkflow;
  workflowType: WorkflowTypeEnum;
  entityType?: string;
  events: EventConfig[];
  onDelete?: (eventKey: string) => void;
}

export const EventsPanel: React.FC<EventsPanelProps> = ({
  workflowKey,
  workflow,
  workflowType,
  entityType,
  events = [],
  onDelete,
}) => {
  const [localEvents, setLocalEvents] = useState<EventConfig[]>(events);

  const handleEventUpdate = async (
    updatedEvent: EventConfig,
    isNew: boolean
  ) => {
    if (isNew) {
      setLocalEvents((prev) => [...prev, updatedEvent]);
    } else {
      setLocalEvents((prev) =>
        prev.map((event) =>
          event.eventKey === updatedEvent.eventKey ? updatedEvent : event
        )
      );
    }
  };

  const { showEventEditor, showEventsToolbox } = useEventEdit({
    workflowKey,
    workflowType,
    entityType,
    onEventUpdated: handleEventUpdate,
    onEventDeleted: (eventKey) => {
      setLocalEvents((prev) =>
        prev.filter((event) => event.eventKey !== eventKey)
      );
      onDelete?.(eventKey);
    },
  });

  const handleEventClick = (event: EventConfig) => {
    showEventEditor(event);
  };

  return (
    <Card p="md" withBorder radius="md" mb="md" w="100%" bg="gray.3">
      <Text fz="lg" fw={600} mb="sm">
        When this happens...
      </Text>
      <Stack gap="xs">
        {localEvents.length > 0 ? (
          localEvents.map((event) => (
            <EventConfigCard
              key={event.eventKey}
              event={event}
              workflow={workflow}
              onClick={() => handleEventClick(event)}
              isSelected={false}
            />
          ))
        ) : (
          <Box
            w="100%"
            h={160}
            style={(theme) => ({
              border: `2px dashed ${theme.colors.gray[5]}`,
              borderRadius: theme.radius.md,
              padding: theme.spacing.xl,
              textAlign: 'center',
            })}
          >
            <Center h="100%" style={{ flexDirection: 'column', gap: '1rem' }}>
              <Text fz="xl" fw={700} c="dimmed">
                Select the Trigger
              </Text>
              <ActionIcon
                variant="light"
                color="blue"
                size="xl"
                radius="xl"
                onClick={showEventsToolbox}
              >
                <IconPlus size={24} />
              </ActionIcon>
            </Center>
          </Box>
        )}
      </Stack>
    </Card>
  );
};
