import { useUserRoles } from '@btrway/security-manager';
import { Card, Select, Stack, Switch, Text } from '@mantine/core';

interface RegistrantSettingsCardProps {
  registrantRoleId?: number;
  onRegistrantRoleChange: (value: number | undefined) => void;
  dependentRoleId?: number;
  onDependentRoleChange: (value: number | undefined) => void;
  allowChildren: boolean;
  onAllowChildrenChange: (value: boolean) => void;
  allowOtherRelationships: boolean;
  onAllowOtherRelationshipsChange: (value: boolean) => void;
}

export function RegistrantSettingsCard({
  registrantRoleId,
  onRegistrantRoleChange,
  dependentRoleId,
  onDependentRoleChange,
  allowChildren,
  onAllowChildrenChange,
  allowOtherRelationships,
  onAllowOtherRelationshipsChange,
}: RegistrantSettingsCardProps) {
  const { userRoles, isLoading: rolesLoading } = useUserRoles();

  const roleOptions =
    userRoles?.map((role) => ({
      value: role.id.toString(),
      label: role.name,
    })) || [];

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack gap="md">
        <Text size="lg" fw={500}>
          Registrant Settings
        </Text>

        <Select
          label="Registrant Created as Role:"
          data={roleOptions}
          value={registrantRoleId?.toString()}
          onChange={(value) =>
            onRegistrantRoleChange(value ? parseInt(value) : undefined)
          }
          placeholder="Select a role"
          disabled={rolesLoading}
          required
        />

        <Switch
          label="Allow Registrant to Add Children"
          checked={allowChildren}
          onChange={(e) => onAllowChildrenChange(e.currentTarget.checked)}
        />

        {allowChildren && (
          <Select
            label="Child/Dependent Created as Role:"
            data={roleOptions}
            value={dependentRoleId?.toString()}
            onChange={(value) =>
              onDependentRoleChange(value ? parseInt(value) : undefined)
            }
            placeholder="Select a role"
            disabled={rolesLoading}
          />
        )}

        <Switch
          label="Allow Registrant to Add Other Related People"
          checked={allowOtherRelationships}
          onChange={(e) =>
            onAllowOtherRelationshipsChange(e.currentTarget.checked)
          }
        />
      </Stack>
    </Card>
  );
}
