import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { fieldValueMappingsAtomFamily } from '../atoms/fieldValueMappingsAtomFamily';
import { ImportedPerson } from '../types';

export const useFieldValueMapping = (fieldKey: keyof ImportedPerson) => {
  const [fieldValueMapping, setFieldValueMapping] = useAtom(
    fieldValueMappingsAtomFamily(fieldKey)
  );

  const updateMapping = useCallback(
    (sourceValue: string, targetValue: string | number | null) => {
      setFieldValueMapping({
        targetField: fieldKey,
        valueMappings:
          fieldValueMapping?.valueMappings.map((mapping) =>
            mapping.sourceValue === sourceValue
              ? { ...mapping, targetValue }
              : mapping
          ) || [],
      });
    },
    [fieldKey, fieldValueMapping, setFieldValueMapping]
  );

  const resetMapping = useCallback(
    (sourceValue: string) => {
      updateMapping(sourceValue, null);
    },
    [fieldKey, fieldValueMapping, setFieldValueMapping]
  );

  return {
    mappings: fieldValueMapping?.valueMappings || [],
    updateMapping,
    resetMapping,
  };
};
