import { StyledModal } from '@btrway/styled-modal';
import React from 'react';
import { DisplayConfig } from '../../types/display';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  config: DisplayConfig['modalProps'];
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  config,
}) => {
  return (
    <StyledModal
      opened={isOpen}
      onClose={onClose}
      variant={config?.variant || 'xl'}
      title={config?.title}
    >
      {children}
    </StyledModal>
  );
};
