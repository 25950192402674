import { useTaskManager } from '@btrway/task-manager';
import { Button, Card, Group, Text } from '@mantine/core';
import { IconCircleCheck, IconEye } from '@tabler/icons-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';
import TaskAssignmentStartButton from '../TaskAssignmentStartButton/TaskAssignmentStartButton';
import classes from './TaskAssignmentsBanner.module.css';

export const TaskAssignmentsBanner: React.FC = () => {
  const { assignedTasks } = useTaskManager();
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const initialTaskCountRef = useRef(0);

  const isLoading = false;

  useEffect(() => {
    if (!isLoading && assignedTasks.length > 0) {
      initialTaskCountRef.current = assignedTasks.length;
    }
  }, [isLoading, assignedTasks]);

  const handleReviewClick = () => {
    navigate('/app/tasks');
  };

  const handleTaskCompletion = useCallback(() => {
    if (initialTaskCountRef.current >= 5 && assignedTasks.length === 0) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [assignedTasks.length]);

  if (isLoading) {
    return (
      <Card
        padding="md"
        radius="lg"
        className={`${classes.banner} ${classes.loadingBanner}`}
      >
        <Text className={classes.text}>Loading...</Text>
      </Card>
    );
  }

  const taskAssignmentCount = assignedTasks.length;

  return (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={200}
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 10000 }}
        />
      )}
      <Card
        padding="md"
        radius="lg"
        className={`${classes.banner} ${
          taskAssignmentCount > 0 ? classes.activeBanner : classes.emptyBanner
        }`}
      >
        <Group justify="space-between" align="center" h="100%">
          <Group>
            {taskAssignmentCount === 0 ? (
              <>
                <Text className={classes.text}>
                  You have no To-Dos awaiting completion
                </Text>
                <IconCircleCheck size={24} className={classes.icon} />
              </>
            ) : (
              <Text className={classes.text}>
                You have{' '}
                <Text span className={classes.boldText}>
                  {taskAssignmentCount}
                </Text>{' '}
                To-Dos awaiting your attention
              </Text>
            )}
          </Group>
          {taskAssignmentCount > 0 && (
            <Group>
              <Button
                leftSection={<IconEye size={18} />}
                onClick={handleReviewClick}
                // color="light"
                bg="white"
                variant="outline"
              >
                Review To-Do List
              </Button>
              <TaskAssignmentStartButton
                onTaskCompletion={handleTaskCompletion}
                initialTaskCount={initialTaskCountRef.current}
              />
            </Group>
          )}
        </Group>
      </Card>
    </>
  );
};
