import { useGetRelationshipTypes } from '@btrway/api-core';
import { Box, Button, Card, Group, Stack, Text, Title } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import React from 'react';
import { useRegistrationFlow } from '../../hooks/useRegistrationFlow';
import { useRegistrationSubmission } from '../../hooks/useRegistrationSubmission';

const SummaryInfo: React.FC = () => {
  const { registration, getRelationship } = useRegistrationSubmission();
  const { moveToStep, steps } = useRegistrationFlow();
  const { data: relationshipTypes } = useGetRelationshipTypes();

  const registrant = registration.persons.find(
    (person) => person.personKey === registration.registrantPersonKey
  );

  const children = registration.persons.filter((person) => person.isDependent);

  const otherRelatedPeople = registration.persons.filter(
    (person) =>
      !person.isDependent &&
      person.personKey !== registration.registrantPersonKey
  );

  // Helper function to find step index by type
  const getStepIndex = (type: string) => {
    return steps.findIndex((step) => step.type === type);
  };

  return (
    <Stack gap="md">
      <Card withBorder radius="sm" padding="sm" shadow="none" bg="yellow.1">
        <Text size="lg" fw={500}>
          Please review registration information before submitting.
        </Text>
      </Card>
      <Box>
        <Group justify="space-between" align="center">
          <Title order={4}>Registrant Information</Title>
          <Button
            variant="subtle"
            size="sm"
            leftSection={<IconEdit size={16} />}
            onClick={() => moveToStep(getStepIndex('registrant'))}
          >
            Edit
          </Button>
        </Group>
        <Card mt="xs" withBorder>
          <Text fz="md" fw={600}>
            {registrant?.firstName} {registrant?.lastName}
          </Text>
          <Text fz="sm">Email: {registrant?.email}</Text>
          <Text fz="sm">Mobile Phone: {registrant?.mobilePhone}</Text>
          <Text fz="sm">Person ID: {registrant?.personId}</Text>
        </Card>
      </Box>

      {children.length > 0 && (
        <Box>
          <Group justify="space-between" align="center">
            <Title order={4}>Children / Dependents</Title>
            <Button
              variant="subtle"
              size="sm"
              leftSection={<IconEdit size={16} />}
              onClick={() => moveToStep(getStepIndex('children'))}
            >
              Edit
            </Button>
          </Group>
          {children.map((child) => (
            <Card key={child.personKey} mt="xs" withBorder>
              <Text fz="md" fw={600}>
                {child.firstName} {child.lastName}
              </Text>
              <Text fz="sm">Email: {child.email}</Text>
              <Text fz="sm">Mobile Phone: {child.mobilePhone}</Text>
            </Card>
          ))}
        </Box>
      )}

      {otherRelatedPeople.length > 0 && (
        <Box>
          <Group justify="space-between" align="center">
            <Title order={4}>Other Related People</Title>
            <Button
              variant="subtle"
              size="sm"
              leftSection={<IconEdit size={16} />}
              onClick={() => moveToStep(getStepIndex('other'))}
            >
              Edit
            </Button>
          </Group>
          {otherRelatedPeople.map((person) => (
            <Card key={person.personKey} mt="xs" withBorder>
              <Text fz="md" fw={600}>
                {person.firstName} {person.lastName}
              </Text>
              <Text fz="sm">Email: {person.email}</Text>
              <Text fz="sm">Mobile Phone: {person.mobilePhone}</Text>
              {children.map((child) => {
                const relationship = getRelationship(
                  person.personKey,
                  child.personKey
                );
                const relationshipType = relationshipTypes?.find(
                  (type) => type.id === relationship?.relationshipTypeId
                );

                return relationship && relationshipType ? (
                  <Text fz="sm" key={child.personKey}>
                    {relationshipType.name} of {child.firstName}
                  </Text>
                ) : null;
              })}
            </Card>
          ))}
        </Box>
      )}
    </Stack>
  );
};

export default SummaryInfo;
