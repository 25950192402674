import { HelpRepositoryEnum, HelpResourceResponse } from '@btrway/api-help';
import { getPresignedUrl, StorageUsageEnum } from '@btrway/api-storage';
import { ContentRenderer } from '@btrway/content-editor';
import { Box, Button, Stack, Text } from '@mantine/core';
import MuxPlayer from '@mux/mux-player-react';
import { IconDownload } from '@tabler/icons-react';
import React from 'react';

interface HelpArticleRendererProps {
  helpResource: HelpResourceResponse;
}

const S3Viewer: React.FC<{ fileKey: string }> = ({ fileKey }) => {
  let presignedUrl: string = '';

  getPresignedUrl(StorageUsageEnum.helparticles, { storageKey: fileKey }).then(
    (data) => {
      presignedUrl = data as string;
    }
  );

  // Get the file name from the fileKey
  const fileName = fileKey.split('/').pop();

  console.log(presignedUrl);

  const handleDownload = () => {
    window.open(presignedUrl, '_blank');
  };

  return (
    <Box my="md">
      <Button
        onClick={handleDownload}
        leftSection={<IconDownload size={16} />}
        variant="light"
      >
        Download {fileName}
      </Button>
    </Box>
  );
};

const HelpArticleRenderer: React.FC<HelpArticleRendererProps> = ({
  helpResource,
}) => {
  const { content, repository } = helpResource;

  if (!content) {
    return <Text>No content available</Text>;
  }

  // Convert the content object to a string for ContentRenderer
  const stringifiedContent = content.content
    ? JSON.stringify(content.content)
    : '';

  console.log('assetIdentifier', content.assetIdentifier);
  return (
    <Stack>
      {/* Video Content for clientWhy */}
      {repository === HelpRepositoryEnum.clientWhy &&
        content.assetIdentifier && (
          <Box my="md">
            <MuxPlayer
              streamType="on-demand"
              playbackId={content.assetIdentifier}
              metadata={{
                video_title: helpResource.metadata?.title || 'Video Content',
              }}
              style={{
                width: '100%',
                maxWidth: '100%',
                height: 'auto',
                aspectRatio: '16/9',
              }}
            />
          </Box>
        )}

      {/* File Content for clientFile */}
      {repository === HelpRepositoryEnum.clientFiles && content.fileKey && (
        <S3Viewer fileKey={content.fileKey} />
      )}

      {/* Main Content */}
      {content.content && (
        <ContentRenderer
          content={stringifiedContent}
          title={helpResource.metadata?.title}
        />
      )}
    </Stack>
  );
};

export default HelpArticleRenderer;
