import { WorkflowDefinitionResponse } from '@btrway/api-workflow'; // Adjust import path as needed
import { LoadingSpinner } from '@btrway/shared-components';
import { useWorkflowDefinitions as useWorkgroupDefinitionsManager } from '@btrway/workflow-manager';
import { createContext, useContext } from 'react';

interface WorkflowDefinitionsContextValue {
  workflowDefinitions: WorkflowDefinitionResponse[];
  isLoading: boolean;
}

const WorkflowDefinitionsContext = createContext<
  WorkflowDefinitionsContextValue | undefined
>(undefined);

export const useWorkflowDefinitions = () => {
  const context = useContext(WorkflowDefinitionsContext);
  if (!context) {
    throw new Error(
      'useWorkflowDefinitions must be used within a WorkflowDefinitionsProvider'
    );
  }
  return context;
};

interface WorkflowDefinitionsProviderProps {
  children: React.ReactNode;
}

export const WorkflowDefinitionsProvider: React.FC<
  WorkflowDefinitionsProviderProps
> = ({ children }) => {
  const { workflows: workflowDefinitions, isLoading } =
    useWorkgroupDefinitionsManager();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <WorkflowDefinitionsContext.Provider
      value={{
        workflowDefinitions,
        isLoading: false,
      }}
    >
      {children}
    </WorkflowDefinitionsContext.Provider>
  );
};
