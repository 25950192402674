import { EventRequest } from '@btrway/api-calendar';
import { Paper } from '@mantine/core';
import { useCallback, useState } from 'react';
import FacilityAvailabilityEdit from '../FacilityAvailabilityEdit/FacilityAvailabilityEdit';
import { FacilityAvailabilityView } from '../FacilityAvailabilityView/FacilityAvailabilityView';

interface FacilityAvailabilityItemProps {
  event: EventRequest;
  facilityId: number;
  workgroupId: number;
  userTimezone: string;
  onEdit: (event: EventRequest) => void;
  onDelete: (event: EventRequest) => void;
}

export const FacilityAvailabilityItem = ({
  event: initialEvent,
  facilityId,
  workgroupId,
  userTimezone,
  onEdit,
  onDelete,
}: FacilityAvailabilityItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<EventRequest>(initialEvent);

  const handleSave = useCallback(
    (updatedEvent: EventRequest) => {
      setCurrentEvent(updatedEvent);
      onEdit(updatedEvent);
      setIsEditing(false);
    },
    [currentEvent, onEdit]
  );

  const handleDelete = useCallback(() => {
    onDelete(currentEvent);
  }, [currentEvent, onDelete]);

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, [currentEvent]);

  const handleCancel = useCallback(() => {
    setIsEditing(false);
  }, [currentEvent]);

  return (
    <Paper withBorder p="sm">
      {isEditing ? (
        <FacilityAvailabilityEdit
          facilityId={facilityId}
          workgroupId={workgroupId}
          initialEvent={currentEvent}
          onSave={handleSave}
          onCancel={handleCancel}
          userTimezone={userTimezone}
        />
      ) : (
        <FacilityAvailabilityView
          event={currentEvent}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
    </Paper>
  );
};
