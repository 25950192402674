import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import { EDITOR_COLORS } from '../../styles/editorColors';

export const MergeTokenComponent: React.FC<NodeViewProps> = ({
  node,
  selected,
}) => {
  const attrs = node.attrs;
  const marks = node.marks || [];

  // Extract styles from textStyle marks
  const combinedStyle = marks.reduce((styles: Record<string, string>, mark) => {
    if (mark.type.name === 'textStyle') {
      if (mark.attrs.fontSize) {
        styles.fontSize = mark.attrs.fontSize;
      }
      if (mark.attrs.color) {
        styles.color = mark.attrs.color;
      }
    }
    return styles;
  }, {});

  return (
    <NodeViewWrapper
      as="span"
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        ...combinedStyle,
      }}
    >
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          padding: '0.20em 0.5em',
          margin: '0 0.1em',
          borderRadius: '4px',
          background: selected
            ? EDITOR_COLORS.selected.background
            : EDITOR_COLORS.default.background,
          border: '1px solid var(--mantine-color-gray-4)',
          cursor: 'pointer',
          userSelect: 'none',
          lineHeight: 1,
          transition: 'background-color 0.15s ease',
          ...combinedStyle,
        }}
      >
        {attrs.fieldName}
      </span>
    </NodeViewWrapper>
  );
};
