// src/hooks/useGlobalSearch.ts
import {
  DataTypeEnum,
  GlobalSearchRequest,
  GlobalSearchResponse,
  useSearchGlobal,
} from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';

export interface UseGlobalSearchOptions {
  debounceMs?: number;
  entityTypes?: DataTypeEnum[];
  initialResults?: GlobalSearchResponse[];
  showInitialResultsOnEmpty?: boolean;
}

export function useGlobalSearch({
  debounceMs = 300,
  entityTypes,
  initialResults = [],
  showInitialResultsOnEmpty = false,
}: UseGlobalSearchOptions = {}) {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebouncedValue(searchValue, debounceMs);
  const [searchResults, setSearchResults] =
    useState<GlobalSearchResponse[]>(initialResults);
  const { currentOrganization } = useAuthenticatedUser();
  const { mutate: search } = useSearchGlobal();

  useEffect(() => {
    if (debouncedSearchValue && currentOrganization) {
      const searchRequest: GlobalSearchRequest = {
        organizationId: currentOrganization.id,
        searchValue: debouncedSearchValue,
      };
      search(
        { data: searchRequest },
        {
          onSuccess: (data) => {
            const searchResponse = data as GlobalSearchResponse[];
            const filteredResults = entityTypes
              ? searchResponse.filter((result) =>
                  entityTypes.includes(result.entityType as DataTypeEnum)
                )
              : searchResponse;
            setSearchResults(filteredResults);
          },
        }
      );
    } else if (debouncedSearchValue === '' && showInitialResultsOnEmpty) {
      setSearchResults(initialResults);
    } else {
      setSearchResults([]);
    }
  }, [
    debouncedSearchValue,
    currentOrganization,
    search,
    entityTypes,
    showInitialResultsOnEmpty,
    initialResults,
  ]);

  return {
    searchValue,
    setSearchValue,
    searchResults,
    setSearchResults,
  };
}
