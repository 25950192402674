import {
  DataTypeEnum,
  WorkflowConfig,
  WorkflowDefinitionRequest,
  WorkflowTypeEnum,
} from '@btrway/api-workflow';
import { Button, Modal, Text, TextInput } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';

interface AddWorkflowDefinitionModalProps {
  workflowType: WorkflowTypeEnum;
  workflowTypeDescription: string;
  opened: boolean;
  onClose: () => void;
  onSave: (
    partialWorkflowDefinition: Partial<WorkflowDefinitionRequest>
  ) => void;
}

const AddWorkflowDefinitionModal = ({
  workflowType,
  workflowTypeDescription,
  opened,
  onClose,
  onSave,
}: AddWorkflowDefinitionModalProps) => {
  const [workflowName, setWorkflowName] = useState('');
  const [entityType, setEntityType] = useState<DataTypeEnum | null>(null);
  const [personDescription, setPersonDescription] = useState('');

  const handleCreateWorkflow = () => {
    if (workflowName) {
      const workflowConfig: WorkflowConfig = {
        events: [],
        steps: [],
        transitions: [],
      };

      onSave({
        name: workflowName,
        workflowType: workflowType,
        workflowConfiguration: workflowConfig,
        entityType: entityType ? entityType : undefined,
        entityName:
          entityType === DataTypeEnum.person ? personDescription : undefined,
      });
      resetState();
      onClose();
    }
  };

  const resetState = () => {
    setWorkflowName('');
    setEntityType(null);
    setPersonDescription('');
  };

  const entityTypeOptions = [
    { value: DataTypeEnum.person, label: 'Person' },
    { value: DataTypeEnum.calendarEvent, label: 'Event' },
    { value: DataTypeEnum.workgroup, label: 'Organizational Level' },
  ];

  return (
    <Modal
      opened={opened}
      onClose={() => {
        resetState();
        onClose();
      }}
      title={
        <Text fz="xl" fw={600}>
          Add {workflowTypeDescription}
        </Text>
      }
      size="80%"
      h={600}
    >
      <Modal.Body mih="calc(100vh *.6)">
        <TextInput
          label={`${workflowTypeDescription} Name`}
          placeholder={`Enter ${workflowTypeDescription} Name`}
          value={workflowName}
          onChange={(event) => setWorkflowName(event.currentTarget.value)}
          data-autofocus
          withAsterisk
          mb="md"
        />
        {/* {workflowType === WorkflowTypeEnum.automation && ( */}
        {/* <Select
          label="Workflow Type"
          placeholder="Select workflow type"
          data={entityTypeOptions}
          value={entityType}
          onChange={(value) => setEntityType(value as DataTypeEnum)}
          withAsterisk
          mb="md"
        /> */}
        {/* )} */}
      </Modal.Body>

      <Button
        onClick={handleCreateWorkflow}
        leftSection={<IconPlus size={16} />}
        disabled={
          !workflowName ||
          (workflowType === WorkflowTypeEnum.automation && !entityType)
        }
      >
        Save
      </Button>
    </Modal>
  );
};

export default AddWorkflowDefinitionModal;

// let eventConfig: EventConfig | undefined;

// switch (entityType) {
//   case DataTypeEnum.person:
//     eventConfig = {
//       eventKey: uuid(),
//       title: 'Person Added',
//       eventTypeCode: EventTypeEnum.personCreated,
//     };
//     break;
//   // case DataTypeEnum.calendarEvent:
//   //   eventConfig = {
//   //     eventKey: uuid(),
//   //     title: 'Event Created',
//   //     eventTypeCode: EventTypeEnum.calendarEventCreated,
//   //   };
//   //   break;
//   // case DataTypeEnum.workgroup:
//   //   eventConfig = {
//   //     eventKey: uuid(),
//   //     title: 'Organizational Level Added',
//   //     eventTypeCode: EventTypeEnum.workgroupCreated,
//   //   };
//   //   break;
// }
