import {
  PublicRegistrationFormResponse,
  RegistrationDTO,
  RegistrationInstanceRequest,
  RegistrationPersonDTO,
  RegistrationRelationshipDTO,
  useSaveRegistrationInstance,
} from '@btrway/api-core';
import { uuid } from '@btrway/utils';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { publicRegistrationFormAtom } from '../atoms/publicRegistrationFormAtom';
import {
  registrationAtom,
  registrationYearAtom,
  workgroupIdAtom,
} from '../atoms/registrationAtoms';

export const useRegistrationSubmission = () => {
  const [registration, setRegistration] = useAtom(registrationAtom);
  const [publicFormRegistration] =
    useAtom<PublicRegistrationFormResponse | null>(publicRegistrationFormAtom);
  const [workgroupId] = useAtom(workgroupIdAtom);
  const [registrationYear] = useAtom(registrationYearAtom);

  const { mutateAsync: saveRegistration } = useSaveRegistrationInstance();

  const updateRegistrant = useCallback(
    (field: string, value: string | number) => {
      setRegistration((prev) => ({
        ...prev,
        persons: prev.persons.map((person) =>
          person.personKey === prev.registrantPersonKey
            ? { ...person, [field]: value }
            : person
        ),
      }));
    },
    [setRegistration]
  );

  const addChild = useCallback(() => {
    if (!publicFormRegistration || workgroupId === null) return;

    const newChild: RegistrationPersonDTO = {
      firstName: '',
      lastName: '',
      email: '',
      mobilePhone: '',
      personKey: uuid(),
      isDependent: true,
      userRoleId:
        publicFormRegistration.registrationFormConfiguration
          ?.dependentUserRoleId || 0,
      workgroupIds: [],
    };

    setRegistration((prev) => ({
      ...prev,
      persons: [...prev.persons, newChild],
    }));
  }, [setRegistration, publicFormRegistration, workgroupId]);

  const updateChild = useCallback(
    (personKey: string, field: string, value: string) => {
      setRegistration((prev) => ({
        ...prev,
        persons: prev.persons.map((person) =>
          person.personKey === personKey
            ? { ...person, [field]: value }
            : person
        ),
      }));
    },
    [setRegistration]
  );

  const updatePersonWorkgroups = useCallback(
    (personKey: string, workgroupIds: number[]) => {
      setRegistration((prev) => ({
        ...prev,
        persons: prev.persons.map((person) =>
          person.personKey === personKey ? { ...person, workgroupIds } : person
        ),
      }));
    },
    [setRegistration]
  );

  const addOtherRelatedPerson = useCallback(() => {
    if (!publicFormRegistration || workgroupId === null) return;

    const newPerson: RegistrationPersonDTO = {
      firstName: '',
      lastName: '',
      email: '',
      mobilePhone: '',
      personKey: uuid(),
      isDependent: false,
      userRoleId:
        publicFormRegistration.registrationFormConfiguration
          ?.registrantUserRoleId || 0,
      workgroupIds: [workgroupId],
    };

    setRegistration((prev) => ({
      ...prev,
      persons: [...prev.persons, newPerson],
    }));
  }, [setRegistration, publicFormRegistration, workgroupId]);

  const updateOtherRelatedPerson = useCallback(
    (personKey: string, field: string, value: string | number) => {
      setRegistration((prev) => ({
        ...prev,
        persons: prev.persons.map((person) =>
          person.personKey === personKey
            ? { ...person, [field]: value }
            : person
        ),
      }));
    },
    [setRegistration]
  );

  const getRelationship = useCallback(
    (fromPersonKey: string, toPersonKey: string) => {
      return registration.relationships.find(
        (rel) =>
          rel.fromPersonKey === fromPersonKey && rel.toPersonKey === toPersonKey
      );
    },
    [registration.relationships]
  );

  const updateRelationship = useCallback(
    (
      fromPersonKey: string,
      toPersonKey: string,
      relationshipTypeId: number
    ) => {
      setRegistration((prev) => {
        const existingRelationshipIndex = prev.relationships.findIndex(
          (rel) =>
            rel.fromPersonKey === fromPersonKey &&
            rel.toPersonKey === toPersonKey
        );

        let updatedRelationships: RegistrationRelationshipDTO[];

        if (existingRelationshipIndex !== -1) {
          updatedRelationships = prev.relationships.map((rel, index) =>
            index === existingRelationshipIndex
              ? { ...rel, relationshipTypeId }
              : rel
          );
        } else {
          updatedRelationships = [
            ...prev.relationships,
            { fromPersonKey, toPersonKey, relationshipTypeId },
          ];
        }

        return {
          ...prev,
          relationships: updatedRelationships,
        };
      });
    },
    [setRegistration]
  );

  const removePerson = useCallback(
    (personKey: string) => {
      setRegistration((prev) => ({
        ...prev,
        persons: prev.persons.filter(
          (person) => person.personKey !== personKey
        ),
        relationships: prev.relationships.filter(
          (rel) =>
            rel.fromPersonKey !== personKey && rel.toPersonKey !== personKey
        ),
      }));
    },
    [setRegistration]
  );

  const handleSubmit = useCallback(async () => {
    if (!publicFormRegistration || workgroupId === null) return false;

    const registrationRequest: RegistrationInstanceRequest = {
      organizationId: publicFormRegistration.organizationId,
      registrationData: registration,
      registrationFormId: publicFormRegistration.id,
      workgroupId: workgroupId,
      year: registrationYear ? registrationYear : undefined,
    };

    try {
      await saveRegistration({ data: registrationRequest });
      return true; // Indicate successful submission
    } catch (error) {
      console.error('Error submitting registration:', error);
      return false; // Indicate failed submission
    }
  }, [
    registration,
    publicFormRegistration,
    workgroupId,
    registrationYear,
    saveRegistration,
  ]);

  const initializeRegistration = useCallback(() => {
    if (!publicFormRegistration || workgroupId === null) return;

    if (!registration.registrantPersonKey) {
      const registrant: RegistrationPersonDTO = {
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        personKey: uuid(),
        isDependent: false,
        userRoleId:
          publicFormRegistration.registrationFormConfiguration
            ?.registrantUserRoleId || 0,
        workgroupIds: [workgroupId],
      };

      const initialRegistration: RegistrationDTO = {
        persons: [registrant],
        registrantPersonKey: registrant.personKey,
        relationships: [],
      };

      if (
        publicFormRegistration.registrationFormConfiguration
          ?.allowChidrenCreation
      ) {
        const initialChild: RegistrationPersonDTO = {
          firstName: '',
          lastName: '',
          email: '',
          mobilePhone: '',
          personKey: uuid(),
          isDependent: true,
          userRoleId:
            publicFormRegistration.registrationFormConfiguration
              ?.dependentUserRoleId || 0,
          workgroupIds: [],
        };
        initialRegistration.persons.push(initialChild);
      }

      setRegistration(initialRegistration);
    }
  }, [
    registration.registrantPersonKey,
    publicFormRegistration,
    workgroupId,
    setRegistration,
  ]);

  return {
    registration,
    updateRegistrant,
    addChild,
    updateChild,
    updatePersonWorkgroups,
    addOtherRelatedPerson,
    updateOtherRelatedPerson,
    handleSubmit,
    initializeRegistration,
    getRelationship,
    updateRelationship,
    removePerson,
  };
};
