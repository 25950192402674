import {
  MessageRecipientResponse,
  useGetMessageById,
} from '@btrway/api-messaging';
import { formatDateTime } from '@btrway/utils';
import { Badge, Box, Group, Stack, Text } from '@mantine/core';
import { useState } from 'react';
import { MessageContentView } from '../MessageContentView/MessageContentView';
import { MessageRecipientList } from '../MessageRecipientList/MessageRecipientList';

export const MessageDetail: React.FC<{
  messageId: number;
}> = ({ messageId }) => {
  const { data: message, isLoading } = useGetMessageById(messageId);
  const [selectedRecipient, setSelectedRecipient] =
    useState<MessageRecipientResponse>();

  if (isLoading || !message) return <Text>Loading...</Text>;

  return (
    <Stack
      h="100%"
      gap="md"
      style={{
        flex: 1,
        minHeight: 0,
        overflow: 'hidden',
      }}
    >
      <Group>
        <Text size="xl" fw={500}>
          {message.messageSubject}
        </Text>
        <Badge>{message.status}</Badge>
        {message.processedAt && (
          <Text size="sm" c="dimmed">
            Processed: {formatDateTime(message.processedAt)}
          </Text>
        )}
      </Group>

      <Group
        align="stretch"
        wrap="nowrap"
        style={{
          flex: 1,
          minHeight: 0,
        }}
      >
        <Box
          w="20%"
          style={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MessageRecipientList
            messageId={messageId}
            selectedRecipient={selectedRecipient}
            onSelectRecipient={setSelectedRecipient}
          />
        </Box>
        <Box
          w="80%"
          style={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MessageContentView
            message={message}
            selectedRecipient={selectedRecipient}
          />
        </Box>
      </Group>
    </Stack>
  );
};
