import {
  CertificationInstanceGridResponse,
  CertificationTypeEnum,
  PersonDataProperties,
  TaskDataProperties,
} from '@btrway/api-workflow';
import { Divider, Group, Stack, Text } from '@mantine/core';

interface DataDisplayProps {
  requirement: CertificationInstanceGridResponse;
}

const renderTaskData = (data: TaskDataProperties) => (
  <Group gap="xs">
    <Text size="sm">Tasks Completed: {data.tasksCompleted}</Text>
  </Group>
);

const renderPersonData = (data: PersonDataProperties) => (
  <Stack gap="xs">
    {data.firstName && <Text size="sm">First Name: {data.firstName}</Text>}
    {data.lastName && <Text size="sm">Last Name: {data.lastName}</Text>}
    {data.middleName && <Text size="sm">Middle Name: {data.middleName}</Text>}
    {data.email && <Text size="sm">Email: {data.email}</Text>}
    {data.mobilePhone && (
      <Text size="sm">Mobile Phone: {data.mobilePhone}</Text>
    )}
    {data.age !== undefined && <Text size="sm">Age: {data.age}</Text>}
  </Stack>
);

export const DataDisplay = ({ requirement }: DataDisplayProps) => {
  if (!requirement.evaluatedData) return null;

  const renderData = () => {
    switch (requirement.certificationType) {
      case CertificationTypeEnum.taskData:
        return renderTaskData(requirement.evaluatedData as TaskDataProperties);
      case CertificationTypeEnum.personData:
        return renderPersonData(
          requirement.evaluatedData as PersonDataProperties
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      <Divider />
      <Group gap="md" align="flex-start">
        <Text fw={500} size="sm">
          Evaluated Data:
        </Text>
        {renderData()}
      </Group>
    </Stack>
  );
};
