import {
  StepCompletionDTO,
  StepConfig,
  StepTypeEnum,
  TaskTypeEnum,
  WorkflowConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { SurveyJsWrapper, SurveyJsWrapperHandle } from '@btrway/form-surveyjs';
import { Box, Button, Group, List, Stack, Text } from '@mantine/core';
import { IconRocket } from '@tabler/icons-react';
import React, { useRef } from 'react';

interface StartStepProps {
  workflowDefinition: WorkflowDefinitionResponse;
  currentStep: StepConfig;
  onStartClick: (stepData: StepCompletionDTO) => void;
  onCancel: () => void;
  workflowConfiguration: WorkflowConfig;
}

const StartStep: React.FC<StartStepProps> = ({
  workflowDefinition,
  currentStep,
  onStartClick,
  onCancel,
  workflowConfiguration,
}) => {
  const surveyRef = useRef<SurveyJsWrapperHandle>(null);

  const handleStartClick = () => {
    if (surveyRef.current) {
      const survey = surveyRef.current.getSurveyModel();
      if (survey) {
        const isValid = survey.validate();
        if (isValid) {
          const stepData: StepCompletionDTO = {
            stepKey: currentStep.stepKey,
            values: survey.data,
          };

          onStartClick(stepData);
        }
      }
    } else {
      onStartClick({ stepKey: currentStep.stepKey, values: {} });
    }
  };

  const getReviewSteps = () => {
    const reviewSteps: StepConfig[] = [];
    const visitedSteps = new Set<string>();

    const traverseSteps = (stepKey: string) => {
      if (visitedSteps.has(stepKey)) return;
      visitedSteps.add(stepKey);

      const step = workflowConfiguration.steps?.find(
        (s) => s.stepKey === stepKey
      );
      if (!step) return;

      if (
        step.stepTypeCode === StepTypeEnum.assignTask &&
        step.taskConfig &&
        step.taskConfig.taskType === TaskTypeEnum.completeWorkflowStep
      ) {
        reviewSteps.push(step);
      }

      const outgoingTransitions =
        workflowConfiguration.transitions?.filter(
          (t) => t.sourceStepKey === stepKey
        ) || [];

      outgoingTransitions.forEach((transition) => {
        traverseSteps(transition.targetStepKey);
      });
    };

    const startStep = workflowConfiguration.steps?.find(
      (s) => s.stepTypeCode === StepTypeEnum.flowStart
    );
    if (startStep) {
      traverseSteps(startStep.stepKey);
    }

    return reviewSteps;
  };

  const reviewSteps = getReviewSteps();

  return (
    <Stack
      h="100%"
      w="100%"
      // bg="gray.8"
      style={{
        // border: '1px solid red',
        // borderRadius: 'var(--mantine-radius-xl)',
        padding: 'var(--mantine-spacing-xl)',
        // marginTop: 'calc(-1 * var(--mantine-header-height, 0px) / 2)',
      }}
    >
      <Group
        align="flex-start"
        style={{ flex: 1, overflow: 'hidden' }}
        wrap="nowrap"
      >
        <Box
          style={{
            width: '50%',
            overflow: 'auto',
            paddingRight: 'var(--mantine-spacing-md)',
          }}
        >
          <Text fz={36} fw={700} mb="md">
            {workflowDefinition.name}
          </Text>
          <Text fz="lg" fw={700} mb="md">
            {workflowDefinition.workflowMetadata?.shortDescription}
          </Text>
          <Text fz="lg" mb="xl">
            {workflowDefinition.workflowMetadata?.longDescription}
          </Text>
          {reviewSteps.length > 0 && (
            <>
              <Text fz="xl" fw={600} mb="md">
                What Happens after You Submit?
              </Text>
              <List type="ordered" spacing="xs">
                {reviewSteps.map((step, index) => (
                  <List.Item key={step.stepKey} fz="lg">
                    <Text fz="lg">
                      {step.title || `Review Step ${index + 1}`}
                    </Text>
                  </List.Item>
                ))}
              </List>
            </>
          )}
        </Box>
        {currentStep.formConfig && (
          <Box
            style={{
              width: '50%',
              height: '100%',
              borderRadius: 'var(--mantine-radius-md)',
              overflow: 'auto',
            }}
          >
            <SurveyJsWrapper
              ref={surveyRef}
              formConfig={currentStep.formConfig}
              hideCompleteButton={true}
            />
          </Box>
        )}
      </Group>
      <Group justify="space-between" mt="xl" wrap="nowrap">
        <Button
          onClick={onCancel}
          size="xl"
          variant="outline"
          color="gray.6"
          w="25%"
        >
          <Text fz={28} fw={600}>
            Cancel
          </Text>
        </Button>
        <Button
          onClick={handleStartClick}
          size="xl"
          w="25%"
          leftSection={<IconRocket size={36} />}
        >
          <Text fz={28} fw={600}>
            Start Form!
          </Text>
        </Button>
      </Group>
    </Stack>
  );
};

export default StartStep;
