// hooks/useScreen.ts
import {
  CertificationTypeEnum,
  EventTypeEnum,
  StepTypeEnum,
  TaskConfig,
  TaskTypeEnum,
} from '@btrway/api-workflow';
import { ToolboxItem } from '@btrway/workflow-builder-toolbox';
import {
  useStepConfigFactory,
  useWorkflowService,
  useWorkflowUpdater,
} from '@btrway/workflow-configuration-manager';
import { useEventTypes } from '@btrway/workflow-manager';
import { useCallback } from 'react';
import { WorkflowItemConfig } from '../types/state';
import {
  getWorkflowTypeForCertification,
  getWorkflowTypeForTask,
} from '../utils/screenUtils';
import { useCertificationHandlers } from './useCertificationHandlers';
import { useConfigFactory } from './useConfigFactory';
import { useEventHandlers } from './useEventHandlers';
import { useScreenHelpers } from './useScreenHelpers';
import { useSelectionHandlers } from './useSelectionHandlers';
import { useStepHandlers } from './useStepHandlers';
import { useTaskHandlers } from './useTaskHandlers';
import { useWorkflowBuilderState } from './useWorkflowBuilderState';

export const useScreen = () => {
  const { createStepConfig } = useStepConfigFactory();
  const { getEventType } = useEventTypes();
  const defaultWorkflowService = useWorkflowService();
  const { workflows } = defaultWorkflowService.getWorkflows();

  const {
    state,
    openScreen,
    closeScreen,
    updateTaskConfig,
    updateCertificationConfig,
    updateStepConfig,
    updateEventConfig,
    reset,
  } = useWorkflowBuilderState();

  // Create the updater using the appropriate service. This enables nested workflows to save changes and be updated.
  const workflowUpdater = useWorkflowUpdater(
    state.nestedWorkflowService || undefined
  );
  const activeWorkflow = state.nestedWorkflow || state.parentWorkflow;

  const {
    createCertificationConfig,
    createCourseCertificationConfig,
    createCourseTaskConfig,
    createEventConfig,
    createTaskConfig,
    createWorkflowCertificationConfig,
    createWorkflowTaskConfig,
  } = useConfigFactory();

  // Screen helpers
  const { isAutomationWorkflow, openStepConfigScreen, openTaskConfigScreen } =
    useScreenHelpers({
      openScreen,
      parentWorkflow: activeWorkflow!,
    });

  // Handlers (no longer passing service)
  const { handleStepTypeSelect } = useStepHandlers({
    reset,
    openStepConfigScreen,
    createStepConfig,
  });

  const { handleTaskTypeSelect } = useTaskHandlers({
    reset,
    openScreen,
    openTaskConfigScreen,
    openStepConfigScreen,
    createTaskConfig,
    createStepConfig,
    workflows,
    getWorkflowTypeForTask,
    parentWorkflow: activeWorkflow!,
  });

  const { handleCertificationTypeSelect } = useCertificationHandlers({
    reset,
    openScreen,
    createCertificationConfig,
    parentWorkflow: activeWorkflow!,
    workflows,
    getWorkflowTypeForCertification,
  });

  const { handleEventTypeSelect } = useEventHandlers({
    reset,
    openScreen,
    getEventType,
    createEventConfig,
    parentWorkflow: activeWorkflow!,
  });

  const { handleWorkflowSelect, handleCourseSelect } = useSelectionHandlers({
    state,
    parentWorkflow: activeWorkflow!,
    openScreen,
    openTaskConfigScreen,
    updateTaskConfig,
    updateCertificationConfig,
    createWorkflowTaskConfig,
    createWorkflowCertificationConfig,
    createCourseCertificationConfig,
    createCourseTaskConfig,
    openStepConfigScreen,
    createStepConfig,
  });

  const handleSave = useCallback(
    async (itemConfig: WorkflowItemConfig) => {
      try {
        switch (itemConfig.type) {
          case 'step': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'STEP_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await workflowUpdater.appendStep(config);
            } else {
              await workflowUpdater.updateConfig({
                workflowConfig: {
                  steps: [config],
                },
              });
            }
            updateStepConfig(config);
            break;
          }
          case 'task': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'TASK_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await workflowUpdater.addTask(config);
            } else {
              await workflowUpdater.updateConfig({
                workflowConfig: {
                  tasks: [config],
                },
              });
            }
            updateTaskConfig(config);
            break;
          }
          case 'certification': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'CERTIFICATION_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await workflowUpdater.addCertification(config);
            } else {
              await workflowUpdater.updateConfig({
                workflowConfig: {
                  certifications: [config],
                },
              });
            }
            updateCertificationConfig(config);
            break;
          }
          case 'event': {
            const config = itemConfig.config;
            const currentScreenData =
              state.currentScreen?.type === 'EVENT_CONFIG'
                ? state.currentScreen.data
                : null;
            if (currentScreenData?.isNew) {
              await workflowUpdater.addEvent(config);
            } else {
              await workflowUpdater.updateConfig({
                workflowConfig: {
                  events: [config],
                },
              });
            }
            updateEventConfig(config);
            break;
          }
        }
        closeScreen();
      } catch (error) {
        console.error('Error saving configuration:', error);
      }
    },
    [
      state.currentScreen,
      workflowUpdater,
      updateStepConfig,
      updateTaskConfig,
      updateCertificationConfig,
      updateEventConfig,
      closeScreen,
    ]
  );

  const handleDelete = useCallback(
    async (key: string) => {
      try {
        const currentScreen = state.currentScreen;
        if (!currentScreen) return;

        if (currentScreen.type === 'TASK_CONFIG') {
          await workflowUpdater.removeTask(key);
        } else if (currentScreen.type === 'CERTIFICATION_CONFIG') {
          await workflowUpdater.removeCertification(key);
        } else if (currentScreen.type === 'STEP_CONFIG') {
          await workflowUpdater.removeStep(key);
        } else if (currentScreen.type === 'EVENT_CONFIG') {
          await workflowUpdater.removeEvent(key);
        }
        closeScreen();
      } catch (error) {
        console.error('Error deleting configuration:', error);
      }
    },
    [state.currentScreen, workflowUpdater, closeScreen]
  );

  const handleEditorSave = useCallback(
    async (
      addedTasks: TaskConfig[],
      removedTaskKeys: string[],
      workflowName: string,
      workflowKey: string,
      showTaskConfigAfterSave: boolean
    ) => {
      try {
        const taskType =
          state.currentScreen?.type === 'CURRICULUM_EDITOR'
            ? TaskTypeEnum.completeCurriculum
            : TaskTypeEnum.completeFormPacket;

        const newConfig = createWorkflowTaskConfig(
          workflowKey,
          workflowName,
          taskType
        );
        await updateTaskConfig(newConfig);

        if (showTaskConfigAfterSave) {
          openTaskConfigScreen(newConfig, true);
        } else {
          closeScreen();
        }
      } catch (error) {
        console.error('Error in handleEditorSave:', error);
      }
    },
    [
      state.currentScreen,
      createWorkflowTaskConfig,
      updateTaskConfig,
      openTaskConfigScreen,
      closeScreen,
    ]
  );

  const handleToolboxSelect = useCallback(
    (item: ToolboxItem) => {
      if (item.type === 'stepType') {
        handleStepTypeSelect(item.data.code as StepTypeEnum);
      } else if (item.type === 'taskType') {
        handleTaskTypeSelect(item.data.code as TaskTypeEnum);
      } else if (item.type === 'certificationType') {
        handleCertificationTypeSelect(item.data.code as CertificationTypeEnum);
      } else if (item.type === 'eventType') {
        handleEventTypeSelect(item.data.code as EventTypeEnum);
      }
    },
    [
      handleStepTypeSelect,
      handleTaskTypeSelect,
      handleCertificationTypeSelect,
      handleEventTypeSelect,
    ]
  );

  return {
    state,
    closeScreen,
    screenHandlers: {
      onToolboxSelect: handleToolboxSelect,
      onWorkflowSelect: handleWorkflowSelect,
      onCourseSelect: handleCourseSelect,
      onSave: handleSave,
      onDelete: handleDelete,
      onEditorSave: handleEditorSave,
      onClose: closeScreen,
    },
  };
};
