import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import { useDeleteHelpTag, useGetHelpTags } from '@btrway/api-help';
import { Button, Stack, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React, { useCallback, useMemo, useState } from 'react';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const TagsView: React.FC = () => {
  const { data: helpTags, refetch } = useGetHelpTags();
  const { mutateAsync: deleteHelpTag } = useDeleteHelpTag();
  const [searchTerm, setSearchTerm] = useState('');

  const onDeleteClick = useCallback(
    async (id: number) => {
      try {
        await deleteHelpTag({ helpTagId: id });
        refetch();
      } catch (error) {
        console.error('Error deleting help tag:', error);
      }
    },
    [deleteHelpTag, refetch]
  );

  const actionCellRenderer = useCallback(
    (params: any) => (
      <Button
        size="compact-sm"
        variant="light"
        color="red"
        onClick={() => onDeleteClick(params.data.id)}
      >
        Delete
      </Button>
    ),
    [onDeleteClick]
  );

  const columnDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: 'Tag',
        field: 'tag',
        sort: 'asc',
        flex: 1,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: actionCellRenderer,
        width: 150,
        suppressHeaderMenuButton: true,
      },
    ],
    [actionCellRenderer]
  );

  const filteredTags = useMemo(() => {
    if (!searchTerm) return helpTags;
    return helpTags?.filter((helpTag) =>
      helpTag.tag.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [helpTags, searchTerm]);

  return (
    <Stack gap="md" h="100%">
      <TextInput
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.currentTarget.value)}
        leftSection={<IconSearch size={16} />}
      />
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100% - 40px)', width: '100%' }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={filteredTags}
          onGridReady={(params) => params.api.sizeColumnsToFit()}
        />
      </div>
    </Stack>
  );
};

export default TagsView;
