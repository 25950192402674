import { WorkgroupResponse } from '@btrway/api-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useWorkgroups } from '@btrway/workgroup-manager';
import { Tree, TreeNodeData, useTree } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';

interface WorkgroupHierarchyTreeProps {
  rootWorkgroupId: number;
  selectedWorkgroup?: WorkgroupResponse;
  onSelectWorkgroup: (workgroup: WorkgroupResponse) => void;
}

const convertWorkgroupToTreeData = (
  workgroup: WorkgroupResponse
): TreeNodeData => ({
  value: workgroup.id.toString(),
  label: workgroup.name,
  children:
    workgroup.children && workgroup.children.length > 0
      ? workgroup.children.map(convertWorkgroupToTreeData)
      : undefined,
});

const WorkgroupHierarchyTree: React.FC<WorkgroupHierarchyTreeProps> = ({
  rootWorkgroupId,
  selectedWorkgroup,
  onSelectWorkgroup,
}) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { hierarchicalWorkgroups, isLoading, error, getWorkgroup } =
    useWorkgroups(currentOrganization.id);
  const [treeData, setTreeData] = useState<TreeNodeData[]>([]);
  const tree = useTree({
    initialSelectedState: selectedWorkgroup
      ? [selectedWorkgroup.id.toString()]
      : [],
  });

  useEffect(() => {
    if (hierarchicalWorkgroups && hierarchicalWorkgroups.length > 0) {
      const data = hierarchicalWorkgroups.map(convertWorkgroupToTreeData);
      setTreeData(data);
      tree.initialize(data);

      // Expand the root node(s) by default
      data.forEach((node) => tree.expand(node.value));

      // If there's a selected workgroup, ensure its path is expanded
      if (selectedWorkgroup) {
        expandParents(data, selectedWorkgroup.id.toString());
      }
    }
  }, [hierarchicalWorkgroups, selectedWorkgroup]);

  const expandParents = (nodes: TreeNodeData[], targetValue: string) => {
    const findAndExpand = (currentNodes: TreeNodeData[]): boolean => {
      for (const node of currentNodes) {
        if (node.value === targetValue) {
          return true;
        }
        if (node.children && findAndExpand(node.children)) {
          tree.expand(node.value);
          return true;
        }
      }
      return false;
    };

    findAndExpand(nodes);
  };

  const renderNode = ({
    node,
    hasChildren,
    expanded,
    level,
  }: {
    node: TreeNodeData;
    hasChildren: boolean;
    expanded: boolean;
    level: number;
  }) => {
    const isRootNode = level === 1;
    const isSelected =
      selectedWorkgroup && node.value === selectedWorkgroup.id.toString();

    const nodeStyle: React.CSSProperties = {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      marginBottom: '4px',
      paddingLeft: `${(level - 1) * 20 + 8}px`,
      cursor: 'pointer',
      borderRadius: 'var(--mantine-radius-xl)',
      transition: 'background-color 0.2s ease',
      backgroundColor: isSelected
        ? 'var(--mantine-color-gray-2)'
        : 'transparent',
    };

    const chevronStyle: React.CSSProperties = {
      width: '18px',
      height: '18px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '5px',
    };

    return (
      <div
        style={nodeStyle}
        onClick={() => {
          const workgroup = getWorkgroup(parseInt(node.value));
          if (workgroup) {
            onSelectWorkgroup(workgroup);
          }
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = 'var(--mantine-color-gray-2)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = isSelected
            ? 'var(--mantine-color-gray-2)'
            : 'transparent';
        }}
      >
        {!isRootNode && (
          <span
            style={chevronStyle}
            onClick={(e) => {
              if (hasChildren) {
                e.stopPropagation();
                expanded ? tree.collapse(node.value) : tree.expand(node.value);
              }
            }}
          >
            {hasChildren ? (
              expanded ? (
                <IconChevronDown size={18} />
              ) : (
                <IconChevronRight size={18} />
              )
            ) : null}
          </span>
        )}
        {isRootNode && <span style={chevronStyle}></span>}
        <span style={{ fontSize: 'var(--mantine-font-size-sm)' }}>
          {node.label}
        </span>
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading workgroups...</div>;
  }

  if (error) {
    return <div>Error loading workgroups: {error.toString()}</div>;
  }

  if (treeData.length === 0) {
    return <div>No workgroups available</div>;
  }

  return (
    <Tree
      data={treeData}
      tree={tree}
      renderNode={renderNode}
      expandOnClick={false}
      selectOnClick={false}
      clearSelectionOnOutsideClick
    />
  );
};

export default WorkgroupHierarchyTree;
