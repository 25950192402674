import {
  WorkflowTemplateRequest,
  WorkflowTemplateResponse,
} from '@btrway/api-workflow';
import { uuid } from '@btrway/utils';

export const convertToWorkflowTemplateRequest = (
  template: WorkflowTemplateResponse
): WorkflowTemplateRequest => {
  return {
    id: template.id,
    name: template.name,
    description: template.description || '',
    templateKey: template.templateKey || uuid(),
    workflowType: template.workflowType!,
    published: template.published,
    workflowConfiguration: template.workflowConfiguration,
    templateSettings: template.templateSettings,
    versionDescription: template.versionDescription,
    versionPublishedAt: template.versionPublishedAt,
  };
};
