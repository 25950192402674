import { StyledModal } from '@btrway/styled-modal';
import React, { createContext, useContext, useState } from 'react';
import { SettingsDisplayLayout } from '../components/SettingsDisplayLayout/SettingsDisplayLayout';
import {
  DisplayConfig,
  SettingsDisplayContextValue,
  SettingsDisplayState,
} from '../types/settingsDisplay';

export const SettingsDisplayContext = createContext<
  SettingsDisplayContextValue | undefined
>(undefined);

interface SettingsDisplayProviderProps {
  children: React.ReactNode;
}

export const SettingsDisplayProvider: React.FC<
  SettingsDisplayProviderProps
> = ({ children }) => {
  const [leftPanel, setLeftPanel] = useState<SettingsDisplayState>({
    isOpen: false,
    content: null,
  });
  const [rightPanel, setRightPanel] = useState<SettingsDisplayState>({
    isOpen: false,
    content: null,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalConfig, setModalConfig] = useState<DisplayConfig['modalProps']>();

  const openDisplay = (
    content: React.ReactNode,
    config: DisplayConfig = { mode: 'panel', position: 'left' }
  ) => {
    if (config.mode === 'modal') {
      setModalContent(content);
      setModalConfig(config.modalProps);
      setIsModalOpen(true);
    } else {
      const panelState: SettingsDisplayState = {
        isOpen: true,
        content,
        width: config.width || 400,
      };

      if (config.position === 'right') {
        setRightPanel(panelState);
      } else {
        setLeftPanel(panelState);
      }
    }
  };

  const closeDisplay = () => {
    setLeftPanel((prev) => ({ ...prev, isOpen: false }));
    setRightPanel((prev) => ({ ...prev, isOpen: false }));
    setIsModalOpen(false);

    setTimeout(() => {
      if (!leftPanel.isOpen && !rightPanel.isOpen && !isModalOpen) {
        setLeftPanel((prev) => ({ ...prev, content: null }));
        setRightPanel((prev) => ({ ...prev, content: null }));
        setModalContent(null);
      }
    }, 300);
  };

  const closeLeftPanel = () => {
    setLeftPanel((prev) => ({ ...prev, isOpen: false }));
    setTimeout(() => {
      setLeftPanel((prev) => ({ ...prev, content: null }));
    }, 300);
  };

  const closeRightPanel = () => {
    setRightPanel((prev) => ({ ...prev, isOpen: false }));
    setTimeout(() => {
      setRightPanel((prev) => ({ ...prev, content: null }));
    }, 300);
  };

  return (
    <SettingsDisplayContext.Provider value={{ openDisplay, closeDisplay }}>
      <SettingsDisplayLayout
        leftPanel={leftPanel}
        rightPanel={rightPanel}
        onLeftPanelClose={closeLeftPanel}
        onRightPanelClose={closeRightPanel}
      >
        {children}
      </SettingsDisplayLayout>

      <StyledModal
        opened={isModalOpen}
        onClose={closeDisplay}
        variant={modalConfig?.variant || 'xl'}
        title={modalConfig?.title}
      >
        {modalContent}
      </StyledModal>
    </SettingsDisplayContext.Provider>
  );
};

export const useSettingsDisplay = () => {
  const context = useContext(SettingsDisplayContext);
  if (!context) {
    throw new Error(
      'useSettingsDisplay must be used within SettingsDisplayProvider'
    );
  }
  return context;
};
