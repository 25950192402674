import { StepTypeEnum } from '@btrway/api-workflow';
import { localization } from 'survey-creator-core';

/*
Possible properties that can be customized:
https://github.com/surveyjs/survey-creator/blob/master/packages/survey-creator-core/src/localization/english.ts
*/

export const setupLabels = (stepTypeCode: StepTypeEnum | undefined) => {
  const enLocale = localization.getLocale('en');

  // Set up common translations
  enLocale.tabs.designer = 'Form Layout';
  enLocale.ed.survey = 'Form';
  enLocale.ed.surveyTypeName = 'Form';

  // Set up survey placeholder text based on step type
  let surveyPlaceholderTitle = "Let's add some questions to this form!";
  let surveyPlaceholderDescription =
    'Drag an element from the toolbox or click the button below.';

  if (stepTypeCode === StepTypeEnum.flowStart) {
    surveyPlaceholderTitle = 'Let’s get started!';
    surveyPlaceholderDescription =
      'These are questions that will be asked when the Form is launched. Typically used to specify that the Form is being completed about someone else.';
  }

  enLocale.ed.surveyPlaceholderTitle = surveyPlaceholderTitle;
  enLocale.ed.surveyPlaceholderDescription = surveyPlaceholderDescription;

  // Force update of localization
  localization.currentLocale = 'en';
};
