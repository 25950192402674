import { AssetResponse } from '@btrway/api-courseware';
import { Timeline } from '@mantine/core';
import React, { useCallback } from 'react';
import ChapterListItem from '../ChapterListItem/ChapterListItem';

interface ChapterListProps {
  course: AssetResponse;
  currentChapter: string;
  onChapterSelect: (chapterKey: string) => void;
}

const ChapterList: React.FC<ChapterListProps> = ({
  course,
  currentChapter,
  onChapterSelect,
}) => {
  const handleChapterClick = useCallback(
    (chapterId: string) => {
      onChapterSelect(chapterId);
    },
    [onChapterSelect]
  );

  return (
    <Timeline bulletSize={24} lineWidth={3} reverseActive>
      {course.chapters?.map((courseChapter) => (
        <ChapterListItem
          key={courseChapter.key}
          chapter={courseChapter}
          isActive={courseChapter.key === currentChapter}
          onClick={() => handleChapterClick(courseChapter.key)}
        />
      ))}
    </Timeline>
  );
};

export default ChapterList;
