import { TaskTypeEnum } from '@btrway/api-workflow';
import { useRightSection } from '@btrway/workflow-builder-rightsection';
import { Card, Group, Text, UnstyledButton } from '@mantine/core';
import { IconBook, IconEdit } from '@tabler/icons-react';
import React, { useState } from 'react';
import { getTypedTaskProperties } from '../../../config/taskPropertiesMap';
import { TaskConfigProps } from '../../../types/editors';

const taskType = TaskTypeEnum.createCalendarEvent;

export const CreateCalendarEventConfiguration: React.FC<TaskConfigProps> = ({
  config,
  onConfigChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setRightSectionContent, clearRightSectionContent } =
    useRightSection();

  const taskProperties = getTypedTaskProperties(config, taskType);

  const openMessageComposer = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        // onClick={openMessageComposer}
        // style={{ cursor: 'pointer' }}
      >
        <Group justify="space-between" wrap="nowrap">
          <Group justify="flex-start" gap="xs" wrap="nowrap" align="flex-start">
            <IconBook style={{ width: '20px', height: '20px' }} />
            <Text fz="md" fw={600}>
              Configure Event Details
            </Text>
          </Group>
          <UnstyledButton>
            <IconEdit size={20} color="blue" />
          </UnstyledButton>
        </Group>
      </Card>
    </>
  );
};
