import {
  NavLink as MantineNavLink,
  NavLinkProps as MantineNavLinkProps,
  Tooltip,
} from '@mantine/core';
import { Icon as TablerIcon } from '@tabler/icons-react';
import cx from 'clsx';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

interface NavbarItemProps extends Omit<MantineNavLinkProps, 'component'> {
  to: string;
  icon?: TablerIcon;
  minimized?: boolean;
  onItemClick?: () => void;
}

export const NavbarItem: React.FC<NavbarItemProps> = ({
  to,
  icon: Icon,
  minimized = false,
  label,
  onItemClick,
  ...props
}) => {
  const handleClick = () => {
    if (onItemClick) {
      onItemClick();
    }
  };

  const content = (
    <MantineNavLink
      {...props}
      label={minimized ? undefined : label}
      onClick={handleClick}
      leftSection={
        Icon && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 40,
              height: 40,
            }}
          >
            <Icon size="1.25rem" stroke={1.5} />
          </div>
        )
      }
      renderRoot={({ className, ...others }) => (
        <RouterNavLink
          to={to}
          className={({ isActive }) =>
            cx(className, { 'mantine-NavLink-active': isActive })
          }
          {...others}
        />
      )}
      styles={(theme) => ({
        root: {
          height: 40,
          padding: 0,
          borderRadius: theme.radius.sm,
        },
        body: {
          display: 'flex',
          alignItems: 'center',
          marginLeft: -10,
        },
        label: {
          display: minimized ? 'none' : 'block',
        },
      })}
    />
  );

  if (minimized) {
    return (
      <Tooltip label={label} position="right" withArrow>
        {content}
      </Tooltip>
    );
  }

  return content;
};
