import { SurveyJsWrapper, SurveyJsWrapperHandle } from '@btrway/form-surveyjs';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';

import { useStyledModal } from '@btrway/styled-modal';
import { useFormReview } from '../../hooks/useFormReview';
import WorkflowInstanceView from '../WorkflowInstanceView/WorkflowInstanceView';
import styles from './FormReview.module.css';

interface FormReviewProps {
  taskInstanceId: number;
  isOpen: boolean;
  onClose: () => void;
  onTaskComplete?: (taskInstanceId: number) => void;
}

const FormReview: React.FC<FormReviewProps> = ({
  taskInstanceId,
  isOpen,
  onClose,
  onTaskComplete,
}) => {
  const { setModalTitle } = useStyledModal();

  const { taskInstance, currentStep, isLoading, submitReview } =
    useFormReview(taskInstanceId);
  // const { removeTask } = useTaskAssignments();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const surveyRef = useRef<SurveyJsWrapperHandle>(null);

  useEffect(() => {
    setModalTitle(
      <Text fz="xl" fw={700}>
        {taskInstance?.workflowInstance?.workflowDefinition?.name || 'Review'}
      </Text>
    );

    return () => {
      setModalTitle(undefined);
    };
  }, [taskInstance?.workflowInstance?.workflowDefinition?.name, setModalTitle]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = async () => {
    if (surveyRef.current) {
      const survey = surveyRef.current.getSurveyModel();
      if (survey) {
        const isValid = survey.validate();
        if (isValid) {
          setIsSubmitting(true);
          setError(null);
          try {
            const result = await submitReview(survey.data);
            if (result) {
              // removeTask(taskInstanceId);
              if (onTaskComplete) {
                onTaskComplete(taskInstanceId);
              } else {
                onClose();
              }
            } else {
              setError('Failed to submit review. Please try again.');
            }
          } catch (error) {
            console.error('Error submitting review:', error);
            setError('Failed to submit review. Please try again.');
          } finally {
            setIsSubmitting(false);
          }
        } else {
          setError('Please fill out all required fields before submitting.');
        }
      }
    }
  };

  if (isLoading || !taskInstance || !currentStep) {
    return null; // Or return a loading indicator
  }

  return (
    <Stack style={{ width: '100%', height: '100%' }} gap={0}>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <div
          className={styles.split}
          style={{ display: 'flex', width: '100%' }}
        >
          <div style={{ flex: 1, height: '100%', overflow: 'auto' }}>
            {taskInstance.workflowInstance && (
              <WorkflowInstanceView taskInstance={taskInstance} />
            )}
          </div>
          <div style={{ flex: 1, height: '100%', overflow: 'auto' }}>
            {currentStep.formConfig ? (
              <SurveyJsWrapper
                ref={surveyRef}
                formConfig={currentStep.formConfig}
                hideCompleteButton={true}
              />
            ) : (
              <Text>No survey available</Text>
            )}
          </div>
        </div>
      </Box>
      {error && (
        <Text color="red" style={{ textAlign: 'center', padding: '10px' }}>
          {error}
        </Text>
      )}
      <Group
        justify="flex-end"
        gap="md"
        p="md"
        style={
          {
            // height: '60px',
            // marginTop: 'var(--mantine-spacing-md)',
            // marginBottom: 'var(--mantine-spacing-md)',
          }
        }
      >
        <Button onClick={handleCancel} size="lg" variant="outline">
          <Text fz={24} fw={600}>
            Cancel
          </Text>
        </Button>
        <Button
          onClick={handleSubmit}
          color="green.8"
          size="lg"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          <Text fz={24} fw={600}>
            Submit
          </Text>
        </Button>
      </Group>
    </Stack>
  );
};

export default FormReview;
