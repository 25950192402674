// import { DataTypeEnum } from '@btrway/api-workflow';
// import { Alert, Skeleton, Text, TextProps } from '@mantine/core';
// import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useEntityData } from '../../hooks/useEntityData';
// import { renderEntityContent } from '../../utils/getRenderer';

// interface EntityTagProps extends TextProps {
//   dataType: keyof typeof DataTypeEnum;
//   id: number;
//   allowClick?: boolean;
//   onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
// }

// export const EntityTag: React.FC<EntityTagProps> = ({
//   dataType,
//   id,
//   allowClick = true,
//   onClick,
//   ...textProps
// }) => {
//   const navigate = useNavigate();
//   const [isVisible, setIsVisible] = useState(false);
//   const ref = useRef<HTMLSpanElement>(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       ([entry]) => {
//         if (entry.isIntersecting) {
//           setIsVisible(true);
//           observer.disconnect();
//         }
//       },
//       { threshold: 0.1 } // Trigger when 10% of the element is visible
//     );

//     if (ref.current) {
//       observer.observe(ref.current);
//     }

//     return () => {
//       observer.disconnect();
//     };
//   }, []);

//   const { data, isLoading, error } = useEntityData(dataType, id, isVisible);

//   const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
//     if (dataType === DataTypeEnum.person) {
//       navigate(`/app/person/${id}`);
//     } else if (dataType === DataTypeEnum.workgroup) {
//       navigate(`/app/workgroup/${id}`);
//     }
//     onClick?.(event);
//   };

//   if (id === 0) {
//     return (
//       <Alert color="yellow" variant="light" style={{ display: 'inline-block' }}>
//         Warning: Invalid {dataType} ID (0)
//       </Alert>
//     );
//   }

//   return (
//     <span ref={ref}>
//       {isVisible ? (
//         isLoading ? (
//           <Skeleton height={20} width={100} />
//         ) : error ? (
//           <Text component="span" color="red" {...textProps}>
//             Error loading data
//           </Text>
//         ) : (
//           <Text
//             component="span"
//             onClick={allowClick ? handleClick : undefined}
//             style={{
//               cursor: dataType !== DataTypeEnum.course ? 'pointer' : 'default',
//               display: 'inline-block',
//             }}
//             {...textProps}
//           >
//             {renderEntityContent(dataType, data)}
//           </Text>
//         )
//       ) : (
//         <Skeleton height={20} width={100} />
//       )}
//     </span>
//   );
// };

import { DataTypeEnum } from '@btrway/api-workflow';
import { Alert, Skeleton, Text, TextProps } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useVisibleEntityData } from '../../hooks/useElementVisibility';
import { renderEntityContent } from '../../utils/getRenderer';

interface EntityTagProps extends TextProps {
  dataType: keyof typeof DataTypeEnum;
  id: number;
  allowClick?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
}

export const EntityTag: React.FC<EntityTagProps> = ({
  dataType,
  id,
  allowClick = true,
  onClick,
  ...textProps
}) => {
  const navigate = useNavigate();
  const { ref, data, isLoading, error } = useVisibleEntityData(dataType, id);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (dataType === DataTypeEnum.person) {
      navigate(`/app/person/${id}`);
    } else if (dataType === DataTypeEnum.workgroup) {
      navigate(`/app/workgroup/${id}`);
    }
    onClick?.(event);
  };

  if (id === 0) {
    return (
      <Alert color="yellow" variant="light" style={{ display: 'inline-block' }}>
        Warning: Invalid {dataType} ID (0)
      </Alert>
    );
  }

  return (
    <span ref={ref}>
      {isLoading ? (
        <Skeleton height={20} width={100} />
      ) : error ? (
        <Text component="span" color="red" {...textProps}>
          Error loading data
        </Text>
      ) : (
        <Text
          component="span"
          onClick={allowClick ? handleClick : undefined}
          style={{
            cursor: dataType !== DataTypeEnum.course ? 'pointer' : 'default',
            display: 'inline-block',
          }}
          {...textProps}
        >
          {renderEntityContent(dataType, data)}
        </Text>
      )}
    </span>
  );
};
