import { Card, Stack, Text } from '@mantine/core';
import React from 'react';

interface MetricCardProps {
  title: string;
  value: number;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value }) => {
  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Stack align="flex-start" justify="flex-start" gap="xs">
        <Text fz={36} fw={700}>
          {value.toLocaleString()}
        </Text>
        <Text fw={500} size="sm" style={{ textTransform: 'uppercase' }}>
          {title}
        </Text>
      </Stack>
    </Card>
  );
};

export default MetricCard;
