import { AssetResponse } from '@btrway/api-courseware';
import { CourseTaskProperties } from '@btrway/api-workflow';
import { VideoBackground } from '@btrway/courseware-course-viewer';
import { useCourses, useCurriculums } from '@btrway/courseware-manager';
import { useTaskManager } from '@btrway/task-manager';
import { startCourseViewerTransitionAtom } from '@btrway/web-routing';
import {
  Button,
  Container,
  ScrollArea,
  Space,
  Stack,
  Text,
} from '@mantine/core';
import { IconPlayerPlayFilled } from '@tabler/icons-react';
import { useSetAtom } from 'jotai';
import React, { useMemo } from 'react';
import CourseCarousel from '../../components/CourseCarousel/CourseCarousel';
import CurriculumCourseCarousel from '../../components/CurriculumCourseCarousel/CurriculumCourseCarousel';

const EducationPage: React.FC = () => {
  const startCourseViewerTransition = useSetAtom(
    startCourseViewerTransitionAtom
  );
  const {
    curriculums,
    isLoading: isLoadingCurriculums,
    error,
  } = useCurriculums();
  const { courses } = useCourses();
  const { incompleteCourseTasks, assignedUnstartedCourseTasks } =
    useTaskManager();

  // Convert tasks to course arrays
  const incompleteCourses = useMemo(() => {
    return incompleteCourseTasks
      .map((task) => {
        if (task.taskProperties) {
          const properties = task.taskProperties as CourseTaskProperties;
          const courseId = properties.courseSelection.courseUid?.value;
          const course = courses.find((course) => course.uid === courseId);
          if (course) {
            return course;
          }
        }
        return null;
      })
      .filter((course): course is AssetResponse => course !== null); // Type guard to ensure AssetResponse array
  }, [incompleteCourseTasks, courses]);

  const unstartedCourses = useMemo(() => {
    return assignedUnstartedCourseTasks
      .map((task) => {
        if (task.taskProperties) {
          const properties = task.taskProperties as CourseTaskProperties;
          const courseId = properties.courseSelection.courseUid?.value;
          const course = courses.find((course) => course.uid === courseId);
          if (course) {
            return course;
          }
        }
        return null;
      })
      .filter((course): course is AssetResponse => course !== null); // Type guard to ensure AssetResponse array
  }, [assignedUnstartedCourseTasks, courses]);

  const handlePlayCourse = (courseUid: number) => {
    startCourseViewerTransition({ courseUid: courseUid, resume: true });
  };

  const handlePlayButton = () => {
    console.log('Main play button clicked');
  };

  const isLoading = isLoadingCurriculums;
  if (isLoading || !curriculums) return <div>Loading...</div>;

  return (
    <ScrollArea
      style={{
        height: '100%',
        backgroundColor: 'black',
      }}
    >
      <VideoBackground
        playbackId="Wkg8uwnrd5H3pMH5ldDfP01LJK02gUDGkl6EgG1Yn4Yc8"
        height="80vh"
      >
        <Container size="95%" style={{ height: '100%' }}>
          <Stack
            justify="space-between"
            style={{
              height: '100%',
              paddingTop: '10vh',
              paddingBottom: '5vh',
            }}
          >
            <Stack justify="flex-start" gap="xl" style={{ flex: 1 }}>
              <Space h={200} />
              <Text fz={60} fw={700} c="white" ta="left">
                Welcome to A Better Way
              </Text>
              <Text fz={28} c="white" ta="left" style={{ maxWidth: '800px' }}>
                Begin your journey towards becoming a better steward of our
                children's athletics journey. Start now!
              </Text>
              <Button
                leftSection={<IconPlayerPlayFilled size={24} />}
                size="xl"
                bg="white"
                c="black"
                onClick={handlePlayButton}
                style={{ width: 'fit-content' }}
              >
                Play
              </Button>
            </Stack>
            {incompleteCourses.length > 0 && (
              <CourseCarousel
                courses={incompleteCourses}
                onPlayCourse={handlePlayCourse}
                title="Continue Watching"
                height={150}
                growthFactor={1.2}
              />
            )}
            {unstartedCourses.length > 0 && (
              <CourseCarousel
                courses={unstartedCourses}
                onPlayCourse={handlePlayCourse}
                title="Assigned Courses"
                height={150}
                growthFactor={1.2}
              />
            )}
            {curriculums.map((curriculum) => (
              <CurriculumCourseCarousel
                key={curriculum.id}
                curriculumId={curriculum.id}
                onPlayCourse={handlePlayCourse}
                title={curriculum.name || 'Untitled Curriculum'}
                height={150}
                growthFactor={1.2}
              />
            ))}
            <Space h={40} />
          </Stack>
        </Container>
      </VideoBackground>
    </ScrollArea>
  );
};

export default EducationPage;
