import {
  getGetFacilityByIdQueryKey,
  useGetFacilityById,
} from '@btrway/api-calendar';
import {
  getGetEntityGroupByIdQueryKey,
  getGetPersonByIdQueryKey,
  getGetWorkgroupByIdQueryKey,
  useGetEntityGroupById,
  useGetPersonById,
  useGetWorkgroupById,
} from '@btrway/api-core';
import {
  getGetAssetByUidQueryKey,
  useGetAssetByUid,
} from '@btrway/api-courseware';
import {
  getGetUserRoleByIdQueryKey,
  useGetUserRoleById,
} from '@btrway/api-security';
import {
  DataTypeEnum,
  getGetWorkflowDefinitionByIdQueryKey,
  useGetWorkflowDefinitionById,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';

// const STALE_TIME = 60000; // 1 minute
const STALE_TIME = Infinity; // 1 minute

// Define a generic type for the query result
type QueryResult<T> = {
  data: T | undefined;
  error: Error | null;
  isLoading: boolean;
};

export const useEntityData = (
  dataType: keyof typeof DataTypeEnum,
  id: number,
  isVisible: boolean
): QueryResult<any> => {
  const { currentOrganization } = useAuthenticatedUser();

  // Early return if id is 0
  if (id === 0) {
    return {
      data: undefined,
      error: new Error('Invalid ID: 0'),
      isLoading: false,
    };
  }

  switch (dataType) {
    case DataTypeEnum.person:
      if (!currentOrganization) {
        return {
          data: undefined,
          error: new Error('Current organization is not available'),
          isLoading: false,
        };
      }
      return useGetPersonById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetPersonByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.workgroup:
      return useGetWorkgroupById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetWorkgroupByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.course:
      return useGetAssetByUid(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetAssetByUidQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.workflowDefinition:
      return useGetWorkflowDefinitionById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetWorkflowDefinitionByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.entityGroup:
      return useGetEntityGroupById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetEntityGroupByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.facility:
      return useGetFacilityById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetFacilityByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    case DataTypeEnum.userRole:
      return useGetUserRoleById(id, {
        query: {
          staleTime: STALE_TIME,
          queryKey: getGetUserRoleByIdQueryKey(id),
          enabled: isVisible,
        },
      }) as QueryResult<any>;
    default:
      throw new Error(`Unsupported data type: ${dataType}`);
  }
};
