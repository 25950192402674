import React, { createContext, useContext } from 'react';
import { RouteConfig } from '../types/route';

interface RoutesContextValue {
  routes: RouteConfig[];
  setRoutes: (routes: RouteConfig[]) => void;
}

const RoutesContext = createContext<RoutesContextValue | undefined>(undefined);

export const useRoutesContext = () => {
  const context = useContext(RoutesContext);
  if (!context) {
    throw new Error('useRoutesContext must be used within a RoutesProvider');
  }
  return context;
};

interface RoutesProviderProps {
  children: React.ReactNode;
  routes: RouteConfig[];
}

// Simplified RoutesProvider that only stores routes without filtering
export const RoutesProvider: React.FC<RoutesProviderProps> = ({
  children,
  routes: initialRoutes,
}) => {
  const [routes, setRoutes] = React.useState<RouteConfig[]>(initialRoutes);

  return (
    <RoutesContext.Provider
      value={{
        routes,
        setRoutes,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};
