import {
  ActionIcon,
  Box,
  Group,
  ScrollArea,
  Stack,
  Text,
  Transition,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { ReactNode } from 'react';

interface RightPanelProps {
  title: string;
  isVisible: boolean;
  onToggle: () => void;
  children: ReactNode;
}

export function RightPanel({
  title,
  isVisible,
  onToggle,
  children,
}: RightPanelProps) {
  if (!isVisible) {
    return (
      <ActionIcon
        variant="subtle"
        onClick={onToggle}
        style={{ alignSelf: 'flex-start' }}
      >
        <IconChevronRight size={16} style={{ transform: 'rotate(180deg)' }} />
      </ActionIcon>
    );
  }

  return (
    <Transition mounted={isVisible} transition="slide-left" duration={200}>
      {(styles) => (
        <Box w={300} h="100%" style={styles}>
          <Stack h="100%" gap="md">
            <Group align="center" gap="xs">
              <ActionIcon variant="subtle" onClick={onToggle}>
                <IconChevronRight size={16} />
              </ActionIcon>
              <Text size="sm" fw={500}>
                {title}
              </Text>
            </Group>
            <ScrollArea style={{ flex: 1 }}>{children}</ScrollArea>
          </Stack>
        </Box>
      )}
    </Transition>
  );
}
