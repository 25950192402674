import { OutsideTaskDefinitionRequest } from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { useScrollableDrawer } from '@btrway/scrollable-drawer';
import { UrlTextInput } from '@btrway/shared-components';
import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useEffect, useRef, useState } from 'react';

interface OutsideTaskEditProps {
  task: OutsideTaskDefinitionRequest;
  isNew?: boolean;
  onSave: (task: OutsideTaskDefinitionRequest) => Promise<void>;
  onDelete?: (taskId: number) => Promise<void>;
  onCancel: () => void;
  isSaving?: boolean;
}

export const OutsideTaskEdit = ({
  task,
  isNew = false,
  onSave,
  onDelete,
  onCancel,
  isSaving = false,
}: OutsideTaskEditProps) => {
  const { currentOrganization } = useAuthenticatedUser();
  const { setDrawerFooter } = useScrollableDrawer();

  // Use a ref to maintain the current form data
  const formDataRef = useRef<OutsideTaskDefinitionRequest>({
    ...task,
    organizationId: currentOrganization.id,
  });

  // State for rendering UI updates
  const [formData, setFormData] = useState<OutsideTaskDefinitionRequest>({
    ...task,
    organizationId: currentOrganization.id,
  });

  const handleFormChange = (
    field: keyof OutsideTaskDefinitionRequest,
    value: string | null
  ) => {
    const updatedData = {
      ...formData,
      [field]: value,
    };

    // Update both state and ref
    setFormData(updatedData);
    formDataRef.current = updatedData;
  };

  const handleSave = async () => {
    if (!formDataRef.current.name.trim()) return;

    // Use the ref data for saving
    const taskToSave: OutsideTaskDefinitionRequest = {
      id: formDataRef.current.id,
      name: formDataRef.current.name.trim(),
      description: formDataRef.current.description?.trim(),
      url: formDataRef.current.url?.trim(),
      outsideTaskKey: formDataRef.current.outsideTaskKey,
      organizationId: currentOrganization.id,
    };

    console.log('Saving task:', taskToSave);
    await onSave(taskToSave);
  };

  const handleDelete = async () => {
    if (!task.id || !onDelete) return;
    await onDelete(task.id);
  };

  useEffect(() => {
    setDrawerFooter(
      <Group justify="space-between" gap="md">
        {!isNew && onDelete && (
          <Button
            variant="light"
            color="red"
            onClick={handleDelete}
            loading={isSaving}
          >
            Delete
          </Button>
        )}
        <Group ml="auto">
          <Button variant="light" onClick={onCancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            loading={isSaving}
            disabled={!formData.name.trim()}
          >
            Save Changes
          </Button>
        </Group>
      </Group>
    );

    return () => setDrawerFooter(null);
  }, [isNew, isSaving, formData.name]);

  return (
    <Stack gap="md">
      <LoadingOverlay visible={isSaving} />
      <TextInput
        label="Task Name"
        value={formData.name}
        onChange={(e) => handleFormChange('name', e.target.value)}
        placeholder="Enter task name"
        required
      />
      <Textarea
        label="Description"
        value={formData.description ?? ''}
        onChange={(e) => handleFormChange('description', e.target.value)}
        placeholder="Enter task description"
      />
      <UrlTextInput
        label="Web Address (URL)"
        value={formData.url ?? ''}
        onUrlChange={(url) => handleFormChange('url', url)}
        placeholder="Enter task URL"
      />
    </Stack>
  );
};
