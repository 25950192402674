// src/context/AuthContext.tsx
import { createAxiosInstance, getClient } from '@btrway/api-client-provider';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface AuthState {
  status: 'loading' | 'authenticated' | 'unauthenticated';
  isAuthenticated: boolean;
  isAccessingOrg: boolean;
  isAccessingPerson: boolean;
  apiUrl: string;
}

interface AuthContextType {
  authState: AuthState;
  updateAuthState: (state: Partial<AuthState>) => void;
  checkAuthStatus: () => Promise<void>;
  logout: () => Promise<void>;
  login: (credentials: { email: string; password: string }) => Promise<{
    success: boolean;
    error?: string;
    requireMfa?: boolean;
    email?: string;
  }>;
  revokeToken: (tokenType: string) => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

const isPublicRoute = (pathname: string): boolean => {
  return pathname.startsWith('/register/') || 
         pathname === '/login' ||
         pathname.startsWith('/callback') ||
         pathname.startsWith('/reset-password/');
};

export const AuthProvider: React.FC<{
  children: React.ReactNode;
  apiUrl: string;
}> = ({ children, apiUrl }) => {
  const navigate = useNavigate();
  const [authState, setAuthState] = useState<AuthState>({
    status: 'loading',
    isAuthenticated: false,
    isAccessingOrg: false,
    isAccessingPerson: false,
    apiUrl: apiUrl,
  });
  const axios = getClient();
  const location = useLocation();
  const isPublicPath = isPublicRoute(location.pathname);


  const updateAuthState = useCallback((newState: Partial<AuthState>) => {
    setAuthState((current) => ({ ...current, ...newState }));
  }, []);

  const checkAuthStatus = useCallback(async () => {
    try {
      const response = await axios.post('/auth/status', {
        withCredentials: true,
      });

      const newState: Partial<AuthState> = {
        status: response.data.isAuthenticated
          ? 'authenticated'
          : 'unauthenticated',
        isAuthenticated: response.data.isAuthenticated,
        isAccessingOrg: response.data.isAccessingOrg || false,
        isAccessingPerson: response.data.isAccessingPerson || false,
      };

      updateAuthState(newState);
      return response.data;
    } catch (error) {
      console.error('Error checking auth status:', error);
      updateAuthState({
        status: 'unauthenticated',
        isAuthenticated: false,
        isAccessingOrg: false,
        isAccessingPerson: false,
      });
      throw error;
    }
  }, [updateAuthState]);

  const revokeToken = async (tokenType: string) => {
    try {
      const response = await axios.post('/auth/revoke-token', tokenType);
    } catch (error) {
      console.error('Error revoking token', error);
      throw error;
    }
  };

  const login = useCallback(
    async (credentials: { email: string; password: string }) => {
      try {
        const response = await axios.post('/auth/login', credentials);
        await checkAuthStatus();
        return {
          success: true,
          requireMfa: response.data.requireMfa,
          email: credentials.email,
        };
      } catch (error: any) {
        console.error('Login error:', error);
        return {
          success: false,
          error: error.response?.data?.message || 'Invalid credentials',
        };
      }
    },
    [checkAuthStatus]
  );

  const logout = useCallback(async () => {
    try {
      await axios.post('/auth/logout');

      updateAuthState({
        status: 'unauthenticated',
        isAuthenticated: false,
        isAccessingOrg: false,
        isAccessingPerson: false,
        apiUrl: apiUrl,
      });
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      // Still cleanup state and redirect even if the request fails
      updateAuthState({
        status: 'unauthenticated',
        isAuthenticated: false,
        isAccessingOrg: false,
        isAccessingPerson: false,
        apiUrl: apiUrl,
      });
      navigate('/login', { replace: true });
    }
  }, [navigate, updateAuthState]);

  useEffect(() => {
    let mounted = true;

    if (apiUrl) {
      createAxiosInstance(apiUrl);

      // Only check auth status if not on a public path
      if (!isPublicPath) {
        checkAuthStatus()
          .then(() => {
            if (!mounted) return;
            const hasOrgToken = document.cookie.includes('org_token');
            const hasPersonToken = document.cookie.includes('person_token');

            if (hasOrgToken || hasPersonToken) {
              updateAuthState({
                isAccessingOrg: hasOrgToken,
                isAccessingPerson: hasPersonToken,
              });
            }
          })
          .catch(console.error);
      } else {
        // For public paths, set an appropriate state
        updateAuthState({
          status: 'unauthenticated',
          isAuthenticated: false,
          isAccessingOrg: false,
          isAccessingPerson: false
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [apiUrl, checkAuthStatus, isPublicPath]);

  const contextValue = {
    authState,
    updateAuthState,
    checkAuthStatus,
    logout,
    login,
    revokeToken,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }
  return context;
};
