import { useAuthContext } from '@btrway/auth-core';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthenticatedRouteProps {
  children: ReactNode;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  children,
}) => {
  const { currentUser } = useAuthenticatedUser();
  const { authState } = useAuthContext();
  const location = useLocation();
  const [isRouteSettled, setIsRouteSettled] = useState(false);

  // Determine the correct base path for redirection
  const getRedirectBasePath = () => {
    const isInternal = currentUser.user.internal;
    const isImpersonating =
      authState.isAccessingPerson || authState.isAccessingOrg;

    if (isInternal && !isImpersonating) {
      return '/app-internal'; // Internal users not impersonating
    }
    return '/app'; // External users or impersonating users
  };

  const shouldRedirect = () => {
    const basePath = getRedirectBasePath();
    const currentBasePath = location.pathname.startsWith('/app-internal')
      ? '/app-internal'
      : '/app';

    // Redirect if the current base path does not match the expected base path
    if (currentBasePath !== basePath) {
      return basePath;
    }
    return null;
  };

  useEffect(() => {
    const redirectPath = shouldRedirect();
    if (!redirectPath) {
      setIsRouteSettled(true);
    }
  }, [
    location.pathname,
    currentUser.user.internal,
    authState.isAccessingPerson,
    authState.isAccessingOrg,
  ]);

  const redirectPath = shouldRedirect();
  if (redirectPath) {
    // Redirect to the new base path, replacing the current URL completely
    return <Navigate to={redirectPath} replace />;
  }

  // Render children once the route is settled
  return isRouteSettled ? <>{children}</> : null;
};
