import { ChapterDTO } from '@btrway/api-courseware';
import React from 'react';
import { useIncrementalSave } from '../../hooks/useIncrementalSave';

export interface IncrementalProgressSaverProps {
  chapter: ChapterDTO;
  frequencySeconds: number;
}

export const IncrementalProgressSaver: React.FC<
  IncrementalProgressSaverProps
> = ({ chapter, frequencySeconds }) => {
  if (!chapter) {
    throw new Error('Non-null chapter required in IncrementalSave component');
  }

  const { savedCurrentTimeRef, savedMaxTimeRef } = useIncrementalSave(
    chapter,
    frequencySeconds
  );

  return (
    <>
      {/* <div style={{ backgroundColor: 'yellow' }}>
        {chapter.title} Current Time: {savedCurrentTimeRef.current}, Max Time:{' '}
        {savedMaxTimeRef.current}
      </div> */}
    </>
  );
};
