import { useAuthenticatedUser } from '@btrway/current-user';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface WorkgroupRedirectProps {
  path: string;
}

export const WorkgroupRedirect: FC<WorkgroupRedirectProps> = ({ path }) => {
  const { currentUser } = useAuthenticatedUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.rootWorkgroupId) {
      navigate(`/app/${path}/${currentUser.rootWorkgroupId}`, {
        replace: true,
      });
    }
  }, [currentUser.rootWorkgroupId, navigate, path]);

  return null;
};
