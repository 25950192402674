import { useGetAssets } from '@btrway/api-courseware';
import { useAddHelpTags } from '@btrway/help-tag-manager';
import { getFormattedDuration } from '@btrway/utils';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  SegmentedControl,
  Stack,
  Text,
} from '@mantine/core';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMuxAssets } from '../../hooks/useMuxAssets';

interface MuxAssetListProps {
  onSelect?: (playbackId: string, duration: number) => void;
  isSelector?: boolean;
}

type FilterType = 'all' | 'unused';
type DurationFilterType =
  | 'all'
  | '<1min'
  | '1min'
  | '2min'
  | '3min'
  | '4min'
  | '5min+';
type SizeType = 'small' | 'large';

export const MuxAssetList: React.FC<MuxAssetListProps> = ({
  onSelect,
  isSelector = false,
}) => {
  useAddHelpTags(['video', 'mux']);

  const [filter, setFilter] = useState<FilterType>(
    isSelector ? 'unused' : 'all'
  );
  const [durationFilter, setDurationFilter] =
    useState<DurationFilterType>('all');
  const [size, setSize] = useState<SizeType>(isSelector ? 'large' : 'small');
  const { assets, isLoading, error, refetch, deleteAsset, isDeletingAsset } =
    useMuxAssets();
  const {
    data: courses,
    isLoading: coursesLoading,
    error: coursesError,
  } = useGetAssets();

  const getAssetUsage = useMemo(() => {
    return (assetId: string) => {
      const usage: {
        courseId: number;
        courseTitle: string;
        chapterTitle: string;
      }[] = [];
      courses?.forEach((course) => {
        course.chapters?.forEach((chapter) => {
          if (chapter.videoPlaybackIdentifier === assetId) {
            usage.push({
              courseId: course.id,
              courseTitle: course.title,
              chapterTitle: chapter.title,
            });
          }
        });
      });
      return usage;
    };
  }, [courses]);

  const filteredAssets = useMemo(() => {
    if (!assets) return [];
    let filtered = assets;

    if (filter === 'unused') {
      filtered = filtered.filter(
        (asset) => getAssetUsage(asset.playback_ids[0]?.id).length === 0
      );
    }

    filtered = filtered.filter((asset) => {
      const duration = asset.duration || 0;
      switch (durationFilter) {
        case '<1min':
          return duration < 60;
        case '1min':
          return duration >= 60 && duration < 120;
        case '2min':
          return duration >= 120 && duration < 180;
        case '3min':
          return duration >= 180 && duration < 240;
        case '4min':
          return duration >= 240 && duration < 300;
        case '5min+':
          return duration >= 300;
        default:
          return true;
      }
    });

    return filtered.sort((a, b) => {
      const durationA = a.duration || 0;
      const durationB = b.duration || 0;
      return durationA - durationB;
    });
  }, [assets, filter, durationFilter, getAssetUsage]);

  const handleFilterChange = (value: string) => {
    setFilter(value as FilterType);
  };

  const handleDurationFilterChange = (value: string) => {
    setDurationFilter(value as DurationFilterType);
  };

  const handleSizeChange = (value: string) => {
    setSize(value as SizeType);
  };

  const handleDeleteAsset = (assetId: string) => {
    if (confirm('Are you sure you want to delete this asset?')) {
      deleteAsset(assetId);
    }
  };

  const handleRefresh = () => {
    refetch();
  };

  if (isLoading || coursesLoading) {
    return <Text>Loading...</Text>;
  }

  if (error || coursesError) {
    return <Text color="red">Error loading data</Text>;
  }

  return (
    <Stack gap="md">
      <Group justify="apart" mb="md">
        <Button
          leftSection={<IconRefresh size={14} />}
          onClick={handleRefresh}
          loading={isLoading}
        >
          Refresh
        </Button>
      </Group>
      <Group>
        <SegmentedControl
          value={filter}
          onChange={handleFilterChange}
          data={[
            { label: 'All', value: 'all' },
            { label: 'Unused', value: 'unused' },
          ]}
        />
        <SegmentedControl
          value={durationFilter}
          onChange={handleDurationFilterChange}
          data={[
            { label: 'All', value: 'all' },
            { label: '< 1min', value: '<1min' },
            { label: '1min', value: '1min' },
            { label: '2min', value: '2min' },
            { label: '3min', value: '3min' },
            { label: '4min', value: '4min' },
            { label: '5min+', value: '5min+' },
          ]}
        />
        <SegmentedControl
          value={size}
          onChange={handleSizeChange}
          data={[
            { label: 'Small', value: 'small' },
            { label: 'Large', value: 'large' },
          ]}
        />
      </Group>
      {filteredAssets.map((asset) => (
        <Box key={asset.id} mb="lg">
          <Group
            align="flex-start"
            gap="md"
            style={{ flexWrap: size === 'large' ? 'wrap' : 'nowrap' }}
          >
            <Box
              style={{
                width: size === 'large' ? '100%' : '300px',
                position: 'relative',
              }}
            >
              <MuxPlayer
                streamType="on-demand"
                playbackId={asset.playback_ids[0]?.id}
                metadataVideoTitle={`Asset ${asset.id}`}
                primaryColor="#FFFFFF"
                secondaryColor="#000000"
                theme="microvideo"
                style={{ width: '100%', height: size === 'large' ? 300 : 150 }}
              />
              <ActionIcon
                color="red"
                variant="subtle"
                onClick={() => handleDeleteAsset(asset.id)}
                style={{ position: 'absolute', top: 5, right: 5 }}
                loading={isDeletingAsset}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </Box>
            <Box style={{ flexGrow: 1 }}>
              <Text>
                <strong>Asset ID:</strong> {asset.id}
              </Text>
              <Text>
                <strong>Playback ID:</strong> {asset.playback_ids[0]?.id}
              </Text>
              <Text>
                <strong>Duration:</strong>{' '}
                {asset.duration != null
                  ? getFormattedDuration(asset.duration)
                  : 'N/A'}
              </Text>
              <Text>
                <strong>Created At:</strong>{' '}
                {new Date(parseInt(asset.created_at) * 1000).toLocaleString()}
              </Text>
              <Text>
                <strong>Aspect Ratio:</strong> {asset.aspect_ratio || 'N/A'}
              </Text>
              <Text mt="sm">
                <strong>Used in:</strong>
              </Text>
              {getAssetUsage(asset.playback_ids[0]?.id).map((usage, index) => (
                <Text key={index} ml="md">
                  Course:{' '}
                  {isSelector ? (
                    usage.courseTitle
                  ) : (
                    <Link
                      to={`/app-internal/courseware/courses/${usage.courseId}`}
                    >
                      {usage.courseTitle}
                    </Link>
                  )}
                  , Chapter: {usage.chapterTitle}
                </Text>
              ))}
              {isSelector && onSelect && (
                <Button
                  onClick={() =>
                    onSelect(asset.playback_ids[0]?.id, asset.duration || 0)
                  }
                  mt="sm"
                >
                  Select This Video
                </Button>
              )}
            </Box>
          </Group>
        </Box>
      ))}
    </Stack>
  );
};
