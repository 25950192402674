import { FormReview } from '@btrway/form-review';
import { useTaskManager } from '@btrway/task-manager';
import { Box, LoadingOverlay } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';

interface TaskCompletionWrapperProps {
  onClose: () => void;
}

const TaskCompletionWrapper: React.FC<TaskCompletionWrapperProps> = ({
  onClose,
}) => {
  const { assignedTasks } = useTaskManager();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (assignedTasks.length === 0) {
      // Delay closing to allow any animations to complete
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [assignedTasks]);

  const handleTaskComplete = useCallback((taskInstanceId: number) => {
    // removeTask(taskInstanceId);
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        onClose();
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isVisible, onClose]);

  const currentTask = assignedTasks[0];

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {currentTask ? (
        <FormReview
          key={currentTask.taskInstanceId}
          taskInstanceId={currentTask.taskInstanceId}
          isOpen={true}
          onClose={handleClose}
          onTaskComplete={handleTaskComplete}
        />
      ) : (
        <LoadingOverlay visible={true} />
      )}
    </Box>
  );
};

export default TaskCompletionWrapper;
