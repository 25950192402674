import { getGetCurrentUserQueryKey, PublicRegistrationFormResponse } from '@btrway/api-core';
import { NewUserRequest, useAuth, useAuthContext } from '@btrway/auth-core';
import {
  Button,
  Grid,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { publicRegistrationFormAtom } from '../../atoms/publicRegistrationFormAtom';
import { workgroupIdAtom } from '../../atoms/registrationAtoms';
import { useRegistrationAuth } from '../../hooks/useRegistrationAuth';
import { createAxiosInstance, useAPIClient } from '@btrway/api-client-provider';
import { useCurrentUser } from '@btrway/current-user';

interface SignUpFormProps {
  onSuccess: (userData: any) => void;
  onBackToLogin: () => void;
}

interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  mobilePhone: string;
}

export function SignUpForm({ onSuccess, onBackToLogin }: SignUpFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { registrationCreateNewUser, clearTempToken } = useRegistrationAuth();
  const { checkAuthStatus } = useAuthContext();
  const { queryClient } = useAPIClient();
  const { refetchCurrentUser } = useCurrentUser();
  const [workgroupId] = useAtom(workgroupIdAtom);
  const [publicFormRegistration] =
    useAtom<PublicRegistrationFormResponse | null>(publicRegistrationFormAtom);

  const form = useForm<SignUpFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      mobilePhone: '',
    },
    validate: {
      firstName: (value) => (!value ? 'First name is required' : null),
      lastName: (value) => (!value ? 'Last name is required' : null),
      email: (value) => {
        if (!value) return 'Email is required';
        if (!/^\S+@\S+$/.test(value)) return 'Invalid email';
        return null;
      },
      mobilePhone: (value) => {
        if (!value) return 'Phone number is required';
        if (value.replace(/\D/g, '').length !== 10)
          return 'Invalid phone number';
        return null;
      },
      password: (value) => {
        if (!value) return 'Password is required';
        if (value.length < 8) return 'Password must be at least 8 characters';
        return null;
      },
      confirmPassword: (value, values) =>
        value !== values.password ? 'Passwords do not match' : null,
    },
  });

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(event.currentTarget.value);
    form.setFieldValue('mobilePhone', formatted);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!form.isValid()) {
      form.validate();
      return;
    }

    setIsSubmitting(true);
    try {
      const request: NewUserRequest = {
        ...form.values,
        workgroupId: workgroupId || 0,
        userRoleId: publicFormRegistration?.registrationFormConfiguration
          ?.registrantUserRoleId || 0,
        orgId: null,
      };

      const response = await registrationCreateNewUser(request);

      if (response.success) {
        await clearTempToken();
        // Wait for auth state to update
        // await checkAuthStatus();
        
        // Invalidate and refetch current user
        await queryClient.invalidateQueries({ 
          queryKey: getGetCurrentUserQueryKey() 
        });
        
        const { data: freshUserData } = await refetchCurrentUser();

        if (freshUserData) {
          // Ensure axios instance is updated with new cookies          
          onSuccess({
            firstName: freshUserData.user.firstName,
            lastName: freshUserData.user.lastName,
            email: freshUserData.user.email,
            phoneNumber: freshUserData.user.mobilePhone,
          });
        } else {
          setError('Unable to retrieve user data');
        }
      } else {
        setError(response.error || 'Failed to create account');
      }
    } catch (error) {
      console.error('Error creating new user:', error);
      setError('An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack gap="xl">
      <form onSubmit={handleSubmit}>
        <Stack gap="lg">
          <Grid gutter="md">
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                placeholder="Your first name"
                required
                {...form.getInputProps('firstName')}
                size="md"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                placeholder="Your last name"
                required
                {...form.getInputProps('lastName')}
                size="md"
              />
            </Grid.Col>
          </Grid>

          <Grid gutter="md">
            <Grid.Col span={6}>
              <TextInput
                label="Email"
                placeholder="your@email.com"
                required
                {...form.getInputProps('email')}
                size="md"
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Phone Number"
                placeholder="(123)-456-7890"
                required
                value={form.values.mobilePhone}
                onChange={handlePhoneChange}
                error={form.errors.mobilePhone}
                maxLength={14}
                size="md"
              />
            </Grid.Col>
          </Grid>

          <Grid gutter="md">
            <Grid.Col span={12}>
              <PasswordInput
                label="Password"
                placeholder="Create password"
                required
                {...form.getInputProps('password')}
                size="md"
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <PasswordInput
                label="Confirm Password"
                placeholder="Confirm password"
                required
                {...form.getInputProps('confirmPassword')}
                size="md"
              />
            </Grid.Col>
          </Grid>

          {error && (
            <Text color="red" size="sm">
              {error}
            </Text>
          )}

          <Group justify="space-between">
            <Button variant="subtle" onClick={onBackToLogin}>
              Back to Login
            </Button>
            <Button type="submit" size="lg">
              Create Account
            </Button>
          </Group>
        </Stack>
      </form>
    </Stack>
  );
}
