import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { TextSelection } from 'prosemirror-state';
import { DynamicContentComponent } from '../components/DynamicContentComponent/DynamicContentComponent';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dynamicContent: {
      setDynamicContent: (options: {
        id: string;
        type: string;
        placeholder: string;
      }) => ReturnType;
    };
  }
}

export const DynamicContent = Node.create({
  name: 'dynamicContent',
  priority: 100,
  group: 'block',
  atom: true,
  selectable: true,
  draggable: true,
  defining: true,
  isolating: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      contentId: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-content-id'),
        renderHTML: (attributes) => ({
          'data-content-id': attributes.contentId,
        }),
      },
      contentType: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-content-type'),
        renderHTML: (attributes) => ({
          'data-content-type': attributes.contentType,
        }),
      },
      placeholder: {
        default: 'Dynamic Content Placeholder',
        parseHTML: (element) => element.getAttribute('data-placeholder'),
        renderHTML: (attributes) => ({
          'data-placeholder': attributes.placeholder,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="dynamic-content"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': 'dynamic-content',
        contenteditable: 'false',
      }),
    ];
  },

  addCommands() {
    return {
      setDynamicContent:
        (options) =>
        ({ tr, chain, editor }) => {
          const dynamicNode = editor.schema.nodes[this.name].create({
            contentId: options.id,
            contentType: options.type,
            placeholder: options.placeholder,
          });

          const $pos = editor.state.selection.$from;
          const currentNode = $pos.node($pos.depth);
          const isEmpty =
            currentNode.type.name === 'paragraph' &&
            currentNode.content.size === 0;
          const paragraphNode = editor.schema.nodes.paragraph.create();

          return chain()
            .command(({ tr: trInner, dispatch }) => {
              if (!dispatch) return true;

              let insertPos = $pos.pos;
              let newSelectionPos;

              // Check if previous node is a dynamic content
              const previousNode = $pos
                .node($pos.depth - 1)
                .maybeChild($pos.index($pos.depth - 1) - 1);
              const isPreviousDynamicContent =
                previousNode?.type.name === 'dynamicContent';

              if (isEmpty) {
                if (isPreviousDynamicContent) {
                  // Case 3: Empty paragraph after dynamic content
                  // Insert: paragraph -> dynamic content -> keep current paragraph
                  insertPos = $pos.before();
                  trInner.insert(insertPos, paragraphNode);
                  trInner.insert(insertPos + 1, dynamicNode);
                  newSelectionPos = insertPos + dynamicNode.nodeSize + 2;
                } else {
                  // Case 2: Empty paragraph
                  // Insert: dynamic content -> keep current paragraph
                  insertPos = $pos.before();
                  trInner.insert(insertPos, dynamicNode);
                  newSelectionPos = insertPos + dynamicNode.nodeSize + 1;
                }
              } else {
                // Case 1: Non-empty paragraph
                // Insert: after current -> dynamic content -> new paragraph
                insertPos = $pos.after($pos.depth);
                trInner.insert(insertPos, dynamicNode);
                trInner.insert(insertPos + dynamicNode.nodeSize, paragraphNode);
                newSelectionPos = insertPos + dynamicNode.nodeSize + 1;
              }

              // Ensure selection position is within bounds
              const docSize = trInner.doc.content.size;
              newSelectionPos = Math.min(newSelectionPos, docSize - 1);

              // Set selection to the new paragraph
              try {
                const selection = TextSelection.create(
                  trInner.doc,
                  newSelectionPos
                );
                trInner.setSelection(selection);
                trInner.scrollIntoView();
              } catch (e) {
                console.error('Error setting selection:', e);
                // Fallback to a safe position
                const $fallbackPos = trInner.doc.resolve(insertPos);
                const textSelection = TextSelection.between(
                  $fallbackPos,
                  $fallbackPos
                );
                trInner.setSelection(textSelection);
                trInner.scrollIntoView();
              }

              return true;
            })
            .focus()
            .run();
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(DynamicContentComponent);
  },
});
