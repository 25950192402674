import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, ModuleRegistry } from '@ag-grid-community/core';

import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { OrganizationResponse, useGetOrganizations } from '@btrway/api-core';
import { GridCardView } from '@btrway/grid-components';
import { InfoCard } from '@btrway/shared-components';
import { Space } from '@mantine/core';
import React, { useMemo } from 'react';
import OrganizationCard from '../../components/OrganizationCard/OrganizationCard';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  MenuModule,
  ColumnsToolPanelModule,
]);

const OrganizationTemplatesView: React.FC = () => {
  const { data: organizations, refetch: refetchOrganizations } =
    useGetOrganizations();

  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
      sortable: true,
      flex: 1,
    },
    { headerName: 'Type', field: 'organizationType.name' },
    // {
    //   headerName: 'Actions',
    //   field: 'id',
    //   cellRenderer: (params: any) => (
    //     <OrgAccessButton organizationId={params.value} />
    //   ),
    //   flex: 1,
    // },
  ];

  const getNavigationPath = (organization: OrganizationResponse) => '';

  // Filter and sort organizations
  const filteredAndSortedOrganizations = useMemo(() => {
    if (!organizations) return [];
    return organizations
      .filter((org) => org.template)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [organizations]);

  if (!organizations) {
    return null;
  }

  return (
    <>
      <InfoCard
        text="Organization presets are used to create new organizations with predefined settings. Currently supports Workgroup Types and User Roles."
        bg="gray.1"
      />
      <Space h={20} />
      <GridCardView
        data={filteredAndSortedOrganizations}
        gridCols={columnDefs}
        cardComponent={OrganizationCard}
        defaultView="grid"
        getNavigationPath={getNavigationPath}
      />
    </>
  );
};

export default OrganizationTemplatesView;
