import {
  StepCompletionDTO,
  StepConfig,
  WorkflowDefinitionResponse,
} from '@btrway/api-workflow';
import { SurveyJsWrapper, SurveyJsWrapperHandle } from '@btrway/form-surveyjs';
import { useStyledModal } from '@btrway/styled-modal';
import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { IconClick } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';

interface FormStepProps {
  workflowDefinition: WorkflowDefinitionResponse;
  currentStep: StepConfig;
  prefilledData: any;
  onSubmit: (stepData: StepCompletionDTO) => void;
  onCancel: () => void;
  onSaveForLater: () => void;
}

const FormStep: React.FC<FormStepProps> = ({
  workflowDefinition,
  currentStep,
  prefilledData,
  onSubmit,
  onCancel,
  onSaveForLater,
}) => {
  const { setModalTitle } = useStyledModal();
  const surveyRef = useRef<SurveyJsWrapperHandle>(null);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setModalTitle(
      <Text fz="xl" fw={700}>
        {workflowDefinition.name}
      </Text>
    );

    return () => {
      setModalTitle(undefined);
    };
  }, [workflowDefinition.name, setModalTitle]);

  const handleSubmit = () => {
    if (surveyRef.current) {
      const survey = surveyRef.current.getSurveyModel();
      if (survey) {
        const isValid = survey.validate();
        if (isValid) {
          const stepData: StepCompletionDTO = {
            stepKey: currentStep.stepKey,
            values: survey.data,
          };
          onSubmit(stepData);
        } else {
          console.log('Please fill out all required fields before submitting.');
        }
      }
    }
  };
  return (
    <Stack bg="gray.1" style={{ width: '100%', height: '100%' }} gap={0}>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          // marginLeft: '60px',
          // marginRight: '60px',
          // borderRadius: 'var(--mantine-radius-xl)',
        }}
      >
        <SurveyJsWrapper
          ref={surveyRef}
          formConfig={currentStep.formConfig!}
          prefilledData={prefilledData}
          hideCompleteButton={true}
        />
      </Box>
      <Group
        justify="space-between"
        bg="white"
        p="md"
        gap="md"
        style={
          {
            // height: '60px',
            // marginTop: 'var(--mantine-spacing-md)',
            // marginBottom: 'var(--mantine-spacing-md)',
          }
        }
      >
        <Button onClick={onCancel} size="lg" variant="outline">
          <Text fz={24} fw={600}>
            Cancel
          </Text>
        </Button>
        <Group justify="flex-end" gap="md">
          <Button onClick={onSaveForLater} size="lg" variant="outline">
            <Text fz={24} fw={600}>
              Save for Later
            </Text>
          </Button>
          {/* <IconDotsVertical size={36} /> */}
          <Button
            onClick={handleSubmit}
            color="green.8"
            size="lg"
            leftSection={<IconClick size={30} />}
          >
            <Text fz={24} fw={600}>
              Submit Form
            </Text>
          </Button>
        </Group>
      </Group>
    </Stack>
  );
};

export default FormStep;
