import {
  PersonResponse,
  RelationshipRequest,
  useGetRelationshipTypes,
  useSaveRelationship,
} from '@btrway/api-core';
import { GlobalSearchControl } from '@btrway/global-search';
import { Button, Modal, Select, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';

interface AddRelationshipModalProps {
  person: PersonResponse;
  onRelationshipAdded: () => void;
}

const AddRelationshipModal: React.FC<AddRelationshipModalProps> = ({
  person,
  onRelationshipAdded,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedPersonId, setSelectedPersonId] = useState<number | null>(null);
  const [relationshipTypeId, setRelationshipTypeId] = useState<string>('');
  const { mutateAsync: addRelationship } = useSaveRelationship();
  const { data: relationshipTypes } = useGetRelationshipTypes();

  const handleAddRelationship = async () => {
    if (selectedPersonId && relationshipTypeId) {
      const newRelationship: RelationshipRequest = {
        fromPersonId: person.id,
        toPersonId: selectedPersonId,
        organizationId: person.organizationId,
        relationshipTypeId: Number.parseInt(relationshipTypeId, 10),
      };

      console.log('newRelationship', newRelationship);

      try {
        await addRelationship({ data: newRelationship });
        onRelationshipAdded();
        close();
        setSelectedPersonId(null);
        setRelationshipTypeId('');
      } catch (error) {
        console.error('Failed to add relationship:', error);
        // Handle error (e.g., show error message to user)
      }
    }
  };

  return (
    <>
      <Button onClick={open}>Add Relationship</Button>

      <Modal opened={opened} onClose={close} title="Add New Relationship">
        <Stack>
          <GlobalSearchControl
            onSelect={(personId) => setSelectedPersonId(personId)}
            w={300}
          />
          <Select
            label="Relationship Type"
            placeholder="Select relationship type"
            data={
              relationshipTypes?.map((type) => ({
                value: type.id.toString(),
                label: type.name,
              })) || []
            }
            value={relationshipTypeId}
            onChange={(value) => setRelationshipTypeId(value || '')}
          />
          <Button
            onClick={handleAddRelationship}
            disabled={!selectedPersonId || !relationshipTypeId}
          >
            Add Relationship
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default AddRelationshipModal;
