// import { Node, mergeAttributes } from '@tiptap/core';
// import { ReactNodeViewRenderer } from '@tiptap/react';
// import { MergeTokenComponent } from '../components/MergeTokenComponent/MergeTokenComponent';
// import { AVAILABLE_TOKENS } from '../config/mailMergeTokens';

// declare module '@tiptap/core' {
//   interface Commands<ReturnType> {
//     mergeToken: {
//       setMergeToken: (options: {
//         type: string;
//         fieldName: string;
//         fieldId: string;
//       }) => ReturnType;
//     };
//   }
// }

// export const MergeTokenNode = Node.create({
//   name: 'mergeToken',
//   group: 'inline',
//   inline: true,
//   atom: true,
//   draggable: false,
//   selectable: false,
//   defining: true,

//   addOptions() {
//     return {
//       HTMLAttributes: {},
//     };
//   },

//   addAttributes() {
//     return {
//       tokenType: {
//         default: 'field',
//       },
//       fieldName: {
//         default: null,
//       },
//       fieldId: {
//         default: null,
//       },
//     };
//   },

//   parseHTML() {
//     return [
//       {
//         tag: 'span[data-type="merge-token"]',
//       },
//     ];
//   },

//   renderHTML({ HTMLAttributes }) {
//     return [
//       'span',
//       mergeAttributes(
//         this.options.HTMLAttributes,
//         { 'data-type': 'merge-token' },
//         HTMLAttributes
//       ),
//       `{{${HTMLAttributes.fieldId}}}`,
//     ];
//   },

//   addKeyboardShortcuts() {
//     return {
//       ArrowLeft: () => {
//         const { selection } = this.editor.state;
//         if (selection.empty) {
//           const pos = selection.$from.pos;
//           const node = this.editor.state.doc.nodeAt(pos - 1);
//           if (node?.type.name === 'mergeToken') {
//             this.editor.commands.setTextSelection(pos - 1);
//             return true;
//           }
//         }
//         return false;
//       },
//       ArrowRight: () => {
//         const { selection } = this.editor.state;
//         if (selection.empty) {
//           const pos = selection.$from.pos;
//           const node = this.editor.state.doc.nodeAt(pos);
//           if (node?.type.name === 'mergeToken') {
//             this.editor.commands.setTextSelection(pos + 1);
//             return true;
//           }
//         }
//         return false;
//       },
//     };
//   },

//   addCommands() {
//     return {
//       setMergeToken:
//         (options) =>
//         ({ commands }) => {
//           const token = AVAILABLE_TOKENS.find(
//             (t) => t.name === options.fieldName
//           );
//           return commands.insertContent({
//             type: this.name,
//             attrs: {
//               tokenType: options.type,
//               fieldName: options.fieldName,
//               fieldId: token?.fieldId || '',
//             },
//           });
//         },
//     };
//   },

//   addNodeView() {
//     return ReactNodeViewRenderer(MergeTokenComponent, {
//       as: 'span',
//     });
//   },
// });

import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { MergeTokenComponent } from '../components/MergeTokenComponent/MergeTokenComponent';
import { AVAILABLE_TOKENS } from '../config/mailMergeTokens';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mergeToken: {
      setMergeToken: (options: {
        type: string;
        fieldName: string;
        fieldId: string;
      }) => ReturnType;
    };
  }
}

export const MergeTokenNode = Node.create({
  name: 'mergeToken',
  group: 'inline',
  inline: true,
  atom: true,
  draggable: false,
  selectable: true,
  defining: true,
  marks: '_', // Allow all marks

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      tokenType: {
        default: 'field',
      },
      fieldName: {
        default: null,
      },
      fieldId: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="merge-token"]',
        getAttrs: (element) => {
          if (typeof element === 'string') return {};
          return {
            'data-type': 'merge-token',
            tokenType: element.getAttribute('data-token-type'),
            fieldName: element.getAttribute('data-field-name'),
            fieldId: element.getAttribute('data-field-id'),
          };
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const attrs = mergeAttributes(this.options.HTMLAttributes, {
      'data-type': 'merge-token',
      'data-token-type': HTMLAttributes.tokenType,
      'data-field-name': HTMLAttributes.fieldName,
      'data-field-id': HTMLAttributes.fieldId,
    });

    return ['span', attrs, `{{${HTMLAttributes.fieldId}}}`];
  },

  addKeyboardShortcuts() {
    return {
      ArrowLeft: () => {
        const { selection } = this.editor.state;
        if (selection.empty) {
          const pos = selection.$from.pos;
          const node = this.editor.state.doc.nodeAt(pos - 1);
          if (node?.type.name === 'mergeToken') {
            this.editor.commands.setTextSelection(pos - 1);
            return true;
          }
        }
        return false;
      },
      ArrowRight: () => {
        const { selection } = this.editor.state;
        if (selection.empty) {
          const pos = selection.$from.pos;
          const node = this.editor.state.doc.nodeAt(pos);
          if (node?.type.name === 'mergeToken') {
            this.editor.commands.setTextSelection(pos + 1);
            return true;
          }
        }
        return false;
      },
    };
  },

  addCommands() {
    return {
      setMergeToken:
        (options) =>
        ({ commands }) => {
          const token = AVAILABLE_TOKENS.find(
            (t) => t.name === options.fieldName
          );
          return commands.insertContent({
            type: this.name,
            attrs: {
              tokenType: options.type,
              fieldName: options.fieldName,
              fieldId: token?.fieldId || '',
            },
          });
        },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(MergeTokenComponent, {
      as: 'span',
    });
  },
});
