import { EventRequest } from '@btrway/api-calendar';
import { parseRecurrenceRule } from '@btrway/calendar-recurrence';
import { ActionIcon, Badge, Group, Stack, Text } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { useMemo } from 'react';

interface FacilityAvailabilityViewProps {
  event: EventRequest;
  onEdit: () => void;
  onDelete: () => void;
}

const weekDays = [
  { value: 'MO', label: 'Mon' },
  { value: 'TU', label: 'Tue' },
  { value: 'WE', label: 'Wed' },
  { value: 'TH', label: 'Thu' },
  { value: 'FR', label: 'Fri' },
  { value: 'SA', label: 'Sat' },
  { value: 'SU', label: 'Sun' },
];

export const FacilityAvailabilityView = ({
  event,
  onEdit,
  onDelete,
}: FacilityAvailabilityViewProps) => {
  // Parse the recurrence rule to get selected days
  const selectedDays = useMemo(() => {
    if (!event.recurrenceRule) return [];
    const parsedRule = parseRecurrenceRule(event.recurrenceRule);
    return parsedRule.weekDays || [];
  }, [event.recurrenceRule]);

  const handleEdit = () => {
    onEdit();
  };

  const handleDelete = () => {
    onDelete();
  };

  // Format dates and times for display
  const startDate = new Date(event.startTime);
  const endDate = new Date(event.endTime);

  const timeDisplay = `${startDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })} - ${endDate.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })}`;

  const dateDisplay = useMemo(() => {
    const recurrence = parseRecurrenceRule(event.recurrenceRule);
    const start = startDate.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    if (!recurrence.endDate) return `From ${start}`;

    const end = recurrence.endDate.toLocaleDateString([], {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return `${start} - ${end}`;
  }, [event.recurrenceRule, startDate]);

  return (
    <Stack gap="xs">
      <Group justify="space-between" align="center">
        <Group gap="xs">
          {weekDays.map((day) => {
            const isSelected = selectedDays.includes(day.value);
            return (
              <Badge
                key={day.value}
                radius="xl"
                size="sm"
                variant={isSelected ? 'filled' : 'light'}
                color={isSelected ? 'blue' : 'gray'}
              >
                {day.label}
              </Badge>
            );
          })}
        </Group>
        <Group gap="xs">
          <ActionIcon variant="subtle" onClick={handleEdit}>
            <IconEdit size={16} />
          </ActionIcon>
          <ActionIcon variant="subtle" color="red" onClick={handleDelete}>
            <IconTrash size={16} />
          </ActionIcon>
        </Group>
      </Group>

      <Text size="sm" fw={500}>
        {timeDisplay}
      </Text>

      <Text size="sm" c="dimmed">
        {dateDisplay}
      </Text>
    </Stack>
  );
};
