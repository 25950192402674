import { EventTypeEnum } from '@btrway/api-workflow';
import {
  IconAlignBoxLeftTop,
  IconBuilding,
  IconCalendar,
  IconFriends,
  IconMail,
  IconMoodSmile,
  IconPlayerPlay,
  IconProgressCheck,
  IconProps,
  IconTimeDuration0,
  IconUserCheck,
  IconUserShare,
  IconUsersPlus,
} from '@tabler/icons-react';
import React from 'react';

const iconMap: Record<EventTypeEnum, React.ComponentType<IconProps>> = {
  [EventTypeEnum.formSubmitted]: IconAlignBoxLeftTop,
  [EventTypeEnum.personCreated]: IconUsersPlus,
  [EventTypeEnum.courseCompleted]: IconPlayerPlay,
  [EventTypeEnum.personUpdated]: IconUserCheck,
  [EventTypeEnum.workgroupMemberCreated]: IconUsersPlus,
  [EventTypeEnum.calendarEventCreated]: IconCalendar,
  [EventTypeEnum.calendarEventUpdated]: IconCalendar,
  [EventTypeEnum.facilityRequested]: IconBuilding,
  [EventTypeEnum.gameRescheduled]: IconTimeDuration0,
  [EventTypeEnum.registrationSubmitted]: IconUserShare,
  [EventTypeEnum.registrationProcessed]: IconProgressCheck,
  [EventTypeEnum.relationshipCreated]: IconFriends,
  [EventTypeEnum.messageSent]: IconMail,
  [EventTypeEnum.taskCompleted]: IconProgressCheck,
  [EventTypeEnum.certificationAssigned]: IconProgressCheck,
  [EventTypeEnum.certificationEvaluationRequested]: IconProgressCheck,
};

interface EventTypeIconProps {
  iconName: EventTypeEnum;
  color?: string;
  size?: string | number;
}

export const EventTypeIcon: React.FC<EventTypeIconProps> = ({
  iconName,
  color = 'currentColor',
  size = 24,
}) => {
  const IconComponent = iconMap[iconName] || IconMoodSmile;
  return <IconComponent color={color} size={size} />;
};
