import { Anchor, Box, Button, Group, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { useCallback, useEffect, useState } from 'react';
import { useStorageUpload } from '../../hooks/useStorageUpload';
import { getPresignedUrl, StorageUploadRequest, StorageUsageEnum } from '@btrway/api-storage';
import { useAuthenticatedUser } from '@btrway/current-user';

interface S3FileUploaderProps {
  onUploadComplete: (fileKey: string, fileName: string) => void;
  existingFileKey?: string;
  existingFileName?: string;
}

const S3FileUploader: React.FC<S3FileUploaderProps> = ({
  onUploadComplete,
  existingFileKey,
  existingFileName,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(existingFileName || null);
  const [fileKey, setFileKey] = useState<string | null>(existingFileKey || null);
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined);
  console.log(fileKey);
  console.log(uploadedFileName);

  const [error, setError] = useState<string | null>(null);
  const { currentUser, currentOrganization } = useAuthenticatedUser();
  
  const { storageUpload } = useStorageUpload();

  useEffect(() => {
    if(existingFileKey){
        getPresignedUrl(StorageUsageEnum.helparticles, {storageKey: existingFileKey}).then((data) => {
            setFileUrl(data as string);
        });
    };
  })

  const handleUpload = useCallback(async (files: File[]) => {
    if (files.length === 0) return;

    const file = files[0];
    setIsUploading(true);
    setError(null);

    try {
      const fileName = file.name;

      const uploadRequest: StorageUploadRequest = {
        fileNames: [fileName],
        organizationId: currentOrganization.id,
        storageUsageEnum: 'helparticles',
        userId: currentUser.user.id
      };

      const fileUploadData = [{
        file,
        contentType: file.type,
        fileName,
      }];

      const response = await storageUpload(uploadRequest, fileUploadData);
      
      if (response.storageKeys[0]) {
        setUploadedFileName(fileName);
        setFileKey(response.storageKeys[0]);
        setFileUrl(response.presignedUrls[0]);
        onUploadComplete(response.storageKeys[0], fileName);
      } else {
        throw new Error('Upload response missing file information');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Upload failed');
      console.error('Upload error:', err);
    } finally {
      setIsUploading(false);
    }
  }, [storageUpload, onUploadComplete]);

  return (
    <Box>
      {!uploadedFileName ? (
        <Dropzone
          onDrop={handleUpload}
          maxSize={50 * 1024 * 1024} // 50MB
          accept={['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain']}
          disabled={isUploading}
        >
          <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
            {isUploading ? (
              <Text>Uploading...</Text>
            ) : (
              <div>
                <Text size="xl" inline>
                  Drag a file here or click to select
                </Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  Upload any document (PDF, DOC, DOCX, TXT), max 50MB
                </Text>
              </div>
            )}
          </Group>
        </Dropzone>
      ) : (
        <Group>
          <Text>Uploaded file: <Anchor href={fileUrl} target='_blank'>{uploadedFileName}</Anchor></Text>
          <Button
            variant="subtle"
            color="red"
            onClick={() => {
              setUploadedFileName(null);
              onUploadComplete('', '');
            }}
          >
            Remove
          </Button>
        </Group>
      )}

      {error && (
        <Text c="red" size="sm" mt="xs">
          {error}
        </Text>
      )}
    </Box>
  );
};

export default S3FileUploader;