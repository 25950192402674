import { CourseExecutionPage } from '@btrway/courseware-library-components';
import { RouteConfig } from '@btrway/web-routing';
import { WorkflowDefinitionPage } from '@btrway/workflow-administration';

export const fullScreenRoutes: RouteConfig[] = [
  {
    path: 'workflowadmin/automations/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'workflowadmin/forms/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'workflowadmin/form-packets/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'workflowadmin/todo-lists/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'workflowadmin/curriculums/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'workflowadmin/certifications/:workflowKey',
    element: <WorkflowDefinitionPage />,
  },
  {
    path: 'education/course-viewer/:courseUid',
    element: <CourseExecutionPage />,
  },
];
