import {
  QueryFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { MuxAsset } from '../types/MuxAsset';

export const useMuxAssets = () => {
  const tokenId = import.meta.env.VITE_MUX_TOKEN_ID;
  const tokenSecret = import.meta.env.VITE_MUX_TOKEN_SECRET;
  const queryClient = useQueryClient();

  const fetchAssets: QueryFunction<MuxAsset[], [string, number]> = useCallback(
    async ({ queryKey }) => {
      const [_key, limit = 300] = queryKey;

      const response = await fetch(
        `https://api.mux.com/video/v1/assets?limit=${limit}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch Mux assets');
      }

      const data = await response.json();
      return data.data;
    },
    [tokenId, tokenSecret]
  );

  const {
    data: assets,
    isLoading,
    error,
    refetch,
  } = useQuery<MuxAsset[], Error, MuxAsset[], [string, number]>({
    queryKey: ['muxAssets', 100],
    queryFn: fetchAssets,
    refetchOnWindowFocus: false,
    staleTime: Infinity, // Prevent auto-refetching
  });

  const deleteAsset = useCallback(
    async (assetId: string): Promise<void> => {
      const response = await fetch(
        `https://api.mux.com/video/v1/assets/${assetId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${btoa(`${tokenId}:${tokenSecret}`)}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to delete asset');
      }
    },
    [tokenId, tokenSecret]
  );

  const deleteMutation = useMutation({
    mutationFn: deleteAsset,
    onSuccess: () => {
      // Invalidate and refetch the assets query after successful deletion
      queryClient.invalidateQueries({ queryKey: ['muxAssets'] });
    },
  });

  return {
    assets,
    isLoading,
    error,
    refetch,
    deleteAsset: deleteMutation.mutate,
    isDeletingAsset: deleteMutation.isPending,
    deleteError: deleteMutation.error,
  };
};
