import { StorageUsageEnum } from '@btrway/api-storage';
import { WorkflowDefinitionResponse } from '@btrway/api-workflow';
import { CompletionRing } from '@btrway/courseware-progress-components';
import { useImageUpload } from '@btrway/file-storage-components';
import { Card, Group, Image, Text } from '@mantine/core';
import React from 'react';
import classes from './CurriculumCard.module.css';

interface CurriculumCardProps {
  curriculum: WorkflowDefinitionResponse;
  onClick?: (curriculumId: number) => void;
}

const CurriculumCard: React.FC<CurriculumCardProps> = ({
  curriculum,
  onClick,
}) => {
  const imageKey = curriculum.workflowTemplate?.templateSettings?.imageKey;

  const { imageUrl } = useImageUpload({
    storageUsage: StorageUsageEnum.curriculum,
    initialImageKey: imageKey,
  });

  const handleClick = () => {
    if (onClick) {
      onClick(curriculum.id);
    }
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Card
        withBorder
        padding="lg"
        className={classes.card}
        style={{ width: 300, height: 400 }}
      >
        <Card.Section>
          <Image
            src={imageUrl}
            height={240}
            alt={curriculum.name}
            fallbackSrc="https://placehold.co/400x400?text=No+Curriculum+Image"
          />
        </Card.Section>

        <Group justify="space-between" mt="xl">
          <Text fz="sm" fw={700} className={classes.title}>
            {curriculum.name}
          </Text>
          <CompletionRing curriculum={curriculum} />
        </Group>
        <Text mt="sm" mb="md" c="dimmed" fz="xs">
          {curriculum.description}
        </Text>
        <Card.Section className={classes.footer}></Card.Section>
      </Card>
    </div>
  );
};

export default CurriculumCard;
