import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import {
  StepTypeEnum,
  TaskInstanceGridRequest,
  TaskInstanceGridRequestDirectEnum,
  TaskInstanceGridRequestStatusEnum,
  TaskInstanceGridRequestTaskListEnum,
  TaskInstanceGridResponse,
} from '@btrway/api-workflow';
import { useAuthenticatedUser } from '@btrway/current-user';
import { ResponsiveInfiniteGrid } from '@btrway/grid-components';
import { useTaskModal, useTaskQueries } from '@btrway/task-manager';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface TasksInfiniteGridProps {
  filterByWorkgroupIds?: number[];
  filterByWorkflowDefinitionIds?: number[];
  filterByPersonIds?: number[];
  filterByStepTypes?: StepTypeEnum[];
  statusOption: TaskInstanceGridRequestStatusEnum;
  taskListOption: TaskInstanceGridRequestTaskListEnum;
  directOption: TaskInstanceGridRequestDirectEnum;
  onSelectionChanged: (
    selectedCount: number,
    isAllSelected: boolean,
    totalRows: number
  ) => void;
}

export interface TasksInfiniteGridRef {
  clearSelection: () => void;
}

const TasksInfiniteGrid = forwardRef<
  TasksInfiniteGridRef,
  TasksInfiniteGridProps
>((props, ref) => {
  const {
    filterByWorkgroupIds,
    filterByWorkflowDefinitionIds,
    filterByPersonIds,
    filterByStepTypes,
    statusOption,
    taskListOption,
    directOption,
    onSelectionChanged,
  } = props;

  const navigate = useNavigate();
  const { currentOrganization } = useAuthenticatedUser();
  const { fetchTasks } = useTaskQueries();
  const { openTask } = useTaskModal();
  const gridApiRef = useRef<any>(null);
  const [totalRows, setTotalRows] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      if (gridApiRef.current) {
        gridApiRef.current.deselectAll();
      }
    },
  }));

  const getTaskId = useCallback((data: any) => {
    if (data.taskInstanceId != null) {
      return data.taskInstanceId.toString();
    }
    return `${data.parentTaskListId}:${data.taskKey}`;
  }, []);

  const handleTaskClick = useCallback(
    (task: TaskInstanceGridResponse) => {
      if (!task.taskProperties) {
        console.warn('Task clicked without taskProperties:', task);
        return;
      }

      // openTask({
      //   taskProperties: task.taskProperties,
      //   taskInstanceId: task.taskInstanceId,
      //   assignedEntityType: task.assignedEntityType,
      //   assignedEntityId: task.assignedEntityId,
      //   workgroupId: task.workgroupId,
      //   taskKey: task.taskKey,
      //   taskType: task.taskType,
      // });
    },
    [navigate, openTask]
  );

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Assigned To',
        field: 'assignedEntityName',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
      },
      {
        headerName: 'Workflow',
        field: 'workflowDefinitionName',
        valueGetter: (params) => params.data?.workflowDefinitionName,
      },
      {
        headerName: 'Workgroup',
        field: 'workgroupName',
      },
      {
        headerName: 'Task Title',
        field: 'taskTitle',
        valueGetter: (params) => params.data?.taskTitle,
      },
      {
        headerName: 'Task Type',
        field: 'taskType',
        valueGetter: (params) => params.data?.taskType,
      },
      {
        headerName: 'Assigned At',
        field: 'assignedAt',
        valueGetter: (params) => params.data?.assignedAt,
        valueFormatter: (params) =>
          params.value ? new Date(params.value).toLocaleString() : '',
      },
      {
        headerName: 'Due At',
        field: 'dueAt',
        valueGetter: (params) => params.data?.dueAt,
        valueFormatter: (params) =>
          params.value ? new Date(params.value).toLocaleString() : '',
      },
    ],
    []
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      resizable: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const getRowId = useCallback(
    (params: any) => getTaskId(params.data),
    [getTaskId]
  );

  const createDatasource = useCallback((): IServerSideDatasource => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const { startRow = 0, endRow = 100, sortModel = [] } = params.request;
        const sortFields: string[] = [];
        const sortOrders: string[] = [];

        sortModel.forEach((model) => {
          sortFields.push(model.colId);
          sortOrders.push(model.sort);
        });

        const request: TaskInstanceGridRequest = {
          organizationId: currentOrganization.id,
          filterByPersonIds,
          filterByStepTypes,
          filterByWorkflowDefinitionIds,
          filterByWorkgroupIds,
          statusOption,
          taskListOption,
          directOption,
          startRow,
          endRow,
          sortFields,
          sortOrders,
        };

        // try {
        //   const data = await fetchTasks(request);
        //   const rowsThisBlock = data.content;
        //   const lastRow =
        //     data.totalElements <= endRow ? data.totalElements : -1;
        //   setTotalRows(data.totalElements);
        //   params.success({ rowData: rowsThisBlock, rowCount: lastRow });
        // } catch (error) {
        //   console.error('API Error:', error);
        //   params.fail();
        // }
      },
    };
  }, [
    currentOrganization.id,
    filterByWorkgroupIds,
    filterByWorkflowDefinitionIds,
    filterByPersonIds,
    filterByStepTypes,
    statusOption,
    taskListOption,
    directOption,
    fetchTasks,
  ]);

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      gridApiRef.current = params.api;
      const dataSource = createDatasource();
      params.api.setGridOption('serverSideDatasource', dataSource);
    },
    [createDatasource]
  );

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const api = event.api;
      const selectionState =
        api.getServerSideSelectionState() as IServerSideSelectionState;

      const isAllSelected = selectionState.selectAll;
      const selectedCount = selectionState.selectAll
        ? totalRows - (selectionState.toggledNodes?.length || 0)
        : selectionState.toggledNodes?.length || 0;

      onSelectionChanged(selectedCount, isAllSelected, totalRows);
    },
    [onSelectionChanged, totalRows]
  );

  useEffect(() => {
    if (gridApiRef.current) {
      const dataSource = createDatasource();
      gridApiRef.current.setGridOption('serverSideDatasource', dataSource);
    }
  }, [
    filterByWorkgroupIds,
    filterByWorkflowDefinitionIds,
    filterByPersonIds,
    filterByStepTypes,
    statusOption,
    taskListOption,
    directOption,
    createDatasource,
  ]);

  return (
    <ResponsiveInfiniteGrid
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      getRowId={getRowId}
      cacheBlockSize={100}
      maxConcurrentDatasourceRequests={1}
      infiniteInitialRowCount={1000}
      maxBlocksInCache={10}
      enableRangeSelection={true}
      rowSelection="multiple"
      onGridReady={onGridReady}
      onSelectionChanged={handleSelectionChanged}
      onRowClicked={(event) => handleTaskClick(event.data)}
    />
  );
});

export default TasksInfiniteGrid;
