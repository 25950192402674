import { MantineColorScheme } from '@mantine/core';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

export const colorSchemeAtom = atomWithStorage<MantineColorScheme>(
  'color-scheme-preference',
  'light',
  createJSONStorage(() => localStorage),
  { getOnInit: true }
);

export const forcedDarkModeAtom = atomWithStorage<boolean>(
  'forced-dark-mode',
  false,
  createJSONStorage(() => localStorage),
  { getOnInit: true }
);
